/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropertyBookingLink from "./property_booking_link/PropertyBookingLink";
import useScrollPage from "../../../hooks/useScrollPage";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import PropertyInfo from "./property_info/PropertyInfo";
import PropertyOwnerInfo from "./property_owner/PropertyOwnerInfo";
import PropertyAddress from "./property_address/PropertyAddress";
import PropertyFeatures from "./property_features/PropertyFeatures";
import PropertyPhotos from "./property_photos/PropertyPhotos";
import PropertyPrice from "./property_price/PropertyPrice";
import LocalRecommendation from "./local_recommendation/LocalRecommendations";
import LastMinuteDeal from "./last_minute_deals/LastMinuteDeals";
import useLocalStorage from "../../../hooks/useLocalStorage";
import submitProperty from "./submitProperty";
import { MAGIC_NUMBERS } from "../../../constant";
  
function AddVacationHome() {
  useScrollPage();
  const { userState: { accessToken } } = useUserContext();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [property, setProperty] = useLocalStorage("property", {
    propertyName: "",
    propertyEmailAddress: "",
    propertyType: null,
    propertyDiscount: null,
    propertyDescription: "",
    propertyRules: "",
    numberOfGuest: 0,
    numberOfBathRooms: 0,
    numberOfBedRooms: 0,
    numberOfBeds: 0,
    eventsAllowed: false,
    petFriendly: false,
    childrenWelcome: false,
    propertyLink: "",
    propertyOwnerFirstName: "",
    propertyOwnerLastName: "",
    propertyOwnerEmailAddress: "",
    propertyOwnerPhoneNumber: "",
    propertyOwnerBio: "",
    propertyOwnerLink: "",
    propertyOwnerImage: null,
    propertyLocation: {
      streetAddress: "",
      city: "",
      state: "",
      country: "US",
      zip: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    minPrice: 0,
    maxPrice: 0,
    amenities: {
      bathroom: {},
      bedroomLaundry: {},
      entertainmentTech: {},
      kitchen: {},
      safety: {},
      heatingCooling: {},
      family: {},
      parking: {},
      outdoor: {},
      accessibility: {},
    },
    propertyCouponCode: "",
    lastMinuteDiscount: "",
    lastMinuteStartDate: null,
    lastMinuteEndDate: null,
    sightSeeing: ["", "", "", "", ""],
    restaurant: ["", "", "", "", ""],
    shopping: ["", "", "", "", ""],
    propertyCoverImage: null,
    propertyImages: [],
  });

  const handleSubmit = async () => {
    if (!property.propertyName) {
      notify("Property name is required", "error");
      return;
    }
    if (!property.propertyEmailAddress) {
      notify("Property email address is required", "error");
      return;
    }
    if (!property.propertyType) {
      notify("Property type is required", "error");
      return;
    }
    if (!property.propertyDiscount) {
      notify("Property discount is required", "error");
      return;
    }
    if (!property.propertyDescription) {
      notify("Property description is required", "error");
      return;
    }
    if (!property.numberOfGuest) {
      notify("Number of Guests is required", "error");
      return;
    }
    if (!property.numberOfBeds) {
      notify("Number of Beds is required", "error");
      return;
    }
    if (!property.numberOfBedRooms) {
      notify("Number of Bedrooms is required", "error");
      return;
    }
    if (!property.numberOfBathRooms) {
      notify("Number of Bathrooms is required", "error");
      return;
    }
    if (!property.propertyOwnerImage) {
      notify("Profile image is required", "error");
      return;
    }
    if (!property.propertyOwnerFirstName) {
      notify("Owner first name is required", "error");
      return;
    }
    if (!property.propertyOwnerLastName) {
      notify("Owner last name is required", "error");
      return;
    }
    if (!property.propertyOwnerEmailAddress) {
      notify("Owner email address is required", "error");
      return;
    }
    if (!property.propertyLocation.streetAddress) {
      notify("Property street address is required", "error");
      return;
    }
    if (!property.propertyLocation.city) {
      notify("Property city is required", "error");
      return;
    }
    if (!property.propertyLocation.state) {
      notify("Property state is required", "error");
      return;
    }
    if (!property.propertyLocation.zip) {
      notify("Property zipcode is required", "error");
      return;
    }
    if (!property.minPrice) {
      notify("Minimum Price per Night is required", "error");
      return;
    }
    if (!property.maxPrice) {
      notify("Maximum Price per Night is required", "error");
      return;
    }
    if (!property.propertyCoverImage) {
      notify("Property cover image is required", "error");
      return;
    }
    /* if (property.propertyImages && property.propertyImages.length !== 5) {
      notify("Please choose 5 property images", "error");
      return;
    } */

    try {
      setSubmitting(true);
      const response = await submitProperty(accessToken, {
        ...property,
        amenities: {
          bathroom: property.amenities.bathroom || {},
          bedroomLaundry: property.amenities.bedroomLaundry || {},
          entertainmentTech: property.amenities.entertainmentTech || {},
          kitchen: property.amenities.kitchen || {},
          safety: property.amenities.safety || {},
          heatingCooling: property.amenities.heatingCooling || {},
          family: property.amenities.family || {},
          parking: property.amenities.parking || {},
          outdoor: property.amenities.outdoor || {},
          accessibility: property.amenities.accessibility || {},
        },
      });
      if (response.status === "success") {
        notify("We've received your request! The property manager will be in touch.", "info");
        window.sessionStorage.clear();
        navigate("/dashboard/vacation_homes");
      } else {
        setSubmitting(false);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      setSubmitting(false);
      notify("Something went wrong. Please try again later.", "error");
    }
  };
 console.log(property);

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <div className="space-y-6 sm:px-6 lg:col-span-12 lg:px-0">
        <PropertyInfo property={property} setProperty={setProperty} />

        <PropertyOwnerInfo property={property} setProperty={setProperty} />

        <PropertyBookingLink property={property} setProperty={setProperty} />

        <PropertyAddress property={property} setProperty={setProperty} />

        <PropertyPrice property={property} setProperty={setProperty} />

        <PropertyFeatures property={property} setProperty={setProperty} />

        <PropertyPhotos property={property} setProperty={setProperty} />

        <LocalRecommendation property={property} setProperty={setProperty} />

        <LastMinuteDeal property={property} setProperty={setProperty} />

        <div className="flex justify-center">
          <button
            type="button"
            className="rounded-md border mr-2 border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
          >
            Cancel
          </button>
          <div>
            <ButtonPrimary
              icon="save"
              text={submitting ? "Please wait..." : "Submit"}
              size="md"
              action={handleSubmit}
              loading={submitting ? true : false}
            />
          </div>
      </div>
      </div>
     
    </div>
    
  );
}

export default AddVacationHome;
