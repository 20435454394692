/* eslint-disable react/prop-types */
import React from "react";
import DisneyCard from "../../../components/disney_card/DisneyCard";
import VacationCard from "../../../components/vacation_card/VacationCard";

export default function DisneyPage({
  properties,
}) {
  return (
    <div className="mb-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
      <DisneyCard property={{}} />
      {properties.map(property => <VacationCard key={property._id} property={property} />)}
    </div>
  );
}