/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Footer from "./../../../components/footer/Footer";
import Logo from "../../../components/logo/Logo";
import { Link } from "react-router-dom";
import MarketplaceNavigation from "../../../components/marketplace_navigation/MarketplaceNavigation";
import ListingOwner from "../../../components/listing_owner/ListingOwner";
import BusinessHours from "../../../components/business_hours/BusinessHours";
import MessageForm from "../../../components/message_form/MessageForm";
import ReviewList from "../../../components/review/ReviewList";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useParams, useNavigate } from "react-router-dom";
import getListing from "./getListing";
import { BUSINESS_CATEGORY, PLANS } from "../../../constant";
import Spinner from "../../../components/spinner/Spinner";
import { formatUrl, getValueFromArrayOfObject, phoneNumberFormatter } from "../../../services/util";
import useUserContext from "./../../../hooks/useUserContext";
import ListingHeader from "./listing_header/ListingHeader";
import ListingBadges from "./listing_badges/ListingBadges";
import ListingDescription from "./listing_description/ListingDescription";
import ListingCoupons from "./listing_coupons/ListingCoupons";
import ListingSocialLink from "./listing_social_link/ListingSocialLink";
import ListingAmenities from "./listing_amenities/ListingAmenities";
import ListingPaymentMethod from "./listing_payment_method/ListingPaymentMethod";
import ListingMap from "./listing_map/ListingMap";
import createView from "./createView";
import useScrollPage from "../../../hooks/useScrollPage";
import ListingEvents from "../../../components/listing_events/ListingEvents";

function Listing() {
  useScrollPage();

  const params = useParams();
  const navigate = useNavigate();
  const {
    userState,
    subscription: { product },
  } = useUserContext();
  const { listingId } = params;
  const [listing, setListing] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);


  const listingLogo = listing?.businessPhotos?.logo?.small ? listing.businessPhotos.logo.small.url : null;
  const listingGallery = listing?.businessPhotos?.gallery?.length ? listing.businessPhotos.gallery : [];
  const listingCategory = listing?.businessCategory ? listing.businessCategory : null;
  const listingName = listing?.businessName ? listing.businessName : null;
  const listingSubCategory = listing?.businessSubCategory ? getValueFromArrayOfObject(BUSINESS_CATEGORY[listingCategory]["subCategory"], { value: listing.businessSubCategory }) : null;
  const listingPrice = listing?.price ? listing.price : null;
  const listingAddress = listing?.businessAddress ? listing.businessAddress : null;
  const listingPhone = listing?.businessPhoneNumber ? phoneNumberFormatter(listing.businessPhoneNumber) : null;
  const listingEmail = listing?.businessEmail ? listing.businessEmail : null;
  const listingWebsite = listing?.businessWebsite ? formatUrl(listing.businessWebsite) : null;
  const listingBadges = listing?.badges ? listing.badges : null;
  const listingDescription = listing?.businessDescription ? listing.businessDescription : null;
  const listingFacebook = listing?.businessFacebook ? listing.businessFacebook : null;
  const listingInstagram = listing?.businessInstagram ? listing.businessInstagram : null;
  const listingLinkedIn = listing?.businessLinkedIn ? listing.businessLinkedIn : null;
  const listingAmenities = listing?.businessAmenities ? listing.businessAmenities : null;
  const paymentOptions = listing?.paymentOptions ? listing.paymentOptions : null;
  const listingOwner = listing?.businessOwner ? listing.businessOwner : null;
  const listingHours = listing?.businessHours ? listing.businessHours : null;
  const listingCoupons = listing?.coupons ? listing.coupons : null;

  const fetchListing = async () => {
    try {
      setFetching(true);
      const response = await getListing(listingId, userState && userState.id);
      if (response.status === "success") {
        setFetching(false);
        const { data } = response;
        setListing(data[0]);
      } else {
        setFetching(false);
        navigate("/error");
      }
    } catch (error) {
      setFetching(false);
      navigate("/error");
    }
  };

  const handleCreateView = async () => {
    try {
      const payload = {
        listingId,
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
      await createView(payload);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchListing();
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleCreateView();
      clearTimeout(timer);
    }, 20000);
    return () => clearTimeout(timer);
  }, []);

  console.log(listing?.userId);

  return (
    <div className="w-full">
      <ListingHeader
        listingLogo={listingLogo}
        listingSubCategory={listingSubCategory}
        listingName={listingName}
        listingPrice={listingPrice}
        listingAddress={listingAddress}
        listingPhone={listingPhone}
        listingEmail={listingEmail}
        listingWebsite={listingWebsite}
        listingFacebook={listingFacebook}
        listingInstagram={listingInstagram}
        listingLinkedIn={listingLinkedIn}
      />

      <div className="mt-3 mb-10">
        <div className="max-w-7xl mx-auto sm:flex gap-2">
          <div className="w-full sm:w-[100%] space-y-2 p-3">
            <>
              {fetching ? (
                <Spinner displayText="Loading..." />
              ) : (
                <>
                  {listingGallery.length ? (
                    <Carousel
                      showThumbs={false}
                      centerSlidePercentage={100}
                      centerMode={true}
                      showStatus={false}
                      renderArrowNext={(clickHandler, hasNext) =>
                        hasNext && (
                          <button className="control-arrow control-next" onClick={clickHandler}>
                            <span className="material-icons-outlined text-4xl">arrow_forward_ios</span>
                          </button>
                        )
                      }
                      renderArrowPrev={(clickHandler, hasPrev) =>
                        hasPrev && (
                          <button className="control-arrow control-prev" onClick={clickHandler}>
                            <span className="material-icons-outlined text-4xl">arrow_back_ios</span>
                          </button>
                        )
                      }>
                      {listingGallery.map((image, index) => {
                        return (
                          <div key={index}>
                            <img src={image.small.url} alt="default cover image" className="w-full h-full object-cover" />
                          </div>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <Carousel
                      showThumbs={false}
                      centerSlidePercentage={50}
                      showStatus={false}
                      renderArrowNext={(clickHandler, hasNext) =>
                        hasNext && (
                          <button className="control-arrow control-next" onClick={clickHandler}>
                            <span className="material-icons-outlined text-4xl">arrow_forward_ios</span>
                          </button>
                        )
                      }
                      renderArrowPrev={(clickHandler, hasPrev) =>
                        hasPrev && (
                          <button className="control-arrow control-prev" onClick={clickHandler}>
                            <span className="material-icons-outlined text-4xl">arrow_back_ios</span>
                          </button>
                        )
                      }>
                      <div className="w-full h-[600px]">
                        <img src="https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_3.png" alt="default cover image" className="w-full h-full object-cover" />
                      </div>
                      <div className="w-full h-[600px]">
                        <img src="https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_1.png" alt="default cover image" className="w-full h-full object-cover" />
                      </div>
                      <div className="w-full h-[600px]">
                        <img src="https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_2.png" alt="default cover image" className="w-full h-full object-cover" />
                      </div>
                    </Carousel>
                  )}

                  <ListingBadges listingBadges={listingBadges} />
                  <ListingDescription listingDescription={listingDescription} />
                  <ListingCoupons listingCoupons={listingCoupons} listing={listing} />
                  {/* <ListingSocialLink listingFacebook={listingFacebook} listingInstagram={listingInstagram} listingLinkedIn={listingLinkedIn} /> */}
                  <ListingAmenities listingAmenities={listingAmenities} />
                  <ListingPaymentMethod paymentOptions={paymentOptions} />
                  <ListingMap listingAddress={listingAddress} />
                  {/* <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
                    <h1 className="text-xl text-success-800 font-bold m-2">Reviews</h1>
                    <hr />
                    <div className="w-full p-2">
                      {listing && <ReviewList
                        userId={listing.userId}
                        target="listing"
                        targetId={listingId}
                        targetObject={listing}
                        targetObjectName={listingName}
                      />}
                    </div>
                  </div> */}
                </>
              )}
            </>
          </div>
          <div className="sm:w-[60%] h-full sticky top-[90px] space-y-2 p-3">
            {listing && <MessageForm listing={listing} />}
            {listingOwner && listing && <ListingOwner listingOwner={listingOwner} userId={listing.userId} />}
            {listingHours && <BusinessHours listingHours={listingHours} />}
            {listing && <ListingEvents eventId={listing?.userId} />} 
     
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Listing;
