/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function getUserInfo(userId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/user/${userId}?exclude=firstName-lastName-emailAddress-bio-causes-city-state-company-interest-onboarding`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getUserInfo;
