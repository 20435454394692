/* eslint-disable */
import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner/Spinner";
import { MAGIC_NUMBERS } from "../../../constant";
import getApplicationsById from "../../business_hub/getApplicationsById";
import useUserContext from "../../../hooks/useUserContext";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import ScholarshipPrompt from "../../business_hub/ScholarshipApplyPage";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { useCollapse } from "react-collapsed";


function ScholarshipApps() {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
        defaultExpanded: true,
    });
    const { userState } = useUserContext();
    const [apps, setApps] = useState([]);
    const [isAppsLoading, setIsAppsLoading] = useState(true);
    const [pagination, setPagination] = React.useState({
        total: 0,
        page: 0,
        remaining: 0,
        limit: 11,
    });

    const fetchApplications = async (accessToken) => {
        try {
            setIsAppsLoading(true);

            const response = await getApplicationsById(accessToken);

            if (response.status === "success") {
                console.log(response.data);
                setApps(response.data);
            } else if (response.status === "fail") {
                let displayMessage = "";
                if (Array.isArray(response.message)) {
                    displayMessage = response.message[0].message;
                } else {
                    displayMessage = response.message;
                    if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
                        displayMessage =
                            "An error has occurred. Please try again later. If this error persists please contact support";
                    }
                }
                notify(displayMessage, "error");
            }
        } catch (error) {
            console.log("something went wrong");
        } finally {
            setIsAppsLoading(false);
        }
    };

    useEffect(() => {
        fetchApplications(userState.accessToken, 0, pagination.limit);
    }, []);



    console.log(apps)
    return (
        <>


            <div className="bg-white rounded-lg p-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {isAppsLoading ? (
                    <Spinner displayText="Loading..." />
                ) : apps.length ? (
                    <div className=" mt-3 mx-auto bg-white p-4 flex flex-col">
                        {/*Scholarship Table */}


                        <div className="mt-10 px-4 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">

                                    <p className="mt-2 text-sm text-gray-700">
                                        Your applications can be found here.
                                    </p>
                                </div>

                            </div>
                            <div className="mt-8 flow-root">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            Organization
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Scholarship Type
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Email
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Status
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Date Submitted
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {apps.map((application) => (
                                                        <tr key={application.email}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {application.orgName}
                                                            </td>
                                                            <td className="whitespace-nowrap capitalize px-3 py-4 text-sm text-gray-500">{application.type}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{application.email}</td>
                                                            <td className="whitespace-nowrap capitalize px-3 py-4 text-sm text-gray-500">{application.appStatus}</td>
                                                            <td className="whitespace-nowrap capitalize px-3 py-4 text-sm text-gray-500">{format(new Date(application.createdAt), "MM/dd/yyyy")}</td>
                                                            <Link
                                                                to={`/application_file/${application._id}`}
                                                                target="_blank">
                                                                <td className="whitespace-nowrap capitalize px-3 py-4 text-sm text-indigo-600 hover:text-indigo-900">View</td>
                                                            </Link>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-20 w-full text-center">No Applications Found</div>
                )}
            </div>
        </>
    )
}

export default ScholarshipApps;