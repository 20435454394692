/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";

function Notification({ header, body, type }, ref) {
  const [open, setOpen] = React.useState(false);
  const notificationRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    showNotification: () => {
      setOpen(true);
    },
    hideNotification: () => {
      setOpen(false);
    },
  }));

  return (
    <div ref={notificationRef} aria-live="assertive" className={`${open ? "block" : "hidden"} fixed inset-0 sm:inset-20 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[9999999999]`}>
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {type === "error" ? (
                  <span className="w-6 h-6 text-red-500 material-icons-outlined">error_outline</span>
                ) : type === "warning" ? (
                  <span className="w-6 h-6 text-yellow-500 material-icons-outlined">warning</span>
                ) : (
                  <span className="w-6 h-6 text-primary-400 material-icons-outlined">check_circle</span>
                )}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className={`text-sm font-bold ${type === "error" ? "text-red-500" : type === "warning" ? "text-yellow-500" : "text-primary-600"}`}>{header}!</p>
                <p className={`mt-1 text-sm ${type === "error" ? "text-red-500" : type === "warning" ? "text-yellow-500" : "text-primary-500"}`}>{body}</p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400">
                  <span onClick={() => setOpen(false)} className="w-5 h-5 material-icons-outlined">
                    close
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(Notification);
