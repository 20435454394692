/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Modal from "../modal/Modal";
import { MdClear } from "react-icons/md";
import { GROUP_LOGO_TEMPLATE, GROUP_TEMPLATE_BANNER } from "../../constant";

function PickTemplate({ DisplayIcon, className, setBackground, setLogo }) {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(null);
  const [logoCheck, setLogoCheck] = React.useState(null);

  const handleSelectTemplate = event => {
    const templateIndex = parseInt(event.target.value, 10);
    setChecked(templateIndex);
    const template = GROUP_TEMPLATE_BANNER[templateIndex];
    setBackground(template);
  };

  const handleSelectLogoTemplate = event => {
    const templateIndex = parseInt(event.target.value, 10);
    setLogoCheck(templateIndex);
    const template = GROUP_LOGO_TEMPLATE[templateIndex];
    setLogo(template);
  };
  return (
    <>
      {open ? (
        <Modal>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
                <div className="w-full flex justify-between items-center">
                  <MdClear className="text-2xl cursor-pointer text-success-800" onClick={() => setOpen(false)} />
                </div>
                <h2 className="text-lg font-bold capitalize">Banner Templates</h2>
                <div className="w-full sm:max-w-full flex gap-3 overflow-x-auto ">
                  {GROUP_TEMPLATE_BANNER.length
                    ? GROUP_TEMPLATE_BANNER.map((template, index) => {
                        return (
                          <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                            <input
                              type="radio"
                              name="template"
                              value={index}
                              onChange={handleSelectTemplate}
                              className="sr-only"
                              aria-labelledby={`template-${index}`}
                              aria-describedby={`template-${index}`}
                            />
                            <div className="relative h-[200px] w-[200px]">
                              <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                            </div>
                            {checked !== null && index === checked ? (
                              <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ) : null}
                          </label>
                        );
                      })
                    : null}
                </div>

                <h2 className="text-lg font-bold capitalize">Cover Photo Templates</h2>
                <div className="w-full sm:max-w-full flex gap-3 overflow-x-auto ">
                  {GROUP_LOGO_TEMPLATE.length
                    ? GROUP_LOGO_TEMPLATE.map((template, index) => {
                        return (
                          <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                            <input
                              type="radio"
                              name="template"
                              value={index}
                              onChange={handleSelectLogoTemplate}
                              className="sr-only"
                              aria-labelledby={`template-${index}`}
                              aria-describedby={`template-${index}`}
                            />
                            <div className="relative h-[200px] w-[200px]">
                              <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                            </div>
                            {logoCheck !== null && index === logoCheck ? (
                              <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ) : null}
                          </label>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <DisplayIcon className={className ? className : "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"} callback={() => setOpen(true)} />
      )}
    </>
  );
}

export default PickTemplate;
