import React from "react";
import PropTypes from "prop-types";

function ListingDescription({ listingDescription }) {
  return (
    <>
      {listingDescription ? (
        <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
          <h1 className="text-xl text-success-800 font-bold m-2">Business Description</h1>
          <hr />
          <p className="text-md text-gray-600 font-medium p-2">{listingDescription}</p>
        </div>
      ) : null}
    </>
  );
}

ListingDescription.propTypes = {
  listingDescription: PropTypes.string,
};

export default ListingDescription;
