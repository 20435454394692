import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Logo({ color }) {
  const logoSource =
    color === "green"
      ? "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/marketplace_logo_green.png"
      : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/marketplace_logo_white.png";
  return (
    <>
      <Link to="/marketplace" className="flex">
        <span className="sr-only">COhatch+</span>
        <img className="h-8 w-auto sm:h-10" src={logoSource} alt="Marketplace Logo" />
      </Link>
    </>
  );
}

Logo.propTypes = {
  color: PropTypes.string,
};

export default Logo;
