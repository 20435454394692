/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
import React from "react";
import ButtonTeritary from "../button/ButtonTertiary";
import { validateEmail } from "../../services/validation";
import { Link } from "react-router-dom";
import submitNewsLetterForm from "./handleNewsLetterSubmit";
import notify from "../../services/toast";
import { generateClassName } from "../../services/util";

const SERVER_ERROR_CODES = [404, 500];

function NewsLetter() {
  const [email, setEmail] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(false);

  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    const result = validateEmail(email);
    setValidEmail(result);
  }, [email]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!validEmail) {
      notify("Email address is invalid. Please try another one.", "error");
      return;
    }
    setSubmitting(true);
    try {
      const payload = {
        emailAddress: email,
      };
      const response = await submitNewsLetterForm(payload);
      if (response.status === "success") {
        notify("You have successfully subscribed to our monthly newsletter.", "info");
        setEmail("");
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="relative top-20">
      <div className="bg-no-repeat bg-cover bg-[url('/src/assets/grungTextureGreen.jpg')]">
        <div className="max-w-7xl  mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center ">
          <div className="lg:w-0 lg:flex-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-success-800 sm:text-4xl" id="newsletter-headline">
              Stay in the loop with COhatch+!
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-white">Join our email list to be the first to know about upcoming features and updates.</p>
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-8">
            <form className="sm:flex items-center" onSubmit={handleFormSubmit}>
              <div className="relative w-full">
                <label htmlFor="email-address" className="sr-only">
                  Email Address
                </label>
                <input
                  onChange={(event) => setEmail(event.target.value)}
                  id="email-address"
                  name="email-address"
                  type="email"
                  value={email}
                  autoComplete="off"
                  required
                  className={generateClassName(email && !validEmail)}
                  placeholder="Enter your email."
                />
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0 ">
                <ButtonTeritary className="bg-success-400" text={submitting ? "Please wait..." : "Notify me"} loading={!validEmail || submitting ? true : false} size="md" />
              </div>
            </form>
            <p className="mt-3 text-sm text-white">
              We care about the protection of your data. Read our
              <Link to="#" className="text-success-800 font-medium underline px-2">
              Privacy Policy.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
