/* eslint-disable no-console */
import React from "react";
import { STATUS } from "../../constant";
import { Outlet, Navigate } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";

function NoAuth() {
  const { userState, subscription } = useUserContext();
  if (userState?.accessToken && userState?.status === STATUS.REQUIRES_SUBSCRIPTION) {
    return <Navigate to="/plan" replace />;
  } else if (userState?.accessToken && (userState?.status === STATUS.ACTIVE || userState?.status === STATUS.REQUIRES_EMAIL_VERIFICATION) && subscription.subscriptionStatus === "active") {
    return <Navigate to="/dashboard" replace />;
  } else if (userState?.accessToken && subscription.subscriptionStatus !== "active") {
    return <Navigate to="/subscription_info" replace />;
  } else {
    return <Outlet />;
  }
}

export default NoAuth;
