import React from "react";
import useTitle from "../../../hooks/useTitle";

import Tab from "../../../components/tab/Tab";
import { Outlet } from "react-router-dom";

function MainDrives() {
   useTitle("Drives");
	
	

	const navigations = [
		{ pathName: "Ongoing", url: "", icon: "group", end: true },
		{ pathName: "My Drives", url: "my_drives" },
	];
  return (
    <div className="w-full py-8 px-5 text-center ">
      <div className="mb-5">
				<h1 className="w-full text-4xl md:text-5xl text-success-800 font-bold">
					Drives
				</h1>
				
			</div>
			<Tab navigations={navigations} />
      <Outlet/>

      </div>
  );
}
export default MainDrives;