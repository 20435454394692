import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function updateMember(accessToken, groupId, memberId, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/group_member/${groupId}/${memberId}`;

  const response = await makeAPIRequest(API_ENDPOINT, "PUT", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateMember;
