import React from "react";
import { Link } from "react-router-dom";

import ButtonPrimary from "../../../components/button/ButtonSecondary";

function DriveListing() {
	const stats = [
		{ id: 1, name: "Creators on the platform", value: "8,000+" },
		{ id: 2, name: "Flat platform fee", value: "3%" },
	];
	
	return (
		<>
			<div className="mt-9 mb-5">
				<h1 className="w-full text-center text-4xl md:text-5xl text-success-800 font-bold">
					Drives
				</h1>
			
			</div>
			<div className="w-full">
				<div className="mt-3 mb-10">
					<div className="max-w-5xl mx-auto sm:flex gap-2">
						<div className="w-full sm:w-[100%] space-y-2 p-3">
							<>
								<div className="w-full bg-white rounded-md shadow-md flex flex-col">
									<div className="w-full flex justify-center gap-2 p-4 items-center">
										<img
											src="https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"
											alt=""
											className="w-[100px] h-[100px] rounded-full"
										/>
										<div className="w-full flex flex-col space-y-2">
											<h1 className="text-2xl text-success-800 font-bebas font-extrabold uppercase truncate">
												Summer Clothing Drive
											</h1>
											<div className="inline-flex items-center ">
												<span className="w-fit  px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 truncate mr-3">
													Clothing
												</span>
											</div>
										</div>
									</div>
									<hr />
									<div className="w-full flex flex-col p-4 space-y-2">
										<div className="w-full flex gap-1 items-center">
											<span className="material-icons-outlined text-md text-gray-400">
												business
											</span>
											<span className="text-base text-success-800">
												The Parable Project
											</span>
										</div>
										<div className="w-full flex gap-1 items-center">
											<span className="material-icons-outlined text-md text-gray-400">
												location_on
											</span>
											<span className="text-base text-success-800">
												4889 Sinclair Rd suite 204, Columbus
											</span>
										</div>

										<div className="w-full flex gap-1 items-center">
											<span className="material-icons-outlined text-md text-gray-400">
												call
											</span>
											<span className="text-base text-success-800">
												216-458-989
											</span>
										</div>
										<div className="w-full flex gap-2">
											<a
												href={`mailto:${"jclopto@gmail.com"}`}
												className="w-fit inline-flex items-center gap-1 px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 truncate cursor-pointer"
											>
												<span className="material-icons-outlined">email</span>
												Email
											</a>

											<a
												href="www.drives.com"
												target="_blank"
												rel="noreferrer"
												className="w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 truncate cursor-pointer"
											>
												<span className="material-icons-outlined">
													language
												</span>
												Website
											</a>
										</div>
									</div>
								</div>

								<div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
									<h1 className="text-xl text-success-800 font-bold m-2">
										About This Event
									</h1>
									<hr />
									<p className="text-md text-gray-600 font-medium p-2">
										This Drive is an ongoing effort to help children feel
										comfortable and clean within their environment! Everyone
										deserves clothes that fit, look nice, and that feel nice. We
										feel especially for our children in the society since they
										can't provide for themselves.
									</p>
									<h3>Items We Are Accepting:</h3>
									<p className="text-md  font-medium p-2">
										<ul>
											<li className="list-decimal">
												New and gently used clothing of all sizes and genders
											</li>
											<li>New and gently used shoes</li>
											<li>New and gently used outerwear like raincoats</li>
											<li>New towels</li>
											<li>Pull ups</li>
										</ul>
									</p>
								</div>
							</>
						</div>
						<div className="sm:w-[60%] h-full sticky top-[90px] space-y-2 p-3">
							<div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
								<h1 className="text-2xl text-success-800 font-bold m-2">
									When and Where
								</h1>
								<div className="inline-flex items-center ">
									<span className="material-icons-outlined text-md text-success-800">
										event
									</span>
									<span className="w-fit text-xl font-bold px-2.5 font-medium ">
										Date And Time
									</span>
								</div>
								<div className="inline-flex mt-1 text-gray-700">
									<p>Monday, June 21 9AM - 5PM EDT</p>
								</div>
								<div className="mt-6 inline-flex items-center ">
									<span className="material-icons-outlined text-md text-success-800">
										location_on
									</span>
									<span className="w-fit text-xl font-bold px-2.5 font-medium ">
										Location
									</span>
								</div>
								<div className="inline-flex mt-1 text-gray-700">
									<p>St Johns Middle School</p>
								</div>
								<div className="inline-flex mt-1 text-gray-700">
									<p>6845 Liddleburg Ln, Columbus Oh</p>
								</div>
								<div className="mt-4">
									<Link to="drives_listing">
										<ButtonPrimary size="md" text="RSVP" />
									</Link>
								</div>
							</div>

							<div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
								<h1 className="text-xl text-success-800 font-bold m-2">
									Contact Person
								</h1>
								<div className="w-full relative px-6 py-5 flex items-start space-x-3">
									<div className="flex-shrink-0">
										<img
											className="h-10 w-10 rounded-full"
											src="https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"
											alt=""
										/>
									</div>
									<div className="flex-1 min-w-0">
										<Link
											to="/profile/"
											className="text-sm font-medium text-gray-900 underline capitalize"
										>
											Kimberly Foulis
										</Link>
										<p className="text-sm text-gray-500 truncate capitalize">
											Executive Director
										</p>

										<p className="text-sm font-medium text-gray-900 lowercase">
											kimberlyf@gmail.com
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ml-20 mb-10">
				<dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
					{stats.map((stat) => (
						<div key={stat.id} className="flex flex-col bg-white p-8">
							<dt className="text-sm font-semibold leading-6 text-gray-600">
								{stat.name}
							</dt>
							<dd className="order-first  text-3xl font-semibold tracking-tight text-gray-900">
								{stat.value}
							</dd>
						</div>
					))}
				</dl>
			</div>
		</>
	);
}

export default DriveListing;
