/* eslint-disable no-console */
import config from "./../config";
import useUserContext from "../hooks/useUserContext";
import makeAPIRequest from "../services/makeRequest";
import { useNavigate } from "react-router-dom";
import notify from "./../services/toast";

function useLogout(setLoggingOut) {
  const { userState, setUserState, setSubscription, setPrivacy, setEmailPreference } = useUserContext();

  const navigate = useNavigate();
  const handleLogout = async event => {
    event.preventDefault();
    try {
      setLoggingOut(true);
      const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/logout`;
      const response = await makeAPIRequest(API_ENDPOINT, "GET", null, userState.accessToken);
      if (response.ok) {
        navigate("/");
      } else {
        setLoggingOut(false);
        notify("Unable to log you out. Please try again. If this error persists contact support", "error");
      }
    } catch (error) {
      setLoggingOut(false);
      console.error(error);
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setUserState({});
      setSubscription({});
      setPrivacy({});
      setEmailPreference({});
    }
  };

  return [handleLogout];
}

export default useLogout;
