import { MAGIC_NUMBERS } from "./../constant";

/* eslint-disable no-console */
export const validateName = (userName) => {
  if (!userName) {
    return false;
  }
  return true;
};

export const validateUserName = (userName) => {
  if (!userName) {
    return false;
  }
  return true;
};

export const validateEmail = (userEmail) => {
  const regex = /^[a-zA-Z0-9.!#$%&*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/gi;
  if (!userEmail) {
    return false;
  } else {
    return regex.test(userEmail);
  }
};

export const validatePassword = (userPassword) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,24}$/;
  if (!userPassword) {
    return false;
  } else {
    return regex.test(userPassword);
  }
};

export const validateReview = (review) => {
  if (!review) {
    return false;
  } else {
    return review.length < MAGIC_NUMBERS.REVIEW_LENGTH;
  }
};

export const validateBio = (bio) => {
  if (!bio) {
    return false;
  } else {
    return bio.length < MAGIC_NUMBERS.BIO_LENGTH;
  }
};

export const validateMessage = (message) => {
  if (!message) {
    return false;
  } else {
    return message.length < MAGIC_NUMBERS.MESSAGE_LENGTH;
  }
};

export const validateCompany = (company) => {
  const regex = /^[a-zA-Z0-9 ]{2,255}$/gi;
  if (!company) {
    return false;
  } else {
    return regex.test(company);
  }
};

export const validateCity = (city) => {
  const regex = /^[a-zA-Z0-9 .]{2,255}$/gi; 
  if (!city) {
    return false;
  } else {
    return regex.test(city);
  }
};


export const validateUrl = (url) => {
  const regex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,255}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  if (!url) {
    return false;
  } else {
    return regex.test(url);
  }
};

export const validatePhone = (phone) => {
  const regex = /^[0-9]{10}$/gi;
  if (!phone) {
    return false;
  } else {
    return regex.test(phone);
  }
};

export const validateJobTitle = (jobTitle) => {
  if (!jobTitle) {
    return false;
  }
  return true;
};

export const validateBusinessName = (businessesName) => {
  const regex = /^.{2,255}$/gi;
  if (!businessesName) {
    return false;
  } else {
    return regex.test(businessesName);
  }
};

export const validateCoupon = (couponsName) => {
  const regex = /^.{2,64}$/gi;
  if (!couponsName) {
    return false;
  } else {
    return regex.test(couponsName);
  }
};

export const validateCouponName = (couponsName) => {
  const regex = /^.{2,30}$/gi;
  if (!couponsName) {
    return false;
  } else {
    return regex.test(couponsName);
  }
};

export const validateCouponCode = (couponsName) => {
  const regex = /^.{2,15}$/gi;
  if (!couponsName) {
    return false;
  } else {
    return regex.test(couponsName);
  }
};

export const validateCouponUse = (couponsName) => {
  const regex = /^.{2,50}$/gi;
  if (!couponsName) {
    return false;
  } else {
    return regex.test(couponsName);
  }
};

export const validateGroupName = (groupName) => {
  const regex = /^.{2,100}$/gi;
  if (!groupName) {
    return false;
  } else {
    return regex.test(groupName);
  }
};

export const validateGroupDescription = (groupDescription) => {
  const regex = /^.{2,100}$/gi;
  if (!groupDescription) {
    return false;
  } else {
    return regex.test(groupDescription);
  }
};

export const validateGroupRules = (groupRules) => {
  const regex = /^.{2,100}$/gi;
  if (!groupRules) {
    return false;
  } else {
    return regex.test(groupRules);
  }
};

export const validatePropertyName = (propertyName) => {
  const regex = /^.{2,255}$/gi;
  if (!propertyName) {
    return false;
  } else {
    return regex.test(propertyName);
  }
};
