/* eslint-disable */
import React from "react";
import { Outlet } from "react-router-dom";
// import BreadCrumb from "../../../components/bread_crumb/BreadCrumb";
// import Tab from "../../../components/tab/Tab";

function Impact() {
	// const directions = [
	// 	{ pathName: "Philanthropy", url: "/dashboard/philanthropy" },
	// 	{ pathName: "Volunteer", url: "" },
	// ];

	// const navigations = [
  //   {
	// 		pathName: "Impact Feed",
	// 		url: "",
	// 		icon: "group",
	// 		end: true,
	// 	},
	// 	{
	// 		pathName: "Overview",
	// 		url: "overview",
	// 		icon: "group",
	// 		end: true,
	// 	},
	// 	{ pathName: "Favorites", url: "favorites" },
		
	// ];
	 return (
		// <>
		// 	<div className="w-full p-5 space-y-2 text-center ">
		// 		<h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">
		// 			Impact
		// 		</h1>
		// 		<div className="mb-3 mt-3">
		// 			<BreadCrumb directions={directions} />
		// 		</div>
		// 		<Tab navigations={navigations} />
		// 	</div>
			<Outlet />
		// </>
   );
}

export default Impact;
