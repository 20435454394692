/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import useTogglePassword from "../../hooks/useTogglePassword";
import submitLoginForm from "../login/handleSubmit";
import useUserContext from "../../hooks/useUserContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import notify from "../../services/toast";
import logo from "../../assets/COhatch+LogoWhite.png";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";
import autoLogin from "../login/autoLogin";

const SERVER_ERROR_CODES = [404, 500];

function ScholarshipLogin() {
	useScrollPage();
	useTitle("COhatch+ - Login");

	const {
		setUserState,
		userState,
		setSubscription,
		setPrivacy,
		setEmailPreference,
	} = useUserContext();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";
	const emailRef = React.useRef(null);
	const passwordRef = React.useRef(null);
	const passwordVisibilityRef = React.useRef(null);
	const [togglePasswordVisibility] = useTogglePassword(
		passwordRef,
		passwordVisibilityRef
	);

	const [email, setEmail] = React.useState("");
	const [error, setError] = React.useState(false);
	const [password, setPassword] = React.useState("");
	const [rememberMe, setRememberMe] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);

	const generateClassName = (valid) => {
		if (!valid) {
			return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md";
		} else {
			return "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-400 focus:border-red-400 sm:text-md";
		}
	};

	React.useEffect(() => {
		setError(false);
	}, [email, password]);

	React.useEffect(() => {
		emailRef.current.focus();
	}, []);

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		try {
			setSubmitting(true);
			const payload = {
				emailAddress: email,
				password: password,
				rememberMe: rememberMe,
			};
			const response = await submitLoginForm(payload);
			if (response.status === "success") {
				const { data, subscription, privacy, emailPreference } = response;
				setUserState(data);
				setSubscription(subscription);
				setPrivacy(privacy);
				setEmailPreference(emailPreference);
				navigate("/dashboard");
			} else if (response.status === "fail") {
				setError(true);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
					}
				}
				setSubmitting(false);
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later.", "error");
		}
	};

	const handleAutoLogin = async () => {
		try {
			setSubmitting(true);
			const response = await autoLogin();
			if (response.status === "success") {
				const { data, subscription, privacy, emailPreference } = response;
				setUserState(data);
				setSubscription(subscription);
				setPrivacy(privacy);
				setEmailPreference(emailPreference);
				navigate("/dashboard");
			} else {
				setSubmitting(false);
			}
		} catch (error) {
			setSubmitting(false);
			console.log("Auto login failed");
		}
	};

	React.useEffect(() => {
		if (!userState.accessToken) {
			handleAutoLogin();
		}
	}, []);
	return (
		<>
			<div className="h-screen w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-no-repeat bg-cover bg-[url('/src/assets/grungTextureGreen.jpg')] bg-fixed">
				<div className="flex justify-center">
					<img className="h-8 w-auto sm:h-10" src={logo} alt="Logo" />
				</div>

				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="mt-6 text-center text-3xl font-extrabold text-white">
						 Log In to apply for COhatch scholarships through My Business Hub.
					</h2>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 md:shadow sm:rounded-lg sm:px-10">
						<form className="space-y-6" onSubmit={handleFormSubmit}>
							<div>
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Email address
								</label>
								<div className="mt-1">
									<input
										onChange={(event) => setEmail(event.target.value)}
										ref={emailRef}
										id="email"
										name="email"
										type="email"
										placeholder="example@email.com"
										required
										value={email}
										className={generateClassName(error)}
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700"
								>
									Password
								</label>
								<div className="relative mt-1">
									<input
										onChange={(event) => setPassword(event.target.value)}
										ref={passwordRef}
										id="password"
										name="password"
										type="password"
										placeholder="Enter your password"
										required
										value={password}
										className={generateClassName(error)}
									/>
									<span
										ref={passwordVisibilityRef}
										onClick={togglePasswordVisibility}
										className="absolute top-3 left-[90%] material-icons-outlined text-gray-400 cursor-pointer"
									>
										visibility
									</span>
								</div>
							</div>

							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										id="remember-me"
										name="remember-me"
										onChange={(event) => setRememberMe(event.target.checked)}
										type="checkbox"
										className="h-4 w-4 text-primary-400 focus:ring-primary-400 border-gray-300 rounded"
									/>
									<label
										htmlFor="remember-me"
										className="ml-2 block text-sm text-gray-900"
									>
										Remember me
									</label>
								</div>

								<div className="text-sm">
									<Link
										to="/reset_request"
										className="font-medium text-primary-400 hover:text-primary-400"
									>
										Forgot your password?
									</Link>
								</div>
							</div>

							<div>
								<ButtonPrimary
									text={submitting ? "Please wait..." : "Login"}
									size="md"
									loading={submitting ? true : false}
								/>
							</div>
						</form>

						<div className="mt-6">
							<div className="relative">
								<div className="absolute inset-0 flex items-center">
									<div className="w-full border-t border-gray-300"></div>
								</div>
								<div className="relative flex justify-center text-sm">
									<span className="px-2 bg-white text-gray-500">
										{" "}
										Don&apos;t have an account?{" "}
									</span>
								</div>
							</div>

							<div className="mt-6 flex justify-center">
								<span className="text-sm text-gray-500">
									Create a new account
									<Link className="text-primary-400 ml-1" to="/impact_signup">
										here
									</Link>
								</span>
							</div>
							<div className="p-5 text-xs text-gray-500 text-center">
								&copy; COhatch/COhatch+ All rights reserved
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default React.memo(ScholarshipLogin);
