/*eslint-disable*/
import React, {useState} from "react";
import DefaultState from "../../../components/default_state/DefaultState";

function FavoriteDrives() {
  const [fetching, setFetching] = useState(false);
  return (
     <div className="bg-white">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8">
            <DefaultState icon="block" heading="No Favorites" description="You have not favorited any drives." />
        
        </div>
      </div>
    </div>
  );
}

export default FavoriteDrives;