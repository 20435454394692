/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import useUserContext from "../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import { Carousel } from "react-responsive-carousel";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import getVolunteerEvents from "./getVolunteerEvents";
import ImpactEventForm from "../../../components/philanthropy/VolunteerEventForm";
import createImpactEvent from "../../../components/philanthropy/createImpactEvent";
import notify from "../../../services/toast";
import Pagination from "../../../components/pagination/Pagination";
import VolunteerCard from "../../../components/philanthropy/VolunteerCard";
import DefaultState from "../../../components/default_state/DefaultState";
import DriveEventForm from "../../../components/philanthropy/DriveEventForm";
import { FaHome, FaUmbrellaBeach, FaUsers, FaCalendarCheck, FaShoppingBag } from "react-icons/fa";
import { HiHomeModern } from "react-icons/hi2";
import { NavLink, useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner/Spinner";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import getImpactEvents from "./getImpactEvents";
import getImpactEventByType from "./getImpactEventByType";

function UpcomingVolunteer() {
	useScrollPage();
	useTitle("Events");

  const tabs = [
	{ pathName: "All Events", value: "", icon: FaCalendarCheck, end: true },
	{
		pathName: "Drives",
		value: "Drive",
		icon: FaShoppingBag,
	},
	{ pathName: "Volunteer", value: "Volunteer", icon: FaUsers },
];
  const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const eventCategory = queryParams.get("type") || "";

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [event, setEvent] = useState({
    eventName: "",
    eventDescription: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    eventCategory: "",
    eventLocation: {
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
    },
    eventCOhatchMarket: "",
    eventCOhatchLocation: "",
    eventCOhatchMeetingRoom: "",
    eventImage: null,
    eventOwnerImage: null,
    eventOwnerFirstName: "",
    eventOwnerLastName: "",
    aboutOrganization: "",
  });
  
	const { userState } = useUserContext();
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 11,
	});
  const [submitting, setSubmitting] = useState(false);
  const [isShowing, setIsShowing] = useState(false);


   const handleNavigate = url => {
      navigate(`/dashboard/volunteer/volunteer?type=${url}`);
    };

    //CHANGE to get all Impact events
	const fetchEvents = async (accessToken) => {
    
		try {
      setIsEventsLoading(true);

			const response = await getImpactEvents(
				accessToken
			);

			if (response.status === "success") {
    
			const { limit, page, remaining, total, results } = response.data;
			setEvents(results);
      console.log(results);
        
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occured. Please try again later. If this error persists please contact support.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			//notify("something went wrong");
		} finally {
      setIsEventsLoading(false);
			//console.log("error not working");
		}
	};

  //CREATE calls to get by type
const fetchEventsByType = async (page, limit) => {
		try {
      setIsEventsLoading(true);
			const response = await getImpactEventByType(
				userState.accessToken, 
        page, 
        limit, 
        eventCategory
			);
			if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
				setEvents(results);
        console.log(results);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occured. Please try again later. If this error persists please contact support.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("something went wrong. Please Try again");
		} finally {
			setIsEventsLoading(false);
		}
	};
	const handlePrevFetch = async () => {
		await fetchEvents(userState.accessToken, pagination.page - 1, keyword);
	};

	const handleNextFetch = async () => {
		await fetchEvents(userState.accessToken, pagination.page + 1, keyword);
	};

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const dt = new Date(parseInt(event.eventDate.substring(0, 4), 10), parseInt(event.eventDate.substring(5, 7), 10) - 1, parseInt(event.eventDate.substring(8, 10), 10), parseInt(event.startTime.substring(0, 2), 10), parseInt(event.endTime.substring(3, 5), 10), 0);
      const response = await createImpactEvent(userState.accessToken, {
        ...event,
        eventDate: dt,
      });
      if (response.status === "success") {
        notify("Your event was successfully submitted.", "info");
        
      } else {
        setSubmitting(false);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      notify("Something went wrong. Please try again later.", "error");
    }
  };

   useEffect(() => {
		//fetchEvents(userState.accessToken);
    setPagination({ total: 0, page: 0, remaining: 0, limit: 12 });
    setEvents([]);
    fetchEventsByType(0, 12)
	}, [eventCategory]);
  console.log(events);
  
  return (
		<div className="max-w-7xl mx-auto sm:px-6 lg:px-8 ">
			<div className="relative mt-10 max-w-full h-full mx-auto bg-white shadow rounded-lg">
				<div className="w-full h-full bg-white p-10 space-y-8">
					{/* Header Language */}
					<div className="relative overflow-hidden pt-3 pb-2">
						{/* <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" /> */}
						<div
							aria-hidden="true"
							className="absolute inset-x-0 top-0 h-48 bg-white"
						/>
						<div className="relative">
							<div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
								<div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-0 lg:px-0">
									<div>
										<div>
											<h2 className="text-3xl font-bold font-bebas tracking-wide text-success-800">
												See All Opportunities
											</h2>
											<p className="mt-4 text-lg text-gray-500">
												We're not only members, but we're a team! Need some
												helping hands for an upcoming event? Add your volunteer
												event or ongoing drive below so members can sign up to help out!
											</p>
											{/* <p className="mt-4 text-lg text-gray-500">
                        You can now find cost effective coverage for you or your employees using A.I. Underwriting and top carriers without lengthy health questionnaires or clunky processes.
                      </p> */}
											{/* <div className="mt-6">
                    <a
                        href="https://calendly.com/jackreinoehl/benefits-overview"
                        target="_blank" rel="noreferrer"
                        className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-primary-400 to-primary-400 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-primary-100 hover:to-primary-100"
                      >
                        Learn More
                      </a>
                    </div> */}
										</div>
									</div>
								</div>
								{/* Image Carousel >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
								<div className="mt-10 sm:mt-10 lg:mt-0">
									<Carousel
										showThumbs={false}
										centerSlidePercentage={50}
										showStatus={false}
										autoPlay={true}
										interval={5000}
										infiniteLoop={true}
										renderArrowNext={(clickHandler, hasNext) =>
											hasNext && (
												<button
													className="control-arrow control-next"
													onClick={clickHandler}
												>
													<span className="material-icons-outlined text-4xl">
														arrow_forward_ios
													</span>
												</button>
											)
										}
										renderArrowPrev={(clickHandler, hasPrev) =>
											hasPrev && (
												<button
													className="control-arrow control-prev"
													onClick={clickHandler}
												>
													<span className="material-icons-outlined text-4xl">
														arrow_back_ios
													</span>
												</button>
											)
										}
									>
										
										<div className="w-full h-[300px]">
											<img
												src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD_vTI2hVp0ZvjyH2-WqX3HPafupJzLwUvGQ&usqp=CAU"
												alt="default  image"
												className="w-full h-full object-cover shadow-xl"
											/>
										</div>
										<div className="w-full h-[300px]">
											<img
												src="https://cdn.aarp.net/content/dam/aarp/volunteer/2022/1140x655-create-the-good-volunteer.jpg"
												alt="default cover image"
												className="w-full h-full object-cover shadow-xl"
											/>
										</div>
									</Carousel>
								</div>

								
							</div>
						</div>
					</div>

			
          {/* Add Event Form */}
           <div className="flex justify-end ">
          	<Menu as="div" className="relative inline-block text-left">
      <div>
        <button 
        onClick={() => setIsShowing((isShowing) => !isShowing)} 
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Add Event
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>

      <Transition show={isShowing}>
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
                 <ImpactEventForm
					      button={<button
                type="button"
                 className="block px-4 py-2 text-sm"
                >
                   Volunteer
                </button>}
                title="Volunteer"
                link={"Link for the Event"}
                event={event}
                setEvent={setEvent}
                onSubmit={handleSubmit}
					/> 
                 <DriveEventForm
					  button={<button
                type="button"
                 className="text-gray-700 block px-4 py-2 text-sm"
                >
                   Drive
                </button>}
                title="Drive"
                link={"Link for the Event"}
                event={event}
                setEvent={setEvent}
                onSubmit={handleSubmit}
		
					/> 
          </div>
        </div>
      </Transition>
     </Menu> 
   
					</div>
					<div className="relative">
						<div
							className="absolute inset-0 flex items-center"
							aria-hidden="true"
						>
							<div className="w-full border-t border-gray-300" />
						</div>
						<div className="relative flex justify-start">
							<span className="bg-white pr-3 text-4xl text-primary-400 font-bebas font-bold">
								 Opportunities
							</span>
						</div>
					</div>


        <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-primary-400 focus:border-primary-400 border-primary-400 rounded-md"
          defaultValue={`/dashboard/volunteer/volunteer${tabs.value ? `?type=${tabs.value}` : ""}`}
          onChange={(evt) => handleNavigate(evt.target.value)}
        >
          {tabs.map((tab) => (
            <option  
            key={tab.value}
            value={tab.value}
            defaultValue={`/dashboard/volunteer/volunteer${tab.value ? `?type=${tab.value}` : ""}`}>
            {tab.pathName}</option>
          ))}
        </select>
      </div>
       {/* Filter Tabs */}
       <div>
				 <div>
          <nav className="-mb-px flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <NavLink
              key={tab.value}
              to={`/dashboard/volunteer/volunteer${tab.value ? `?type=${tab.value}` : ""}`}
              end={tab?.end}
              className={() =>
                classNames(
                  tab.value === eventCategory ? "border-primary-400 text-primary-400"
                  : "border-transparent text-success-800 hover:text-primary-400 hover:border-primary-400",
                  index === 0 ? " " : " ",
                  index === 1 ? " text-danger-500 " : " ",
                  index === tabs.length - 1 ? " " : "",
                  "group inline-flex items-center py-4 px-3 border-b-2 font-medium text-sm hidden sm:block"
                )
              }>
                <tab.icon
                  className={classNames(
                    tab.end ? "text-success-400" : "text-success-800 group-hover:text-success-800",
                    "-ml-0.5 mr-2 h-5 w-5",
                    index === 1 ? " text-danger-500 " : " ",
                  )}
                  aria-hidden="true"
                />
                <span>{tab.pathName}</span>
                </NavLink>
            ))}
          </nav>
        </div>
        </div>
         
         
          <div className="h-full mx-24 ">
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
             {isEventsLoading ? (
              <div className="text-center w-full relative">
                <Spinner displayText="Fetching..." />
              </div>
            ) : (<>
                {events?.length > 0  ? (
                <>
                  {events.map(event => 
                  <VolunteerCard key={event._id} event={event} /> )}
           
                  <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
                </>
                ) : null} 
                </>)}
        <div className="m-6">
      <Pagination
          pagination={pagination}
          next={handleNextFetch}
          prev={handlePrevFetch}
        />
        </div>
			</div>
      </div>
		</div>
    </div>
     </div>
	);
}

export default UpcomingVolunteer;
