/* eslint-disable */
import React, { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import {
	MAGIC_NUMBERS,
	APP_MARKETS,
	APP_LOCATIONS,
	STARTUP_CATEGORY,
	libraries,
} from "../../constant";
import { generateClassName, inArray } from "../../services/util";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import notify from "../../services/toast";
import submitApplication from "../../pages/philanthropy/give_scholarships/submitApplication";
import Modal from "../../components/modal/Modal";
import Spinner from "../../components/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import {
	Input,
	SelectInputs,
	Upload,
} from "../../components/formvalidate/Input";
import { FormProvider, useForm } from "react-hook-form";
import config from "../../config";

function ScholarshipPrompt() {
	const { userState } = useUserContext();
	const navigate = useNavigate();
	// Payload/application information
	const [submitting, setSubmitting] = React.useState(false);
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [orgName, setOrgName] = React.useState("");
	const [orgMission, setOrgMission] = React.useState("");
	const [website, setWebsite] = React.useState("");
	const [orgAlign, setOrgAlign] = React.useState("");
	const [orgAmount, setOrgAmount] = React.useState("");
	const [orgImpact, setOrgImpact] = React.useState("");
	const [causes, setCauses] = React.useState([]);
	const [coLocation, setCOLocation] = React.useState("");
	const [orgSponsored, setOrgSponsored] = React.useState("");
	const [googleMap, setGoogleMap] = React.useState(null);
	const [market, setMarket] = React.useState("");
	const [type, setType] = React.useState("boost");
	const status = "pending";
	const [businessAddress, setBusinessAddress] = useState(null);
	const [dateFounded, setDateFounded] = useState("");
	const [businessPlan, setBusinessPlan] = useState("");
	const [businessPlanPdf, setBusinessPlanPdf] = useState("");
	const [businessVideo, setBusinessVideo] = useState("");
	const [businessPdf, setBusinessPdf] = useState("");
	const [fundingStrategy, setFundingStrategy] = useState("");
	const [breakevenPoint, setBreakevenPoint] = useState("");
	const [threeChallenges, setThreeChallenges] = useState("");
	const [planHire, setPlanHire] = useState("");
	const [jobRole, setJobRole] = useState("");
	const [category, setCategory] = useState("");
	const [orgOffer, setOrgOffer] = useState("");
	const [orgUses, setOrgUses] = useState("");
	const [logoImage, setLogoImage] = useState("");
	// const [reapplicant, setReapplicant] = useState("");
	const [idOption, setIdOption] = React.useState("");
	const [idFile, setIdFile] = React.useState(null);
	const [idReason, setIdReason] = React.useState(null);
	const createdAt = new Date();

	const [autoComplete, setAutoComplete] = useState(null);
	//Const location = useLocation();
	const nonprofitId = location.state;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries,
		region: "US",
	});

	const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" },
	];

	const scholarOPTIONS = [
		{ value: "no", label: "No, this is my first time applying." },
		{
			value: "yes",
			label: "Yes, I am a scholarship recipient and am re-applying.",
		},
	];
	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;
			console.log(value, "event");
			setMarket(value);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		} else {
			setMarket(null);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		}
	};

	const handleCategoryChange = (event) => {
		if (event) {
			const { value } = event;

			setCategory(value);
		} else {
			setCategory(null);
		}
	};
	const generateCohatchMarket = () => {
		const cohatchMarket = [];

		for (const market in APP_MARKETS) {
			cohatchMarket.push(APP_MARKETS[market]);
		}

		return cohatchMarket.flat();
	};

	const handleLocationsChange = (event) => {
		if (event) {
			const { label } = event;

			setCOLocation(label);
		} else {
			setCOLocation(null);

			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		}
	};
	const generateCohatchLocation = () => {
		if (market) {
			const cohatchLocation = [];
			cohatchLocation.push(APP_LOCATIONS[market]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};

	const onLoad = (autoComplete) => {
		setAutoComplete(autoComplete);
	};
	const onPlaceChanged = () => {
		if (autoComplete !== null) {
			const {
				formatted_address,
				geometry: {
					location: { lat, lng },
				},
			} = autoComplete.getPlace();
			const latitude = lat();
			const longitude = lng();
			setBusinessAddress({
				address: formatted_address,
				coordinates: { lat: latitude, lng: longitude },
				location: { type: "Point", coordinates: [longitude, latitude] },
			});
		} else {
			console.log("Autocomplete is not loaded yet");
		}
	};

	const onPositionChanged = () => {
		if (googleMap) {
			googleMap.panTo(businessAddress.coordinates);
		}
	};

	const generateCauses = (causesArray) => {
		const causes = [];

		for (const cause of causesArray) {
			causes.push({ value: cause, label: cause });
		}

		return causes;
	};

	//Add Causes
	const [userCause, setUserCause] = React.useState(causes || []);
	const [newCause, setNewCause] = React.useState(null);
	const handleAddCause = (event) => {
		event.preventDefault();
		if (!newCause) {
			return;
		}

		if (inArray(userCause, newCause.value)) {
			notify(`${newCause.value} already exists in your causes list.`, "error");
			return;
		}

		setUserCause((prevState) => {
			return [...prevState, newCause.value];
		});
		setCauses((prevState) => {
			return [...prevState, newCause.value];
		});

		setNewCause(null);
	};
	const handleRemoveCause = (event) => {
		const { cause } = event.target.dataset;
		const newCauseList = userCause.filter(
			(value, index) => parseInt(cause, 10) !== index
		);
		setUserCause(newCauseList);
		setCauses(newCauseList);
	};

	const CAUSES = [
		"Animals",
		"Arts, Culture & Humanity",
		"Climate",
		"Community Development",
		"Conservation",
		"Consumption",
		"Crisis",
		"Education",
		"Energy",
		"Equality",
		"Family",
		"Food",
		"Health",
		"Homeless",
		"Jobs",
		"Other",
		"Peace and Justice",
		"Poverty",
		"Refugees",
		"Religious",
		"Water & Sanitation",
		"Minority Owned",
		"Woman Owned",
	];

	//form
	const methods = useForm();

	const handleFormSubmit = async (event) => {
		//event.preventDefault();
		setSubmitting(true);
		try {
			//setCauses(userCause);
			const payload = {
				firstName,
				lastName,
				email,
				website,
				businessAddress,
				phoneNumber,
				orgName,
				orgMission,
				orgAlign,
				orgAmount,
				orgImpact,
				orgSponsored,
				causes,
				type,
				// nonprofitId,
				market,
				dateFounded,
				jobRole,
				category,
				orgOffer,
				orgUses,
				idNumber: {
					idOption: idOption,
					idFile: idFile,
					idReason: idReason,
				},
				businessPlan,
				businessPlanPdf,
				businessVideo,
				businessPdf,
				fundingStrategy,
				breakevenPoint,
				threeChallenges,
				planHire,
				appStatus: status,
				cmStatus: status,
				mlStatus: status,
				// reapplicant,
				coLocation,
				logoImage,
				createdAt,
			};

			const response = await submitApplication(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmitting(false);
				navigate("/application_success");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Unable to submit application. Please try again later.");
		}
	};

	const handleLogoUploads = (result) => {
		setLogoImage({
			logoImage: result,
		});
	};
	const handlePdfUploads = (result) => {
		setBusinessPdf({
			businessPdf: result,
		});
	};
	const handleBusinessPdfUploads = (result) => {
		setBusinessPlanPdf({
			businessPlanPdf: result,
		});
	};

	const handleLogoUpload = async (data) => {
		try {
			const logo = methods.watch().logoImage;
			const folder = "logo_photos";
			const formData = new FormData();

			formData.append("width", 0);
			formData.append("height", 0);
			formData.append("folder", folder);
			formData.append("logo", logo[0]);
			formData.append("name", logo[0].name);
			const BASE_API_URL = config["API_BASE_ENDPOINT"];
			const response = await fetch(`${BASE_API_URL}/uploads/logos`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${userState.accessToken}`,
				},
			});
			if (response.ok) {
				const jsonResponse = await response.json();
				handleLogoUploads(jsonResponse.data);
			} else {
				console.log(
					"An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.",
					"error"
				);
				return;
			}
		} catch (error) {
			console.log("Something went wrong. Please try again later.", "error");
		}
	};

	const handlePdfUpload = async (data) => {
		try {
			const file = methods.watch().businessPdf;
			const folder = "501c3_photos";
			const formData = new FormData();
			formData.append("width", 0);
			formData.append("height", 0);
			formData.append("folder", folder);
			formData.append("file", file[0]);
			formData.append("name", file[0].name);
			const BASE_API_URL = config["API_BASE_ENDPOINT"];
			const response = await fetch(`${BASE_API_URL}/uploads/files`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${userState.accessToken}`,
				},
			});
			if (response.ok) {
				const jsonResponse = await response.json();
				handlePdfUploads(jsonResponse.data);
			} else {
				notify(
					"An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.",
					"error"
				);
				return;
			}
		} catch (error) {
			console.log("Something went wrong. Please try again later.", "error");
		} finally {
			console.log("done");
		}
	};
	const handleBusinessPdfUpload = async (data) => {
		try {
			const file = methods.watch().businessPlanPdf;
			const folder = "501c3_photos";
			const formData = new FormData();
			formData.append("width", 0);
			formData.append("height", 0);
			formData.append("folder", folder);
			formData.append("file", file[0]);
			formData.append("name", file[0].name);
			const BASE_API_URL = config["API_BASE_ENDPOINT"];
			const response = await fetch(`${BASE_API_URL}/uploads/files`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${userState.accessToken}`,
				},
			});
			if (response.ok) {
				const jsonResponse = await response.json();
				handleBusinessPdfUploads(jsonResponse.data);
			} else {
				notify(
					"An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.",
					"error"
				);
				return;
			}
		} catch (error) {
			console.log("Something went wrong. Please try again later.", "error");
		} finally {
			console.log("done");
		}
	};

	const onSubmit = methods.handleSubmit((data) => {
		handleFormSubmit();
	});
	React.useEffect(() => {
		setType("boost");
		//setReapplicant(methods.watch().reapplicant);
		setFirstName(methods.watch().firstName);
		setLastName(methods.watch().lastName);
		setEmail(methods.watch().email);
		setPhoneNumber(methods.watch().phoneNumber);
		setOrgName(methods.watch().orgName);
		setWebsite(methods.watch().website);
		setBusinessAddress(methods.watch().address);
		setOrgMission(methods.watch().orgMission);
		setOrgAlign(methods.watch().orgAlign);
		setOrgImpact(methods.watch().orgImpact);
		setCauses(methods.watch().causes);
		setCOLocation(methods.watch().coLocation);
		setMarket(methods.watch().market);
		setOrgAmount(methods.watch().orgAmount);
		setOrgSponsored(methods.watch().orgSponsored);
		setJobRole(methods.watch().jobRole);
		setCategory(methods.watch().category);
		setOrgUses(methods.watch().orgUses);
		setDateFounded(methods.watch().dateFounded);
		setBusinessPlan(methods.watch().businessPlan);
		setBusinessVideo(methods.watch().businessVideo);
		setFundingStrategy(methods.watch().fundingStrategy);
		setBreakevenPoint(methods.watch().breakevenPoint);
		setThreeChallenges(methods.watch().threeChallenges);
		setPlanHire(methods.watch().planHire);
	});

	React.useEffect(() => {
		handleLogoUpload();
	}, [methods.watch().logoImage]);
	React.useEffect(() => {
		handlePdfUpload();
	}, [methods.watch().businessPdf]);
	React.useEffect(() => {
		handleBusinessPdfUpload();
	}, [methods.watch().businessPlanPdf]);

	return (
		<>
			<div className="p-10 flex justify-center">
				<div className="max-w-7xl  overflow-hidden shadow rounded-lg">
					<div className="bg-success-800">
						<div className="max-w-7xl mx-auto  sm:py-24 sm:px-6 lg:px-8">
							<div className="text-center">
								<p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
									Apply for the Boost Scholarship
								</p>
								<p className="max-w-xl mt-5 mx-auto text-xl text-gray-200">
									Need an office or collaborative work community to help you get
									the job done? We’re looking to give great startups, woman-led,
									and minority-owned businesses access to all of our locations
									to give you what you need to help strengthen our community and
									improve lives in our neighborhoods.
									<p>
										<br />
										<b>
											Applications are open year round. Scholarships will be
											awarded on a rolling basis.
										</b>
									</p>
								</p>
								<br />
								<p className="text-white ml-5 pb-4">
									<span>*</span> Startups must be under 3 years old with a
									maximum of 5 employees{" "}
								</p>
							</div>
						</div>
					</div>
					<div className=" bg-white flex justify-center ">
						<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-8">
							<FormProvider {...methods}>
								<form
									onSubmit={onSubmit}
									noValidate
									autoComplete="off"
									className="container"
								>
									<div className="max-w-lg mx-auto lg:max-w-none">
										{/* <div className="w-full flex gap-3 mb-6">
									<div className="w-full">
									<SelectInputs
                  label="Are you a current COhatch scholar applying for 2024?:"
											name="reapplicant"
											id="reapplicant"
                      options={scholarOPTIONS}
											setSelectedValue={(event) => setReapplicant(event.value)}
                       validation={{
                            required: {
                              value: true,
                              message: 'required',
                            },
                          }}
										/>
									</div>
								</div> */}
										<div className="mb-6 w-full flex gap-3">
											<div className=" w-full">
												<Input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="First Name"
													name="firstName"
													type="input"
													id="name"
													placeholder="First Name"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
											<div className="w-full">
												<Input
													type="text"
													name="lastName"
													id="lastName"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="Last Name"
													placeholder="Enter Last Name"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													type="text"
													name="orgName"
													id="orgName"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="Organization Name"
													placeholder="Enter Organization Name"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													label="Job Role"
													type="text"
													name="jobRole"
													id="jobRole"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray"
													placeholder="Enter role..."
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													label="Email"
													type="text"
													name="email"
													id="email"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													placeholder="Enter Email"
													validation={{
														required: {
															value: true,
															message: "required",
														},
														pattern: {
															value:
																/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
															message: "not a valid email address",
														},
													}}
												/>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													type="input"
													name="phoneNumber"
													id="phoneNumber"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="Phone Number"
													placeholder="Enter phone number"
													validation={{
														required: {
															value: true,
															message: "required",
														},
														pattern: {
															value: /^[0-9]{10}$/,
															message: "not a valid phone number",
														},
													}}
												/>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													type="string"
													name="website"
													id="website"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="Organization Website"
													placeholder="Enter website"
													validation={{
														required: {
															value: false,
														},
														pattern: {
															value:
																/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,255}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
															message: "not a valid website",
														},
													}}
												/>
											</div>
										</div>

										<div className="mt-5 mb-4">
											<Upload
												label="Logo Image"
												type="file"
												name="logoImage"
												id="logoImage"
											/>
											<div>
												Image MUST be a PNG file
												<span className="ml-3 text-lg text-red-400">*</span>
											</div>
										</div>

										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												{isLoaded ? (
													<Autocomplete
														onLoad={onLoad}
														onPlaceChanged={onPlaceChanged}
													>
														<div className="w-full flex gap-3">
															<div className="w-full">
																<Input
																	className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
																	label="Organization Address"
																	placeholder="enter address"
																	validation={{
																		required: {
																			value: true,
																			message: "required",
																		},
																	}}
																	type="text"
																	name="address"
																	id="address"
																/>
															</div>
														</div>
													</Autocomplete>
												) : (
													<Spinner displayText="Loading..." />
												)}
											</div>
										</div>

										<div className="w-full mt-3 flex gap-3 mb-6">
											<div className="w-full">
												<SelectInputs
													label="Are you a..."
													name="category"
													id="category"
													options={STARTUP_CATEGORY}
													noOptionsMessage="No categories found"
													placeholder="Select a category"
													setSelectedValue={handleCategoryChange}
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="When was your organization established?"
													type="date"
													name="dateFounded"
													id="dateFounded"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>
										{/*      
          <div className="mt-10 w-full flex justify-between items-center">
            <label className="block text-lg font-medium text-black-700">Logo Image</label>
          </div>
          <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px] mt-1 mb-6">
            {logoPic?.small.url ? <img src={logoPic?.small?.url ? logoPic.small.url  : null} className="w-full h-full" alt="event owner image upload" /> : <p className="w-full pt-8 text-md text-center text-gray-400">Logo Image</p>}
            <FileUploader
              fileType="image/*"
              isMultiple={false}
              validationCriteria={imageCriteriaEventOwner}
              DisplayIcon={PhotoDisplay}
              accessToken={userState.accessToken}
              folder="logo_photos"
              description="Image - Min: 400 X 400, Max: 5MB"
              numberOfFiles={1}
              callback={result => setLogoImage(result)}
            />
          </div> */}
										<div className="w-full flex gap-3 mb-6">
											{/* Business Info */}
											<div className="w-full">
												<Input
													className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="Tell us about your business. Do you have a business plan you can share?"
													multiline={true}
													rows="3"
													type="textarea"
													name="businessPlan"
													placeholder="Answer here..."
													id="businessPlan"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
											<div className="w-full">
												<Input
													label="Business plan file (Optional)"
													type="file"
													name="businessPlanPdf"
													placeholder="Answer here..."
													id="businessPlanPdf"
												/>
											</div>
										</div>

										<div className="w-full flex gap-3 mb-6">
											{/* Business Video */}
											<div className="w-full">
												<label className="font-semibold capitalize">
													Please record a 3 minute or less video talking about
													your business and why you'd like to be a part of the
													COhatch ecosystem. Please utilize a service like loom
													where you can record yourself and grab a link, for
													free! Loom:
													<a
														href="https://www.loom.com/signup"
														target="_blank"
														className="text-blue-900 underline lowercase"
													>
														https://www.loom.com/signup
													</a>
												</label>
												<span> (Please only add links to videos here)</span>
												<Input
													className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													type="input"
													name="businessVideo"
													placeholder="Link here..."
													id="businessVideo"
													validation={{
														required: {
															value: false,
														},
														pattern: {
															value:
																/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,255}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
															message: "not a valid website",
														},
													}}
												/>
											</div>
										</div>

										<div className="mt-5 mb-4">
											<Upload
												label="Must provide proof of business (LLC, Sole Proprietorship, DBA, etc.)"
												type="file"
												name="businessPdf"
												id="businessPdf"
											/>
											<div>
												File MUST be a PDF file
												<span className="ml-3 text-lg text-red-400">*</span>
											</div>
										</div>

										<div className="w-full flex gap-3 mb-6">
											{/* Funding Strategy */}
											<div className="w-full">
												<Input
													className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="What is your funding strategy?"
													multiline={true}
													rows="3"
													type="textarea"
													placeholder="Answer here..."
													name="fundingStrategy"
													id="fundingStrategy"
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>

										<div className="w-full flex gap-3 mb-6">
											{/* Breakeven */}
											<div className="w-full">
												<Input
													className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="When do you project your breakeven point?"
													multiline={true}
													rows="3"
													type="textarea"
													name="breakevenPoint"
													id="breakevenPoint"
													placeholder="Answer here..."
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>

										<div className="w-full flex gap-3 mb-6">
											{/* Challenges */}
											<div className="w-full">
												<Input
													className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="What are your organization's top three challenges?"
													multiline={true}
													rows="3"
													type="textarea"
													name="threeChallenges"
													id="threeChallenges"
													placeholder="Answer here..."
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>

										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="How are you planning to use our space?"
													multiline={true}
													rows="3"
													type="textarea"
													name="orgUses"
													id="orgUses"
													placeholder="Answer here..."
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>

										<div className="w-full mt-3 flex gap-3 mb-6">
											<div className="w-full">
												<div className="mt-6">
													<SelectInputs
														label="COhatch City"
														name="market"
														id="market"
														options={generateCohatchMarket()}
														noOptionsMessage="No cities found"
														placeholder="Select a COhatch City"
														setSelectedValue={handleMarketChange}
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>
											<div className="w-full flex mt-6 gap-3">
												<div className="w-full">
													<SelectInputs
														label="COhatch Location"
														id="coLocation"
														name="coLocation"
														options={generateCohatchLocation()}
														noOptionsMessage="Select a COhatch Location"
														placeholder={methods.watch().coLocation}
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<SelectInputs
													label="How many employees need access to COhatch?"
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													name="orgAmount"
													id="orgAmount"
													options={scorecardOPTIONS}
													setSelectedValue={(event) =>
														setOrgAmount(event.value)
													}
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>
										<div className="w-full flex gap-3 mb-6">
											<div className="w-full">
												<Input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="How many employees do you have?  How many do you plan to hire in the next 12/24/36 months?"
													name="planHire"
													id="planHire"
													placeholder="Answer here..."
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>

										<div className="w-full flex gap-3 ">
											<div className="mb-8 w-full">
												<Input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="Are you sponsored by a COhatch employee? If so, who?"
													type="text"
													name="orgSponsored"
													id="orgSponsored"
													placeholder="Answer here..."
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div>
										<div className=" mt-4 w-full flex justify-between items-center">
											<div>
												<button
													className="cursor-pointer inline-flex items-center justify-center gap-2 px-3 py-2 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
													type="submit"
												>
													Submit
												</button>
											</div>
										</div>
									</div>
								</form>
							</FormProvider>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ScholarshipPrompt;
