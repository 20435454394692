import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function deleteProperty(authToken, propertyId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/property/${propertyId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteProperty;
