/* eslint-disable */
import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactstory`;

async function getImpactStories(query, accessToken) {
	let url = `${API_ENDPOINT}?true=true`;
	Object.keys(query).forEach((key) => {
		url += `&${key}=${key}=${query[key]}`;
	});
	const response = await makeAPIRequest(url, "GET", null, accessToken);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default getImpactStories;
