/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import notify from "../../../../services/toast";
import { generateClassName } from "../../../../services/util";
import { validateEmail, validateJobTitle, validateUserName } from "../../../../services/validation";
import useScrollPage from "../../../../hooks/useScrollPage";

function OwnerInfo({ steps, currentStep, setCurrentStep, listing, setListing }) {
  useScrollPage();

  const [firstName, setFirstName] = React.useState(listing?.ownerInfo?.ownerFirstName || "");
  const [lastName, setLastName] = React.useState(listing?.ownerInfo?.ownerLastName || "");
  const [email, setEmail] = React.useState(listing?.ownerInfo?.ownerEmail || "");
  const [title, setTitle] = React.useState(listing?.ownerInfo?.ownerJobTitle || "");

  const [validFirstName, setValidFirstName] = React.useState(false);
  const [validLastName, setValidLastName] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);
  const [validTitle, setValidTitle] = React.useState(false);

  React.useEffect(() => {
    const result = validateUserName(firstName);
    setValidFirstName(result);
  }, [firstName]);

  React.useEffect(() => {
    const result = validateUserName(lastName);
    setValidLastName(result);
  }, [lastName]);

  React.useEffect(() => {
    const result = validateEmail(email);
    setValidEmail(result);
  }, [email]);

  React.useEffect(() => {
    const result = validateJobTitle(title);
    setValidTitle(result);
  }, [title]);

  const handleSubmit = () => {
    if (!validFirstName || !validLastName || !validEmail || !validTitle) {
      notify("One or more fields are invalid. Please check before submitting.", "error");
      setValidEmail(false);
      return;
    }

    const payload = {
      ownerFirstName: firstName,
      ownerLastName: lastName,
      ownerEmail: email,
      ownerJobTitle: title,
    };

    setListing(prevState => {
      return { ...prevState, ownerInfo: payload };
    });
    setCurrentStep(currentStep + 1);
  };

  const handleGoBack = () => {
    if (validFirstName && validLastName && validEmail && validTitle) {
      const payload = {
        ownerFirstName: firstName,
        ownerLastName: lastName,
        ownerEmail: email,
        ownerJobTitle: title,
      };

      setListing(prevState => {
        return { ...prevState, ownerInfo: payload };
      });
    }
    setCurrentStep(currentStep - 1);
  };
  return (
    <div className="w-full bg-white overflow-hidden shadow rounded-lg">
      <div className="w-full px-4 py-5 sm:p-6 space-y-2">
        <h1 className="text-xl font-bold text-success-800">Business Owner Information</h1>
        <p className="text-sm text-gray-400">Show your customers who they're working with!</p>
        <div className="w-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:items-center sm:gap-4">
          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                Point of Contact First Name
              </label>
            </div>
            <div className="mt-1">
              <input
                onChange={event => setFirstName(event.target.value)}
                type="text"
                name="first_name"
                id="first_name"
                value={firstName}
                className={generateClassName(!validFirstName && firstName)}
                placeholder="Mary"
              />
            </div>
          </div>

          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Point of Contact Last Name
              </label>
            </div>
            <div className="mt-1">
              <input
                onChange={event => setLastName(event.target.value)}
                type="text"
                name="last_name"
                id="last_name"
                value={lastName}
                className={generateClassName(!validLastName && lastName)}
                placeholder="Smith"
              />
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="email" className="items-center text-sm font-medium text-gray-700">
              Point of Contact Email Address
            </label>
          </div>
          <div className="mt-1">
            <input
              onChange={event => setEmail(event.target.value)}
              type="email"
              name="email"
              id="email"
              value={email}
              className={generateClassName(!validEmail && email)}
              placeholder="mary.smith@gmail.com"
            />
          </div>
        </div>

        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Point of Contact Job Title
            </label>
          </div>
          <div className="mt-1">
            <input
              onChange={event => setTitle(event.target.value)}
              type="text"
              name="title"
              id="title"
              value={title}
              className={generateClassName(!validTitle && title)}
              placeholder="Head Of Marketing"
              
            />
          </div>
        </div>
        <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
          <div>{currentStep <= 0 ? null : <ButtonPrimary icon="arrow_back" text={steps[currentStep - 1]} size="md" action={handleGoBack} />}</div>
          <div>
            {currentStep >= steps.length ? null : (
              <ButtonPrimary
                icon="arrow_forward"
                text={steps[currentStep + 1]}
                size="md-1"
                action={handleSubmit}
                loading={!validFirstName || !validLastName || !validTitle || !validEmail ? true : false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwnerInfo;
