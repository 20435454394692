/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { v4 as uuid } from "uuid";
import { BUSINESS_CATEGORY, PLANS } from "../../../../constant";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import {
	validateBusinessName,
	validateEmail,
	validateMessage,
	validatePhone,
	validateUrl,
} from "../../../../services/validation";
import {
	generateClassName,
	inArrayObject,
	getValueFromArrayOfObject,
} from "../../../../services/util";
import notify from "../../../../services/toast";
import CreatableSelect from "react-select/creatable";
import DefaultState from "../../../../components/default_state/DefaultState";

import useUserContext from "../../../../hooks/useUserContext";
import useScrollPage from "../../../../hooks/useScrollPage";
import SelectInput from "../../../../components/select_input/SelectInput";
import { Link } from "react-router-dom";

function NonprofitInfo({
	steps,
	currentStep,
	setCurrentStep,
	setListing,
	listing,
}) {
	useScrollPage();

	const {
		subscription: { product },
	} = useUserContext();


	const [loadSubCategory, setloadSubCategory] = React.useState([]);

	const [category, setCategory] = React.useState(
		listing?.businessInfo?.businessCategory || null
	);

	const [subCategory, setSubCategory] = React.useState(
		listing?.businessInfo?.businessSubCategory || null
	);

	const [website, setWebsite] = React.useState(
		listing?.businessInfo?.website || ""
	);
	const [facebook, setFacebook] = React.useState(
		listing?.businessInfo?.facebook || ""
	);
	const [linkedIn, setLinkedIn] = React.useState(
		listing?.businessInfo?.linkedIn || ""
	);
	const [instagram, setInstagram] = React.useState(
		listing?.businessInfo?.instagram || ""
	);
	const [businessName, setBusinessName] = React.useState(
		listing?.businessInfo?.businessName || ""
	);
	const [businessEmail, setBusinessEmail] = React.useState(
		listing?.businessInfo?.businessEmail || ""
	);
	const [businessPhone, setBusinessPhone] = React.useState(
		listing?.businessInfo?.businessPhone || ""
	);
	const [businessDescription, setBusinessDescription] = React.useState(
		listing?.businessInfo?.businessDescription || ""
	);

	const [validWesite, setValidWebsite] = React.useState(false);
	const [validFacebook, setValidFacebook] = React.useState(false);
	const [validLinkedIn, setValidLinkedIn] = React.useState(false);
	const [validInstagram, setValidInstagram] = React.useState(false);
	const [validBusinessName, setValidBusinessName] = React.useState(false);
	const [validBusinessEmail, setValidBusinessEmail] = React.useState(false);
	const [validBusinessPhone, setValidBusinessPhone] = React.useState(false);
	const [validBusinessDescription, setValidBusinessDescription] =
		React.useState(false);

	React.useEffect(() => {
		const result = validateBusinessName(businessName);
		setValidBusinessName(result);
	}, [businessName]);

	React.useEffect(() => {
		const result = validateEmail(businessEmail);
		setValidBusinessEmail(result);
	}, [businessEmail]);

	React.useEffect(() => {
		const result = validatePhone(businessPhone);
		setValidBusinessPhone(result);
	}, [businessPhone]);

	React.useEffect(() => {
		const result = validateMessage(businessDescription);
		setValidBusinessDescription(result);
	}, [businessDescription]);

	React.useEffect(() => {
		const result = validateUrl(facebook);
		setValidFacebook(result);
	}, [facebook]);

	React.useEffect(() => {
		const result = validateUrl(linkedIn);
		setValidLinkedIn(result);
	}, [linkedIn]);

	React.useEffect(() => {
		const result = validateUrl(instagram);
		setValidInstagram(result);
	}, [instagram]);

	React.useEffect(() => {
		const result = validateUrl(website);
		setValidWebsite(result);
	}, [website]);

	const handleCategoryChange = (event) => {
		const { value } = event;
		setCategory(value);
		setSubCategory(null);
		setloadSubCategory(BUSINESS_CATEGORY[value]["subCategory"]);
	
	};

	const handleSubCategoryChange = (event) => {
		const { value } = event;
		setSubCategory(value);
	};

	


	const generateBusinessCategory = () => {
		const businessCategory = [];

		for (const category in BUSINESS_CATEGORY) {
			businessCategory.push(BUSINESS_CATEGORY[category]["mainCategory"]);
		}
		return businessCategory;
	};

	const submitData = () => {
		if (businessEmail && !validateEmail(businessEmail)) {
			setValidBusinessEmail(false);
			notify(`Business email ${businessEmail} is not valid`, "error");
			return;
		}

		if (businessPhone && !validatePhone(businessPhone)) {
			setValidBusinessPhone(false);
			notify(`Business phone number ${businessPhone} is not valid`, "error");
			return;
		}

		if (businessDescription && !validateMessage(businessDescription)) {
			setValidBusinessDescription(false);
			notify("Business description can't exceed 2500 characters", "error");
			return;
		}

		if (website && !validateUrl(website)) {
			setValidWebsite(false);
			notify(`Business website ${website} is not valid`, "error");
			return;
		}

		if (facebook && !validateUrl(facebook)) {
			setValidFacebook(false);
			notify(`Business facebook url ${facebook} is not valid`, "error");
			return;
		}

		if (instagram && !validateUrl(instagram)) {
			setValidInstagram(false);
			notify(`Business instagram url ${instagram} is not valid`, "error");
			return;
		}

		if (linkedIn && !validateUrl(linkedIn)) {
			setValidLinkedIn(false);
			notify(`Business linkedIn url ${linkedIn} is not valid`, "error");
			return;
		}

		const payload = {
			businessName: businessName,
			businessEmail: businessEmail || null,
			businessPhone: businessPhone || null,
			businessCategory: category,
			businessSubCategory: subCategory,

			businessDescription: businessDescription || null,
			facebook: facebook || null,
			instagram: instagram || null,
			linkedIn: linkedIn || null,
			website: website || null,
		};

		setListing((prevState) => {
			return { ...prevState, businessInfo: payload };
		});
		setCurrentStep(currentStep + 1);
	};

	return (
		<div className="w-full bg-white overflow-hidden shadow rounded-lg">
			<div className="w-full px-4 py-5 sm:p-6 space-y-2">
				<h1 className="text-xl font-bold text-success-800">
					Nonprofit Information
				</h1>
				<p className="text-sm text-gray-400">
					Tell us about what you have to offer and how to contact your business.
				</p>
				<div className="w-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:items-center sm:gap-4">
					<div className="w-full">
						<div className="w-full flex justify-between items-center">
							<label
								htmlFor="business_name"
								className="block text-sm font-medium text-gray-700"
							>
								Nonprofit Name
							</label>
							<span className="w-2 h-2 rounded-full bg-red-500"></span>
						</div>
						<div className="mt-1">
							<input
								onChange={(event) => setBusinessName(event.target.value)}
								type="text"
								name="business_name"
								id="business_name"
								autoComplete="off"
								value={businessName}
								className={generateClassName(
									!validBusinessName && businessName
								)}
								placeholder="Giving Project"
								aria-describedby="name-optional"
							/>
						</div>
					</div>
					<div className="w-full">
						<div className="flex justify-between">
							<label
								htmlFor="business_email"
								className="block text-sm font-medium text-gray-700"
							>
								Nonprofit Email
							</label>
						</div>
						<div className="mt-1">
							<input
								onChange={(event) => setBusinessEmail(event.target.value)}
								type="email"
								name="business_email"
								id="business_email"
								autoComplete="off"
								value={businessEmail}
								className={generateClassName(
									!validBusinessEmail && businessEmail
								)}
								placeholder="company@domain.com"
								aria-describedby="email-optional"
							/>
						</div>
					</div>
				</div>
				<div className="w-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:items-center sm:gap-4">
					<div className="w-full">
						<div className="w-full flex justify-between items-center">
							<label
								htmlFor="category"
								className="block text-sm font-medium text-gray-700"
							>
								Nonprofit Category
							</label>
							<span className="w-2 h-2 rounded-full bg-red-500"></span>
						</div>
						<SelectInput
							options={generateBusinessCategory()}
							noOptionsMessage="No categories found"
							placeholder="Select a business category"
							defaultValue={() => {
								return category
									? {
											value: category,
											label:
												BUSINESS_CATEGORY[category]["mainCategory"]["label"],
									  }
									: null;
							}}
							setSelectedValue={handleCategoryChange}
						/>
					</div>
					<div className="w-full">
						<div className="w-full flex justify-between items-center">
							<label
								htmlFor="subCategory"
								className="block text-sm font-medium text-gray-700"
							>
								Business Sub Category
							</label>
							<span className="w-2 h-2 rounded-full bg-red-500"></span>
						</div>
						<SelectInput
							options={BUSINESS_CATEGORY[category]?.subCategory || []}
							noOptionsMessage="Select a business category"
							placeholder="Select a business category"
							defaultValue={() => {
								return subCategory
									? {
											value: subCategory,
											label: getValueFromArrayOfObject(
												BUSINESS_CATEGORY[category]["subCategory"],
												{ value: subCategory }
											),
									  }
									: null;
							}}
							setSelectedValue={handleSubCategoryChange}
						/>
					</div>
				</div>
				
				{["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? (
					<div className="w-full">
						<div className="flex justify-between">
							<label
								htmlFor="phone"
								className="block text-sm font-medium text-gray-700"
							>
							 Phone Number
							</label>
						</div>
						<div className="mt-1">
							<input
								onChange={(event) => setBusinessPhone(event.target.value)}
								type="tel"
								name="phone"
								id="phone"
								value={businessPhone}
								className={generateClassName(
									!validBusinessPhone && businessPhone
								)}
								placeholder="6147854582"
								aria-describedby="phone-optional"
							/>
						</div>
					</div>
				) : (
					<div className="w-full flex-col">
						<Link
							to="/dashboard/settings/subscription"
							className="text-primary-400 font-bold text-lg underline"
						>
							Upgrade your subscription to add a business phone number
						</Link>
					</div>
				)}
				<div className="w-full">
					<div className="w-full flex justify-between">
						<label
							htmlFor="description"
							className="block text-sm font-medium text-gray-700"
						>
						Description of Nonprofit
						</label>
					</div>
					<div className="mt-1">
						<textarea
							onChange={(event) => setBusinessDescription(event.target.value)}
							value={businessDescription}
							rows="4"
							name="description"
							id="description"
							className={generateClassName(
								!validBusinessDescription && businessDescription
							)}
						></textarea>
					</div>
				</div>
				{["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? (
					<div className="w-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:items-center sm:gap-4">
						<div className="w-full">
							<div className="w-full flex justify-between">
								<label
									htmlFor="website"
									className="block text-sm font-medium text-gray-700"
								>
									Website
								</label>
							</div>
							<div className="mt-1">
								<input
									onChange={(event) => setWebsite(event.target.value)}
									type="url"
									name="website"
									id="website"
									value={website}
									className={generateClassName(!validWesite && website)}
									placeholder="https://www.website.com"
									aria-describedby="website-optional"
								/>
							</div>
						</div>
						<div className="w-full">
							<div className="w-full flex justify-between">
								<label
									htmlFor="facebook"
									className="block text-sm font-medium text-gray-700"
								>
									Facebook
								</label>
							</div>
							<div className="mt-1">
								<input
									onChange={(event) => setFacebook(event.target.value)}
									type="url"
									name="facebook"
									id="facebook"
									value={facebook}
									className={generateClassName(!validFacebook && facebook)}
									placeholder="https://www.facebook.com"
									aria-describedby="facebook-optional"
								/>
							</div>
						</div>
						<div className="w-full">
							<div className="w-full flex justify-between">
								<label
									htmlFor="linkedin"
									className="block text-sm font-medium text-gray-700"
								>
									LinkedIn
								</label>
							</div>
							<div className="mt-1">
								<input
									onChange={(event) => setLinkedIn(event.target.value)}
									type="url"
									name="linkedin"
									id="linkedin"
									value={linkedIn}
									className={generateClassName(!validLinkedIn && linkedIn)}
									placeholder="https://www.linkedin.com"
									aria-describedby="linkedin-optional"
								/>
							</div>
						</div>
						<div className="w-full">
							<div className="w-full flex justify-between">
								<label
									htmlFor="instagram"
									className="block text-sm font-medium text-gray-700"
								>
									Instagram
								</label>
							</div>
							<div className="mt-1">
								<input
									onChange={(event) => setInstagram(event.target.value)}
									type="url"
									name="instagram"
									id="instagram"
									value={instagram}
									className={generateClassName(!validInstagram && instagram)}
									placeholder="https://www.instagram.com"
									aria-describedby="instagram-optional"
								/>
							</div>
						</div>
					</div>
				) : (
					<div className="w-full flex-col">
						<Link
							to="/dashboard/settings/subscription"
							className="text-primary-400 font-bold text-lg underline"
						>
							Upgrade your subscription to add a website and social media
							accounts
						</Link>
					</div>
				)}
				<div className="w-full flex justify-between items-center">
					<div>
						{currentStep - 1 <= 0 ? null : (
							<ButtonPrimary
								icon="arrow_back"
								text={steps[currentStep - 1]}
								size="md"
								action={() => setCurrentStep(currentStep - 1)}
							/>
						)}
					</div>
					<div>
						{currentStep + 1 >= steps.length ? null : (
							<ButtonPrimary
								text={steps[currentStep + 1]}
								size="md-1"
								loading={
									!validBusinessName || !category || !subCategory ? true : false
								}
								action={submitData}
								icon="arrow_forward"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default NonprofitInfo;
