/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import PlanCard from "../../../components/plan_card/PlanCard";
import { MAGIC_NUMBERS, PLANS } from "../../../constant";
import useUserContext from "./../../../hooks/useUserContext";
import ButtonPrimary from "./../../../components/button/ButtonPrimary";
import cancelSubscription from "./cancelSubscription";
import createPortalSession from "./createPortSession";
import Prompt from "../../../components/prompt/Prompt";
import { showPrompt } from "../../../services/util";
import DefaultState from "./../../../components/default_state/DefaultState";
import notify from "./../../../services/toast";
import useScrollPage from "../../../hooks/useScrollPage";
import UpgradeSubscription from "../../../components/upgrade_subscription/UpgradeSubscription";
import useTitle from "../../../hooks/useTitle";

function Subscription() {
  useScrollPage();
  useTitle("COhatch+ Manage Subscription");

  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });
  const promptRef = React.useRef(null);
  const [cancelling, setCancelling] = React.useState(false);
  const [creatingPortal, setCreatingPortal] = React.useState(false);

  const {
    subscription: { subscriptionId, customerId, amount, amountDue, product, subscriptionStatus, subscriptionEndDate, quantity, paymentMethod },
    userState: { accessToken, officerndId },
  } = useUserContext();

  const handleCancelSubscription = async () => {
    try {
      setCancelling(true);
      const response = await cancelSubscription({ subscriptionId }, accessToken);
      if (response.status === "success") {
        window.location.replace("/");
      } else {
        let displayMessage = "";
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Ooh no", "Something went wrong. Please try again later.", "error");
    } finally {
      setCancelling(false);
    }
  };

  const handleCustomerPortal = async () => {
    try {
      setCreatingPortal(true);
      const response = await createPortalSession({ customerId }, accessToken);
      if (response.status === "success") {
        const { portalUrl } = response.data;
        window.location.replace(portalUrl);
      } else {
        let displayMessage = "";
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Ooh no", "Something went wrong. Please try again later.", "error");
    } finally {
      setCreatingPortal(false);
    }
  };

  const handleShowPrompt = () => {
    showPrompt(
      "Cancel Subscription",
      "Are you sure you want to cancel your subscription? Cancelling your subscription immediately terminates the subscription, revoking all your privileges to subscribed product. This action can't be undone and will require a new subscription if your wish to continue using COhatch+ features.",
      promptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  return (
    <>
      {officerndId ? (
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <DefaultState icon="card_membership" heading="COHATCH MEMBERSHIP" description="COhatch members have full access to this platform for free." />
        </div>
      ) : (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
            <div className="w-full">
              <h1 className="text-lg text-success-800 font-bold">Manage Your Subscription</h1>
              {subscriptionId ? (
                <div className="w-full flex justify-end p-3">
                  <button
                    onClick={handleShowPrompt}
                    type="button"
                    className="flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all ease-linear duration-500">
                    <span className="material-icons-outlined text-white text-md">delete</span>
                    Cancel Subscription
                  </button>
                </div>
              ) : null}
              <div className="w-full space-y-4">
                <PlanCard
                  action={handleCustomerPortal}
                  subscriptionId={subscriptionId}
                  creatingPortal={creatingPortal}
                  planDetails={{ planName: PLANS[product], amount: amount, quantity: quantity, status: subscriptionStatus, nextBillingCycle: subscriptionEndDate, nextBillingCycleAmount: amountDue }}
                />
              </div>
              {subscriptionId ? (
                <div className="mt-10 w-full bg-white rounded-md shadow-md p-3">
                  <div className="w-full flex justify-between p-3">
                    <div className="flex flex-col">
                      <p className="text-lg text-gray-400 capitalize">{paymentMethod?.type}</p>
                    </div>
                    <div className="flex flex-col gap-5">
                      <div className="w-full flex gap-2">
                        <p className="text-lg text-gray-400 capitalize">{paymentMethod?.brand}</p>
                        <p className="text-lg text-success-800 font-bold">...{paymentMethod?.last4}</p>
                      </div>
                    </div>
                    <p className="text-lg text-gray-400">
                      {paymentMethod?.expMonth.toString().padStart(MAGIC_NUMBERS.TWO, "0")}/{paymentMethod?.expYear}
                    </p>
                  </div>
                  <div className="mt-5 w-fit">
                    <ButtonPrimary text={creatingPortal ? "Please wait..." : "Update Payment Method"} size="md" action={handleCustomerPortal} loading={creatingPortal ? true : false} />
                    <p className="mt-2 text-sm text-gray-400">You will be temporarily redirected to Stripe to update your payment method.</p>
                  </div>
                </div>
              ) : (
                <UpgradeSubscription />
              )}
            </div>
          </div>
          <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={handleCancelSubscription} isExecuting={cancelling} />
        </div>
      )}
    </>
  );
}

export default Subscription;
