/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import useScrollPage from "../../../hooks/useScrollPage";
//import WorkshopDetails from "../../pages/workshops/workshop_details/WorkshopDetailsEventsAttending";
import { Link, useNavigate } from "react-router-dom";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
function Rsvp({
  data,
  reload,
  event
}) {
    useScrollPage();
    const dt = new Date(data?.event?.eventDate);

    // console.log(data);
    const getStatus = (status) => {
      if (status === 0) {
        return "Maybe";
      }
      if (status === 1) {
        return "Attending";
      }
      if (status === 2) {
        return "Not Attending";
      }
      return "";
    };
const navigate = useNavigate();

     function handleClick() {

		navigate(`/event_details/${data.eventId}`);
	}

    return (
       
        <div className="lg:flex shadow rounded-lg border  border-gray-400">
              <div className="bg-primary-400 rounded-lg lg:w-2/12 py-4 block h-full shadow-inner">
                <div className="text-center tracking-wide">
                  <div className="text-white font-normal text-2xl">{months[dt.getMonth()]}</div>
                  <div className="text-white font-bold text-4xl ">{dt.getDate()}</div>
                  <div className="text-white font-normal text-2xl">{dt.getFullYear()}</div>
                </div>
              </div>
              <div className="w-full  lg:w-11/12 xl:w-full px-1 bg-white py-5 lg:px-2 lg:py-2 tracking-wide">
                <div className="flex flex-row lg:justify-start justify-center">
                  <div className="text-gray-700 font-medium text-sm text-center lg:text-left px-2">
                    <i className="far fa-clock"></i> {dt.getHours() < 10 ? `0${dt.getHours()}` : dt.getHours()}:{dt.getMinutes() < 10 ? `0${dt.getMinutes()}` : dt.getMinutes()}
                  </div>
                  <div className="text-gray-700 font-medium text-sm text-center lg:text-left px-2">
                    Organizer : {data?.event?.eventOwnerFirstName} {data?.event?.eventOwnerLastName}
                  </div>
                </div>
                <div className="font-semibold text-gray-800 text-xl text-center lg:text-left px-2">
                  {data?.event?.eventName}
                </div>
        
                <div className="text-gray-600 font-medium text-sm pt-1 text-center lg:text-left px-2">
                  {data?.event?.eventLocation.streetAddress}, {data?.event?.eventLocation.city}, {data?.event?.eventLocation.state}, {data?.event?.eventLocation.country}
                </div>

                <span className="tracking-wider text-gray-600 bg-gray-200 px-2 text-sm rounded leading-loose mx-2 font-semibold capitalize">
                  {data.status}
                </span>
              </div>

              <div className="flex flex-row items-center w-full lg:w-1/3 bg-white lg:justify-end justify-center px-2 py-4 lg:px-0">
               

            <button 
            onClick={handleClick}
            className="group flex">
                <div className="text-sm font-semibold leading-6 text-primary-400 hover:text-primary-100">
                    View Event <span aria-hidden="true">&rarr;</span>
                </div>
              </button>
                                                          
               
              </div>
            </div>
);
}

export default Rsvp;

