/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import VisitorReview from "../../../components/review/VisitorReviewItem";
import DefaultState from "../../../components/default_state/DefaultState";
import Spinner from "../../../components/spinner/Spinner";
import useUserContext from "./../../../hooks/useUserContext";
import getVisitorReviews from "../../marketplace/visitor_review/getVisitorReviews";
import notify from "../../../services/toast";
import Pagination from "../../../components/pagination/Pagination";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function VisitorReviews() {
  useScrollPage();
  useTitle("Visitors Review");
  const {
    userState: { accessToken },
  } = useUserContext();
  const [reviews, setReviews] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 5 });

  const getAllReviews = React.useCallback(async (accessToken, page, limit) => {
    try {
      setFetching(true);
      const response = await getVisitorReviews(accessToken, page, limit, "property");
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setReviews(results);
      } else {
        notify("An error has occurred while fetching your reviews", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  }, []);

  const handlePrevFetch = () => {
    getAllReviews(accessToken, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = () => {
    getAllReviews(accessToken, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    getAllReviews(accessToken, 0, 5);
  }, []);
  return (
    <>
      {!fetching ? (
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="w-full mt-10 flex items-center justify-center gap-5 flex-wrap">
            <ul role="list" className="divide-y divide-gray-200 w-full">
              {reviews.length ? (
                reviews.map((review, index) => <VisitorReview key={index} review={review} />)
              ) : (
                <DefaultState icon="reviews" heading="No Visitor Reviews" description="Reviews left by visitors on your listings will appear here" />
              )}
            </ul>
            <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
          </div>
          {/* <section className="pt-12">
              <Footer />
              </section> */}
        </div>
      ) : (
        <Spinner displayText="Fetching..." />
        
      )}
    </>
  );
}

export default VisitorReviews;
