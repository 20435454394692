/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import MarketplaceCategories from "../../../components/marketplace_categories/MarketplaceCategories";
import getListings from "./getListings";
import { useNavigate } from "react-router-dom";
import ListingCard from "../../../components/listing_card/ListingCard";
import Spinner from "../../../components/spinner/Spinner";
import DefaultState from "../../../components/default_state/DefaultState";
import Pagination from "../../../components/pagination/Pagination";
import useUserContext from "../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import { debounce } from "../../../services/util";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import MarketplaceCard from "../../../components/marketplace_card/MarketplaceCard";


function MarketPlaceFeed() {
  useScrollPage();
  useTitle("All listings");

  const navigate = useNavigate();
  const { userState } = useUserContext();
  const [listings, setlistings] = useState([]);
  const [isListingLoading, setIsListingLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 12 });
  const [keyword, setKeyword] = useState("");

  const fetchAllListings = async (accessToken, offset, limit) => {
    setIsListingLoading(true);
    console.log(accessToken, offset, limit);
    const response = await getListings(accessToken, offset, limit);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      console.log(results);
      setPagination((prevState) => {
        return { ...prevState, limit, page: page, remaining, total };
      });
      setlistings(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsListingLoading(false);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    fetchAllListings(userState.accessToken, 0, query);
    setKeyword(query);
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchAllListings((userState.accessToken, pagination.page - 1, pagination.limit));
    // await fetchAllListings(userState.accessToken, pagination.page - 1, keyword);

  };

  const handleNextFetch = async () => {
    await fetchAllListings(userState.accessToken, pagination.page + 1, pagination.limit);
    // await fetchAllListings(userState.accessToken, pagination.page + 1, keyword);
  };

  const reloadlistings = () => {
    setPagination({ limit: 12, total: 0, page: 0, remaining: 0 });
    fetchAllListings(userState.accessToken, 0, pagination.limit);
    // fetchAllListings(userState.accessToken, 0, keyword);
  };

  useEffect(() => {
    fetchAllListings(userState.accessToken, 0, pagination.limit);
    // fetchAllListings(userState.accessToken, 0, "");
  }, []);

  return (
    <>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-2">
        <div className="relative mt-2 max-w-full h-full mx-auto ">
          <div className="w-full h-full p-4 space-y-8">
            <MarketplaceCategories />
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="relative px-4 pt-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            {/* <span className="ml-3 text-4xl font-bold font-bebas text-primary-400 sm:leading-9 sm:truncate">Book Free Life Events</span> */}
            <span className="bg-white pr-3 text-4xl text-primary-400 font-bebas font-bold">
              All Listings
            </span>
          </div>
        </div>
        {!isListingLoading && !listings.length && (
          <DefaultState
            icon="corporate_fare"
            heading="No listings Found"
            description="All listings created by members will appear here"
            action={() => navigate("/dashboard/marketplace/add_listing")}
            actionText="Create your own listing"
          />
        )}
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          <>{isListingLoading ? <Spinner displayText="Loading..." /> : listings.map((listing) => <MarketplaceCard key={listing._id} listing={listing} />)}</>
        </div>
        <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      </div>
    </>
  );
}

export default MarketPlaceFeed;
