/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React, { useEffect, useState } from "react";
import useScrollPage from "../../../hooks/useScrollPage";
import WorkshopRsvpList from "../../../components/workshop_rsvp_list/WorkshopRsvpList";
import getMyDriveEvents from "./getMyDriveEvents";
import notify from "../../../services/toast";
import { MAGIC_NUMBERS } from "../../../constant";
import { FaWindowClose, FaPencilAlt, FaTrashAlt} from "react-icons/fa";
import Spinner from "../../../components/spinner/Spinner";
import useUserContext from "../../../hooks/useUserContext";
import EditWorkshopForm from "../../../components/workshop_form/EditWorkshopForm";
import WorkshopForm from "../../../components/workshop_form/WorkshopForm";
import updateImpactEvent from "./updateImpactEvent.js";
import Prompt from "../../../components/prompt/Prompt";
import { showPrompt } from "../../../services/util";
import deleteEvent from "../volunteer/deleteEvent";
import EditDriveForm from "../../../components/philanthropy/EditDriveForm";

import DefaultState from "../../../components/default_state/DefaultState";
import { Link, useNavigate } from "react-router-dom";


function MyDrives({ event, onReload }) {
  useScrollPage();
  const promptRef = React.useRef(null);
  const { userState } = useUserContext();
  
  const {
    userState: { accessToken },
  } = useUserContext();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deletingEvent, setDeletingEvent] = React.useState(false);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });
  const [editEvent, setEditEvent] = React.useState({
    eventName: "",
    eventDescription: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    eventCategory: "",
    eventLocation: {
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
    },
    eventCOhatchMarket: "",
    eventCOhatchLocation: "",
    eventCOhatchMeetingRoom: "",
    eventImage: null,
    eventOwnerImage: null,
    eventOwnerFirstName: "",
    eventOwnerLastName: "",
    aboutOrganization: "",
  
  });
  

  

  const getMyEvents = async (accessToken) => {
    console.log("Trying to getMyEvents.");
    try {
      setFetching(true);
      const response = await getMyDriveEvents(accessToken, 0, 0, true);
      if (response.status === "success") {
        console.log("getMyEvents a success.");
        console.log(response.data);
        setEvents(response.data);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };
  const handleReload = async () => {
    await getMyEvents(accessToken);
  };
  

  const handleDeleteShowPrompt = () => {
    showPrompt(
      "Delete Event",
      "Are you sure you want to delete this event?",
      promptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const handleDelete = async (close) => {
    try {

      const body = {
        eventName: currentEvent.eventName,
        bookingUserId: currentEvent.userId,
      };
      
      console.log("trying to delete");
      console.log(event);
      setDeletingEvent(true);
      const response = await deleteEvent(userState.accessToken, currentEvent._id, body);
      if (response.status === "success") {
        notify("Your delete event request was successful.", "info");
        getMyEvents(accessToken);
        if (onReload) {
          onReload();
        }
        promptRef.current.closePrompt();
        if (close) {
          close();
        }
      } else {
        let displayMessage = "";
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      console.log(error);
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setDeletingEvent(false);
    }
  };


  const handleSubmit = async (editEvent, eventId) => {
    console.log(editEvent);
    console.log(eventId);
    try {
      console.log("entered try block for handle submit");
      setSubmitting(true);
      // const dt = new Date(parseInt(editEvent.eventDate.substring(0, 4), 10), parseInt(editEvent.eventDate.substring(5, 7), 10) - 1, parseInt(editEvent.eventDate.substring(8, 10), 10), parseInt(editEvent.eventTime.substring(0, 2), 10), parseInt(editEvent.eventTime.substring(3, 5), 10), 0);
      const response = await updateImpactEvent(accessToken, {
        ...editEvent,
      },
      eventId);
      if (response.status === "success") {
        notify("Your event was successfully submitted.", "info");
        handleReload();
      } else {
        setSubmitting(false);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  useEffect(() => {
    getMyEvents(accessToken);
  }, []);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        
        
        
        {fetching ? (
            <div className="text-center w-full relative">
              <Spinner displayText="Fetching..." />
            </div>
          ) : events.length ? (
            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          {events.map((event) => (
            <div key={event._id} className="group relative">
              <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              
              { event.approvalStatus === "approved" ?
                  <Link to={`/workshop_details/${event._id}`}>
                    <img className="rounded-t-lg object-cover object-center w-full" 
                      src={event.eventImage?.small?.url}
                      alt="event"
                    />
                  </Link>
                      : 
                      <Link to={""}>
                      <img className="rounded-t-lg object-cover object-center w-full cursor-default" 
                        src={event.eventImage?.small?.url}
                        alt="event"
                      />
                    </Link>
                      
                    }
                <div className="p-5">
                  <a href="#">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {event.eventName}
                      { event.approvalStatus === "pending" ?
                        <span className="pl-5">(Pending)</span> :
                        event.approvalStatus === "rejected" ?
                        <span className="pl-5">(Rejected)</span> :
                        null
                      }
                    </h5>
                  </a>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{event.eventDescription}</p>
                  <div className="flex space-x-6">
                    

                    <span className="relative flex items-center justify-center text-sm">
                      { event.approvalStatus !== "rejected" ?
                      <EditDriveForm 
                      button={<button type="button" className="relative flex items-center justify-center px-3 py-2 text-sm font-medium text-center text-white bg-primary-400 rounded-lg hover:bg-success-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-success-700 dark:hover:bg-success-800 dark:focus:ring-success-800">
                            
                           EDIT
                            <span className="sr-only">Edit</span>
                          </button>}
                          title="Event Form"
                          event={event}
                          setEvent={setEditEvent}
                          handleSubmit={handleSubmit}
                          
                          />
                      : null
                    }
                    </span>

                    <span className="relative flex items-center justify-center text-sm">
                      <button type="button" 
                        onClick={() => {
                          setCurrentEvent(event);
                          handleDeleteShowPrompt();
                        }} 
                        className="relative flex items-center justify-center px-3 py-2 text-sm font-medium text-center text-white bg-danger-500 rounded-lg hover:bg-success-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-success-700 dark:hover:bg-success-800 dark:focus:ring-success-800"
                      >
                        DELETE
                        <span className="sr-only">Delete</span>
                      </button>
                    </span>
                  </div>

                  { event.approvalStatus === "approved" ?
                    <div className="mt-6 border-t border-gray-900/5 pt-6">
                          <Link to={`/philanthropy/event_details/${event._id}`}>
                                  <div className="text-md relative flex items-center justify-center font-bold leading-6 text-success-800 hover:text-primary-100">
                                      View Event <span aria-hidden="true">&rarr;</span>
                                  </div>
                          </Link>
                    
                  </div>
                      : null
                    }
              </div>
              {/* ### */}
                {/* <button className="button" onClick={handleEdit} >
                  <FaPencilAlt className="flex-shrink-0 h-6 w-6 pb-2" aria-hidden="true" alt="Edit"/>
                  
                </button> */}
                {/* ### */}
            </div>
          </div>))}
        </div>
          ) : (
            <DefaultState icon="block" heading="No Events" description="You have not created any events." />
          )}
        

        

      </div>
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={handleDelete} />
    </div>





);
}

export default MyDrives;

