/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import React from "react";
import Logo from "../../../components/logo/Logo";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FaWindowClose } from "react-icons/fa";
import { MdDateRange, MdLocationPin, MdOutlineAppRegistration } from "react-icons/md";
import Popup from "reactjs-popup";
import EventRsvpForm from "../../../components/workshop_rsvp_form/EventRsvpForm";
import { FaFacebook, FaInstagramSquare, FaTwitter, FaGlobe } from "react-icons/fa";
import useUserContext from "../../../hooks/useUserContext";
import Prompt from "../../../components/prompt/Prompt";
import { format } from "date-fns";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import Spinner from "../../../components/spinner/Spinner";
import getEvent from "./getEvent";





function timeFormatter(time) {
  const hour = Number(time.slice(0, time.indexOf(":")));
  const minutes = time.slice(time.indexOf(":"));

  if (hour === 12) {
    return hour + minutes + " PM";
  } else if (hour === 0) {
    return hour + 12 + minutes + " AM";
  }

  if (hour > 12) {
    return hour - 12 + minutes + " PM";
  } else {
    return hour + minutes + " AM";
  }
}

const footerNavigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/events/976408409223088",
      icon: FaFacebook
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/cohatchcommunity/",
      icon: FaInstagramSquare
    },
    {
      name: "Twitter",
      href: "https://twitter.com/cohatchlife",
      icon: FaTwitter
    },
    {
      name: "Website",
      href: "https://www.cohatch.com/",
      icon: FaGlobe
    },

  ],
};


export default function WorkshopDetails() {
  const { userState } = useUserContext();
  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });
  const navigate = useNavigate();
  const params = useParams();
  const { eventId } = params;
  const [event, setEvent] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const {
    userState: { accessToken },
  } = useUserContext();

  const fetchEvent = async () => {
    console.log("entered fetch");
    try {
      setFetching(true);
      const response = await getEvent(accessToken, eventId);
      if (response.status === "success") {
        setFetching(false);
        const { data } = response;
        console.log(data[0]);
        setEvent(data[0]);
      } else {
        setFetching(false);
        navigate("/error");
      }
    } catch (error) {
      setFetching(false);
      navigate("/error");
    }
  };

  React.useEffect(() => {
    fetchEvent();
  }, []);

  return (
    <>

      {event === null || fetching ? (
        <Spinner displayText="Fetching..." />
      ) : (
        <>
          <div className="sticky top-0 z-10 w-full bg-white shadow-md flex justify-start p-5">
            <div className="w-full flex justify-between items-center gap-3 text-primary-400 hover:text-primary-600 transition-all duration-500">
              <Logo color="green" />
              <div className="flex justify-center gap-3">
                <Link to="/dashboard/workshop">All Events</Link>
                <span className="material-icons-outlined">arrow_forward</span>
              </div>
            </div>
          </div>
          <main>
            {/* Feature section with screenshot */}
            <div className="relative max-w-6xl mx-auto bg-white rounded-t-md h-[400px]">
              <img
                src={event && event.eventImage?.large ? event.eventImage.large.url : "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"}
                className="w-full h-full object-cover"
              />
            </div>

            {/* Feature section with grid */}
            <div className="relative bg-white py-16 sm:py-24 lg:py-32">
              <div className="mx-auto max-w-md px-4  sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <p className="mt-2 text-3xl font-bold text-center tracking-tight text-gray-900 sm:text-4xl">
                  {event.eventName}
                </p>
                <p className="mx-auto mt-5 text-xl text-gray-500">
                  {event.eventDescription}
                </p>


                {event?.eventLink ?
                  <section aria-labelledby="contact-info-heading">
                    <a href={event?.eventLink ? (event?.eventLink.indexOf("http") > -1 ? event?.eventLink : `https://${event?.eventLink}`) : "#"} target="_blank" rel="noreferrer">
                      <div className="mt-2">
                        <button
                          className=" w-full text-center gap-x-2 rounded-md bg-danger-500 px-4.5 py-3.5 text-lg font-semibold text-white shadow-sm hover:bg-danger-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                          target="_blank">
                          Virtual Event Link: <span className="text-center underline text-white">{event.eventLink}</span>
                        </button>
                      </div>
                    </a>
                  </section>
                  : null
                }
                {/* Virtual Event Link: <span className="text-center underline text-white">{event?.eventLink || ""}</span> */}


                <div className="mt-12 text-center">
                  <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="pt-6">
                      <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center rounded-xl bg-success-800 p-3 shadow-lg">
                              <MdDateRange className="h-8 w-8 text-white" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Event Date & Time</h3>
                          <p className="mt-5 text-base leading-7 text-gray-600">{format(new Date(event.eventDate), "MM/dd/yy")}, {timeFormatter(event.startTime)} – {timeFormatter(event.endTime)} EST</p>
                        </div>
                      </div>
                    </div>

                    <div className="pt-6">
                      <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center rounded-xl bg-primary-400 p-3 shadow-lg">
                              <MdLocationPin className="h-8 w-8 text-white" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Event Address</h3>

                          {event?.eventLocation.streetAddress ?
                            <p className="mt-5 text-base leading-7 text-gray-600">{event.eventLocation.streetAddress}, {event.eventLocation.city}, {event.eventLocation.state} - {event.eventLocation.zip}</p>
                            : <p className="mt-5 text-base leading-7 text-gray-600">Location Not Available</p>
                          }
                          {event?.eventCOhatchMarket ?
                            <p className="mt-2 text-base leading-7 text-gray-600">{event.eventCOhatchMeetingRoom} - {event.eventCOhatchLocation}, {event.eventCOhatchMarket}</p>
                            : null
                          }
                        </div>
                      </div>
                    </div>

                    <div className="pt-6">
                      <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center rounded-xl bg-danger-500 p-3 shadow-lg">
                              <MdOutlineAppRegistration className="h-8 w-8 text-white" />
                            </span>
                          </div>
                          <h3 className="mt-7 text-lg font-semibold leading-8 tracking-tight text-gray-900">RSVP</h3>
                        </div>
                        {event?.userId !== userState.id && <EventRsvpForm
                          button={<button
                            type="button"
                            className="mt-4 ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                          >
                            {event.userRSVP?.length !== 0 ? "Edit RSVP" : "RSVP Here"}
                          </button>}
                          title="Workshops Form"
                          event={event}
                        />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-success-800 to-primary-400 pb-16 lg:relative lg:z-10 lg:pb-0">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
                <div className="relative lg:-my-8">
                  <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                  <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                    <div className="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                      <img
                        className="object-event lg:h-full lg:w-full"
                        src={event.eventOwnerImage?.small?.url || "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                    <blockquote>
                      <div>
                        <p className="mt-6 text-2xl font-medium text-white">
                          About the Event Organizer
                        </p>
                      </div>
                      <footer className="mt-6">
                        <p className="text-base font-medium text-white">{event.eventOwnerFirstName} {event.eventOwnerLastName}</p>
                        <p className="text-base font-medium text-cyan-100">{event.aboutOrganization}</p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
            <p className="mt-6 text-2xl leading-8 text-center font-bebas text-success-800">Add Event to Calendar</p>
            <div className="mt-4 flex justify-center">

              <AddToCalendarButton
                name={event.eventName}
                options={["Apple", "Google", "iCal", "Outlook.com"]}
                location={event.eventLink}
                startDate="2023-12-31"
                endDate="2023-12-31"
                startTime={event.startTime}
                endTime={event.endTime}
                buttonsList
                hideTextLabelButton
                buttonStyle="round"
                lightMode="bodyScheme"
              // ... other props as needed
              ></AddToCalendarButton>
            </div>
            <p className="mt-6 text-2xl leading-8 text-center font-bebas text-success-800">Share on social media</p>
            <div className="mt-4 flex justify-center space-x-4">
              <LinkedinShareButton
                url={`https://cohatchplus.com/workshop_details/${eventId}`}
                media={event.eventOwnerImage}
                quote={"COhatch Event"}
                hashtag="#muo"
                title={event.eventName}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <FacebookShareButton
                url={`https://cohatchplus.com/workshop_details/${eventId}`}
                media={event.eventOwnerImage}
                quote={"COhatch Event"}
                hashtag="#COhatchplus"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://cohatchplus.com/workshop_details/${eventId}`}
                media={event.eventOwnerImage}
                quote={"COhatch Event"}
                hashtag="#muo"
                title={event.eventName}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>

          </div>
          <Prompt
            header={displayPromptMessage.heading}
            body={displayPromptMessage.body}
            ref={promptRef}
          />
        </>
      )}
    </>
  );
}
