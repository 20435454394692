/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function submitRegistrationForm(payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/email/available/${payload}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET");
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default submitRegistrationForm;
