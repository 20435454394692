/* eslint-disable react/prop-types */
/* eslint-disable require-await */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import DefaultState from "../../../components/default_state/DefaultState";
import AddMemberModal from "../../../components/add_member_modal/AddMemberModal";
import useUserContext from "../../../hooks/useUserContext";
import createNewGroup from "./createNewGroup";
import {
	validateGroupName,
	validateMessage,
} from "../../../services/validation";
import { generateClassName } from "../../../services/util";
import notify from "../../../services/toast";
import { MAGIC_NUMBERS } from "../../../constant";
import { MdLocalSee, MdClear } from "react-icons/md";
import FileUploader from "../../../components/file_uploader/FileUploader";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import PickTemplate from "../../../components/pick_template/PickTemplate";
import useWebsocket from "../../../hooks/useWebsocket";

const logoCriteria = {
	requiredSize: 5e6,
	requiredDimensions: { width: 400, height: 400 },
};
const coverPhotoCriteria = {
	requiredSize: 6e6,
	requiredDimensions: { width: 1000, height: 800 },
};

function PhotoDisplay({ callback }) {
	return (
		<MdLocalSee
			onClick={callback}
			className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
		/>
	);
}

function Template({ callback }) {
	return (
		<div
			onClick={callback}
			className="absolute top-[80%] right-10 mt-5 sm:mt-0"
		>
			<ButtonPrimary text="Select a template" size="md" />
		</div>
	);
}

function CreateGroup() {
	useScrollPage();
	useTitle("Create Group");

	const { userState } = useUserContext();
	const socket = React.useRef(useWebsocket(userState.accessToken));
	const navigate = useNavigate();
	const [submitting, setSubmitting] = React.useState(false);

	const [groupName, setName] = React.useState("");
	const [validName, setValidName] = React.useState(false);

	const [description, setDescription] = React.useState("");
	const [validDescription, setValidDescription] = React.useState(false);

	const [rules, setRules] = React.useState("");
	const [validRules, setValidRules] = React.useState(false);

	const [type, setType] = React.useState("Public");

	const [logos, setLogos] = React.useState(null);
	const [coverImages, setCoverImages] = React.useState(null);
	const [selectedMembers, setSelectedMembers] = React.useState([]);
	const [showModal, setShowModal] = React.useState(false);

	React.useEffect(() => {
		const result = validateGroupName(groupName);
		setValidName(result);
	}, [groupName]);

	React.useEffect(() => {
		const result = validateMessage(description);
		setValidDescription(result);
	}, [description]);

	React.useEffect(() => {
		const result = validateMessage(rules);
		setValidRules(result);
	}, [rules]);

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);
		try {
			if (!validName) {
				notify("One or more fields are invalid. Check and try again");
				return;
			}
			const payload = {
				// eslint-disable-next-line id-blacklist
				name: groupName,
				description,
				rules,
				type,
				logos,
				coverImages,
				members: selectedMembers,
			};
			const response = await createNewGroup(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmitting(false);
				setName("");
				setDescription("");
				setRules("");
				if (selectedMembers.length) {
					socket.current.emit("group_invitation", {
						recipientId: selectedMembers
							.filter((member) => member._id !== null)
							.map((member) => member._id),
					});
				}
				notify(`${groupName} group created successfully`, "success");
				navigate("/dashboard/neighborhood");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later", "error");
		}
	};

	const handleLogoUpload = (result) => {
		setLogos(result);
	};

	const handleCoverImagesUpload = (result) => {
		setCoverImages(result);
	};

	const handleDeleteMember = (value) => {
		const newMembers = [...selectedMembers];
		const idx = newMembers.findIndex(
			(mem) =>
				mem.emailAddress.toLowerCase() === value.emailAddress.toLowerCase()
		);
		if (idx === -1) {
			newMembers.push(value);
		} else {
			newMembers.splice(idx, 1);
		}
		setSelectedMembers(newMembers);
	};

	return (
		<div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
			<div className="relative mt-10 max-w-4xl h-full mx-auto bg-white shadow rounded-lg">
				<div
					className="w-full flex justify-end h-96 relative bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${
							coverImages?.large
								? coverImages.large.url
								: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_2.png"
						})`,
					}}
				>
					<div className="absolute top-0 right-0 w-[120px] h-[120px] flex items-center">
						<FileUploader
							fileType="image/*"
							isMultiple={false}
							validationCriteria={coverPhotoCriteria}
							DisplayIcon={PhotoDisplay}
							accessToken={userState.accessToken}
							folder="group_photos"
							description="1000 X 800 6MB Cover Photo"
							numberOfFiles={1}
							callback={handleCoverImagesUpload}
						/>
					</div>

					<PickTemplate
						DisplayIcon={Template}
						className=""
						setBackground={setCoverImages}
						setLogo={setLogos}
					/>
				</div>
				<div
					className="absolute top-40 left-10 w-[150px] h-[150px] flex items-center justify-center border-4 border-primary-400 border-solid bg-center bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${
							logos?.small
								? logos.small.url
								: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"
						})`,
					}}
				>
					<FileUploader
						fileType="image/*"
						isMultiple={false}
						validationCriteria={logoCriteria}
						DisplayIcon={PhotoDisplay}
						accessToken={userState.accessToken}
						folder="group_photos"
						description="400 X 400 5MB Logo"
						numberOfFiles={1}
						callback={handleLogoUpload}
					/>
				</div>
				<div
					className="w-full h-full bg-white p-10 space-y-8"
					onSubmit={handleFormSubmit}
				>
					<div className="w-full">
						<div className="w-full flex justify-between items-center">
							<label
								htmlFor="name"
								className="block text-sm font-medium text-gray-700"
							>
								Group Name
							</label>
							<span
								className="w-2 h-2 rounded-full bg-red-500"
								id="phone-optional"
							></span>
						</div>
						<div className="mt-1">
							<input
								maxLength={40}
								type="text"
								name="name"
								id="name"
								value={groupName}
								onChange={(event) => setName(event.target.value)}
								className={generateClassName(!validName && groupName)}
								placeholder="Technology Group"
								aria-describedby="name-optional"
							/>
						</div>
					</div>

					<div className="w-full">
						<div className="w-full flex justify-between">
							<label
								htmlFor="comment"
								className="block text-sm font-medium text-gray-700"
							>
								Group Description
							</label>
						</div>
						<div className="mt-1">
							<textarea
								rows="4"
								name="comment"
								value={description}
								onChange={(event) => setDescription(event.target.value)}
								className={generateClassName(!validDescription && description)}
							></textarea>
						</div>
					</div>

					<div>
						<label className="text-base font-medium text-gray-900">
							Group Privacy
						</label>
						<p className="text-sm leading-5 text-gray-500">
							Private groups requires member&apos;s request be approved by the
							administrator
						</p>
						<fieldset className="mt-4">
							<legend className="sr-only">Group Privacy</legend>
							<div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
								<div className="flex items-center">
									<input
										id="public"
										name="privacy"
										type="radio"
										value="Public"
										checked={type === "Public"}
										onChange={(event) => setType(event.target.value)}
										className="focus:ring-primary-500 h-4 w-4 text-primary-400 border-gray-300"
									/>
									<label
										htmlFor="public"
										className="ml-3 block text-sm font-medium text-gray-700"
									>
										Public
									</label>
								</div>

								<div className="flex items-center">
									<input
										id="private"
										name="privacy"
										type="radio"
										value="Private"
										checked={type === "Private"}
										onChange={(event) => setType(event.target.value)}
										className="focus:ring-primary-500 h-4 w-4 text-primary-400 border-gray-300"
									/>
									<label
										htmlFor="private"
										className="ml-3 block text-sm font-medium text-gray-700"
									>
										Private
									</label>
								</div>
							</div>
						</fieldset>
					</div>

					<div className="w-full">
						<div className="w-full flex justify-between">
							<label
								htmlFor="comment"
								className="block text-sm font-medium text-gray-700"
							>
								Group Rules
							</label>
						</div>
						<div className="mt-1">
							<textarea
								rows="4"
								name="comment"
								id="comment"
								value={rules}
								onChange={(event) => setRules(event.target.value)}
								className={generateClassName(!validRules && rules)}
							></textarea>
						</div>
					</div>

					<div className="w-full">
						{!!selectedMembers.length && (
							<div className="mb-5 w-full flex justify-end">
								<div className="text-center">
									<ButtonPrimary
										text="Edit Members"
										size="md"
										action={() => setShowModal(true)}
									/>
								</div>
							</div>
						)}

						{!selectedMembers.length && (
							<div className="w-full h-96 border-4 border-dashed rounded-md p-3">
								<DefaultState
									icon="person_add"
									heading="No Members Added Yet"
									description="Invites members to your newly created group"
									action={() => setShowModal(true)}
									actionText="Add Members"
								/>
							</div>
						)}
						{!!selectedMembers.length && (
							<div className="w-full border-4 border-dashed rounded-md p-2">
								<div className="w-full flex flex-col justify-center items-center">
									<div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-4 content-between max-h-60 overflow-y-auto px-1 mx-1">
										{selectedMembers.map((member) => {
											return (
												<div
													key={uuid()}
													className="flex items-center justify-between p-3 bg-white shadow-md rounded-md"
												>
													<div className="flex items-center">
														<img
															className="h-10 w-10 rounded-full"
															src={
																member?.photo?.small
																	? member.photo.small.url
																	: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"
															}
														/>
														<div className="ml-3 overflow-hidden">
															{member?.firstName && member?.lastName ? (
																<p className="text-sm font-medium capitalize">
																	{member.firstName} {member.lastName}
																</p>
															) : (
																<p className="text-sm font-medium lowercase">
																	{member.emailAddress}
																</p>
															)}
														</div>
													</div>
													<MdClear
														className="text-xl text-red-500 cursor-pointer"
														onClick={() => handleDeleteMember(member)}
													/>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
						<AddMemberModal
							open={showModal}
							selectedMembers={selectedMembers}
							onClose={() => setShowModal(false)}
							onDone={() => setShowModal(false)}
							onChange={setSelectedMembers}
						/>
					</div>
					<div className="w-full flex justify-end">
						<div>
							<ButtonPrimary
								text={submitting ? "Please wait..." : "Create Group"}
								size="md"
								loading={!validName || submitting ? true : false}
								action={handleFormSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreateGroup;
