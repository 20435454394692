import React from "react";

// eslint-disable-next-line react/prop-types
function Toggle({ action, state, update }) {
  const handleClick = () => {
    action(!state);
    update();
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      className={`${
        !state ? "bg-primary-400" : "bg-gray-200"
      } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
      role="switch"
      aria-checked="false">
      <span className="sr-only">Use setting</span>
      {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
      <span
        aria-hidden="true"
        className={` ${!state ? "translate-x-5" : "translate-x-0"} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}>
      </span>
    </button>
  );
  
}

export default Toggle;