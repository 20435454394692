/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Spinner from "../../../components/spinner/Spinner";
import DefaultState from "../../../components/default_state/DefaultState";
import CouponCard from "../../../components/coupon_card/CouponCard";
import getDiscounts from "../getDiscounts";
import generateCategory from "../generateCategory";
import Pagination from "../../../components/pagination/Pagination";
import useUserContext from "../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import { debounce } from "../../../services/util";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function MyDiscounts() {
  useScrollPage();
  useTitle("My Listing Discounts");
  
  const { userState } = useUserContext();
  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [isDiscountLoading, setIsDiscountLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ limit: 4, total: 0, page: 0, remaining: 0 });
  const categoryRef = useRef(category);
  categoryRef.current = category;

  const fetchDiscounts = async (accessToken, page, _keyword, _category) => {
    setIsDiscountLoading(true);
    const response = await getDiscounts({
      creator: true,
      page,
      keyword: _keyword,
      category: _category,
      limit: pagination.limit,
    }, accessToken);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setDiscounts(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsDiscountLoading(false);
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchDiscounts(userState.accessToken, 0, query, categoryRef.current);
    setKeyword(query);
  };

  const handleSearchByCategory = value => {
    fetchDiscounts(userState.accessToken, 0, keyword, value);
    setCategory(value);
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page - 1, keyword, category);
  };

  const handleNextFetch = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page + 1, keyword, category);
  };

  const handleReload = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page, keyword, category);
  };

  useEffect(() => {
    fetchDiscounts(userState.accessToken, 0, "", "");
  }, []);

  return (
    <div className="h-full w-full">
      <div className="w-full flex gap-3 justify-between items-center">
        <div className="w-full mt-1 relative flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            onInput={debouncedHandleSearch}
            placeholder="Search discount"
            className="shadow-sm focus:ring-primary-400 focus:border-primary-400 block w-full max-w-md p-3 sm:text-sm border-gray-300 rounded-full"
          />
        </div>
        <div className="w-1/5">
          <Select
            placeholder="Select a category:"
            isDisabled={false}
            isClearable={true}
            isLoading={false}
            isRtl={false}
            isSearchable={true}
            name="category"
            options={generateCategory()}
            onChange={event => handleSearchByCategory(event ? event.value : "")}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#ecf8ef",
                primary: "#56c271",
              },
            })}
          />
        </div>
      </div>

      <div className="w-full mt-10 flex flex-col items-center justify-center gap-5 sm:flex-wrap sm:justify-start sm:flex-row">
        {!isDiscountLoading && !discounts.length &&
          <DefaultState icon="corporate_fare" heading="No Discounts Found" description="Discounts added by your businesses will appear here." />
        }
        <div className="w-full flex items-center justify-center md:justify-start gap-5 flex-wrap">
          <>
            {isDiscountLoading ? (
              <Spinner displayText="Loading..." />
            ) : (
              discounts.map(discount =>
                <CouponCard
                  key={discount._id}
                  coupon={discount}
                  isFavorite={!!discount.favorites.length}
                  isOwner
                  onReload={handleReload}
                  fid={discount.favorites[0] ? discount.favorites[0]._id : ""}
                />
              )
            )}
          </>
        </div>
        <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      </div>
      {/* <section className="pt-12">
         <Footer />
    </section> */}
    </div>
   
  );
}

export default MyDiscounts;
