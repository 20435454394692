/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import useUserContext from "../../hooks/useUserContext";
import { MdBadge } from "react-icons/md";
import SelectInput from "../select_input/SelectInput";
import { LOCATIONS, MAGIC_NUMBERS, STATES_CITIES } from "../../constant";
import { generateClassName } from "../../services/util";
import notify from "../../services/toast";
import updateOfficernd from "./updateOfficernd";
import { capitalize, sortBy } from "lodash";
import { validateName } from "../../services/validation";

const generateStates = statesObject => {
  const states = [];
  for (const state in statesObject) {
    states.push({ value: state, label: state });
  }
  return sortBy(states, item => item.value);
};

const generateCities = citiesObject => {
  const cities = [];

  for (const city in citiesObject) {
    cities.push({ value: city, label: (city === "Tampa Bay Area" ? city : capitalize(city)) });
  }

  return sortBy(cities, item => item.value);
};

const generateLocation = city => {
  const location = LOCATIONS[city];
  return sortBy(location, item => item.value);
};

function OfficerndUpdateModal() {
  const { userState } = useUserContext();
  const [isOpened, setIsOpened] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const [firstName, setFirstName] = React.useState(userState.firstName);
  const [validFirstName, setValidFirstName] = React.useState(false);

  const [lastName, setLastName] = React.useState(userState.lastName);
  const [validLastName, setValidLastName] = React.useState(false);

  const [city, setCity] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [location, setLocation] = React.useState(null);

  const [locations, setLocations] = React.useState([]);

  React.useEffect(() => {
    const result = validateName(firstName);
    setValidFirstName(result);
  }, [firstName]);

  React.useEffect(() => {
    const result = validateName(lastName);
    setValidLastName(result);
  }, [lastName]);

  React.useEffect(() => {
    if (city) {
      setLocations(generateLocation(city.value));
    } else {
      setLocations([]);
    }
  }, [city]);

  const handleUpdate = async () => {
    setSubmitting(true);
    try {
      if (!validFirstName || !validLastName || !state || !city || !location) {
        notify("One or more fields are invalid. Check and try again", "error");
        return;
      }
      const payload = {
        firstName: firstName.toLocaleLowerCase(),
        lastName: lastName.toLocaleLowerCase(),
        state: state["value"].toLocaleLowerCase(),
        location: location["value"].toLocaleLowerCase(),
        city: city["value"].toLocaleLowerCase(),
      };
      const response = await updateOfficernd(payload, userState.accessToken);
      if (response.status === "success") {
        window.location.reload();
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      setSubmitting(false);
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setState(false);
    }
  };

  React.useEffect(() => {
    if (userState.officerndId && (!userState.city || !userState.state || !userState.location)) {
      setIsOpened(true);
    }
  }, []);

  return (
    <>
      {isOpened ? (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100">
                    <MdBadge className="text-primary-400 text-4xl" />
                  </div>
                  <div className="flex flex-col">
                    <h3 className="text-2xl leading-6 font-medium text-gray-900 text-center sm:text-left" id="modal-title">
                      Almost done!
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Please take the time to review and update your basic information below. All fields are required.</p>
                    </div>
                    <form className="mt-2 space-y-4">
                      <div className="flex items-center flex-col justify-center gap-5 sm:flex-row">
                        <div className="w-full">
                          <label htmlFor="firstname" className="block text-sm font-bold text-gray-700">
                            First Name
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={event => setFirstName(event.target.value)}
                              id="firstname"
                              name="firstname"
                              type="text"
                              placeholder="John"
                              value={firstName}
                              autoComplete="off"
                              className={generateClassName(!validFirstName && firstName)}
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <label htmlFor="lastname" className="block text-sm font-bold text-gray-700">
                            Last Name
                          </label>
                          <div className="mt-1">
                            <input
                              id="lastname"
                              onChange={event => setLastName(event.target.value)}
                              name="lastname"
                              type="text"
                              placeholder="Smith"
                              value={lastName}
                              autoComplete="off"
                              className={generateClassName(!validLastName && lastName)}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="city" className="flex justify-between items-center text-sm font-bold text-gray-700">
                          City
                        </label>
                        <div className="mt-1">
                          <SelectInput options={generateCities(LOCATIONS)} noOptionsMessage="No cities available" placeholder="Select a city" placeMent="top" setSelectedValue={setCity} />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="state" className="flex justify-between items-center text-sm font-bold text-gray-700">
                          State
                        </label>
                        <div className="mt-1">
                          <SelectInput options={generateStates(STATES_CITIES)} noOptionsMessage="No states available" placeholder="Select a state" placeMent="top" setSelectedValue={setState} />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="location" className="flex justify-between items-center text-sm font-bold text-gray-700">
                          COhatch Home Location
                        </label>
                        <div className="mt-1">
                          <SelectInput options={locations} noOptionsMessage="No locations available" placeholder="Select a location" placeMent="top" setSelectedValue={setLocation} />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={handleUpdate}
                    disabled={!validFirstName || !validLastName || !city || !state || !location || submitting ? true : false}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-400 text-base font-medium text-white hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm">
                    {submitting ? "Please wait..." : "Complete"}
                  </button>
                  <button
                    onClick={() => window.location.replace("/")}
                    type="button"
                    disabled={submitting ? true : false}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default OfficerndUpdateModal;
