/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from "react";
import { FacebookSelector } from "@charkour/react-reactions";
import { REACTIONS } from "../../constant";

function Reaction({ createReaction, postId }) {
  const react = (react) => {
    const payload = {
      reactionType: REACTIONS[react.toUpperCase()],
      postId: postId,
    };
    createReaction(payload);
  };
  return (
    <div className="ab">
      <FacebookSelector onSelect={(label) => react(label)} />
    </div>
  );
}

export default Reaction;
