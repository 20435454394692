import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function createUnsubscribe(payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/news_letter/unsubscribe`;

  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, null);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createUnsubscribe;
