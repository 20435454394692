/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { Outlet } from "react-router-dom";
import { PLANS, STATUS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import UpgradeModel from "../upgrade_modal/UpgradeModel";

function Feature({ products }) {
  const {
    subscription: { product },
  } = useUserContext();

  return <>{products.includes(PLANS[product]) ? <Outlet /> : <UpgradeModel />}</>;
}

export default Feature;
