import React from "react";
import PropTypes from "prop-types";
import { convert24hTo12h } from "./../../services/util";

function printHours(hours) {
  if (hours === "Closed") {
    return "Closed";
  } else {
    return "24 hours";
  }
}

function BusinessHours({ listingHours }) {
  return (
    <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
      <h1 className="text-xl text-success-800 font-bold m-2">Business Hours</h1>
      <div className="w-full space-y-2 divide-y divide-gray-200 p-3">
        {listingHours["Monday"] ? (
          <div className="w-full flex justify-between">
            <span className="text-md text-success-800 font-bold">Monday</span>
            <span className="text-md text-success-800">
              {listingHours["Monday"]["round"]
                ? printHours(listingHours["Monday"]["round"])
                : `${convert24hTo12h(listingHours["Monday"]["open"])} - ${convert24hTo12h(listingHours["Monday"]["close"])}`}
            </span>
          </div>
        ) : null}
        {listingHours["Tuesday"] ? (
          <div className="w-full flex justify-between">
            <span className="text-md text-success-800 font-bold">Tuesday</span>
            <span className="text-md text-success-800">
              {listingHours["Tuesday"]["round"]
                ? printHours(listingHours["Tuesday"]["round"])
                : `${convert24hTo12h(listingHours["Tuesday"]["open"])} - ${convert24hTo12h(listingHours["Tuesday"]["close"])}`}
            </span>
          </div>
        ) : null}

        {listingHours["Wednesday"] ? (
          <div className="w-full flex justify-between">
            <span className="text-md text-success-800 font-bold">Wednesday</span>
            <span className="text-md text-success-800">
              {listingHours["Wednesday"]["round"]
                ? printHours(listingHours["Wednesday"]["round"])
                : `${convert24hTo12h(listingHours["Wednesday"]["open"])} - ${convert24hTo12h(listingHours["Wednesday"]["close"])}`}
            </span>
          </div>
        ) : null}

        {listingHours["Thursday"] ? (
          <div className="w-full flex justify-between">
            <span className="text-md text-success-800 font-bold">Thursday</span>
            <span className="text-md text-success-800">
              {listingHours["Thursday"]["round"]
                ? printHours(listingHours["Thursday"]["round"])
                : `${convert24hTo12h(listingHours["Thursday"]["open"])} - ${convert24hTo12h(listingHours["Thursday"]["close"])}`}
            </span>
          </div>
        ) : null}

        {listingHours["Friday"] ? (
          <div className="w-full flex justify-between">
            <span className="text-md text-success-800 font-bold">Friday</span>
            <span className="text-md text-success-800">
              {listingHours["Friday"]["round"]
                ? printHours(listingHours["Friday"]["round"])
                : `${convert24hTo12h(listingHours["Friday"]["open"])} - ${convert24hTo12h(listingHours["Friday"]["close"])}`}
            </span>
          </div>
        ) : null}

        {listingHours["Saturday"] ? (
          <div className="w-full flex justify-between">
            <span className="text-md text-success-800 font-bold">Saturday</span>
            <span className="text-md text-success-800">
              {listingHours["Saturday"]["round"]
                ? printHours(listingHours["Saturday"]["round"])
                : `${convert24hTo12h(listingHours["Saturday"]["open"])} - ${convert24hTo12h(listingHours["Saturday"]["close"])}`}
            </span>
          </div>
        ) : null}

        {listingHours["Sunday"] ? (
          <div className="w-full flex justify-between">
            <span className="text-md text-success-800 font-bold">Sunday</span>
            <span className="text-md text-success-800">
              {listingHours["Sunday"]["round"]
                ? printHours(listingHours["Sunday"]["round"])
                : `${convert24hTo12h(listingHours["Sunday"]["open"])} - ${convert24hTo12h(listingHours["Sunday"]["close"])}`}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

BusinessHours.propTypes = {
  listingHours: PropTypes.object,
};

export default BusinessHours;
