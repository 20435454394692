/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdLockOutline } from "react-icons/md";
import useUserContext from "./../../hooks/useUserContext";

function determineSetting(group, userId) {
  if (group.creator._id === userId) {
    return true;
  } else {
    const member = group.members.find((member) => member?.user?._id === userId);
    if (!member) {
      return false;
    }

    if (member.status === "ACTIVE" && member.role === "admin") {
      return true;
    }
  }

  return false;
}

function userInGroup(group, userId) {
  if (group.creator._id === userId) {
    return true;
  }

  if (!group.members?.length) {
    return false;
  }

  for (const member of group.members) {
    if (member?.user?._id === userId && member.status === "ACTIVE") {
      return true;
    }
  }

  return false;
}

function GroupNav({ group }) {
  const navigate = useNavigate();
  const { userState } = useUserContext();
  React.useEffect(() => {
    if (group.status !== "ACTIVE" || !userInGroup(group, userState.id)) {
      navigate("about");
    }
  }, []);
  return (
    <div>
      <div className="sm:block overflow-x-auto">
        <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
          <NavLink
            to={group.status !== "ACTIVE" || !userInGroup(group, userState.id) ? "about" : ""}
            className="text-gray-900 rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
            aria-current="page"
          >
            <span className="flex gap-2 items-center justify-center">
              Timeline
              {group.status !== "ACTIVE" || !userInGroup(group, userState.id) ? <MdLockOutline className="" /> : null}
            </span>
          </NavLink>

          <NavLink
            to={group.status !== "ACTIVE" || !userInGroup(group, userState.id) ? "about" : "members"}
            className="text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
          >
            <span className="flex gap-2 items-center justify-center">
              Members
              {group.status !== "ACTIVE" || !userInGroup(group, userState.id) ? <MdLockOutline className="" /> : null}
            </span>
          </NavLink>

          {/* <NavLink
            to={group.status !== "ACTIVE" || !userInGroup(group, userState.id) ? "about" : "photos"}
            className="text-gray-500 hover:text-gray-700 rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10">
            <span className="flex gap-2 items-center justify-center">
              Photos
              {group.status !== "ACTIVE" || !userInGroup(group, userState.id) ? <MdLockOutline className="" /> : null}
            </span>
          </NavLink> */}

          <NavLink
            to="about"
            className="text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
          >
            <span>About</span>
          </NavLink>

          <NavLink
            to={determineSetting(group, userState.id) ? "settings" : "about"}
            className="text-gray-500 hover:text-gray-700 rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
          >
            <span className="flex gap-2 items-center justify-center">
              Settings
              {!determineSetting(group, userState.id) ? <MdLockOutline className="" /> : null}
            </span>
          </NavLink>
        </nav>
      </div>
    </div>
  );
}

export default GroupNav;
