/* eslint-disable no-console */
import React from "react";
import useTitle from "../../hooks/useTitle";
import { useSearchParams } from "react-router-dom";
import notify from "../../services/toast";
import createUnsubscribe from "./createUnsubscribe";

function UnsubscribeList() {
  useTitle("Unsubscribe");

  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = React.useState(false);

  const emailAddress = searchParams.get("emailAddress");
  const type = searchParams.get("type");

  const handleUnsubscribe = async event => {
    event.preventDefault();
    try {
      setSubmitting(true);
      const payload = { emailAddress: emailAddress, type: type };
      const response = await createUnsubscribe(payload);
      if (response.status === "success") {
        notify("You have successfully unsubscribed. Redirecting in a moment...", "success");
        window.setTimeout(() => {
          window.location.replace("/");
        }, 3000);
      } else {
        notify("An error has occurred. Please try again", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Unsubscribe</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>You will no longer receive email notifications. If you wish to opt in some other time, you can conveniently do so from your settings page</p>
          </div>
          <form onSubmit={handleUnsubscribe} className="mt-5 sm:flex sm:items-center">
            <div className="w-full sm:max-w-xs">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                disabled
                value={emailAddress}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                placeholder="you@example.com"
              />
            </div>
            <button
              type="submit"
              disabled={(emailAddress && type) || submitting ? false : true}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              {submitting ? "Please wait..." : "Confirm"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UnsubscribeList;
