import React from "react";
import PropTypes from "prop-types";

function ListingAmenities({ listingAmenities }) {
  return (
    <>
      {listingAmenities?.length ? (
        <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
          <h1 className="text-xl text-success-800 font-bold m-2">Amenities</h1>
          <hr />
          <div className="p-2 w-full flex flex-wrap gap-2">
            {listingAmenities.map((amenity, index) => {
              return (
                <div key={index} className="w-fit border border-solid bg-white rounded-md shadow-md p-3">
                  {amenity.label}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

ListingAmenities.propTypes = {
  listingAmenities: PropTypes.array,
};

export default ListingAmenities;
