/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";

function customTheme(theme) {
  return {
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary25: "#ecf8ef",
      primary: "#56c271",
    },
  };
}
const style = {
  input: (provided, state) => ({
    ...provided,
    padding: 5,
  }),
};
function SelectInput({ options = [], noOptionsMessage = "No options found.", placeholder = "Select an option", defaultValue = null, placeMent = "auto", isDisabled = false, setSelectedValue }) {
  return (
    <div className="w-full h-full">
      <Select
        options={options}
        styles={style}
        closeMenuOnScroll
        isClearable
        isSearchable
        menuPlacement={placeMent}
        menuPosition="absolute"
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView
        noOptionsMessage={() => noOptionsMessage}
        placeholder={placeholder}
        theme={customTheme}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        onChange={setSelectedValue}
      />
    </div>
  );
}

export default SelectInput;
