function makeAPIRequest(url, method, body = null, authToken = null) {
  const init = {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": authToken ? `Basic ${authToken}` : null,
    },
    mode: "cors",
    credentials: "include",
    method,
    body: ["GET", "HEAD", "OPTIONS"].includes(method) ? null : !body ? null : JSON.stringify(body),
  };

  return window.fetch(url, init);
}

export default makeAPIRequest;
