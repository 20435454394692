/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function getFavoriteListing(accessToken, page, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/favorite?kind=Listing&page=${page}&limit=${limit}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getFavoriteListing;
