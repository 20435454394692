/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function autoLogin() {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/login/auto`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", null);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default autoLogin;
