import React from "react";
import PropTypes from "prop-types";

function ListingPaymentMethod({ paymentOptions }) {
  return (
    <>
      {paymentOptions?.length ? (
        <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
          <h1 className="text-xl text-success-800 font-bold m-2">Accepted Payment Methods</h1>
          <hr />
          <div className="p-2 w-full flex flex-wrap gap-2">
            {paymentOptions.map((method, index) => {
              return (
                <div key={index} className="w-fit text-gray-600 border border-solid bg-white rounded-md shadow-md p-3 flex gap-1 items-center">
                  <span className="material-icons-outlined">payments</span>
                  {method}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

ListingPaymentMethod.propTypes = {
  paymentOptions: PropTypes.array,
};

export default ListingPaymentMethod;
