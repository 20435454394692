 /* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { format } from "date-fns";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function FeedList({ timelines }) {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8 divide-y divide-gray-100">
        {timelines.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timelines.length - 1 ? <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-black" aria-hidden="true" /> : null}
              <div className="relative flex space-x-3">
                <div>
                  <span className={classNames(event.iconBackground, "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white shadow-2xl")}>
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center pt-2 text-sm whitespace-nowrap text-gray-500">{event.date ? <time>{format(new Date(event.date), "MMMM do")}</time> : null}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FeedList;
