/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import PropTypes from "prop-types";

function Rating({ rating, reviews }) {
  return (
    <div className="flex items-center">
      <span className="material-icons-outlined text-yellow-400">star</span>
      <p className="ml-2 text-sm font-bold text-gray-900 dark:text-white">{rating}</p>
      <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
      <span className="text-sm font-medium text-gray-900 underline hover:no-underline dark:text-white">{reviews} reviews</span>
    </div>
  );
}

Rating.propTypes = { rating: PropTypes.number.isRequired, reviews: PropTypes.number.isRequired };

export default Rating;
