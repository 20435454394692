/* eslint-disable  */
import React, {useState} from "react";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../constant";
import { Link } from "react-router-dom";
import getEventsById from "./getEventsById";
import { Carousel} from "react-responsive-carousel";
import VolunteerCard from "../../components/philanthropy/VolunteerCard";


function ListingEvents({eventId}) {
 
  const {
    userState: { accessToken },
  } = useUserContext();
const [events, setEvents] = useState([]);
const [isEventsLoading, setIsEventsLoading] = useState(true);

const fetchEvents = async () => {
		try {
			setIsEventsLoading(true);

			const response = await getEventsById(accessToken, eventId);
    
			if (response.status === "success") {	
        console.log(response.data);	
				setEvents(response.data);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			console.log("something went wrong");
		} finally {
			setIsEventsLoading(false);
		}
	};

  React.useEffect(() => {
    fetchEvents();
  }, []);

  

  return (
       <div className=" bg-white rounded-md shadow-md">
              <h1 className="text-xl text-success-800 font-bold m-2">Volunteer and Drive Events</h1>
              <div className="w-full relative px-6 py-5 flex items-start space-x-3">
        <div className="flex-shrink-0">
              {events.length ? events.map((event, index) => {
                return (
                  <div key={index}>
                <Link to={`/event_details/${event._id}`}>
                {event.eventName}
                </Link>
                </div>
                );
                
              }) : <div> This Organization has no events listed</div>}
         </div>
       </div>
       </div>
  );
}


export default ListingEvents;
