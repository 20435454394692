/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
import React, { useState, useEffect } from "react";
import { FaWindowClose } from "react-icons/fa";
import Popup from "reactjs-popup";
import { useParams } from "react-router-dom";
import { INTERESTS } from "../../../constant";
import getUserByInterests from "../getUserByInterests";
import { DEFAULT_PAGINATION_LIMIT } from "../../../constant";
import MemberCard from "../../../components/member_card/MemberCard";
import DefaultState from "../../../components/default_state/DefaultState";
import { Navigate } from "react-router-dom";

function InterestDetails({
    button,
    title,
}) 
{
  const params = useParams();
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: DEFAULT_PAGINATION_LIMIT });


  const { value, label } = INTERESTS?.[params.interest] || "";

  const [users, setUsers] = React.useState(null);

  const fetchUserByInterests = async (value, offset, limit) => {
    try {
      const response = await getUserByInterests(value, offset, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        console.log(response.data);
        setPagination(prevState => {
          return { ...prevState, limit, page: page, remaining, total };
        });
        setUsers(results);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };



  React.useEffect(() => {
   
      fetchUserByInterests(title, 0, DEFAULT_PAGINATION_LIMIT);
  }, []);

  
  // const memoizedUser = React.useMemo(() => user || [], [user, value]);
  // const memoizedPagination = React.useMemo(() => pagination, [pagination, value]);

    
    return (
        
        <Popup trigger={button} modal nested position="right center">
            {close => (
                <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                &#8203;
                            </span>

                            <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
                                <div className="w-full flex justify-between">
                                    <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">#{title}</h1>
                                    <button className="button" onClick={() => { close(); }} >
                                        <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close" />
                                    </button>
                                </div>

                                {title ? (
        <div className="w-full flex flex-col">
          <div className="w-full py-8 px-5 text-center space-y-3 bg-no-repeat bg-cover bg-[url('/src/assets/grungeTextureLight.png')]">
            <h1 className="w-full text-4xl md:text-5xl text-success-800 font-bebas font-bold">{label}</h1>
            <p className="w-full text-md text-gray-500">{INTERESTS[value]}</p>
          </div>
          {/* <div className="w-full h-[450px] xl:h-[600px]">{<Map locations={listingCoordinates} />}</div> */}
          <div className="mt-10 w-full px-3 sm:px-3">
            <div className="mb-10 max-w-5xl mx-auto space-y-4">
      
            </div>
              <div className="space-y-5 sm:space-y-0 sm:w-full sm:flex sm:gap-2w-full flex justify-center md:justify-center gap-5 flex-wrap">
                <div className=" grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                  {/* {users.map((people, index) => {
                    return <div key={index}>{people.interest}</div>;
                  })} */}
                  {users && users.length ? (
                    users.map((people, index) => {
                      return <MemberCard key={index} member={people} />;
                    })
                  ) : (
                    <DefaultState icon="store" heading="No Users Found" description={" Users with the same interests or causes will appear here"} />
                  )}
                </div>
              </div>
            </div>
          </div>
       
      ) : (
        <div>NO result</div>
      )}




                            </div>



                           
     







                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default React.forwardRef(InterestDetails);
