/* eslint-disable no-unused-vars */
import React from "react";
import ButtonPrimary from "./../../components/button/ButtonPrimary";
import BasicPlanFeatures from "./../../components/plan_features/Basic";
import LocalDiscountPlanFeatures from "./../../components/plan_features/LocalDiscount";
import PremiumPlanFeature from "./../../components/plan_features/Premium";
import Logo from "./../../components/logo/Logo";
import useUserContext from "../../hooks/useUserContext";
import createSubscription from "./createSubscription";
import { generateClassName } from "./../../services/util";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";

function Plan() {
  useScrollPage();
  useTitle("Plan");
  const { userState } = useUserContext();

  const [freeReferralCode, setFreeReferralCode] = React.useState("");
  const [basicReferralCode, setBasicReferralCode] = React.useState("");
  const [premiumReferralCode, setPremiumReferralCode] = React.useState("");
  const [subscribing, setSubscribing] = React.useState(false);

  const handleBasicSubscription = async () => {
    const payload = {
      firstName: userState.firstName,
      lastName: userState.lastName,
      emailAddress: userState.emailAddress,
      referralCode: freeReferralCode,
      memberShip: "FREE",
    };

    await createSubscription(payload, setSubscribing, userState.accessToken);
  };

  const handleLocalSubscription = async () => {
    const payload = {
      firstName: userState.firstName,
      lastName: userState.lastName,
      emailAddress: userState.emailAddress,
      referralCode: basicReferralCode,
      memberShip: "BASIC",
    };

    await createSubscription(payload, setSubscribing, userState.accessToken);
  };

  const handlePremiumSubscription = async () => {
    const payload = {
      firstName: userState.firstName,
      lastName: userState.lastName,
      emailAddress: userState.emailAddress,
      referralCode: premiumReferralCode,
      memberShip: "PREMIUM",
    };

    await createSubscription(payload, setSubscribing, userState.accessToken);
  };

  return (
    <div className="h-full w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-[url('https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/background-image.png')] bg-no-repeat bg-cover">
      <div className="flex justify-center">
        <Logo color="green" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-400">Select a monthly subscription.</h2>
      </div>
      <div className="flex flex-col px-3 md:flex-row justify-center gap-4 w-full mt-8">
        <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div className="p-6">
            <h2 className="text-lg leading-6 font-medium text-primary-400">Basic</h2>
            <p className="mt-4 text-sm text-secondary-600">All the basics for trying out COhatch+.</p>
            <p className="mt-8 mb-5">
              <span className="text-4xl font-extrabold text-primary-400">$0</span>
              <span className="text-base font-medium text-secondary-600">/mo</span>
            </p>
            <div className="mb-5">
              <label htmlFor="referral_code" className="flex justify-between items-center text-sm font-bold text-gray-700">
                Referral code <span className="text-gray-400 text-sm">optional</span>
              </label>
              <div className="mt-1">
                <input
                  id="referral_code"
                  onChange={(event) => setFreeReferralCode(event.target.value)}
                  name="referral_code"
                  type="text"
                  placeholder="HAPPY89"
                  value={freeReferralCode}
                  autoComplete="off"
                  className={generateClassName(false)}
                />
              </div>
            </div>
            <ButtonPrimary text={subscribing ? "Please wait..." : "Select basic"} size="md" icon="add_shopping_cart" action={handleBasicSubscription} loading={subscribing ? true : false} />
          </div>
          <BasicPlanFeatures />
        </div>

        {/* <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div className="p-6">
            <h2 className="text-lg leading-6 font-medium text-primary-400">Local Discount Club</h2>
            <p className="mt-4 text-sm text-secondary-600">Get advanced business listings and access to all local discounts.</p>
            <p className="mt-8 mb-4">
              <span className="text-4xl font-extrabold text-primary-400">$10</span>
              <span className="text-base font-medium text-secondary-600">/mo</span>
            </p>
            <div className="mb-5">
              <label htmlFor="referral_code" className="flex justify-between items-center text-sm font-bold text-gray-700">
                Referral code <span className="text-gray-400 text-sm">optional</span>
              </label>
              <div className="mt-1">
                <input
                  id="referral_code"
                  onChange={(event) => setBasicReferralCode(event.target.value)}
                  name="referral_code"
                  type="text"
                  placeholder="HAPPY89"
                  value={basicReferralCode}
                  autoComplete="off"
                  className={generateClassName(false)}
                />
              </div>
            </div>
            <ButtonPrimary text={subscribing ? "Please wait..." : "Select Local Discount"} size="md" icon="add_shopping_cart" action={handleLocalSubscription} loading={subscribing ? true : false} />
          </div>
          <LocalDiscountPlanFeatures />
        </div> */}

        {/* <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div className="p-6">
            <h2 className="text-lg leading-6 font-medium text-primary-400">Premium</h2>
            <p className="mt-4 text-sm text-secondary-600">Unlimited Access to All COhatch+ Features.</p>
            <p className="mt-8 mb-4">
              <span className="text-4xl font-extrabold text-primary-400">$30</span>
              <span className="text-base font-medium text-secondary-600">/mo</span>
            </p>
            <div className="mb-5">
              <label htmlFor="referral_code" className="flex justify-between items-center text-sm font-bold text-gray-700">
                Referral code <span className="text-gray-400 text-sm">optional</span>
              </label>
              <div className="mt-1">
                <input
                  id="referral_code"
                  onChange={(event) => setPremiumReferralCode(event.target.value)}
                  name="referral_code"
                  type="text"
                  placeholder="HAPPY89"
                  value={premiumReferralCode}
                  autoComplete="off"
                  className={generateClassName(false)}
                />
              </div>
            </div>
            <ButtonPrimary text={subscribing ? "Please wait..." : "Select Premium"} size="md" icon="add_shopping_cart" action={handlePremiumSubscription} loading={subscribing ? true : false} />
          </div>
          <PremiumPlanFeature />
        </div> */}
      </div>
      <div className="p-5 text-xs text-gray-500 text-center">&copy; COhatch/COhatch+ All rights reserved</div>
    </div>
  );
}

export default Plan;
