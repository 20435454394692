/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import useConversationContext from "../../hooks/useConversationContext";

function ConversationHeader({ setShowMessages, socket }) {
  const navigate = useNavigate();
  const { userState } = useUserContext();
  const [typing, setTyping] = React.useState(null);
  const { currentConversation } = useConversationContext();
  const handleBack = () => {
    setShowMessages(false);
    navigate("/dashboard/messaging");
  };

  const recipient = currentConversation?.recipient?._id !== userState.id ? currentConversation?.recipient : currentConversation?.sender;
  const photo = recipient?.photo?.small ? recipient.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png";
  const firstName = recipient?.firstName ? recipient.firstName : null;
  const lastName = recipient?.lastName ? recipient.lastName : null;

  React.useEffect(() => {
    socket.current.on("typing", data => {
      setTyping(data);
    });
  }, [currentConversation]);

  return (
    <div className="sm:w-[calc(100vw-635px)] flex justify-between items-center bg-white p-3 border-b-2">
      <div className="w-full flex items-center gap-2">
        <span onClick={handleBack} className="material-icons-outlined block sm:hidden text-gray-500 w-10 h-10 cursor-pointer ">
          chevron_left
        </span>
        <div className="w-full flex gap-2">
          <img className="block h-10 w-10 rounded-full" src={photo} alt={lastName} />
          <div className="w-full flex flex-col justify-center">
            <h1 className="w-[400px] text-success-800 text-md capitalize">
              {firstName} {lastName}
            </h1>
            {typing && typing?.conversationId === currentConversation._id && typing?.typing ? <p className="w-full text-primary-400 text-sm italic">Typing...</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConversationHeader;
