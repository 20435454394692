/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {FaWindowClose} from "react-icons/fa";
import Popup from "reactjs-popup";
import ButtonPrimary from "../button/ButtonPrimary";
import {PROPERTY_DISCOUNT, PROPERTY_BATHROOM_AMENITIES, PROPERTY_BEDROOM_AMENITIES, PROPERTY_ENTERTAINMENT_AMENITIES, PROPERTY_KITCHEN_AMENITIES, PROPERTY_SAFETY_AMENITIES, PROPERTY_COOLING_AMENITIES, PROPERTY_FAMILY_AMENITIES, PROPERTY_PARKING_AMENITIES, PROPERTY_OUTDOOR_AMENITIES, PROPERTY_ACCESSIBILITY_AMENITIES} from "../../constant";
import MultiRangeSlider from "../multirange_slider/MultiRangeSlider";

const filtersMap = {
  price: [
    { value: "0", label: "$0 - $25", checked: false },
    { value: "25", label: "$25 - $50", checked: false },
    { value: "50", label: "$50 - $75", checked: false },
    { value: "75", label: "$75+", checked: false },
  ],
  ratings: [
    { value: "5", label: "5 stars" },
    { value: "4", label: "4 stars" },
    { value: "3", label: "3 stars" },
  ],
  rooms: [
    { value: "Any", label: "Any" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5+" },
  ],
  category: [
    { value: "all-new-arrivals", label: "All New Arrivals", checked: false },
    { value: "tees", label: "Tees", checked: false },
    { value: "objects", label: "Objects", checked: false },
    { value: "sweatshirts", label: "Sweatshirts", checked: false },
    { value: "pants-and-shorts", label: "Pants & Shorts", checked: false },
  ],
};
function VacationHomesFilter({
  button,
  filters,
  setFilters,
  onFilter,
  onClear,
}) {
  const [bedrooms, setBedrooms] = useState(filters.bedrooms || []);
  const [beds, setBeds] = useState(filters.beds || []);
  const [bathrooms, setBathrooms] = useState(filters.bathrooms || []);
  const [ratings, setRatings] = useState(filters.ratings || []);
  const [discounts, setDiscounts] = useState(filters.discounts || []);
  const [amenities, setAmenities] = useState(filters.amenities || {
    bathroom: {},
    bedroomLaundry: {},
    entertainmentTech: {},
    kitchen: {},
    safety: {},
    heatingCooling: {},
    family: {},
    parking: {},
    outdoor: {},
    accessibility: {},
  });
  const [min, setMin] = useState(filters.min || 0);
  const [max, setMax] = useState(filters.max || 0);

  const handleChange = (type, value) => {
    if (type === "bedrooms") {
      if (value === "Any") {
        setBedrooms([]);
      } else {
        const idx = bedrooms.indexOf(value);
        const newBedrooms = [...bedrooms];
        if (idx === -1) {
          newBedrooms.push(value);
        } else {
          newBedrooms.splice(idx, 1);
        }
        setBedrooms(newBedrooms);
      }
    }
    if (type === "beds") {
      if (value === "Any") {
        setBeds([]);
      } else {
        const idx = beds.indexOf(value);
        const newBeds = [...beds];
        if (idx === -1) {
          newBeds.push(value);
        } else {
          newBeds.splice(idx, 1);
        }
        setBeds(newBeds);
      }
    }
    if (type === "bathrooms") {
      if (value === "Any") {
        setBathrooms([]);
      } else {
        const idx = bathrooms.indexOf(value);
        const newBathrooms = [...bathrooms];
        if (idx === -1) {
          newBathrooms.push(value);
        } else {
          newBathrooms.splice(idx, 1);
        }
        setBathrooms(newBathrooms);
      }
    }
    if (type === "ratings") {
      const idx = ratings.indexOf(value);
      const newRatings = [...ratings];
      if (idx === -1) {
        newRatings.push(value);
      } else {
        newRatings.splice(idx, 1);
      }
      setRatings(newRatings);
    }
    if (type === "discounts") {
      const idx = discounts.indexOf(value);
      const newDiscounts = [...discounts];
      if (idx === -1) {
        newDiscounts.push(value);
      } else {
        newDiscounts.splice(idx, 1);
      }
      setDiscounts(newDiscounts);
    }
  };

  const handleSave = () => {
    setFilters({
      ...filters,
      beds,
      bedrooms,
      bathrooms,
      amenities,
      ratings,
      discounts,
      min,
      max,
    });
    onFilter({
      ...filters,
      beds,
      bedrooms,
      bathrooms,
      ratings,
      discounts,
      amenities,
      min,
      max,
    });
  };

  const handleClear = () => {
    onClear();
    setBedrooms([]);
    setBeds([]);
    setBathrooms([]);
    setRatings([]);
    setDiscounts([]);
    setAmenities({
      bathroom: {},
      bedroomLaundry: {},
      entertainmentTech: {},
      kitchen: {},
      safety: {},
      heatingCooling: {},
      family: {},
      parking: {},
      outdoor: {},
      accessibility: {},
    });
    setMin(0);
    setMax(0);
  };

  const handleClose = () => {
    setBeds(filters.beds);
    setBathrooms(filters.bathrooms);
    setBedrooms(filters.bedrooms);
    setRatings(filters.ratings);
    setDiscounts(filters.discounts || []);
    setAmenities(filters.amenities);
  };

  const handleChangeAmenities = (key, value) => {
    setAmenities({
      ...amenities,
      [key]: {
        ...amenities[key],
        [value]: !amenities[key][value]
      }
    });
  };

  return (
    <Popup
      trigger={
        <button
          className="inline-flex items-center py-2.5 px-3 ml-2 text-sm font-medium text-white bg-primary-400 rounded-full border border-primary-400 hover:bg-primary-800  focus:outline-none  dark:bg-primary-600 dark:hover:bg-primary-700 "
        >
          Filter  
        </button>
      }
      modal
      nested
      position="right center"
    >
      {close => (
      <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
                        <div className="w-full flex justify-between">
                        <h1 className="mb-6 text-2xl text-success-800 font-bold m-2">Filters</h1>
                            <button className="button" onClick={() => {
                              close();
                              handleClose();
                            }}>
                                <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close"/>
                            </button> 
                        </div>
        <div className="border-t border-gray-200 py-10">
    
              <fieldset>
                <legend className="block font-medium">Price</legend>
                <MultiRangeSlider
                  min={0}
                  max={1000}
                  value={{
                    min: min,
                    max: max
                  }}
                  onChange={({ min, max }) => {
                    setMin(min);
                    setMax(max);
                  }}
                />
              </fieldset>

              <hr className="bg-gray-200 lg:w-6/12 w-full md:my-10 my-8" />

              <fieldset className="mt-4">
                <legend className="block font-medium ">Discount</legend>
                <div className="md:flex md:space-x-6 mt-4 grid grid-cols-3 gap-y-8 flex-wrap">
                        <div className="flex space-x-2 md:justify-center md:items-center items-center justify-start">
                  {PROPERTY_DISCOUNT.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                    <input
                      id={`discounts-${optionIdx}`}
                      name="discounts[]"
                      type="checkbox"
                      className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      checked={discounts.indexOf(option.value) > -1}
                      onChange={() => handleChange("discounts", option.value)}
                    />
                    <label htmlFor={`discounts-${optionIdx}`} className="pl-3 min-w-0 flex-1 text-gray-600">
                      {option.label}
                    </label>
                  </div>
                  ))}
                </div>
                </div>
              </fieldset>

              <hr className="bg-gray-200 lg:w-6/12 w-full md:my-10 my-8" />

              <fieldset className="mt-4">
                <legend className="block font-medium ">Ratings</legend>
                <div className="md:flex md:space-x-6 mt-4 grid grid-cols-3 gap-y-8 flex-wrap">
                        <div className="flex space-x-2 md:justify-center md:items-center items-center justify-start">
                  {filtersMap.ratings.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                    <input
                      id={`ratings-${optionIdx}`}
                      name="ratings[]"
                      type="checkbox"
                      className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      checked={ratings.indexOf(option.value) > -1}
                      onChange={() => handleChange("ratings", option.value)}
                    />
                    <label htmlFor={`ratings-${optionIdx}`} className="pl-3 min-w-0 flex-1 text-gray-600">
                      {option.label}
                    </label>
                  </div>
                  ))}
                </div>
                </div>
              </fieldset>

              <hr className="bg-gray-200 lg:w-6/12 w-full md:my-10 my-8" />

              <fieldset className="mt-4">
                <legend className="block font-medium ">Bedrooms(s)</legend>
                <div className="md:flex md:space-x-6 mt-4 grid grid-cols-3 gap-y-8 flex-wrap">
                        <div className="flex space-x-2 md:justify-center md:items-center items-center justify-start">
                  {filtersMap.rooms.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                    <input
                      id={`bedrooms-${optionIdx}`}
                      name="bedrooms[]"
                      type="checkbox"
                      className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      checked={bedrooms.indexOf(option.value) > -1 || (option.value === "Any" && bedrooms.length === 0)}
                      onChange={() => handleChange("bedrooms", option.value)}
                    />
                    <label htmlFor={`bedrooms-${optionIdx}`} className="pl-3 min-w-0 flex-1 text-gray-600">
                      {option.label}
                    </label>
                  </div>
                  ))}
                </div>
                </div>
              </fieldset>
              <fieldset className="mt-4">
                <legend className="block font-medium ">Bed(s)</legend>
                <div className="md:flex md:space-x-6 mt-4 grid grid-cols-3 gap-y-8 flex-wrap">
                        <div className="flex space-x-2 md:justify-center md:items-center items-center justify-start">
                  {filtersMap.rooms.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                    <input
                      id={`beds-${optionIdx}`}
                      name="beds[]"
                      type="checkbox"
                      className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      checked={beds.indexOf(option.value) > -1 || (option.value === "Any" && beds.length === 0)}
                      onChange={() => handleChange("beds", option.value)}
                    />
                    <label htmlFor={`beds-${optionIdx}`} className="pl-3 min-w-0 flex-1 text-gray-600">
                      {option.label}
                    </label>
                  </div>
                  ))}
                </div>
                </div>
              </fieldset>
              <fieldset className="mt-4">
                <legend className="block font-medium">Bathroom(s)</legend>
                <div className="md:flex md:space-x-6 mt-4 grid grid-cols-3 gap-y-8 flex-wrap">
                        <div className="flex space-x-2 md:justify-center md:items-center items-center justify-start">
                  {filtersMap.rooms.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                    <input
                      id={`bathrooms-${optionIdx}`}
                      name="bathrooms[]"
                      type="checkbox"
                      className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      checked={bathrooms.indexOf(option.value) > -1 || (option.value === "Any" && bathrooms.length === 0)}
                      onChange={() => handleChange("bathrooms", option.value)}
                    />
                    <label htmlFor={`bathrooms-${optionIdx}`} className="pl-3 min-w-0 flex-1 text-gray-600">
                      {option.label}
                    </label>
                  </div>
                  ))}
                </div>
                </div>
              </fieldset>
  
                <hr className="bg-gray-200 lg:w-6/12 w-full md:my-10 my-8" />

                <h1 className="block font-medium ">Amenities and Features</h1>
                
                <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Bathroom</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-4 sm:gap-px sm:divide-y-0">
                  {PROPERTY_BATHROOM_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`bathroom-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.bathroom[value]}
                        onChange={() => handleChangeAmenities("bathroom", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`bathroom-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Bedroom & Laundry</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_BEDROOM_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`bedroomLaundry-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.bedroomLaundry[value]}
                        onChange={() => handleChangeAmenities("bedroomLaundry", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`bedroomLaundry-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Entertainment & Tech</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_ENTERTAINMENT_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`entertainmentTech-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.entertainmentTech[value]}
                        onChange={() => handleChangeAmenities("entertainmentTech", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`entertainmentTech-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Kitchen</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_KITCHEN_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`kitchen-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.kitchen[value]}
                        onChange={() => handleChangeAmenities("kitchen", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`kitchen-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Safety</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_SAFETY_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`safety-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.safety[value]}
                        onChange={() => handleChangeAmenities("safety", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`safety-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Heating & Cooling</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_COOLING_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`heatingCooling-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.heatingCooling[value]}
                        onChange={() => handleChangeAmenities("heatingCooling", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`heatingCooling-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Family</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_FAMILY_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`family-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.family[value]}
                        onChange={() => handleChangeAmenities("family", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`family-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Parking</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_PARKING_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`parking-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.parking[value]}
                        onChange={() => handleChangeAmenities("parking", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`parking-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Outdoor</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_OUTDOOR_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`outdoor-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.outdoor[value]}
                        onChange={() => handleChangeAmenities("outdoor", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`outdoor-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>

              <fieldset className="mt-4">
                  <legend className="block font-medium text-primary-400">Accessibility</legend>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {PROPERTY_ACCESSIBILITY_AMENITIES.map(({ label, value }, index) => (
                    <li className="flex items-center text-base sm:text-sm" key={index}>
                    <div className="flex items-center pl-3">
                      <input
                        id={`accessibility-${index}`}
                        type="checkbox"
                        name={label}
                        value={value}
                        checked={!!amenities.accessibility[value]}
                        onChange={() => handleChangeAmenities("accessibility", value)}
                       className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                      <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`accessibility-${index}`}>
                        {label}
                      </label>
                    </div>
                  </li>
                  ))}
                </div>
              </fieldset>
          </div>
            <div className="flex text-right">
              <div className="ml-auto mr-2">
                <button
                  onClick={handleClear}
                  type="button"
                  className="inline-flex items-center gap-2 px-3.5 py-2.5 border border-transparent shadow-sm text-md leading-4 font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 transition-all duration-500"
                >
                  Clear
                </button>
              </div>
              <div className="w-[200px]">
                <ButtonPrimary
                  text="Save"
                  size="md-1"
                  action={() => {
                    close();
                    handleSave();
                  }}
                />
              </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    )}
  </Popup>
   
  );
}

export default VacationHomesFilter;