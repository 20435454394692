/* eslint-disable id-length */
/* eslint-disable new-cap */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Preview, print } from "react-html2pdf";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { BUSINESS_CATEGORY, MAGIC_NUMBERS } from "../../constant";
import ButtonTertiary from "../../components/button/ButtonTertiary";
import Prompt from "../../components/prompt/Prompt";
import useUserContext from "../../hooks/useUserContext";
import addFavorite from "../../pages/local_discount/addFavorite";
import deleteFavorite from "../../pages/local_discount/deleteFavorite";
import deleteCoupon from "../../pages/local_discount/deleteCoupon";
import updateCoupon from "../../pages/local_discount/updateCoupon";
import notify from "../../services/toast";
import { showPrompt } from "../../services/util";
import EditCouponModal from "../coupon_card/EditCouponModal";
import CouponLogo from "../../assets/CouponLogo.png";
import { Link } from "react-router-dom";
import { MdTimer } from "react-icons/md";

function FeaturedCoupons({ coupon, isFavorite, isOwner, fid, onReload }) {
    const { listing, couponName, useAge, couponCode, background, expiration, _id } = coupon;
    const { userState } = useUserContext();
    const promptRef = React.useRef(null);
    const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showBackSide, setShowBackSide] = React.useState(false);

    const handleShowPrompt = () => {
        showPrompt("Delete", "Are your sure you want to delete this coupon? This action cannot be undone.", promptRef, "open", setDisplayPromptMessage);
    };

    const handleFavorite = async () => {
        if (!isFavorite) {
            const response = await addFavorite(
                {
                    target: _id,
                    kind: "Coupon",
                },
                userState.accessToken
            );
            if (response.status === "success") {
                notify("This coupon was successfully added to your favorite list.", "info");
                if (onReload) {
                    onReload();
                }
            } else if (response.status === "fail") {
                let displayMessage = "";
                if (Array.isArray(response.message)) {
                    displayMessage = response.message[0].message;
                } else {
                    displayMessage = response.message;
                    if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
                        displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
                    }
                }
                notify(displayMessage, "error");
            }
        } else {
            const response = await deleteFavorite(fid, userState.accessToken);
            if (response.status === "success") {
                notify("This coupon was successfully removed from your favorite list.", "info");
                if (onReload) {
                    onReload();
                }
            } else if (response.status === "fail") {
                let displayMessage = "";
                if (Array.isArray(response.message)) {
                    displayMessage = response.message[0].message;
                } else {
                    displayMessage = response.message;
                    if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
                        displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
                    }
                }
                notify(displayMessage, "error");
            }
        }
    };

    const handleDelete = async () => {
        const response = await deleteCoupon(_id, userState.accessToken);
        if (response.status === "success") {
            notify("This coupon was successfully deleted.", "info");
            if (onReload) {
                onReload();
            }
        } else if (response.status === "fail") {
            let displayMessage = "";
            if (Array.isArray(response.message)) {
                displayMessage = response.message[0].message;
            } else {
                displayMessage = response.message;
                if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
                    displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
                }
            }
            notify(displayMessage, "error");
        }
    };

    const handleSave = async body => {
        const response = await updateCoupon(_id, body, userState.accessToken);
        if (response.status === "success") {
            notify("This coupon was successfully updated.", "info");
            if (onReload) {
                onReload();
            }
        } else if (response.status === "fail") {
            let displayMessage = "";
            if (Array.isArray(response.message)) {
                displayMessage = response.message[0].message;
            } else {
                displayMessage = response.message;
                if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
                    displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
                }
            }
            notify(displayMessage, "error");
        }
    };

    const handlePrint = id => {
        const isSmall = screen.width < 1600;
        if (isSmall) {
            const input = document.getElementById(`coupon-print-laptop-${id}`);
            html2canvas(input).then(canvas => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF();
                pdf.addImage(imgData, "JPEG", 0, 0);
                pdf.save("coupon.pdf");
            });
        } else {
            print("coupon", `coupon-print-${id}`);
        }
    };

    const expirationDate = new Date(parseInt(expiration.substring(0, 4), 10), parseInt(expiration.substring(5, 7), 10) - 1, parseInt(expiration.substring(8, 10), 10));

    return (
        <>
            <div className="relative overflow-auto items-center space-x-3 rounded-lg border border-gray-300 bg-white px-16 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                <div className="flex-shrink-0">
                    <img className="mx-auto h-24 w-24 flex-shrink-0 rounded-full"
                        src={
                            listing && listing.businessPhotos && listing.businessPhotos.logo
                                ? listing.businessPhotos.logo.small.url
                                : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"
                        }
                        alt="Listing Logo" />
                </div>
                <Link to={`marketplace/listing/${listing._id}`} className="focus:outline-none">
                    <div className="min-w-0 flex-1 text-center">
                        <p className="text-sm font-medium text-gray-900">{listing ? listing.businessName : "-"}</p>
                        {/* <p className=" text-sm text-gray-500">How To Use: {useAge}</p> */}
                        <div className="flex gap-x-4">
                            <dt className="flex-none">
                                <span className="sr-only">Address</span>
                                <svg className="h-5 w-5 text-gray-400 " fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                </svg>
                            </dt>
                            <dd className="text-sm text-gray-500">{listing ? listing.businessAddress.address : "-"}</dd>
                        </div>
                        <p className="truncate text-sm text-gray-500 mb-2">Ends: {format(expirationDate, "MMMM do YYY")}</p>
                    </div>
                    <div className="flex w-full h-32 items-center justify-center bg-[url('/src/assets/grungTextureGreen.jpg')] border-2 border-dashed rounded-l-lg font-medium text-white">
                        <p className="text-sm text-center leading-6 text-white">{couponName}
                            <p className="rounded-full bg-green-50 px-2 py-1 text-md font-bold text-green-700 ring-1 ring-inset ring-green-600/20">
                                {couponCode}
                            </p>
                        </p>
                    </div>

                </Link>
            </div>


            {!!isOwner && showEditModal && <EditCouponModal open={showEditModal} coupon={coupon} onClose={() => setShowEditModal(false)} onSave={handleSave} />}
            {!!isOwner && <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={handleDelete} />}
        </>
    );
}

FeaturedCoupons.propTypes = {
    coupon: PropTypes.object.isRequired,
    isFavorite: PropTypes.bool,
    isOwner: PropTypes.bool,
    fid: PropTypes.string,
    onReload: PropTypes.func,
};

export default FeaturedCoupons;
