/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import useUserContext from "../../hooks/useUserContext";

function Member({ member, selectedMembers, setSelectedMembers }) {
  const firstName = member?.firstName ? member.firstName : null;
  const lastName = member?.lastName ? member.lastName : null;
  const emailAddress = member?.emailAddress ? member.emailAddress : null;
  const photo = member?.photo?.small ? member.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png";
  const userId = member?.id ? member.id : member?._id ? member._id : null;
  const { userState } = useUserContext();
  const [userIds, setUserIds] = React.useState([]);

  const handleChange = event => {
    const selected = event.target.checked;
    if (selected) {
      const newMembers = [...selectedMembers, member];
      setSelectedMembers(newMembers);
    } else {
      const newMembers = selectedMembers.filter(member => (member?.id ? member.id !== userId : member._id !== userId));
      setSelectedMembers(newMembers);
    }
  };

  React.useEffect(() => {
    const Ids = selectedMembers.map(member => (member?.id ? member.id : member._id));
    setUserIds(Ids);
  }, [selectedMembers]);

  return (
    <li className="flex justify-between items-center p-2 first:pt-0 last:pb-0">
      <div className="flex items-center">
        <img className="h-10 w-10 rounded-full" src={photo} />
        <div className="ml-3 overflow-hidden">
          <p className="text-sm font-medium capitalize">
            {firstName} {lastName}
          </p>
          <p className="text-xs font-medium lowercase text-gray-500">{emailAddress}</p>
        </div>
      </div>
      {userId && userId !== userState.id ? (
        <input type="checkbox" checked={userIds.includes(userId) ? true : false} onChange={handleChange} className="focus:ring-primary-500 h-4 w-4 text-primary-400 border-gray-300" />
      ) : null}
    </li>
  );
}

export default Member;
