import React from "react";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import NonprofitInfo from "./NonprofitInfo";

import OwnerInfo from "../../../marketplace/add_listing/owner_info/OwnerInfo";

import BusinessAddress from "../../../marketplace/add_listing/business_address/BusinessAddress";
import Photos from "../../../marketplace/add_listing/photos/Photos";
import BusinessHours from "../../../marketplace/add_listing/business_hours/BusinessHours";
import ScholarshipPrompt from "./ScholarshipPrompt";


import ReviewNp from "../add_nonprofit/ReviewNp";

import useTitle from "../../../../hooks/useTitle";

function AddNonprofit() {
	useTitle("Add Nonprofit(s)");

	const STEPS = [
		"Nonprofit Information",
		"Owner Information",
		"Address",
		"Photos",
		"Hours",
     "Scholarship",
		"Review",
   
	];
	const [currentStep, setCurrentStep] = React.useState(0);
	const [listing, setListing] = useLocalStorage("nonprofit", {
		businessInfo: null,
		ownerInfo: null,
		coupon: null,
		address: null,
		photos: null,
		businessHours: null,
	});

	const renderComponent = (currentStep) => {
		switch (currentStep) {
			case 0:
				return (
					<NonprofitInfo
						steps={STEPS}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						setListing={setListing}
						listing={listing}
					/>
				);
			case 1:
				return (
					<OwnerInfo
						steps={STEPS}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						setListing={setListing}
						listing={listing}
            ownerTitle={"Nonprofit"}
					/>
				);

			case 2:
				return (
					<BusinessAddress
						steps={STEPS}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						setListing={setListing}
						listing={listing}
					/>
				);
			case 3:
				return (
					<Photos
						steps={STEPS}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						setListing={setListing}
						listing={listing}
					/>
				);
			case 4:
				return (
					<BusinessHours
						steps={STEPS}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						setListing={setListing}
						listing={listing}
					/>
				);
			case 5:
				return (
          <ScholarshipPrompt
          	steps={STEPS}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						listing={listing}/>
				);
      case 6:
				return (
          <ReviewNp
          	steps={STEPS}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						listing={listing}/>
				);
			default:
				break;
		}
	};
	return (
		<>
			<div className="max-w-full sm:max-w-7xl sm:mx-auto sm:px-6 sm:lg:px-8">
				<div className="mt-8 space-y-5">
					<div className="flex gap-3 items-center justify-center">
						<span className="text-sm text-gray-400">
							{`Step ${currentStep + 1} of ${STEPS.length}`}
						</span>
						<h1 className="text-md text-success-800">{STEPS[currentStep]}</h1>
					</div>
					{renderComponent(currentStep)}
				</div>
		
			</div>
		</>
	);
}

export default AddNonprofit;
