/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import ButtonSecondary from "./../button/ButtonSecondary";

function Pagination({ pagination, next, prev }) {
  return (
    <nav
      className={`${pagination.total <= pagination.limit ? "hidden" : "w-full py-3 flex flex-col space-y-2 items-center sm:flex-row sm:flex sm:items-center sm:justify-between"}`}
      aria-label="Pagination">
      <div className="block">
        <p className="text-sm text-gray-700 flex gap-1">
          Showing
          <span className="font-medium">{pagination.page * pagination.limit + 1} </span>
          to
          <span className="font-medium">{pagination.remaining <= 0 ? pagination.total : pagination.total - pagination.remaining}</span>
          of
          <span className="font-medium">{pagination.total}</span>
          results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <div className="flex gap-2">
          <ButtonSecondary action={prev} text="Previous" size="md" loading={pagination.page <= 0 ? true : false} />
          <ButtonSecondary action={next} text="Next" size="md" loading={pagination.remaining <= 0 ? true : false} />
        </div>
      </div>
    </nav>
  );
}

export default React.memo(Pagination);
