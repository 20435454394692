/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
import { Fragment } from "react";
import {
  FiClock,
} from "react-icons/fi";
import WorkshopDetails from "../../pages/workshops/workshop_details/WorkshopDetails";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CalendarMonthView({
  selectedMonth,
  selectedYear,
  events,
  onReload,
}) {
  const firstDate = new Date(selectedYear, selectedMonth - 1, 1);
  const lastDate = new Date(selectedYear, selectedMonth, 1);
  const startDate = new Date(selectedYear, selectedMonth - 1, 1);
  const endDate = new Date(selectedYear, selectedMonth - 1, 1);
  const nextMonthStartDate = new Date(selectedYear, selectedMonth, 1);
  const lastMonthDays = (firstDate.getDay() === 0 ? 6 : firstDate.getDay() - 1);
  startDate.setDate(firstDate.getDate() - lastMonthDays);
  lastDate.setDate(lastDate.getDate() - 1);
  const nextMonthDays = (lastDate.getDay() === 0 ? 0 : 7 - lastDate.getDay());
  endDate.setDate(lastDate.getDate() + nextMonthDays);

  const days = [];
  for (let i = 0; i < lastMonthDays; i++) {
    days.push({
      date: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate() + i}`,
      events: (events || []).filter(event => {
        const dt = new Date(event.eventDate);
        return startDate.getFullYear() === dt.getFullYear() && startDate.getMonth() === dt.getMonth() && startDate.getDate() + i === dt.getDate();
      }),
    });
  }
  for (let i = 0; i < lastDate.getDate(); i++) {
    days.push({
      date: `${firstDate.getFullYear()}-${firstDate.getMonth() + 1}-${firstDate.getDate() + i}`,
      events: (events || []).filter(event => {
        const dt = new Date(event.eventDate);
        return firstDate.getFullYear() === dt.getFullYear() && firstDate.getMonth() === dt.getMonth() && firstDate.getDate() + i === dt.getDate();
      }),
      isCurrentMonth: true,
    });
  }
  for (let i = 0; i < nextMonthDays; i++) {
    days.push({
      date: `${nextMonthStartDate.getFullYear()}-${nextMonthStartDate.getMonth() + 1}-${nextMonthStartDate.getDate() + i}`,
      events: (events || []).filter(event => {
        const dt = new Date(event.eventDate);
        return nextMonthStartDate.getFullYear() === dt.getFullYear() && nextMonthStartDate.getMonth() === dt.getMonth() && nextMonthStartDate.getDate() + i === dt.getDate();
      }),
    });
  }

  const selectedDay = days.find((day) => day.isSelected);

  return (<>
    <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
      <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
        <div className="bg-white py-2">
          M<span className="sr-only sm:not-sr-only">on</span>
        </div>
        <div className="bg-white py-2">
          T<span className="sr-only sm:not-sr-only">ue</span>
        </div>
        <div className="bg-white py-2">
          W<span className="sr-only sm:not-sr-only">ed</span>
        </div>
        <div className="bg-white py-2">
          T<span className="sr-only sm:not-sr-only">hu</span>
        </div>
        <div className="bg-white py-2">
          F<span className="sr-only sm:not-sr-only">ri</span>
        </div>
        <div className="bg-white py-2">
          S<span className="sr-only sm:not-sr-only">at</span>
        </div>
        <div className="bg-white py-2">
          S<span className="sr-only sm:not-sr-only">un</span>
        </div>
      </div>
      <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
        <div className={`hidden w-full lg:grid lg:grid-cols-7 ${days.length === 35 ? "lg:grid-rows-5" : "lg:grid-rows-6"} lg:gap-px`}>
          {days.map((day) => (
            <div
              key={day.date}
              className={classNames(
                day.isCurrentMonth ? "bg-white" : "bg-gray-50 text-gray-500",
                "relative py-2 px-3"
              )}
            >
              <time
                dateTime={day.date}
                className={
                  day.isToday
                    ? "flex h-6 w-6 items-center justify-center rounded-full bg-primary-400 font-semibold text-white"
                    : undefined
                }
              >
                {day.date.split("-").pop().replace(/^0/, "")}
              </time>
              {day.events.length > 0 && (
                <ol className="mt-2">
                  {day.events.slice(0, 2).map((event) => (
                    <li key={event._id}>
                      <Link to={`/workshop_details/${event._id}`}>
                          <button className="group flex">
                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-success-800">
                              {event.eventName}
                            </p>
                            <time
                              dateTime={event.datetime}
                              className="ml-3 hidden flex-none text-gray-500 group-hover:text-success-800 xl:block"
                            >
                              {(new Date(event.eventDate)).toString().substring(16, 21)}
                            </time>
                          </button>
                       </Link>
                    </li>
                  ))}
                  {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                </ol>
              )}
            </div>
          ))}
        </div>
        <div className={`isolate grid w-full grid-cols-7 ${days.length === 35 ? "grid-rows-5" : "grid-rows-6"} gap-px lg:hidden`}>
          {days.map((day) => (
            <button
              key={day.date}
              type="button"
              className={classNames(
                day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-white",
                !day.isSelected && day.isToday && "text-primary-400",
                !day.isSelected && day.isCurrentMonth && !day.isToday && "text-gray-900",
                !day.isSelected && !day.isCurrentMonth && !day.isToday && "text-gray-500",
                "flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10"
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  day.isSelected && "flex h-6 w-6 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-primary-400",
                  day.isSelected && !day.isToday && "bg-gray-900",
                  "ml-auto"
                )}
              >
                {day.date.split("-").pop().replace(/^0/, "")}
              </time>
              <span className="sr-only">{day.events.length} events</span>
              {day.events.length > 0 && (
                <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                  {day.events.map((event) => (
                    <span key={event._id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                  ))}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
    {selectedDay?.events.length > 0 && (
      <div className="py-10 px-4 sm:px-6 lg:hidden">
        <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
          {selectedDay.events.map((event) => (
            <li key={event._id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
              <div className="flex-auto">
                <p className="font-semibold text-gray-900">{event.eventName}</p>
                <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                  <FiClock className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {(new Date(event.eventDate)).toString().substring(16, 21)}
                </time>
              </div>
              <a
                href={event.href}
                className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100"
              >
                Edit<span className="sr-only">, {event.eventName}</span>
              </a>
            </li>
          ))}
        </ol>
      </div>
    )}
  </>);
}