/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";

function Modal({ children }) {
  return (
    <div className="relative z-[99999999999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
