/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function createRSVP(accessToken, eventId, payload) {
  console.log("in rsvp", eventId);
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/event/${eventId}/attendee`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createRSVP;
