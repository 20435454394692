/* eslint-disable */
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {format} from "date-fns";

function VolunteerCard({ index, event }) {
const navigate = useNavigate();


  function handleClick() {
		
		navigate(`/event_details/${event._id}`);
	}

	return (
		<div key={index} className="flex  shadow-xl hover:shadow-gray-500 rounded bg-white p-4 flex-col items-start justify-between">
					{/* <div className="w-full h-[200px] bg-center bg-no-repeat bg-cover bg-[url('https://chopra.brightspotcdn.com/24/a8/faa072655ded01ab5619b910f567/volunteers.jpg')]">
					</div> */}
          <div className="relative mt-5 mb-5 aspect-[16/9] sm:aspect-[2/1] cursor-pointer lg:aspect-square lg:w-64 lg:shrink-0" onClick={handleClick}>
              <img
                  src={event.eventImage?.large?.url ? event.eventImage.large.url : "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"}
                  alt=""
                  className="absolute inset-0 h-full w-full rounded bg-gray-50 object-cover"
				  
              />
              <div className="absolute inset-0 rounded ring-1 ring-inset ring-gray-900/10" />
            </div>
					<div className="w-full bg-white p-5 space-y-3">
						<div className="w-full flex items-center gap-2">
							<h1 className="text-xl text-success-800 font-bold">
								{event.eventName}
							</h1>
						</div>
						<div className="w-full flex items-center gap-2">
							<h1 className="text-m text-success-800 capitalize text-ellipsis overflow-hidden whitespace-nowrap">
								<time>
                    {format(new Date(event.eventDate), "PPPP")}
                </time>
							</h1>
						</div>
						<div className="w-full flex items-center gap-2">
							<h1 className="text-md text-success-800 capitalize ">
								{event.eventLocation.streetAddress} {'\u2022'} {event.eventLocation.city}
							</h1>
						</div>
            <div className="relative bottom-50">
              <Link 
              to={`/event_details/${event._id}`}
              className="w-full text-primary-400 underline  gap-2">
								<i className="fa fa-sign-in"></i> View Event
             </Link>
             </div>
					</div>
				</div>
        
	);
}

export default VolunteerCard;
