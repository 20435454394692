/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import { Fragment, useState } from "react";
import { format } from "date-fns";
import { Dialog, Disclosure, Transition, Menu } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FunnelIcon, MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import Pagination from "../pagination/Pagination";
import { WORKSHOP_CATEGORY, COHATCH_MARKETS, COHATCH_LOCATIONS } from "../../constant";
import { Link } from "react-router-dom";

const filters = [
    {
        id: "category",
        name: "Filter By Category",
        options: [
            { id: "", title: "All" },
            ...WORKSHOP_CATEGORY.map((category) => ({
                id: category.value,
                title: category.label,
            })),
        ],
    },
    {
        id: "location",
        name: "Filter By Location",
        options: [
            { id: "", title: "All" },
            ...Object.keys(COHATCH_LOCATIONS).map((city) => ({
                id: city.toLowerCase(),
                title: city,
                locations: COHATCH_LOCATIONS[city].locations,
            })),
        ],
    },
];





// const events = [
//     {
//       id: 1,
//       title: "Boost your conversion rate",
//       href: "#",
//       description:
//         "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.",
//       imageUrl:
//         "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80",
//       date: "Mar 16, 2020",
//       datetime: "2020-03-16",
//       category: { title: "Marketing", href: "#" },
//       author: {
//         name: "Michael Foster",
//         role: "Co-Founder / CTO",
//         href: "#",
//         imageUrl:
//           "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//       },
//     },
//     // More posts...
//   ];


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}


export default function CalendarListView({ events, pagination, handleNextFetch, handlePrevFetch, setFilterListId }) {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

    return (
        <>
            <div className="bg-white">
                <div>
                    {/* Mobile filter dialog */}
                    <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-40 flex">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transition ease-in-out duration-300 transform"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transition ease-in-out duration-300 transform"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                        <div className="flex items-center justify-between px-4">
                                            <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                            <button
                                                type="button"
                                                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                                onClick={() => setMobileFiltersOpen(false)}
                                            >
                                                <span className="sr-only">Close menu</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>

                                        {/* Filters */}
                                        <form className="space-y-10 divide-y divide-gray-200">
                                            {filters.map((section) => (
                                                <div key={section.id} className="border-b border-gray-200 py-6">
                                                    <fieldset>
                                                        <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
                                                        <div className="space-y-3 pt-6">
                                                            {section.options.map((option, optionIdx) => (
                                                                <div key={option.id} className="flex items-center">
                                                                    {option.locations ? (
                                                                        // Display city options
                                                                        <Disclosure as="div" key={option.id} className="border-t border-gray-200 px-4 py-6">
                                                                            {({ open }) => (
                                                                                <>
                                                                                    <h3 className="-mx-2 -my-3 flow-root">
                                                                                        <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                                                            <span className="font-medium text-gray-900">{option.title}</span>
                                                                                            <span className="ml-6 flex items-center">
                                                                                                {open ? <MinusIcon className="h-5 w-5" aria-hidden="true" /> : <PlusIcon className="h-5 w-5" aria-hidden="true" />}
                                                                                            </span>
                                                                                        </Disclosure.Button>
                                                                                    </h3>
                                                                                    <Disclosure.Panel className="pt-6">
                                                                                        <div className="space-y-6">
                                                                                            {option.locations.map((location) => (
                                                                                                <div key={location.value} className="flex items-center">
                                                                                                    <input
                                                                                                        id={`${section.id}-${optionIdx}-${location.value}-mobile`}
                                                                                                        name="notification-method"
                                                                                                        type="radio"
                                                                                                        className="h-4 w-4 border-gray-300 text-primary-400 focus:ring-primary-400"
                                                                                                        onChange={() => setFilterListId(location.value)}
                                                                                                    />
                                                                                                    <label htmlFor={`${section.id}-${optionIdx}-${location.value}-mobile`} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                                                        {location.label}
                                                                                                    </label>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </Disclosure.Panel>
                                                                                </>
                                                                            )}
                                                                        </Disclosure>
                                                                    ) : (
                                                                        // Display regular options
                                                                        <div key={option.id} className="flex items-center">
                                                                            <input
                                                                                id={`${section.id}-${optionIdx}-mobile`}
                                                                                name="notification-method"
                                                                                type="radio"
                                                                                className="h-4 w-4 border-gray-300 text-primary-400 focus:ring-primary-400"
                                                                                onChange={() => setFilterListId(option.id)}
                                                                            />
                                                                            <label htmlFor={`${section.id}-${optionIdx}-mobile`} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                                {option.title}
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            ))}
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition.Root>

                    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex items-baseline pb-6">

                            <div className="flex items-center">
                                <Menu as="div" className="relative inline-block text-left">
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                    </Transition>
                                </Menu>
                                <button
                                    type="button"
                                    className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                                    onClick={() => setMobileFiltersOpen(true)}
                                >
                                    <span className="sr-only">Filters</span>
                                    <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>

                        <section aria-labelledby="products-heading" className="pb-12 pt-6">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                                {/* Filters */}

                                <form className="space-y-2 divide-y divide-gray-200">
                                    {filters.map((section) => (
                                        <div key={section.id} className=" py-6">
                                            <fieldset>
                                                <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
                                                <div className="space-y-3 pt-6">
                                                    {section.options.map((option, optionIdx) => (
                                                        <div key={option.id} className="flex items-center">
                                                            {option.locations ? (
                                                                // Display city options
                                                                <Disclosure as="div" key={option.id} className="border-t border-gray-200 ">
                                                                    {({ open }) => (
                                                                        <>
                                                                            <h3 className="-mx-2 -my-3 flow-root">
                                                                                <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                                                    <span className="font-medium text-gray-900">{option.title}</span>
                                                                                    <span className="ml-6 flex items-center">
                                                                                        {open ? <MinusIcon className="h-5 w-5" aria-hidden="true" /> : <PlusIcon className="h-5 w-5" aria-hidden="true" />}
                                                                                    </span>
                                                                                </Disclosure.Button>
                                                                            </h3>
                                                                            <Disclosure.Panel className="pt-6">
                                                                                <div className="space-y-6">
                                                                                    {option.locations.map((location) => (
                                                                                        <div key={location.value} className="flex items-center">
                                                                                            <input
                                                                                                id={`${section.id}-${optionIdx}-${location.value}-mobile`}
                                                                                                name="notification-method"
                                                                                                type="radio"
                                                                                                className="h-4 w-4 border-gray-300 text-primary-400 focus:ring-primary-400"
                                                                                                onChange={() => setFilterListId(location.value)}
                                                                                            />
                                                                                            <label htmlFor={`${section.id}-${optionIdx}-${location.value}-mobile`} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                                                {location.label}
                                                                                            </label>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </Disclosure.Panel>
                                                                        </>
                                                                    )}
                                                                </Disclosure>
                                                            ) : (
                                                                // Display regular options
                                                                <div key={option.id} className="flex items-center">
                                                                    <input
                                                                        id={`${section.id}-${optionIdx}-mobile`}
                                                                        name="notification-method"
                                                                        type="radio"
                                                                        className="h-4 w-4 border-gray-300 text-primary-400 focus:ring-primary-400"
                                                                        onChange={() => setFilterListId(option.id)}
                                                                    />
                                                                    <label htmlFor={`${section.id}-${optionIdx}-mobile`} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                        {option.title}
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </fieldset>
                                        </div>
                                    ))}
                                </form>

                                {/* Product grid */}
                                <div className="lg:col-span-3">{/* Your content */}
                                    <div className="bg-white py-24 sm:py-6">
                                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                            <div className="mt-16 space-y-20 lg:mt-1 lg:space-y-20">
                                                {events.map((event) => (
                                                    <article key={event.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                                                        <Link to={`/workshop_details/${event._id}`}>
                                                            <div className="relative aspect-[16/9] sm:aspect-[2/1] cursor-pointer lg:aspect-square lg:w-64 lg:shrink-0">
                                                                <img
                                                                    src={event.eventImage?.large?.url ? event.eventImage.large.url : "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"}
                                                                    alt=""
                                                                    className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                                                                />
                                                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                                            </div>
                                                        </Link>
                                                        <div>
                                                            <div className="flex items-center gap-x-4 text-xs">
                                                                <a
                                                                    // href={event.category.href}
                                                                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                                                                >
                                                                    {event ? (WORKSHOP_CATEGORY.find(element => element.value === event.eventCategory)).label : "- "}

                                                                </a>
                                                            </div>
                                                            <div className="group relative max-w-xl">
                                                                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                                                    <Link to={`/workshop_details/${event._id}`}>
                                                                        <a
                                                                        // href={event.href}
                                                                        >
                                                                            <span className="absolute cursor-pointer inset-0" />
                                                                            {event.eventName}
                                                                        </a>
                                                                    </Link>
                                                                    <div className="text-sm">
                                                                        <time
                                                                            //  dateTime={event.datetime}
                                                                            className="text-gray-500">

                                                                            {format(new Date(event.eventDate), "PPPP")}
                                                                        </time>
                                                                    </div>
                                                                </h3>
                                                            </div>
                                                            <p className="mt-5 text-sm leading-6 text-gray-600 line-clamp-3 ">{event.eventDescription}</p>
                                                            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                                                                <div className="relative flex items-center gap-x-4">
                                                                    <Link to={`/workshop_details/${event._id}`}>
                                                                        <button className="group flex">
                                                                            <div className="text-sm font-semibold leading-6 text-primary-400 hover:text-primary-100">
                                                                                View Event <span aria-hidden="true">&rarr;</span>
                                                                            </div>
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <h1><Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} /></h1>
                    </main>
                </div>
            </div>
        </>
    );
}
