/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import Spinner from "../../../../components/spinner/Spinner";

const libraries = ["places"];
function PropertyAddress({
  property,
  setProperty,
}) {
  const [autoComplete, setAutoComplete] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
    region: "US",
  });

  const onLoad = autoComplete => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete.getPlace();
      const {
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
      } = autoComplete.getPlace();
      if (formatted_address) {
        const addresses = formatted_address.split(",");
        setProperty({
          ...property,
          propertyLocation: {
            ...property?.propertyLocation,
            country: "US",
            streetAddress: addresses[0].trim(),
            fullAddress: formatted_address,
            city: addresses[1].trim(),
            state: addresses[2].trim().split(" ")[0].trim(),
            zip: addresses[2].trim().split(" ")[1].trim(),
            lat: lat(),
            lng: lng(),
          },
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const submitData = (key, value) => {
    setProperty((prevState) => {
      return {
        ...prevState,
        propertyLocation: {
          ...prevState?.propertyLocation,
          [key]: value,
        },
      };
    });
  };
  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Property Address</h3>
          <p className="mt-1 text-sm text-gray-500">Please list the address of your property. The street address will not be displayed on the website.</p>
        </div>
              

        <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6">
        <div className="w-full flex justify-between items-center">
          <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
            Street Address
          </label>
          <span className="w-2 h-2 rounded-full bg-red-500"></span>
        </div>
          {isLoaded ? (
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                name="street-address"
                id="name"
                className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3"
                aria-describedby="name-optional"
                value={property?.propertyLocation?.fullAddress}
                onChange={event => submitData("fullAddress", event.target.value)}
              />
            </Autocomplete>
          ) : (
            <Spinner displayText="Loading..." />
          )}
          <p className="mt-2 text-sm text-gray-500">The address won't be displayed on the website!</p>
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
        <div className="w-full flex justify-between items-center">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            City
          </label>
          <span className="w-2 h-2 rounded-full bg-red-500"></span>
        </div>
          <input
            type="text"
            name="city"
            id="city"
            autoComplete="address-level2"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => submitData("city", event.target.value)}
            value={property?.propertyLocation?.city}
          />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
        <div className="w-full flex justify-between items-center">
          <label htmlFor="region" className="block text-sm font-medium text-gray-700">
            State / Province
          </label>
          <span className="w-2 h-2 rounded-full bg-red-500"></span>
        </div>
          <input
            type="text"
            name="region"
            id="region"
            autoComplete="address-level1"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => submitData("state", event.target.value)}
            value={property?.propertyLocation?.state}
          />
        </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <div className="w-full flex justify-between items-center">
          <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
            ZIP / Postal code
          </label>
          <span className="w-2 h-2 rounded-full bg-red-500"></span>
        </div>
          <input
            type="text"
            name="postal-code"
            id="postal-code"
            autoComplete="postal-code"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => submitData("zip", event.target.value)}
            value={property?.propertyLocation?.zip}
          />
        </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyAddress;