import React from "react";
import PropTypes from "prop-types";

function DisplayCouponCard({ coupon, removeCoupon, remove = true }) {
  return (
    <div className="w-fit bg-white text-success-800 rounded-md shadow-md p-3">
      <div className="w-full flex gap-2">
        {remove ? (
          <span data-coupon={coupon.index} className="block cursor-pointer material-icons-outlined" onClick={removeCoupon}>
            cancel
          </span>
        ) : null}

        <p className="text-md capitalize">{coupon.value.couponCode}</p>
      </div>
    </div>
  );
}

DisplayCouponCard.propTypes = {
  coupon: PropTypes.object.isRequired,
  removeCoupon: PropTypes.func,
  remove: PropTypes.bool,
};

export default DisplayCouponCard;
