import { BUSINESS_CATEGORY } from "../../constant";

const generateCategory = () => {
  const categories = [];

  for (const category in BUSINESS_CATEGORY) {
    categories.push(BUSINESS_CATEGORY[category]["mainCategory"]);
  }

  return categories;
};

export default generateCategory;
