/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */

import React, { useState } from "react";
import {FaWindowClose} from "react-icons/fa";
import Popup from "reactjs-popup";
import { MdLocalSee } from "react-icons/md";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import FileUploader from "../file_uploader/FileUploader";
import useUserContext from "../../hooks/useUserContext";
import SelectInput from "../select_input/SelectInput";
import {WORKSHOP_CATEGORY} from "../../constant";
import format from "date-fns/format";


const libraries = ["places"];

const imageCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };

const eventImageCriteria = { requiredSize: 5e6, requiredDimensions: { width: 250, height: 250 } };

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

function EditWorkshopForm({
  button,
  video,
  title,
  poster,
  event,
  setEvent,
  handleSubmit,
  chooseMessage
}) {
  const [editEvent, setEditEvent] = React.useState({
    eventName: event.eventName,
    eventDescription: event.eventDescription,
    eventDate: event.eventDate,
    startTime: event.startTime,
    endTime: event.endTime,
    eventCategory: event.eventCategory,
    eventLink: event.eventLink,
    eventLocation: {
      streetAddress: event.eventLocation.streetAddress,
      city: event.eventLocation.city,
      state: event.eventLocation.state,
      zip: event.eventLocation.zip,
      country: event.eventLocation.country,
    },
    eventCOhatchMarket: event.eventCOhatchMarket,
    eventCOhatchLocation: event.eventCOhatchLocation,
    eventCOhatchMeetingRoom: event.eventCOhatchMeetingRoom,
    eventImage: event.eventImage,
    eventOwnerImage: event.eventOwnerImage,
    eventOwnerFirstName: event.eventOwnerFirstName,
    eventOwnerLastName: event.eventOwnerLastName,
    aboutOrganization: event.aboutOrganization,
  });

  const { userState } = useUserContext();
  const [autoComplete, setAutoComplete] = useState(null);
  const [fullAddress, setFullAddress] = useState(event?.eventLocation?.fullAddress || "");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
    region: "US",
  });

  const onLoad = autoComplete => {
    setAutoComplete(autoComplete);
  };

  const handleUpload = result => {
    setEditEvent({
      ...editEvent,
      eventImage: result,
    });
  };

  const handleOwnerImageUpload = result => {
    setEditEvent({
      ...editEvent,
      eventOwnerImage: result,
    });
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete.getPlace();
      const {
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
      } = autoComplete.getPlace();
      if (formatted_address) {
        const addresses = formatted_address.split(",");
        setEditEvent({
          ...editEvent,
          eventLocation: {
            ...editEvent.eventLocation,
            fullAddress: formatted_address,
            streetAddress: addresses[0].trim(),
            city: addresses[1].trim(),
            state: addresses[2].trim().split(" ")[0].trim(),
            zip: addresses[2].trim().split(" ")[1].trim(),
            lat: lat(),
            lng: lng(),
          },
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleChange = (key, value) => {
    setEditEvent({
      ...editEvent,
      [key]: value,
    });
  };

  const handleChangeAddress = (key, value) => {
    setEditEvent({
      ...editEvent,
      eventLocation: {
        ...editEvent.eventLocation,
        [key]: value,
      },
    });
  };

  const submitData = (key, value) => {
    setEditEvent((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const testingFillForm = () => {
    console.log("testing pre fill");
    setEditEvent({
      eventName: "TESTING",
      eventDescription: "Event Description. Testing.",
      eventDate: "2023-04-25",
      startTime: "17:00",
      endTime: "18:30",
      eventCategory: "community_culture",
      eventLocation: {
        city: "New York", 
        country: "US", 
        fullAddress: "555 Madison Ave, New York, NY 10022", 
        lat: 40.7613149,
        lng: -73.9726805, 
        state: "NY", 
        streetAddress: "555 Madison Ave", 
        zip: "10022",
      },
      eventImage: {
        large: {
          filename: "3fe13c2c6719d61b53a79d12268a48e6.png",
          height: 375,
          url: "https://nyc3.digitaloceanspaces.com/cohatch-media-assets/event_photos/44c78610-bdfa-45b2-bc94-f129476f701e.png",
          width: 375,
        },
        small: {
          filename: "3fe13c2c6719d61b53a79d12268a48e6.png",
          height: 250,
          url: "https://nyc3.digitaloceanspaces.com/cohatch-media-assets/event_photos/580c783a-cbeb-404d-a79b-5e88c7d30bc9.png",
          width: 250,
        }  
      },
      eventOwnerImage: {
        large: {
          filename: "d5cf4e08a6515e927ad5a1e3f5176c9f.png",
          height: 600,
          url: "https://nyc3.digitaloceanspaces.com/cohatch-media-assets/event_photos/a1a50b31-1f59-48a8-8305-acb76e73c414.png",
          width: 600,
        },
        small: {
          filename: "d5cf4e08a6515e927ad5a1e3f5176c9f.png",
          height: 400,
          url: "https://nyc3.digitaloceanspaces.com/cohatch-media-assets/event_photos/b0cef08e-e959-4d2b-855d-cd739e694e81.png",
          width: 400,
        }  
      },
      eventOwnerFirstName: "Alex",
      eventOwnerLastName: "Testing",
      aboutOrganization: "Testing For Event.",
    });
  };

  return (
 
    <Popup trigger={button} modal nested position="right center">
    {close => (
      <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
            <div className="w-full flex justify-between">
              <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">{title}</h1>
              <button className="button" onClick={() => { close(); }} >
                <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close"/> </button> 
            </div>
            
            <button onClick={testingFillForm}>
              TEST FILL FOR DEVELOPMENT*
            </button>

            <div className="w-full bg-white overflow-hidden shadow rounded-lg">
      <div className="w-full px-4 py-5 sm:p-6 space-y-2">
            <div className="sm:col-span-4">
              <label htmlFor="workshop-title" className="block text-sm font-medium text-gray-700">
              Workshop Title
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
              <div className="mt-1">
                <input
                  id="workshop-title"
                  name="workshop-title"
                  type="text"
                  defaultValue={editEvent.eventName}
                  // onChange={(evt) => {
                  //   setEditEvent("eventName", evt.target.value);
                  //   console.log(event);
                  // }}
                  onChange={(evt) => {
                    handleChange("eventName", evt.target.value);
                    console.log(evt.target.value);
                    console.log(editEvent);
                  }}
                //   onChange={ (evt) => {
                //     console.log(evt.target.value);
                //     setEditEvent(...editEvent, (editEvent["eventName"]= evt.target.value) );
                //     setEditEvent((prevState) => {
                //       return {
                //         ...prevState,
                //         [key]: value,
                //       };
                //     });
                //   }
                // }
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>
            </div>
        <div className="w-full">
          <p className="text-sm text-gray-300">30 characters max</p>
        </div>

        

          <div className="col-span-6 sm:col-span-3">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="workshop_category" className="block text-sm font-medium text-gray-700">
                Workshop Category
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <SelectInput
              options={WORKSHOP_CATEGORY}
              noOptionsMessage="No categories found"
              placeholder="Select event type"
              defaultValue={() => {
                return editEvent?.eventCategory ? WORKSHOP_CATEGORY.find(item => item.value === editEvent?.eventCategory) : null;
              }}
              setSelectedValue={(event) => event ? submitData("eventCategory", event.value) : submitData("eventCategory", null)}
              // Still need to fix    
              // onChange={(evt) => {
                  //   handleChange("eventCategory", evt.target.value);
                  //   console.log(evt.target.value);
                  //   console.log(editEvent);
                  // }}
                  // //
             />
          </div>

        <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:gap-3 sm:justify-between sm:items-center">
          <div className="w-full">
            <div className="w-full flex justify-between">
              <label htmlFor="event_date" className="block text-sm font-medium text-gray-700">
                Event Date
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <div className="mt-1">
              <input
                type="date"
                name="event_date"
                id="event_date"
                aria-describedby="date-optional"
                defaultValue={String(editEvent.eventDate).slice(0, 10)}
                onChange={(evt) => {
                  handleChange("eventDate", evt.target.value);
                  console.log(evt.target.value);
                  

                }}
                // onChange={evt => {
                //   setEditEvent({
                //     ...editEvent,
                //     eventDate: evt.target.value,
                //   });
                //   console.log(evt.target.value);
                // }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              />
            </div>
            <p className="text-sm text-gray-300"></p>
          </div>
                    <div className="col-span-6 sm:col-span-3">
                    <div className="w-full flex justify-between">
                      <label
                        htmlFor="startTime"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Start Time
                      </label>
                      <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      </div>
                      <input
                        type="time"
                        name="startTime"
                        id="startTime"
                        defaultValue={editEvent.startTime}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                        // onChange={(evt) => handleChange("startTime", evt.target.value)}
                        onChange={(evt) => {
                          handleChange("startTime", evt.target.value);
                          console.log(evt.target.value);
                          console.log(editEvent);
                        }}
                        required
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                    <div className="w-full flex justify-between">
                      <label
                        htmlFor="endTime"
                        className="block text-sm font-medium text-gray-700"
                      >
                        End Time
                      </label>
                      <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      </div>
                      <input
                        type="time"
                        name="endTime"
                        id="endTime"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                        // onChange={(evt) => handleChange("endTime", evt.target.value)}
                        onChange={(evt) => {
                          handleChange("endTime", evt.target.value);
                          console.log(evt.target.value);
                          console.log(editEvent);
                        }}
                        defaultValue={editEvent.endTime}
                        required
                      />
                    </div>
          
        </div>
        <div className="w-full">
          
        <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Details of Event
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
              <div className="mt-1">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  defaultValue={editEvent.eventDescription}
                  // onChange={(evt) => handleChange("eventDescription", evt.target.value)}
                  onChange={(evt) => {
                    handleChange("eventDescription", evt.target.value);
                    console.log(evt.target.value);
                    console.log(editEvent);
                  }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">150 characters max</p>
            </div>

            <div className="sm:col-span-6 mt-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Link for the Virtual Event
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                            http://
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                          placeholder="www.example.com"
                          defaultValue={event?.eventLink || ""}
                            onChange={(evt) => {
                              handleChange("eventLink", evt.target.value);
                              console.log(evt.target.value);
                              
                            }}
                        />
                      </div>
                    </div>

            <div className="sm:col-span-6">
              <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                Street address
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
              <div className="mt-1">
                {isLoaded &&
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <input
                      type="text"
                      name="street-address"
                      id="name"
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3"
                      aria-describedby="name-optional"
                      defaultValue={editEvent.eventLocation.streetAddress}
                      // onChange={event => setFullAddress(event.target.value)}
                      onChange={(evt) => handleChangeAddress("streetAddress", evt.target.value)}

                    />
                  </Autocomplete>
                }
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                City
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
              <div className="mt-1">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level2"
                  defaultValue={editEvent.eventLocation.city}
                  onChange={(evt) => handleChangeAddress("city", evt.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                State / Province
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
              <div className="mt-1">
                <input
                  type="text"
                  name="region"
                  id="region"
                  autoComplete="address-level1"
                  defaultValue={editEvent.eventLocation.state}
                  onChange={(evt) => handleChangeAddress("state", evt.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                ZIP / Postal code
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
              <div className="mt-1">
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  defaultValue={editEvent.eventLocation.zip}
                  onChange={(evt) => handleChangeAddress("zip", evt.target.value)}
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>
            </div>
          
          <div className="w-full pt-4 flex justify-between items-center">
            <label htmlFor="event_image" className="block text-sm font-medium text-gray-700">
              Add Event Image
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
          <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
            {editEvent.eventImage?.small.url && <img src={editEvent.eventImage.small.url} className="w-full h-full" alt="cover image upload" />}
            <FileUploader
              fileType="image/*"
              isMultiple={false}
              validationCriteria={eventImageCriteria}
              DisplayIcon={PhotoDisplay}
              accessToken={userState.accessToken}
              folder="event_photos"
              description="Image - Min: 400 X 400, Max: 5MB"
              numberOfFiles={1}
              callback={handleUpload}
            />
          </div>

  
      <div className="space-y-6 bg-white py-6 px-4 sm:p-1">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 pt-8 text-success-800">Host and Organization Information</h3>
        </div>
        <div className="col-span-3">
        <div className="w-full flex justify-between items-center mb-1">
        <label className="block text-sm font-medium text-gray-700">Profile Image</label>
        <span className="w-2 h-2 rounded-full bg-red-500"></span>
      </div>
            <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
              {event?.eventOwnerImage?.small.url ? <img src={event?.eventOwnerImage.small.url} className="w-full h-full" alt="event owner image upload" /> : <p className="w-full pt-8 text-md text-center text-gray-400">Profile Image</p>}
              <FileUploader
                fileType="image/*"
                isMultiple={false}
                validationCriteria={imageCriteria}
                DisplayIcon={PhotoDisplay}
                accessToken={userState.accessToken}
                folder="event_photos"
                description="Image - Min: 400 X 400, Max: 5MB"
                numberOfFiles={1}
                callback={handleOwnerImageUpload}
              />
            </div>
          </div>

        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
            <input
              // onChange={(evt) => submitData("eventOwnerFirstName", evt.target.value)}
              onChange={(evt) => {
                handleChange("eventOwnerFirstName", evt.target.value);
                console.log(evt.target.value);
                console.log(editEvent);
              }}
              type="text"
              name="first_name"
              id="first_name"
              autoComplete="off"
              defaultValue={editEvent?.eventOwnerFirstName}
              placeholder=""
              aria-describedby="name-optional"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
            <input
              // onChange={(evt) => submitData("eventOwnerLastName", evt.target.value)}
              onChange={(evt) => {
                handleChange("eventOwnerLastName", evt.target.value);
                console.log(evt.target.value);
                console.log(editEvent);
              }}
              type="text"
              name="last_name"
              id="last_name"
              autoComplete="off"
              defaultValue={editEvent?.eventOwnerLastName}
              placeholder=""
              aria-describedby="name-optional"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
        </div>

          
          
          <div className="col-span-6">
          <label htmlFor="about-organization" className="block text-sm font-medium text-gray-700">
            About Organization
          </label>
          <div className="mt-1">
            <textarea
              id="about-organization"
              name="about-organization"
              rows={3}
              placeholder="Please share a brief description of the host."
              // onChange={(evt) => submitData("aboutOrganization", evt.target.value)}
              onChange={(evt) => {
                handleChange("aboutOrganization", evt.target.value);
                console.log(evt.target.value);
              }}
              defaultValue={editEvent?.aboutOrganization}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          </div>
        </div>
      </div>
    
          </div>
          
        <button
              onClick={() => {
                handleSubmit(editEvent, event._id);
                close();
              }}
              type="button"
              className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
            >
              Submit
            </button>
      </div>
    </div>
          </div>
        </div>
      </div>
    </div>
    )}
  </Popup>
  );
}

export default EditWorkshopForm;
