import React from "react";
import DefaultState from "../../../components/default_state/DefaultState";


function FavoriteListings() {
  



  return (
     <div className="hidden sm:block bg-white">
      <div className="bg-white">
        <div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
    <DefaultState icon="favorite" heading="No favorites yet" description="Your favorite Nonprofits will appear here" />
    </div>
    </div>
    </div>
  );
}

export default FavoriteListings;
