/* eslint-disable react/prop-types */

export default function PropertyBookingLink({
  property,
  setProperty,
}) {
    return (
<>
    <div>
        <form action="#" method="POST">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="text-center">
                        <h3 className="text-lg font-medium leading-6 text-success-800">Booking Reference Link</h3>
                        <p className="mt-1 text-sm text-gray-500">Please add a link for an user to book the vacation home from.</p>
                    </div>
                    <div className="grid grid-cols-1">
                        <div>
                            <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                                Website
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                                    http://
                                </span>
                                <input
                                    type="text"
                                    name="company-website"
                                    id="company-website"
                                    className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                                    placeholder="www.example.com"
                                    value={property?.propertyLink || ""}
                                    onChange={(evt) => setProperty({
                                        ...property,
                                        propertyLink: evt.target.value
                                    })}
                                />
                            </div>
                           
        <div className="sm:col-span-4">
          <div className="w-full flex justify-between items-center pt-4">
            <label htmlFor="coupon_code" className="block text-sm font-medium text-gray-700">
            Coupon Code
            </label>
          </div>
          <p className="mt-1 text-sm text-gray-500">Explain how to receive your property discount here</p>
            <input
              type="text"
              name="coupon_code"
              id="coupon_code"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              autoComplete="off"
              placeholder="ABC123"
              value={property?.propertyCouponCode || ""}
              onChange={(evt) => setProperty({
                  ...property,
                  propertyCouponCode: evt.target.value
              })}
              aria-describedby="name-optional"
            />
        </div>
        <p className="text-sm text-gray-300">30 characters max</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</>
  );
}