/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable wrap-iife */
/*eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaHome, FaUmbrellaBeach, FaUsers, FaCaravan } from "react-icons/fa";
import { MdCabin } from "react-icons/md";
import { GiMushroomHouse } from "react-icons/gi";
import { HiHomeModern } from "react-icons/hi2";
import { TbDiscount } from "react-icons/tb";
import { RiMickeyFill, RiHomeHeartFill } from "react-icons/ri";
import { NavLink, Link } from "react-router-dom";
import {
	GoogleMap,
	Marker,
	InfoWindow,
	OverlayView,
	useLoadScript,
} from "@react-google-maps/api";
import VacationCard from "../../../components/vacation_card/VacationCard";
import DefaultState from "../../../components/default_state/DefaultState";
import PropertySearch from "../../../components/property_search/PropertySearch";
import InfoWindowModal from "../../../components/info_window/InfoWindowModal";
import useUserContext from "./../../../hooks/useUserContext";
import notify from "../../../services/toast";
import Pagination from "../../../components/pagination/Pagination";
import { MAGIC_NUMBERS, DISNEY_CLUBS } from "../../../constant";
import Spinner from "../../../components/spinner/Spinner";
import DisneyPage from "../disney/Disney";
import useLocalStorage from "../../../hooks/useLocalStorage";
import getPropertyByType from "./getPropertyByType";
import ImgDisneyCard from "../../../assets/DisneyCard.png";

const tabs = [
	{ pathName: "All Homes", value: "", icon: FaHome, end: true },
	{ pathName: "Vacation Deals", value: "vacation_deals", icon: TbDiscount },
	{
		pathName: "Near the Beach",
		value: "beach_vacation",
		icon: FaUmbrellaBeach,
	},
	{ pathName: "For Large Groups", value: "family_homes", icon: FaUsers },
	{ pathName: "Cabins", value: "cabins", icon: MdCabin },
	/* { pathName: "Disney", value: "disney_resort", icon: RiMickeyFill}, */
	{ pathName: "Tiny Homes", value: "tiny_home", icon: HiHomeModern },
	{ pathName: "Campers", value: "camper", icon: FaCaravan },
	{ pathName: "Unique Stays", value: "unique_stay", icon: GiMushroomHouse },
	{ pathName: "Local Stays", value: "local_stays", icon: RiHomeHeartFill },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function AllVacationHomes() {
	const {
		userState: { accessToken },
	} = useUserContext();
	const navigate = useNavigate();
	const [fetching, setFetching] = useState(false);
	const [properties, setProperties] = useState([]);
	const [filters, setFilters] = useLocalStorage("vacation_filters", {
		ratings: [],
		bedrooms: [],
		beds: [],
		bathrooms: [],
		min: 0,
		max: 0,
		amenities: {
			bathroom: {},
			bedroomLaundry: {},
			entertainmentTech: {},
			kitchen: {},
			safety: {},
			heatingCooling: {},
			family: {},
			parking: {},
			outdoor: {},
			accessibility: {},
		},
	});
	const [keyword, setKeyword] = useState("");
	const [pagination, setPagination] = useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 12,
	});
	const [viewMode, setViewMode] = useState(0);
	const [infoPosition, setInfoPosition] = useState(null);
	const [selectedProperty, setSelectedProperty] = useState(null);
	const queryParams = new URLSearchParams(window.location.search);
	const propertyType = queryParams.get("type") || "";
	const { isLoaded } = useLoadScript({
		// eslint-disable-next-line no-undef
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		mapIds: ["a0a654f664a096b7"],
		region: "US",
	});

	const getProperties = async (page, limit, newFilters) => {
		try {
			setFetching(true);
			const amenities = [];
			Object.keys(newFilters.amenities).forEach((key) => {
				Object.keys(newFilters.amenities[key]).forEach((subKey) => {
					if (newFilters.amenities[key][subKey]) {
						amenities.push(`${key}.${subKey}`);
					}
				});
			});
			const response = await getPropertyByType(
				accessToken,
				page,
				limit,
				propertyType,
				keyword,
				{
					...newFilters,
					status: "ACTIVE",
					amenities,
				}
			);
			if (response.status === "success") {
				const { limit, page, remaining, total, results } = response.data;
				setPagination({ limit, page: page, remaining, total });
				setProperties(results);
			} else {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later", "error");
		} finally {
			setFetching(false);
		}
	};

	const handlePrevFetch = () => {
		getProperties(pagination.page - 1, pagination.limit, filters);
	};

	const handleNextFetch = () => {
		getProperties(pagination.page + 1, pagination.limit, filters);
	};

	const handleSearch = () => {
		setPagination({ total: 0, page: 0, remaining: 0, limit: 12 });
		setProperties([]);
		getProperties(0, 12, filters);
	};

	const handleFilter = (newFilters) => {
		setPagination({ total: 0, page: 0, remaining: 0, limit: 12 });
		setProperties([]);
		getProperties(0, 12, newFilters);
	};

	const onMarkerClick = (property) => {
		const position = {
			lat: property.propertyLocation.lat,
			lng: property.propertyLocation.lng,
		};
		setSelectedProperty(property);
		setInfoPosition(position);
	};

	const onCloseInfo = () => {
		setInfoPosition(null);
		setSelectedProperty(null);
	};

	useEffect(() => {
		/* (function(d, u, ac) {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.src = "https://a.omappapi.com/app/js/api.min.js";
      s.async = true;
      s.dataset.user = u;
      s.dataset.account = ac;
      d.getElementsByTagName("head")[0].appendChild(s);
    })(document, 92384, "hcywvgpxmetolbaec5gr"); */
	}, []);

  useEffect(() => {
    setPagination({ total: 0, page: 0, remaining: 0, limit: 12 });
    setProperties([]);
    getProperties(0, 12, filters);
  }, [propertyType]);
  
    const handleNavigate = url => {
      navigate(`/dashboard/vacation_homes?type=${url}`);
    };

    return(
      <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-primary-400 focus:border-primary-400 border-primary-400 rounded-md"
          defaultValue={`/dashboard/vacation_homes${tabs.value ? `?type=${tabs.value}` : ""}`}
          onChange={(evt) => handleNavigate(evt.target.value)}
        >
          {tabs.map((tab) => (
            <option  key={tab.value}
            value={tab.value}
            defaultValue={`/dashboard/vacation_homes${tab.value ? `?type=${tab.value}` : ""}`}>
            {tab.pathName}</option>
          ))}
        </select>
      </div>
      <div className="bg-white">
        <div>
          <nav className="-mb-px flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <NavLink
              key={tab.value}
              to={`/dashboard/vacation_homes${tab.value ? `?type=${tab.value}` : ""}`}
              end={tab?.end}
              className={() =>
                classNames(
                  tab.value === propertyType ? "border-primary-400 text-primary-400"
                  : "border-transparent text-success-800 hover:text-primary-400 hover:border-primary-400",
                  index === 0 ? " " : " ",
                  index === 1 ? " text-danger-500 " : " ",
                  index === tabs.length - 1 ? " " : "",
                  "group inline-flex items-center py-4 px-3 border-b-2 font-medium text-sm hidden sm:block"
                )
              }>
                <tab.icon
                  className={classNames(
                    tab.end ? "text-success-400" : "text-success-800 group-hover:text-success-800",
                    "-ml-0.5 mr-2 h-5 w-5",
                    index === 1 ? " text-danger-500 " : " ",
                  )}
                  aria-hidden="true"
                />
                <span>{tab.pathName}</span>
                </NavLink>
            ))}
          </nav>
        </div>
       
        <div className="mt-2 flex flex-col px-3 py-3 space-y-4 sm:space-y-0 sm:mx-auto sm:gap-5">
          <PropertySearch
            filters={filters}
            setFilters={setFilters}
            onFilter={handleFilter}
            keyword={keyword}
            setKeyword={setKeyword}
            onSearch={handleSearch}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        </div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
            {fetching ? (
              <div className="text-center w-full relative">
                <Spinner displayText="Fetching..." />
              </div>
            ) : (<>
              {propertyType === "disney_resort" ? (<>
                <DisneyPage properties={properties} />
              </>) : <>
                {properties.length && viewMode === 0 ? (<>
                  <div className="mb-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                    {properties.map(property => <VacationCard key={property._id} property={property} />)}
                  </div>
                  <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
                </>) : null}
                <div className={`w-full h-[450px] xl:h-[600px] relative ${properties.length && viewMode === 1 ? "block" : "hidden"}`}>
                  {isLoaded ? (
                    <GoogleMap
                      options={{ mapId: "a0a654f664a096b7", mapTypeControl: false, panControl: false, tabControl: false, scaleControl: false, scrollWheel: false, streetViewControl: false }}
                      zoom={4}
                      center={{ lat: 37.0902, lng: -95.7129 }}
                      mapContainerClassName="h-full w-full"
                    >
                      {infoPosition && selectedProperty && (
                        <InfoWindow position={infoPosition} onCloseClick={onCloseInfo}>
                          {selectedProperty?._id && !!selectedProperty?.createdAt ? <Link to={`/home_details/${selectedProperty?._id}`}>
                            <InfoWindowModal image={selectedProperty?.propertyCoverImage?.large?.url} listingName={selectedProperty?.propertyName} address={`${selectedProperty?.propertyLocation?.streetAddress} ${selectedProperty?.propertyLocation?.city}, ${selectedProperty?.propertyLocation?.state} ${selectedProperty?.propertyLocation?.zip}`} />
                          </Link> :
                          <Link to={`/disney_details/${selectedProperty?._id}`}>
                            <InfoWindowModal image={ImgDisneyCard} listingName={selectedProperty?.propertyName} address={`${selectedProperty?.propertyLocation?.streetAddress} ${selectedProperty?.propertyLocation?.city}, ${selectedProperty?.propertyLocation?.state} ${selectedProperty?.propertyLocation?.zip}`} />
                          </Link>}
                        </InfoWindow>
                      )}

                      {DISNEY_CLUBS.map(property => <OverlayView
                        position={{ lat: property?.propertyLocation?.lat - 0.03, lng: property?.propertyLocation?.lng }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        key={property._id}
                      >
                        <button
                          className={`rounded-full drop-shadow-xl text-bold text-sm hover:scale-110 duration-300 ${selectedProperty?._id !== property._id ? "border border-primary-400 bg-primary-400 py-1.5 px-2.5 text-white" : "border border-gray-100 bg-zinc-50 py-1.5 px-2.5 text-primary-400"}`}
                          onClick={() => onMarkerClick(property)}
                        >
                          ${property.minPrice}
                        </button>
                      </OverlayView>)}
                      {properties.map(property => <OverlayView
                        position={{ lat: property?.propertyLocation?.lat - 0.03, lng: property?.propertyLocation?.lng }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        key={property._id}
                      >
                        <button
                          className={`rounded-full drop-shadow-xl text-bold text-sm hover:scale-110 duration-300 ${selectedProperty?._id !== property._id ? "border border-primary-400 bg-primary-400 py-1.5 px-2.5 text-white" : "border border-gray-100 bg-zinc-50 py-1.5 px-2.5 text-primary-400"}`}
                          onClick={() => onMarkerClick(property)}
                        >
                          ${property.minPrice}
                        </button>
                      </OverlayView>)}
                    </GoogleMap>
                  ) : (
                    <Spinner displayText="Loading Map" />
                  )}
                </div>
                {!properties.length ? (
                  <DefaultState
                    icon="storefront"
                    heading="No Homes Yet!"
                    action={() => navigate("/dashboard/vacation_homes/add_vacation_home")}
                    actionText="Add Home"
                  />
                ) : null}
              </>}
            </>)}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllVacationHomes;
