/* eslint-disable capitalized-comments */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import Option from "./../../components/option/Option";
import { MdGrade, MdThumbUp, MdDone, MdClear } from "react-icons/md";
import useUserContext from "./../../hooks/useUserContext";
import Stars from "react-rating";
import { MdOutlineStar, MdOutlineStarOutline } from "react-icons/md";
import { generateClassName } from "../../services/util";
import { validateReview } from "../../services/validation";
import notify from "../../services/toast";
import handleEdit from "./editReview";
import handleDelete from "./deleteReview";
import { BUSINESS_CATEGORY, PROPERTY_CATEGORY } from "../../constant";

function Review({ review, deleteReview }) {
  const {
    userState: { accessToken },
  } = useUserContext();
  const [{ firstName, lastName, photo }] = review.user;
  const { like, createdAt } = review;
  const rating = React.useRef(review.rating);
  const reviewerReview = React.useRef(review.reviewerReview);
  let businessName = null;
  let businessCategory = null;
  switch(review.target) {
    case "listing":
      businessName = review.listing.length ? review.listing[0].businessName : null;
      businessCategory = review.listing.length ? BUSINESS_CATEGORY[review.listing[0].businessCategory]["mainCategory"]["label"] : null;
      break;
    case "property":
      businessName = review.property.length ? review.property[0].propertyName : null;
      businessCategory = review.property.length ? PROPERTY_CATEGORY.find((item) => item.value === review.property[0].propertyType)?.label : null;
      break;
    default:
  }

  const [editingMode, setEditingMode] = React.useState(false);

  const [newRating, setNewRating] = React.useState(rating.current);
  const [newReview, setNewReview] = React.useState(reviewerReview.current);
  const [reviewerReviewValid, setReviewerReviewValid] = React.useState(false);

  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    const result = validateReview(newReview);
    setReviewerReviewValid(result);
  }, [newReview]);

  const enableEditMode = React.useCallback(() => {
    setEditingMode(!editingMode);
  }, [editingMode]);

  const handleReviewEdit = async () => {
    try {
      const payload = {
        review: newReview,
        rating: newRating,
        reviewId: review._id,
      };
      setEditing(true);
      const response = await handleEdit(accessToken, payload);
      if (response.status === "success") {
        const { data } = response;
        const { rating: newRating, reviewerReview: newReview } = data;
        rating.current = newRating;
        reviewerReview.current = newReview;
        setEditingMode(false);
        notify("Your review has been updated successfully.", "info");
      } else {
        notify("An error has occurred while updating your review. If this error persist contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setEditing(false);
    }
  };

  const handleReviewDelete = async (reviewId) => {
    try {
      const response = await handleDelete(accessToken, reviewId);
      if (response.status === "success") {
        deleteReview();
        notify("Your review has been deleted successfully.", "info");
      } else {
        notify("An error has occurred while updating your review. If this error persist contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const returnActions = () => {
    return [
      { text: "Delete", icon: "delete", key: "delete", action: () => handleReviewDelete(review._id) },
      { text: "Edit", icon: "edit", key: "edit", action: () => enableEditMode() },
    ];
  };

  const action = React.useMemo(() => returnActions(), []);

  return (
    <li className="py-4 w-full">
      <div className="sm:flex p-5 w-full">
        <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
          <img
            src={photo?.small?.url ? photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
            alt={photo?.small?.filename ? photo.small.filename : "Profile Photo"}
            className="inline-block rounded-full border border-3 object-cover h-[64px] w-[64px]"
          />
        </div>
        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <h4 className="text-lg font-bold flex flex-col">
              {firstName} {lastName}
              <span className="w-full text-md font-semibold text-gray-500 flex justify-start items-center gap-2 flex-col sm:flex-row">
                <span className="sm:w-fit w-[200px] capitalize truncate">{businessName ? businessName : null}</span>
                {businessCategory ? (
                  <span className="w-fit capitalize truncate inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 ">
                    {businessCategory}
                  </span>
                ) : null}
              </span>
            </h4>
            {editingMode ? null : <Option actions={action} itemKey={review.userId} deleteAction={() => handleReviewDelete(review._id)} />}
          </div>
          <span className="text-sm text-gray-500">{format(new Date(createdAt), "MMMM d, yyyy")}</span>
          {editingMode ? (
            <div>
              <div className="mt-1">
                <textarea
                  onChange={(event) => setNewReview(event.target.value)}
                  rows="4"
                  name="review"
                  id="review"
                  value={newReview}
                  autoComplete="off"
                  className={generateClassName(!reviewerReviewValid && newReview)}
                ></textarea>
                <p className="mt-2 text-sm text-gray-500" id="email-description">
                  1000 characters maximum
                </p>
              </div>
            </div>
          ) : (
            <p className="mt-1">{reviewerReview.current}</p>
          )}
          {editingMode ? (
            <div className="w-full">
              <Stars
                initialRating={rating.current}
                onChange={(value) => setNewRating(value)}
                fullSymbol={<MdOutlineStar className="text-yellow-500 text-2xl" />}
                emptySymbol={<MdOutlineStarOutline className="text-2xl" />}
              />
            </div>
          ) : (
            <div className="w-fit flex justify-start gap-3">
              <div className="w-full flex gap-1 justify-start items-center">
                <MdThumbUp className="text-success-800" />
                <span className="text-sm text-gray-500">{like}</span>
              </div>
              <div className="w-full flex gap-1 justify-start items-center">
                <MdGrade className="text-yellow-400" />
                <span className="text-sm text-gray-500">{rating.current}</span>
              </div>
            </div>
          )}
          {(review.reviewImages || []).length > 0 && <div className="my-3 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
            {(review.reviewImages || []).map((img, index) => <img
              src={img.small.url}
              alt=""
              key={`review-image-${index}`}
              className="bg-gray-100 rounded-lg"
            />)}
          </div>}
        </div>
      </div>
      {editingMode ? (
        <div className="w-full flex justify-end gap-3">
          <button
            onClick={enableEditMode}
            type="button"
            disabled={!reviewerReviewValid || editing ? true : false}
            className="inline-flex items-center gap-2 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-all duration-500"
          >
            {editing ? (
              "Please wait..."
            ) : (
              <>
                <MdClear className="text-white text-lg" />
                Cancel
              </>
            )}
          </button>
          <button
            onClick={handleReviewEdit}
            type="button"
            disabled={!reviewerReviewValid || editing ? true : false}
            className="inline-flex items-center gap-2 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-all duration-500"
          >
            {editing ? (
              "Please wait..."
            ) : (
              <>
                <MdDone className="text-white text-lg" />
                Done
              </>
            )}
          </button>
        </div>
      ) : null}
    </li>
  );
}

export default Review;
