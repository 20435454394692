/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function getImpactEventByType(accessToken, page, limit, type) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactevent?page=${page}&limit=${limit}${type ? `&type=${type}` : ""}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getImpactEventByType;
