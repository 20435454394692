import React from "react";
import { Outlet } from "react-router-dom";


import Tab from "../../../components/tab/Tab";

function Volunteer() {


	const navigations = [
		{
			pathName: "Upcoming",
			url: "",
			icon: "group",
			end: true,
		},
		{ pathName: "Events Calendar", url: "member_events" },
    { pathName: "My Events", url: "my_events" },
		{ pathName: "Events Attending", url: "attending", icon: "group" },
	];
	return (
		<>
			<div className="w-full py-8 px-5 text-center ">
				<h1 className="w-full mb-10 text-4xl md:text-5xl text-success-800 font-bold">
					Volunteer and Drive Events
				</h1>
			
				<Tab navigations={navigations} />
			</div>
      
			<Outlet />
 
		</>
	);
}

export default Volunteer;
