/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import useConversationContext from "../../hooks/useConversationContext";
import { format } from "date-fns";
import notify from "../../services/toast";
import getUnreadMessage from "./getUnreadMessage.js";
import { decrypt, showPrompt } from "../../services/util";
import deleteConversation from "./deleteConversation";
import Prompt from "../../components/prompt/Prompt";

function getLastMessage(lastMessage) {
  if (lastMessage.textMessage) {
    return decrypt(lastMessage.textMessage);
  }
  const decryptedMessage = lastMessage.mediaMessage && JSON.parse(decrypt(lastMessage.mediaMessage)).length;
  if (decryptedMessage) {
    return `${decryptedMessage} attachment(s)`;
  }
}

function Conversation({ conversation, setShowMessages, reload }) {
  const { userState } = useUserContext();
  const { setCurrentConversation } = useConversationContext();
  const recipient = conversation?.recipient?._id !== userState.id ? conversation.recipient : conversation.sender;
  const { firstName, lastName } = recipient;
  const conversationId = conversation?._id ? conversation._id : null;
  const [unreadMessage, setUnreadMessage] = React.useState(0);
  const lastMessage = conversation?.lastMessage ? getLastMessage(conversation.lastMessage, firstName, lastName) : `Start sending messages to ${firstName} ${lastName}`;
  const createdAt = conversation?.createdAt ? conversation.createdAt : null;
  const photo = recipient?.photo?.small ? recipient.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png";
  const listing = conversation?.listing ? conversation.listing : null;
  const property = conversation?.property ? conversation.property : null;
  const navigate = useNavigate();

  const [deleting, setDeleting] = React.useState(false);

  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });

  const handleShowPrompt = () => {
    showPrompt(
      "Delete Entire Conversation",
      "Are you sure you want to delete this conversation? They other recipient will still see all in the deleted thread.",
      promptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const handleGetUnreadMessages = async () => {
    try {
      const response = await getUnreadMessage(userState.accessToken, conversationId);
      if (response.status === "success") {
        setUnreadMessage(response.data);
      } else {
        notify("An error occurred while updating conversation. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleDeleteConversation = async () => {
    try {
      setDeleting(true);
      const response = await deleteConversation(userState.accessToken, conversationId);
      if (response.status === "success") {
        navigate("/dashboard/messaging");
        reload();
        promptRef.current.closePrompt();
      } else {
        notify("An error occurred while deleting conversation. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setDeleting(false);
    }
  };

  const handleClickConversation = event => {
    event.preventDefault();
    setShowMessages(true);
    setCurrentConversation(conversation);
    setUnreadMessage(0);
    navigate(`/dashboard/messaging/${conversationId}`);
  };

  React.useEffect(() => {
    handleGetUnreadMessages();
  }, []);
  return (
    <NavLink
      to={`/dashboard/messaging/${conversationId}`}
      onClick={handleClickConversation}
      className={({ isActive }) => {
        return isActive ? "group w-full flex gap-4 items-center p-3  bg-gray-200 transition-all duration-500" : "group w-full flex gap-4 items-center p-3 hover:bg-gray-50 transition-all duration-500";
      }}>
      <img className="block h-14 w-14 rounded-full" src={photo} alt={lastName} />
      <div className="w-full flex flex-col">
        <h1 className="max-w-[200px] text-md font-semibold text-success-800 truncate capitalize">
          {firstName} {lastName} {listing && conversation.target === "listing" && `@ ${listing.businessName}`} {property && conversation.target === "property" && `@ ${property.propertyName}`}
        </h1>
        <p className="max-w-[200px] text-sm text-gray-500 truncate">{lastMessage}</p>
      </div>
      <div className="w-full flex flex-col items-end">
        <p className="max-w-full text-gray-500 text-sm">{format(new Date(createdAt), "MM/dd/yy")}</p>
        <span className="hidden group-hover:block material-icons-outlined text-[20px] text-red-400 cursor-pointer" onClick={handleShowPrompt}>
          delete
        </span>
        {unreadMessage ? (
          <span className="group-hover:hidden inline-flex items-center p-3 justify-center w-5 h-5 rounded-full text-xs font-medium text-success-800 bg-primary-100">{unreadMessage}</span>
        ) : null}
      </div>
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={handleDeleteConversation} isExecuting={deleting} />
    </NavLink>
  );
}

export default Conversation;
