import React from "react";
import { Link } from "react-router-dom";

function ComResources() {
  return (
    <div className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
      <div className="rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">

          <Link to="/marketplace/listings/business_resources" className="-m-3 p-3 block rounded-md hover:bg-gray-200 transition ease-in-out duration-150">
            <p className="text-base font-medium text-gray-900">Business Resources</p>
          </Link>

          <Link to="/marketplace/listings/chambers" className="-m-3 p-3 block rounded-md hover:bg-gray-200 transition ease-in-out duration-150">
            <p className="text-base font-medium text-gray-900">Chambers</p>
          </Link>

          <Link to="/marketplace/listings/non_profits" className="-m-3 p-3 block rounded-md hover:bg-gray-200 transition ease-in-out duration-150">
            <p className="text-base font-medium text-gray-900">Nonprofits</p>
          </Link>

          <Link to="/marketplace/listings/community_resources" className="-m-3 p-3 block rounded-md hover:bg-gray-200 transition ease-in-out duration-150">
            <p className="text-base font-medium text-gray-900">All Resources</p>
          </Link>

          <Link to="/events" className="-m-3 p-3 block rounded-md hover:bg-gray-200 transition ease-in-out duration-150">
            <p className="text-base font-medium text-gray-900">Events</p>
          </Link>
          
        </div>
      </div>
    </div>
  );
}

export default ComResources;
