/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React, { useState, useEffect } from "react";
import { MdClear } from "react-icons/md";
import SelectInput from "../../../../components/select_input/SelectInput";
import { PROPERTY_CATEGORY, PROPERTY_DISCOUNT, PROPERTY_INFO_FEATURES } from "../../../../constant";
import { generateClassName } from "./../../../../services/util";
import { validateBusinessName, validateEmail, validateMessage } from "../../../../services/validation";

function PropertyInfo({
  property,
  setProperty,
  onClose,
}) {
  const [validPropertyName, setValidPropertyName] = useState(false);
  const [validPropertyEmailAddress, setValidPropertyEmailAddress] = useState(false);
  const [validPropertyDescription, setValidPropertyDescription] = useState(false);

  useEffect(() => {
    const result = validateBusinessName(property?.propertyName);
    setValidPropertyName(result);
  }, [property?.propertyName]);

  useEffect(() => {
    const result = validateEmail(property?.propertyEmailAddress);
    setValidPropertyEmailAddress(result);
  }, [property?.propertyEmailAddress]);

  useEffect(() => {
    const result = validateMessage(property?.propertyDescription) && !!property?.propertyDescription;
    setValidPropertyDescription(result);
  }, [property?.propertyDescription]);

  // console.log(property);

  const submitData = (key, value) => {
    setProperty((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        {onClose && <MdClear className="text-xl cursor-pointer" onClick={onClose} />}
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Property Info</h3>
          <p className="mt-1 text-sm text-gray-500">
            This information will be displayed publicly. Please do not share the property address or other sensitive information.
          </p>
        </div>

        <div className="grid grid-cols-6 gap-6">

          <div className="col-span-6 sm:col-span-3">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="property-name" className="block text-sm font-medium text-gray-700">
                Property Name
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <input
              onChange={(event) => submitData("propertyName", event.target.value)}
              type="text"
              name="property_name"
              id="property_name"
              autoComplete="off"
              value={property?.propertyName || ""}
              className={generateClassName(!validPropertyName && property?.propertyName)}
              placeholder=""
              aria-describedby="name-optional"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Contact Email Address
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <input
              onChange={(event) => submitData("propertyEmailAddress", event.target.value)}
              type="email"
              name="property_email"
              id="property_email"
              autoComplete="off"
              value={property?.propertyEmailAddress || ""}
              className={generateClassName(!validPropertyEmailAddress && property?.propertyEmailAddress)}
              placeholder="company@domain.com"
              aria-describedby="email-optional"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="property_type" className="block text-sm font-medium text-gray-700">
                Property Type
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <SelectInput
              options={PROPERTY_CATEGORY}
              noOptionsMessage="No categories found"
              placeholder="Select property type"
              defaultValue={() => {
                return property?.propertyType ? PROPERTY_CATEGORY.find(item => item.value === property?.propertyType) : null;
              }}
              setSelectedValue={(event) => event ? submitData("propertyType", event.value) : submitData("propertyType", null)}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="property_discount" className="block text-sm font-medium text-gray-700">
                Property Discount Offered to COhatch+ Users
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <SelectInput
              options={PROPERTY_DISCOUNT}
              noOptionsMessage="No discount found"
              placeholder="Select discount offered"
              defaultValue={() => {
                return property?.propertyDiscount ? PROPERTY_DISCOUNT.find(item => item.value === property?.propertyDiscount) : null;
              }}
              setSelectedValue={(event) => event ? submitData("propertyDiscount", event.value) : submitData("propertyDiscount", null)}
            />
          </div>

          <div className="col-span-6">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="about-property" className="block text-sm font-medium text-gray-700">
                Property Description
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <div className="mt-1">
              <textarea
                id="about-property"
                name="about-property"
                rows={3}
                className={generateClassName(!validPropertyDescription && property?.propertyDescription)}
                placeholder="Please share a brief description of your property."
                onChange={(event) => submitData("propertyDescription", event.target.value)}
                value={property?.propertyDescription || ""}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="rules" className="block text-sm font-medium text-gray-700">
              Rules
            </label>
            <div className="mt-1">
              <textarea
                id="rules"
                name="rules"
                rows={3}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                placeholder="Please share any rules about your property that guests should know."
                onChange={(event) => submitData("propertyRules", event.target.value)}
                value={property?.propertyRules || ""}
              />
            </div>
          </div>

        </div>

        <div className="grid grid-cols-8 gap-6">

          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="guest" className="block text-sm font-medium text-gray-700">
                Max Guests
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <input
              type="number"
              min={0}
              name="guest"
              id="number-of-guests"
              autoComplete="email"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
              value={property?.numberOfGuest}
              onChange={event => submitData("numberOfGuest", parseInt(event.target.value, 10))}
            />
          </div>

          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="bedrooms" className="block text-sm font-medium text-gray-700">
                Number of Bedrooms
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <input
              type="number"
              min={0}
              id="bedroom"
              name="bedroom"
              autoComplete="number-of-bedroom"
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary-400 focus:outline-none focus:ring-primary-400 sm:text-sm"
              value={property?.numberOfBedRooms}
              onChange={event => submitData("numberOfBedRooms", parseInt(event.target.value, 10))}
            />
          </div>

          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="bedrooms" className="block text-sm font-medium text-gray-700">
                Number of Beds
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <input
              type="number"
              min={0}
              id="bed"
              name="bed"
              autoComplete="number-of-bed"
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary-400 focus:outline-none focus:ring-primary-400 sm:text-sm"
              value={property?.numberOfBeds}
              onChange={event => submitData("numberOfBeds", parseInt(event.target.value, 10))}
            />
          </div>

          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="bathrooms" className="block text-sm font-medium text-gray-700">
                Number of Bathrooms
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <input
              type="number"
              min={0}
              id="bathroom"
              name="bathroom"
              autoComplete="number-of-bathroom"
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary-400 focus:outline-none focus:ring-primary-400 sm:text-sm"
              value={property?.numberOfBathRooms}
              onChange={event => submitData("numberOfBathRooms", parseInt(event.target.value, 10))}
            />
          </div>

        </div>


        <fieldset className="space-y-5">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="property-guidelines" className="block text-sm font-medium text-gray-700">
              Property Guidelines
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
          <ul className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
            {PROPERTY_INFO_FEATURES.map(({ label, value }, index) => {
              return (
                <li className="w-full " key={index}>
                  <div className="flex items-center pl-3">
                    <input
                      id={`feature-checkbox-${value}`}
                      type="checkbox"
                      name={label}
                      checked={property[value]}
                      onChange={() => {
                        submitData(value, !property[value]);
                      }}
                      className="w-4 h-4 text-primary-400 bg-white rounded border-gray-300 focus:ring-primary-400 dark:focus:ring-primary-400 dark:ring-offset-primary-400 focus:ring-2 dark:bg-white dark:border-gray-500"
                    />
                    <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`feature-checkbox-${value}`}>{label}</label>
                  </div>
                </li>
              );
            })}
          </ul>
        </fieldset>

      </div>
    </div>
  );
}
export default PropertyInfo;