import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";

function notify(message, type, duration = 10000, position = "top-right") {
  switch (type) {
    case "error":
      return toast.error(`${message}`, { position, autoClose: duration, toastId: uuid(), bodyClassName: "text-red-600 text-sm" });
    case "info":
      return toast.info(`${message}`, { position, autoClose: duration, toastId: uuid(), bodyClassName: "text-primary-400 text-sm" });
    case "success":
      return toast.success(`${message}`, { position, autoClose: duration, toastId: uuid(), bodyClassName: "text-primary-400 text-sm" });
    case "warn":
      return toast.warn(`${message}`, { position, autoClose: duration, toastId: uuid(), bodyClassName: "text-yellow-500 text-sm" });
    default:
      return toast.info(`${message}`, { position, autoClose: duration, toastId: uuid(), bodyClassName: "text-gray-700 text-sm" });
  }
}

export default notify;
