import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function deleteAllNotifications(accessToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/notification`;

  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteAllNotifications;
