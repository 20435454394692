/* eslint-disable no-console */
import makeAPIRequest from "../../../../services/makeRequest";
import config from "../../../../config";

async function getListing(listingId, userId = "") {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/nonprofit/${listingId}${userId ? `?userId=${userId}` : ""}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getListing;
