/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import Comment from "../comments/Comment";
import useUserContext from "../../hooks/useUserContext";
import { validateMessage } from "../../services/validation";
import notify from "../../services/toast";
import createComment from "./createComment";
import getAllComments from "./getAllComments";
import deleteComment from "./deleteComment";


function CommentSection({ setTotalComments, postId, socket, groupId, groupName, creatorId }) {
  const [comment, setComment] = React.useState("");
  const [validComment, setValidComment] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [creatingComment, setCreatingComment] = React.useState(false);
  const [loadingComments, setLoadingComments] = React.useState(false);
  const [isCommentDeleted, setIsCommentDeleted] = React.useState(false);
  const { userState } = useUserContext();
  const page = React.useRef(0);
  const remaining = React.useRef(0);

  React.useEffect(() => {
    const result = validateMessage(comment);
    setValidComment(result);
  }, [comment]);

  const fetchAllComments = async () => {
    try {
      setLoadingComments(true);
      const response = await getAllComments(userState.accessToken, postId, page.current, 5);
      if (response.status === "success") {
        const { remaining: remainingResult, results, total } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setTotalComments(total);
        setComments((prevState) => {
          return [...prevState, ...results];
        });
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setLoadingComments(false);
    }
  };

  const reload = async () => {
    try {
      setLoadingComments(true);
      const response = await getAllComments(userState.accessToken, postId, 0, 5);
      if (response.status === "success") {
        const { remaining: remainingResult, results, total } = response.data;
        setTotalComments(total);
        setComments(results);
        remaining.current = remainingResult;
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setLoadingComments(false);
    }
  };

  const handleCreateComment = async () => {
    try {
      const payload = {
        postId: postId,
        comment: comment,
      };
      setCreatingComment(true);
      const response = await createComment(userState.accessToken, payload);
      if (response.status === "success") {
        notify("Comment successfully created", "info");
        setComment("");
        setComments([]);
        reload();
        fetchAllComments();
        socket.current.emit("group_post_comment", {
          groupId: groupId,
          postId: postId,
          groupName: groupName,
          userFirstName: userState.firstName,
          userLastName: userState.lastName,
          userId: userState.id,
          creatorId: creatorId,
        });
      } else {
        notify("An error occurred while creating comment", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setCreatingComment(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await deleteComment(userState.accessToken, commentId);
      if (response.status === "success") {
        const newComments = comments.filter((comment) => comment._id !== commentId);
        setComments(newComments);
        setTotalComments(newComments.length);
        setIsCommentDeleted(!isCommentDeleted);
        notify("Successfully deleted comment", "info");
      } else {
        notify("Error deleting comment. If error persists, please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  React.useEffect(() => {
    fetchAllComments();
  }, []);

  return (
    <div className="w-full">
      <div className="w-full flex gap-2 items-center">
        <img
          className="w-10 h-10 flex-shrink-0 mx-auto rounded-full"
          src={userState?.photo?.small ? userState.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
          alt={userState.lastName}
        />
        <div className="w-full">
          <div>
            <div className="mt-1">
              <textarea
                onChange={(event) => setComment(event.target.value)}
                rows="2"
                name="comment"
                id="comment"
                value={comment}
                placeholder="Add a comment"
                className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      {comment && validComment ? (
        <div className="mt-5 w-full flex justify-end">
          <button
            onClick={handleCreateComment}
            type="button"
            disabled={creatingComment ? true : false}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            {creatingComment ? "Please wait..." : "Comment"}
          </button>
        </div>
      ) : null}
      <div className="mt-5 w-full space-y-3 p-3">
        {comments.length
          ? comments.map((com, index) => (
              <Comment
                key={index}
                isDeleted={isCommentDeleted}
                comment={com}
                deleteComment={handleDeleteComment}
                postId={postId}
                socket={socket}
                groupId={groupId}
                groupName={groupName}
                creatorId={creatorId}
              />
            ))
          : null}
      </div>
      {remaining.current > 0 && (
        <span onClick={fetchAllComments} className="mt-6 w-full flex justify-center text-gray-400 underline cursor-pointer">
          {loadingComments ? "loading..." : "load more comments"}
        </span>
      )}
    </div>
  );
}

export default CommentSection;
