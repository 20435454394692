/* eslint-disable no-undef */
/* eslint-disable capitalized-comments */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { MdGrade, MdThumbUp, MdReply, MdSend } from "react-icons/md";
import { validateReview } from "../../services/validation";
import { generateClassName } from "../../services/util";
import notify from "../../services/toast";
import useUserContext from "./../../hooks/useUserContext";
import sendReply from "./sendReply";

function VisitorReview({ review }) {
  const {
    userState: { accessToken },
  } = useUserContext();
  const firstName = review?.user[0]?.firstName || review.reviews.reviewerFirstName;
  const lastName = review?.user[0]?.lastName || review.reviews.reviewerLastName;
  const photo = review?.user[0]?.photo || null;
  const { businessName } = review;
  const { createdAt, reviewerReview, like, rating, _id } = review.reviews;
  const replies = React.useRef(review?.replies?.length || 0);

  const [reply, setReply] = React.useState("");
  const [validReply, setValidReply] = React.useState(false);

  const [replying, setReplying] = React.useState(false);

  const [replyMode, setReplyMode] = React.useState(false);

  React.useEffect(() => {
    const result = validateReview(reply);
    setValidReply(result);
  }, [reply]);

  const handleSendReply = async () => {
    try {
      setReplying(true);
      const payload = {
        reviewId: _id,
        listingId: review.reviews.listingId,
        reply,
      };
      const response = await sendReply(accessToken, payload);
      if (response.status === "success") {
        notify("Successfully created a reply", "info");
        setReplyMode(false);
        replies.current += 1;
      } else {
        notify("An error has while replying to the listing review. If this error persist contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setReplying(false);
    }
  };
  return (
    <li className="py-4 w-full">
      <div className="sm:flex p-5 w-full">
        <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
          <img
            src={photo?.small?.url ? photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
            alt={"Profile Photo"}
            className="inline-block rounded-full border border-3 object-cover h-[64px] w-[64px]"
          />
        </div>
        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <h4 className="text-lg font-bold flex flex-col">
              {firstName} {lastName}
              <span className="w-full text-md font-semibold text-gray-500 flex justify-start items-center gap-2 flex-col sm:flex-row">
                <span className="sm:w-fit w-[200px] capitalize truncate">{businessName}</span>
              </span>
            </h4>
            <MdReply className="text-success-800 text-4xl cursor-pointer" onClick={() => setReplyMode(!replyMode)} />
          </div>
          <span className="text-sm text-gray-500">{format(new Date(createdAt), "MMMM d, yyyy")}</span>
          <p className="mt-1">{reviewerReview}</p>
          <div className="w-fit flex justify-start gap-3">
            <div className="w-full flex gap-1 justify-start items-center">
              <MdThumbUp className="text-success-800" />
              <span className="text-sm text-gray-500">{like}</span>
            </div>
            <div className="w-full flex gap-1 justify-start items-center">
              <MdGrade className="text-yellow-400" />
              <span className="text-sm text-gray-500">{rating}</span>
            </div>
            <div className="w-full flex gap-1 justify-start items-center">
              <MdReply className="text-success-800" />
              <span className="text-sm text-gray-500">{replies.current}</span>
            </div>
          </div>
          {(review?.reviewImages || []).length > 0 && <div className="my-3 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
            {(review?.reviewImages || []).map((img, index) => <img
              src={img.small.url}
              alt=""
              key={`review-image-${index}`}
              className="bg-gray-100 rounded-lg"
            />)}
          </div>}
        </div>
      </div>
      {replyMode ? (
        <div className="w-full flex flex-col gap-4 p-5">
          <div className="mt-1 w-full">
            <textarea
              onChange={(event) => setReply(event.target.value)}
              rows="3"
              name="review"
              id="review"
              value={reply}
              autoComplete="off"
              className={generateClassName(!validReply && reply)}
            ></textarea>
            <p className="mt-2 text-sm text-gray-500" id="email-description">
              1000 characters maximum
            </p>
          </div>
          <button
            onClick={handleSendReply}
            type="button"
            disabled={!validReply || replying ? true : false}
            className="w-fit inline-flex items-center gap-2 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-all duration-500"
          >
            {replying ? "Please wait..." : "Send"}
            <MdSend className="text-white text-lg" />
          </button>
        </div>
      ) : null}
    </li>
  );
}

export default VisitorReview;
