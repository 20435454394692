/* eslint-disable no-console */
/* eslint-disable  no-unused-vars */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getEventsList(accessToken, page, limit, filter) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/event/list?page=${page}&limit=${limit}&filter=${filter}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}
export default getEventsList;