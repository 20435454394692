import React from "react";
import Logo from "./../../components/logo/Logo";
import { Link } from "react-router-dom";
import useScrollPage from "../../hooks/useScrollPage";

function NotFound() {
  useScrollPage();
  return (
    <div className="h-screen pt-16 pb-12 flex flex-col bg-[url('https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/background-image.png')] bg-no-repeat bg-cover">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <Logo color="green" />
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-primary-400 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold text-success-800 tracking-tight sm:text-5xl">Page not found.</h1>
            <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
            <div className="mt-6">
              <Link to="/" className="text-base font-medium text-primary-400 hover:text-primary-500">
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <Link to="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact Support
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true"></span>
          <Link to="/signup" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Signup
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true"></span>
          <Link to="/login" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Login
          </Link>
        </nav>
      </footer>
    </div>
  );
}

export default NotFound;
