import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function getUnreadNotifications(accessToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/notification/unread`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getUnreadNotifications;
