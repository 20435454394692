import React from "react";
import { Outlet } from "react-router-dom";
import BreadCrumb from "./../../components/bread_crumb/BreadCrumb";
import Tab from "./../../components/tab/Tab";
import useScrollPage from "../../hooks/useScrollPage";

const directions = [
  { pathName: "Dashboard", url: "/dashboard" },
  { pathName: "Settings", url: "" },
];

const navigations = [
  { pathName: "Profile", url: "", disabled: false, end: true },
  { pathName: "Account", url: "account" },
  { pathName: "Privacy", url: "privacy" },
  { pathName: "Email Preferences", url: "email_preference" },
  { pathName: "Subscription", url: "subscription" },
  { pathName: "Activity", url: "activity" },
  { pathName: "Scholarship Apps", url: "scholarship_apps" },
];

function Settings() {
  useScrollPage();
  return (
    <div className="p-5 space-y-2">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">Settings</h1>
      </div>
      <BreadCrumb directions={directions} />
      <Tab navigations={navigations} section="settings" />
      <Outlet />
    </div>
  );
}

export default Settings;
