/* eslint-disable no-console */
import React from "react";
import MemberCard from "./../../../components/member_card/MemberCard";
import { useOutletContext } from "react-router-dom";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import Spinner from "../../../components/spinner/Spinner";
import Pagination from "../../../components/pagination/Pagination";
import getMembers from "./getMembers";
import DefaultState from "../../../components/default_state/DefaultState";
import useScrollPage from "../../../hooks/useScrollPage";

function Members() {
  useScrollPage();

  const { group } = useOutletContext();
  const { userState } = useUserContext();

  const [members, setMembers] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  const [pagination, setPagination] = React.useState({ limit: 10, total: 0, page: 0, remaining: 0 });

  const handleGetMembers = async (accessToken, groupId, page, limit) => {
    try {
      setFetching(true);
      const response = await getMembers(accessToken, groupId, page, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setMembers(results);
      } else {
        notify("An error occurred while fetching members. If this error persists please contact support", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const handlePrevFetch = async () => {
    await handleGetMembers(userState.accessToken, group._id, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = async () => {
    await handleGetMembers(userState.accessToken, group._id, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    if (group && group._id) {
      handleGetMembers(userState.accessToken, group._id, 0, 12);
    }
  }, []);
  return (
    <div className="w-full bg-white rounded-md shadow-md mt-4">
      <div className="w-full p-5">
        <h1 className="text-lg font-bold text-success-800">Members</h1>
        <div className="w-full flex items-stretch justify-center md:justify-start gap-5 flex-wrap">
          {fetching ? (
            <Spinner displayText="Fetching..." />
          ) : members.length ? (
            members.map((member, index) => <MemberCard key={index} member={member.user} />)
          ) : (
            <DefaultState icon="person" heading="No members available" description="Members excluding you will appear" />
          )}
          <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
        </div>
      </div>
    </div>
  );
}

export default Members;
