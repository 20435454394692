import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function getLikes(accessToken, commentId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/Like/${commentId}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getLikes;
