/*eslint-disable */
import React, {useState, useEffect} from "react";
import Spinner from "../../../components/spinner/Spinner";
import useUserContext from "../../../hooks/useUserContext";
import useTitle from "../../../hooks/useTitle";
import Footer from "../../../components/footer/Footer";
import MarketplaceSearch from "../../../components/marketplace_search/MarketplaceSearch";
import CalendarMonthView from "../../../components/calendar_month_view/CalendarMonthView";
import CalendarWeekView from "../../../components/calendar_week_view/CalendarWeekView";
import CalendarDayView from "../../../components/calendar_day_view/CalendarDayView";

import CalendarNav from "../../../components/calendar_nav/CalendarNav";
import notify from "../../../services/toast";
import { DEFAULT_PAGINATION_LIMIT, MAGIC_NUMBERS } from "../../../constant";
import getAllImpactEvents from "./getAllImpactEvents.js";



function MemberEvents() {
  useTitle("Member Events");
  const {
    userState: { accessToken },
  } = useUserContext();
	const directions = [
		{ pathName: "Philanthropy", url: "/dashboard/philanthropy" },
		{ pathName: "Nonprofits", url: "" },
	];
  const [events, setEvents] = useState([]);

  console.log(events);
  const [fetching, setFetching] = React.useState(false);
  const [viewMode, setViewMode] = useState(3);
  const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(10);
  const today = new Date();
  const startDate = new Date();
  const lastMondayDays = (today.getDay() === 0 ? 6 : today.getDay() - 1);
  startDate.setDate(today.getDate() - lastMondayDays);
  const [selectedWeek, setSelectedWeek] = useState(startDate);
  const [selectedDay, setSelectedDay] = useState(today);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: DEFAULT_PAGINATION_LIMIT });

   const getAllEvents = async (accessToken) => {
    try {
      setFetching(true);
      let year = 2023;
      let month = 10;
     
      const response = await getAllImpactEvents(accessToken, year, month, false);
      if (response.status === "success") {
        setEvents(response.data.results);
        
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

 
useEffect(() => {
  getAllEvents(accessToken);
},[]);
  
	return (
		<>
      <div className="p-7 ">
			<div className="relative max-w-full h-full mx-auto bg-white shadow rounded-lg">
				<div className="w-full h-full bg-white p-10 space-y-8">
			{/* <div className="inline-block">
				<MarketplaceSearch placeholder="Search for upcoming events, drives, and ongoing fundraisers" />{" "}
			</div> */}
      <div className="App">
        {fetching ? (
          <Spinner displayText="Fetching..." />
        ) : (<div className="lg:flex sticky lg:h-full lg:flex-col">
          <CalendarNav
            setViewMode={setViewMode}
            viewMode={viewMode}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            onReload={() => getAllEvents(accessToken, 0, 100)}
          />
         
          {viewMode === 3 && <CalendarMonthView selectedMonth={selectedMonth} selectedYear={selectedYear} events={events} setEvents={setEvents} onReload={() => getAllEvents(accessToken)} />}
          
        </div>)}
        </div>
        </div>
        </div>
        </div>
		
		
		</>
	);
}

export default MemberEvents;
