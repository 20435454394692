import React from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Tab({ navigations, section }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = url => {
    navigate(url);
  };

  const selected = navigations.find(navigation => location.pathname === `/dashboard/${section}/${navigation.url}` || location.pathname === `/dashboard/${section}${navigation.url}`);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="navigation"
          name="navigation"
          className="block w-full focus:ring-primary-400 focus:border-primary-400 border-primary-400 rounded-md"
          onChange={event => handleNavigate(event.target.value)}
          value={selected?.url}>
          {navigations.map(navigation => (
            <option
              key={uuid()}
              value={navigation.url}
            >
              {navigation.pathName}
            </option>
          ))}
        </select>
      </div>

      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {navigations.map((navigation, index) => (
            <NavLink
              key={uuid()}
              to={navigation.url}
              end={navigation?.end}
              className={({ isActive }) =>
                classNames(
                  isActive ? "border-success-800 text-success-800" : "border-transparent text-primary-400 hover:border-gray-300 hover:text-gray-700",
                  "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium",
                  index === 0 ? " " : " ",
                  index === navigations.length - 1 ? " " : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-centerfocus:z-10"
                )
              }>
              {/* <span className="material-icons-outlined text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5">{navigation.icon}</span> */}
              <span>{navigation.pathName}</span>
            </NavLink>
          ))}
        </nav>
        </div>
      </div>
    </div>
  );
}

Tab.propTypes = {
  navigations: PropTypes.array.isRequired,
  section: PropTypes.string,
};
export default Tab;
