/* eslint-disable */
/* eslint-disable capitalized-comments */
import React, {useState, useEffect} from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import NonprofitInfo from "../philanthropy/nonprofits/add_nonprofit/NonprofitInfo";
import OwnerInfo from "../marketplace/add_listing/owner_info/OwnerInfo";
import Photos from "../marketplace/add_listing/photos/Photos";
import BusinessHours from "../marketplace/add_listing/business_hours/BusinessHours";
import useUserContext from "../../hooks/useUserContext";
import BusinessAddress from "../marketplace/add_listing/business_address/BusinessAddress";
import ScholarshipPrompt from "../philanthropy/nonprofits/add_nonprofit/ScholarshipPrompt";
import ReviewNp from "../philanthropy/nonprofits/add_nonprofit/ReviewNp";
import BusinessInfo from "../marketplace/add_listing/business_info/BusinessInfo";
import Review from "../marketplace/add_listing/review/Review";
import Coupon from "../marketplace/add_listing/coupon/Coupon";
import Price from "../marketplace/add_listing/price/Price";
import Badge from "../marketplace/add_listing/badge/Badge";
import AddListing from "../marketplace/add_listing/AddListing";

// function renderApps(app, index) {
// 	return <ApplicantCard key={index} application={app}/>
// }

function BusinessHubHome(){

  return (
		<>
			<AddListing />
		</>
    

  );
}

export default BusinessHubHome;