/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import CheckBox from "../../../../components/checkbox/Checkbox";
import {PROPERTY_BATHROOM_AMENITIES, PROPERTY_BEDROOM_AMENITIES, PROPERTY_ENTERTAINMENT_AMENITIES, PROPERTY_KITCHEN_AMENITIES, PROPERTY_SAFETY_AMENITIES, PROPERTY_COOLING_AMENITIES, PROPERTY_FAMILY_AMENITIES, PROPERTY_PARKING_AMENITIES, PROPERTY_OUTDOOR_AMENITIES, PROPERTY_ACCESSIBILITY_AMENITIES} from "../../../../constant";

function PropertyFeatures({
  property,
  setProperty,
}) {
  const submitData = (key, subKey, value) => {
    setProperty((prevState) => {
      return {
        ...prevState,
        amenities: {
          ...prevState?.amenities,
          [key]: {
            ...prevState?.amenities[key],
            [subKey]: value,
          },
        },
      };
    });
  };

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Amenities + Features</h3>
        </div>
        
      <fieldset>
        <legend className="text-base font-medium text-gray-900">Bathroom</legend>
        <CheckBox 
          amenities={PROPERTY_BATHROOM_AMENITIES}
          checked={property?.amenities?.bathroom}
          onChange={value => {
            submitData("bathroom", value, !property?.amenities?.bathroom[value]);
          }}
        />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Bedroom & Laundry</legend>
          <CheckBox 
            amenities={PROPERTY_BEDROOM_AMENITIES}
            checked={property?.amenities?.bedroomLaundry}
            onChange={value => {
              submitData("bedroomLaundry", value, !property?.amenities?.bedroomLaundry[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Entertainment & Tech</legend>
          <CheckBox 
            amenities={PROPERTY_ENTERTAINMENT_AMENITIES}
            checked={property?.amenities?.entertainmentTech}
            onChange={value => {
              submitData("entertainmentTech", value, !property?.amenities?.entertainmentTech[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Kitchen</legend>
          <CheckBox 
            amenities={PROPERTY_KITCHEN_AMENITIES}
            checked={property?.amenities?.kitchen}
            onChange={value => {
              submitData("kitchen", value, !property?.amenities?.kitchen[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Safety</legend>
          <CheckBox 
            amenities={PROPERTY_SAFETY_AMENITIES}
            checked={property?.amenities?.safety}
            onChange={value => {
              submitData("safety", value, !property?.amenities?.safety[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Heating & Cooling</legend>
          <CheckBox 
            amenities={PROPERTY_COOLING_AMENITIES}
            checked={property?.amenities?.heatingCooling}
            onChange={value => {
              submitData("heatingCooling", value, !property?.amenities?.heatingCooling[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Family</legend>
          <CheckBox 
            amenities={PROPERTY_FAMILY_AMENITIES}
            checked={property?.amenities?.family}
            onChange={value => {
              submitData("family", value, !property?.amenities?.family[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Parking</legend>
          <CheckBox 
            amenities={PROPERTY_PARKING_AMENITIES}
            checked={property?.amenities?.parking}
            onChange={value => {
              submitData("parking", value, !property?.amenities?.parking[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Outdoor</legend>
          <CheckBox 
            amenities={PROPERTY_OUTDOOR_AMENITIES}
            checked={property?.amenities?.outdoor}
            onChange={value => {
              submitData("outdoor", value, !property?.amenities?.outdoor[value]);
            }}
          />
      </fieldset>

      <fieldset>
          <legend className="text-base font-medium text-gray-900">Accessibility</legend>
          <CheckBox 
            amenities={PROPERTY_ACCESSIBILITY_AMENITIES}
            checked={property?.amenities?.accessibility}
            onChange={value => {
              submitData("accessibility", value, !property?.amenities?.accessibility[value]);
            }}
          />
      </fieldset>
      
      </div>
    </div>
  );
}

export default PropertyFeatures;