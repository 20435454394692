/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import React from "react";
import { FaWindowClose} from "react-icons/fa";
import { MdDateRange, MdLocationPin, MdOutlineAppRegistration } from "react-icons/md";
import Popup from "reactjs-popup";
import EventRsvpForm from "../../../components/workshop_rsvp_form/EventRsvpFormEventsAttending";
import {FaFacebook, FaInstagramSquare, FaTwitter, FaGlobe} from "react-icons/fa";
import useUserContext from "../../../hooks/useUserContext";
import Prompt from "../../../components/prompt/Prompt";
import { format } from "date-fns";
import { AddToCalendarButton } from "add-to-calendar-button-react";


function timeFormatter(time) {
  const hour = Number(time.slice(0, time.indexOf(":")));
  const minutes = time.slice(time.indexOf(":"));

  if (hour === 12) {
    return hour + minutes + " PM";
  } else if (hour === 0) {
    return hour + 12 + minutes + " AM";
  }

  if (hour > 12) {
    return hour - 12 + minutes + " PM";
  } else {
    return hour + minutes + " AM";
  }
}

const footerNavigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/events/976408409223088",
      icon: FaFacebook
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/cohatchcommunity/",
      icon: FaInstagramSquare
    },
    {
      name: "Twitter",
      href: "https://twitter.com/cohatchlife",
      icon: FaTwitter
    },
    {
      name: "Website",
      href: "https://www.cohatch.com/",
      icon: FaGlobe
    },
    
  ],
};


export default function WorkshopDetails({ button, title, event, reload }) {
  const { userState } = useUserContext();
  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });

  const {
    userState: { accessToken },
  } = useUserContext();

  const handleEdit = async () => {
    await console.log("edit");
    // try {
    //   setDeletingEvent(true);
    //   const response = await deleteEvent(userState.accessToken, event.event._id);
    //   if (response.status === "success") {
    //     notify("Your delete event request was successful.", "info");
    //     if (onReload) {
    //       onReload();
    //     }
    //     promptRef.current.closePrompt();
    //     if (close) {
    //       close();
    //     }
    //   } else {
    //     let displayMessage = "";
    //     displayMessage = response.message;
    //     if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
    //       displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
    //     }
    //     notify(displayMessage, "error");
    //   }
    // } catch (error) {
    //   notify("Something went wrong. Please try again later.", "error");
    // } finally {
    //   setDeletingEvent(false);
    // }
  };
  const handleSubmit = async () => {
    await console.log("handle Submit");
    // try {
    //   setSubmitting(true);
    //   const dt = new Date(parseInt(event.event.eventDate.substring(0, 4), 10), parseInt(event.event.eventDate.substring(5, 7), 10) - 1, parseInt(event.event.eventDate.substring(8, 10), 10), parseInt(event.event.eventTime.substring(0, 2), 10), parseInt(event.event.eventTime.substring(3, 5), 10), 0);
    //   const response = await createEvent(accessToken, {
    //     ...event,
    //     eventDate: dt,
    //   });
    //   if (response.status === "success") {
    //     notify("Your event was successfully submitted.", "info");
    //     onReload();
    //   } else {
    //     setSubmitting(false);
    //     let displayMessage = "";
    //     if (Array.isArray(response.message)) {
    //       displayMessage = response.message[0].message;
    //     } else {
    //       displayMessage = response.message;
    //       if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
    //         displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
    //       }
    //     }
    //     notify(displayMessage, "error");
    //   }
    // } catch (error) {
    //   setSubmitting(false);
    //   notify("Something went wrong. Please try again later.", "error");
    // }
  };

  return (
<Popup trigger={button} modal nested position="right center">
    {close => (
      <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
            <div className="w-full flex justify-between">
              <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">{title}</h1>
              <div className="flex">
                
  
                <button className="button" onClick={() => { close(); }} >
                  <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close"/>
                </button>
              </div>
            </div>
            <div className="bg-white">
      <div className="relative overflow-hidden">
        
        <main>
          {/* Feature section with screenshot */}
          <div className="relative max-w-6xl mx-auto bg-white rounded-t-md h-[400px]">
            <img
              src={event && event.event.eventImage?.large ? event.event.eventImage.large.url : "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"}
              className="w-full h-full object-cover"
            />
          </div>
         
          {/* Feature section with grid */}
          <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
             {event.event.eventName}
              </p>
              <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
              {event.event.eventDescription}
              </p>
              <section aria-labelledby="contact-info-heading">
            
            <a href={event?.eventLink ? (event?.eventLink.indexOf("http") > -1 ? event?.eventLink : `https://${event?.eventLink}`) : "#"} target="_blank" rel="noreferrer">
              <div className="mt-2">
                <button
                className=" w-full text-center gap-x-2 rounded-md bg-danger-500 px-4.5 py-3.5 text-lg font-semibold text-white shadow-sm hover:bg-danger-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                target="_blank">
                Virtual Event Link: <span className="text-center underline text-white">{event?.eventLink || ""}</span>
                  </button>
                
              </div>
            </a>
          
        </section>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-success-800 p-3 shadow-lg">
                  
                  <MdDateRange className="h-8 w-8 text-white"   />
                  
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Event Date & Time</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">{format(new Date(event.event.eventDate), "MM/dd/yy")}, {timeFormatter(event.event.startTime)} – {timeFormatter(event.event.endTime)} EST</p>
            </div>
          </div>
        </div>

        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-primary-400 p-3 shadow-lg">
                  <MdLocationPin className="h-8 w-8 text-white" />
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Event Address</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">{event.event.eventLocation.streetAddress}, {event.event.eventLocation.city}, {event.event.eventLocation.state} - {event.event.eventLocation.zip}</p>
              { event?.eventCOhatchMarket ?
                 <p className="mt-2 text-base leading-7 text-gray-600">{event.event.eventCOhatchMeetingRoom} - {event.event.eventCOhatchLocation}, {event.event.eventCOhatchMarket}</p>
                  : null
              }
            </div>
          </div>
        </div>

        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-danger-500 p-3 shadow-lg">
                  <MdOutlineAppRegistration className="h-8 w-8 text-white" />
                    
                </span>
              </div>
              <h3 className="mt-7 text-lg font-semibold leading-8 tracking-tight text-gray-900">RSVP</h3>
              
            </div>
            {event?.event.userId !== userState.id && <EventRsvpForm
      button={<button
      type="button"
      className="mt-4 ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
      >
        {/* {event.event.userRSVP?.length !== 0 ? "Edit RSVP" : "RSVP Here"} */}
        {"Edit RSVP"}
      </button>}
      title="Workshops Form"
      event={event}
      reload={reload}
    />}
          </div>
        </div>
                </div>
              </div>
            </div>
          </div>

          {/* Testimonial section */}
          <div className="bg-gradient-to-r from-success-800 to-primary-400 pb-16 lg:relative lg:z-10 lg:pb-0">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
              <div className="relative lg:-my-8">
                <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                  <div className="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                    <img
                      className="object-event lg:h-full lg:w-full"
                      src={event.event.eventOwnerImage?.small?.url || "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                  <blockquote>
                    <div>
                      <p className="mt-6 text-2xl font-medium text-white">
                       About the Event Organizer
                      </p>
                    </div>
                    <footer className="mt-6">
                      <p className="text-base font-medium text-white">{event.event.eventOwnerFirstName} {event.event.eventOwnerLastName}</p>
                      <p className="text-base font-medium text-cyan-100">{event.event.aboutOrganization}</p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
          <p className="mt-6 text-lg leading-8 text-center text-gray-600">Add Event to Calendar</p>
          <div className="mt-10 flex justify-center space-x-10">
            <AddToCalendarButton
              name={event.event.eventName}
              startDate={event.event.eventDate}
              startTime={event.event.startTime}
              endTime={event.event.endTime}
              location={event.eventLink}
              description="Check out the maybe easiest way to include Add to Calendar Buttons to your web projects:[br]→ [url]https://add-to-calendar-button.com/"
              options="'Apple','Google','iCal','Outlook.com'"
              buttonsList
              hideTextLabelButton
              buttonStyle="round"
              lightMode="bodyScheme"
            >
            </AddToCalendarButton>
          </div> 
   
        </div>
      </div>
    </div>
          </div>
        </div>
      </div>

      <Prompt
        header={displayPromptMessage.heading}
        body={displayPromptMessage.body}
        ref={promptRef}
      />
    </div>
    )}
  </Popup>

  );
}
