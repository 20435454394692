/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import notify from "../../services/toast";
import useUserContext from "../../hooks/useUserContext";
import useConversationContext from "../../hooks/useConversationContext";
import createConversation from "./createConversation";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsChatLeftTextFill, BsInfoCircleFill } from "react-icons/bs";
import addFavorite from "../../pages/local_discount/addFavorite";
import { MAGIC_NUMBERS } from "../../constant";
import deleteFavorite from "../../pages/local_discount/deleteFavorite";

// eslint-disable-next-line react/prop-types
function MemberCard({ member, isFavorite, fid, onReload }) {
  const { userState } = useUserContext();
  const { setCurrentConversation } = useConversationContext();
  const navigate = useNavigate();
  const photo = member?.photo?.small ? member.photo.small.url : null;
  const firstName = member?.firstName ? member.firstName : null;
  const lastName = member?.lastName ? member.lastName : null;
  const jobTitle = member?.jobTitle ? member.jobTitle : null;
  const state = member?.state ? member.state : null;
  const city = member?.city ? member.city : null;
  const bio = member?.bio ? member.bio : null;
  const facebook = member?.facebook ? member.facebook : null;
  const instagram = member?.instagram ? member.instagram : null;
  const twitter = member?.twitter ? member.twitter : null;
  const linkedIn = member?.facebook ? member.linkedIn : null;
  const website = member?.website ? member.website : null;

  const handleFavorite = async () => {
    if (!isFavorite) {
      const response = await addFavorite(
        {
          target: member._id,
          kind: "Member",
        },
        userState.accessToken
      );
      if (response.status === "success") {
        notify("This user was successfully added to your favorite list.", "info");
        if (onReload) {
          onReload();
        }
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } else {
      const response = await deleteFavorite(fid, userState.accessToken);
      if (response.status === "success") {
        notify("This user was successfully removed from your favorite list.", "info");
        if (onReload) {
          onReload();
        }
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    }
  };


  const handleCreateConversation = async () => {
    try {
      const payload = {
        recipientId: member.id || member._id,
      };
      const response = await createConversation(payload, userState.accessToken);
      if (response.status === "success") {
        setCurrentConversation(response.data[0]);
        const [{ _id }] = response.data;
        navigate(`/dashboard/messaging/${_id}`);
      } else {
        notify("An error occurred while creating this conversation. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };
  return (
    <>
      {member?._id || member?.id ? (
        <li className="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-gray-200 ">
          <span className="material-icons cursor-pointer text-lg mr-1" onClick={handleFavorite}>
            {isFavorite ? "favorite" : "favorite_border"}
          </span>
          <Link to={`/profile/${member._id || member.id}`}>
            <div className="flex items-center gap-x-4">
              <img className="rounded-full w-20 h-20"
                src={photo ? photo : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                alt={`${lastName} Profile Photo`}>
              </img>
              <div className="grow">
                <h3 className="font-medium text-gray-800">
                  {firstName} {lastName}
                </h3>
                {jobTitle ? (
                  <p className="text-xs uppercase text-gray-500">
                    {jobTitle}
                  </p>
                ) : null}
                <p className="text-xs uppercase text-gray-500">
                  {city || state ? (
                    <>
                      <dt className="sr-only">location</dt>
                      <dd className="mt-3">
                        <span className="px-2 py-1 text-success-800 text-xs font-medium bg-primary-100 rounded-full capitalize">
                          {city}, {state}
                        </span>
                      </dd>
                    </>
                  ) : null}
                </p>
              </div>
            </div>
            {bio ? (
            <p className="mt-3 text-gray-500 line-clamp-3">
             {bio}
            </p>
            ) : null}
          </Link>
          {/* <!-- Social Brands --> */}
          <div className="mt-3 space-x-1">
            {facebook ? (
              <Link to={facebook} className="text-gray-400 hover:text-gray-500 inline-flex items-center space-x-2 rounded">
                <span className="sr-only">Facebook</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
              </Link>
            ) : null}
            {instagram ? (
              <Link to={instagram} className="text-gray-400 hover:text-gray-500 inline-flex items-center space-x-2 rounded">
                <span className="sr-only">Instagram</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                </svg>
              </Link>
            ) : null}
            {twitter ? (
              <Link to={twitter} className="text-gray-400 hover:text-gray-500 inline-flex items-center space-x-2 rounded">
                <span className="sr-only">Twitter</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </Link>
            ) : null}

            {linkedIn ? (
              <Link to={linkedIn} className="text-gray-400 hover:text-gray-500 inline-flex items-center space-x-2 rounded">
                <span className="sr-only">Website</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clipRule="evenodd" />
                </svg>
              </Link>
            ) : null}

            {website ? (
              <Link to={website} className="text-gray-400 hover:text-gray-500 inline-flex items-center space-x-2 rounded">
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                </svg>
              </Link>
            ) : null}

          </div>
          {/* <!-- End Social Brands --> */}

          <div className="flex justify-center gap-2 p-6 pt-4">
            {userState.id === member?.id || userState.id === member?._id ? null : (
              <button onClick={handleCreateConversation}
                className="inline-flex items-center justify-center flex-1 h-10 gap-2 px-5 text-sm font-medium tracking-wide transition duration-300 rounded focus-visible:outline-none justify-self-center whitespace-nowrap bg-success-800 text-white hover:bg-success-50 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
                <span className="order-2">Message</span>
                <span className="relative only:-mx-5">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5" role="graphics-symbol" aria-labelledby="title-21 desc-21">
                    <title id="title-21">Icon title</title>
                    <desc id="desc-21">
                      A more detailed description of the icon
                    </desc>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                </span>
              </button>
            )}
            <Link to={`/profile/${member._id || member.id}`} className="inline-flex items-center justify-center flex-1 h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-primary-400 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="order-2">More Info</span>
              <span className="relative only:-mx-5">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5" role="graphics-symbol" aria-labelledby="title-06 desc-06">
                  <title id="title-06">Icon title</title>
                  <desc id="desc-06">
                    A more detailed description of the icon
                  </desc>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                </svg>
              </span>
            </Link>
          </div>
        </li>
      ) : null}
    </>
  );
}

export default MemberCard;
