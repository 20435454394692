/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import ButtonPrimary from "../button/ButtonPrimary";
import Modal from "../modal/Modal";
import { CAUSES } from "../../constant";

function AddCauses({ showSelectedCauses, complete, DisplayIcon, className, heading = "Add Causes" }) {
  const [open, setOpen] = useState(false);
  const [selectedCauses, setSelectedCauses] = useState([]);

  const handleDone = () => {
    setOpen(false);
    complete();
    showSelectedCauses(selectedCauses);
  };

  const handleCheckboxChange = (value) => {
    setSelectedCauses((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((selectedValue) => selectedValue !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  

  return (
    <>
      {open ? (
        <Modal>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
                <div className="w-full flex justify-between items-center">
                  <h2 className="text-lg font-bold capitalize">{heading}</h2>
                  <div>
                    <ButtonPrimary size="md" text="Done" action={handleDone} />
                  </div>
                </div>
                <ul className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
                  {CAUSES.map(({ label, value }, index) => {
                    return (
                      <li className="w-full " key={index}>
                        <div className="flex items-center pl-3">
                          <input
                            id={`feature-checkbox-${value}`}
                            type="checkbox"
                            name={label}
                            checked={selectedCauses.includes(value)}
                            onChange={() => handleCheckboxChange(value)}
                            className="w-4 h-4 text-primary-400 bg-white rounded border-gray-300 focus:ring-primary-400 dark:focus:ring-primary-400 dark:ring-offset-primary-400 focus:ring-2 dark:bg-white dark:border-gray-500"
                          />
                          <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`feature-checkbox-${value}`}>{label}</label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <DisplayIcon className={className ? className : "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"} callback={() => setOpen(true)} />
      )}
    </>
  );
}

export default AddCauses;
