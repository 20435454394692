/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";

function PropertyPrice({
  property,
  setProperty,
}) {

  const submitData = (key, value) => {
    setProperty((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Property Price</h3>
          <p className="mt-1 text-sm text-gray-500">
          Please enter the rate per night in USD
          </p>
        </div>
        
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="minimum-price" className="block text-sm font-medium text-gray-700">
             Minimum Price per Night
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
            <input
              type="number"
              name="minimum-price"
              id="minimum-price"
              autoComplete="given-number"
              placeholder="$"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
              onChange={(event) => submitData("minPrice", parseInt(event.target.value, 10))}
              value={property?.minPrice}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="maximum-price" className="block text-sm font-medium text-gray-700">
             Maximum Price per Night
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
            <input
              type="number"
              name="maximum-price"
              id="maximum-price"
              autoComplete="given-number"
              placeholder="$"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
              onChange={(event) => submitData("maxPrice", parseInt(event.target.value, 10))}
              value={property?.maxPrice}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyPrice;