/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VacationCard from "../../../components/vacation_card/VacationCard";
import PropertyUpdate from "../add_vacation_home/PropertyUpdate";
import DefaultState from "../../../components/default_state/DefaultState";
import SelectInput from "../../../components/select_input/SelectInput";
import PropertySearch from "../../../components/property_search/PropertySearch";
import useUserContext from "./../../../hooks/useUserContext";
import notify from "../../../services/toast";
import Pagination from "../../../components/pagination/Pagination";
import { MAGIC_NUMBERS } from "../../../constant";
import Spinner from "../../../components/spinner/Spinner";
import getPropertyByType from "../all_homes/getPropertyByType";
import deleteProperty from "./deleteProperty";
import {PROPERTY_CATEGORY} from "../../../constant";


function MyVacationHomes() {
  const {
    userState: { accessToken },
  } = useUserContext();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const [properties, setProperties] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({ total: 0, page: 0, remaining: 0, limit: 6 });
  const [property, setProperty] = useState(null);
  const [propertyType, setPropertyType] = useState("");

  const getProperties = async (page, limit, type) => {
    try {
      setFetching(true);
      const response = await getPropertyByType(accessToken, page, limit, type, keyword, {
      	creator: true
      });
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setProperties(results);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const handleDelete = async (propertyId) => {
    try {
      const response = await deleteProperty(accessToken, propertyId);
      if (response.status === "success") {
        setPagination({ total: 0, page: 0, remaining: 0, limit: 6 });
        setProperties([]);
        getProperties(0, 6, propertyType);
        notify("Property successfully deleted.", "info");
      } else {
        notify("An error occurred while trying to delete property. Contact support if this error persist", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  const handlePrevFetch = () => {
    getProperties(pagination.page - 1, pagination.limit, propertyType);
  };

  const handleNextFetch = () => {
    getProperties(pagination.page + 1, pagination.limit, propertyType);
  };

  const handleSearch = () => {
    setPagination({ total: 0, page: 0, remaining: 0, limit: 6 });
    setProperties([]);
    getProperties(0, 6, propertyType);
  };

  useEffect(() => {
    setPagination({ total: 0, page: 0, remaining: 0, limit: 6 });
    setProperties([]);
    getProperties(0, 6, propertyType);
  }, []);

  if (property) {
  	return <PropertyUpdate property={property} onClose={() => setProperty(null)} onUpdate={() => {
  		setProperty(null);
  		handleSearch();
  	}} />;
  }

    return(
      <>
      <div className="sm:block bg-white">
      <div className="w-full flex gap-3 justify-between items-center">
        <div className="w-full mt-1 relative flex items-center">
          
        </div>
        <div className="w-1/5 mt-4">
          <SelectInput
            options={PROPERTY_CATEGORY}
            placeholder="Select a category"
            defaultValue={() => {
              return propertyType ? PROPERTY_CATEGORY.find(item => item.value === propertyType) : null;
            }}
            setSelectedValue={(event) => {
              setPagination({ total: 0, page: 0, remaining: 0, limit: 6 });
              setProperties([]);
              getProperties(0, 6, event ? event.value : "");
              setPropertyType(event ? event.value : "");
            }}
          />
        </div>
      </div>
        
        <div className="bg-white">
          <div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
            {fetching ? (
              <div className="text-center w-full relative">
                <Spinner displayText="Fetching..." />
              </div>
            ) : (<>
              {properties.length ? (<>
                <div className="mb-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                  {properties.map(property => <VacationCard key={property._id} property={property} onDelete={handleDelete} onEdit={setProperty} />)}
                </div>
                <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
              </>) : (
                <DefaultState
                  icon="storefront"
                  heading="Your properties will appear here"
                  action={() => navigate("/dashboard/vacation_homes/add_vacation_home")}
                  actionText="Add Home"
                />
              )}
            </>)}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyVacationHomes;
