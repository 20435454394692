import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function getRecentMembers(authToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/neighborhood_feed/latest_member`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getRecentMembers;
