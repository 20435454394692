/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { MdLocalSee } from "react-icons/md";
import useUserContext from "./../../../../hooks/useUserContext";
import FileUploader from "../../../../components/file_uploader/FileUploader";
import { generateClassName } from "./../../../../services/util";
import notify from "../../../../services/toast";
import { validateUserName, validateEmail, validateMessage } from "../../../../services/validation";

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

const imageCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };

function PropertyOwnerInfo({
  property,
  setProperty,
  isSaved,
}) {
  const { userState } = useUserContext();
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validBio, setValidBio] = useState(false);

  useEffect(() => {
    const result = validateUserName(property?.propertyOwnerFirstName);
    setValidFirstName(result);
  }, [property?.propertyOwnerFirstName]);

  useEffect(() => {
    const result = validateUserName(property?.propertyOwnerLastName);
    setValidLastName(result);
  }, [property?.propertyOwnerLastName]);

  useEffect(() => {
    const result = validateMessage(property?.propertyOwnerBio);
    setValidBio(result);
  }, [property?.propertyOwnerBio]);

  useEffect(() => {
    const result = validateEmail(property?.propertyOwnerEmailAddress);
    setValidEmail(result);
  }, [property?.propertyOwnerEmailAddress]);

  const submitData = (key, value) => {
    setProperty((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleUpload = result => {
    setProperty({
      ...property,
      propertyOwnerImage: result
    });
  };

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Host Information</h3>
          <p className="mt-1 text-sm text-gray-500">Who should guests contact to book your property?</p>
        </div>
        <div className="col-span-3">
        <div className="w-full flex justify-between items-center mb-1">
        <label className="block text-sm font-medium text-gray-700">Profile Image<div className="w-2 h-2 rounded-full bg-red-500"></div></label>
        <span className="w-2 h-2 rounded-full bg-red-500"></span>
      </div>
            <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
              {property?.propertyOwnerImage?.small.url ? <img src={property?.propertyOwnerImage.small.url} className="w-full h-full" alt="property owner image upload" /> : <p className="w-full pt-8 text-md text-center text-gray-400">Profile Image</p>}
              <FileUploader
                fileType="image/*"
                isMultiple={false}
                validationCriteria={imageCriteria}
                DisplayIcon={PhotoDisplay}
                accessToken={userState.accessToken}
                folder="property_photos"
                description="Image - Min: 400 X 400, Max: 5MB"
                numberOfFiles={1}
                callback={handleUpload}
              />
            </div>
          </div>

        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
            <input
              onChange={(event) => submitData("propertyOwnerFirstName", event.target.value)}
              type="text"
              name="first_name"
              id="first_name"
              autoComplete="off"
              value={property?.propertyOwnerFirstName}
              className={generateClassName(!validFirstName && property?.propertyOwnerFirstName)}
              placeholder=""
              aria-describedby="name-optional"
            />
          </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
            <input
              onChange={(event) => submitData("propertyOwnerLastName", event.target.value)}
              type="text"
              name="last_name"
              id="last_name"
              autoComplete="off"
              value={property?.propertyOwnerLastName}
              className={generateClassName(!validLastName && property?.propertyOwnerLastName)}
              placeholder=""
              aria-describedby="name-optional"
            />
        </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="owners-phone" className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
              onChange={(event) => submitData("propertyOwnerPhoneNumber", event.target.value)}
              type="text"
              name="phone_number"
              id="phone_number"
              autoComplete="off"
              value={property?.propertyOwnerPhoneNumber}
              className={generateClassName(false)}
              placeholder=""
              aria-describedby="name-optional"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
            <input
              onChange={(event) => submitData("propertyOwnerEmailAddress", event.target.value)}
              type="email"
              name="owner_email"
              id="owner_email"
              autoComplete="off"
              value={property?.propertyOwnerEmailAddress}
              className={generateClassName(!validEmail && property?.propertyOwnerEmailAddress)}
              placeholder="company@domain.com"
              aria-describedby="email-optional"
            />
          </div>

          <div className="col-span-6">
          <label htmlFor="owners-bio" className="block text-sm font-medium text-gray-700">
            Bio
          </label>
          <div className="mt-1">
            <textarea
              id="owners-bio"
              name="owners-bio"
              rows={3}
              className={generateClassName(!validBio && property?.propertyOwnerBio)}
              placeholder="Please share a brief description of the host."
              onChange={(event) => submitData("propertyOwnerBio", event.target.value)}
              value={property?.propertyOwnerBio}
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyOwnerInfo;