import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function BreadCrumb({ directions }) {
  return (
    <div>
      <nav className="hidden sm:flex justify-center" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          {directions.map((direction, index) =>
            index !== 0 ? (
              <li key={uuid()}>
                <div className="flex items-center">
                  <span className="material-icons-outlined flex-shrink-0 text-gray-400">chevron_right</span>
                  <Link to={direction.url} className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {direction.pathName}
                  </Link>
                </div>
              </li>
            ) : (
              <li key={uuid()}>
                <div className="flex">
                  <Link to={direction.url} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    {direction.pathName}
                  </Link>
                </div>
              </li>
            )
          )}
        </ol>
      </nav>
    </div>
  );
}

BreadCrumb.propTypes = {
  directions: PropTypes.array.isRequired,
};
export default BreadCrumb;
