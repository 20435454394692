/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
import React, { useState } from "react";
import {FaWindowClose} from "react-icons/fa";
import Popup from "reactjs-popup";
import createRSVP from "./createRSVP";
import { MAGIC_NUMBERS } from "../../constant";
import useUserContext from "./../../hooks/useUserContext";
import notify from "../../services/toast";

export default function EventRsvpForm({ button, title, event, reload }) {
  const [attending, setAttending] = useState(event.status ? event.status : "attending");
  const [firstName, setFirstName] = useState(event.firstName ? event.firstName : "");
  const [lastName, setLastName] = useState(event.lastName ? event.lastName : "");
  const [email, setEmail] = useState(event.email ? event.email : "");
  const [submitListing, setSubmitting] = useState(false);
  const {
    userState: { accessToken },
  } = useUserContext();

  const handleAdd = async (close) => {
    try {
      setSubmitting(true);
      const response = await createRSVP(accessToken, event.event.id ? event.event.id : event.event._id, {
        status: attending,
        firstName: firstName,
        lastName: lastName,
        email: email
      });
      if (response.status === "success") {
        notify("Your RSVP was successfully submitted.", "info");
      } else {
        setSubmitting(false);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
      close();
    } catch (error) {
      setSubmitting(false);
      close();
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      reload();
    }
  };

    return (
<Popup trigger={button} modal nested position="right center">
  {close => (
    <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
            <div className="w-full flex justify-between">
              <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">{title}</h1>
              <button className="button" onClick={() => { close(); }} >
                <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close"/> </button> 
            </div>
            
            <form className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="pt-2">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        First name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          onChange={evt => setFirstName(evt.target.value)}
                          defaultValue={firstName}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
  
                    <div className="sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          defaultValue={lastName}
                          onChange={evt => setLastName(evt.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          onChange={evt => setEmail(evt.target.value)}
                          defaultValue={email}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
  
                    <div className="sm:col-span-3">
                      <label htmlFor="attending" className="block text-sm font-medium text-gray-700">
                        Attending?
                      </label>
                      <div className="mt-1">
                        <select
                          id="attending"
                          name="attending"
                          autoComplete="attending"
                          defaultValue={attending}
                          onChange={evt => {
                            console.log(event);
                            console.log(evt.target.value);
                            setAttending(evt.target.value);
                          }}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                          required
                        >
                          <option value="attending">Yes</option>
                          <option value="maybe">Maybe</option>
                          <option value="not attending">No</option>
                        </select>
                  
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => { close(); }}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={submitListing}
                    type="button"
                    onClick={() => handleAdd(close)}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    )}
  </Popup>
  
    );
  }
  