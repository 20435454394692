/* eslint-disable react/prop-types */
import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function Reviews({ analytics }) {
  const labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const data = {
    labels,
    datasets: [
      {
        label: "Listing Reviews",
        data: analytics,
        borderColor: "#002a4d",
        backgroundColor: "#56c271",
      },
    ],
  };
  return (
    <div className="w-full">
      <h1 className="text-2xl text-success-800 font-bold">Listing Reviews</h1>
      <p className="text-sm text-gray-400">
        Review analytics are based on the number of times your listing is reviewed by another user. Reviews generated by the owner are not included.
      </p>
      <div className="w-full">
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

export default Reviews;
