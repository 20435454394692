/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
/* eslint-disable  */

import React, {useState} from "react";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import useUserContext from "./../../../hooks/useUserContext";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import LifePerksCreatePost from "../../../components/create_post/LifePerksCreatePost";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import ImpactTimeLine from "./ImpactTimeline";
import ImpactStats from "./ImpactStats";
import CauseCard from "../../../components/cause_card/CauseCard";
import DefaultState from "../../../components/default_state/DefaultState";
import { v4 as uuid } from "uuid";
import getFavoriteNonprofits from "./getFavoriteNonprofits";
import notify from "../../../services/toast";
import ListingCard from "../../../components/favorite_listing_card/FavoriteListingCard";
import getFeaturedVolunteerEvents from "../philanthropy_feed/getFeaturedVolunteerEvents";
import VolunteerCard from "../../../components/philanthropy/VolunteerCard";
import CreatePost from "../../../components/create_post/CreatePost";
import Spinner from "../../../components/spinner/Spinner";
import useWebsocket from "../../../hooks/useWebsocket";


function ImpactFeed () {
    useScrollPage();
    useTitle("Impact Feed");

   const { userState } = useUserContext();
   const { firstName, photo, causes } = userState;
   const [userCause, setUserCause] = React.useState(causes || []);
   const [favoriteListings, setFavoriteListings] = React.useState([]);
   const [fetching, setFetching] = React.useState(false);
   const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 6 });
     const [featuredVolunteerEvents, setFeaturedVolunteerEvents] = React.useState([]);
  const [fetchingVolunteerEvents, setFetchingVolunteerEvents] = React.useState(false);
const coverPhoto = favoriteListings?.businessPhotos?.coverPhoto?.small ? listing.businessPhotos.coverPhoto.small.url : null;
 const [posts, setPosts] = React.useState([]);
  const [fetchingPost, setFetchingPost] = React.useState(false);
  const group = {
    _id: "64809e5b05b17ddb585e7e0d",
    name: "Impact",
    creator: "63a47b79dc152f79738ca7c9"
  };
  const rootRef = React.useRef(null);
const remaining = React.useRef(0);
   const socket = React.useRef(useWebsocket(userState.accessToken));
   const [impactStats, setImpactStats] = useState();
   const [editImpactStats, setEditImpactStats] = React.useState({
    volunteerHours: null,
    donationAmount: null,
    eventsAttended: null
   })


  const reload = async () => {
    try {
      setFetchingPost(true);
      const response = await getPost(userState.accessToken, group._id, 0, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        remaining.current = remainingResult;
        page.current = 0;
        setPosts([]);
        setPosts(results);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };

 const fetchFavoriteNonprofits = async (accessToken, page, limit) => {
    try {
      setFetching(true);
      const response = await getFavoriteNonprofits(accessToken, page, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setFavoriteListings(results);
      } else {
        notify(response.message, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };
    const fetchFeaturedVolunteerEvents = async (accessToken) => {
  
        try {
    setFetchingVolunteerEvents(true);
    const response = await getFeaturedVolunteerEvents(accessToken);

    if (response.status === "success") {
      
      console.log("event", response.data);
      setFeaturedVolunteerEvents(response.data);
    }
  } catch (error) {
    notify("Something went wrong. Please try again later", "error");
  } finally {
    setFetchingVolunteerEvents(false);
  }
  };

 React.useEffect(() => {
    fetchFavoriteNonprofits(userState.accessToken, 0, 6);
    fetchFeaturedVolunteerEvents(userState.accessToken);
  }, []);
    console.log(favoriteListings);
    return (
      <div className="bg-gradient-to-br from-gray-50 to-green-200">	
       <div className="grid grid-cols-1 divide-white divide-y">
       <div className="w-full py-8 px-5 text-center ">
      <div className="max-w-xl mb-5">
          <h1 className="ml-20 text-4xl md:text-5xl text-success-800 font-bold">
            <span className="mr-3 material-icons-outlined text-success-800 text-7xl">public</span>
            My Impact
          </h1>
			</div>
      </div>
				
     
        <div className="section-1 ">
        <div className="max-w-7xl px-6 lg:px-8 mt-5">
          <h1 className="font-bold text-2xl text-gray-900 mb-5">Stats</h1>
          <div className="widgets gap-x-20 p-5 flex-1 flex ">
          <ImpactStats 
          impactStats={impactStats} 
          setImpactStats={setEditImpactStats}
          handleSubmit={handleSubmit}/>
          
        </div>
        </div>
      </div>
      <div className="section-2">
        <div className="max-w-7xl px-6 lg:px-8 mt-5">
          <h1 className="font-bold text-2xl text-gray-900">Causes You Care About</h1>
              <div className="widgets gap-x-20 p-5 flex-1 flex ">
               <div className="mt-5 w-full">
							<div className="w-full flex flex-wrap gap-2 items-start justify-start overflow-y-auto p-3">
								{!userCause.length ? (
									<DefaultState
										icon="diversity_1"
										heading="No Causes You Care About Found"
										description="Add the causes you care about."
									/>
								) : (
									userCause.map((value, index) => (
										<CauseCard
											key={uuid()}
											cause={{ value, index }}
											removeCause=""
										/>
									))
								)}
							</div>
						</div>
             
            </div>
          </div>
      </div>
       <div className="section-3">
        <div className="max-w-7xl px-6 lg:px-8 mt-5 mb-5">
          <h1 className="font-bold text-2xl text-gray-900 mb-5">Favorite Nonprofits</h1>
             <div className="w-full max-w-xs shadow-xl rounded bg-white">
      <div className="np-card w-full">
        <Link to={`/marketplace/listing`}>
          <div
            style={{
              backgroundImage: `url(${coverPhoto ? coverPhoto : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_1.png"})`,
            }}
            className="w-full h-[200px] bg-center bg-no-repeat bg-cover"></div>
          <div className="w-full bg-white p-3 space-y-3">
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">business</span>
              <h1 className="text-xl text-success-800 font-bold capitalize truncate">Nonprofit Test</h1>
            </div>
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">location_on</span>
              <h1 className="text-md text-success-800 capitalize truncate">1899 Cleveland</h1>
            </div>
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">person</span>
              <h1 className="text-md text-success-800 capitalize trunc">
                John Peters
              </h1>
            </div>
          </div>
        <div className="flex justify-end mr-5">
              <div>
              <span
              className="material-icons-outlined text-2xl cursor-pointer text-primary-400 hover:text-primary-400 transition-all duration-500 "
            >
              favorite
            </span>
            </div>
        </div>
        </Link>
      </div>
    </div>
              {/* {favoriteListings.length ? (
                favoriteListings.map((listing, index) => {
                  return <ListingCard key={index} listing={listing.target} favoriteId={listing._id}/>;
                })
              ) : (
                <DefaultState icon="storefront" heading="No Favorite Nonprofits" description="Your favorite nonprofits will appear here" />
              )} */}
              
            </div>
          </div>
     
        <div className="section-4">
        <div className="max-w-7xl px-6 lg:px-8 mt-5">
          <h1 className="font-bold text-2xl text-gray-900 mb-5">Favorite Impact Events and Drives</h1>
              <div className="widgets gap-x-20 p-5 flex-1 flex ">
                 {featuredVolunteerEvents.map((event) => {
            return (
              <>
              <VolunteerCard index={event._id} event={event} />
              <VolunteerCard index={event._id} event={event} />
              
              </>
            );
          })}
              </div> 
            </div>
          </div>

           <div className="section-5">
        <div className="max-w-2xl px-6 lg:px-8 mt-5 mb-20">
          <h1 className="font-bold text-2xl text-gray-900 mb-5">Share a Post to be Featured on the Feed</h1>
         <div className="bg-white p-6">
           <h1 className="ml-3 mb-3 text-l leading-7 "> Have you Impacted your community lately? Share photo of a recent volunteer event or anything you've done to help in your community. </h1>
             <div className="shadow w-full space-y-3">
        <CreatePost groupId={group._id} groupName={group.name} reload={reload} setPosts={setPosts} creatorId={group.creator} socket={socket} />
        <div ref={rootRef} className="w-full space-y-3">
          {posts.map((post, index) => {
            return <Post key={index} post={post} reload={reload} socket={socket} groupId={group._id} groupName={group.name} creatorId={group.creator} />;
          })}

          {posts.length <= 0}

          {fetchingPost && <Spinner displayText="Fetching..." />}

          {remaining.current > 0 ? (
            <span ref={setElement} className="w-full flex justify-center text-gray-400">
              Loading...
            </span>
          ) : null}

          {posts.length && remaining.current <= 0 ? <span className="w-full flex justify-center text-gray-400">No more posts to load</span> : null}
        </div>
      </div>
         </div>
              </div>
            </div>
          </div>
      </div>
     


         
    );
}

export default ImpactFeed;






  