/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { v4 as uuid } from "uuid";
import notify from "../../services/toast";

function validateImageFiles(imageFile, validationCriteria, setReadingProgress) {
  const { originalName, size, image, error } = imageFile;
  const {
    requiredSize,
    requiredDimensions: { width, height },
  } = validationCriteria;

  setReadingProgress(`Validating size of ${originalName}.`);
  if (size > requiredSize) {
    error["fileSize"] = `Your image file size is ~ ${Math.floor(size / 1e6)}MB is greater than ${Math.floor(requiredSize / 1e6)}MB.`;
    notify(`Sorry, your image file size is greater than ${Math.floor(requiredSize / 1e6)}MB.`, "error");
  }

  setReadingProgress(`Validating dimensions of ${originalName}.`);
  if (image.width < width || image.height < height) {
    error["fileDimension"] = `Your image dimensions are ${image.width} x ${image.height}. Required dimensions are ${width} x ${height}.`;
  }

  setReadingProgress("");
  return imageFile;
}

function validateVideoFiles(videoFile, validationCriteria, setReadingProgress) {
  const { originalName, size, error } = videoFile;
  const { requiredSize } = validationCriteria;

  setReadingProgress(`Validating size of ${originalName}.`);
  if (size > requiredSize) {
    error["fileSize"] = `Your video file size is ~ ${Math.floor(size / 1e6)}MB is greater than ${Math.floor(requiredSize / 1e6)}MB.`;
    notify(`Sorry, your video file size is greater than ${Math.floor(requiredSize / 1e6)}MB.`, "error");
  }

  setReadingProgress("");
  return videoFile;
}

export function readAndValidateImageFile(arrayOfImageFiles, criteria, setReadingProgress, setQueue) {
  //Read image
  for (const imageFile of arrayOfImageFiles) {
    const fileReader = new FileReader();
    const { name: imageName, size, type } = imageFile;
    if (type.startsWith("image")) {
      fileReader.readAsDataURL(imageFile);

      fileReader.addEventListener("loadstart", () => {
        setReadingProgress(`Reading ${imageName.toLowerCase()}`);
      });

      fileReader.addEventListener("error", () => {
        setReadingProgress(`Error reading ${imageName.toLowerCase()}`);
      });

      fileReader.addEventListener("progress", (event) => {
        const percentageRead = Math.round((event.loaded / event.total) * 100);
        setReadingProgress(`Read ${percentageRead}% of ${imageName.toLowerCase()}`);
      });

      fileReader.addEventListener("load", (event) => {
        setReadingProgress(`Successfully read ${imageName.toLowerCase()}`);
        const image = new Image();
        image.src = event.target.result;

        image.addEventListener("load", () => {
          setReadingProgress(`Loading ${imageName.toLowerCase()}`);
          const validatedFile = validateImageFiles({ file: imageFile, originalName: imageName, derivedName: uuid(), size, image, error: {} }, criteria, setReadingProgress);
          setQueue((prevState) => {
            return [...prevState, validatedFile];
          });
        });
      });
    }
  }
}


export function readAndValidateAppFiles(arrayOfImageFiles, setReadingProgress, setQueue) {
  //Read image
  for (const imageFile of arrayOfImageFiles) {
    const fileReader = new FileReader();
    const { name: imageName, size, type } = imageFile;
    if (type.startsWith("app")) {
      fileReader.readAsDataURL(imageFile);

      fileReader.addEventListener("loadstart", () => {
        setReadingProgress(`Reading ${imageName.toLowerCase()}`);
      });

      fileReader.addEventListener("error", () => {
        setReadingProgress(`Error reading ${imageName.toLowerCase()}`);
      });

      fileReader.addEventListener("progress", (event) => {
        const percentageRead = Math.round((event.loaded / event.total) * 100);
        setReadingProgress(`Read ${percentageRead}% of ${imageName.toLowerCase()}`);
      });

      fileReader.addEventListener("load", (event) => {
        setReadingProgress(`Successfully read ${imageName.toLowerCase()}`);
        const image = new Image();
        image.src = event.target.result;

        image.addEventListener("load", () => {
          setReadingProgress(`Loading ${imageName.toLowerCase()}`);
          const validatedFile = validateImageFiles({ file: imageFile, originalName: imageName, derivedName: uuid(), size, image, error: {} }, setReadingProgress);
          setQueue((prevState) => {
            return [...prevState, validatedFile];
          });
        });
      });
    }
  }
}

export function readAndValidateVideoFile(arrayOfVideoFiles, criteria, setReadingProgress, setQueue) {
  //Read image
  for (const videoFile of arrayOfVideoFiles) {
    const fileReader = new FileReader();
    const { name: videoName, size, type } = videoFile;

    if (type.startsWith("video")) {
      fileReader.readAsDataURL(videoFile);

      fileReader.addEventListener("loadstart", () => {
        setReadingProgress(`Reading ${videoName.toLowerCase()}`);
      });

      fileReader.addEventListener("error", () => {
        setReadingProgress(`Error reading ${videoName.toLowerCase()}`);
      });

      fileReader.addEventListener("progress", (event) => {
        const percentageRead = Math.round((event.loaded / event.total) * 100);
        setReadingProgress(`Read ${percentageRead}% of ${videoName.toLowerCase()}`);
      });

      fileReader.addEventListener("load", (event) => {
        const source = event.target.result;
        setReadingProgress(`Successfully read ${videoName.toLowerCase()}`);
        const validatedFile = validateVideoFiles({ file: videoFile, originalName: videoName, derivedName: uuid(), source, size, error: {} }, criteria, setReadingProgress);
        setQueue((prevState) => {
          return [...prevState, validatedFile];
        });
      });
    }
  }
}
