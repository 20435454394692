/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { MdStar } from "react-icons/md";
import { Link } from "react-router-dom";
import { inArrayObject } from "../../services/util";
import notify from "../../services/toast";
import Option from "../option/Option";
import createFavorite from "../display_listing/createFavorite";
import useUserContext from "./../../hooks/useUserContext";
import config from "../../config";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
 
export default function VacationCard({
  property,
  onDelete,
  onEdit,
  refetchFavorites,
  forceFavorite,
}) {
  const {
    userState,
  } = useUserContext();
  const [isFavorite, setIsFavorite] = useState(inArrayObject(property?.favorites || [], userState.id, "creator"));

  const handleCreateFavorite = async () => {
    try {
      const payload = {
        kind: "Property",
        target: property._id,
      };
      const response = await createFavorite(payload, userState.accessToken);
      if (response.status === "success") {
        setIsFavorite(response.data);
        if (refetchFavorites) {
          refetchFavorites();
        }
      } else {
        notify("An error occurred while creating favorite. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  let totalReview = 0;
  (property?.reviews || []).forEach(review => {
    totalReview += review.total;
  });

  return (
    <div className={`${onDelete ? "" : "group"} relative`}>
      <div className={`w-full ${property._id === config["SANDY_PANTS_PROPERTY_ID"] ? "bg-primary-100" : "bg-gray-100"} p-2 flex justify-end`}>
        <span
          onClick={handleCreateFavorite}
          className={`material-icons-outlined text-2xl cursor-pointer hover:text-primary-400 transition-all duration-500 ${(isFavorite || forceFavorite) ? "text-primary-400" : "text-gray-400"}`}
        >
          favorite
        </span>
        {onDelete ? <Option
          actions={[
            { text: "Delete", icon: "delete", key: "delete", action: () => onDelete(property._id) },
            { text: "Edit", icon: "edit", key: "edit", action: () => onEdit(property) },
          ]}
          itemKey={property.userId}
          deleteAction={() => onDelete(property._id)}
        />: null}
      </div>
      <div className={`aspect-w-4 aspect-h-3 overflow-hidden ${property._id === config["SANDY_PANTS_PROPERTY_ID"] ? "bg-primary-100" : "bg-gray-100"}`}>
        <div className={`px-5 ${property._id === config["SANDY_PANTS_PROPERTY_ID"] ? "bg-primary-100" : "bg-gray-100"}`}>
          <img src={property?.propertyCoverImage?.small?.url} alt={property.propertyName} className="object-cover object-center" />
        </div>
       
        <div className="flex items-end p-6 opacity-0 group-hover:opacity-100" aria-hidden="true" style={{ marginTop: "-70px", marginBottom: "35px" }}>
          <div className="w-full rounded-md bg-white bg-opacity-75 py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter">
            View Rental
          </div>
        </div>
      </div>
      <div className="pt-10 pb-4 text-center">
          <h3 className="text-sm font-medium text-gray-900">
            {!onDelete ? <Link to={`/home_details/${property._id}`}>
              <span aria-hidden="true" className="absolute inset-0 top-[48px]" />
              {property.propertyName}
            </Link> : <div>{property.propertyName}</div>}
          </h3>
          <p className="mt-1 text-sm text-gray-500 text-center">{property.propertyLocation?.city}, {property.propertyLocation?.state}</p>
          <div className="mt-3 flex flex-col items-center">
            <p className="sr-only">{property.averageRating} out of 5 stars</p>
            <div className="flex items-center">
              {[0, 1, 2, 3, 4].map((rating) => (
                <MdStar
                  key={rating}
                  className={classNames(
                    property.averageRating > rating ? "text-yellow-400" : "text-gray-200",
                    "flex-shrink-0 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
              ))}
            </div>
        <p className="mt-1 text-sm text-gray-500">{totalReview} reviews</p>
      </div>
      <p className="mt-4 text-base font-medium text-gray-900">${property.minPrice} - ${property.maxPrice}/night</p>
    </div>
      {property.status === "PENDING" && <p className="mt-1 text-md text-red-500 text-center">PENDING</p>}
    </div>
  );
}
