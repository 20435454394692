/* eslint-disable  */
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import DefaultState from "../../../components/default_state/DefaultState";
import { Link } from "react-router-dom";
import getMyNonprofit from "../nonprofits/my_nonprofits/getMyNonprofits";
import "./stepper.css";
import useUserContext from "../../../hooks/useUserContext";
import reactSelect from "react-select";
import { useEffect } from "react";
import getApplication from "./getApplications";

 
function GiveScholarship() {
	const navigate = useNavigate();
  const {userState} = useUserContext();
  const nonprofit = userState.npOption;
  const [viewMode, setViewMode] = useState(0);
  const [listing, setListing] = useState(false);
  const [app, setApp] = useState(false);
  const [nonprofitId, setNonprofitId] = useState();  
  // viewmode 1 = nonprofit profile + add a nonprofit listing
  // viewmode 2 = successfully added nonprofit + need to apply
  // viewmode 3 = boost profile
  // viewmode 4 = Boost + applied to scholarship
  //viewmode 5 = Nonprofit + applied to scholarship 

  function handleView(){
if (nonprofit === 1) {
  setViewMode(1);
}
if (nonprofit === 2) {
  setViewMode(3);
}
  }

   const fetchNonprofit = async (accessToken) => {
    try {
      
      const response = await getMyNonprofit(accessToken);
      
      if (response.status === "success") {
        console.log(response.data[0]._id, "get nonprofit");
        setListing(true);
        setViewMode(2);
        setNonprofitId(response.data[0]._id);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      //notify("Something went wrong. Please try again later", "error");
    } finally {
      //setFetching(false);
    }
  };

  	const fetchApplication = async (accessToken) => {
    
		try {
			setIsAppsLoading(true);
			const response = await getApplicationById(accessToken);
			if (response.status === "success") {
        console.log(response, "check application");
        setApp(true);
				setViewMode(4);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				console.log(displayMessage, "error");
			}
		} catch (error) {
			console.log("something went wrong");
		} finally {
			//setIsAppsLoading(false);
		}
	};
 useEffect(() => {
    handleView();
    fetchNonprofit(userState.accessToken);
    fetchApplication(userState.accessToken);
  }, []);


	return (
		<>
		
      {viewMode === 1 && (	
        <>	
        	<div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
				<DefaultState
					icon="lock"
					heading="Locked"
					description="List your nonprofit to gain access to Give scholarships"
					action={() =>
						navigate("/philanthropy/nonprofits/add_nonprofit")
					}
					actionText="Add Nonprofit"
				/>
			</div>
      <section>
				<ul className="step-wizard-list">
					<li className="step-wizard-item">
						<span className="progress-count">1</span>
						<span className="progress-label"> Create COhatch Plus account</span>
					</li>
					<li className="step-wizard-item current-item">
						<span className="progress-count">
							<span className="material-symbols-outlined">2</span>
						</span>
						<span className="progress-label">Add a Nonprofit Listing</span>
					</li>
					<li className="step-wizard-item ">
						<span className="progress-count">3</span>
						<span className="progress-label">Apply to Give scholarship</span>
					</li>

					<li className="step-wizard-item">
						<Link to="/dashboard/philanthropy/scholarships">
							<span className="progress-count">4</span>
							<span className="progress-label">
								Receive response in 2-4 business days
							</span>
						</Link>
					</li>
				</ul>
			</section>
      </>
      )}
       {viewMode === 2 && (	
        <>	
        	<div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
				<DefaultState
					icon="open"
					heading="Unlocked"
					description="Apply to our Give Scholarship here!"
					action={() =>
						navigate("/philanthropy/scholarships",
            {
              state: nonprofitId
            })
					}
					actionText="Apply"
				/>
			</div>
      <section>
				<ul className="step-wizard-list">
					<li className="step-wizard-item">
						<span className="progress-count">1</span>
						<span className="progress-label"> Create COhatch Plus account</span>
					</li>
					
          <li className="step-wizard-item">
						<span className="progress-count">
							<span className="material-symbols-outlined">2</span>
						</span>
						<span className="progress-label">Add a Nonprofit Listing</span>
            <p>You have completed this step!</p>
					</li>
					<li className="step-wizard-item current-item">
						<span className="progress-count">3</span>
						<span className="progress-label">Apply to Give Scholarship</span>
					</li>

					<li className="step-wizard-item">
					
							<span className="progress-count">4</span>
							<span className="progress-label">
								Receive response in 2-4 business days
							</span>
					
					</li>
				</ul>
			</section>
      </>
      )}

      {viewMode === 3 && (	
        <>	
        	<div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
				<DefaultState
					icon="lock"
					heading="Locked"
					description="Apply to our Boost Scholarship here!"
					action={() =>
						navigate("/dashboard/philanthropy/scholarships")
					}
					actionText="Apply"
				/>
			</div>
      <section>
				<ul className="step-wizard-list">
					<li className="step-wizard-item">
						<span className="progress-count">1</span>
						<span className="progress-label"> Create COhatch Plus account</span>
					</li>
					
					<li className="step-wizard-item current-item">
						<span className="progress-count">3</span>
						<span className="progress-label">Apply to Boost scholarship</span>
					</li>

					<li className="step-wizard-item ">
						<Link to="/dashboard/philanthropy/scholarships">
							<span className="progress-count">4</span>
							<span className="progress-label">
								Receive response in 2-4 business days
							</span>
						</Link>
					</li>
				</ul>
			</section>
      </>
      )}
	{viewMode === 4 && (	
        <>	
        	<div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
				<DefaultState
					icon="lock"
					heading="Locked"
					description="You've successfully completed all steps."
					action={() =>
						navigate("/dashboard/philanthropy/scholarships")
					}
					actionText="Applied"
				/>
			</div>
      <section>
				<ul className="step-wizard-list">
					<li className="step-wizard-item">
						<span className="progress-count">1</span>
						<span className="progress-label"> Create COhatch Plus account</span>
					</li>
					
					<li className="step-wizard-item ">
						<span className="progress-count">3</span>
						<span className="progress-label">Apply to Boost scholarship</span>
					</li>

					<li className="step-wizard-item current-item">
						<Link to="/dashboard/philanthropy/scholarships">
							<span className="progress-count">4</span>
							<span className="progress-label">
								Receive response in 2-4 business days
							</span>
						</Link>
					</li>
				</ul>
			</section>
      </>
      )}
		</>
	);
}

export default GiveScholarship;
