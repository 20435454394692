/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { debounce, generateClassName } from "../../services/util";
import ButtonPrimary from "../button/ButtonPrimary";
import useUserContext from "../../hooks/useUserContext";
import Modal from "../modal/Modal";
import { validateEmail } from "../../services/validation";
import Member from "../member/Member";
import getPremiumMembers from "./getPremiumMembers";
import notify from "../../services/toast";
import searchPremiumMembers from "./searchPremiumMembers";

function AddMember({ invite = false, selectedMembers, setSelectedMembers, complete, endpoint, searchMembers, DisplayIcon, className, heading = "Add members" }) {
  const { userState } = useUserContext();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(false);

  const [query, setQuery] = React.useState("");
  const [premiumMembers, setPremiumMembers] = React.useState([]);

  const rootRef = React.useRef(null);
  const page = React.useRef(0);
  const remaining = React.useRef(0);

  const resetResult = () => {
    page.current = 0;
    remaining.current = 0;
  };

  const fetchMembers = async () => {
    try {
      const response = await getPremiumMembers(userState.accessToken, page.current, 5, endpoint);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setPremiumMembers(prevState => {
          return [...prevState, ...results];
        });
      } else {
        notify("An error occurred while fetching members. Please try again later", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  const handleSearchMembers = async event => {
    try {
      if (!event.target.value) {
        resetResult();
        setPremiumMembers([]);
        fetchMembers();
        return;
      }
      resetResult();
      setQuery(event.target.value);
      const response = await searchPremiumMembers(userState.accessToken, page.current, 5, searchMembers, event.target.value);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setPremiumMembers(results);
      } else {
        notify("An error occurred while searching members. Please try again later", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  const debouncedHandleSearch = debounce(handleSearchMembers, 500);

  const handleDone = () => {
    setOpen(false);
    complete();
  };

  const observer = React.useRef(
    new IntersectionObserver(
      entries => {
        const [first] = entries;
        if (first.isIntersecting) {
          fetchMembers();
        }
      },
      { threshold: 1, root: rootRef.current }
    )
  );

  const [element, setElement] = React.useState(null);

  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  React.useEffect(() => {
    const result = validateEmail(email);
    setValidEmail(result);
  }, [email]);

  React.useEffect(() => {
    fetchMembers();
  }, []);
  return (
    <>
      {open ? (
        <Modal>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
                <div className="w-full flex justify-between items-center">
                  <h2 className="text-lg font-bold capitalize">{heading}</h2>
                  <div>
                    <ButtonPrimary size="md" text="Done" action={handleDone} />
                  </div>
                </div>
                <div className="mt-1 flex justify-between items-center gap-3">
                  <input type="text" name="text" id="text" onChange={debouncedHandleSearch} className={generateClassName(false)} placeholder="Search members by first or last name" />
                </div>
                <ul className="p-2 divide-y divide-slate-200 max-h-60 overflow-y-auto">
                  {premiumMembers.length
                    ? premiumMembers.map((member, index) => <Member key={index} member={member} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers}/>)
                    : null}
                  {remaining.current > 0 ? (
                    <span ref={setElement} className="w-full flex justify-center text-gray-400">
                      Loading...
                    </span>
                  ) : null}

                  {premiumMembers.length && remaining.current <= 0 ? <span className="w-full flex justify-center text-gray-400">No more members to load</span> : null}
                </ul>
                {invite ? (
                  <div className="w-full">
                    <h2 className="text-lg font-bold">0 Member(s) Invited</h2>
                    <div className="mt-1 flex justify-between items-center gap-3">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        className={generateClassName(!validEmail && email)}
                        placeholder="Enter email address"
                        aria-describedby="invite-optional"
                      />
                      <div>
                        <ButtonPrimary size="md" text="Add" loading={!validEmail} />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <DisplayIcon className={className ? className : "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"} callback={() => setOpen(true)} />
      )}
    </>
  );
}

export default AddMember;
