/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
import config from "../../config";
import { MAGIC_NUMBERS } from "../../constant";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/favorite`;

async function deleteFavorite(id, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}/${id}`, "DELETE", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteFavorite;
