/* eslint-disable */
import { Fragment, useState } from "react";
import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import Volunteer from "../../../assets/volunteer1.jpeg";

import {
	CalendarDaysIcon,
	CreditCardIcon,
	FaceFrownIcon,
	FaceSmileIcon,
	FireIcon,
	HandThumbUpIcon,
	HeartIcon,
	PaperClipIcon,
	UserCircleIcon,
	XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";
import { MdOutlineCancel } from "react-icons/md";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../components/bread_crumb/BreadCrumb";



import ImpactStats from "./ImpactStats";




// const blogs = [
// 	{
// 		id: 1,
// 		title: "New beginnings with COhatch",
// 		href: "#",
// 		description:
// 			"Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.",
// 		imageUrl:
// 			"https://www.cohatch.com/wp-content/uploads/2022/05/03.21.22_Dolr-27_web.jpg",
// 		date: "May 15, 2023",
// 		datetime: "2020-03-16",
// 		category: { title: "Impact", href: "#" },
// 		author: {
// 			name: "Veronica Smith",
// 			role: "Co-Founder / CTO",
// 			href: "#",
// 			imageUrl:
// 				"https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// 		},
// 	},
// 	{
// 		id: 2,
// 		title: "How The Scholarship has helped my Nonprofit",
// 		href: "#",
// 		description:
// 			"Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.",
// 		imageUrl:
// 			"https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80",
// 		date: "May 15, 2023",
// 		datetime: "2020-03-16",
// 		category: { title: "Volunteer", href: "#" },
// 		author: {
// 			name: "Michael Foster",
// 			role: "Co-Founder / CTO",
// 			href: "#",
// 			imageUrl:
// 				"https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// 		},
// 	},
// 	// More posts...
// ];

const activity = [
	{
		id: 1,
		type: "Signed up 3 volunteer hours",
		person: { name: "Chelsea Hagon" },
		date: "Today",
		dateTime: "2023-01-23T10:32",
	},
	{
		id: 2,
		type: "Donated to 'Give Drive'",
		person: { name: "Ben Smith" },
		date: "2d ago",
		dateTime: "2023-01-23T11:03",
	},
	{
		id: 3,
		type: "Created a Volunteer event",
		person: { name: "Chris Hen" },
		date: "3d ago",
		dateTime: "2023-01-23T11:24",
	},
	{
		id: 4,
		type: "Submitted an Impact Story",
		person: {
			name: "Chelsea Hagon",
			imageUrl:
				"https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
		},

		date: "3d ago",
		dateTime: "2023-01-23T15:56",
	},
];
const moods = [
	{
		name: "Excited",
		value: "excited",
		icon: FireIcon,
		iconColor: "text-white",
		bgColor: "bg-red-500",
	},
	{
		name: "Loved",
		value: "loved",
		icon: HeartIcon,
		iconColor: "text-white",
		bgColor: "bg-pink-400",
	},
	{
		name: "Happy",
		value: "happy",
		icon: FaceSmileIcon,
		iconColor: "text-white",
		bgColor: "bg-green-400",
	},
	{
		name: "Sad",
		value: "sad",
		icon: FaceFrownIcon,
		iconColor: "text-white",
		bgColor: "bg-yellow-400",
	},
	{
		name: "Like",
		value: "thumbsy",
		icon: HandThumbUpIcon,
		iconColor: "text-white",
		bgColor: "bg-blue-500",
	},
	{
		name: "I feel nothing",
		value: null,
		icon: XMarkIconMini,
		iconColor: "text-gray-400",
		bgColor: "bg-transparent",
	},
];

// const posts = [
// 	{
// 		id: 1,
// 		title: "Special Olympics at my local highschool!",
// 		href: "#",
// 		description:
// 			"Over the weekend I had the pleasure of Volunteering at my local HighSchool! The Special Olympics was being hosted and I was paired with 9th grader James to help him train over the last few weeks.",
// 		image:
// 			{Volunteer},
// 		date: "May 15, 2023",
// 		datetime: "2020-03-16",
// 		category: { title: "Volunteer", href: "#" },
// 		author: {
// 			name: "Jada Clopton",
// 			role: "Co-Founder / CTO",
// 			href: "#",
// 			imageUrl:
// 				"https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// 		},
// 	},
	
// 	// More posts...
// ];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Example() {
	const [selected, setSelected] = useState(moods[5]);
 
   
	const [postMedia, setPostMedia] = React.useState([]);
  const directions = [
		{ pathName: "Philanthropy", url: "/dashboard/philanthropy" },
		{ pathName: "Impact", url: "" },
	];
	return (
		<>
    	
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="relative  max-w-full h-full mx-auto bg-white shadow rounded-lg">
			<div className="bg-gradient-to-br from-gray-50 to-lime-100">
				<div className="mx-10 mb-6">
					<main className=" ">
						<div className=" mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
							<div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {/*Impact Stats */}
                 <ImpactStats/>
								{/* Wallet  */}
								<div className=" lg:col-start-3 lg:row-start-1">
									<h2 className=" sr-only">Summary</h2>
									<div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5">
										<dl className="flex flex-wrap">
											<div className="flex-auto pl-6 pt-6">
												<dt className="text-lg font-semibold leading-6 text-gray-900">
													Wallet
												</dt>
												<dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
													$55.00
												</dd>
											</div>
											<div className="flex-none self-end px-6 pt-4">
												<dt className="sr-only">Status</dt>
												<dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
													Add
												</dd>
											</div>
											<div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
												<dt className="flex-none">
													<span className="sr-only">Client</span>
													<UserCircleIcon
														className="h-6 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</dt>
												<dd className="text-sm font-medium leading-6 text-gray-900">
													Jada Clopton
												</dd>
											</div>
											<div className="mt-4 flex w-full flex-none gap-x-4 px-6">
												<dt className="flex-none">
													<span className="sr-only">Due date</span>
													<CalendarDaysIcon
														className="h-6 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</dt>
												<dd className="text-sm leading-6 text-gray-500">
													<time dateTime="2023-01-31">January 31, 2023</time>
												</dd>
											</div>
											<div className="mt-4 flex w-full flex-none gap-x-4 px-6">
												<dt className="flex-none">
													<span className="sr-only">Status</span>
													<CreditCardIcon
														className="h-6 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</dt>
												<dd className="text-sm leading-6 text-gray-500">
													MasterCard xxxx
												</dd>
											</div>
										</dl>
										<div className="mt-6 border-t border-gray-900/5 px-6 py-6">
											<a
												href="#"
												className="text-sm font-semibold leading-6 text-gray-900"
											>
												Wallet Feauture Coming Soon{" "}
											</a>
										</div>
									</div>
								</div>
               

							
                
                
								<div className="w-full flex-col space-y-2">
									{postMedia.length ? (
										<p className="flex gap-2 text-md text-primary-400">
											{postMedia.length} Images/Videos
											<MdOutlineCancel
												className="mt-1 text-lg cursor-pointer"
												onClick={() => setPostMedia([])}
											/>
											{/* <MdOutlineCancel className="text-lg cursor-pointer" onClick={onRemove} /> */}
										</p>
									) : null}
								</div>

							
							</div>

							<div>
								{/* Activity feed */}
								<div className="bg-white p-4 rounded shadow-sm ring-1 ring-gray-900/5 lg:col-start-3">
									<h2 className="text-sm font-semibold leading-6 text-gray-900">
										Impact Activity
									</h2>
									<ul role="list" className="mt-6 space-y-6">
										{activity.map((activityItem, activityItemIdx) => (
											<li
												key={activityItem.id}
												className="relative flex gap-x-4"
											>
												<div
													className={classNames(
														activityItemIdx === activity.length - 1
															? "h-6"
															: "-bottom-6",
														"absolute left-0 top-0 flex w-6 justify-center"
													)}
												>
													<div className="w-px bg-gray-200" />
												</div>
												{activityItem.type === "commented" ? (
													<>
														<img
															src={activityItem.person.imageUrl}
															alt=""
															className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
														/>
														<div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
															<div className="flex justify-between gap-x-4">
																<div className="py-0.5 text-xs leading-5 text-gray-500">
																	<span className="font-medium text-gray-900">
																		{activityItem.person.name}
																	</span>{" "}
																	commented
																</div>
																<time
																	dateTime={activityItem.dateTime}
																	className="flex-none py-0.5 text-xs leading-5 text-gray-500"
																>
																	{activityItem.date}
																</time>
															</div>
															<p className="text-sm leading-6 text-gray-500">
																{activityItem.comment}
															</p>
														</div>
													</>
												) : (
													<>
														<div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
															{activityItem.type === "paid" ? (
																<CheckCircleIcon
																	className="h-6 w-6 text-indigo-600"
																	aria-hidden="true"
																/>
															) : (
																<div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
															)}
														</div>
														<p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
															<span className="font-medium text-gray-900">
																{activityItem.person.name}
															</span>{" "}
															{activityItem.type}.
														</p>
														<time
															dateTime={activityItem.dateTime}
															className="flex-none py-0.5 text-xs leading-5 text-gray-500"
														>
															{activityItem.date}
														</time>
													</>
												)}
											</li>
										))}
									</ul>

									{/* New comment form */}
									<div className="mt-6 flex gap-x-3">
										<img
											src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
											alt=""
											className="h-6 w-6 flex-none rounded-full bg-gray-50"
										/>
										<form action="#" className="relative flex-auto">
											<div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
												<label htmlFor="comment" className="sr-only">
													Add your comment
												</label>
												<textarea
													rows={2}
													name="comment"
													id="comment"
													className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
													placeholder="Add your comment..."
													defaultValue={""}
												/>
											</div>

											<div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
												<div className="flex items-center space-x-5">
													<div className="flex items-center">
														<button
															type="button"
															className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
														>
															<PaperClipIcon
																className="h-5 w-5"
																aria-hidden="true"
															/>
															<span className="sr-only">Attach a file</span>
														</button>
													</div>
													<div className="flex items-center">
														<Listbox value={selected} onChange={setSelected}>
															{({ open }) => (
																<>
																	<Listbox.Label className="sr-only">
																		Your mood
																	</Listbox.Label>
																	<div className="relative">
																		<Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
																			<span className="flex items-center justify-center">
																				{selected.value === null ? (
																					<span>
																						<FaceSmileIcon
																							className="h-5 w-5 flex-shrink-0"
																							aria-hidden="true"
																						/>
																						<span className="sr-only">
																							Add your mood
																						</span>
																					</span>
																				) : (
																					<span>
																						<span
																							className={classNames(
																								selected.bgColor,
																								"flex h-8 w-8 items-center justify-center rounded-full"
																							)}
																						>
																							<selected.icon
																								className="h-5 w-5 flex-shrink-0 text-white"
																								aria-hidden="true"
																							/>
																						</span>
																						<span className="sr-only">
																							{selected.name}
																						</span>
																					</span>
																				)}
																			</span>
																		</Listbox.Button>

																		<Transition
																			show={open}
																			as={Fragment}
																			leave="transition ease-in duration-100"
																			leaveFrom="opacity-100"
																			leaveTo="opacity-0"
																		>
																			<Listbox.Options className="absolute z-10 -ml-6 mt-1 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
																				{moods.map((mood) => (
																					<Listbox.Option
																						key={mood.value}
																						className={({ active }) =>
																							classNames(
																								active
																									? "bg-gray-100"
																									: "bg-white",
																								"relative cursor-default select-none px-3 py-2"
																							)
																						}
																						value={mood}
																					>
																						<div className="flex items-center">
																							<div
																								className={classNames(
																									mood.bgColor,
																									"flex h-8 w-8 items-center justify-center rounded-full"
																								)}
																							>
																								<mood.icon
																									className={classNames(
																										mood.iconColor,
																										"h-5 w-5 flex-shrink-0"
																									)}
																									aria-hidden="true"
																								/>
																							</div>
																							<span className="ml-3 block truncate font-medium">
																								{mood.name}
																							</span>
																						</div>
																					</Listbox.Option>
																				))}
																			</Listbox.Options>
																		</Transition>
																	</div>
																</>
															)}
														</Listbox>
													</div>
												</div>
												<button
													type="submit"
													className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												>
													Comment
												</button>
											</div>
										</form>
									</div>
								</div>

								{/* Blog

								<div className="mt-2 p-3 mr-2 rounded-md bg-white">
									<div className="mt-3 mb-1">
										<h1 className="mb-2 text-center text-xl  text-primary-900 font-semibold ">
											From the Blog
										</h1>
									</div>
									{blogs.map((blog) => (
										<article
											key={blog.id}
											className="relative isolate flex flex-col gap-8 lg:flex-row"
										>
											<div className="mt-5  shadow-lg ring-1 ring-gray-900/5 bg-white rounded-lg p-4">
												<div className="flex items-center gap-x-4 text-xs">
													<time
														dateTime={blog.datetime}
														className="text-gray-500"
													>
														{posts.date}
													</time>

													<a
														href={blog.category.href}
														className="relative rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20"
													>
														{blog.category.title}
													</a>
												</div>
												<div className="pb-3 flex border-b border-gray-900/5 pt-6">
													<div className="relative flex items-center gap-x-4">
														<img
															src={blog.author.imageUrl}
															alt=""
															className="h-10 w-10 rounded-full bg-gray-50"
														/>
														<div className="text-sm leading-6">
															<p className="font-semibold text-gray-900">
																<a href={blog.author.href}>
																	<span className="absolute inset-0" />
																	{blog.author.name}
																</a>
															</p>
														</div>
													</div>
												</div>
												<div className="group relative max-w-xl">
													<h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
														<a href={blog.href}>
															<span className="absolute inset-0" />
															{blog.title}
														</a>
													</h3>
													<p className="mt-5 text-sm leading-6 text-gray-600">
														{blog.description}
													</p>
												</div>
												<div className="mx-auto mt-3 aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
													<img
														src={blog.imageUrl}
														alt=""
														className="  h-full w-full rounded-2xl bg-gray-50 object-cover"
													/>
													<div className=" rounded-2xl ring-1 ring-inset ring-gray-900/10" />
												</div>
											</div>
										</article>
									))}
								</div> */}
							</div>
						</div>
					</main>
				</div>
			</div>
      </div>
      </div>
		</>
	);
}
