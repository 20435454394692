/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";

function LocalRecommendation({
    property,
    setProperty,
}) {
    const submitData = (key, value) => {
        setProperty((prevState) => {
          return {
            ...prevState,
            [key]: value,
          };
        });
    };
  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Local Recommendations</h3>
          <p className="mt-1 text-sm text-gray-500">Please enter a few of your favorite restaurants, shops, and places to visit!</p>
        </div>
              

        <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <div className="w-full flex justify-between items-center">
                    <label htmlFor="restaurant" className="block text-sm font-medium text-gray-700">
                        Restaurants
                    </label>
                </div>
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <div className="w-full flex justify-between items-center">
                    <label htmlFor="shopping" className="block text-sm font-medium text-gray-700">
                        Shopping
                    </label>
                </div>
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <div className="w-full flex justify-between items-center">
                <label htmlFor="sightseeing" className="block text-sm font-medium text-gray-700">
                        Sightseeing
                    </label>
                </div>
            </div>
        </div>

        {[0, 1, 2, 3, 4].map(item => (<div className="grid grid-cols-6 gap-6" key={`recommendation-${item}`}>
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <input
                    type="text"
                    name="restaurant"
                    id="restaurant"
                    autoComplete="address-level1"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={property?.restaurant[item] || ""}
                    onChange={(event) => submitData("restaurant", property?.restaurant.map((subItem, index) => item === index ? event.target.value : subItem))}
                />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <input
                    type="text"
                    name="shopping"
                    id="shopping"
                    autoComplete="shopping"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={property?.shopping[item] || ""}
                    onChange={(event) => submitData("shopping", property?.shopping.map((subItem, index) => item === index ? event.target.value : subItem))}
                />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <input
                    type="text"
                    name="sightseeing"
                    id="sightseeing"
                    autoComplete="address-level2"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={property?.sightSeeing[item] || ""}
                    onChange={(event) => submitData("sightSeeing", property?.sightSeeing.map((subItem, index) => item === index ? event.target.value : subItem))}
                />
            </div>
        </div>))}

      </div>
    </div>);
}

export default LocalRecommendation;