/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Spinner from "./../../../components/spinner/Spinner";
import { v4 as uuid } from "uuid";
import getActivity from "./getActivity";
import useUserContext from "../../../hooks/useUserContext";
import Pagination from "../../../components/pagination/Pagination";
import { format } from "date-fns";
import { DEFAULT_PAGINATION_LIMIT, MAGIC_NUMBERS } from "./../../../constant";
import notify from "./../../../services/toast";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function Activity() {
  useScrollPage();
  useTitle("COhatch+ Activity");
  
  const { userState } = useUserContext();
  const [activities, setActivities] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: DEFAULT_PAGINATION_LIMIT });

  const fetchActivity = async (accessToken, offset, limit) => {
    try {
      setFetching(true);

      const response = await getActivity(accessToken, offset, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setActivities(results);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const handlePrevFetch = async () => {
    await fetchActivity(userState.accessToken, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = async () => {
    await fetchActivity(userState.accessToken, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    fetchActivity(userState.accessToken, 0, DEFAULT_PAGINATION_LIMIT);
  }, []);
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
        <div className="w-full">
          <h1 className="text-lg text-success-800 font-bold">Your activity</h1>
        </div>
        <ul role="list" className="divide-y divide-gray-200">
          <>
            {fetching ? (
              <Spinner displayText="Loading..." />
            ) : (
              activities.map((activity) => {
                return (
                  <li key={uuid()} className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600">
                    <div className="flex justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <a href="#" className="block focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true"></span>
                          <p className="text-md font-bold text-gray-900 truncate">{activity.title}</p>
                          <p className="text-sm text-gray-400 trunc">Account: {userState.emailAddress}</p>
                        </a>
                      </div>
                      <time dateTime="2021-01-27T16:35" className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                        {format(new Date(activity.createdAt), "iii, MMMM do, hh:mm a")}
                      </time>
                    </div>
                    <div className="mt-1">
                      <p className="line-clamp-2 text-sm text-gray-600">{activity.description}</p>
                    </div>
                  </li>
                );
              })
            )}
          </>
        </ul>
        <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      </div>
    </div>
  );
}

export default Activity;
