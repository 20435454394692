/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Pagination from "../../../components/pagination/Pagination";

import { useNavigate } from "react-router-dom";
import { MAGIC_NUMBERS } from "../../../constant";
import { debounce } from "../../../services/util";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import StoryCard from "./StoryCard";
import getImpactStories from "./getImpactStories.js";

function ImpactStoryTimeline() {
	const [stories, setStories] = useState([]);
	const { userState } = useUserContext();
	const navigate = useNavigate();

	function renderStories(story, index) {
		return <StoryCard key={index} story={story} />;
	}

	function onClick() {
		navigate("add");
	}

	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 11,
	});

	const fetchImpactStories = async (accessToken, offset, limit) => {
		try {
			const response = await getImpactStories(
				{
					offset,
					limit,
				},
				accessToken
			);

			if (response.status === "success") {
				const { limit, page, remaining, total, results } = response.data;
console.log(results);
				setPagination({ limit, page: page, remaining, total });
				setStories(results);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("something went wrong");
		} finally {
			console.log("error");
		}
	};

	const handlePrevFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page - 1,
			keyword
		);
	};

	const handleNextFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page + 1,
			keyword
		);
	};

	useEffect(() => {
		fetchImpactStories(userState.accessToken, 0, pagination.limit);
	}, []);

	return (
		<div className="bg-gradient-to-b from-white to-emerald-500/90 py-18 sm:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl text-center">
					<h2 className="text-gray-950 opacity-90 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						Impact Stories
					</h2>
					<p className="mt-2 text-lg leading-8 text-gray-600">
						The Latest Impact Stories from our Nonprofits, Startups, and Members.
					</p>
				</div>
				<div className=" mt-6">
					<div className="flex justify-center ">
						{/* <input
							type="text"
							name="search"
							placeholder="Search"
							className="block w-1/2 border border-gray-300 rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 "
						/> */}
						<div className="ml-4">
							<button
								className="bg-orange-700  text-white py-2 px-3 rounded-md"
								onClick={onClick}
								type="button"
							>
								Add Story
							</button>
						</div>
					</div>
				</div>

				<div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-4">
					{stories.map((stories, index) => {
						return renderStories(stories, index);
					})}
				</div>
			</div>
			<div className="mt-20">
				<Pagination
					pagination={pagination}
					next={handleNextFetch}
					prev={handlePrevFetch}
				/>
			</div>
		</div>
	);
}

export default ImpactStoryTimeline;
