import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function searchPremiumMembers(accessToken, page, limit, endpoint, query) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/user${endpoint}&page=${page}&limit=${limit}&query=${query}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default searchPremiumMembers;
