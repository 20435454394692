/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import AmenityCard from "../../../../components/amenity_card/AmenityCard";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import DefaultState from "../../../../components/default_state/DefaultState";
import DisplayCouponCard from "../../../../components/display_coupon_card/DisplayCouponCard";
import PaymentOptionCard from "../../../../components/payment_option_card/PaymentOptionCard";
import { BUSINESS_CATEGORY, MAGIC_NUMBERS, PLANS } from "../../../../constant";
import notify from "../../../../services/toast";
import { useNavigate } from "react-router-dom";
import { getValueFromArrayOfObject, phoneNumberFormatter } from "../../../../services/util";
import useUserContext from "./../../../../hooks/useUserContext";
import submitNonprofit from "./submitNonprofit";
import useScrollPage from "../../../../hooks/useScrollPage";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function Review({ steps, currentStep, setCurrentStep, listing }) {
  useScrollPage();

  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const { businessInfo, ownerInfo, coupon, address, photos, businessHours, price, paymentOptions, badges } = listing;
  const {
    subscription: { product },
    userState: { accessToken },
  } = useUserContext();
  const handleSubmit = async () => {
    const payload = {
      businessInfo,
      ownerInfo,
      coupon,
      address,
      photos,
      businessHours,
      price,
      paymentOptions,
      badges,
      rank: ["COHATCH", "PREMIUM"].includes(PLANS[product]) ? 3 : ["LOCAL DISCOUNT"].includes(PLANS[product]) ? 2 : 1,
    };

    try {
      setSubmitting(true);
      const response = await submitNonprofit(accessToken, payload);
      if (response.status === "success") {
        notify("Your nonprofit was successfully submitted.", "info");
        window.sessionStorage.clear();
        navigate("/philanthropy/nonprofits");
      } else {
        setSubmitting(false);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      setSubmitting(false);
      notify("Something went wrong. Please try again later.", "error");
    }
  };
  return (
    <div className="w-full bg-white overflow-hidden shadow rounded-lg">
      <div className="w-full px-4 py-5 sm:p-6 space-y-7">
        <h1 className="text-xl font-bold text-success-800">Review Nonprofit Listing</h1>
        <p className="text-sm text-gray-400">Please make sure you have all the required information. You can always return to edit your listing.</p>
        <div className="w-full">
          <h1 className="text-xl font-bold text-success-800">Business Information</h1>
          <div className="w-full flex flex-col space-y-4 divide-y-2 px-2">
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Name</h3>
              {businessInfo?.businessName ? <p className="text-md text-gray-500 px-3">{businessInfo.businessName}</p> : <p className="text-md text-gray-500 px-3">No business name</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Email</h3>
              {businessInfo?.businessEmail ? <p className="text-md text-gray-500 px-3">{businessInfo.businessEmail}</p> : <p className="text-md text-gray-500 px-3">No business email</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Phone</h3>
              {businessInfo?.businessPhone ? (
                <p className="text-md text-gray-500 px-3">{phoneNumberFormatter(businessInfo.businessPhone)}</p>
              ) : (
                <p className="text-md text-gray-500 px-3">No business phone</p>
              )}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Description</h3>
              {businessInfo?.businessDescription ? (
                <p className="text-md text-gray-500 px-3">{businessInfo.businessDescription}</p>
              ) : (
                <p className="text-md text-gray-500 px-3">No business description</p>
              )}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Website</h3>
              {businessInfo?.website ? <p className="text-md text-gray-500 px-3">{businessInfo.website}</p> : <p className="text-md text-gray-500 px-3">No business website</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Facebook</h3>
              {businessInfo?.facebook ? <p className="text-md text-gray-500 px-3">{businessInfo.facebook}</p> : <p className="text-md text-gray-500 px-3">No business facebook</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Instagram</h3>
              {businessInfo?.instagram ? <p className="text-md text-gray-500 px-3">{businessInfo.instagram}</p> : <p className="text-md text-gray-500 px-3">No business instagram</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business LinkedIn</h3>
              {businessInfo?.linkedIn ? <p className="text-md text-gray-500 px-3">{businessInfo.linkedIn}</p> : <p className="text-md text-gray-500 px-3">No business linkedIn</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business Category</h3>
              {businessInfo?.businessCategory ? (
                <p className="text-md text-gray-500 px-3">{BUSINESS_CATEGORY[businessInfo.businessCategory]["mainCategory"]["label"]}</p>
              ) : (
                <p className="text-md text-gray-500 px-3">No business category</p>
              )}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Business SubCategory</h3>
              {businessInfo?.businessSubCategory ? (
                <p className="text-md text-gray-500 px-3">{getValueFromArrayOfObject(BUSINESS_CATEGORY[businessInfo.businessCategory]["subCategory"], { value: businessInfo.businessSubCategory })}</p>
              ) : (
                <p className="text-md text-gray-500 px-3">No business subcategory</p>
              )}
            </div>
          
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-xl font-bold text-success-800">Point of Contact Information</h1>
          <div className="w-full flex flex-col space-y-4 divide-y-2 px-2">
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Point of Contact First Name</h3>
              {ownerInfo?.ownerFirstName ? <p className="text-md text-gray-500 px-3">{ownerInfo.ownerFirstName}</p> : <p className="text-md text-gray-500 px-3">No business owner first name</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Point of Contact Last Name</h3>
              {ownerInfo?.ownerLastName ? <p className="text-md text-gray-500 px-3">{ownerInfo.ownerLastName}</p> : <p className="text-md text-gray-500 px-3">No business owner last name</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Point of Contact Email</h3>
              {ownerInfo?.ownerEmail ? <p className="text-md text-gray-500 px-3">{ownerInfo.ownerEmail}</p> : <p className="text-md text-gray-500 px-3">No business owner email</p>}
            </div>
            <div className="w-full flex flex-col">
              <h3 className="text-md text-success-800">Point of Contact Job Title</h3>
              {ownerInfo?.ownerJobTitle ? <p className="text-md text-gray-500 px-3">{ownerInfo.ownerJobTitle}</p> : <p className="text-md text-gray-500 px-3">No business owner job title</p>}
            </div>
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-xl font-bold text-success-800">Coupons</h1>
          <div className="w-full flex flex-col space-y-4 divide-y-2 px-2">
            <div className="w-full px-2">
              <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                {!coupon ? (
                  <DefaultState icon="discount" heading="No Coupons Found" description="" />
                ) : (
                  coupon.map((value, index) => <DisplayCouponCard key={index} coupon={{ value, index }} remove={false} />)
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-xl font-bold text-success-800">Nonprofit Address</h1>
          <div className="w-full flex flex-col space-y-4 divide-y-2 px-2">
            <div className="w-full flex flex-col">
              {address?.address ? <p className="text-md text-gray-500 px-3">{address?.address}</p> : <p className="text-md text-gray-500 px-3">No business address</p>}
            </div>
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-xl font-bold text-success-800">Photos</h1>
          <div className="w-full flex flex-col space-y-4 divide-y-2 px-2">
            <div className="w-full flex items-center justify-start gap-4 flex-wrap">
              <div className="flex flex-col sm:flex-row gap-2">
                <div className="relative h-[200px] w-[200px]">
                  <img
                    src={photos?.logo?.small ? photos.logo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_upload_image.png"}
                    className="w-full h-full"
                    alt="logo upload"
                  />
                </div>
                <div className="relative h-[200px] w-[200px]">
                  <img
                    src={photos?.coverPhoto?.small ? photos.coverPhoto.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_upload_image.png"}
                    className="w-full h-full"
                    alt="logo upload"
                  />
                </div>
                <div className="relative h-[200px] w-[200px]">
                  <img
                    src={
                      photos?.gallery?.length ? photos.gallery[photos.gallery.length - 1].small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_upload_image.png"
                    }
                    className="w-full h-full blur-sm"
                    alt="logo upload"
                  />
                  {photos?.gallery?.length ? (
                    <p className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-lg text-white font-bold text-center">+ {photos.gallery.length - 1} more</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-xl font-bold text-success-800">Business Hours</h1>
          <div className="w-full flex flex-col space-y-4 divide-y-2 px-2">
            <div className="w-full flex flex-col">
              {businessHours ? (
                daysOfWeek.map((dayOfWeek, index) => {
                  return businessHours?.[dayOfWeek] ? (
                    <div key={index} className="w-full flex gap-4">
                      {dayOfWeek}: {businessHours[dayOfWeek]["round"] ? businessHours[dayOfWeek]["round"] : `${businessHours[dayOfWeek]["open"]} AM - ${businessHours[dayOfWeek]["close"]} PM`}
                    </div>
                  ) : null;
                })
              ) : (
                <p className="text-md text-gray-500 px-3">No business hours</p>
              )}
            </div>
          </div>
        </div>
       
        <div className="w-full">
          <h1 className="text-xl font-bold text-success-800">Payment Options</h1>
          <div className="w-full flex flex-col space-y-4 divide-y-2 px-2">
            <div className="w-full px-2">
              <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                {!paymentOptions ? (
                  <DefaultState icon="credit_card" heading="No Payment Option Found" description="" />
                ) : (
                  paymentOptions.map((value, index) => <PaymentOptionCard key={index} paymentOption={{ value, index }} remove={false} />)
                )}
              </div>
            </div>
          </div>
        </div>
        
       

        <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
          <div>
            <ButtonPrimary icon="arrow_back" text={steps[currentStep - 1]} size="md" action={() => setCurrentStep(currentStep - 1)} loading={submitting ? true : false} />
          </div>
          <div>
            <ButtonPrimary icon="save" text={submitting ? "Please wait..." : "Submit Listing"} size="md" action={handleSubmit} loading={submitting ? true : false} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
