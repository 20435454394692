/* eslint-disable no-console */
import React from "react";
import { ConversationContext } from "../context/ConversationContext";

function useConversationContext() {
  const context = React.useContext(ConversationContext);
  return context;
}

export default useConversationContext;
