import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function handleChangePassword(data, authToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/password/change_password`;
  const response = await makeAPIRequest(API_ENDPOINT, "PUT", data, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default handleChangePassword;
