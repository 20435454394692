/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import CouponCard from "../../../../components/coupon_card/CouponCard";
import useUserContext from "../../../../hooks/useUserContext";
import { PLANS } from "../../../../constant";

function ListingCoupons({ listing, listingCoupons }) {
  const {
    userState,
    subscription: { product },
  } = useUserContext();

  return (
    <>
      {(listingCoupons?.length && ["PREMIUM", "COHATCH", "LOCAL DISCOUNT", "BASIC"].includes(PLANS[product])) || (userState?.accessToken && userState.id === listing?.userId) ? (
        <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
          <h1 className="text-xl text-success-800 font-bold m-2">Coupons</h1>
          <hr />
          <div id="couponCarousel" className="carousel slide carousel-dark relative" data-bs-ride="carousel">
            <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
              {listingCoupons.map((discount, index) => (
                <button
                  key={`button-${discount._id}`}
                  type="button"
                  data-bs-target="#couponCarousel"
                  data-bs-slide-to={`${index}`}
                  className={index === 0 ? "active" : ""}
                  aria-current={index === 0 ? "true" : "false"}
                  aria-label={`Slide ${index + 1}`}></button>
              ))}
            </div>
            <div className="carousel-inner relative w-full overflow-hidden px-3 sm:px-10 pt-10 pb-12">
              {listingCoupons.map((discount, index) => {
                return (
                  <div className={`carousel-item ${index === 0 ? "active" : ""} relative float-left w-full`} key={discount._id}>
                    <CouponCard
                      coupon={{
                        ...discount,
                        listing,
                      }}
                      fid={discount.favorites[0] ? discount.favorites[0]._id : ""}
                      isFavorite={!!discount.favorites.length}
                      isOwner={userState && userState.id === discount.userId}
                    />
                  </div>
                );
              })}
            </div>
            <button
              className="sm:block carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
              type="button"
              data-bs-target="#couponCarousel"
              data-bs-slide="prev">
              <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="sm:block carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
              type="button"
              data-bs-target="#couponCarousel"
              data-bs-slide="next">
              <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

ListingCoupons.propTypes = {
  listingCoupons: PropTypes.array,
  listing: PropTypes.any,
};

export default ListingCoupons;
