/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
import config from "../../config";
import { MAGIC_NUMBERS } from "../../constant";
import makeAPIRequest from "../../services/makeRequest";
import notify from "../../services/toast";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/subscription/manage_subscription`;

async function createSubscription(payload, setSubScribing, accessToken) {
  setSubScribing(true);

  try {
    const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, accessToken);
    const jsonResponse = await response.json();
    if (response.ok) {
      const { data } = jsonResponse;
      window.location.replace(data.stripe);
    } else {
      let displayMessage = "";
      if (Array.isArray(jsonResponse.message)) {
        displayMessage = jsonResponse.message[0].message;
      } else {
        displayMessage = jsonResponse.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(jsonResponse.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
  } catch (error) {
    notify("Something went wrong. Please try again later.", "error");
  } finally {
    setSubScribing(false);
  }
}

export default createSubscription;
