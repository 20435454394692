/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import getListings from "../../marketplace_feed/getListings";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ListingCard from "../../../../components/listing_card/ListingCard";
import Spinner from "../../../../components/spinner/Spinner";
import DefaultState from "../../../../components/default_state/DefaultState";
import Pagination from "../../../../components/pagination/Pagination";
import useUserContext from "../../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../../constant";
import notify from "../../../../services/toast";
import { debounce } from "../../../../services/util";
import useScrollPage from "../../../../hooks/useScrollPage";
import useTitle from "../../../../hooks/useTitle";
import BreadCrumb from "../../../../components/bread_crumb/BreadCrumb";
import MarketplaceCard from "../../../../components/marketplace_card/MarketplaceCard";
import getLocalFoodListings from "./getAllLocalFood";

const serviceCategories = [
  {
    name: "Farmers + Food Producers",
    link: "farmers_market",
    imageUrl:
      "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/Marketplace-Categories/farmers%20+%20food.png",
  },
  {
      name: "Restaurants",
      link: "restaurants",
      imageUrl:
        "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/Marketplace-Categories/restaurants.png",
    },
  // More serviceCategories...
];

const directions = [
    { pathName: "Dashboard", url: "/dashboard" },
    { pathName: "Marketplace", url: "/dashboard/marketplace" },
    { pathName: "Local Food", url: "" },
  ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function LocalFood() {
  useScrollPage();
  useTitle("All listings");

  const navigate = useNavigate();
  const { userState } = useUserContext();
  const [listings, setlistings] = useState([]);
  const [isListingLoading, setIsListingLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 12 });
  const [keyword, setKeyword] = useState("");

  const fetchAllListings = async (accessToken, offset, limit) => {
    setIsListingLoading(true);
    console.log(accessToken, offset, limit);
    const response = await getLocalFoodListings(accessToken, offset, limit);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      console.log(results);
      setPagination((prevState) => {
        return { ...prevState, limit, page: page, remaining, total };
      });
      setlistings(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsListingLoading(false);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    fetchAllListings(userState.accessToken, 0, query);
    setKeyword(query);
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchAllListings((userState.accessToken, pagination.page - 1, pagination.limit));
    // await fetchAllListings(userState.accessToken, pagination.page - 1, keyword);
    
  };

  const handleNextFetch = async () => {
    await fetchAllListings(userState.accessToken, pagination.page + 1, pagination.limit);
    // await fetchAllListings(userState.accessToken, pagination.page + 1, keyword);
  };

  const reloadlistings = () => {
    setPagination({ limit: 12, total: 0, page: 0, remaining: 0 });
    fetchAllListings(userState.accessToken, 0, pagination.limit);
    // fetchAllListings(userState.accessToken, 0, keyword);
  };

  useEffect(() => {
    fetchAllListings(userState.accessToken, 0, pagination.limit);
    // fetchAllListings(userState.accessToken, 0, "");
  }, []);

  return (
  <>
      <div className="max-w-6xl mx-auto sm:px-6 lg:px-2">
        <div className="relative mt-2 max-w-full h-full mx-auto ">
          <div className="py-2 text-center">
            <h2 className="text-3xl font-bold tracking-wide font-bebas text-primary-400 sm:text-4xl">Local Food Categories</h2>
            <BreadCrumb directions={directions} />
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Your one stop shop to find and support local businesses, food producers, restaurants, makers, and more.
            </p>

            <div className="hidden sm:block">
              <ul
                role="list"
                className="text-center mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2"
              >
                {serviceCategories.map((category) => (
                  <Link to={`listings/${category.link}`} key={category.link} className=" p-2 hover:bg-gray-200">
                  <li key={category.name}>
                    
                      <img className="mx-auto h-40 w-40 rounded-full" src={category.imageUrl} alt="" />
                      <h3 className="mt-2 text-lg font-semibold leading-8 tracking-tight text-black">{category.name}</h3>
                    
                  </li>
                  </Link>
                ))}
              </ul>
            </div>
        
        
            <div className="sm:hidden">
              <ul role="list" className="mt-3">
                {serviceCategories.map((category) => (
                  <li key={category.name} className="col-span-1 mt-4 flex rounded-md shadow-sm">
                    <div
                      className={classNames(
                      category.bgColor,
                      "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                      )}
                    >
                      <img  src={category.imageUrl} alt="" />
                    </div>
                    <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                      <div className="flex-1 truncate px-4 py-2 text-sm">
                        <a href={category.href} className="font-medium text-gray-900 hover:text-gray-600">
                          {category.name}
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          
          </div>
        </div>
      </div>

      {/* Displaying all the LocalFood */}
      <div className="bg-white">
        <div className="relative px-4 pt-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            {/* <span className="ml-3 text-4xl font-bold font-bebas text-primary-400 sm:leading-9 sm:truncate">Book Free Life Events</span> */}
            <span className="bg-white pr-3 text-4xl text-primary-400 font-bebas font-bold">
                All Local Food Listings
            </span>
          </div>
        </div>
        {!isListingLoading && !listings.length && (
          <DefaultState
            icon="corporate_fare"
            heading="No listings Found"
            description="All local food listings created by members will appear here"
            action={() => navigate("/dashboard/business_hub")}
            actionText="Create your own listing"
          />
        )}
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <>{isListingLoading ? <Spinner displayText="Loading..." /> : listings.map((listing) => <MarketplaceCard key={listing._id} listing={listing} />)}</>
            </div>
        <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      </div>
  </>
  );
}

export default LocalFood;
