/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { formatDistanceToNow } from "date-fns/esm";
import React from "react";
import notify from "../../services/toast";
import useUserContext from "../../hooks/useUserContext";
import createLike from "./createLike";
import getLikes from "./getLikes";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import CommentReply from "../comment_reply/CommentReply";
import { validateMessage } from "../../services/validation";
import getReplies from "./getReplies";
import createReply from "./createReply";
import deleteReply from "./deleteReply";
import { Anchorme } from "react-anchorme";


// TODO
// FIX import CommentReply 
// Socket for replies


const CustomLink = (props) => {
  const { href } = props;
  return (
    <i className="text-primary-400 text-md underline">
      <a href={href}>{href}</a>
    </i>
  );
};

function LifePerksComment({ comment, deleteComment, postId, socket, groupId, groupName, creatorId }) {
  const { userState } = useUserContext();
  const userFirstName = comment?.user?.firstName ? comment.user.firstName : null;
  const userLastName = comment?.user?.lastName ? comment.user.lastName : null;
  const userJobTitle = comment?.user?.jobTitle ? comment.user.jobTitle : null;
  const photo = comment?.user?.photo?.small ? comment.user.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png";
  const userComment = comment?.comment ? comment.comment : null;
  const [likes, setLikes] = React.useState(0);

  const [reply, setReply] = React.useState("");
  const [validReply, setValidReply] = React.useState(false);
  const [creatingReply, setCreatingReply] = React.useState(false);
  const [replies, setReplies] = React.useState([]);
  const [fetchingReplies, setFetchingReplies] = React.useState(false);
  const [totalReplies, setTotalReplies] = React.useState(0);
  const [showReply, setShowReply] = React.useState(false);

  const page = React.useRef(0);
  const remaining = React.useRef(0);

  React.useEffect(() => {
    const result = validateMessage(reply);
    setValidReply(result);
  }, [reply]);

  const handleGetLikes = async () => {
    try {
      const response = await getLikes(userState.accessToken, comment._id);
      if (response.status === "success") {
        setLikes(response?.data?.like ? response.data.like : 0);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const fetchAllCommentsReplies = async () => {
    try {
      setFetchingReplies(true);
      const response = await getReplies(userState.accessToken, comment._id, page.current, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results, total } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setTotalReplies(total);
        setReplies((prevState) => {
          return [...prevState, ...results];
        });
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingReplies(false);
    }
  };

  const reloadReplies = async () => {
    try {
      setFetchingReplies(true);
      const response = await getReplies(userState.accessToken, comment._id, 0, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results, total } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setReplies(results);
        setTotalReplies(total);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingReplies(false);
    }
  };

  const handleCreateReply = async () => {
    try {
      setCreatingReply(true);
      const payload = {
        postId: comment.postId,
        commentId: comment._id,
        comment: reply,
      };
      const response = await createReply(userState.accessToken, payload);
      if (response.status === "success") {
        notify("Reply successfully created", "info");
        setReply("");
        reloadReplies();
        // socket.current.emit("group_post_comment_reply", {
        //   groupId: groupId,
        //   postId: postId,
        //   groupName: groupName,
        //   userFirstName: userState.firstName,
        //   userLastName: userState.lastName,
        //   userId: userState.id,
        //   creatorId: creatorId,
        // });
      } else {
        notify("An error occurred while creating comment reply", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setCreatingReply(false);
    }
  };

  const handleCreateLike = async () => {
    try {
      const payload = {
        commentId: comment._id,
        postId: comment.postId,
      };
      const response = await createLike(userState.accessToken, payload);
      if (response.status === "success") {
        notify("Successfully liked this comment", "info");
        const newLike = parseInt(likes, 10) + parseInt(response.data, 10);
        setLikes(newLike);
      } else {
        notify("Error creating a like for this comment. If error persists, please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleDeleteReply = async (replyId) => {
    try {
      const response = await deleteReply(userState.accessToken, replyId);
      if (response.status === "success") {
        const newReplies = replies.filter((reply) => reply._id !== replyId);
        setReplies(newReplies);
        setTotalReplies(newReplies.length);
        notify("Successfully deleted reply", "info");
      } else {
        notify("Error deleting comment. If error persists, please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  React.useEffect(() => {
    handleGetLikes();
    fetchAllCommentsReplies();
  }, []);
  return (
    <div className="w-full">
      <div className="w-full flex gap-2">
        <img className="w-10 h-10 flex-shrink-0 mx-auto rounded-full" src={photo} alt={userLastName} />
        <div className="w-full">
          <div className="w-full bg-gray-300 p-2 rounded-md">
            <div className="w-full flex justify-between">
              <div className="flex flex-col">
                <Link to={`/profile/${comment.userId}`} className="text-md text-success-800 font-bold underline capitalize">
                  {userFirstName} {userLastName}
                </Link>
                {userJobTitle ? <p className="text-xs text-success-800">{userJobTitle}</p> : null}
              </div>
              <p className="text-sm text-success-800">{formatDistanceToNow(new Date(comment.createdAt), { addSuffix: false })} ago</p>
            </div>
            <p className="mt-1 text-md text-success-800">
              <Anchorme target="_blank" linkComponent={CustomLink}>
                {userComment}
              </Anchorme>
            </p>
            {comment.userId === userState.id ? (
              <div className="w-full flex justify-end">
                <MdDeleteOutline className="text-red-400 cursor-pointer" onClick={() => deleteComment(comment._id)} />
              </div>
            ) : null}
          </div>
          <div className="mt-1 w-full flex gap-5 items-center text-primary-400 cursor-pointer">
            <div className="w-fit flex gap-2">
              <span onClick={handleCreateLike} className="material-icons-outlined text-sm">
                thumb_up
              </span>
              {likes > 0 ? <p className="text-sm">{likes}</p> : null}
            </div>
            <span>&#124;</span>
            <div className="w-full flex gap-2 items-center">
              <span onClick={() => setShowReply(!showReply)} className="text-sm underline text-primary">
                Reply
              </span>
              {totalReplies ? (
                <>
                  <span>&#124;</span>
                  <span className="text-sm text-primary">
                    {totalReplies} {totalReplies > 1 ? "Replies" : "Reply"}
                  </span>
                </>
              ) : null}
            </div>
          </div>
          {showReply ? (
            <div>
              <div className="w-full flex gap-2 items-center">
                <img
                  className="w-10 h-10 flex-shrink-0 mx-auto rounded-full"
                  src={userState?.photo?.small ? userState.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                  alt={userState.lastName}
                />
                <div className="w-full">
                  <div>
                    <div className="mt-1">
                      <textarea
                        onChange={(event) => setReply(event.target.value)}
                        rows="2"
                        name="reply"
                        id="reply"
                        value={reply}
                        placeholder="Add a reply"
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              {reply && validReply ? (
                <div className="mt-5 w-full flex justify-end">
                  <button
                    onClick={handleCreateReply}
                    type="button"
                    disabled={creatingReply && !validReply ? true : false}
                    className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {creatingReply ? "Please wait..." : "Reply"}
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="mt-5 flow-root px-4">
            <ul role="list" className="-mb-8">
              {replies.length ? replies.map((reply, index) => <CommentReply key={index} index={index} total={replies.length} reply={reply} deleteReply={handleDeleteReply} />) : null}
            </ul>
          </div>
          <div className="relative">
            {remaining.current > 0 && (
              <span onClick={fetchAllCommentsReplies} className="w-full flex justify-center text-gray-400 underline cursor-pointer">
                {fetchingReplies ? "loading..." : "load more replies"}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LifePerksComment;
