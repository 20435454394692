/* eslint-disable */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumb from "../../../components/bread_crumb/BreadCrumb";
import { 
generateClassName,
inArray 
} from "../../../services/util";
import {
	GoogleMap,
	Autocomplete,
	useLoadScript,
	Marker,
} from "@react-google-maps/api";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import Spinner from "../../../components/spinner/Spinner";
import submitApplication from "../give_scholarships/submitApplication";
import useUserContext from "../../../hooks/useUserContext";
import { MdLocalSee } from "react-icons/md";
import {
	validateName,
	validateMessage,
	validateEmail,
	validatePhone,
} from "../../../services/validation";
import notify from "../../../services/toast";
import {
	MAGIC_NUMBERS,
	DEFAULT_MAP_CENTER,
	DEFAULT_MAP_OPTIONS,
	DEFAULT_MAP_ZOOM,
	LOCATIONS,
  COHATCH_MARKETS,
  COHATCH_LOCATIONS,
} from "../../../constant";
import { sortBy } from "lodash";
import SelectInput from "../../../components/select_input/SelectInput";
import FileUploader from "../../../components/file_uploader/FileUploader";
import { MdUpload } from "react-icons/md";
import CauseCard from "../../../components/cause_card/CauseCard";
import { v4 as uuid } from "uuid";
import DefaultState from "../../../components/default_state/DefaultState";



const libraries = ["places"];

function Scholarships() {
	const directions = [
		{ pathName: "Nonprofits", url: "/dashboard/philanthropy" },
		{ pathName: "Scholarship", url: "" },
	];

	const { userState } = useUserContext();
   

  // Payload/application information
  const nonprofit = userState.npOption;
	const [submitting, setSubmitting] = React.useState(false);
	const [firstName, setFirstName] = React.useState("");
	const [validFirstName, setValidFirstName] = React.useState(false);
	const [lastName, setLastName] = React.useState("");
	const [validLastName, setValidLastName] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [validEmail, setValidEmail] = React.useState(false);
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [validPhoneNumber, setValidPhoneNumber] = React.useState(false);
	const [orgName, setOrgName] = React.useState("");
	const [validOrgName, setValidOrgName] = React.useState(false);
	const [orgMission, setOrgMission] = React.useState("");
  const [orgAlign, setOrgAlign] = React.useState("");
  const [orgAmount, setOrgAmount] = React.useState("");
  const [orgImpact, setOrgImpact] = React.useState("");
  const [causes, setCauses] = React.useState([]);
	const [coLocation, setCOLocation] = React.useState("");
  const [idOption, setIdOption] = React.useState("");
  const [idFile, setIdFile] = React.useState(null);
  const [logoImage, setLogoImage] = React.useState(null);
  const [idReason, setIdReason] = React.useState("");
  const [orgSponsored, setOrgSponsored] = React.useState("");
	const [googleMap, setGoogleMap] = React.useState(null);
  const [market, setMarket] = React.useState(event?.cohatchMarket || null);
  const type = "Give";
	const status = "pending";
	const [businessAddress, setBusinessAddress] = useState(null);
	const [autoComplete, setAutoComplete] = useState(null);
  const location = useLocation();
  const nonprofitId = location.state;
	// end application data


	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries,		region: "US",
	});

  const imageCriteriaEventOwner = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };

   

  function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}
  	//testing form data
	const testingFillForm = () => {
		setFirstName("shondra");
		setLastName("Roberts");
    setBusinessAddress({address: "1899 East 22nd Street, Cleveland, OH", coordinates: {
      lat: 41.5035818,
      lng: -81.6747077
    },
    location: { coordinates: {
      0: -81.6747077,
      1: 41.5035818
    },
    type: "Point"}
    }),
		setEmail("test@test.com");
		setPhoneNumber(1234567890);
		setOrgName("Impact Foundation");
    setOrgAlign("we alignn");
    setOrgAmount(3);
		setOrgMission("We are impacting communities by giving back");
    setCOLocation("springfield");
    setIdOption("Yes");
     setIdFile({
        	large: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/8.png",
			width: 250,
			height: 250,
		},
       	small: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/8.png",
			width: 250,
			height: 250,
		},
      }),
    setIdReason("Exempt");
	};
	//end testing form data


	// Form data

// File Upload Information
  const imageCriteria = {
		requiredSize: 6e6,
		requiredDimensions: { width: 1000, height: 800 },
	};

  function PhotoDisplay({ callback }) {
	return (
		<MdUpload
			onClick={callback}
			className="text-2xl text-primary-500 cursor-pointer"
		/>
	);
}


  const handleMarketChange = (event) => {
  if (event) {
    const { value } = event;
   
    setMarket(value);
    setCOLocation(null);
    // setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
  } else {
    setMarket(null);
    setCOLocation(null);
    // setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
  }
};
const generateCohatchMarket = () => {
  const cohatchMarket = [];

  for (const market in COHATCH_MARKETS) {
    cohatchMarket.push(COHATCH_MARKETS[market]);
  }
  return cohatchMarket.flat();
};

const handleLocationsChange = (event) => {
  if (event) {
    const { value } = event;
   
    setCOLocation(value);
    
  } else {

    setCOLocation(null);
   
    // setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
  }
};
const generateCohatchLocation = () => {
  if (market) {
    const cohatchLocation = [];
    cohatchLocation.push(COHATCH_LOCATIONS[market]["locations"]);
    return cohatchLocation.flat();
  } else {
    return [];
  }
};
	const onLoad = (autoComplete) => {
		setAutoComplete(autoComplete);
	};
	const onPlaceChanged = () => {
		if (autoComplete !== null) {
			const {
				formatted_address,
				geometry: {
					location: { lat, lng },
				},
			} = autoComplete.getPlace();
			const latitude = lat();
			const longitude = lng();
			setBusinessAddress({
				address: formatted_address,
				coordinates: { lat: latitude, lng: longitude },
				location: { type: "Point", coordinates: [longitude, latitude] },
			});
		} else {
			console.log("Autocomplete is not loaded yet");
		}
	};

	const onPositionChanged = () => {
		if (googleMap) {
			googleMap.panTo(businessAddress.coordinates);
		}
	};

  

  const generateCauses = (causesArray) => {
    const causes = [];

    for (const cause of causesArray) {
      causes.push({ value: cause, label: cause });
    }

    return causes;
  };
   //Add Causes
  
  const [userCause, setUserCause] = React.useState(causes || []);
  const [newCause, setNewCause] = React.useState(null);
  const handleAddCause = (event) => {
    event.preventDefault();
    if (!newCause) {
      return;
    }

    if (inArray(userCause, newCause.value)) {
      notify(`${newCause.value} already exists in your causes list.`, "error");
      return;
    }
    setUserCause((prevState) => {
      return [...prevState, newCause.value];
    });
    setNewCause(null);
  };
  const handleRemoveCause = (event) => {
    const { cause } = event.target.dataset;
    const newCauseList = userCause.filter(
      (value, index) => parseInt(cause, 10) !== index
    );
    setUserCause(newCauseList);
  };

  const CAUSES = [
	"Animals",
	"Arts, Culture & Humanity",
	"Climate",
	"Community Development",
	"Conservation",
	"Consumption",
	"Crisis",
	"Education",
	"Energy",
	"Equality",
	"Family",
	"Food",
	"Health",
	"Homeless",
	"Jobs",
	"Other",
	"Peace and Justice",
	"Poverty",
	"Refugees",
	"Religious",
	"Water & Sanitation",
  "Minority Owned",
  "Woman Owned"
];

  const OPTIONS =[	
    { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  ]

	React.useEffect(() => {
		const result = validateName(firstName);
		setValidFirstName(result);
	}, [firstName]);

	React.useEffect(() => {
		const result = validateName(lastName);
		setValidLastName(result);
	}, [lastName]);

	React.useEffect(() => {
		const result = validateEmail(email);
		setValidEmail(result);
	}, [email]);

	React.useEffect(() => {
		const result = validatePhone(phoneNumber);
		setValidPhoneNumber(result);
	}, [phoneNumber]);

	React.useEffect(() => {
		const result = validateMessage(orgName);
		setValidOrgName(result);
	}, [orgName]);

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);
		try {
			if (!validFirstName) {
				notify("One or more fields are invalid. Check and try again");
			}
			const payload = {
				firstName,
				lastName,
				email,
				businessAddress,
				phoneNumber,
				orgName,
				orgMission,
        orgAlign,
        orgAmount,
        orgImpact,
        orgSponsored,
        causes,
				type,
        nonprofitId,
        idNumber: {
					idOption: idOption,
					idFile: idFile,
          idReason: idReason
				},
       
				admin: {
					appStatus: status,
          cmStatus: status,
          mlStatus: status,

				},
				coLocation,
        logoImage
			};
			
			const response = await submitApplication(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmitting(false);
				setFirstName(""), setLastName(""), setEmail("");
				setPhoneNumber("");
				setOrgName("");
				setOrgMission("");
				setBusinessAddress("");
				setCOLocation("");
        setOrgAlign("");
        setOrgAmount("");
        setLogoImage("");
        setCauses("[]");
        setOrgImpact("");
        setOrgSponsored("");
				notify("Application form submitted successfully");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later");
		}
	};

  
	return (
		<>
		{/* GIVE Application */}
      	<div className="">
          { nonprofit === 1 && (
            <>
				<div className="bg-success-800">
					<BreadCrumb directions={directions} />
					<div className="max-w-7xl mx-auto  sm:py-24 sm:px-6 lg:px-8">
						<div className="text-center">
							<p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
								Apply for the Give Scholarship
							</p>
							<p className="max-w-xl mt-5 mx-auto text-xl text-gray-200">
								If you’re a nonprofit looking to create more good in our
								communities, we’re here to help. Apply for our Give Scholarship
								to get access to our space while driving your mission forward.
								<p>
									<b>
										Applications are open year round. Scholarships will be
										awarded on a rolling basis.
									</b>
								</p>
							</p>
						</div>
					</div>
				</div>
        	<div className="float-center lg:grid lg:grid-cols-4">
					<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-8">
						<div className="max-w-lg mx-auto lg:max-w-none">
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="firstName"
											className="block text-lg font-medium text-black-700"
										>
											First name:
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="firstName"
											id="firstName"
											value={firstName}
											onChange={(event) => setFirstName(event.target.value)}
											className={generateClassName(
												!validFirstName && firstName
											)}
										/>
									</div>
                  <div className="w-full">
										<label 
                    htmlFor="lastName"
                    className="block text-lg font-medium text-black-700"
                    >
                      Last name:
                      </label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="lastName"
											id="lastName"
											value={lastName}
											onChange={(event) => setLastName(event.target.value)}
											className={generateClassName(!validLastName && lastName)}
										/>
									</div>
								</div>

								<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="email"
                    className="block text-lg font-medium text-black-700"
                    >
                      Email:
                    </label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="email"
											id="email"
											value={email}
											onChange={(event) => setEmail(event.target.value)}
											className={generateClassName(!validEmail && email)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="phoneNumber"
                    className="block text-lg font-medium text-black-700">
                      Phone Number:
                      </label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="phoneNumber"
											id="phoneNumber"
											value={phoneNumber}
											onChange={(event) => setPhoneNumber(event.target.value)}
											className={generateClassName(
												!validPhoneNumber && phoneNumber
											)}
										/>
									</div>
								</div>
								<div className="mt-3 w-full flex gap-3">
									<div className="w-full">
										<label 
                    htmlFor="orgMission"
                    className="block text-lg font-medium text-black-700">Organization Name:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="orgName"
											id="orgName"
											value={orgName}
											onChange={(event) => setOrgName(event.target.value)}
											className={generateClassName(!validOrgName && orgName)}
										/>
									</div>
								</div>
                
                	<div className="mt-3 w-full flex gap-3">
									<div className="w-full">
										<label 
                    htmlFor="orgMission"
                    className="block text-lg font-medium text-black-700">Organization Website:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="orgName"
											id="orgName"
											value={orgName}
											onChange={(event) => setOrgName(event.target.value)}
											className={generateClassName(!validOrgName && orgName)}
										/>
									</div>
								</div>

                		<div className="mt-3 w-full flex gap-3">
									<div className="w-full">
                    <label 
                    htmlFor="address"
                    className="block text-lg font-medium text-black-700">Nonprofit Address:</label>
                    {isLoaded ? (
                      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <div className="w-full flex gap-3">
                          <div className="w-full">
                            <p className="text-sm text-red-400 text-right">*</p>
                            <input
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                              type="text"
                              name="lineOne"
                              id="lineOne"
                            />
                          </div>
                        </div>
                      </Autocomplete>
                    ) : (
                      <Spinner displayText="Loading..." />
                    )}
                </div>
                </div>
							

								<div className="mt-3 w-full flex gap-3">
									<div className="w-full">
										<label 
                    htmlFor="orgMission"
                    className="block text-lg font-medium text-black-700">
											Description of Your Organization and Mission:
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<textarea
											className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                      rows="3"
											type="textarea"
											name="orgMission"
											id="orgMission"
											value={orgMission}
											onChange={(event) => setOrgMission(event.target.value)}
										/>
									</div>
								</div>
                

                	<div className="mt-3 w-full flex gap-3">
									<div className="w-full">
										<label 
                    htmlFor="orgMission"
                    className="block text-lg font-medium text-black-700">What aspect of COhatch's mission do you think most aligns with your organization's mission?:</label>
										<p className="text-sm text-red-400 text-right">*</p>
									<textarea
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                      rows="3"
											type="textarea"
											name="orgAlign"
											id="orgAlign"
											value={orgAlign}
											onChange={(event) => setOrgAlign(event.target.value)}
										/>
									</div>
								</div>
                	<div className="mt-3 w-full flex gap-3">
									<div className="w-full">
										<label 
                    htmlFor="orgMission"
                    className="block text-lg font-medium text-black-700">How many employees are in your organization?:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="number"
											name="orgAmount"
											id="orgAmount"
											value={orgAmount}
											onChange={(event) => setOrgAmount(event.target.value)}
											
										/>
									</div>
								</div>

                	<div className="mt-3 w-full flex gap-3">
									<div className="w-full">
										<label 
                    htmlFor="orgMission"
                    className="block text-lg font-medium text-black-700">How does your organization impact the local community?(Ex. event, providing necessary services, etc.):</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="orgAmount"
											id="orgAmount"
											value={orgImpact}
											onChange={(event) => setOrgImpact(event.target.value)}
										/>
									</div>
								</div>

                 <div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="orgMission"
                    >Please select a cause that aligns with your organization:</label>
										<p className="text-sm text-red-400 text-right">*</p>
                    <form onSubmit={handleAddCause}>
                    <div className="w-full flex items-center gap-3">
                      <div className="w-full">
                        <div className="mt-1">
                          <SelectInput
                            options={generateCauses(CAUSES)}
                            noOptionsMessage="No causes found"
                            placeholder="Select a cause"
                            setSelectedValue={setNewCause}
                          />
                        </div>
                      </div>
                      <div>
                        <ButtonPrimary icon="add" size="md" />
                      </div>
                    </div>
                  </form>
                    <div className="mt-5 w-full">
                      <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                        {!userCause.length ? (
                          <DefaultState
                            icon="diversity_1"
                            heading="No Causes You Care About Found"
                            description="Add the causes you care about."
                          />
                        ) : (
                          userCause.map((value, index) => (
                            <CauseCard
                              key={uuid()}
                              cause={{ value, index }}
                              removeCause={handleRemoveCause}
                            />
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                 </div>
               

               <div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="orgMission">Do you have a 501C3?:</label>
										<p className="text-sm text-red-400 text-right">*</p>
                    <SelectInput
                      options={sortBy(OPTIONS, (item) => item.value)}
                      noOptionsMessage="No options found"
                      setSelectedValue={(event) => setIdOption(event.value)}
                    />
                  </div>
                 </div>
                 {idOption === "No" && (
                  <div>
                    <label htmlFor="idNumber">Explain why not:</label>
                  	<textarea
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                      rows="3"
											type="textarea"
											name="orgAlign"
											id="orgAlign"
                      defaultValue="Please explain why you do not have one..."
											value={idReason}
											onChange={(event) => setIdReason(event.target.value)}
										/>
                    </div>
                 )}
                 	{idOption == "Yes" && (
                    <>
								<div className="relative w-full flex gap-2 items-center">
									<span className="px-15 bg-white text-gray-500 mt-3">
										Upload a copy of your 501c3 information
									</span>
                  </div> 
                  <div className="cursor-pointer hover:rounded-md hover:bg-primary-100 transition-all duration-500">
									<FileUploader
										  fileType="image/*"
                        isMultiple={false}
                        validationCriteria={imageCriteria}
                        DisplayIcon={PhotoDisplay}
                        accessToken={userState.accessToken}
                        folder="501c3_photos"
                        description="Image - Min: 1000 X 800, Max: 6MB"
                        numberOfFiles={1}
                        callback={result => setIdFile(result)}
									/>
								
                </div>
                {idFile?.small.url && <img src={idFile.small.url} className="w-full h-full" alt="cover image upload" />}
                </>
						)}
						
               
          <div className="mt-10 w-full flex justify-between items-center mb-1">
            <label className="block text-sm font-medium text-gray-700">Logo Image</label>
            <p className="text-sm text-red-400 text-right">*</p>
          </div>
          <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px] mt-5">
            {logoImage?.small.url ? <img src={logoImage?.small.url} className="w-full h-full" alt="event owner image upload" /> : <p className="w-full pt-8 text-md text-center text-gray-400">Logo Image</p>}
            <FileUploader
              fileType="image/*"
              isMultiple={false}
              validationCriteria={imageCriteriaEventOwner}
              DisplayIcon={PhotoDisplay}
              accessToken={userState.accessToken}
              folder="logo_photos"
              description="Image - Min: 400 X 400, Max: 5MB"
              numberOfFiles={1}
              callback={result => setLogoImage(result)}
            />
          </div>
                     
            <div className="w-full mt-3 flex gap-3">
              <div className="w-full">
                <label htmlFor="orgMission">Location:</label>
                <p className="text-sm text-red-400 text-right">*</p>
              <SelectInput
                options={generateCohatchMarket()}
                noOptionsMessage="No cities found"
                placeholder="Select a COhatch City"
                // defaultValue={() => {
                // return market ? { value: market, label: COHATCH_LOCATIONS[market]["market"]["label"] } : null;
                // }}
                setSelectedValue={handleMarketChange}
              />
          </div>
            <div className="w-full flex mt-6 gap-3">
              <div className="w-full">
                    <p className="text-sm text-red-400 text-right">*</p>
                    <SelectInput
                      options={generateCohatchLocation()}
                      noOptionsMessage="Select a COhatch Location"
                      placeholder="Select a COhatch Location"
                      // defaultValue={() => {
                      // return location ? { value: location, label: getValueFromArrayOfObject(COHATCH_LOCATIONS[market]["location"], { value: location }) } : null;
                      // }}
                      setSelectedValue={handleLocationsChange}
                    />
                  </div>
                  </div>
              </div>

          <div className="w-full flex gap-3 mt-6">
          <div className="w-full">
            <label htmlFor="orgMission">Are you sponsored by an employee? If so, who?:</label>
            <input
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
              type="text"
              name="orgAmount"
              id="orgAmount"
              value={orgSponsored}
              onChange={(event) => setOrgSponsored(event.target.value)}
            />
          </div>
        </div>
							<div className="mt-4 h-20 w-40">
								<ButtonPrimary
									text={"Submit"}
									size="md"
									action={handleFormSubmit}
								/>
							</div>
							<div className=" h-20 w-40">
								<ButtonPrimary
									text={"test"}
									size="md"
									action={testingFillForm}
								/>
							</div>
						</div>
					</div>
				</div>
       
        </>
    )}
    {/* BOOST Application */}
       { nonprofit === 2 && (
        <>
				<div className="bg-success-800">
					<BreadCrumb directions={directions} />
					<div className="max-w-7xl mx-auto  sm:py-24 sm:px-6 lg:px-8">
						<div className="text-center">
							<p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
								Apply for the Boost Scholarship
							</p>
							<p className="max-w-xl mt-5 mx-auto text-xl text-gray-200">
								If you’re a startup looking to create more good in our
								communities, we’re here to help. Apply for our Boost Scholarship
								to get access to our space while driving your mission forward.
								<p>
									<b>
										Applications are open year round. Scholarships will be
										awarded on a rolling basis.
									</b>
								</p>
							</p>
						</div>
					</div>
				</div>
        	<div className=" ml-28  float-center lg:grid lg:grid-cols-4">
					<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-8">
						<div className="max-w-lg mx-auto lg:max-w-none">
							
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="firstName"
											className="block text-lg font-medium text-black-700"
										>
											First name:
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="firstName"
											id="firstName"
											value={firstName}
											onChange={(event) => setFirstName(event.target.value)}
											className={generateClassName(
												!validFirstName && firstName
											)}
										/>
									</div>
								</div>

								<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="lastName">Last name:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="lastName"
											id="lastName"
											value={lastName}
											onChange={(event) => setLastName(event.target.value)}
											className={generateClassName(!validLastName && lastName)}
										/>
									</div>
								</div>
               
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="email">Email:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="email"
											id="email"
											value={email}
											onChange={(event) => setEmail(event.target.value)}
											className={generateClassName(!validEmail && email)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="phoneNumber">Phone Number:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="phoneNumber"
											id="phoneNumber"
											value={phoneNumber}
											onChange={(event) => setPhoneNumber(event.target.value)}
											className={generateClassName(
												!validPhoneNumber && phoneNumber
											)}
										/>
									</div>
								</div>
                 	<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="lastName">Website:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="lastName"
											id="lastName"
											value={lastName}
											onChange={(event) => setLastName(event.target.value)}
											className={generateClassName(!validLastName && lastName)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="orgMission">Organization Name:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											type="text"
											name="orgName"
											id="orgName"
											value={orgName}
											onChange={(event) => setOrgName(event.target.value)}
											className={generateClassName(!validOrgName && orgName)}
										/>
									</div>
								</div>
                
                		<div className="w-full flex gap-3">
									<div className="w-full">
                    <label htmlFor="address">Startup Address:</label>
                    {isLoaded ? (
                      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <div className="w-full flex gap-3">
                          <div className="w-full">
                            <p className="text-sm text-red-400 text-right">*</p>
                            <input
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                              type="text"
                              name="lineOne"
                              id="lineOne"
                            />
                          </div>
                        </div>
                      </Autocomplete>
                    ) : (
                      <Spinner displayText="Loading..." />
                    )}
                </div>
                </div>
							

								<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="orgMission">
											Description of Your Organization and Mission:
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<textarea
											className="mt-5 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                      rows="3"
											type="textarea"
											name="orgMission"
											id="orgMission"
											value={orgMission}
											onChange={(event) => setOrgMission(event.target.value)}
										/>
									</div>
								</div>
                

                	<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="orgMission">What aspect of COhatch's mission do you think most aligns with your organization's mission?:</label>
										<p className="text-sm text-red-400 text-right">*</p>
									<textarea
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                      rows="3"
											type="textarea"
											name="orgAlign"
											id="orgAlign"
											value={orgAlign}
											onChange={(event) => setOrgAlign(event.target.value)}
										/>
									</div>
								</div>
                	<div className="w-full flex gap-3">
									<div className="w-full">
										<label htmlFor="orgMission">How many employees are in your organization?:</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="number"
											name="orgAmount"
											id="orgAmount"
											value={orgAmount}
											onChange={(event) => setOrgAmount(event.target.value)}
											
										/>
									</div>
							
             
							</div>
              	<div className="w-full mt-3 flex gap-3">
									<div className="w-full">
										<label htmlFor="orgMission">Location:</label>
										<p className="text-sm text-red-400 text-right">*</p>
								 <SelectInput
                          options={generateCohatchMarket()}
                          noOptionsMessage="No cities found"
                          placeholder="Select a COhatch City"
                          // defaultValue={() => {
                          // return market ? { value: market, label: COHATCH_LOCATIONS[market]["market"]["label"] } : null;
                          // }}
                          setSelectedValue={handleMarketChange}
                        />
							</div>
              	<div className="w-full flex mt-6 gap-3">
									<div className="w-full">
                       	<p className="text-sm text-red-400 text-right">*</p>
                        <SelectInput
                          options={generateCohatchLocation()}
                          noOptionsMessage="Select a COhatch Location"
                          placeholder="Select a COhatch Location"
                          // defaultValue={() => {
                          // return location ? { value: location, label: getValueFromArrayOfObject(COHATCH_LOCATIONS[market]["location"], { value: location }) } : null;
                          // }}
                          setSelectedValue={handleLocationsChange}
                        />
                      </div>
                      </div>
               
              </div>

							    

							<div className="mt-4 h-20 w-40">
								<ButtonPrimary
									text={"Submit"}
									size="md"
									action={handleFormSubmit}
								/>
							</div>
							<div className=" h-20 w-40">
								<ButtonPrimary
									text={"test"}
									size="md"
									action={testingFillForm}
								/>
							</div>
						</div>
					</div>
				</div>
        </>
    )}

			
			</div>
		</>
	);
}

export default React.forwardRef(Scholarships);
