import React from "react";
import PropTypes from "prop-types";

function ButtonPrimary({ text, loading, action, size, icon }) {
  switch (size) {
    case "xs":
      return (
        <button
          disabled={loading}
          onClick={action}
          className={`w-full ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
          {icon ? <span className="material-icons-outlined text-xs">{icon}</span> : null}
          {text}
        </button>
      );

    case "sm":
      return (
        <button
          disabled={loading}
          onClick={action}
          className={`w-full ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
          {icon ? <span className="material-icons-outlined text-sm">{icon}</span> : null}
          {text}
        </button>
      );

    case "md":
      return (
        <button
          disabled={loading}
          onClick={action}
          className={`w-full ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
          {icon ? <span className="material-icons-outlined text-md">{icon}</span> : null}
          {text}
        </button>
      );
      case "md-1":
      return (
        <button
          disabled={loading}
          onClick={action}
          className={`w-full ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
          {text}
          {icon ? <span className="material-icons-outlined text-md">{icon}</span> : null}
        </button>
      );

    case "lg":
      return (
        <button
          disabled={loading}
          onClick={action}
          className={`w-full ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-lg font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
          {icon ? <span className="material-icons-outlined text-lg">{icon}</span> : null}
          {text}
        </button>
      );

    case "xl":
      return (
        <button
          disabled={loading}
          onClick={action}
          className={`w-full ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } inline-flex items-center justify-center gap-2 px-3 py-3 border border-transparent text-base font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
          {icon ? <span className="material-icons-outlined text-base">{icon}</span> : null}
          {text}
        </button>
      );

    default:
      return (
        <button
          disabled={loading}
          onClick={action}
          className={`w-full ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
          {icon ? <span className="material-icons-outlined text-md">{icon}</span> : null}
          {text}
        </button>
      );
  }
}

ButtonPrimary.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  action: PropTypes.func,
  size: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default ButtonPrimary;
