/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from "react";

const reactionIcons = {
  LIKE: "👍",
  LOVE: "❤️",
  HAHA: "😆",
  WOW: "😮",
  SAD: "😢",
  ANGRY: "😡",
};

function GroupReaction({ reaction }) {
  return (
    <div className="flex -space-x-1 relative z-0 overflow-hidden">
      {reaction?.reactions.length
        ? reaction.reactions.map((react, index) => {
            return (
              <span
                key={index}
                className={"relative z-30 inline-block rounded-full material-icons-outlined  text-lg"}
              >
                {reactionIcons[react?._id?.reactionType]}
              </span>
            );
          })
        : null}
    </div>
  );
}

export default GroupReaction;
