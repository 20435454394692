
/*eslint-disable */
import React from "react";
import BreadCrumb from "../../../../components/bread_crumb/BreadCrumb";
import useTitle from "../../../../hooks/useTitle";
//import Tab from "../../../../components/tab/Tab";
import useUserContext from "../../../../hooks/useUserContext";
import { Outlet } from "react-router-dom";
import Tab from "../../../../components/tab/Tab"


function Nonprofits() {
	useTitle("Nonprofits");
 

	
  const gnavigation = [
    { pathName: "All Nonprofits", url: "", icon: "group", end: true },
    { pathName: "My Nonprofits", url: "my_nonprofits", icon: "group" },
    { pathName: "Add Nonprofit", url: "add_nonprofit" },
		
	];

	return (
		<div className="w-full py-8 px-5 text-center ">
			<h1 className="w-full text-4xl md:text-5xl text-success-800 font-bold">
				Local Nonprofits
			</h1>

      

      
   
			<Outlet />
		</div>
	);
}

export default Nonprofits;
