/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function createView(payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/listing_analytics/view`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createView;
