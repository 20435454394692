/*eslint-disable*/

import React from "react";
import { useEffect } from "react";
import {
	MAGIC_NUMBERS,
	APP_MARKETS,
	APP_LOCATIONS,
	libraries,
} from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import getAppsById from "./requests/getAppsByUserId";
import { FormProvider, useForm } from "react-hook-form";
import submitApplication from "../philanthropy/give_scholarships/submitApplication";

import { sortBy } from "lodash";
import notify from "../../services/toast";
import {
	Input,
	SelectInputs,
	Upload,
} from "../../components/formvalidate/Input";

import { Autocomplete, useLoadScript } from "@react-google-maps/api";

function ReapplicationPage() {
	const { userState } = useUserContext();
	const accessToken = userState.accessToken;
	const userId = userState.id;
	const [apps, setApps] = React.useState("");
	const [selectedApp, setSelectedApp] = React.useState("");
	const [isChecked, setIsChecked] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);

	const [autoComplete, setAutoComplete] = React.useState(null);

	const onPlaceChanged = () => {
		if (autoComplete !== null) {
			const {
				formatted_address,
				geometry: {
					location: { lat, lng },
				},
			} = autoComplete.getPlace();
			const latitude = lat();
			const longitude = lng();
			setBusinessAddress({
				address: formatted_address,
				coordinates: { lat: latitude, lng: longitude },
				location: { type: "Point", coordinates: [longitude, latitude] },
			});
		} else {
			console.log("Autocomplete is not loaded yet");
		}
	};
	const fetchApplicationByUserId = async (accessToken, userId) => {
		try {
			const response = await getAppsById(accessToken, userId);
			if (response.status === "success") {
				setApps(response.data);
			} else {
				console.log("error");
			}
		} catch (error) {}
	};

	//form
	const methods = useForm();

	const CAUSES = [
		{ value: "Animals", label: "Animals" },
		{ value: "Arts, Culture & Humanity", label: "Arts, Culture & Humanity" },
		{ value: "Climate", label: "Climate" },
		{ value: "Community Development", label: "Community Development" },
		{ value: "Conservation", label: "Conservation" },
		{ value: "Consumption", label: "Consumption" },
		{ value: "Crisis", label: "Crisis" },
		{ value: "Education", label: "Education" },
		{ value: "Energy", label: "Energy" },
		{ value: "Equality", label: "Equality" },
		{ value: "Family", label: "Family" },
		{ value: "Food", label: "Food" },
		{ value: "Health", label: "Health" },
		{ value: "Homeless", label: "Homeless" },
		{ value: "Jobs", label: "Jobs" },
		{ value: "Peace and Justice", label: "Peace and Justice" },
		{ value: "Refugees", label: "Refugees" },
		{ value: "Religion", label: "Religion" },
		{ value: "Water & Sanitation", label: "Water & Sanitation" },
		{ value: "Other", label: "Other" },
	];

	const OPTIONS = [
		{ value: "Yes", label: "Yes" },
		{ value: "No", label: "No" },
	];
	const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" },
	];
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries,
		region: "US",
	});

	const handleOnChange = (app) => {
		setIsChecked(!isChecked);
		setSelectedApp(app);
		console.log(isChecked);
	};

	const onLoad = (autoComplete) => {
		setAutoComplete(autoComplete);
	};
	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;

			setMarket(value);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		} else {
			setMarket(null);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		}
	};
	const generateCohatchMarket = () => {
		const cohatchMarket = [];

		for (const market in APP_MARKETS) {
			cohatchMarket.push(APP_MARKETS[market]);
		}

		return cohatchMarket.flat();
	};

	const generateCohatchLocation = () => {
		if (methods.watch().market) {
			const cohatchLocation = [];
			cohatchLocation.push(APP_LOCATIONS[methods.watch().market]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};

	// PAYLOAD
	const handleChange = (key, value) => {
		console.log(key, value);
		//setPayload({
		//	...payload,
		//	[key]: value,
		//	});
	};

	const onSubmit = methods.handleSubmit((data) => {
		handleFormSubmit();
	});

	const handleFormSubmit = async (data) => {
		console.log("trying", selectedApp);
		try {
			setSubmitting(true);
			const givePayload = {
				firstName: selectedApp.firstName,
				lastName: selectedApp.lastName,
				email: selectedApp.email,
				website: selectedApp.website,
				businessAddress: selectedApp.businessAddress,
				phoneNumber: selectedApp.phoneNumber,
				orgName: selectedApp.orgName,
				orgMission: selectedApp.orgMission,
				orgAlign: selectedApp.orgAlign,
				orgAmount: selectedApp.orgAmount,
				orgImpact: selectedApp.orgImpact,
				orgSponsored: selectedApp.orgSponsored,
				causes: selectedApp.causes,
				type: selectedApp.type,
				// nonprofitId,
				market: selectedApp.market,
				orgOffer: selectedApp.orgOffer,
				//orgUses,
				idNumber: {
					idOption: selectedApp.idNumber.idOption,
					idFile: selectedApp.idNumber.idFile,
					idReason: selectedApp.idNumber.idReason,
				},
				appStatus: "pending",
				cmStatus: "pending",
				mlStatus: "pending",
				// reapplicant,
				coLocation: selectedApp.coLocation,
				logoImage: selectedApp.logoImage,
				createdAt: new Date(),
			};
			// const boostPayload = { };

			const response = await submitApplication(
				givePayload,
				userState.accessToken
			);
			if (response.status === "success") {
				setSubmitting(false);
				console.log("submitted application");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occured. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later.");
		}
	};

	useEffect(() => {
		fetchApplicationByUserId(accessToken, userId);
	}, []);

	return (
		<div className="p-10 flex justify-center">
			<div className="max-w-7xl  overflow-hidden shadow rounded-lg">
				<div className=" bg-white flex justify-center ">
					<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-8">
						<div className="font-bold mb-3">2024 Application</div>

						<div>
							{apps && !isChecked
								? apps?.map((app, index) => (
										<div>
											<div key={index}>
												<FormProvider {...methods}>
													<form onSubmit={onSubmit}>
														<input
															id="selectedApp"
															name="selectedApp"
															value={app}
															type="checkbox"
															className=""
															onChange={() => handleOnChange(app)}
															//onChange={handleOnChange}
														/>
														<label
															htmlFor="selectedApp"
															className="m-3 capitalize"
														>
															{app.orgName} {app.type}
														</label>
														<div></div>
													</form>
												</FormProvider>
											</div>
										</div>
								  ))
								: null}
							<div>
								{isChecked ? (
									<div className="mt-10">
										<h1 className="mb-5 ">
											Please double check that all informaton is correct.
										</h1>
										<FormProvider {...methods}>
											<form
												onSubmit={onSubmit}
												noValidate
												autoComplete="off"
												className="container"
											>
												<div className="bg-white max-w-lg mx-auto lg:max-w-none">
													{/* <div className="w-full flex gap-3 mb-6">
                        <div className="w-full">
                         <SelectInputs
                         label="Are you a current COhatch scholar applying for 2024?:"
                         name="reapplicant"
                         id="reapplicant"
                         options={scholarOPTIONS}
                        setSelectedValue={(event) => setReapplicant(event.value)}
                          validation={{
                            required: {
                              value: true,
                              message: 'required',
                            },
                          }}
                         />
                        </div>
                      </div> */}
													<div className="mb-6 w-full flex gap-3">
														<div className="w-full">
															<label
																htmlFor="firstName"
																className="font-semibold"
															>
																First Name
															</label>
															<input
																className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray-100/70"
																label="First Name"
																name="firstName"
																type="input"
																id="name"
																defaultValue={selectedApp.firstName}
															/>
														</div>
														<div className="w-full">
															<label
																htmlFor="lastName"
																className="font-semibold"
															>
																Last Name
															</label>
															<input
																className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray-100/70"
																label="First Name"
																name="firstName"
																type="input"
																id="name"
																defaultValue={selectedApp.lastName}
															/>
														</div>
													</div>

													<div className="w-full flex mb-6">
														<div className="w-full">
															<label htmlFor="email" className="font-semibold">
																Email
															</label>
															<input
																className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray-100/70"
																name="email"
																type="input"
																id="email"
																defaultValue={selectedApp.email}
															/>
														</div>
													</div>
													<div className="w-full flex gap-3 mb-6">
														<div className="w-full">
															<label htmlFor="email" className="font-semibold">
																Phone Number
															</label>
															<input
																type="input"
																name="phoneNumber"
																id="phoneNumber"
																className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray-100/70"
																label="Phone Number"
																defaultValue={selectedApp.phoneNumber}
															/>
														</div>
													</div>

													<div className="mb-6 w-full flex gap-3">
														<div className="w-full">
															<label htmlFor="email" className="font-semibold">
																Organization Website
															</label>
															<input
																type="string"
																name="website"
																id="website"
																className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray-100/70"
																defaultValue={selectedApp.website}
															/>
														</div>
													</div>
													<div className="mb-6 w-full flex gap-3">
														<div className="w-full">
															<label htmlFor="email" className="font-semibold">
																Organization Address
															</label>
															<Autocomplete
																onLoad={onLoad}
																onPlaceChanged={onPlaceChanged}
															>
																<div className="w-full flex gap-3">
																	<div className="w-full">
																		<input
																			className="mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray-100/70"
																			defaultValue={selectedApp.businessAddress}
																			type="text"
																			name="address"
																			id="address"
																		/>
																	</div>
																</div>
															</Autocomplete>
														</div>
													</div>

													<div className="w-full mt-3 flex gap-3 mb-6">
														<div className="w-full">
															<SelectInputs
																label="COhatch City:"
																name="market"
																id="market"
																options={generateCohatchMarket()}
																noOptionsMessage="No cities found"
																placeholder={selectedApp.market}
																setSelectedValue={handleMarketChange}
															/>
														</div>

														<div className="w-full flex  gap-3">
															<div className="w-full ">
																<SelectInputs
																	label="COhatch Location"
																	id="coLocation"
																	name="coLocation"
																	options={generateCohatchLocation()}
																	noOptionsMessage="Select a COhatch Location"
																	placeholder={selectedApp.coLocation}
																/>
															</div>
														</div>
													</div>
													<div className="w-full flex gap-3 mb-6">
														<div className="w-full">
															<SelectInputs
																label="How many employees need access to COhatch?:"
																className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
																name="orgAmount"
																id="orgAmount"
																options={scorecardOPTIONS}
																setSelectedValue={(event) =>
																	setOrgAmount(event.value)
																}
																placeholder={selectedApp.orgAmount}
															/>
														</div>
													</div>

													<div className="mt-4 ">
														<div className="w-full mt-4 flex justify-between items-center">
															<div>
																<button
																	className="cursor-pointer inline-flex items-center justify-center gap-2 px-3 py-2 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
																	type="submit"
																>
																	Submit
																</button>
															</div>
														</div>
													</div>
												</div>
											</form>
										</FormProvider>
										<button
											className="m-10 cursor-pointer inline-flex items-center justify-center gap-2 px-3 py-2 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
											onClick={() => setIsChecked(false)}
										>
											Back
										</button>
										<button
											className="mt-10 cursor-pointer inline-flex items-center justify-center gap-2 px-3 py-2 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
											type="submit"
										>
											Submit
										</button>
									</div>
								) : (
									<button
										className="mt-10 inline-flex items-center justify-center gap-2 px-3 py-2 border border-transparent text-md font-medium rounded shadow-sm text-white bg-slate-400"
										disabled="true"
									>
										Submit
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ReapplicationPage;

//1. populate application DONE
//2. ask user to select app DONE
//3. take information from selected app for payload DONE
//add conditional for give type app vs boost type app
//3. submit information like a regular scholarship application Done
//4. If information is not up to date, advise applicant to edit their application/profile first** Update on cohatchplus.com or have CM update it
