/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "../../components/logo/Logo";
import { useSearchParams } from "react-router-dom";
import Alert from "../../components/alert/Alert";
import makeAPIRequest from "./../../services/makeRequest";
import config from "./../../config";
import Spinner from "../../components/spinner/Spinner";
import useScrollPage from "../../hooks/useScrollPage";

function Success() {
  useScrollPage();
  const [queryParams, setQueryParams] = useSearchParams();
  const token = queryParams.get("token");

  const [verifying, setVerifying] = React.useState(false);
  const [error, setError] = React.useState(true);

  const calledOnce = React.useRef(false);

  React.useEffect(() => {
    const verifyingEmail = async () => {
      try {
        setVerifying(true);
        const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/email/verify?token=${token}`;
        const response = await makeAPIRequest(API_ENDPOINT, "GET");
        if (response.ok) {
          setQueryParams("");
          setError(false);
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setVerifying(false);
        calledOnce.current = true;
      }
    };

    if (calledOnce.current) {
      return;
    }
    if (token) {
      verifyingEmail();
    } else {
      setVerifying(false);
      setError(false);
    }
  }, []);

  return (
    <div className="h-screen w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-[url('https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/background-image.png')] bg-no-repeat bg-cover">
      <div className="flex justify-center">
        <Logo color="green" />
      </div>
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-md space-y-5">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-400">Email Address Verification</h2>
        {!verifying && !error ? (
          <Alert color="primary" heading="Your email address has been verified!" body={["Your email address has been verified successfully.", "You can now close this page."]} direction="/dashboard" />
        ) : error ? (
          <Alert
            color="red"
            heading="We could not verify your email address."
            body={[
              "Your email address could not be verified.",
              "If you have already verified your email address, use the link below to go to the dashboard.",
              "If you account is still not verified, please contact support@cohatchplus.com.",
            ]}
            direction="/dashboard"
          />
        ) : (
          <Spinner displayText="Verifying please wait..." />
        )}
      </div>
      <div className="mt-10 p-5 text-xs text-gray-500 text-center">&copy; COhatch/COhatch+ All rights reserved</div>
    </div>
  );
}

export default Success;
