/* eslint-disable require-await */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
import React from "react";
import handleUpdateEmailPreference from "./handleUpdateEmailPreference";
import useUserContext from "./../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "./../../../services/toast";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function EmailPreferences() {
  useScrollPage();
  useTitle("COhatch+ Email Preferences");
  const { userState, setEmailPreference, emailPreference } = useUserContext();

  const [generalLetters, setGeneralLetters] = React.useState(() => emailPreference?.letters?.GENERAL_UPDATES);
  const [updateLetters, setUpdateLetters] = React.useState(() => emailPreference?.letters?.NEW_FEATURES);
  const [securityLetters, setSecurityLetters] = React.useState(() => emailPreference?.letters?.SECURITY_ALERT);

  const updateEmailPreference = async data => {
    try {
      const payload = {
        ...emailPreference.letters,
        ...data,
      };
      const response = await handleUpdateEmailPreference(payload, userState.accessToken);

      if (response.status === "success") {
        if (response.data) {
          setEmailPreference(response.data);
        }
        notify("Your email preferences settings was updated successfully. Changes should propagate immediately.", "info");
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
        <div className="w-full">
          <h1 className="text-lg text-success-800 font-bold">Manage your email newsletters</h1>
        </div>
        <ul role="list" className="divide-y divide-gray-200">
          <li className="py-4">
            <div className="w-full bg-white rounded-md p-3 flex flex-col space-y-3 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
              <div>
                <h1 className="text-md text-success-800 font-bold">General Newsletters</h1>
                <p className="text-sm text-gray-500">Stay up to date with all things COhatch+. We’ll send out newsletters and updates a few times per month, but nothing crazy - we promise!</p>
              </div>
              <div className="flex items-center h-5">
                <input
                  onChange={event => {
                    setGeneralLetters(event.target.checked);
                    updateEmailPreference({ GENERAL_UPDATES: event.target.checked });
                  }}
                  aria-describedby="candidates-description"
                  name="GENERAL_LETTERS"
                  type="checkbox"
                  checked={generalLetters}
                  className="focus:ring-primary-500 h-6 w-6 text-primary-400 border-gray-300 rounded cursor-pointer"
                />
              </div>
            </div>
          </li>

          <li className="py-4">
            <div className="w-full bg-white rounded-md p-3 flex flex-col space-y-3 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
              <div>
                <h1 className="text-md text-success-800 font-bold">New Feature Updates</h1>
                <p className="text-sm text-gray-500">Be the first to know about new features and updates.</p>
              </div>
              <div className="flex items-center h-5">
                <input
                  onChange={event => {
                    setUpdateLetters(event.target.checked);
                    updateEmailPreference({ NEW_FEATURES: event.target.checked });
                  }}
                  aria-describedby="candidates-description"
                  name="NEW_FEATURES"
                  type="checkbox"
                  checked={updateLetters}
                  className="focus:ring-primary-500 h-6 w-6 text-primary-400 border-gray-300 rounded cursor-pointer"
                />
              </div>
            </div>
          </li>

          <li className="py-4">
            <div className="w-full bg-white rounded-md p-3 flex flex-col space-y-3 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
              <div>
                <h1 className="text-md text-success-800 font-bold">Security Alerts</h1>
                <p className="text-sm text-gray-500">Be notified about updates and alerts concerning your COhatch+ account.</p>
              </div>
              <div className="flex items-center h-5">
                <input
                  onChange={event => {
                    setSecurityLetters(event.target.checked);
                    updateEmailPreference({ SECURITY_ALERT: event.target.checked });
                  }}
                  aria-describedby="candidates-description"
                  name="SECURITY_ALERT"
                  type="checkbox"
                  checked={securityLetters}
                  className="focus:ring-primary-500 h-6 w-6 text-primary-400 border-gray-300 rounded cursor-pointer"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default EmailPreferences;
