/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { fromUnixTime } from "date-fns";
import ButtonPrimary from "./../../components/button/ButtonPrimary";
import { formatStripeMoney } from "../../services/util";
import { DATE_LOCALE, DATE_OPTIONS } from "../../constant";

function PlanCard({ planDetails, action, creatingPortal, subscriptionId }) {
  return (
    <div className="w-full bg-white rounded-md shadow-md">
      <div className="w-full p-5">
        <h1 className="text-xl text-success-800 font-bold uppercase">{planDetails.planName}</h1>
        {planDetails?.amount ? <h1 className="text-xl text-success-800 font-bold">${formatStripeMoney(planDetails.amount)}</h1> : null}
        <div className="w-full mt-2">
          {planDetails?.quantity ? <p className="text-lg text-gray-400">Quantity: {planDetails.quantity}</p> : <p className="text-lg text-gray-400">Quantity: N/A</p>}
          {planDetails?.status ? (
            <p className="text-lg text-gray-400 flex gap-3">
              Status:
              <span className="w-fit flex items-center rounded-full px-3 text-sm font-medium bg-primary-100 text-primary-700">{planDetails.status}</span>
            </p>
          ) : (
            "N/A"
          )}
          {planDetails?.nextBillingCycle ? (
            <p className="text-lg text-gray-400 flex gap-2">Next billing Cycle: {fromUnixTime(planDetails.nextBillingCycle).toLocaleDateString(DATE_LOCALE, DATE_OPTIONS)}</p>
          ) : (
            <p className="text-lg text-gray-400 flex gap-2">Next billing Cycle: N/A</p>
          )}
          {planDetails?.nextBillingCycleAmount ? (
            <p className="text-lg text-gray-400">Next billing Cycle Amount: ${formatStripeMoney(planDetails.nextBillingCycleAmount)}</p>
          ) : (
            <p className="text-lg text-gray-400">Next billing Cycle Amount: N/A</p>
          )}
        </div>
        {subscriptionId ? (
          <div className="mt-5 w-fit">
            <ButtonPrimary text={creatingPortal ? "Please wait..." : "Change plan"} size="md" action={action} loading={creatingPortal ? true : false} />
            <p className="mt-2 text-sm text-gray-400">You will be temporarily redirected to stripe to finalize your subscription.</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

PlanCard.propTypes = { planDetails: PropTypes.object.isRequired, action: PropTypes.func.isRequired, creatingPortal: PropTypes.bool.isRequired };
export default PlanCard;
