/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";

import { PROPERTY_BOOKING_DISCOUNT } from "../../../../constant";
import SelectInput from "../../../../components/select_input/SelectInput";

function LastMinuteDeal({
  property,
  setProperty,
}) {
  return (
    <div className="shadow sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Last Minute Deal</h3>
          <p className="mt-1 text-sm text-gray-500">Please enter if any last minute deals offered!</p>
        </div>

          <div className="col-span-6 sm:col-span-3">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="discount_price" className="block text-sm font-medium text-gray-700">
                Discounted Price
              </label>
            </div>
            <input
              type="text"
              name="discount_price"
              id="discount_price"
              autoComplete="off"
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              placeholder="$"
              value={property?.lastMinuteDiscountPrice || ""}
              onChange={(event) => setProperty({
                ...property,
                lastMinuteDiscountPrice: event.target.value,
              })}
              aria-describedby="name-optional"
            />
          </div>
          
            <div className="flex items-center mt-4">
            <label htmlFor="customRange2" className="form-label">Discount start and end date: </label>
              <div className="relative">
                <input
                  name="start"
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select Start Date"
                  onChange={(event) => setProperty({
                    ...property,
                    lastMinuteStartDate: event.target.value,
                  })}
                  value={property?.lastMinuteStartDate}>
                </input>
              </div>
              <span className="mx-4 text-gray-500">to</span>
              <div className="relative">
                <input
                  name="end"
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select End Date"
                  onChange={(event) => setProperty({
                    ...property,
                    lastMinuteEndDate: event.target.value,
                  })}
                  value={property?.lastMinuteEndDate}>
                </input>
              </div>
            </div>
		    </div>
    </div>
    
    );
}

export default LastMinuteDeal;