/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable id-length */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable capitalized-comments */
import React from "react";
import { GoogleMap, Marker, MarkerClusterer, InfoWindow, useLoadScript } from "@react-google-maps/api";
import Spinner from "./../../components/spinner/Spinner";
import InfoWindowModal from "../info_window/InfoWindowModal";
import { Link } from "react-router-dom";

function CustomGoogleMap({ locations, marker = true }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    mapIds: ["a0a654f664a096b7"],
    region: "US",
  });
  const [infoPosition, setInfoPosition] = React.useState(null);
  const [listingInfo, setListingInfo] = React.useState({ image: null, listingName: null, address: null, listingId: null });

  const onMarkerClick = event => {
    const { lat, lng } = event.latLng;
    const position = { lat: lat(), lng: lng() };
    const { image, listingName, address, listingId } = locations[1][`${lat()}${lng()}`];
    setListingInfo({ image, listingName, address, listingId });
    setInfoPosition(position);
  };

  const onCloseInfo = () => {
    setInfoPosition(null);
  };

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          options={{ mapId: "a0a654f664a096b7", mapTypeControl: false, panControl: false, navigationControl: false, scaleControl: false, scrollWheel: false, streetViewControl: false }}
          zoom={8}
          center={locations && locations[0].length ? locations[0][0] : { lat: 40.367474, lng: -82.996216 }}
          mapContainerClassName="h-full w-full">
          {locations && locations.length ? (
            <>
              {infoPosition && (
                <InfoWindow position={infoPosition} onCloseClick={onCloseInfo}>
                  <Link to={listingInfo.listingId ? `/marketplace/listing/${listingInfo.listingId}` : ""}>
                    <InfoWindowModal image={listingInfo.image} listingName={listingInfo.listingName} address={listingInfo.address} />
                  </Link>
                </InfoWindow>
              )}
              <MarkerClusterer maxZoom={15}>
                {clusterer => locations[0].map((location, index) => <Marker key={index} position={location} clusterer={clusterer} onClick={onMarkerClick} clickable={marker} />)}
              </MarkerClusterer>
            </>
          ) : null}
        </GoogleMap>
      ) : (
        <Spinner displayText="Loading Map" />
      )}
    </>
  );
}

export default CustomGoogleMap;
