/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import useNotificationContext from "../../hooks/useNotificationContext";
import useUserContext from "../../hooks/useUserContext";
import useLogout from "../../hooks/useLogout";
import { Link } from "react-router-dom";
import PhilanthropyNavigation from "./philanthropy_feed/PhilanthropyNavigation";
import useWebsocket from "../../hooks/useWebsocket";
import OfficerndUpdateModal from "../../components/officernd_update_modal/OfficerndUpdateModal";
import useScrollPage from "../../hooks/useScrollPage";
import { PLANS } from "../../constant";
import Tab from "../../components/tab/Tab";



function getUnreadNotifications(unreadNotifications) {
  if (unreadNotifications < 0) {
    return "0";
  } else if (unreadNotifications > 100) {
    return "100+";
  } else {
    return unreadNotifications;
  }
}

function Philanthropy() {
  useScrollPage();

  

  return (
    <div>
      
      
      <div className="p-5 space-y-2">
				<div className="flex-1 min-w-0 flex justify-center items-center">
				
				</div>
				{/* <BreadCrumb directions={directions} /> */}
		
				<Outlet />
			</div>
      <OfficerndUpdateModal />
    </div>
  );
}

export default Philanthropy;
