/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { MdLocationPin, MdAddIcCall } from "react-icons/md";
import { SocialIcon } from "react-social-icons";


function ListingHeader({ listingFacebook, listingInstagram, listingLinkedIn, listingLogo, listingSubCategory, listingName, listingPrice, listingAddress, listingPhone, listingEmail, listingWebsite }) {
  const idx = listingAddress && listingAddress.address ? listingAddress.address.lastIndexOf(",") : -1;
  const address = listingAddress && listingAddress.address && idx > -1 ? listingAddress.address.substring(0, idx) : "";

  return (
    <div className="w-full bg-no-repeat bg-cover bg-[url('/src/assets/Footer.jpg')] rounded-md shadow-md ">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-8 lg:px-8">
        <div className="w-full flex justify-center gap-2 p-4 items-center">
          <img src={`${listingLogo ? listingLogo : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"}`} alt="" className="w-[150px] h-[150px] rounded-full" />
          <div className="w-full flex flex-col space-y-2">
            <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{listingName ? listingName : "No listing name"}</h1>

            <div className="w-full flex gap-2 mt-4">
              <span className="w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 truncate">
                {listingSubCategory ? listingSubCategory : "No listing sub category"}
              </span>
              <p className="text-lg text-gray-500">|</p>

              {listingAddress ? (
                <div className=" flex gap-1 items-center">
                  <span className="sr-only">Address</span>
                  <MdLocationPin className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  <p className="text-white">
                    {address}
                  </p>
                  <p className="text-lg text-gray-500 ml-2">|</p>
                </div>
              ) : null}

              {listingPhone ? (
                <div className=" flex gap-1 items-center">
                  <span className="sr-only">Phone</span>
                  <MdAddIcCall className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  <p className="text-white" >
                    {listingPhone}
                  </p>
                  <p className="text-lg text-gray-500 ml-2">|</p>
                </div>
              ) : null}

              <p className="text-white" >
                {listingPrice ? <span>{listingPrice}</span> : null}
              </p>
            </div>

            <div>
              <div className="w-full flex gap-4">
                {listingEmail ? (
                  <a href={`mailto:${listingEmail}`} className="w-fit inline-flex items-center gap-1 px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 truncate cursor-pointer">
                    <span className="material-icons-outlined">email</span>
                    Email
                  </a>
                ) : null}
                {listingWebsite ? (
                  <a
                    href={`${listingWebsite}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 truncate cursor-pointer">
                    <span className="material-icons-outlined">language</span>
                    Website
                  </a>
                ) : null}

                {listingFacebook || listingInstagram || listingLinkedIn ? (
                  <div className=" flex flex-wrap  gap-3">
                    {listingFacebook ? <SocialIcon url={listingFacebook} /> : null}
                    {listingInstagram ? <SocialIcon url={listingInstagram} /> : null}
                    {listingLinkedIn ? <SocialIcon url={listingLinkedIn} /> : null}
                  </div>
                ) : null}
                
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

ListingHeader.propTypes = {
  listingLogo: PropTypes.string,
  listingSubCategory: PropTypes.string,
  listingName: PropTypes.string,
  listingPrice: PropTypes.string,
  listingPhone: PropTypes.string,
  listingEmail: PropTypes.string,
  listingWebsite: PropTypes.string,
  listingAddress: PropTypes.object,
};

export default ListingHeader;
