import React from "react";
import Logo from "./../../components/logo/Logo";
import Alert from "../../components/alert/Alert";
import useScrollPage from "../../hooks/useScrollPage";

function Cancel() {
  useScrollPage();
  return (
    <div className="h-screen w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-[url('https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/background-image.png')] bg-no-repeat bg-cover">
      <div className="flex justify-center">
        <Logo color="green" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md space-y-10">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-400">Subscription Cancelled</h2>

        <Alert
          color="red"
          heading="Your subscription has been cancelled."
          body={[
            "You account will remain in it's current state for 72 hours.",
            "This time limit gives you the opportunity to come back and continue where you left off.",
            "This account will be deleted after 72 hours, in which you will have to start over by creating a new account to use COhatch+ again.",
          ]}
          direction="/"
          directionName="Home"
        />
      </div>
      <div className="p-5 text-xs text-gray-500 text-center">&copy; COhatch/COhatch+ All rights reserved</div>
    </div>
  );
}

export default Cancel;
