import React from "react";

function useLocalStorage(key, initialValue) {
  const [item, setItem] = React.useState(() => {
    const storedValue = window.sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(item));
  }, [item, key]);

  return [item, setItem];
}

export default useLocalStorage;
