import React from "react";
import PropTypes from "prop-types";
import { SocialIcon } from "react-social-icons";

function ListingSocialLink({ listingFacebook, listingInstagram, listingLinkedIn }) {
  return (
    <>
      {listingFacebook || listingInstagram || listingLinkedIn ? (
        <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
          <h1 className="text-xl text-success-800 font-bold m-2">Social Profile</h1>
          <hr />
          <div className="w-full flex flex-wrap p-2 gap-3">
            {listingFacebook ? <SocialIcon url={listingFacebook} /> : null}
            {listingInstagram ? <SocialIcon url={listingInstagram} /> : null}
            {listingLinkedIn ? <SocialIcon url={listingLinkedIn} /> : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

ListingSocialLink.propTypes = {
  listingFacebook: PropTypes.string,
  listingInstagram: PropTypes.string,
  listingLinkedIn: PropTypes.string,
};

export default ListingSocialLink;
