/* eslint-disable consistent-return */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import BusinessInfo from "./business_info/BusinessInfo";
import Coupon from "./coupon/Coupon";
import OwnerInfo from "./owner_info/OwnerInfo";
import Badge from "./badge/Badge";
import BusinessAddress from "./business_address/BusinessAddress";
import Photos from "./photos/Photos";
import BusinessHours from "./business_hours/BusinessHours";
import Price from "./price/Price";
import PaymentOption from "./payment_option/PaymentOption";
import useLocalStorage from "../../../hooks/useLocalStorage";
import Review from "./review/Review";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function AddListing() {
  useScrollPage();
  //useTitle("Add Listing(s)");

  const STEPS = ["Business Information", "Owner Information", "Coupon Information", "Business Address", "Photos", "Business Hours", "Review"];
  const [currentStep, setCurrentStep] = React.useState(0);
  const [listing, setListing] = useLocalStorage("listing", {
    businessInfo: null,
    ownerInfo: null,
    coupon: null,
    address: null,
    photos: null,
    businessHours: null,
    // price: null,
    // paymentOptions: null,
    // badges: null,
  });

  const renderComponent = currentStep => {
    switch (currentStep) {
      case 0:
        return <BusinessInfo steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      case 1:
        return <OwnerInfo steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} ownerTitle={"Business"}/>;
      case 2:
        return <Coupon steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      case 3:
        return <BusinessAddress steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      case 4:
        return <Photos steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      case 5:
        return <BusinessHours steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      // case 6:
      //   return <Price steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      // case 7:
      //   return <PaymentOption steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      // case 8:
      //   return <Badge steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} setListing={setListing} listing={listing} />;
      case 6:
        return <Review steps={STEPS} currentStep={currentStep} setCurrentStep={setCurrentStep} listing={listing} />;
      default:
        break;
    }
  };
  return (
    <div className="max-w-full sm:max-w-7xl sm:mx-auto sm:px-6 sm:lg:px-8">
      <div className="mt-8 space-y-5">
        <div className="flex gap-3 items-center justify-center">
          <span className="text-sm text-gray-400">{`Step ${currentStep + 1} of ${STEPS.length}`}</span>
          <h1 className="text-md text-success-800">{STEPS[currentStep]}</h1>
        </div>
        {renderComponent(currentStep)}
      </div>
      {/* <section className="pt-12">
        <Footer />
      </section> */}
    </div>
  );
}

export default AddListing;
