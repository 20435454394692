/* eslint-disable capitalized-comments */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import DefaultState from "../../../components/default_state/DefaultState";
import Spinner from "../../../components/spinner/Spinner";
import notify from "../../../services/toast";
import MemberCard from "./../../../components/member_card/MemberCard";
import useUserContext from "../../../hooks/useUserContext";
import getMembers from "./getMembers";
import Pagination from "../../../components/pagination/Pagination";
import { DEFAULT_PAGINATION_LIMIT, MAGIC_NUMBERS } from "../../../constant";
import { debounce } from "../../../services/util";
import searchMember from "./searchMember";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function Member() {
  useScrollPage();
  useTitle("All COhatch+ Members");
  
  const { userState } = useUserContext();
  const [members, setMembers] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [mode, setMode] = React.useState("default");
  const [query, setQuery] = React.useState("");

  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 10 });

  const getAllMembers = async (value, page, limit, accessToken) => {
    setFetching(true);
    try {
      setMode("default");
      const response = await getMembers(value, page, limit, accessToken);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination(prevState => {
          return { ...prevState, limit, page: page, remaining, total };
        });
        setMembers(results);
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const handleSearch = async event => {
    try {
      const query = event.target.value;
      setQuery(query);
      setSearching(true);
      const response = await searchMember(userState.accessToken, query, 0, 10);
      setMode("search");
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination(prevState => {
          return { ...prevState, limit, page: page, remaining, total };
        });
        setMembers(results);
      }
    } catch (error) {
      notify("Something went wrong. Please try again try", "error");
    } finally {
      setSearching(false);
    }
  };

  const handlePagination = async (accessToken, query, page, limit) => {
    try {
      const response = await searchMember(accessToken, query, page, limit);
      setMode("search");
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination(prevState => {
          return { ...prevState, limit, page: page, remaining, total };
        });
        setMembers(results);
      }
    } catch (error) {
      notify("Something went wrong. Please try again try", "error");
    }
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = React.useCallback(() => {
    if (mode === "default") {
      getAllMembers({ plan: "COHATCH,PREMIUM" }, pagination.page - 1, pagination.limit, userState.accessToken);
    } else {
      handlePagination(userState.accessToken, query, pagination.page - 1, pagination.limit);
    }
  }, [pagination]);

  const handleNextFetch = React.useCallback(() => {
    if (mode === "default") {
      getAllMembers({ plan: "COHATCH,PREMIUM" }, pagination.page + 1, pagination.limit, userState.accessToken);
    } else {
      handlePagination(userState.accessToken, query, pagination.page + 1, pagination.limit);
    }
  }, [pagination]);

  React.useEffect(() => {
    getAllMembers({ plan: "COHATCH,PREMIUM" }, 0, 12, userState.accessToken);
  }, []);
  return (
    <div className="h-full w-full">
      <h1 className="text-2xl font-bold leading-7 text-primary-400 font-bebas sm:text-4xl sm:truncate text-center">
						Member Directory
					</h1>
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full mt-10 flex justify-center md:justify-start items-center gap-5 flex-wrap">
          <div className="w-full">
            <div className="mt-1">
              <input
                onInput={debouncedHandleSearch}
                type="text"
                name="search"
                id="search"
                placeholder="Search all members"
                className="shadow-sm focus:ring-primary-400 focus:border-primary-400 block w-full max-w-md p-3 sm:text-sm border-gray-300 rounded-full"
              />
            </div>
          </div>
          {fetching || searching ? (
            <Spinner displayText="Fetching..." />
          ) : (
            <ul role="list" className="relative w-full grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {members.length ? (
                members.map((member, index) => <MemberCard key={index} member={member} />)
              ) : (
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <DefaultState icon="person" heading="No Members Found" description="Premium and COhatch members will appear here" />
                </div>
              )}
            </ul>
          )}
        </div>
        <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      </div>
    </div>
  );
}

export default Member;
