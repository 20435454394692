/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "./../../components/logo/Logo";
import useNotificationContext from "../../hooks/useNotificationContext";
import useUserContext from "../../hooks/useUserContext";
import useLogout from "./../../hooks/useLogout";
import { Link } from "react-router-dom";
import useWebsocket from "../../hooks/useWebsocket";
import OfficerndUpdateModal from "../../components/officernd_update_modal/OfficerndUpdateModal";
import useScrollPage from "../../hooks/useScrollPage";
import { PLANS } from "../../constant";
import NewDashboardNavbar from "../../components/new-dashboard-navbar/NewDashboardNavbar";

function getUnreadNotifications(unreadNotifications) {
  if (unreadNotifications < 0) {
    return "0";
  } else if (unreadNotifications > 100) {
    return "100+";
  } else {
    return unreadNotifications;
  }
}

function Dashboard() {
  useScrollPage();

  const {
    userState,
    subscription: { product },
  } = useUserContext();
  const socket = React.useRef(useWebsocket(userState.accessToken));
  const { unreadNotifications, setUnreadNotifications } = useNotificationContext();
  const { photo } = userState;
  const [loggingOut, setLoggingOut] = React.useState(false);
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const [handleLogout] = useLogout(setLoggingOut);
  const mobileNavigationRef = React.useRef(null);
  const toggleMobileMenu = () => {
    mobileNavigationRef.current.classList.toggle("hidden");
  };

  React.useEffect(() => {
    socket.current.on("notification", () => {
      setUnreadNotifications((prev) => prev + 1);
    });

    return () => {
      socket.current.disconnect();
    };
  }, [socket]);

  return (
    <div>
      <NewDashboardNavbar />
      
      {/* <div className={`hidden md:flex ${isFullScreen ? "md:w-[55px]" : "md:w-64"} md:flex-col md:fixed md:inset-y-0 duration-300`}>
        <div className="flex flex-col flex-grow pt-5 bg-success-800 overflow-y-auto">
          {!isFullScreen && <div className="flex items-center flex-shrink-0 px-4 mb-3 ml-auto">
            <button onClick={() => setIsFullScreen(true)} type="button" className="">
              <span className="material-icons-outlined text-white">keyboard_double_arrow_left</span>
            </button>
          </div>}
          {!isFullScreen && <div className="flex items-center flex-shrink-0 px-4 mb-5">
            <Logo color="white" />
          </div>}
          <div className="flex-1 flex flex-col">
            <DashboardNavigation
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
            />
          </div>
        </div>
      </div> */}
      <div className={`${isFullScreen ? "md:pl-[55px]" : "md:pl-0"} flex flex-col flex-1 duration-300`}>
        <Outlet />
      </div>
      <OfficerndUpdateModal />
    </div>
  );
}

export default Dashboard;
