/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import io from "socket.io-client";
import config from "../config";
import useUserContext from "./useUserContext";

function useWebsocket(accessToken) {
  const socket = io(config["SOCKET_BASE_ENDPOINT"], { path: "/socket/", query: { token: accessToken }, transports: ["websocket", "polling"] });
  const { userState } = useUserContext();
  const [isConnected, setIsConnected] = React.useState(socket.connected);
  const [lastPong, setLastPong] = React.useState(null);

  React.useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
      socket.emit("join", { room: userState.id });
    });

    socket.on("disconnect", () => {
      console.log("socket disconnected");
      setIsConnected(false);
    });

    socket.on("pong", () => {
      setLastPong(new Date().toISOString());
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
      socket.disconnect();
    };
  }, []);

  return socket;
}

export default useWebsocket;
