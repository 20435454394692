/* eslint-disable no-console */
/* eslint-disable  */
/* eslint-disable react/prop-types */
import React from "react";
import { FaWindowClose } from "react-icons/fa";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import getReaction from "./getReaction";

function ReactionModal({ postId }, ref) {
  const { userState } = useUserContext();
  const [loveReactions, setLoveReactions] = React.useState([]);
  const [likeReactions, setLikeReactions] = React.useState([]);
  const [angryReactions, setAngryReactions] = React.useState([]);
  const [happyReactions, setHappyReactions] = React.useState([]);
  const [wowReactions, setWowReactions] = React.useState([]);
  const [sadReactions, setSadReactions] = React.useState([]);

  const [currentReaction, setCurrentReaction] = React.useState("ALL");
  const [open, setOpen] = React.useState(false);
  React.useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  const handleGetReactionStat = async (accessToken, type, postId, setter) => {
    try {
      const response = await getReaction(accessToken, type, postId);
      if (response.status === "success") {
        setter(response.data);
      } else {
        notify(`Unable to get reactions for ${type}`);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const displayReactors = reaction => {
    let reactors = [];
    switch (reaction) {
      case "LIKE":
        reactors = likeReactions;
        break;
      case "LOVE":
        reactors = loveReactions;
        break;
      case "ANGRY":
        reactors = angryReactions;
        break;
      case "HAHA":
        reactors = happyReactions;
        break;
      case "WOW":
        reactors = wowReactions;
        break;
      case "SAD":
        reactors = sadReactions;
        break;
      case "ALL":
        reactors = [...likeReactions, ...loveReactions, ...angryReactions, ...happyReactions, ...wowReactions, ...sadReactions];
        break;
      default:
        reactors = [];
    }
    return reactors;
  };

  React.useEffect(() => {
    handleGetReactionStat(userState.accessToken, "LOVE", postId, setLoveReactions);
    handleGetReactionStat(userState.accessToken, "LIKE", postId, setLikeReactions);
    handleGetReactionStat(userState.accessToken, "ANGRY", postId, setAngryReactions);
    handleGetReactionStat(userState.accessToken, "HAHA", postId, setHappyReactions);
    handleGetReactionStat(userState.accessToken, "WOW", postId, setWowReactions);
    handleGetReactionStat(userState.accessToken, "SAD", postId, setSadReactions);
  }, []);

  return (
    <div className={`${open ? "block" : "hidden"} fixed z-50 inset-0 overflow-y-auto`}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-between">
              <h1 className="text-3xl text-gray-900 font-bold">Reactions</h1>
              <button
                className="focus:outline-none"
                onClick={() => {
                  setOpen(false);
                  setCurrentReaction("ALL");
                }}
              >
                <FaWindowClose className="h-8 w-8 text-gray-700" aria-hidden="true" alt="Close" />
              </button>
            </div>

            <div className="flex justify-around mt-6">
              {["ALL", "LIKE", "LOVE", "HAHA", "WOW", "SAD", "ANGRY"].map(reaction => (
                <div
                  key={reaction}
                  className={`flex items-center cursor-pointer ${
                    currentReaction === reaction ? "border-b-4 border-blue-500" : ""
                  }`}
                  onClick={() => setCurrentReaction(reaction)}
                > {reaction === "ALL" ? (
                  <span className="text-xl mr-2">All</span>
                ) : (
                  <span className="text-2xl mr-2">
                    {reaction === "LIKE" ? "👍" : reaction === "LOVE" ? "❤️" : reaction === "HAHA" ? "😆" : reaction === "WOW" ? "😮" : reaction === "SAD" ? "😢" : reaction === "ANGRY" ? "😡" : ""}
                  </span>
                    )}
                  {reaction !== "ALL" && <span className="text-xs">{displayReactors(reaction).length}</span>}
                  {reaction === "ALL" && <span className="text-xs">{displayReactors(reaction).length}</span>}
                </div>
              ))}
            </div>

            <hr className="my-6" />

            <div className="overflow-y-auto max-h-72">
              {displayReactors(currentReaction).map(reactor => (
                <div key={reactor._id} className="flex items-center mb-4">
                 <img
                        src={reactor?.user?.photo?.small ? reactor.user.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                        alt="Profile photo"
                        className="inline-block rounded-full border border-3 object-cover h-[64px] w-[64px]"
                      />
                  <span className="ml-2 text-sm text-gray-700">
                    {reactor.user.firstName} {reactor.user.lastName}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(ReactionModal);
