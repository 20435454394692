/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { useSearchParams } from "react-router-dom";
import DefaultState from "../../components/default_state/DefaultState";
import MarketplaceNavigation from "../../components/marketplace_navigation/MarketplaceNavigation";
import MarketplaceSearch from "../../components/marketplace_search/MarketplaceSearch";
import Pagination from "../../components/pagination/Pagination";
import SearchResult from "../../components/search_result/SearchResult";
import { DEFAULT_PAGINATION_LIMIT } from "../../constant";
import searchAll from "./searchAll";
import useScrollPage from "../../hooks/useScrollPage";

function Search() {
  useScrollPage();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q");
  const [executionTime, setExecutionTime] = React.useState("0");
  const [listings, setListings] = React.useState([]);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: DEFAULT_PAGINATION_LIMIT });

  const searchAllMarket = async (query, offset, limit) => {
    try {
      const startTime = performance.now();
      const response = await searchAll(query, offset, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination((prevState) => {
          return { ...prevState, limit, page: page, remaining, total };
        });
        setListings(results);
        const endTime = performance.now();
        const timeToExecute = (endTime - startTime) / 1000;
        setExecutionTime(timeToExecute.toFixed(5));
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrevFetch = () => {
    searchAllMarket(query, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = () => {
    searchAllMarket(query, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    if (!query) {
      searchAllMarket("", 0, DEFAULT_PAGINATION_LIMIT);
      return;
    }
    searchAllMarket(query, 0, DEFAULT_PAGINATION_LIMIT);
  }, [query]);

  return (
    <div className="w-full">
      {/* <div className="sm:sticky sm:top-20 z-10 h-full w-full flex justify-start p-5 border-b-4 bg-white">
        <MarketplaceSearch placeholder="Search for local business, services and more..." query={query} />
      </div> */}
      <div className="w-full p-5">
        {listings.length ? <h1 className="text-success-800 text-sm">{`About ${pagination.total} results (${executionTime} seconds)`}</h1> : null}
        <div className="mt-10 w-full flex">
          <div className="w-full h-screen space-y-5">
            <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
            {listings.length ? (
              listings.map((listing, index) => {
                return <SearchResult key={index} listing={listing} />;
              })
            ) : (
              <DefaultState icon="travel_explore" heading="No Result Found" description={`${query} did not return any results`} />
            )}
            <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
