/* eslint-disable  */
import React from "react";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import { PhotoIcon } from "@heroicons/react/24/solid";
import createImpactStory from "./createImpactStory";
import notify from "../../../services/toast";
import useUserContext from "../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import FileUploader from "../../../components/file_uploader/FileUploader";
import { MdLocalSee, MdDeleteOutline } from "react-icons/md";
import { StarOutline } from "heroicons-react";

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}


function ImpactSurvey() {
	const { userState } = useUserContext();
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [orgName, setOrgName] = React.useState("");
	const [support, setSupport] = React.useState("");
	const [amountUsed, setAmountUsed] = React.useState("");
	const [howWeHelped, setHowWeHelped] = React.useState("");
	const [metrics, setMetrics] = React.useState("");
	const [feedback, setFeedback] = React.useState("");
  const [blogTitle, setBlogTitle] = React.useState("");
  const [orgDescription, setOrgDescription] = React.useState("");
  const [coverImage, setCoverImage] = React.useState("");
  const [storyImages, setStoryImages] = React.useState([]);
	const [submitting, setSubmitting] = React.useState(false);


  const imageCriteria = { requiredSize: 6e6, requiredDimensions: { width: 400, height: 400 } };

   const handleUpload = result => {
    setCoverImage({
      coverImage: result
    });
  };

   const handleImageUpload = (result) => {
    setStoryImages((prevState) => {
      return {
        ...prevState,
        storyImages: [...(prevState?.storyImages || []), { ...result, type: "image" }],
     };
   });
  };

   const handleDeleteImage = (idx) => {
    setStoryImages((prevState) => {
      return {
        ...prevState,
        storyImages: (prevState?.storyImages || []).filter((image, index) => index !== idx),
     };
   });
  };


	

	const handleSurveySubmit = async (event) => {
		event.preventDefault();
		try {
			setSubmitting(true);
			const payload = {
        blogTitle,
				firstName,
				lastName,
				email,
				orgName,
        orgDescription,
        storyImages,
        coverImage,
				prompts: {
					support: support,
					amountUsed: amountUsed,
					howWeHelped: howWeHelped,
					metrics: metrics,
					feedback: feedback,
				},
			};
			const response = await createImpactStory(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmitting(false);
        setBlogTitle("");
				setFirstName("");
        setLastName(""); 
        setEmail("");
				setOrgName("");
        setOrgDescription("");
        setStoryImages("");
        setCoverImage("");
				setSupport("");
				setAmountUsed("");
				setHowWeHelped("");
				setMetrics("");
				setFeedback("");
				notify("Story submitted successfully");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later");
		}
	};
 console.log(coverImage);
	return (
		<>
			<div className="bg-white rounded-md mt-5 pt-5 pb-10 text-center">
				<h1 className="mb-5 text-orange-700 font-bebas text-3xl">Story Time</h1>
				<h1 className="mb-5 font-bold text-3xl">We'd love to hear from you!</h1>
				<p>
					Every year, we want to know how we helped you achieve your mission.
					fill out this questionnaire and drop a story to move onto the next step of
					the scholarship process. For those renewing your scholarship, this is
					a required step in order to be renewed for the next scholarship year.
				</p>

				<div>
					<div className="mx-6 lg:grid lg:grid-cols-4">
						<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-8">
							<div className="text-left max-w-lg mx-auto lg:max-w-none">
								<div className="w-full grid grid-cols-2 text-left gap-4 gap-y-6">
                  <div className="w-full flex ">
										<div className="w-full">
											<label
												htmlFor="firstName"
												className="block text-lg font-medium text-black-700"
											>
												 Title
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="firstName"
												id="firstName"
												value={blogTitle}
												onChange={(event) => setBlogTitle(event.target.value)}
											/>
										</div>
									</div>
                 
                   <div className="w-full flex ">
										<div className="w-full">
										
										</div>
									</div>
                  
									<div className="w-full flex ">
										<div className="w-full">
											<label
												htmlFor="firstName"
												className="block text-lg font-medium text-black-700"
											>
												First name
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="firstName"
												id="firstName"
												value={firstName}
												onChange={(event) => setFirstName(event.target.value)}
											/>
										</div>
									</div>

									<div className="w-full flex gap-3">
										<div className="w-full">
											<label
												htmlFor="lastName"
												className="block text-lg font-medium text-black-700"
											>
												Last name
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="lastName"
												id="lastName"
												value={lastName}
												onChange={(event) => setLastName(event.target.value)}
											/>
										</div>
									</div>

									<div className="w-full flex gap-3">
										<div className="w-full">
											<label
												htmlFor="email"
												className="block text-lg font-medium text-black-700"
											>
												Email
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="email"
												id="email"
												value={email}
												onChange={(event) => setEmail(event.target.value)}
											/>
										</div>
									</div>

									<div className="w-full flex gap-3 mt-5">
										<div className="w-full">
											<label
												htmlFor="orgName"
												className="block text-lg font-medium text-black-700"
											>
												Your Organization Name
											</label>
										
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="orgName"
												id="orgName"
												value={orgName}
												onChange={(event) => setOrgName(event.target.value)}
											/>
										</div>
									</div>
								</div>
                <div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="amountUsed"
											className="block text-lg font-medium text-black-700"
										>
											Brief description of your organization
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="amountUsed"
											id="amountUsed"
											value={orgDescription}
											onChange={(event) => setOrgDescription(event.target.value)}
										/>
									</div>
								</div>


								<div className="w-full flex gap-3 mt-5">
									<div className="w-full">
										<label
											htmlFor="support"
											className="block text-lg font-medium text-black-700"
										>
											How did you use COhatch this year to support your
											organization?
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="support"
											id="support"
											value={support}
											onChange={(event) => setSupport(event.target.value)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="amountUsed"
											className="block text-lg font-medium text-black-700"
										>
											How many times did you use a COhatch location this year?
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="amountUsed"
											id="amountUsed"
											value={amountUsed}
											onChange={(event) => setAmountUsed(event.target.value)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="metrics"
											className="block text-lg font-medium text-black-700"
										>
											Did COhatch help you create connections, receive funding,
											or any other related metrics this year?
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="metrics"
											id="metrics"
											value={metrics}
											onChange={(event) => setMetrics(event.target.value)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="howWeHelped"
											className="block text-lg font-medium text-black-700"
										>
											Please add details related to how we helped with your
											network, funding/investment, etc. here at COhatch.
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<textarea
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="howWeHelped"
											id="howWeHelped"
											value={howWeHelped}
											onChange={(event) => setHowWeHelped(event.target.value)}
										/>
									</div>
								</div>
						

								<div className="w-full flex gap-3 pt-5">
									<div className="w-full">
										<label
											htmlFor="feedback"
											className="block text-lg font-medium text-black-700"
										>
											What would you say about COhatch and this scholarship? Please be descriptive.
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<textarea
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="feedback"
											id="feedback"
											value={feedback}
											onChange={(event) => setFeedback(event.target.value)}
										/>
									</div>
								</div>

                  <div className="mt-10 text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Story Images</h3>
        </div>
        <div className="col-span-3">
          <div className="w-full flex justify-between items-center">
            <label className="block text-sm font-medium text-gray-700 mb-1">Cover Image</label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
          <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
            <div>
           {coverImage.coverImage?.small.url && <img src={coverImage.coverImage?.small.url} className="w-full h-full" alt="story cover image upload" /> }
           </div>
            <FileUploader
              fileType="image/*"
              isMultiple={false}
              validationCriteria={imageCriteria}
              DisplayIcon={PhotoDisplay}
              accessToken={userState.accessToken}
              folder="story_photos"
              description="Image - Min: 400 X 400, Max: 5MB"
              numberOfFiles={1}
              callback={handleUpload}
            />
            
          </div>
          </div>
          <div className="col-span-3">


    <div className="col-span-3 mt-4">
      <div className="w-full flex justify-between items-center mb-1">
        <label className="block text-sm font-medium text-gray-700">Story Images</label>
        <span className="w-2 h-2 rounded-full bg-red-500"></span>
      </div>
      <p className="mt-1 text-sm text-gray-500">Exactly 5 images required</p>
      <div className="flex mb-8 mt-2">
        {storyImages.storyImages?.map((image, index) => <div key={image.small.url} className="mr-2 w-24 text-center">
          <img src={image.small.url} className="w-full h-full" alt="property image" />
          <div className="text-center">
            <MdDeleteOutline className="mt-1 text-red-400 cursor-pointer mx-auto" onClick={() => handleDeleteImage(index)} />
          </div>
        </div>)}
      </div>
          </div>
          <div className="col-span-3">
          <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
            <FileUploader
              fileType="image/*"
              isMultiple={true}
              validationCriteria={imageCriteria}
              DisplayIcon={PhotoDisplay}
              className="text-2xl text-primary-400 cursor-pointer"
              accessToken={userState.accessToken}
              folder="story_photos"
              description="400 X 400 8MB Image Minimum and you have to add exactly 5 photos"
              numberOfFiles={5}
              callback={handleImageUpload}
            />
          </div>
          </div>
      </div>

								<div className="mt-4 h-20 w-40">
									<ButtonPrimary
										text={"Submit"}
										size="md"
										action={handleSurveySubmit}
									/>
								</div>
						
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ImpactSurvey;
