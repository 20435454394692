/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Toggle from "../../../components/toggle/Toggle";
import useUserContext from "./../../../hooks/useUserContext";
import handleUpdatePrivacy from "./handleUpdatePrivacy";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "./../../../services/toast";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function Privacy() {
  useScrollPage();
  useTitle("COhatch+ Privacy");
  
  const { userState, privacy, setPrivacy } = useUserContext();
  const [personalEmailAddress, setPersonalEmailAddress] = React.useState(privacy?.privacy?.personalEmailAddress ? privacy.privacy.personalEmailAddress : null);
  const [bio, setBio] = React.useState(privacy?.privacy?.bio ? privacy.privacy.bio : null);
  const [personalPhoneNumber, setPersonalPhoneNumber] = React.useState(privacy?.privacy?.personalPhoneNumber ? privacy.privacy.personalPhoneNumber : null);
  const [businessEmailAddress, setBusinessEmailAddress] = React.useState(privacy?.privacy?.businessEmailAddress ? privacy.privacy.businessEmailAddress : null);
  const [businessPhoneNumber, setBusinessPhoneNumber] = React.useState(privacy?.privacy?.businessPhoneNumber ? privacy.privacy.businessPhoneNumber : null);
  const [businessReview, setBusinessReview] = React.useState(privacy?.privacy?.businessReview ? privacy.privacy.businessReview : null);

  const updatePrivacy = async data => {
    
    try {
      const payload = {
        ...privacy.privacy,
        ...data,
      };
      const response = await handleUpdatePrivacy(payload, userState.accessToken);

      if (response.status === "success") {
        setPrivacy(prevState => {
          return { ...prevState, ...response.data };
        });
        notify("Your privacy settings were updated. Changes will take effect immediately.", "info");
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
        <div className="w-full py-5">
          <h1 className="text-lg text-success-800 font-bold">Personal Information</h1>
          <p className="text-sm text-gray-500">This information is public by default and displayed on your personal profile. You can choose to turn these off.</p>
          <ul role="list" className="divide-y divide-gray-200">
            <li className="py-4">
              <div className="w-full bg-white rounded-md p-3 flex justify-between items-center">
                <div>
                  <h1 className="text-md text-success-800 font-bold">Email Address</h1>
                </div>
                <Toggle action={setPersonalEmailAddress} state={personalEmailAddress} update={() => updatePrivacy({ personalEmailAddress: !personalEmailAddress })} />
              </div>
            </li>

            <li className="py-4">
              <div className="w-full bg-white rounded-md p-3 flex justify-between items-center">
                <div>
                  <h1 className="text-md text-success-800 font-bold">Bio</h1>
                </div>
                <Toggle action={setBio} state={bio} update={() => updatePrivacy({ bio: !bio })} />
              </div>
            </li>

            <li className="py-4">
              <div className="w-full bg-white rounded-md p-3 flex justify-between items-center">
                <div>
                  <h1 className="text-md text-success-800 font-bold">Phone Number</h1>
                </div>
                <Toggle action={setPersonalPhoneNumber} state={personalPhoneNumber} update={() => updatePrivacy({ personalPhoneNumber: !personalPhoneNumber })} />
              </div>
            </li>
          </ul>
        </div>

        <div className="w-full">
          <h1 className="text-lg text-success-800 font-bold">Business Listing Information</h1>
          <p className="text-sm text-gray-500">Your business email address and phone number are public by default and visible to anyone who is able to see your listing.</p>
          <ul role="list" className="divide-y divide-gray-200">
            <li className="py-4">
              <div className="w-full bg-white rounded-md p-3 flex justify-between items-center">
                <div>
                  <h1 className="text-md text-success-800 font-bold">Email Address</h1>
                </div>
                <Toggle action={setBusinessEmailAddress} state={businessEmailAddress} update={() => updatePrivacy({ businessEmailAddress: !businessEmailAddress })} />
              </div>
            </li>

            <li className="py-4">
              <div className="w-full bg-white rounded-md p-3 flex justify-between items-center">
                <div>
                  <h1 className="text-md text-success-800 font-bold">Phone Number</h1>
                </div>
                <Toggle action={setBusinessPhoneNumber} state={businessPhoneNumber} update={() => updatePrivacy({ businessPhoneNumber: !businessPhoneNumber })} />
              </div>
            </li>

            <li className="py-4">
              <div className="w-full bg-white rounded-md p-3 flex justify-between items-center">
                <div>
                  <h1 className="text-md text-success-800 font-bold">Business Review</h1>
                </div>
                <Toggle action={setBusinessReview} state={businessReview} update={() => updatePrivacy({ businessReview: !businessReview })} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  );
}

export default Privacy;