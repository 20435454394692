/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { MdPermMedia, MdBackup } from "react-icons/md";
import { readAndValidateAppFiles, readAndValidateImageFile, readAndValidateVideoFile } from "./fileValidation";
import notify from "./../../services/toast";

function UploadButton({ fileType, isMultiple, validationCriteria, setQueue, setReadingProgress, description, numberOfFiles }, ref) {
  const uploaded = React.useRef(0);
  const fileInputRef = React.useRef(null);
  const formRef = React.useRef(null);
  const handleButtonClick = React.useCallback(() => fileInputRef.current.click(), []);

  React.useImperativeHandle(ref, () => ({
    openFileExplorer: () => fileInputRef.current.click(),
    resetFileInput: () => {
      formRef.current.reset();
      fileInputRef.current.value = "";
      uploaded.current = 0;
    },
    updateUploads: () => {
      uploaded.current -= 1;
    },
  }));

  const filterFiles = (files) => {
    const arrayOfImages = files.filter((file) => file.type.startsWith("image"));
    const arrayOfVideos = files.filter((file) => file.type.startsWith("video"));
    return { arrayOfImages, arrayOfVideos };
  };

  const handleFileInputChange = React.useCallback((event) => {
    const files = Array.from(event.target.files);
    uploaded.current += files.length;
    if (uploaded.current > numberOfFiles) {
      notify(`You can't upload more than ${numberOfFiles} file(s).`, "error");
      uploaded.current -= files.length; //Reset the number of uploads
      return;
    }
    const { arrayOfImages, arrayOfVideos } = filterFiles(files);
    if (fileType === "image/*") {
      uploaded.current = arrayOfImages.length;
      readAndValidateImageFile(arrayOfImages, validationCriteria, setReadingProgress, setQueue);
    } else if (fileType === "video/*") {
      uploaded.current = arrayOfVideos.length;
      readAndValidateVideoFile(arrayOfVideos, validationCriteria, setReadingProgress, setQueue);
    } else if (fileType === "app/*") {
      uploaded.current = arrayOfImages.length;
      readAndValidateAppFiles(arrayOfVideos, setReadingProgress, setQueue);
    }
  }, []);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <form ref={formRef}>
        <input onChange={handleFileInputChange} ref={fileInputRef} type="file" name="upload" accept={fileType} multiple={isMultiple} className="hidden" />
        <div className="flex flex-col items-center space-y-2">
          <MdBackup className="text-center text-gray-400 text-7xl" />
          <button
            onClick={handleButtonClick}
            type="button"
            className="inline-flex items-center gap-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition-all duration-500"
          >
            <MdPermMedia className="text-lg" />
            Browse local device
          </button>
          <p className="text-sm text-gray-400">{description}</p>
          {isMultiple && <p className="text-center text-sm text-gray-400 font-bold">{numberOfFiles} upload(s) remaining</p>}
        </div>
      </form>
    </div>
  );
}

export default React.memo(React.forwardRef(UploadButton));
