/* eslint-disable */
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {format} from "date-fns";
import useUserContext from "../../hooks/useUserContext";
import { inArrayObject } from "../../services/util";

function DriveCard({ index, event }) {
const navigate = useNavigate();

  const eventInfo = {
    eventId: event._id,
    eventName: event.eventName,
    eventDate: event.eventDate,
    eventImage: event.eventImage,
    streetAddress: event.eventLocation.streetAddress,
    city: event.eventLocation.city,
    state: event.eventLocation.state,
    zip: event.eventLocation.zip,
    link: event.eventLink,
    ownerImage: event.eventOwnerImage,
    eventOwnerFirstName: event.eventOwnerFirstName,
    eventOwnerLastName: event.eventOwnerLastName,
    aboutOrganization: event.aboutOrganization,
    userId: event.userId,
    userRSVP: event.userRSVP,
    eventDescription: event.eventDescription
  }

  function handleClick() {
		
		navigate("/dashboard/philanthropy/event_details", {
			state: {
				eventInfo,
			},
		});
	}
  const {
    userState,
  } = useUserContext();
  //const [isFavorite, setIsFavorite] = useState(inArrayObject(event?.favorites || [], userState.id, "creator"));

   const handleCreateFavorite = async () => {
    try {
      const payload = {
        kind: "Drive",
        target: event._id,
      };
      const response = await createFavorite(payload, userState.accessToken);
      if (response.status === "success") {
        setIsFavorite(response.data);
        if (refetchFavorites) {
          refetchFavorites();
        }
      } else {
        notify("An error occurred while creating favorite. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };


  return (
    <>
    <Link to={`event_details/${event._id}`}>
				<div 	key={index} className="flex hover:drop-shadow-xl shadow-xl rounded bg-white p-4 flex-col items-start justify-between">
          {/* <div>
            <span
              onClick={handleCreateFavorite}
              className={`material-icons-outlined text-2xl cursor-pointer hover:text-primary-400 transition-all duration-500 ${isFavorite ? "text-primary-400" : "text-gray-400"}`}
            >
            favorite
            </span>
            </div> */}
            
									<div className="relative w-full">
										<img
										 src={event.eventImage?.large?.url ? event.eventImage.large.url : "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"}
											alt=""
											className="aspect-[16/9] w-full rounded bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
										/>
									</div>
									<div className="w-full bg-white p-5 space-y-3">
										{/* <div className="mt-8 flex items-center gap-x-4 text-xs">
											<time dateTime={event.eventDate} className="text-gray-500">
											</time>
										</div> */}
									<div className="w-full bg-white p-5 space-y-3">
						<div className="w-full flex items-center gap-2">
							<h1 className="text-xl text-success-800 font-bold">
								{event.eventName}
							</h1>
						</div>
						<div className="w-full flex items-center gap-2">
							<h1 className="text-m text-success-800 capitalize text-ellipsis overflow-hidden whitespace-nowrap">
								<time>
                    {format(new Date(event.eventDate), "PPPP")}
                </time>
							</h1>
						</div>
											<p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
												{event.eventDescription}
											</p>
										</div>
										<div className="flex justify-between mb-1">
											<span className="text-bold font-bebas  dark:text-white">
												Raised: ${event.donation.amountRaised} 
											</span>
                      <span className="text-bold font-bebas  dark:text-white">
												Target: 	<span className="text-sm font-medium font-bebas text-gray-400 dark:text-white">
												${event.donation.goal} 
											</span>
                        </span>
										
										</div>
									
									
         
										</div>
                    
									</div>
						
					
               </Link>
					</>
  );
              }

  export default DriveCard;