import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";
async function getMostRecentVendor(authToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/marketplace_feed/latest_listing`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getMostRecentVendor;
