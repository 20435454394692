/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function getConversation(accessToken, conversationId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/messaging/conversation/single/${conversationId}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getConversation;
