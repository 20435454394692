/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { Link } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import useLogout from "./../../hooks/useLogout";

function NavigationAuth() {
  const { userState } = useUserContext();
  const [loggingOut, setLoggingOut] = React.useState(false);

  const [handleLogout] = useLogout(setLoggingOut);
  if (userState?.accessToken && userState?.status === "REQUIRES_SUBSCRIPTION") {
    return (
      <>
        <Link to={loggingOut ? "" : "/logout"} onClick={handleLogout} className="text-lg font-bold text-white hover:text-gray-200 flex gap-1 items-center transition ease-linear duration-500">
          <span className="material-icons-outlined text-2xl">login</span>
          Logout
        </Link>
        <Link
          to={loggingOut ? "" : "/plan"}
          className="ml-8 inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-bold text-primary-400 bg-white outline hover:text-primary-500 hover:bg-gray-200 transition ease-linear duration-500 gap-2 items-center"
        >
          <span className="material-icons-outlined text-2xl">add_shopping_cart</span>
          Subscribe
        </Link>
      </>
    );
  } else if (userState?.accessToken && (userState?.status === "REQUIRES_EMAIL_VERIFICATION" || userState?.status === "ACTIVE")) {
    return (
      <>
        <Link to={loggingOut ? "" : "/logout"} onClick={handleLogout} className="text-lg font-bold text-white hover:text-gray-200 flex gap-1 items-center transition ease-linear duration-500">
          <span className="material-icons-outlined text-2xl">login</span>
          Logout
        </Link>
        <Link
          to={loggingOut ? "" : "/dashboard"}
          className="ml-8 inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-bold text-primary-400 bg-white outline hover:text-primary-500 hover:bg-gray-200 transition ease-linear duration-500 gap-2 items-center"
        >
          <span className="material-icons-outlined text-2xl">dashboard</span>
          Dashboard
        </Link>
      </>
    );
  } else {
    return (
      <>
        <Link to="/login" className="text-lg font-bold text-white hover:text-gray-200 flex gap-1 items-center transition ease-linear duration-500">
          <span className="material-icons-outlined text-2xl">login</span>
          Login
        </Link>
        <Link
          to="/signup"
          className="ml-8 inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-bold text-primary-400 bg-white outline hover:text-primary-500 hover:bg-gray-200 transition ease-linear duration-500 gap-2 items-center"
        >
          <span className="material-icons-outlined text-2xl">person_add</span>
          Signup
        </Link>
      </>
    );
  }
}

export default NavigationAuth;
