/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function handleUpdateEmailPreference(payload, authToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/user/email_preference`;
  const response = await makeAPIRequest(API_ENDPOINT, "PUT", payload, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default handleUpdateEmailPreference;
