/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
import config from "../../../config";
import { MAGIC_NUMBERS } from "../../../constant";
import makeAPIRequest from "../../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/group`;

async function getGroups(query, accessToken) {
  let url = `${API_ENDPOINT}?true=true`;
  Object.keys(query).forEach(key => {
    url += `&${key}=${query[key]}`;
  });
  const response = await makeAPIRequest(url, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getGroups;
