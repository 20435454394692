/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { MdClear, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Media from "./../multimedia/MultiMedia";

function LightBox({ medias, initial }) {
  const [opened, setOpened] = React.useState(false);
  const [currentMedia, setCurrentMedia] = React.useState(0);

  const handleNext = () => {
    if (currentMedia >= medias.length - 1) {
      setCurrentMedia(0);
      return;
    }
    setCurrentMedia(prev => prev + 1);
  };

  const handlePrev = () => {
    if (currentMedia <= 0) {
      setCurrentMedia(medias.length - 1);
      return;
    }
    setCurrentMedia(prev => prev - 1);
  };
  return (
    <>
      {!opened ? (
        <div className="w-full h-full cursor-pointer" onClick={() => setOpened(true)}>
          {initial}
        </div>
      ) : (
        <div className="w-screen h-screen bg-black text-white fixed top-0 left-0 flex items-center justify-center z-10">
          <span className="flex justify-end">
            <MdClear className="text-4xl  cursor-pointer absolute top-5 right-5" onClick={() => setOpened(false)} />
          </span>
          <Media media={medias[currentMedia]} />
          {medias.length > 1 ? (
            <div className="w-full max-w-4xl flex justify-between items-center absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4">
              <MdKeyboardArrowLeft className="text-4xl  cursor-pointer " onClick={handlePrev} />
              <MdKeyboardArrowRight className="text-4xl  cursor-pointer" onClick={handleNext} />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default LightBox;
