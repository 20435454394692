/* eslint-disable no-console */
/* eslint-disable id-length */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import useUserContext from "../../../hooks/useUserContext";
import useNotificationUpdate from "../../../hooks/useNotificationUpdate";

function build(notification) {
  if (notification.type === "GROUP_ROLE") {
    return {
      heading: "Member role update",
      description: `Your role for ${notification.data.groupName.toUpperCase()} group has been updated to ${notification.data.update.role.toUpperCase()}`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "MEMBER_INVITE_REJECTED") {
    return {
      heading: "Rejected group invite",
      description: `A user rejected your invite to join ${notification.data.groupName.toUpperCase()}`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "MEMBER_INVITE_ACCEPTED") {
    return {
      heading: "Accepted group invite",
      description: `A user accepted your invite to join ${notification.data.groupName.toUpperCase()}`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "GROUP_MEMBER_REMOVED") {
    return {
      heading: "Removed from group",
      description: `Your have been removed from ${notification.data.groupName.toUpperCase()}. Contact the group administrator for more details.`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "GROUP_JOIN_REQUEST_ACCEPTED") {
    return {
      heading: "Accepted group request",
      description: `Your request to join ${notification.data.groupName.toUpperCase()} was successfully accepted.`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "GROUP_JOIN_REQUEST_REJECTED") {
    return {
      heading: "Rejected group request",
      description: `Your request to join ${notification.data.groupName.toUpperCase()} was rejected. You can always request to join again.`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "JOIN_GROUP") {
    return {
      heading: "New member request",
      description: `A new user wants to join ${notification.data.groupName.toUpperCase()}. Follow the link to accept or deny this request.`,
      link: "/dashboard/neighborhood/member_request",
    };
  } else if (notification.type === "NEW_POST") {
    return {
      heading: "New post",
      description: `${notification.data.firstName.toUpperCase()} ${notification.data.lastName.toUpperCase()} created a new post in ${notification.data.groupName.toUpperCase()}. Follow the link to check it out`,
      link: notification.data.postId ? `/dashboard/post/${notification.data.postId}` : `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "NEW_POST_COMMENT") {
    return {
      heading: "New comment",
      description: `${notification.data.firstName.toUpperCase()} ${notification.data.lastName.toUpperCase()} created a new comment for a post in ${notification.data.groupName.toUpperCase()}. Follow the link to check it out`,
      link: notification.data.postId ? `/dashboard/post/${notification.data.postId}` : `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "NEW_POST_REACTION") {
    return {
      heading: "New post reaction",
      description: `${notification.data.firstName.toUpperCase()} ${notification.data.lastName.toUpperCase()} created a new reaction for a post in ${notification.data.groupName.toUpperCase()}. Follow the link to check it out`,
      link: notification.data.postId ? `/dashboard/post/${notification.data.postId}` : `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "TAGGED") {
    return {
      heading: "Tagged in a post",
      description: `${notification.data.firstName.toUpperCase()} ${notification.data.lastName.toUpperCase()} tagged you in a post for ${notification.data.groupName.toUpperCase()}. Follow the link to check it out`,
      link: notification.data.postId ? `/dashboard/post/${notification.data.postId}` : `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "GROUP_REJECTED") {
    return {
      heading: "Group rejected",
      description: `${notification.data.reason}`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "GROUP_ACCEPTED") {
    return {
      heading: "Group accepted",
      description: `Your group ${notification.data.groupName.toUpperCase()} has been approved`,
      link: `/feed/${notification.data.groupId}`,
    };
  } else if (notification.type === "PROPERTY_REJECTED") {
    return {
      heading: "Property rejected",
      description: `${notification.data.reason}`,
    };
  } else if (notification.type === "PROPERTY_ACCEPTED") {
    return {
      heading: "Property accepted",
      description: `Your property ${notification.data.propertyName.toUpperCase()} has been approved`,
      link: `/home_details/${notification.data.propertyId}`,
    };
  } else if (notification.type === "PROPERTY_CREATED") {
    return {
      heading: "Property created",
      description: `Your property ${notification.data.propertyName.toUpperCase()} has been sent off for approval and will be reviewed soon. Check back in 1-2 business days!`,
    };
  } else if (notification.type === "PROPERTY_DELETED") {
    return {
      heading: "Property deleted",
      description: `Your property ${notification.data.propertyName.toUpperCase()} has been deleted and cannot be found again.`,
    };
  } else if (notification.type === "PROPERTY_UPDATED") {
    return {
      heading: "Property updated",
      description: `Your property ${notification.data.propertyName.toUpperCase()} details are updated.`,
    };
  } else if (notification.type === "PROPERTY_REQUEST_SENT") {
    return {
      heading: "Property request booking sent",
      description: "Your request has been sent.",
      link: `/home_details/${notification.data.propertyId}`,
    };
  } else if (notification.type === "PROPERTY_REQUEST_RECEIVED") {
    return {
      heading: "Property request booking received",
      description: "You've received a booking request.",
      link: `/home_details/${notification.data.propertyId}`,
    };
  } else if (notification.type === "LISTING_REVIEW") {
    let notificationLink = "";
    if (!notification.data.target || notification.data.target === "listing") {
      notificationLink = `/marketplace/listing/${notification.data.listingId}`;
    }
    return {
      heading: `${notification.data.listingName.toUpperCase()} ${notification.data.target || "listing"} review`,
      description: `You have a new ${notification.data.target || "listing"} review: ${notification.data.reviewerReview} by ${notification.data.reviewerFirstName.toUpperCase()} ${notification.data.reviewerLastName.toUpperCase()}`,
      link: notificationLink,
    };
  }
}

function GroupMemberStatus({ notification }) {
  const { userState } = useUserContext();
  const [read, setRead] = React.useState(notification.read);
  const _ = useNotificationUpdate(userState.accessToken, notification.id, { update: { read: true } }, read, setRead);
  return (
    <div className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600">
      <div className="flex justify-between space-x-3">
        <div className="min-w-0 flex-1">
          <span className="flex items-center gap-2 focus:outline-none">
            <p className="text-md font-bold text-gray-900 truncate">{build(notification).heading}</p>
            {!read ? <div className="w-2 h-2 bg-primary-400 rounded-full"></div> : null}
          </span>
        </div>
        <time dateTime="2021-01-27T16:35" className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
          {format(new Date(notification.createdAt), "iii, MMMM do, hh:mm a")}
        </time>
      </div>
      <div className="mt-1">
        <p className="line-clamp-2 text-sm text-gray-600">{build(notification).description}</p>
      </div>
      <div className="mt-1">
        {build(notification)?.link ? (
          <Link to={build(notification)?.link} className="text-primary-400 cursor-pointer underline mt-10">
            view
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default GroupMemberStatus;
