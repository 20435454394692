import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function getListingNearMe(authToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/marketplace_feed/listing_near`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getListingNearMe;
