/* eslint-disable id-length */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import acceptInvite from "./acceptInvite";
import declineInvite from "./declineInvite";
import updateNotification from "./../updateNotification";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import useNotificationUpdate from "../../../hooks/useNotificationUpdate";
import useWebsocket from "../../../hooks/useWebsocket";

function GroupInvitation({ notification, reload }) {
  const { userState } = useUserContext();
  const socket = React.useRef(useWebsocket(userState.accessToken));
  const [read, setRead] = React.useState(notification.read);
  const _ = useNotificationUpdate(userState.accessToken, notification.id, { update: { read: true } }, read, setRead);
  const handleInviteAccept = async () => {
    try {
      const payload = {
        update: {
          status: "ACTIVE",
        },
        type: "invite_accept",
        groupName: notification.data.groupName,
      };

      const notificationPayload = {
        update: {
          data: {
            memberId: notification.data.memberId,
            groupId: notification.data.groupId,
            groupName: notification.data.groupName,
            creator: notification.data.creator,
            action: true,
          },
        },
      };
      const response = await Promise.all([
        await acceptInvite(userState.accessToken, notification.data.groupId, notification.data.creator, payload),
        await updateNotification(userState.accessToken, notification.id, notificationPayload),
      ]);
      if (response[0].status === "success" && response[0].status === "success") {
        socket.current.emit("group_invitation_accepted", { recipientId: notification.data.creator });
        reload();
        notify("Your invite was successfully accepted.", "info");
      } else {
        notify("An error occurred while accepting in the invite.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleInviteReject = async () => {
    try {
      const notificationPayload = {
        update: {
          data: {
            memberId: notification.data.memberId,
            groupId: notification.data.groupId,
            groupName: notification.data.groupName,
            creator: notification.data.creator,
            action: true,
          },
        },
      };
      const response = await Promise.all([
        await declineInvite(userState.accessToken, notification.data.groupId, notification.data.creator, "invite_reject", notification.data.groupName),
        await updateNotification(userState.accessToken, notification.id, notificationPayload),
      ]);
      if (response[0].status === "success" && response[0].status === "success") {
        socket.current.emit("group_invitation_rejected", { recipientId: notification.data.creator });
        reload();
        notify("Your invite was declined.", "info");
      } else {
        notify("An error occurred while rejecting this invite. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  return (
    <li className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600">
      <div className="flex justify-between space-x-3">
        <div className="min-w-0 flex-1">
          <span className="focus:outline-none flex items-center gap-2">
            <p className="text-md font-bold text-gray-900 truncate">Group Invitation</p>
            {!read ? <div className="w-2 h-2 bg-primary-400 rounded-full"></div> : null}
          </span>
        </div>
        <time dateTime="2021-01-27T16:35" className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
          {format(new Date(notification.createdAt), "iii, MMMM do, hh:mm a")}
        </time>
      </div>
      <div className="mt-1">
        <p className="line-clamp-2 text-sm text-gray-600">{notification.data.groupName.toUpperCase()} Invited you to become a member.</p>
      </div>
      {!notification.data.action ? (
        <div className="mt-1 flex justify-start gap-4">
          <span onClick={handleInviteAccept} className="material-icons-outlined text-green-500 hover:text-green-600 cursor-pointer">
            done
          </span>
          <span onClick={handleInviteReject} className="material-icons-outlined text-red-500 hover:text-red-600 cursor-pointer">
            close
          </span>
        </div>
      ) : null}
    </li>
  );
}

export default GroupInvitation;
