/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import HomeNavigation from "../../components/home_navigation/HomeNavigation";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { generateClassName } from "../../services/util";
import { validateName, validateEmail, validatePhone, validateMessage } from "../../services/validation";
import Notification from "./../../components/notification/Notification";
import { showMessage } from "./../../services/util";
import submitContactForm from "./submitForm";
import { MAGIC_NUMBERS } from "../../constant";
import notify from "../../services/toast";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";

function ContactUs() {
  useScrollPage();
  useTitle("COhatch+ Support");

  const [firstName, setFirstName] = React.useState("");
  const [validFirstName, setValidFirstName] = React.useState(false);

  const [lastName, setLastName] = React.useState("");
  const [validLastName, setValidLastName] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(false);

  const [phone, setPhone] = React.useState("");
  const [validPhone, setValidPhone] = React.useState(false);

  const [message, setMessage] = React.useState("");
  const [validMessage, setValidMessage] = React.useState(false);

  const [submitting, setSubmitting] = React.useState(false);
  const notificationRef = React.useRef(null);
  const [displayMessage, setDisplayMessage] = React.useState({ heading: "", body: "", type: "" });

  React.useEffect(() => {
    const result = validateName(firstName);
    setValidFirstName(result);
  }, [firstName]);

  React.useEffect(() => {
    const result = validateName(lastName);
    setValidLastName(result);
  }, [lastName]);

  React.useEffect(() => {
    const result = validateEmail(email);
    setValidEmail(result);
  }, [email]);

  React.useEffect(() => {
    const result = validatePhone(phone);
    setValidPhone(result);
  }, [phone]);

  React.useEffect(() => {
    const result = validateMessage(message);
    setValidMessage(result);
  }, [message]);

  const handleSubmitContactForm = async event => {
    event.preventDefault();
    try {
      setSubmitting(true);
      if (!validFirstName || !validLastName || !validEmail || !validMessage) {
        notify("One or more fields are invalid. Check your email address and try again.", "error");
        return;
      }

      if (phone && !validatePhone(phone)) {
        notify("Invalid phone number. It must be a 10 digit US phone number.", "error");
        return;
      }

      const payload = {
        firstName,
        lastName,
        emailAddress: email,
        phoneNumber: phone,
        message,
      };
      const response = await submitContactForm(payload);
      if (response.status === "success") {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
        notify("Your message was successfully submitted. Our team will reach out to you as soon as possible.", "info");
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="w-full">
      <HomeNavigation />
      <div className="bg-success-800">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">We&apos;d love to hear from you!</p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-200">If you have any questions or need help, please fill out the form below. We do our best to respond within 1-2 business days.</p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2"></div>
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className=" py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Get in touch</h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">We’re here to help! We look forward to hearing from you.</p>
              <dl className="mt-8 text-base text-gray-500">
                
                
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <Link to="mailto:support@cohatch.com" className="ml-3">
                      support@cohatchplus.com
                    </Link>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form onSubmit={handleSubmitContactForm} className="w-full grid grid-cols-1 gap-y-6">
                <div className="w-full flex gap-3">
                  <div className="w-full">
                    <label htmlFor="first_name" className="sr-only">
                      First name
                    </label>
                    <input
                      onChange={event => setFirstName(event.target.value)}
                      type="text"
                      name="first_name"
                      id="first_name"
                      autoComplete="off"
                      value={firstName}
                      className={generateClassName(!validFirstName && firstName)}
                      placeholder="Mary"
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="last_name" className="sr-only">
                      Last name
                    </label>
                    <input
                      onChange={event => setLastName(event.target.value)}
                      type="text"
                      name="last_name"
                      id="last_name"
                      autoComplete="off"
                      value={lastName}
                      className={generateClassName(!validLastName && lastName)}
                      placeholder="Jane"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    onChange={event => setEmail(event.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="off"
                    value={email}
                    className={generateClassName(!validEmail && email)}
                    placeholder="mary.jane@gmail.com"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <p className="text-sm text-gray-400 text-right">optional</p>
                  <input
                    onChange={event => setPhone(event.target.value)}
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    value={phone}
                    className={generateClassName(!validPhone && phone)}
                    placeholder="Phone"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    onChange={event => setMessage(event.target.value)}
                    id="message"
                    name="message"
                    rows="4"
                    value={message}
                    className={generateClassName(!validMessage && message)}
                    placeholder="Message"></textarea>
                </div>
                <div>
                  <ButtonPrimary text={submitting ? "Please wait..." : "Submit"} size="md" loading={!validFirstName || !validLastName || !validEmail || (!validMessage && submitting) ? true : false} />
                </div>
              </form>
            </div>
          </div>
        </div>
        <Notification header={displayMessage.heading} body={displayMessage.body} type={displayMessage.type} ref={notificationRef} />
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
