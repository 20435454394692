/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { MdDeleteOutline } from "react-icons/md";
import BreadCrumb from "../../components/bread_crumb/BreadCrumb";
import useUserContext from "../../hooks/useUserContext";
import Pagination from "../../components/pagination/Pagination";
import { DEFAULT_PAGINATION_LIMIT, MAGIC_NUMBERS } from "./../../constant";
import notify from "./../../services/toast";
import { showPrompt } from "./../../services/util";
import Spinner from "./../../components/spinner/Spinner";
import getNotification from "./getNotification";
import GroupInvitation from "../../components/notification_type/group_invitation/GroupInvitation";
import GroupMemberStatus from "../../components/notification_type/group_member_status/GroupMemberStatus";
import DefaultState from "../../components/default_state/DefaultState";
import Message from "../../components/notification_type/message/Message";
import deleteNotification from "../../components/notification_type/deleteNotification";
import deleteAllNotifications from "./deleteAllNotifications";
import Prompt from "../../components/prompt/Prompt";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";

const directions = [
  { pathName: "Dashboard", url: "/dashboard" },
  { pathName: "Notification", url: "" },
];

const availableNotificationTypes = ["JOIN_GROUP", "GROUP_ROLE", "GROUP_MEMBER_REMOVED", "GROUP_JOIN_REQUEST_ACCEPTED", "GROUP_JOIN_REQUEST_REJECTED",
  "MEMBER_INVITE_ACCEPTED", "MEMBER_INVITE_REJECTED", "NEW_POST", "NEW_POST_COMMENT", "NEW_POST_REACTION", "TAGGED", "GROUP_REJECTED", "GROUP_ACCEPTED", "LISTING_REVIEW",
  "GROUP_INVITATION", "NEW_MESSAGE", "PROPERTY_REJECTED", "PROPERTY_ACCEPTED", "PROPERTY_REQUEST_SENT", "PROPERTY_REQUEST_RECEIVED",
  "PROPERTY_CREATED", "PROPERTY_DELETED", "PROPERTY_UPDATED"];

function determineNotification(notification, index, reload) {
  if (notification.type === "GROUP_INVITATION") {
    return <GroupInvitation key={index} notification={notification} reload={reload} />;
  } else if (
    notification.type === "JOIN_GROUP" ||
    notification.type === "GROUP_ROLE" ||
    notification.type === "GROUP_MEMBER_REMOVED" ||
    notification.type === "GROUP_JOIN_REQUEST_ACCEPTED" ||
    notification.type === "GROUP_JOIN_REQUEST_REJECTED" ||
    notification.type === "MEMBER_INVITE_ACCEPTED" ||
    notification.type === "MEMBER_INVITE_REJECTED" ||
    notification.type === "NEW_POST" ||
    notification.type === "NEW_POST_COMMENT" ||
    notification.type === "NEW_POST_REACTION" ||
    notification.type === "TAGGED" ||
    notification.type === "GROUP_REJECTED" ||
    notification.type === "GROUP_ACCEPTED" ||
    notification.type === "PROPERTY_REJECTED" ||
    notification.type === "PROPERTY_ACCEPTED" ||
    notification.type === "PROPERTY_REQUEST_SENT" ||
    notification.type === "PROPERTY_REQUEST_RECEIVED" ||
    notification.type === "PROPERTY_CREATED" ||
    notification.type === "PROPERTY_DELETED" ||
    notification.type === "PROPERTY_UPDATED" ||
    notification.type === "LISTING_REVIEW"
  ) {
    return <GroupMemberStatus key={index} notification={notification} />;
  } else if (notification.type === "NEW_MESSAGE") {
    return <Message key={index} notification={notification} />;
  }
}

function Notification() {
  useScrollPage();
  useTitle("Notifications");
  const { userState } = useUserContext();
  const [notifications, setNotifications] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: DEFAULT_PAGINATION_LIMIT });
  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });
  const [deletingNotification, setDeletingNotification] = React.useState(false);
  const [id, setId] = React.useState("");

  const fetchNotifications = async (accessToken, offset, limit) => {
    try {
      setFetching(true);

      const response = await getNotification(accessToken, offset, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setNotifications(results);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const reload = () => {
    fetchNotifications(userState.accessToken, 0, DEFAULT_PAGINATION_LIMIT);
  };

  const handlePrevFetch = async () => {
    await fetchNotifications(userState.accessToken, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = async () => {
    await fetchNotifications(userState.accessToken, pagination.page + 1, pagination.limit);
  };

  const handleShowPrompt = (id) => {
    setId(id);
    showPrompt(
      id ? "Delete Notification" : "Delete All Notifications",
      id ? "Are you sure you want to delete this notification?" : "Are you sure you want to delete all notifications?",
      promptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const handleDelete = async () => {
    try {
      setDeletingNotification(true);
      const response = id ? await deleteNotification(userState.accessToken, id) : await deleteAllNotifications(userState.accessToken);
      if (response.status === "success") {
        notify("Your delete notification request was successful.", "info");
        reload();
        promptRef.current.closePrompt();
      } else {
        let displayMessage = "";
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setDeletingNotification(false);
    }
  };

  React.useEffect(() => {
    fetchNotifications(userState.accessToken, 0, DEFAULT_PAGINATION_LIMIT);
  }, []);

  return (
    <div className="p-5 space-y-2">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">Notifications</h1>
      </div>
      <BreadCrumb directions={directions} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {notifications.length ? (
          <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
            <div className="text-right">
              <MdDeleteOutline className="text-red-400 cursor-pointer ml-auto" onClick={() => handleShowPrompt("")} />
            </div>
            <ul role="list" className="divide-y divide-gray-200">
              <>
                {fetching ? (
                  <Spinner displayText="Loading..." />
                ) : (
                  notifications.map((notification, index) => {
                    if (availableNotificationTypes.indexOf(notification.type) === -1) {
                      return null;
                    }
                    return (<div className="flex justify-between items-center" key={index}>
                      {determineNotification(notification, index, reload)}
                      <MdDeleteOutline className="text-red-400 cursor-pointer" onClick={() => handleShowPrompt(notification.id)} />
                    </div>);
                  })
                )}
              </>
            </ul>
            <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
          </div>
        ) : (
          <div className="mt-20 w-full">
            <DefaultState icon="notifications" heading="No Notifications Found" description="All notifications appear here." />
          </div>
        )}
      </div>
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={handleDelete} isExecuting={deletingNotification} />
    </div>
  );
}

export default Notification;
