import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MultiRangeSlider = ({ min, max, value, onChange }) => {
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(value.min);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [value.min, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(value.max);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [value.max, getPercent]);

  return (
    <div className="slider-container">
      <input
        type="range"
        min={min}
        max={max}
        value={value.min}
        ref={minValRef}
        onChange={(event) => {
          const newValue = Math.min(+event.target.value, value.max - 1);
          onChange({
            min: newValue,
            max: value.max,
          });
          event.target.value = value.toString();
        }}
        className={`thumb thumb--zindex-3 ${value.min > max - 100 ? "thumb--zindex-5" : ""}`}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={value.max}
        ref={maxValRef}
        onChange={(event) => {
          const newValue = Math.max(+event.target.value, value.min + 1);
          onChange({
            min: value.min,
            max: newValue
          });
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{value.min}</div>
        <div className="slider__right-value">{value.max}</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
