/* eslint-disable capitalized-comments */
/* eslint-disable id-length */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { validateEmail, validateName, validateMessage } from "./../../services/validation";
import submitMessageForm from "./submitMessageForm";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS, PLANS } from "./../../constant";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import notify from "../../services/toast";
import { encrypt } from "../../services/util";
import useWebsocket from "../../hooks/useWebsocket";

function MessageForm({ listing }) {
  const {
    userState,
    subscription: { product },
  } = useUserContext();
  const socket = React.useRef(useWebsocket(userState.accessToken));
  const { _id: listingId, userId } = listing;
  const [visitorFirstName, setVisitorFirstName] = React.useState(userState?.accessToken ? userState.firstName : "");
  const [visitorLastName, setVisitorLastName] = React.useState(userState?.accessToken ? userState.lastName : "");
  const [visitorEmail, setVisitorEmail] = React.useState(userState?.accessToken ? userState.emailAddress : "");
  const [visitorMessage, setVisitorMessage] = React.useState("");

  const [visitorFirstNameValid, setVisitorFirstNameValid] = React.useState(false);
  const [visitorLastNameValid, setVisitorLastNameValid] = React.useState(false);
  const [visitorEmailValid, setVisitorEmailValid] = React.useState(false);
  const [visitorMessageValid, setVisitorMessageValid] = React.useState(false);

  const [submittingMessage, setSubmittingMessage] = React.useState(false);

  React.useEffect(() => {
    const result = validateName(visitorFirstName);
    setVisitorFirstNameValid(result);
  }, [visitorFirstName]);

  React.useEffect(() => {
    const result = validateName(visitorLastName);
    setVisitorLastNameValid(result);
  }, [visitorLastName]);

  React.useEffect(() => {
    const result = validateEmail(visitorEmail);
    setVisitorEmailValid(result);
  }, [visitorEmail]);

  React.useEffect(() => {
    const result = validateMessage(visitorMessage);
    setVisitorMessageValid(result);
  }, [visitorMessage]);

  const handleMessageSubmit = async event => {
    event.preventDefault();
    setSubmittingMessage(true);
    try {
      if (!visitorFirstName || !visitorLastName || !visitorEmail || !visitorMessage) {
        notify("One or more fields are invalid. Please review and try again.", "error");
        return;
      }
      const payload = {
        message: visitorMessage,
        listingId,
        to: userId,
        target: "listing",
      };
      const response = await submitMessageForm(userState.accessToken, payload);
      if (response.status === "success") {
        setVisitorMessage("");
        const { recipientId, senderId, id } = response.data;
        const payload = {
          to: recipientId,
          from: senderId,
          conversationId: id,
          textMessage: visitorMessage ? encrypt(visitorMessage) : null,
          mediaMessage: null,
          target: "listing",
        };
        socket.current.emit("private_message", payload, response => {
          if (response.ok) {
            notify("Message sent successfully.", "info");
          }
        });
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setSubmittingMessage(false);
    }
  };

  const generateClassName = valid => {
    if (!valid) {
      return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md";
    } else {
      return "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-400 focus:border-red-400 sm:text-md";
    }
  };

  return (
    <>
      {userState.id === userId || !userState.accessToken || !["PREMIUM", "COHATCH"].includes(PLANS[product]) ? null : (
        <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
          <h1 className="text-xl text-success-800 font-bold m-2">Send Message</h1>
          <form className="mt-4 w-full space-y-3" onSubmit={handleMessageSubmit}>
            <div className="w-full flex gap-3">
              <div className="w-full">
                <div className="mt-1">
                  <input
                    onChange={event => setVisitorFirstName(event.target.value)}
                    type="text"
                    name="first_name"
                    id="first_name"
                    disabled={userState?.accessToken ? true : false}
                    value={visitorFirstName}
                    autoComplete="off"
                    className={generateClassName(!visitorFirstNameValid && visitorFirstName)}
                    placeholder="John"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mt-1">
                  <input
                    onChange={event => setVisitorLastName(event.target.value)}
                    type="text"
                    name="last_name"
                    id="last_name"
                    disabled={userState?.accessToken ? true : false}
                    value={visitorLastName}
                    autoComplete="off"
                    className={generateClassName(!visitorLastNameValid && visitorLastName)}
                    placeholder="Deo"
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="mt-1">
                <input
                  onChange={event => setVisitorEmail(event.target.value)}
                  type="email"
                  name="email"
                  id="email"
                  disabled={userState?.accessToken ? true : false}
                  value={visitorEmail}
                  autoComplete="off"
                  className={generateClassName(!visitorEmailValid && visitorEmail)}
                  placeholder="john.deo@gmail.com"
                />
              </div>
              <p className="mt-2 text-sm text-gray-500" id="email-description">
              Email address only used to ensure you’re not a robot!
              </p>
            </div>
            <div>
              <div className="mt-1">
                <textarea
                  onChange={event => setVisitorMessage(event.target.value)}
                  rows="4"
                  name="review"
                  id="review"
                  value={visitorMessage}
                  autoComplete="off"
                  className={generateClassName(!visitorMessageValid && visitorMessage)}></textarea>
                <p className="mt-2 text-sm text-gray-500" id="email-description">
                  2500 characters maximum
                </p>
              </div>
            </div>
            <div>
              <ButtonPrimary
                text={submittingMessage ? "Please wait..." : "Send Message"}
                size="md"
                loading={!visitorFirstNameValid || !visitorLastNameValid || !visitorEmailValid || !visitorMessageValid || submittingMessage ? true : false}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default MessageForm;
