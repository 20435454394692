/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function getListingsByCategory(category, offset, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/listing/category/${category}?page=${offset}&limit=${limit}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getListingsByCategory;
