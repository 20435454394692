/*eslint-disable  */
import React, {Fragment} from "react";
import { useNavigate } from "react-router-dom";
import useScrollPage from "../../../hooks/useScrollPage";
import Footer from "../../../components/footer/Footer";
import useTitle from "../../../hooks/useTitle";
import MarketplaceSearch from "../../../components/marketplace_search/MarketplaceSearch";
import Typewriter from "typewriter-effect/dist/core";
import { NavLink } from "react-router-dom";
import { FaHeart, FaHandHoldingHeart } from "react-icons/fa";
//import { Carousel } from "react-responsive-carousel";
import { GiShoppingBag } from "react-icons/gi";
import { RiCalendarEventFill } from "react-icons/ri";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import { Carousel } from "react-responsive-carousel";
import getFeaturedStories from "./getFeaturedStories";
import notify from "../../../services/toast";
import useUserContext from "../../../hooks/useUserContext";
import StoryCard from "../impact_stories/StoryCard";
import getFeaturedVolunteerEvents from "./getFeaturedVolunteerEvents";
import VolunteerCard from "../../../components/philanthropy/VolunteerCard";
import getFeaturedDriveEvents from "./getFeaturedDriveEvents";
import DriveCard from "../../../components/philanthropy/DriveCard";


function PhilanthropyFeed() {
	useScrollPage();
	useTitle("Philanthropy Feed");
	const navigate = useNavigate();
  const { userState } = useUserContext();
  const [featuredStory, setFeaturedStories] = React.useState([]);
  const [fetchingStory, setFetchingStories] = React.useState(false);
  const [fetchingVolunteerEvents, setFetchingVolunteerEvents] = React.useState(false);
  const [featuredVolunteerEvents, setFeaturedVolunteerEvents] = React.useState([]);
  const [featuredDriveEvents, setFeaturedDriveEvents] = React.useState([]);
  const [fetchingDriveEvents, setFetchingDriveEvents] = React.useState(false);
	const typerRef = React.useRef(null);

function onClick() {
		navigate("impact_stories");
	}
   const fetchFeaturedStories = async (accessToken) => {
      
            try {
        setFetchingStories(true);
        const response = await getFeaturedStories(accessToken);

        if (response.status === "success") {
          
          console.log(response.data);
          setFeaturedStories(response.data);
        }
      } catch (error) {
        notify("Something went wrong. Please try again later", "error");
      } finally {
        setFetchingStories(false);
      }
    };

    //  fetchfeaturedDrives
      const fetchFeaturedVolunteerEvents = async (accessToken) => {
      
            try {
        setFetchingVolunteerEvents(true);
        const response = await getFeaturedVolunteerEvents(accessToken);

        if (response.status === "success") {
          
          console.log("event", response.data);
          setFeaturedVolunteerEvents(response.data);
        }
      } catch (error) {
        notify("Something went wrong. Please try again later", "error");
      } finally {
        setFetchingVolunteerEvents(false);
      }
    };

     const fetchFeaturedDriveEvents = async (accessToken) => {
      
            try {
        setFetchingDriveEvents(true);
        const response = await getFeaturedDriveEvents(accessToken);

        if (response.status === "success") {
          
          console.log("event", response.data);
          setFeaturedDriveEvents(response.data);
        }
      } catch (error) {
        notify("Something went wrong. Please try again later", "error");
      } finally {
        setFetchingDriveEvents(false);
      }
    };
   
    // fetch recent impact feed post using splice function

	React.useEffect(() => {
		new Typewriter(typerRef.current, {
			strings: ["Local Nonprofits", "Your Community", "Eachother"],
			autoStart: true,
			loop: true,
		});
	}, []);

   React.useEffect(() => {
      fetchFeaturedStories(userState.accessToken);
      fetchFeaturedVolunteerEvents(userState.accessToken);
      fetchFeaturedDriveEvents(userState.accessToken);
    }, []);
console.log("storyinfo", featuredStory)
	return (
		<>
   <div className="">
			<header className="relative h-[500px] w-full flex flex-col items-center justify-center bg-[url('https://impact.cohatch.com/wp-content/uploads/sites/7/2021/12/06.09.21_Boost-Meet-Greet-2-800x533.jpg')] bg-no-repeat bg-cover">
				<div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
				<div className="z-10 flex flex-col">
					<h1 className="text-center text-4xl tracking-tight sm:text-2xl lg:text-6xl font-bebas">
						<span className="block text-white">
						Our Impact
							
						</span>
					</h1>
				
				</div>

			</header>

      {/* Menu  */}
			<div className="relative sm:py-32 lg:py-8">
				<div className="mx-auto text-center ">
					<div className="grid grid-cols-1  sm:grid-cols-3 lg:grid-cols-4">
						<div className="pt-6">
							<div className="rounded-lg ">
								<div className="-mt-6">
									<NavLink
										className="border-primary-400 text-primary-400"
										key=""
										to="nonprofits"
									>
										<span className="inline-flex items-center justify-center rounded-xl text-primary-400 p-3">
											<FaHeart className="h-36 w-36  text-success-800 hover:text-primary-400" />
										</span>
										<h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
											378 Nonprofits on Scholarships
										</h3>
									</NavLink>
								</div>
							</div>
						</div>

						<div className="pt-6">
							<div className="flow-root rounded-lg px-6 pb-8">
								<div className="-mt-6">
									<NavLink
										className="border-primary-400 text-primary-400"
										key=""
										to="volunteer"
									>
										<span className="inline-flex items-center justify-center rounded-xl text-success-800 p-3">
											<FaHandHoldingHeart
												className="h-36 w-36 hover:text-primary-400"
												aria-hidden="true"
											/>
										</span>

										<h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
											30+ Community Renovations
										</h3>
									</NavLink>
								</div>
							</div>
						</div>

						<div className="pt-6">
							<div className="flow-root rounded-lg px-6 pb-8">
								<div className="-mt-6">
									<NavLink
										className="border-primary-400 text-primary-400"
										key=""
										to="drives"
									>
										<div>
											<span className="inline-flex items-center justify-center rounded-xl text-success-800 p-3">
												<GiShoppingBag
													className="h-36 w-36 hover:text-primary-400"
													aria-hidden="true"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
											178 startups on scholarship
										</h3>
									</NavLink>
								</div>
							</div>
						</div>

						<div className="pt-6">
							<div className="flow-root rounded-lg px-6 pb-8">
								<div className="-mt-6">
									<nav>
										<NavLink
											className="border-primary-400 text-primary-400"
											key=""
											to="member_events"
										>
											<div>
												<span className="inline-flex items-center justify-center rounded-xl text-success-800 p-3">
													<RiCalendarEventFill
														className="h-36 w-36 hover:text-primary-400"
														aria-hidden="true"
													/>
												</span>
											</div>
											<h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
												Events
											</h3>
										</NavLink>
									</nav>
								</div>
							</div>
						</div>
						{/* <div className="pt-6">
							<div className="rounded-lg ">
								<div className="-mt-6">
									<NavLink
										className="border-primary-400 text-primary-400"
										key=""
										to="impact"
									>
										<span className="inline-flex items-center justify-center rounded-xl text-primary-400 p-3">
											<img
												src={
													userState.photo?.small?.url
														? userState.photo.small.url
														: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"
												}
												className="inline-block mx-5 rounded-full border border-6 object-coverh-36 w-36 "
											/>
										</span>
										<h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
											My Impact
										</h3>
									</NavLink>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>

      	{/* Feed */}
						
							
{/*Featured Section */}
			<section>

			
			</section>

		

			
      </div>


		</>
	);
}

export default PhilanthropyFeed;
