import React from "react";
import { Outlet } from "react-router-dom";
import BreadCrumb from "./../../components/bread_crumb/BreadCrumb";
import useScrollPage from "../../hooks/useScrollPage";
import Video from "../../components/video/Video";
import StartupVideo from "../../assets/StartupVideo.mp4";
import StartupCover from "../../assets/StartupCoverImage.png";

const directions = [
  { pathName: "Dashboard", url: "/dashboard" },
  { pathName: "Startup Club", url: "" },
];


function LocalDiscount() {
  useScrollPage();
  return (
    <div className="p-5 space-y-2">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">Startup - COMING SOON</h1>
      </div>
      <BreadCrumb directions={directions} />
      <Outlet />
      <Video
      className=""
            source= {StartupVideo}
            type="video/mp4"
            poster={StartupCover}
          />
      
    </div>
  );
}

export default LocalDiscount;
