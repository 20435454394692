/* eslint-disable */
import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactstory`;

async function createImpactStory(payload, accessToken) {
	
	const response = await makeAPIRequest(
		API_ENDPOINT,
		"POST",
		payload,
		accessToken
	);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default createImpactStory;
