/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, ChevronDownIcon, PhoneIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon, ChartPieIcon, CursorArrowRaysIcon, FingerPrintIcon, SquaresPlusIcon, ArrowPathIcon, ListBulletIcon } from "@heroicons/react/24/outline";
import { FaListAlt, FaIdCard, FaCalendarCheck,  } from "react-icons/fa";
import { MdDiscount, MdAnalytics, MdOutlineFavorite, MdAddBusiness, MdBusinessCenter, MdOutlineEventNote, MdVolunteerActivism, MdOutlinePersonPin, MdBusiness } from "react-icons/md";
import {GiOpenedFoodCan} from "react-icons/gi";
import {AiFillFileText} from "react-icons/ai";
import { SiSemanticscholar } from "react-icons/si";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import { PLANS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import useWebsocket from "../../hooks/useWebsocket";
import useNotificationContext from "../../hooks/useNotificationContext";
import useLogout from "../../hooks/useLogout";
import Logo from "../logo/Logo";
import MarketplaceSearch from "../marketplace_search/MarketplaceSearch";

function getUnreadNotifications(unreadNotifications) {
  if (unreadNotifications < 0) {
    return "0";
  } else if (unreadNotifications > 100) {
    return "100+";
  } else {
    return unreadNotifications;
  }
}


// const navigation = [
//   { pathName: "Dashboard", url: " " },
//   // { pathName: "Local Discounts", url: "local_discount" },
//   { pathName: "Member Directory", url: "marketplace" },
//   { pathName: "Business Hub", url: "business_hub" },
//   // { pathName: "Neighborhood", url: "neighborhood" },
//   // { pathName: "Vacation Discounts", url: "vacation_homes" },
//   { pathName: "Health Benefits", url: "health_and_wellness" },
//   { pathName: "Workshops", url: "workshop" },
//   // { pathName: "Book at COhatch", url: "life_perks" },
//   // { pathName: "Meeting Rooms", url: "meeting_rooms" },
//   { pathName: "Volunteer", url: "volunteer" },
//   { pathName: "Startups", url: "startup_club" },
//   // { pathName: "Investor Community", url: "investor_club" },
// ];

const directory = [
  { pathName: "Business Directory", description: "See all the businesses inside the COhatch ecosystem", url: "/dashboard/marketplace", icon: FaListAlt },
  { pathName: "Member Directory", description: "Connect with COhatch members across the country", url: "/dashboard/marketplace/members", icon: FaIdCard },
  { pathName: "Local Discounts", description: "Get discounts at local businesses near you", url: "/dashboard/local_discount", icon: MdDiscount },
  { pathName: "Favorites", description: "See your list of favorite businesses and discounts", url: "/dashboard/marketplace/favorite_listing", icon: MdOutlineFavorite },
];

const hub = [
  { pathName: "Add Your Business", description: "Showcase your business to other COhatch members", url: "/dashboard/business_hub", icon: MdAddBusiness },
  { pathName: "My Businesses & Discounts", description: "Edit your business listing and discounts here", url: "/dashboard/business_hub/my_listings", icon: MdBusinessCenter },
  { pathName: "Business Analytics", description: "See what’s happening on your business listing", url: "/dashboard/marketplace/listing_analytics", icon: MdAnalytics },
  { pathName: "Scholarship Applications", description: "Apply and review your COhatch scholarship applications", url: "/dashboard/business_hub/my_applications", icon: AiFillFileText },
];

const workshops = [
  { pathName: "Events Calendar", description: "Find events and workshops that are happening near you", url: "/dashboard/workshop", icon: BsFillCalendarWeekFill },
  { pathName: "My Events", description: "See RSVPs and update your event information", url: "/dashboard/workshop/my_workshops", icon: MdOutlineEventNote },
  { pathName: "Events Attending", description: "Get a list of all of the events you’re attending here ", url: "/dashboard/workshop/workshop_rsvp", icon: FaCalendarCheck },
];

const volunteer = [
  // { pathName: "Philanthropy Home", description: "Overview of of the impact of COhatch", url: "/dashboard/volunteer", icon: MdVolunteerActivism },
  { pathName: "Nonprofits", description: "See all of the nonprofits inside the COhatch ecosystem", url: "/dashboard/volunteer/nonprofits", icon: MdBusiness },
  //{ pathName: "Volunteer and Drives", description: "Sign up for events and donate to ongoing drives", url: "/dashboard/volunteer/volunteer", icon: GiOpenedFoodCan },
  { pathName: "Impact Blog", description: "Submit your impact story", url: "/dashboard/volunteer/impact_stories", icon: MdOutlinePersonPin },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewDashboardNavbar() {
  const {
    userState,
    subscription: { product },
  } = useUserContext();
  const socket = React.useRef(useWebsocket(userState.accessToken));
  const { unreadNotifications, setUnreadNotifications } = useNotificationContext();
  const { photo } = userState;
  const [loggingOut, setLoggingOut] = React.useState(false);


  const [handleLogout] = useLogout(setLoggingOut);


  React.useEffect(() => {
    socket.current.on("notification", () => {
      setUnreadNotifications((prev) => prev + 1);
    });

    return () => {
      socket.current.disconnect();
    };
  }, [socket]);
  return (
    <Disclosure as="header" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-4">
            <div className="relative flex h-16 justify-between">
                <div className="flex flex-shrink-0 items-center">
                  <Logo color="green" />
                </div>
              <div className="relative flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
              <MarketplaceSearch placeholder="Search for local business, services, lunch, and brewery" />
                {/* <div className="w-full sm:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-success-800 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </div> */}
              </div>


              <div className="relative z-10 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>


              <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                <div className="relative">

                  <Link
                    to={loggingOut ? "" : "/dashboard/messaging"}
                    className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-1"
                  >
                    <span className="mr-3 material-icons-outlined">textsms</span>
                    Inbox
                  </Link>


                </div>

                <div className="relative">
                  <Link to="notification" type="button" className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500">
                    <span className="sr-only">View notifications</span>
                    <span className={`material-icons-outlined text-3xl ${unreadNotifications ? "text-red-400" : ""}`}>notifications</span>
                  </Link>
                  {unreadNotifications ? (
                    <span className="absolute top-0 left-4 w-6 h-6 p-3 inline-flex items-center justify-center rounded-full text-xs bg-red-100 text-red-500">
                      {getUnreadNotifications(unreadNotifications)}
                    </span>
                  ) : null}
                </div>

                {/* Profile dropdown */}
                <div className="group ml-3 relative flex">
                  <div>
                    <button
                      type="button"
                      className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success-800"
                      id="user-menu-button"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      <span className="sr-only">Open user menu</span>
                      <img
                        src={photo?.small?.url ? photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                        alt={photo?.small?.filename ? photo.small.filename : "Profile Photo"}
                        className="inline-block rounded-full border border-3 object-cover h-[32px] w-[32px]"
                      />
                    </button>
                  </div>
                  <div
                    className="group-hover:visible group-hover:opacity-100 origin-top-right absolute invisible opacity-0  right-0 top-10 mt-2 w-48 rounded-b-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none -translate-y-0 transition-all duration-500"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabIndex="-1"
                  >
                    <Link to={loggingOut ? "" : "settings"} className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200">
                      <span className="mr-3 material-icons-outlined h-6 w-6 text-success-800">person</span>
                      My Profile
                    </Link>

                    <Link
                      to={loggingOut ? "" : "marketplace/favorite_listing"}
                      className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200 "
                      role="menuitem"
                      tabIndex="-1"
                      id="user-menu-item-1"
                    >
                      <span className="mr-3 material-icons-outlined">favorite</span>
                      Favorite Listings
                    </Link>


                    <Link
                      to={loggingOut ? "" : "/dashboard/notification"}
                      className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200"
                      role="menuitem"
                      tabIndex="-1"
                      id="user-menu-item-1"
                    >
                      <span className="mr-3 material-icons-outlined">notifications</span>
                      Notifications
                    </Link>

                    <Link to={loggingOut ? "" : "settings"} className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200">
                      <span className="mr-3 material-icons-outlined h-6 w-6 text-success-800">person</span>
                      Settings
                    </Link>

                    <div onClick={handleLogout} className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200 cursor-pointer" role="menuitem" tabIndex="-1" id="user-menu-item-2">
                      <span className="mr-3 material-icons-outlined">logout</span>
                      Sign out
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Popover.Group className="hidden lg:flex lg:gap-x-12  mx-auto max-w-fit px-2 items-center justify-between border-b-2 border-gray-100 py-2 md:justify-start md:space-x-4 ">
              
              <NavLink  to="/dashboard"
                        end
                        className="text-md font-semibold leading-6 text-success-800 aria-[current=page]:text-primary-400"
                        >
                        Dashboard
              </NavLink>

              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-success-800 ">

                My Business Hub

                  <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 ">
                    <div className="p-4">
                      {hub.map((item) => (
                        <div
                          key={item.pathName}
                          className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-primary-400" aria-hidden="true" />
                          </div>
                          <div className="flex-auto">
                            <Link to={item.url} className="block font-semibold text-success-800 group-hover:text-primary-400">
                              {item.pathName}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">{item.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-success-800">
                Businesses & Members
                  <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                      {directory.map((item) => (
                        <div
                          key={item.pathName}
                          className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50 "
                        >
                          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-primary-400" aria-hidden="true" />
                          </div>
                          <div className="flex-auto">
                            <Link to={item.url} className="block font-semibold text-success-800 group-hover:text-primary-400">
                              {item.pathName}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">{item.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>

                  </Popover.Panel>
                </Transition>
              </Popover>

              

              <NavLink  to="health_and_wellness"
                       
                        className="text-md font-semibold leading-6 text-success-800 aria-[current=page]:text-primary-400">
                        Health Benefits
              </NavLink>

              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-success-800">
                Events
                  <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                      {workshops.map((item) => (
                        <div
                          key={item.pathName}
                          className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-primary-400" aria-hidden="true" />
                          </div>
                          <div className="flex-auto">
                            <Link to={item.url} className="block font-semibold text-success-800 group-hover:text-primary-400">
                              {item.pathName}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">{item.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>


              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-success-800">
                Impact Hub
                  <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-44 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                      {volunteer.map((item) => (
                        <div
                          key={item.pathName}
                          className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-primary-400" aria-hidden="true" />
                          </div>
                          <div className="flex-auto">
                            <Link to={item.url} className="block font-semibold text-success-800 group-hover:text-primary-400">
                              {item.pathName}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">{item.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

            </Popover.Group>
          </div>



          {/* Mobile Dropdown  */}

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-success-800 hover:bg-gray-50"
                  >
                    Dashboard
                  </Link>

                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-success-800 hover:bg-gray-50">
                          My Business Hub
                          <ChevronDownIcon
                            className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {hub.map((item) => (
                            <Disclosure.Button
                              key={item.pathName}
                              as="a"
                              href={item.url}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-success-800 hover:bg-gray-50"
                            >
                              {item.pathName}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-success-800 hover:bg-gray-50">
                        Businesses & Members
                          <ChevronDownIcon
                            className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {directory.map((item) => (
                            <Disclosure.Button
                              key={item.pathName}
                              as="a"
                              href={item.url}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-success-800 hover:bg-gray-50"
                            >
                              {item.pathName}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>

                      </>
                    )}
                  </Disclosure>

                 

                  <Link
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-success-800 hover:bg-gray-50"
                  >
                    Health Benefits
                  </Link>

                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-success-800 hover:bg-gray-50">
                        Events
                          <ChevronDownIcon
                            className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {workshops.map((item) => (
                            <Disclosure.Button
                              key={item.pathName}
                              as="a"
                              href={item.url}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-success-800 hover:bg-gray-50"
                            >
                              {item.pathName}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-success-800 hover:bg-gray-50">
                        Impact Hub
                          <ChevronDownIcon
                            className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {volunteer.map((item) => (
                            <Disclosure.Button
                              key={item.pathName}
                              as="a"
                              href={item.url}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-success-800 hover:bg-gray-50"
                            >
                              {item.pathName}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                </div>
                <div className="py-6">
                  <Link
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-success-800 hover:bg-gray-50"
                  >
                    Log in
                  </Link>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                <img
                        src={photo?.small?.url ? photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                        alt={photo?.small?.filename ? photo.small.filename : "Profile Photo"}
                        className="inline-block rounded-full border border-3 object-cover h-[32px] w-[32px]"
                      />
                </div>
                <div className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <Link to="notification" type="button" className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500">
                    <span className="sr-only">View notifications</span>
                    <span className={`material-icons-outlined text-3xl ${unreadNotifications ? "text-red-400" : ""}`}>notifications</span>
                  </Link>
                  {unreadNotifications ? (
                    <span className="top-0 left-4 w-6 h-6 p-3 inline-flex items-center justify-center rounded-full text-xs bg-red-100 text-red-500">
                      {getUnreadNotifications(unreadNotifications)}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                <Link to={loggingOut ? "" : "settings"} className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200">
                  <span className="mr-3 material-icons-outlined h-6 w-6 text-success-800">person</span>
                  My Profile
                </Link>

                <Link
                  to={loggingOut ? "" : "marketplace/favorite_listing"}
                  className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200"
                  role="menuitem"
                  tabIndex="-1"
                  id="user-menu-item-1"
                >
                  <span className="mr-3 material-icons-outlined">favorite</span>
                  Favorite Listings
                </Link>


                <Link
                  to={loggingOut ? "" : "/dashboard/messaging"}
                  className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200"
                  role="menuitem"
                  tabIndex="-1"
                  id="user-menu-item-1"
                >
                  <span className="mr-3 material-icons-outlined">textsms</span>
                  Inbox
                </Link>

                <Link
                  to={loggingOut ? "" : "/dashboard/notification"}
                  className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200"
                  role="menuitem"
                  tabIndex="-1"
                  id="user-menu-item-1"
                >
                  <span className="mr-3 material-icons-outlined">notifications</span>
                  Notifications
                </Link>

                <Link to={loggingOut ? "" : "settings"} className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200">
                  <span className="mr-3 material-icons-outlined h-6 w-6 text-success-800">person</span>
                  Settings
                </Link>

                <div onClick={handleLogout} className="flex items-center px-4 py-2 text-sm text-success-800 hover:bg-gray-200 cursor-pointer" role="menuitem" tabIndex="-1" id="user-menu-item-2">
                  <span className="mr-3 material-icons-outlined">logout</span>
                  Sign out
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
