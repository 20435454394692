/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { ArrowLongLeftIcon, ArrowLongRightIcon, EnvelopeIcon, CalendarIcon } from "@heroicons/react/20/solid";
import {FaWindowClose} from "react-icons/fa";
import Popup from "reactjs-popup";
import useScrollPage from "../../hooks/useScrollPage";
import useUserContext from "../../hooks/useUserContext";
import Pagination from "../pagination/Pagination";
import notify from "../../services/toast";
import Spinner from "../spinner/Spinner";
import { MAGIC_NUMBERS } from "../../constant";
import getRSVPs from "./getRSVPs";
import { downloadExcel } from "react-export-table-to-excel";
import { format } from "date-fns";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function WorkshopRsvpList({ button, userName, event }) {
  const [pagination, setPagination] = useState({ total: 0, page: 0, remaining: 0, limit: 5 });
  const [RSVPs, setRSVPs] = useState([]);
  const [fetching, setFetching] = useState(false);
  const {
    userState: { accessToken },
  } = useUserContext();
  useScrollPage();

  const [tabs, setTabs] = useState([
    { name: "All", status: "", count: 0 },
    { name: "Attending", status: "1", count: 0 },
    { name: "Not Attending", status: "2", count: 0 },
    { name: "Maybe", status: "0" },
  ]);
  const [selectedTab, setSelectedTab] = useState("All");

  const fetchRSVPs = async (accessToken, offset, limit, status) => {
    try {
      setFetching(true);

      const response = await getRSVPs(accessToken, event._id, offset, limit, status);
      if (response.status === "success") {
        const { limit, page, remaining, total, totalRejected, totalAttending, totalMaybe, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setRSVPs(results);
        setTabs(tabs.map(tab => {
          if (tab.name === "All") {
            return {
              ...tab,
              count: total,
            };
          }
          if (tab.name === "Attending") {
            return {
              ...tab,
              count: totalAttending,
            };
          }
          if (tab.name === "Rejected") {
            return {
              ...tab,
              count: totalRejected,
            };
          }
          if (tab.name === "Maybe") {
            return {
              ...tab,
              count: totalMaybe,
            };
          }
          return tab;
        }));
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const handlePrevFetch = async () => {
    await fetchRSVPs(accessToken, pagination.page - 1, pagination.limit, tabs.find(tab => tab.name === setSelectedTab).status);
  };

  const handleNextFetch = async () => {
    await fetchRSVPs(accessToken, pagination.page + 1, pagination.limit, tabs.find(tab => tab.name === setSelectedTab).status);
  };

  const getPersonStatus = (status) => {
    if (status === 0) {
      return "Maybe";
    }
    if (status === 1) {
      return "Accepted";
    }
    if (status === 2) {
      return "Rejected";
    }
  };
  

  useEffect(() => {
    fetchRSVPs(accessToken, 0, 5, "");
  }, []);


  const header = ["Name", "Status", "Email", "Date"];

  function handleDownloadExcel() {
    const opt = "downloadExcel Method"; 

    downloadExcel({
      fileName: `react-export-table-to-excel - ${opt}`,
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // Accept two different data structures
        body: RSVPs
      }
    });
  }


  return (
<Popup trigger={button} modal nested position="right center">
  {close => (
    <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-userName" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
              <div className="w-full flex justify-between">
                <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">{userName}</h1>
                <button className="button" onClick={() => { close(); }} >
                <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close"/> </button> 
              </div>
      
            <div className="min-h-full">
              <main className="pt-2 pb-16">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="px-4 sm:px-0">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">RSVP List</h1>
                        <p className="mt-2 text-sm text-gray-700">
                          A list of all the users RSVPd for your event.
                        </p>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                          <button onClick={handleDownloadExcel} className="block rounded-md bg-primary-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"> 
                            Export Attendees 
                          </button>
                      </div>
        
                    </div>
                    {/* Tabs */}
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>
                      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                      <select
                        id="tabs"
                        name="tabs"
                        className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.name === selectedTab).name}
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-200">
                        <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                          {tabs.map((tab) => (
                            <div
                              key={tab.name}
                              onClick={() => {
                              setSelectedTab(tab.name);
                              setRSVPs([]);
                              fetchRSVPs(accessToken, 0, 5, tab.status);
                              }}
                              className={classNames(
                              selectedTab === tab.name
                              ? "border-purple-500 text-purple-600"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                              )}
                            >
                              {tab.name}
                              {tab.count ? (
                                <span
                                className={classNames(
                                tab.name === selectedTab ? "bg-purple-100 text-purple-600" : "bg-gray-100 text-gray-900",
                                "hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                )}
                                >
                                  {tab.count}
                                </span>
                              ) : null}
                            </div>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                  {/* Table */}
                  {fetching && <div className="overflow-hidden bg-white shadow sm:rounded-md" style={{ height: "200px" }}>
                    <Spinner displayText="Fetching..." />
                  </div>} 
                  <div className="px-4 sm:px-6 lg:px-8">  
                    <div className="mt-2 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                         <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-1">
                         {!fetching && <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead  className="bg-gray-50">
                              <tr>
                              {header.map((head) => (
                                <th key={head} scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                                {head}
                                </th>
                                ))}
                              </tr>
                            </thead>
                            {!fetching && RSVPs.length === 0 && 
                            <tbody>
                              <div className="p-4 text-center">No RSVP</div>
                            </tbody>}
                            <tbody className="divide-y divide-gray-200">
                              {RSVPs.map((person) => (
                                <tr key={person._id}>
                                  <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {person.user?.firstName} {person.user?.lastName}
                                  </td>
                                  <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 capitalize">{person.status}</td>
                                  <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">{person?.user?.emailAddress}</td>
                                  <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500"><time dateTime={format(new Date(person.updatedAt), "MM/dd/yy")}>{format(new Date(person.updatedAt.substring(0, 10)), "MM/dd/yy")}</time></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>}
                        </div>
                      </div>
                    </div>
                </div>
                  {/* Pagination */}
                    {!fetching && <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />}
                    {false && <nav
                      className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-4"
                      aria-label="Pagination"
                      >
                        <div className="-mt-px flex w-0 flex-1">
                          <a
                            href="#"
                            className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                          >
                            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Previous
                          </a>
                        </div>
                        <div className="hidden md:-mt-px md:flex">
                          <a
                            href="#"
                            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                          >
                            1
                          </a>
                          {/* Current: "border-purple-500 text-purple-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200" */}
                          <a
                            href="#"
                            className="inline-flex items-center border-t-2 border-purple-500 px-4 pt-4 text-sm font-medium text-purple-600"
                            aria-current="page"
                          >
                            2
                          </a>
                          <a
                            href="#"
                            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                          >
                            3
                          </a>
                          <a
                            href="#"
                            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                          >
                            4
                          </a>
                          <a
                            href="#"
                            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                          >
                            5
                          </a>
                        </div>
                        <div className="-mt-px flex w-0 flex-1 justify-end">
                          <a
                            href="#"
                            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                          >
                            Next
                            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                          </a>
                        </div>
                      </nav>}
                    </div>
                  </main>
                </div>
              </div>
          </div>
      </div>
    </div>
  )}
</Popup>
  
  );
}
