import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function lifePerksDeleteComment(accessToken, commentId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/lifeperks_comment/${commentId}`;

  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default lifePerksDeleteComment;
