/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getStoryById(accessToken, storyId) {
  console.log("Fetching", storyId);
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactstory/${storyId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getStoryById;