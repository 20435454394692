/* eslint-disable no-console */
/* eslint-disable id-length */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import useUserContext from "../../../hooks/useUserContext";
import useNotificationUpdate from "../../../hooks/useNotificationUpdate";

function Message({ notification }) {
  const { userState } = useUserContext();
  const [read, setRead] = React.useState(notification.read);
  const _ = useNotificationUpdate(userState.accessToken, notification.id, { update: { read: true } }, read, setRead);
  return (
    <div className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-600">
      <div className="flex justify-between space-x-3">
        <div className="min-w-0 flex-1">
          <span className="flex items-center gap-2 focus:outline-none">
            <p className="text-md font-bold text-gray-900 truncate">New message</p>
            {!read ? <div className="w-2 h-2 bg-primary-400 rounded-full"></div> : null}
          </span>
        </div>
        <time dateTime="2021-01-27T16:35" className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
          {format(new Date(notification.createdAt), "iii, MMMM do, hh:mm a")}
        </time>
      </div>
      <div className="mt-1">
        <p className="line-clamp-2 text-sm text-gray-600">{notification.data.message}</p>
      </div>
      <div className="mt-1">
        <Link to={`/dashboard/messaging/${notification.data.conversationId}`} className="text-primary-400 cursor-pointer underline mt-10">
          View
        </Link>
      </div>
    </div>
  );
}

export default Message;
