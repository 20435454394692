import React from "react";
import Footer from "../../components/footer/Footer";
import HomeNavigation from "../../components/home_navigation/HomeNavigation";
import NewsLetter from "../../components/newsletter/NewsLetter";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";

function Pricing() {
  useScrollPage();
  useTitle("Pricing");
  
  return (
    <div className="w-full">
      <HomeNavigation />
      <div className="max-w-full bg-success-800 mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="px-0 sm:px-4 lg:px-0 lg:flex lg:justify-between lg:items-center">
          <div className="max-w-xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl font-bebas">Pricing Plans</h2>
          </div>
        </div>
      </div>

      <div className="max-w-full mx-auto py-16 sm:py-24 sm:px-6 lg:px-8">
        {/* <!-- xs to lg --> */}
        <div className="space-y-24 lg:hidden">
          <section>
            <div className="px-4 mb-8">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Basic</h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">$0</span>
                <span className="text-base font-medium text-gray-500">/mo</span>
              </p>
              <p className="mt-4 text-sm text-gray-500">All the basics for trying out COhatch+.</p>
              <a href="/signup" className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                Get Started
              </a>
            </div>

            <table className="w-full">
              <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">Features</caption>
              <thead>
                <tr>
                  <th className="sr-only" scope="col">
                    Feature
                  </th>
                  <th className="sr-only" scope="col">
                    Included
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Unlimited Business Listings
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500 text-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Some Business Listings Options and Information
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    List Discount Codes
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Feature In Search Results
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    24/7 Technical Support
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <section>
            <div className="px-4 mb-8">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Local Discount Club</h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">$10</span>
                <span className="text-base font-medium text-gray-500">/mo</span>
              </p>
              <p className="mt-4 text-sm text-gray-500">Get advanced business listings and access to all local discounts.</p>
              <a href="/signup" className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                Get Started
              </a>
            </div>

            <table className="w-full">
              <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">Features</caption>
              <thead>
                <tr>
                  <th className="sr-only" scope="col">
                    Feature
                  </th>
                  <th className="sr-only" scope="col">
                    Included
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Unlimited Business Listings
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    All Business Listings Options and Information
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    List Discount Codes
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Access to All Discount Codes
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Leave and Generate Reviews for Your Business
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Feature in Search Results
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    24/7 Technical Support
                  </th>
                  <td className="py-5 pr-4">
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <section>
            <div className="px-4 mb-8">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Premium</h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">$30</span>
                <span className="text-base font-medium text-gray-500">/mo</span>
              </p>
              <p className="mt-4 text-sm text-gray-500">Unlimited Access to All Features</p>
              <a href="#" className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                Get Started
              </a>
            </div>

            <table className="w-full">
              <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">Features</caption>
              <thead>
                <tr>
                  <th className="sr-only" scope="col">
                    Feature
                  </th>
                  <th className="sr-only" scope="col">
                    Included
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Unlimited Business Listings
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    All Business Listings Options and Information
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    List Discount Codes
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Access To All Discount Codes
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Leave And Generate Reviews For Your Business
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Feature In Search Results
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Chat And Networking Through Our Chat And Social Networking Features
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Create And Participate In Neighborhood Groups
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    An Event Space At Any Of The COhatch Locations For Your Neighborhood Groups
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Unlimited Access To All Future Updates & Features
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Business Listings And Personal Profile Featured In Search Results
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    24/7 Technical Support
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <section>
            <div className="px-4 mb-8">
              <h2 className="text-lg leading-6 font-medium text-gray-900">COhatch Member</h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">$0</span>
                <span className="text-base font-medium text-gray-500">/mo</span>
              </p>
              <p className="mt-4 text-sm text-gray-500">Unlimited Access To All Features</p>
              <a href="#" className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                Get Started
              </a>
            </div>

            <table className="w-full">
              <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">Features</caption>
              <thead>
                <tr>
                  <th className="sr-only" scope="col">
                    Feature
                  </th>
                  <th className="sr-only" scope="col">
                    Included
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Unlimited Business Listings
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    All Business Listings Options and Information
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    List Discount Codes
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Access To All Discount Codes
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Leave And Generate Reviews For Your Business
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Feature In Search Results
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Chat And Networking Through Our Chat And Social Networking Features
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Create And Participate In Neighborhood Groups
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    An Event Space At Any Of The COhatch Locations For Your Neighborhood Groups
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Unlimited Access To All Future Updates & Features
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    Business Listings And Personal Profile Featured In Search Results
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr className="border-t border-gray-200">
                  <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                    24/7 Technical Support
                  </th>
                  <td className="py-5 pr-4">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
        <div className="hidden lg:block">
          <table className="w-full h-px table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead className="w-full">
              <tr className="w-full">
                <th className="pb-4 pl-6 pr-6 text-sm font-medium text-gray-900 text-left" scope="col">
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>

                <th className="pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-center" scope="col">
                  Basic
                </th>

                <th className="pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-center" scope="col">
                  Local Discount Club
                </th>

                <th className="pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-center" scope="col">
                  Premium
                </th>

                <th className="pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-center" scope="col">
                  COhatch Members
                </th>
              </tr>
            </thead>
            <tbody className="w-full border-t border-gray-200 divide-y divide-gray-200">
              <tr className="w-full">
                <th className="py-8 pl-6 pr-6 align-top text-sm font-medium text-gray-900 text-left" scope="row">
                  Pricing
                </th>

                <td className="h-full py-8 px-6 align-top">
                  <div className="h-full flex flex-col justify-between text-center">
                    <div>
                      <p>
                        <span className="text-4xl font-extrabold text-success-800">$0</span>
                        <span className="text-base font-medium text-success-800">/mo</span>
                      </p>
                      <p className="mt-4 text-sm text-gray-500">Basic</p>
                    </div>
                    <a
                      href="/signup"
                      className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                      Get Started
                    </a>
                  </div>
                </td>

                <td className="h-full py-8 px-6 align-top">
                  <div className="h-full flex flex-col justify-between text-center">
                    <div>
                      <p>
                        <span className="text-4xl font-extrabold text-success-800">$10</span>
                        <span className="text-base font-medium text-success-800">/mo</span>
                      </p>
                      <p className="mt-4 text-sm text-gray-500">Local Discounts and Listing</p>
                    </div>
                    <a
                      href="/signup"
                      className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                      Get Started
                    </a>
                  </div>
                </td>

                <td className="h-full py-8 px-6 align-top">
                  <div className="h-full flex flex-col justify-between text-center">
                    <div>
                      <p>
                        <span className="text-4xl font-extrabold text-success-800">$30</span>
                        <span className="text-base font-medium text-success-800">/mo</span>
                      </p>
                      <p className="mt-4 text-sm text-gray-500">Unlimited Access To All Features</p>
                    </div>
                    <a
                      href="/signup"
                      className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                      Get Started
                    </a>
                  </div>
                </td>

                <td className="h-full py-8 px-6 align-top">
                  <div className="h-full flex flex-col justify-between text-center">
                    <div>
                      <p>
                        <span className="text-4xl font-extrabold text-success-800">$0</span>
                        <span className="text-base font-medium text-success-800">/mo</span>
                      </p>
                      <p className="mt-4 text-sm text-gray-500">Included with COhatch Membership</p>
                    </div>
                    <a
                      href="/contact_us"
                      className="mt-6 block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                      Contact Us
                    </a>
                  </div>
                </td>
              </tr>

              <tr>
                <th className="py-3 pl-6 bg-gray-50 text-xl text-gray-900 text-left font-bebas" colSpan="5" scope="colgroup">
                  WORK
                </th>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                  Unlimited Business Listings
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                  List Discount Codes
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Promoted to National COhatch members
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>
              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Listed on COhatch Marketplace
                </th>

               
                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                   xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20 20"
                   fill="currentColor"
                   aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                  Feature In Search Results
                </th>
                
                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Access to All Local Discounts
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Leave & Generate Reviews for Your Business
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>
              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Support Team Assistance
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                  Unlimited Access To All Future Updates & Features
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Network through Chat Features
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-3 pl-6 bg-gray-50 text-xl font-bebas text-gray-900 text-left" colSpan="5" scope="colgroup">
                  MEET
                </th>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Access to Neighborhood groups
                </th>
                
                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Create your own groups
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              
              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Get 1 hour of meeting or event space at COhatch per month
                </th>

                <td className="relative py-5 px-6">
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                     <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Basic</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                    className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Not Included in Local Discount Club</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in Premium</span>
                </td>

                <td className="relative py-5 px-6">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                   className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] h-5 w-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="sr-only">Included in COhatch Member</span>
                </td>
              </tr>

              <tr>
                <th className="py-3 pl-6 bg-gray-50 text-xl font-bebas text-gray-900 text-left" colSpan="5" scope="colgroup">
                  LIVE
                </th>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Free Personal Life Events
                </th>
                
                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Book local COhatch amenities (Bounce House, Mobi, etc.,)
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              
              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Discounts on vacation homes
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                List & rent your vacation home to members
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-3 pl-6 bg-gray-50 text-xl font-bebas text-gray-900 text-left" colSpan="5" scope="colgroup">
                Workshops & Networking
                </th>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Host digital & in-person workshops
                </th>
                
                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Attend workshops across COhatch+
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              
              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Attend networking events
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                  </td>

                  <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Book 1 hour per month of workshops at your local COhatch location
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-3 pl-6 bg-gray-50 text-xl font-bebas text-gray-900 text-left" colSpan="5" scope="colgroup">
                STARTUPS & INVESTORS
                </th>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                List your startup to gain visibility
                </th>
                
                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Raise money directly (no fees or equity taken)
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              
              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Access to startup programs & accelerators
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                  </td>

                  <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Startup workshops & expert lectures
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Access to Entrepreneur neighborhoods & communities
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Access to local startups
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Invest in local startups
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>

                <td className="relative py-5 px-6 text-center">
                  {/* <!-- Heroicon name: solid/check --> */}
                  <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-3 pl-6 bg-gray-50 text-xl font-bebas text-gray-900 text-left" colSpan="5" scope="colgroup">
                IMPACT
                </th>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Nonprofit listings to generate awareness
                </th>
                
                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                <span>Coming Soon</span>
                </td>
              </tr>

              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Passion & Volunteer Matchmaking
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                <span>Coming Soon</span>
                </td>
              </tr>

              
              <tr>
                <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 text-left" scope="row">
                Volunteer, Donation, and Event Opportunities at local nonprofits
                </th>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6">
                  </td>

                <td className="relative py-5 px-6">
                </td>

                <td className="relative py-5 px-6 text-center">
                <span>Coming Soon</span>
                </td>
              </tr>

            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>

                <td className="pt-5 px-6">
                  <a href="/signup" className="block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                    Get Started
                  </a>
                </td>

                <td className="pt-5 px-6">
                  <a href="/signup" className="block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                    Get Started
                  </a>
                </td>

                <td className="pt-5 px-6">
                  <a href="/signup" className="block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                    Get Started
                  </a>
                </td>
                <td className="pt-5 px-6">
                  <a
                    href="/contact_us"
                    className="block w-full bg-gradient-to-r from-primary-400 to-success-800 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-success-800">
                    Contact Us
                  </a>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <section className="w-full font-roboto pr-20 pl-20">
       <NewsLetter />
      </section>
      <Footer />
    </div>
  );
}

export default Pricing;
