/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
/* eslint-disable no-console */

import React from "react";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import useUserContext from "./../../../hooks/useUserContext";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import LifePerksCreatePost from "../../../components/create_post/LifePerksCreatePost";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import LifePerksPost from "../../../components/post/LifePerksPost";
import LifePerksTimeline from "../../../components/lifeperks/timeline/LifePerksTimeline";


function LifePerksFeed() {
  useScrollPage();
  useTitle("Life Perks Live Wall");

  const { userState } = useUserContext();
  const { firstName, photo } = userState;

  return (
    <div className="p-4">
      <div className="max-w-full h-full rounded-lg p-2 space-y-4">
        {/* <div className="flex flex-col items-start">

            <img
              src={photo?.small?.url ? photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
              alt={photo?.small?.filename ? photo.small.filename : "Profile Photo"}
              className="inline-block rounded-full border border-3 object-cover h-[64px] w-[64px]"
            /> 
            <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-success-800 sm:leading-9 sm:truncate">Hey {firstName}! Have you been to a COhatch Event recently?</h1>
            <h3 className="ml-3 text-lg text-gray-500">Submit photos or post about the event here!</h3>

          </div> */}

        {/* This is the TIMELINE >> stuff that appears on the right hand side (administrator, about, book a meeting room. etc.) */}
        <LifePerksTimeline />


        {/* <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">Life Perks Listing One</h1>
          <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">Member Benefits</h1>
          <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">Recent Life Events</h1> */}


        {/* <>
            FeedList
          </> */}

        {/* <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">LISTINGS NEAR ME</h1>
          <div className="h-full w-full">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative justify-center w-full grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                <>
                  Listings Near Me
                </>
              </div>
            </div>
          </div>
          <div className="z-20 flex flex-col">
            <div className="mt-2 flex flex-col px-3 py-3 space-y-4 sm:space-y-0 sm:mx-auto sm:gap-5">
              <Link
                to="/marketplace"
                className="flex items-center justify-center px-3 py-2 border border-transparent text-xl font-bold rounded-md shadow-sm text-white bg-primary-500 bg-opacity-100 hover:bg-opacity-70 sm:px-8 transition ease-linear duration-500"
              >
                See More
              </Link>
            </div>
          </div>

          <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">SUGGESTED LISTINGS</h1>
          <div className="h-full w-full">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative justify-center w-full grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                <>
                  Suggested Listing
                </>
              </div>
            </div>
          </div>

          <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">FEATURED VENDORS</h1>
          <div>
            <ul role="list" className="relative justify-center w-full grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
              Featured Vendors
            </ul>
          </div> */}
      </div>
    </div>
  );
}

export default LifePerksFeed;






