/* eslint-disable no-unused-vars */
import React from "react";
import { Outlet } from "react-router-dom";
import BreadCrumb from "./../../components/bread_crumb/BreadCrumb";
import Tab from "./../../components/tab/Tab";
import useScrollPage from "../../hooks/useScrollPage";

const directions = [
  { pathName: "Dashboard", url: "/dashboard" },
  { pathName: "Local Discounts", url: "local_discount" },
];

const navigations = [
  // { pathName: "Featured Discounts", url: "featured_discount", icon: "discount" },
  // { pathName: "All Discounts", url: "", icon: "discount", end: true },
  // { pathName: "My Listing Discounts", url: "my_discount", icon: "discount" },
  // { pathName: "My Favorite Discounts", url: "favorite_discount", icon: "local_offer"},
 
];
function LocalDiscount() {
  useScrollPage();
  return (
    <div className="p-5 space-y-2">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">Local Discounts</h1>
      </div>
      {/* <BreadCrumb directions={directions} /> */}
      <Tab navigations={navigations} section="local_discount" />
      <Outlet />
    </div>
  );
}

export default LocalDiscount;
