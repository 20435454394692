import React from "react";
import { Outlet } from "react-router-dom";
import BreadCrumb from "./../../components/bread_crumb/BreadCrumb";
import useScrollPage from "../../hooks/useScrollPage";
import Video from "../../components/video/Video";
import InvestVideo from "../../assets/InvestorVideo.mp4";
import InvestCover from "../../assets/InvestorCoverImage.png";

const directions = [
  { pathName: "Dashboard", url: "/dashboard" },
  { pathName: "Investor Club", url: "" },
];


function LocalDiscount() {
  useScrollPage();
  return (
    <div className="p-5 space-y-2">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">Investor - COMING SOON</h1>
      </div>
      <BreadCrumb directions={directions} />
      <Outlet />
      <Video
      className=""
            source= {InvestVideo}
            type="video/mp4"
            poster={InvestCover}
          />
    </div>
  );
}

export default LocalDiscount;
