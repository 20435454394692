import React from "react";
import PropTypes from "prop-types";

function PaymentOptionCard({ paymentOption, removePaymentOption, remove = true }) {
  return (
    <div className="w-fit bg-white rounded-md shadow-md p-3">
      <div className="w-full flex gap-2">
        {remove ? (
          <span data-paymentoption={paymentOption.index} className="block text-success-800 cursor-pointer material-icons-outlined" onClick={removePaymentOption}>
            cancel
          </span>
        ) : null}
        <p className="text-success-800 text-md capitalize">{paymentOption.value}</p>
      </div>
    </div>
  );
}

PaymentOptionCard.propTypes = {
  paymentOption: PropTypes.object.isRequired,
  removePaymentOption: PropTypes.func,
  remove: PropTypes.bool,
};

export default PaymentOptionCard;
