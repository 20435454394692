/*eslint-disable */
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { AiFillStar } from "react-icons/ai";



  

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ImpactStats({
  impactStats,
  handleSubmit
}) {

const [editImpactStats, setEditImpactStats] = React.useState({
  volunteerHours: null,
  donationAmount: null,
  eventsAttended: null
})


  return (
    <>
    <div className='h-36 flex flex-1 justify-between'>
      <div
        className="bg-pink-600 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
     
      >
        VO
      </div>
      <div className="pl-3 flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
        	<div className="left flex flex-col justify-between">
				<span className="title  text-xl font-bold">Volunteer Hours</span>
				<span className="counter text-gray-500 text-4xl">3</span>
				<span className="link underline text-lime-600">See volunteer events</span>
			</div>
        <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
   </div>

       <div className='h-36 flex flex-1 justify-between'>
      <div
        className="bg-cyan-600 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
     
      >
        DA
      </div>
      <div className="pl-3 flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
        	<div className="left flex flex-col justify-between">
				<span className="title text-xl font-bold">Donation Amount</span>
				<span className="counter text-gray-500 text-4xl">$10</span>
				<span className="link underline text-lime-600">See ongoing Drives</span>
			</div>
        <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
   </div>
     <div className='h-36 flex flex-1 justify-between'>
      <div
        className="bg-green-600 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
     
      >
        EA
      </div>
      <div className="pl-3 flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
        	<div className="left flex flex-col justify-between">
				<span className="title  text-xl font-bold">Events Attended</span>
				<span className="counter text-gray-500 text-4xl">2</span>
				<span className="link underline text-lime-600">See All Events</span>
			</div>
        <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
   </div>
   </>
  )
}

export default ImpactStats;