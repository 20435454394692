/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function search(category, query = "") {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/search/listing?query=${query}&category=${category}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default search;
