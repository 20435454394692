/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
import React from "react";
import { v4 as uuid } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import search from "./search";
import { debounce } from "../../services/util";
import Spinner from "../spinner/Spinner";


function MarketplaceSearch({ placeholder, query = "" }) {
  const navigate = useNavigate();
  const suggestionContainerRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = React.useState(query);
  const [suggestions, setSuggestions] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  const handleSearch = async () => {
    try {
      setFetching(true);
      const response = await search(searchTerm);
      if (response.status === "success") {
        setSuggestions(response.data.results);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), [searchTerm]);

  const handleSubmit = event => {
    event.preventDefault();
    navigate(`search?q=${searchTerm}`);
  };

  const closeSuggestionsOnClick = () => {
    setSuggestions([]);
  };

  const closeSuggestionsOnEnter = event => {
    if (event.code === "Enter") {
      setSuggestions([]);
    }
  };

  React.useEffect(() => {
    debouncedHandleSearch();
  }, [searchTerm]);

  React.useEffect(() => {
    document.addEventListener("click", closeSuggestionsOnClick, false);
    document.addEventListener("keypress", closeSuggestionsOnEnter, false);

    return () => {
      document.removeEventListener("click", closeSuggestionsOnClick, false);
      document.removeEventListener("keypress", closeSuggestionsOnEnter, false);
    };
  }, []);

  return (
   
    <div className="mb-2 w-full md:w-3/5 px-2">
    <div className="w-full relative mt-1">
 
      <form onSubmit={handleSubmit}>
          <input
            onChange={event => setSearchTerm(event.target.value)}
            type="search"
            name="search"
            id="search"
            value={searchTerm}
            className={`shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full text-sm text-success-800 border-gray-300 px-10 py-4 ${
              suggestions?.length ? "rounded-t-md" : "rounded-full"
            }`} 
            placeholder={placeholder}
          />
          <div className="w-[120px]">
            <button
              className="text-white absolute right-2.5 bottom-2.5 bg-primary-400 hover:bg-primary-100 focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-4 py-2 dark:bg-primary-400 dark:hover:bg-primary-100 focus:ring-primary-400">
                Search
            </button>
    
          </div>
          <div ref={suggestionContainerRef} className={`${suggestions?.length ? "block" : "hidden"} z-30 absolute w-full bg-white border border-primary-400 max-h-80 overflow-y-auto p-4 divide-y`}>
            {fetching ? (
              <Spinner displayText="Fetching..." />
            ) : (
              suggestions?.map(suggestion => {
                return (
                  <Link to={`marketplace/listing/${suggestion._id}`} key={uuid()} className="w-full flex gap-2 items-start p-2 hover:bg-gray-200">
                    <img
                      src={suggestion?.businessPhotos?.coverPhoto?.small?.url || "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"}
                      className="w-16 h-16 border-2 border-solid border-white rounded-md"
                      alt={suggestion?.businessName}
                    />
                    <div className="w-full flex flex-col">
                      <h1 className="text-md text-success-800 font-bold capitalize">{suggestion?.businessName}</h1>
                      <p className="w-full text-sm text-gray-400 ">{suggestion?.businessDescription?.substring(0, 500)}</p>
                      <p className="text-sm text-primary-400 truncate">
                        {suggestion?.businessOwner?.firstName} {suggestion?.businessOwner?.lastName}
                      </p>
                    </div>
                  </Link>
                  
                );
              })
            )}
          </div>
          <span className="absolute top-4 left-3 material-icons-outlined text-gray-300">search</span>
        </form>
        </div>
    </div>
  );
}

MarketplaceSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
};

export default MarketplaceSearch;
