/* eslint-disable require-await */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PasswordRequirement from "../../components/password_requirement/PasswordRequirement";
import { NP_OPTIONS, STATES_CITIES } from "../../constant";
import useTitle from "../../hooks/useTitle";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import useTogglePassword from "../../hooks/useTogglePassword";
import {
	validateUserName,
	validateEmail,
	validatePassword,
	validateCity } from "../../services/validation";
import useUserContext from "../../hooks/useUserContext";
import submitRegistrationForm from "../../pages/signup/submitForm";
import checkEmailAvailability from "../../pages/signup/emailAvailability";
import { Link, useNavigate } from "react-router-dom";
import { generateClassName } from "./../../services/util";
import { MAGIC_NUMBERS } from "../../constant";
import notify from "../../services/toast";
import Logo from "../../components/logo/Logo";
import SelectInput from "../../components/select_input/SelectInput";
import { MdErrorOutline } from "react-icons/md";
import { capitalize, sortBy } from "lodash";
import useScrollPage from "../../hooks/useScrollPage";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";
import FileUploader from "../../components/file_uploader/FileUploader";
import { MdUpload } from "react-icons/md";

const generateStates = (statesObject) => {
	const states = [];
	for (const state in statesObject) {
		states.push({ value: state, label: state });
	}
	return sortBy(states, (item) => item.value);
};

const generateCities = (stateValue) => {
	if (stateValue) {
		const cities = [];
		for (const city of STATES_CITIES[stateValue.value]) {
			cities.push({ value: city, label: city });
		}
		return sortBy(cities, (item) => item.value);
	}
	return [];
};

function PhotoDisplay({ callback }) {
	return (
		<MdUpload
			onClick={callback}
			className="text-2xl text-primary-500 cursor-pointer"
		/>
	);
}

function Signup() {
	const imageCriteria = {
		requiredSize: 5e6,
		requiredDimensions: { width: 400, height: 400 },
	};
	useScrollPage();

	const { setUserState } = useUserContext();
	const navigate = useNavigate();
	useTitle("COhatch+ Registration");

	const firstNameRef = React.useRef(null);
	const passwordRef = React.useRef(null);
	const passwordVisibilityRef = React.useRef(null);

	const [passwordRequirement, setPasswordRequirement] = React.useState(false);

	const [togglePasswordVisibility] = useTogglePassword(
		passwordRef,
		passwordVisibilityRef
	);

	const [firstName, setFirstName] = React.useState("");
	const [validFirstName, setValidFirstName] = React.useState(false);

	const [lastName, setLastName] = React.useState("");
	const [validLastName, setValidLastName] = React.useState(false);

	const [email, setEmail] = React.useState("");
	const [validEmail, setValidEmail] = React.useState(false);

	const [password, setPassword] = React.useState("");
	const [validPassword, setValidPassword] = React.useState(false);

	const [city, setCity] = React.useState(null);

	const [state, setState] = React.useState(null);

	const [submitting, setSubmitting] = React.useState(false);
	const [checkingEmail, setCheckingEmail] = React.useState(false);

	const reRef = React.useRef();

	React.useEffect(() => {
		const result = validateUserName(firstName);
		setValidFirstName(result);
	}, [firstName]);

	React.useEffect(() => {
		const result = validateUserName(lastName);
		setValidLastName(result);
	}, [lastName]);

	React.useEffect(() => {
		const result = validateEmail(email);
		setValidEmail(result);
	}, [email]);

	React.useEffect(() => {
		const result = validatePassword(password);
		setValidPassword(result);
	}, [password]);

	// React.useEffect(() => {
	// 	firstNameRef.current.focus();
	// }, []);

	const handleImageUpload = (result) => {};
	const handleEmailAvailability = async () => {
		try {
			if (!email || !validEmail) {
				return;
			}
			setCheckingEmail(true);
			const response = await checkEmailAvailability(email);
			if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
					}
				}
				setValidEmail(false);
				notify(displayMessage, "error");
			} else {
				setValidEmail(true);
				notify("This email address is available for use.", "info");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later.", "error");
		} finally {
			setCheckingEmail(false);
		}
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);

		try {
			if (
				!validFirstName ||
				!validLastName ||
				!validEmail ||
				!validPassword ||
				!state ||
				!city
			) {
				notify("One or more fields are invalid. Check and try again.", "error");
				return;
			}

			const token = await reRef.current.executeAsync();
			reRef.current.reset();

			const payload = {
				token,
				firstName: firstName.toLocaleLowerCase(),
				lastName: lastName.toLocaleLowerCase(),
				emailAddress: email.toLocaleLowerCase(),
				password: password,
				state: state["value"].toLocaleLowerCase(),
				city: city["value"].toLocaleLowerCase(),
			};
			const response = await submitRegistrationForm(payload);
			if (response.status === "success") {
				const { data } = response;
				setUserState(data);
				navigate("/plan");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later.", "error");
		}
	};
	
	return (
		<div className="h-full w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-no-repeat bg-cover bg-[url('/src/assets/grungTextureGreen.jpg')] bg-fixed">
			<div className="flex justify-center">
				<Logo color="white"/>
			</div>

			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<h2 className="mt-4 text-center text-3xl font-extrabold text-white">
					Create a new account to access My Business Hub and apply for scholarships
				</h2>
			</div>

			<ReCAPTCHA
				sitekey={config["SITE_CAPTCHA"]}
				size="invisible"
				ref={reRef}
			/>

			<div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-lg">
				<div className="py-8 px-4 md:shadow sm:rounded-lg sm:px-10">
					<form className="space-y-6" onSubmit={handleFormSubmit}>
						<div className="flex items-center justify-center gap-5">
							<div>
								<label
									htmlFor="firstname"
									className="block text-sm font-bold text-gray-700"
								>
									First name
								</label>
								<div className="mt-1">
									<input
										ref={firstNameRef}
										onChange={(event) => setFirstName(event.target.value)}
										id="firstname"
										name="firstname"
										type="text"
										placeholder="John"
										value={firstName}
										autoComplete="off"
										className={generateClassName(!validFirstName && firstName)}
									/>
								</div>
							</div>
							<div>
								<label
									htmlFor="lastname"
									className="block text-sm font-bold text-gray-700"
								>
									Last name
								</label>
								<div className="mt-1">
									<input
										id="lastname"
										onChange={(event) => setLastName(event.target.value)}
										name="lastname"
										type="text"
										placeholder="Smith"
										value={lastName}
										autoComplete="off"
										className={generateClassName(!validLastName && lastName)}
									/>
								</div>
							</div>
						</div>
						<div>
							<label
								htmlFor="email"
								className="flex justify-between items-center text-sm font-bold text-gray-700"
							>
								Email address
								{checkingEmail ? (
									<span className="text-xs text-gray-400">
										Checking email...
									</span>
								) : null}
							</label>
							<div className="mt-1">
								<input
									id="email"
									onBlur={handleEmailAvailability}
									onChange={(event) => setEmail(event.target.value)}
									name="email"
									type="email"
									placeholder="example@email.com"
									value={email}
									autoComplete="off"
									className={generateClassName(!validEmail && email)}
								/>
							</div>
						</div>
					
						<div>
							<label
								htmlFor="state"
								className="flex justify-between items-center text-sm font-bold text-gray-700"
							>
								State
							</label>
							<div className="mt-1">
								<SelectInput
									options={generateStates(STATES_CITIES)}
									noOptionsMessage="No states available"
									placeholder="Select a state"
									setSelectedValue={setState}
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="city"
								className="flex justify-between items-center text-sm font-bold text-gray-700"
							>
								City
							</label>
							<div className="mt-1">
								<SelectInput
									options={generateCities(state)}
									noOptionsMessage="Select a city"
									placeholder="Select a city"
									setSelectedValue={setCity}
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="password"
								className="text-sm font-bold text-gray-700 flex justify-between items-center"
							>
								Password
								<MdErrorOutline
									className="text-gray-400 text-lg cursor-pointer"
									onClick={() => setPasswordRequirement(!passwordRequirement)}
								/>
							</label>
							<div className="relative mt-1">
								<input
									ref={passwordRef}
									onChange={(event) => setPassword(event.target.value)}
									id="password"
									name="password"
									type="password"
									value={password}
									placeholder="Enter your password"
									autoComplete="off"
									className={generateClassName(!validPassword && password)}
								/>
								<span
									ref={passwordVisibilityRef}
									onClick={togglePasswordVisibility}
									className="absolute top-3 left-[90%] material-icons-outlined text-gray-400 cursor-pointer"
								>
									visibility
								</span>
								{passwordRequirement ? (
									<div className="absolute -top-[190px] right-0">
										<PasswordRequirement />
									</div>
								) : null}
							</div>
						</div>

						<div>
							<ButtonPrimary
								text={submitting ? "Please wait..." : "Confirm and continue"}
								size="md"
								loading={
									!validFirstName ||
									!validLastName ||
									!validEmail ||
									!validPassword ||
									!city ||
									!state ||
									submitting
										? true
										: false
								}
							/>
						</div>
					</form>

					<div className="mt-4">
						<div className="relative">
							<div className="absolute inset-0 flex items-center">
								<div className="w-full border-t border-gray-300"></div>
							</div>
							<div className="relative flex justify-center text-sm">
								<span className="px-2 bg-white text-gray-500">
									{" "}
									Already have an account?{" "}
								</span>
							</div>
						</div>

						<div className="mt-4 flex justify-center">
							<span className="text-sm text-gray-500">
								Login into your account.

								<Link className="text-primary-400 ml-1" to="/impact_login">

									here
								</Link>
							</span>
						</div>
						<div className="p-5 text-xs text-gray-500 text-center">
							&copy; COhatch/COhatch+ All rights reserved
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Signup;
