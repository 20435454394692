/* eslint-disable no-undef */
const config = {};

if (process.env.NODE_ENV === "production") {
  config["API_BASE_ENDPOINT"] = process.env.REACT_APP_PROD_API_BASE_ENDPOINT;
  config["SOCKET_BASE_ENDPOINT"] = process.env.REACT_APP_PROD_SOCKET_BASE_ENDPOINT;
  config["SLACK_WEBHOOK_ENDPOINT"] = process.env.REACT_APP_DEV_SLACK_WEBHOOK_ENDPOINT;
  config["DOMAIN"] = process.env.REACT_APP_PROD_DOMAIN;
} else {
  config["API_BASE_ENDPOINT"] = process.env.REACT_APP_DEV_API_BASE_ENDPOINT;
  config["SOCKET_BASE_ENDPOINT"] = process.env.REACT_APP_DEV_SOCKET_BASE_ENDPOINT;
  config["SLACK_WEBHOOK_ENDPOINT"] = process.env.REACT_APP_DEV_SLACK_WEBHOOK_ENDPOINT;
  config["DOMAIN"] = process.env.REACT_APP_DEV_DOMAIN;
}

config["GOOGLE_API_KEY"] = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
config["ENCRYPTION_KEY"] = process.env.REACT_APP_ENCRYPTION_KEY;
config["SITE_CAPTCHA"] = process.env.REACT_APP_SITE_CAPTCHA;
config["DISNEY_USER_ID"] = process.env.REACT_APP_DISNEY_USER_ID;
config["DISNEY_PROPERTY_ID"] = process.env.REACT_APP_DISNEY_PROPERTY_ID;
config["SANDY_PANTS_PROPERTY_ID"] = process.env.REACT_APP_SANDY_PANTS_PROPERTY_ID || "63e165b2718caa999efe8935";

export default config;
