import React from "react";
import { Link } from "react-router-dom";

function MarketplaceNavFeatures() {
  return (
    <>
      <div className="w-full space-y-1 -m-3 p-3 flex flex-col justify-between rounded-lg">
        <h1 className="text-lg text-success-800 font-bold">Services</h1>
        <div className="w-full px-2">
          <Link to="/marketplace/listings/business_services" className="block text-base text-gray-500 hover:text-gray-700">
            Business Services
          </Link>
          <Link to="/marketplace/listings/home_services" className="block text-base text-gray-500 hover:text-gray-700">
            Home + Personal Services
          </Link>
          <Link to="/marketplace/listings/travel" className="block text-base text-gray-500 hover:text-gray-700">
            Travel
          </Link>
        </div>
      </div>

      <div className="w-full space-y-1 -m-3 p-3 flex flex-col justify-between rounded-lg">
        <h1 className="text-lg text-success-800 font-bold">Local Shopping</h1>
        <div className="w-full px-2">
          <Link to="/marketplace/listings/makers_and_artisans" className="block text-base text-gray-500 hover:text-gray-700">
            Makers + Artisans
          </Link>
          <Link to="/marketplace/listings/shops" className="block text-base text-gray-500 hover:text-gray-700">
            Shops
          </Link>
        </div>
      </div>

      <div className="w-full space-y-1 -m-3 p-3 flex flex-col justify-between rounded-lg">
        <h1 className="text-lg text-success-800 font-bold">Local Food</h1>
        <div className="w-full px-2">
          <Link to="/marketplace/listings/farmers_market" className="block text-base text-gray-500 hover:text-gray-700">
            Farmers + Food Producers
          </Link>
          <Link to="/marketplace/listings/restaurants" className="block text-base text-gray-500 hover:text-gray-700">
            Restaurants
          </Link>
        </div>
      </div>

      <div className="w-full space-y-1 -m-3 p-3 flex flex-col justify-between rounded-lg">
        <h1 className="text-lg text-success-800 font-bold">Community Resources</h1>
        <div className="w-full px-2">
          <Link to="/marketplace/listings/community_resources" className="block text-base text-gray-500 hover:text-gray-700">
            All Resources
          </Link>
          <Link to="" className="block text-base text-gray-500 hover:text-gray-700">
            Events
          </Link>
          <Link to="/marketplace/listings/non_profits" className="block text-base text-gray-500 hover:text-gray-700">
            Nonprofits
          </Link>
        </div>
      </div>
    </>
  );
}

export default MarketplaceNavFeatures;
