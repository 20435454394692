/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function filter(category, offset, limit, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/listing/filter?category=${category}&page=${offset}&limit=${limit}`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default filter;
