import React from "react";
import PropTypes from "prop-types";

function CauseCard({ cause, removeCause }) {
  return (
    <div className="w-fit bg-white rounded-md shadow-md p-3">
      <div className="w-full flex gap-2">
        {removeCause? (
          <span data-cause={cause.index} className="block text-success-800 cursor-pointer material-icons-outlined" onClick={removeCause}>
          cancel
        </span>
        ) : <span></span>}
        <p className="text-success-800 text-md capitalize">{cause.value}</p>
      </div>
    </div>
  );
}

CauseCard.propTypes = {
  cause: PropTypes.object.isRequired,
  removeCause: PropTypes.func.isRequired,
};

export default CauseCard;
