/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function getVisitorReviews(accessToken, page, limit, target) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/review/visitor?page=${page}&limit=${limit}&target=${target}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getVisitorReviews;
