/* eslint-disable */
import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";



async function getVolunteerEvents( accessToken) {
const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactevent/volunteer`;
	
	const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default getVolunteerEvents;
