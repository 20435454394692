/* eslint-disable capitalized-comments */
/* eslint-disable require-await */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import Select from "react-select";
import Ratings from "../../../components/listing_analytics/Rating";
import Reviews from "../../../components/listing_analytics/Reviews";
import Views from "../../../components/listing_analytics/Views";
import getAllListing from "./getAllListing";
import useUserContext from "./../../../hooks/useUserContext";
import notify from "./../../../services/toast";
import Spinner from "./../../../components/spinner/Spinner";
import getAnalytics from "./getAnalytics";
import DefaultState from "../../../components/default_state/DefaultState";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import SelectInput from "../../../components/select_input/SelectInput";

const years = [
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

const getDefaultYear = () => {
  const currentYear = new Date().getFullYear();
  for (const year of years) {
    if (year.value === currentYear.toString()) {
      return year;
    }
  }
  return years[0];
};

const buildListingSelection = listings => {
  const options = [];
  for (const listing of listings) {
    options.push({ value: listing._id, label: listing.businessName });
  }

  return options;
};

const buildAnalytics = (analytics, type) => {
  const viewData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const reviewData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const ratingData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  for (const analytic of analytics) {
    if (analytic?._id?.type === "Review") {
      reviewData[analytic?._id?.month] = analytic?.total;
    } else if (analytic?._id?.type === "View") {
      viewData[analytic?._id?.month] = analytic?.total;
    } else if (analytic?._id?.type === "Rating") {
      ratingData[analytic?._id?.month] = analytic?.total;
    }
  }

  if (type === "Review") {
    return reviewData;
  } else if (type === "View") {
    return viewData;
  } else if (type === "Rating") {
    return ratingData;
  }
};

function ListingAnalytics() {
  useScrollPage();
  useTitle("Listing Analytics");

  const {
    userState: { accessToken },
  } = useUserContext();
  const [listings, setListings] = React.useState([]);
  const [listing, setListing] = React.useState(null);
  const [analytics, setAnalytics] = React.useState([]);
  const [year, setYear] = React.useState(getDefaultYear().value);
  const [fetching, setFetching] = React.useState(false);

  const handleGetAllListing = async () => {
    try {
      const response = await getAllListing(accessToken);
      if (response.status === "success") {
        const allListings = buildListingSelection(response.data);
        setListings(allListings);
      } else {
        notify("An error occurred while getting the listings. If this error persists, please contact support", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  const handleFetchAnalytics = async () => {
    try {
      setFetching(true);
      const response = await getAnalytics(accessToken, listing.value, year);
      if (response.status === "success") {
        setAnalytics(response.data);
      } else {
        notify("An error occurred while getting the analytics. If this error persists please contact support", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    handleGetAllListing();
  }, []);

  React.useEffect(() => {
    if (listing !== null) {
      handleFetchAnalytics();
    }
  }, [listing, year]);
  return (
    <div className="h-full w-full">
      <div className="max-w-full h-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0 flex justify-center items-center">
					<h1 className="text-2xl font-bold leading-7 text-primary-400 font-bebas sm:text-4xl sm:truncate text-center">
						Business Analytics
					</h1>
				</div>
        <div className="w-full mt-10 flex items-center justify-between gap-5">
          <div className="w-1/4">
            <SelectInput
              options={listings}
              noOptionsMessage="No categories found"
              placeholder="Select a listing"
              defaultValue={() => {
                return listings.length ? listings[0] : null;
              }}
              setSelectedValue={event => setListing(event)}
            />
          </div>
          <div className="w-1/7">
            <SelectInput options={years} noOptionsMessage="No year found" placeholder="Select a year" defaultValue={getDefaultYear()} setSelectedValue={event => setYear(event.value)} />
          </div>
        </div>
        <div className="w-full mt-20 pb-12 flex flex-col space-y-20">
          {fetching ? <Spinner displayText="Fetching..." /> : <Views analytics={buildAnalytics(analytics, "View")} />}
          {fetching ? <Spinner displayText="Fetching..." /> : <Reviews analytics={buildAnalytics(analytics, "Review")} />}
          {fetching ? <Spinner displayText="Fetching..." /> : <Ratings analytics={buildAnalytics(analytics, "Rating")} />}
        </div>
      </div>
    </div>
  );
}

export default ListingAnalytics;
