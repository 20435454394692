import React from "react";

function BasicPlanFeatures() {
  return (
    <div className="pt-6 pb-8 px-6 max-w-sm">
    <h3 className="text-xs font-medium text-success-800 tracking-wide uppercase">What's Included</h3>
    <ul role="list" className="mt-6 space-y-4">
      <li className="flex space-x-3">
        <span className="material-icons-outlined text-success-800">done</span>
        <span className="text-sm text-secondary-600">Get access to everything inside of COhatch+ like business listings, local discounts, volunteer opportunities, and more.</span>
      </li>
      <li className="flex space-x-3">
        <span className="material-icons-outlined text-success-800">done</span>
        <span className="text-sm text-secondary-600">Chat and Networking through Chat and Social Networking Features</span>
      </li>

      {/* <li className="flex space-x-3">
        <span className="material-icons-outlined text-success-800">done</span>
        <span className="text-sm text-secondary-600">Create and Participate in Neighborhood Groups</span>
      </li> */}

      <li className="flex space-x-3">
        <span className="material-icons-outlined text-success-800">done</span>
        <span className="text-sm text-secondary-600">Get 1 Hour Of Meeting and Event Space at Your Local COhatch Location to Meet with Your Neighborhood Groups</span>
      </li>

      <li className="flex space-x-3">
        <span className="material-icons-outlined text-success-800">done</span>
        <span className="text-sm text-secondary-600">And all Upcoming Features and Updates!</span>
      </li>
    </ul>
  </div>
  );
}

export default BasicPlanFeatures;
