import React from "react";

function renderMedia(media) {
  const { type, large } = media;

  switch (type) {
    case "image":
      return <img src={large.url} alt="" />;
    case "video":
      return (
        <video className="w-full sm:w-[50%] object-center object-cover" muted controls preload="auto" playsInline>
          <source src={media.url} type="video/mp4"></source>
          Your browser does not support this video format.
        </video>
      );
    default:
      break;
  }
}

function Media({ media }) {
  return renderMedia(media);
}
export default Media;
