/* eslint-disable capitalized-comments */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { MdAddCircleOutline, MdPersonAdd } from "react-icons/md";
import Conversation from "../conversation/Conversation";
import DefaultState from "../default_state/DefaultState";
import useUserContext from "../../hooks/useUserContext";
import { useNavigate } from "react-router-dom";
import notify from "../../services/toast";
import getConversation from "./getConversation";
import Spinner from "../spinner/Spinner";
import { debounce } from "../../services/util";

function Conversations({ setShowMessages, newConversation }) {
  const navigate = useNavigate();
  const { userState } = useUserContext();
  const [conversations, setConversations] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [fetching, setFetching] = React.useState(false);
  const rootRef = React.useRef(null);
  const page = React.useRef(0);
  const remaining = React.useRef(0);

  const handleGetConversations = async () => {
    try {
      setFetching(true);
      const response = await getConversation(userState.accessToken, "", page.current, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setConversations(prev => {
          return [...prev, ...results];
        });
      } else {
        notify("An error has occurred while getting conversations. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const reload = async () => {
    try {
      setFetching(true);
      const response = await getConversation(userState.accessToken, "", 0, 10);
      if (response.status === "success") {
        const { results } = response.data;
        setConversations(results);
      } else {
        notify("An error has occurred while getting conversations. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const handleSearch = async () => {
    try {
      setFetching(true);
      const response = await getConversation(userState.accessToken, searchTerm, 0, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        remaining.current = remainingResult;
        page.current = 0;
        setConversations(results);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), [searchTerm]);

  const observer = React.useRef(
    new IntersectionObserver(
      entries => {
        const [first] = entries;
        if (first.isIntersecting) {
          handleGetConversations();
        }
      },
      { threshold: 1, root: rootRef.current }
    )
  );

  const [element, setElement] = React.useState(null);

  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  React.useEffect(() => {
    handleGetConversations();

    return () => {
      setConversations([]);
    };
  }, [newConversation]);

  React.useEffect(() => {
    debouncedHandleSearch();
  }, [searchTerm]);
  return (
    <div className="w-full h-full flex flex-col space-y-4 bg-white sm:border-r-2 p-2">
      <div className="h-fit space-y-4">
        <div className="w-full h-auto flex justify-between items-center">
          <span className="text-md text-success-800">New conversation</span>
          <MdPersonAdd onClick={() => navigate("/dashboard/neighborhood/members")} className="text-success-800 text-2xl cursor-pointer" />
        </div>
        <div className="w-full h-auto relative flex items-center">
          <input
            onInput={event => setSearchTerm(event.target.value)}
            type="text"
            name="search"
            id="search"
            placeholder="Search Conversations"
            className="shadow-sm focus:ring-primary-400 focus:border-primary-400 block w-full p-3 sm:text-sm border-gray-300 rounded-full"
          />
        </div>
      </div>
      <div className="w-full h-screen scrollbar-hide">
        <div className="h-full overflow-y-auto divide-y-2 scrollbar-hide">
          {conversations.length ? (
            conversations
              .filter(conversation => !conversation.deletedBy.includes(userState.id))
              .map((conversation, index) => <Conversation key={index} conversation={conversation} setShowMessages={setShowMessages} reload={reload} />)
          ) : (
            <DefaultState
              icon="question_answer"
              heading="No Conversation Found"
              description="All conversations will appear here"
              action={() => navigate("/dashboard/neighborhood/members")}
              actionText="Start a new conversation"
            />
          )}
          {fetching && <Spinner displayText="Fetching..." />}

          {remaining.current > 0 ? (
            <span ref={setElement} className="w-full flex justify-center text-gray-400">
              Loading...
            </span>
          ) : null}

          {conversations.length && remaining.current <= 0 ? <span className="w-full flex justify-center text-gray-400">No more conversations to load</span> : null}
        </div>
      </div>
    </div>
  );
}

export default Conversations;
