/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";

function InfoWindowModal({ image, listingName, address }) {
  return (
    <div className="w-[200px]">
      <div className="w-full h-full">
        <img src={image ? image : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"} className="w-full h-full object-contain" />
      </div>
      <h1 className="text-lg text-success-800 font-bold">{listingName}</h1>
      <p className="w-[200px] text-md text-gray-500 truncate">{address}</p>
    </div>
  );
}

export default InfoWindowModal;
