/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import ButtonPrimary from "../button/ButtonPrimary";
import { GoogleMap, Autocomplete, useLoadScript, Marker } from "@react-google-maps/api";
import Spinner from "../../components/spinner/Spinner";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_OPTIONS, DEFAULT_MAP_ZOOM } from "../../constant";

const libraries = ["places"];
function Location({ location, setLocation, callback }) {
  const [autoComplete, setAutoComplete] = React.useState(null);
  const [googleMap, setGoogleMap] = React.useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
    region: "US",
  });

  const handleDone = () => {
    callback();
  };
  const onLoad = (autoComplete) => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const {
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
      } = autoComplete.getPlace();
      const latitude = lat();
      const longitude = lng();
      setLocation({ address: formatted_address, coordinates: { lat: latitude, lng: longitude }, location: { type: "Point", coordinates: [longitude, latitude] } });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onPositionChanged = () => {
    if (googleMap) {
      googleMap.panTo(location.coordinates);
    }
  };
  return (
    <div className="w-full flex items-end justify-center text-center sm:block sm:p-0">
      <div className="w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
        <div className="w-full flex justify-between items-center">
          <h2 className="text-lg font-bold capitalize">Add a location</h2>
          <div>
            <ButtonPrimary size="md" text="Done" action={handleDone} />
          </div>
        </div>
        <div className="mt-1 w-full">
          {isLoaded ? (
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <input type="text" name="name" id="name" className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3" />
            </Autocomplete>
          ) : (
            <Spinner displayText="Loading..." />
          )}
        </div>
        <div className="w-full h-40">
          {isLoaded ? (
            <GoogleMap onLoad={(map) => setTimeout(() => setGoogleMap(map))} options={DEFAULT_MAP_OPTIONS} zoom={DEFAULT_MAP_ZOOM} center={DEFAULT_MAP_CENTER} mapContainerClassName="h-full w-full">
              {location && googleMap && <Marker position={location.coordinates} animation={["DROP"]} onPositionChanged={onPositionChanged} title={location.address} />}
            </GoogleMap>
          ) : (
            <Spinner displayText="Loading Map..." />
          )}
        </div>
      </div>
    </div>
  );
}

export default Location;
