/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { MdClear, MdLocalSee } from "react-icons/md";
import { BADGES, BUSINESS_CATEGORY, COUPON_BG_TEMPLATE, LISTING_COVER_TEMPLATE, LOGO_TEMPLATE, PAYMENT_METHODS, PRICE } from "../../constant";
import notify from "../../services/toast";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { generateClassName, inArray, inArrayObject, getValueFromArrayOfObject } from "../../services/util";
import { validateBusinessName, validateCoupon, validateEmail, validateJobTitle, validateMessage, validateUserName, validatePhone, validateUrl } from "../../services/validation";
import useUserContext from "./../../hooks/useUserContext";
import handleUpdate from "./updateListing";
import ButtonPrimary from "../button/ButtonPrimary";
import DefaultState from "../default_state/DefaultState";
import PaymentOptionCard from "../payment_option_card/PaymentOptionCard";
import DisplayCouponCard from "../display_coupon_card/DisplayCouponCard";
import SelectInput from "../select_input/SelectInput";
import FileUploader from "../file_uploader/FileUploader";
import AmenityCard from "../amenity_card/AmenityCard";
import { GoogleMap, Autocomplete, useLoadScript, Marker } from "@react-google-maps/api";
import Spinner from "../../components/spinner/Spinner";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_OPTIONS, DEFAULT_MAP_ZOOM, PLANS } from "../../constant";
import handleUpdateCoupon from "./updateCoupon";
import { Link } from "react-router-dom";
import TimeCard from "../time_card/TimeCard";

const libraries = ["places"];
const backgroundCriteria = { requiredSize: 5e6, requiredDimensions: { width: 250, height: 250 } };
const logoCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };
const coverPhotoCriteria = { requiredSize: 6e6, requiredDimensions: { width: 400, height: 400 } };
const galleryPhotoCriteria = { requiredSize: 5e6, requiredDimensions: { width: 1000, height: 800 } };
const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}
function ListingUpdate({ listing, setEditingMode, reload }) {
  const {
    userState: { accessToken, id },
    subscription: { product },
  } = useUserContext();

  const handleClose = () => {
    setEditingMode(false);
    reload(accessToken, 0, 6);
  };

  const [businessName, setBusinessName] = React.useState(listing?.businessName || "");
  const [businessEmail, setBusinessEmail] = React.useState(listing?.businessEmail || "");
  const [businessPhone, setBusinessPhone] = React.useState(listing?.businessPhoneNumber || "");
  const [businessDescription, setBusinessDescription] = React.useState(listing?.businessDescription || "");
  const [businessWebsite, setBusinessWebsite] = React.useState(listing?.businessWebsite || "");
  const [businessFacebook, setBusinessFacebook] = React.useState(listing?.businessFacebook || "");
  const [businessInstagram, setBusinessInstagram] = React.useState(listing?.businessInstagram || "");
  const [businessLinkedIn, setBusinessLinkedIn] = React.useState(listing?.businessLinkedIn || "");
  const [businessPrice, setBusinessPrice] = React.useState(listing?.price || "");
  const [businessCategory, setBusinessCategory] = React.useState(listing?.businessCategory || "");
  const [businessAmenities, setBusinessAmenities] = React.useState(listing?.businessAmenities || []);
  const [subCategory, setSubCategory] = React.useState(listing?.businessSubCategory || null);
  const [loadSubCategory, setloadSubCategory] = React.useState([]);

  const [ownerFirstName, setOwnerFirstName] = React.useState(listing?.businessOwner?.firstName || "");
  const [ownerLastName, setOwnerLastName] = React.useState(listing?.businessOwner?.lastName || "");
  const [ownerEmail, setOwnerEmail] = React.useState(listing?.businessOwner?.emailAddress || "");
  const [ownerJobTitle, setOwnerJobTitle] = React.useState(listing?.businessOwner?.jobTitle || "");

  const [validBusinessName, setValidBusinessName] = React.useState(false);
  const [validBusinessEmail, setValidBusinessEmail] = React.useState(false);
  const [validBusinessPhone, setValidBusinessPhone] = React.useState(false);
  const [validBusinessDescription, setValidBusinessDescription] = React.useState(false);
  const [validBusinessWebsite, setValidBusinessWebsite] = React.useState(false);
  const [validBusinessFacebook, setValidBusinessFacebook] = React.useState(false);
  const [validBusinessInstagram, setValidBusinessInstagram] = React.useState(false);
  const [validBusinessLinkedIn, setValidBusinessLinkedIn] = React.useState(false);

  const [validOwnerFirstName, setValidOwnerFirstName] = React.useState(false);
  const [validOwnerLastName, setValidOwnerLastName] = React.useState(false);
  const [validOwnerEmail, setValidOwnerEmail] = React.useState(false);
  const [validOwnerJobTitle, setValidOwnerJobTitle] = React.useState(false);

  const [coupons, setCoupons] = React.useState([]);
  const [couponName, setCouponName] = React.useState("");
  const [expirationDate, setExpirationDate] = React.useState("");
  const [couponCode, setCouponCode] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [background, setBackground] = React.useState(null);
  const [checked, setChecked] = React.useState(null);

  const [validCouponName, setValidCouponName] = React.useState(false);
  const [validExpirationDate, setValidExpirationDate] = React.useState(false);
  const [validCouponCode, setValidCouponCode] = React.useState(false);
  const [validDescription, setValidDescription] = React.useState(false);

  React.useEffect(() => {
    const result = validateCoupon(couponName);
    setValidCouponName(result);
  }, [couponName]);

  React.useEffect(() => {
    const result = expirationDate.toString().length > 0;
    setValidExpirationDate(result);
  }, [expirationDate]);

  React.useEffect(() => {
    const result = validateCoupon(couponCode);
    setValidCouponCode(result);
  }, [couponCode]);

  React.useEffect(() => {
    const result = validateMessage(description);
    setValidDescription(result);
  }, [description]);

  const validateCouponForm = () => {
    if (couponName || couponCode || expirationDate || description) {
      if (!couponName || !couponCode || !expirationDate || !description) {
        notify("You must provide all coupon details.", "error");
      } else {
        return true;
      }
    }
    return false;
  };

  const handleAddCoupons = () => {
    if (!validateCouponForm()) {
      return;
    }

    if (coupons.length >= 4) {
      notify("You can only add up to 4 coupons per listing", "error");
      return;
    }

    const payload = {
      couponName,
      expirationDate,
      couponCode,
      description,
      background,
    };

    if (inArrayObject(coupons, payload.couponCode, "couponCode")) {
      notify(`${payload.couponCode} already exists in your Coupon List.`, "error");
      return;
    }
    setCoupons(prevState => {
      return [...prevState, payload];
    });
    setCouponName("");
    setExpirationDate("");
    setCouponCode("");
    setDescription("");
    setBackground(null);
    setChecked(null);
  };

  const handleRemoveCoupon = event => {
    const { coupon } = event.target.dataset;
    const newCouponList = coupons.filter((value, index) => parseInt(coupon, 10) !== index);
    setCoupons(newCouponList);
  };

  const handleBackgroundUpload = result => {
    setBackground(result);
  };

  const handleSelectTemplate = event => {
    const templateIndex = parseInt(event.target.value, 10);
    setChecked(templateIndex);
    const template = COUPON_BG_TEMPLATE[templateIndex];
    setBackground(template);
  };

  const updateCoupon = async () => {
    if (couponName || couponCode || expirationDate || description) {
      if (!validCouponName || !validCouponCode || !validExpirationDate || !validDescription) {
        notify("One or more fields are invalid. Please check before submitting.", "error");
        return;
      }
    }
    const newCoupon = coupons[coupons.length - 1];

    try {
      const payload = {
        couponName: newCoupon.couponName,
        expiration: newCoupon.expirationDate,
        couponCode: newCoupon.couponCode,
        useAge: newCoupon.description,
        background: newCoupon.background,
        businessListing: listing._id,
      };
      const response = await handleUpdateCoupon(accessToken, payload);
      if (response.status === "success") {
        notify("Coupon successfully updated.", "info");
      } else {
        notify("An error occurred while updating coupon. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  //Photos
  const [logo, setLogo] = React.useState(listing?.businessPhotos?.logo || null);
  const [coverPhoto, setCoverPhoto] = React.useState(listing?.businessPhotos?.coverPhoto || null);
  const [gallery, setGallery] = React.useState(listing?.businessPhotos?.gallery || []);

  const [logoTemplate, setLogoTemplate] = React.useState(null);
  const [coverTemplate, setCoverTemplate] = React.useState(null);
  const [galleryTemplate, setGalleryTemplate] = React.useState(null);

  const handleListingLogoUpload = result => {
    setLogo(result);
  };

  const handleListingTemplateLogo = event => {
    const templateIndex = parseInt(event.target.value, 10);
    setLogoTemplate(templateIndex);
    const template = LOGO_TEMPLATE[templateIndex];
    setLogo(template);
  };

  const handleListingCoverPhotoUpload = result => {
    setCoverPhoto(result);
  };

  const handleListingTemplateCover = event => {
    const templateIndex = parseInt(event.target.value, 10);
    setCoverTemplate(templateIndex);
    const template = LISTING_COVER_TEMPLATE[templateIndex];
    setCoverPhoto(template);
  };

  const handleListingGalleryUpload = result => {
    setGallery(prevState => {
      return [...prevState, result];
    });
  };

  const handleListingTemplateGallery = event => {
    if (gallery?.length >= 9) {
      notify("You can upload a maximum of 9 images", "error");
      return;
    }
    const templateIndex = parseInt(event.target.value, 10);
    setGalleryTemplate(templateIndex);
    const template = LISTING_COVER_TEMPLATE[templateIndex];
    setGallery(prevState => {
      return [...prevState, template];
    });
  };

  const clearGallery = () => {
    setGallery([]);
  };

  const updatePhoto = async () => {
    try {
      const payload = {
        update: {
          businessPhotos: {
            logo: logo || null,
            coverPhoto: coverPhoto || null,
            gallery: gallery.length ? gallery : null,
          },
        },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Photos has been updated.", "success");
      } else {
        notify("An error occurred while updating photos. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  //Payment methods
  const [paymentOptions, setPaymentOptions] = React.useState(listing?.paymentOptions || []);
  const [paymentOption, setPaymentOption] = React.useState(null);
  const handleAddPaymentOption = event => {
    event.preventDefault();
    if (!paymentOption) {
      return;
    }

    if (inArray(paymentOptions, paymentOption.value)) {
      notify(`${paymentOption.label} already exists in your payment option list.`, "error");
      return;
    }
    setPaymentOptions(prevState => {
      return [...prevState, paymentOption.value];
    });
    setPaymentOption(null);
  };

  const handleRemovePaymentOption = event => {
    const { paymentoption } = event.target.dataset;
    const newPaymentOptions = paymentOptions.filter((value, index) => parseInt(paymentoption, 10) !== index);
    setPaymentOptions(newPaymentOptions);
  };

  //Business Hours
  const [businessHours, setBusinessHours] = React.useState(listing?.businessHours || null);
  const updateBusinessHours = async () => {
    try {
      const { businessHours: bHours } = businessHours;
      const payload = {
        update: {
          businessHours: { ...businessHours, ...bHours },
        },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Business hours successfully updated.", "info");
      } else {
        notify("An error occurred while updating business hours. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  //Amenities
  const [newAmenity, setNewAmenity] = React.useState(null);
  const handleAddAmenities = event => {
    event.preventDefault();
    if (!newAmenity) {
      return;
    }

    if (inArrayObject(businessAmenities, newAmenity.value, "value")) {
      notify(`${newAmenity.label} already exists in your amenities list.`, "error");
      return;
    }
    setBusinessAmenities(prevState => {
      return [...prevState, newAmenity];
    });
    setNewAmenity(null);
  };
  const handleRemoveAmenities = event => {
    const { amenity } = event.target.dataset;
    const newAmenityList = businessAmenities.filter((value, index) => parseInt(amenity, 10) !== index);
    setBusinessAmenities(newAmenityList);
  };

  //Business address
  const [autoComplete, setAutoComplete] = React.useState(null);
  const [place, setPlace] = React.useState(listing?.businessAddress || null);
  const [googleMap, setGoogleMap] = React.useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
    region: "US",
  });

  const onLoad = autoComplete => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const {
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
      } = autoComplete.getPlace();
      const latitude = lat();
      const longitude = lng();
      setPlace({ address: formatted_address, coordinates: { lat: latitude, lng: longitude }, location: { type: "Point", coordinates: [longitude, latitude] } });
    }
  };

  const onPositionChanged = () => {
    if (googleMap) {
      googleMap.panTo(place.coordinates);
    }
  };

  //Badges
  const [listingBadges, setListingBadges] = React.useState(listing?.badges || []);

  const handleChange = event => {
    const checkedValue = event.target.checked;
    if (checkedValue) {
      setListingBadges(prevState => [...prevState, event.target.value]);
    } else {
      if (listingBadges.includes(event.target.value)) {
        const newList = listingBadges.filter(value => value !== event.target.value);
        setListingBadges(newList);
      }
    }
  };

  React.useEffect(() => {
    const result = validateBusinessName(businessName);
    setValidBusinessName(result);
  }, [businessName]);

  React.useEffect(() => {
    const result = validateEmail(businessEmail);
    setValidBusinessEmail(result);
  }, [businessEmail]);

  React.useEffect(() => {
    const result = validatePhone(businessPhone);
    setValidBusinessPhone(result);
  }, [businessPhone]);

  React.useEffect(() => {
    const result = validateMessage(businessDescription);
    setValidBusinessDescription(result);
  }, [businessDescription]);

  React.useEffect(() => {
    const result = validateUrl(businessWebsite);
    setValidBusinessWebsite(result);
  }, [businessWebsite]);

  React.useEffect(() => {
    const result = validateUrl(businessFacebook);
    setValidBusinessFacebook(result);
  }, [businessFacebook]);

  React.useEffect(() => {
    const result = validateUrl(businessInstagram);
    setValidBusinessInstagram(result);
  }, [businessInstagram]);

  React.useEffect(() => {
    const result = validateUrl(businessLinkedIn);
    setValidBusinessLinkedIn(result);
  }, [businessLinkedIn]);

  React.useEffect(() => {
    const result = validateUserName(ownerFirstName);
    setValidOwnerFirstName(result);
  }, [ownerFirstName]);

  React.useEffect(() => {
    const result = validateUserName(ownerLastName);
    setValidOwnerLastName(result);
  }, [ownerLastName]);

  React.useEffect(() => {
    const result = validateEmail(ownerEmail);
    setValidOwnerEmail(result);
  }, [ownerEmail]);

  React.useEffect(() => {
    const result = validateJobTitle(ownerJobTitle);
    setValidOwnerJobTitle(result);
  }, [ownerJobTitle]);

  const updateBusinessInfo = async () => {
    if (!businessName) {
      notify("You must provide a business name.", "error");
      return;
    }
    try {
      const payload = {
        update: {
          businessName: businessName,
          businessEmail: businessEmail,
          businessPhoneNumber: businessPhone,
          businessDescription: businessDescription,
          businessWebsite: businessWebsite,
          businessFacebook: businessFacebook,
          businessInstagram: businessInstagram,
          businessLinkedIn: businessLinkedIn,
          businessCategory,
          businessSubCategory: subCategory,
          businessAmenities: businessAmenities,
        },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Business information successfully updated.", "info");
      } else {
        notify("An error occurred while updating business information. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const updateBusinessOwnerInfo = async () => {
    if (!ownerFirstName || !ownerLastName || !ownerEmail || !ownerJobTitle) {
      notify("You must provide a point of contact first name, last name, email and job title.", "error");
      return;
    }
    try {
      const payload = {
        update: {
          businessOwner: {
            firstName: ownerFirstName,
            lastName: ownerLastName,
            emailAddress: ownerEmail,
            jobTitle: ownerJobTitle,
          },
        },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Point of contact has been updated.", "success");
      } else {
        notify("An error occurred while updating business information. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const updateBusinessPrice = async () => {
    try {
      const payload = {
        update: { price: businessPrice },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Your price range successfully updated.", "info");
      } else {
        notify("An error occurred while updating your price range. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const updateBusinessPaymentOptions = async () => {
    try {
      const payload = {
        update: { paymentOptions: paymentOptions },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Your payment options are successfully updated.", "info");
      } else {
        notify("An error occurred while updating your payment options. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const updateBusinessAmenity = async () => {
    try {
      const payload = {
        update: { businessAmenities: businessAmenities },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Your business amenities are successfully updated.", "info");
      } else {
        notify("An error occurred while updating your business amenities. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const updateBusinessAddress = async () => {
    try {
      const payload = {
        update: { businessAddress: place },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Your business address is successfully updated.", "info");
      } else {
        notify("An error occurred while updating your business address. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const updateBadge = async () => {
    try {
      const payload = {
        update: { badges: listingBadges },
        listingId: listing._id,
      };
      const response = await handleUpdate(accessToken, payload);
      if (response.status === "success") {
        notify("Badges are successfully updated.", "info");
      } else {
        notify("An error occurred while updating your badges. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleCategoryChange = (event) => {
    const { value } = event;
    setBusinessCategory(value);
    setSubCategory(null);
    setloadSubCategory(BUSINESS_CATEGORY[value]["subCategory"]);
    setBusinessAmenities([]);
  };

  const handleSubCategoryChange = (event) => {
    const { value } = event;
    setSubCategory(value);
  };

  const generateBusinessCategory = () => {
    const businessCategories = [];

    for (const businessCategory in BUSINESS_CATEGORY) {
      businessCategories.push(BUSINESS_CATEGORY[businessCategory]["mainCategory"]);
    }
    return businessCategories;
  };

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
        <MdClear className="text-xl cursor-pointer" onClick={handleClose} />
        <div className="w-full mt-10 space-y-5">
          <div className="w-full flex gap-2 items-end">
            <div className="w-full">
              <h1 className="mb-5 text-xl font-bold text-success-800">Business Information</h1>
              <div className="w-full flex justify-between items-center">
                <label htmlFor="business_name" className="block text-sm font-medium text-gray-700">
                  Business Name
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={event => setBusinessName(event.target.value)}
                  type="text"
                  name="business_name"
                  id="business_name"
                  autoComplete="off"
                  value={businessName}
                  className={generateClassName(!validBusinessName && businessName)}
                  placeholder="M&H Tech"
                  aria-describedby="name-optional"
                />
              </div>
            </div>
          </div>

          <div className="w-full flex gap-2 items-end">
            <div className="w-full">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="business_name" className="block text-sm font-medium text-gray-700">
                  Business Email
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={event => setBusinessEmail(event.target.value)}
                  type="text"
                  name="business_email"
                  id="business_email"
                  autoComplete="off"
                  value={businessEmail}
                  className={generateClassName(!validBusinessEmail && businessEmail)}
                  placeholder="john@gmail.com"
                  aria-describedby="name-optional"
                />
              </div>
            </div>
          </div>

          <div className="w-full flex gap-2 items-end">
            <div className="w-full">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="business_name" className="block text-sm font-medium text-gray-700">
                  Business Category
                </label>
              </div>
              <div className="mt-1">
                <SelectInput
                  options={generateBusinessCategory()}
                  noOptionsMessage="No categories found"
                  placeholder="Select a business category"
                  defaultValue={() => {
                    return businessCategory ? { value: businessCategory, label: BUSINESS_CATEGORY[businessCategory]["mainCategory"]["label"] } : null;
                  }}
                  setSelectedValue={handleCategoryChange}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex gap-2 items-end">
            <div className="w-full">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="business_name" className="block text-sm font-medium text-gray-700">
                  Business Sub Category
                </label>
              </div>
              <div className="mt-1">
                <SelectInput
                  options={BUSINESS_CATEGORY[businessCategory]?.subCategory || []}
                  noOptionsMessage="Select a business category"
                  placeholder="Select a business category"
                  defaultValue={() => {
                    return subCategory ? { value: subCategory, label: getValueFromArrayOfObject(BUSINESS_CATEGORY[businessCategory]["subCategory"], { value: subCategory }) } : null;
                  }}
                  setSelectedValue={handleSubCategoryChange}
                />
              </div>
            </div>
          </div>

          {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
            <div className="w-full flex gap-2 items-end">
              <div className="w-full">
                <div className="w-full flex justify-between items-center">
                  <label htmlFor="business_name" className="block text-sm font-medium text-gray-700">
                    Business Phone
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    onChange={event => setBusinessPhone(event.target.value)}
                    type="text"
                    name="business_phone"
                    id="business_phone"
                    autoComplete="off"
                    value={businessPhone}
                    className={generateClassName(!validBusinessPhone && businessPhone)}
                    placeholder="6328569854"
                    aria-describedby="name-optional"
                  />
                </div>
              </div>
            </div>
          {/* ) : null} */}

          <div className="w-full flex gap-2 items-end">
            <div className="w-full">
              <div className="w-full flex justify-between">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Business Description
                </label>
              </div>
              <div className="mt-1">
                <textarea
                  onChange={event => setBusinessDescription(event.target.value)}
                  value={businessDescription}
                  rows="4"
                  name="description"
                  id="description"
                  className={generateClassName(!validBusinessDescription && businessDescription)}></textarea>
              </div>
            </div>
          </div>

          {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
            <>
              <div className="w-full flex gap-2 items-end">
                <div className="w-full">
                  <div className="w-full flex justify-between">
                    <label htmlFor="website" className="block text-sm font-medium text-gray-700">
                      Website
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      onChange={event => setBusinessWebsite(event.target.value)}
                      type="url"
                      name="website"
                      id="website"
                      value={businessWebsite}
                      className={generateClassName(!validBusinessWebsite && businessWebsite)}
                      placeholder="https://www.website.com"
                      aria-describedby="website-optional"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex gap-2 items-end">
                <div className="w-full">
                  <div className="w-full flex justify-between">
                    <label htmlFor="facebook" className="block text-sm font-medium text-gray-700">
                      Facebook
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      onChange={event => setBusinessFacebook(event.target.value)}
                      type="url"
                      name="facebook"
                      id="facebook"
                      value={businessFacebook}
                      className={generateClassName(!validBusinessFacebook && businessFacebook)}
                      placeholder="https://www.facebook.com"
                      aria-describedby="facebook-optional"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex gap-2 items-end">
                <div className="w-full">
                  <div className="w-full flex justify-between">
                    <label htmlFor="instagram" className="block text-sm font-medium text-gray-700">
                      Instagram
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      onChange={event => setBusinessInstagram(event.target.value)}
                      type="url"
                      name="instagram"
                      id="instagram"
                      value={businessInstagram}
                      className={generateClassName(!validBusinessInstagram && businessInstagram)}
                      placeholder="https://www.instagram.com"
                      aria-describedby="instagram-optional"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex gap-2 items-end">
                <div className="w-full">
                  <div className="w-full flex justify-between">
                    <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700">
                      Linkedin
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      onChange={event => setBusinessLinkedIn(event.target.value)}
                      type="url"
                      name="linkedin"
                      id="linkedin"
                      value={businessLinkedIn}
                      className={generateClassName(!validBusinessLinkedIn && businessLinkedIn)}
                      placeholder="https://www.linkedin.com"
                      aria-describedby="linkedin-optional"
                    />
                  </div>
                </div>
              </div>
            </>
          {/* ) : null} */}

          <button
            type="button"
            onClick={updateBusinessInfo}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update business information
          </button>
          <hr />

          <div className="w-full">
            <h1 className="mb-5 text-xl font-bold text-success-800">Point of contact information</h1>
            <div className="w-full flex justify-between items-center">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                Point of Contact First Name
              </label>
            </div>
            <div className="mt-1">
              <input
                onChange={event => setOwnerFirstName(event.target.value)}
                type="text"
                name="first_name"
                id="first_name"
                value={ownerFirstName}
                className={generateClassName(!validOwnerFirstName && ownerFirstName)}
                placeholder="Mary"
              />
            </div>
          </div>

          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Point of Contact Last Name
              </label>
            </div>
            <div className="mt-1">
              <input
                onChange={event => setOwnerLastName(event.target.value)}
                type="text"
                name="last_name"
                id="last_name"
                value={ownerLastName}
                className={generateClassName(!validOwnerLastName && ownerLastName)}
                placeholder="Smith"
              />
            </div>
          </div>

          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="email" className="items-center text-sm font-medium text-gray-700">
                Point of Contact Email Address
              </label>
            </div>
            <div className="mt-1">
              <input
                onChange={event => setOwnerEmail(event.target.value)}
                type="email"
                name="email"
                id="email"
                value={ownerEmail}
                className={generateClassName(!validOwnerEmail && ownerEmail)}
                placeholder="mary.smith@gmail.com"
              />
            </div>
          </div>

          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Point of Contact Job Title
              </label>
            </div>
            <div className="mt-1">
              <input
                onChange={event => setOwnerJobTitle(event.target.value)}
                type="text"
                name="title"
                id="title"
                value={ownerJobTitle}
                className={generateClassName(!validOwnerJobTitle && ownerJobTitle)}
                placeholder="Head Of Marketing"
                aria-describedby="name-optional"
              />
            </div>
          </div>

          <button
            type="button"
            onClick={updateBusinessOwnerInfo}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update point of contact information
          </button>
          <hr />
          {/* <div className="w-full flex gap-2 items-end">
            <div className="w-full">
              <h1 className="mb-5 text-xl font-bold text-success-800">Price</h1>
              <div className="w-full flex justify-between items-center">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  Price
                </label>
              </div>
              <div className="mt-1">
                <Select
                  onChange={event => setBusinessPrice(event.value)}
                  placeholder="Start typing..."
                  isDisabled={false}
                  isClearable={false}
                  isLoading={false}
                  isRtl={false}
                  menuPlacement="auto"
                  isSearchable={true}
                  name="Business Category"
                  defaultValue={() => {
                    return businessPrice ? { value: businessPrice, label: businessPrice } : null;
                  }}
                  options={PRICE}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ecf8ef",
                      primary: "#56c271",
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={updateBusinessPrice}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update price
          </button>
          <hr /> */}

          {/* <div className="w-full">
            <h1 className="mb-5 text-xl font-bold text-success-800">Payment methods</h1>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
              Payment methods
            </label>
            <div className="w-full flex gap-2 items-end">
              <div className="w-full flex items-center justify-start gap-4 flex-wrap">
                <form className="w-full" onSubmit={handleAddPaymentOption}>
                  <div className="w-full flex items-end gap-3">
                    <div className="w-full">
                      <div className="w-full mt-1">
                        <CreatableSelect
                          placeholder="Select or create a payment option"
                          isDisabled={false}
                          isClearable={false}
                          isLoading={false}
                          isRtl={false}
                          isSearchable={true}
                          name="payment options"
                          value={paymentOption}
                          menuPlacement="auto"
                          options={PAYMENT_METHODS}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#ecf8ef",
                              primary: "#56c271",
                            },
                          })}
                          onChange={event => setPaymentOption(event)}
                        />
                      </div>
                    </div>
                    <div>
                      <ButtonPrimary icon="add" size="md" />
                    </div>
                  </div>
                </form>
                <div className="w-full">
                  <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                    {!paymentOptions.length ? (
                      <DefaultState icon="credit_card" heading="No payment option found" description="Add payment options you accept" />
                    ) : (
                      paymentOptions.map((value, index) => <PaymentOptionCard key={index} paymentOption={{ value, index }} removePaymentOption={handleRemovePaymentOption} />)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={updateBusinessPaymentOptions}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update payment options
          </button>
          <hr /> */}

          {/* <div className="w-full">
            <h1 className="mb-5 text-xl font-bold text-success-800">Business Amenities</h1>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
              Amenities
            </label>
            <div className="w-full flex gap-2 items-end">
              <div className="w-full flex items-center justify-start gap-4 flex-wrap">
                <form className="w-full" onSubmit={handleAddAmenities}>
                  <div className="w-full flex items-end gap-3">
                    <div className="w-full">
                      <div className="w-full mt-1">
                        <CreatableSelect
                          placeholder="Select or create a new amenity"
                          styles={{
                            input: (provided, state) => ({
                              ...provided,
                              padding: 5,
                            }),
                          }}
                          isDisabled={false}
                          isClearable={false}
                          isLoading={false}
                          isRtl={false}
                          isSearchable={true}
                          noOptionsMessage={() => "Select a business category"}
                          name="amenity"
                          value={newAmenity}
                          menuPlacement="auto"
                          options={BUSINESS_CATEGORY[businessCategory]?.amenities || []}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#ecf8ef",
                              primary: "#56c271",
                            },
                          })}
                          onChange={(event) => setNewAmenity(event)}
                        />
                      </div>
                    </div>
                    <div>
                      <ButtonPrimary icon="add" size="md" />
                    </div>
                  </div>
                </form>
                <div className="w-full">
                  <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                    {!businessAmenities.length ? (
                      <DefaultState icon="miscellaneous_services" heading="No Amenities Found" description="Add additional services your business provide" />
                    ) : (
                      businessAmenities.map((value, index) => <AmenityCard key={index} category={businessCategory} amenity={{ value, index }} removeAmenity={handleRemoveAmenities} />)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={updateBusinessAmenity}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update amenities
          </button>
          <hr /> */}

          <div className="w-full ">
            <h1 className="text-xl font-bold text-success-800">Create coupons</h1>
            <div className="w-full">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="coupon_name" className="block text-sm font-medium text-gray-700">
                  Coupon Name
                </label>
              </div>
              <div className="mt-1">
                <input
                  maxLength={30}
                  onChange={event => setCouponName(event.target.value)}
                  type="text"
                  name="coupon_name"
                  id="coupon_name"
                  value={couponName}
                  className={generateClassName(!validCouponName && couponName)}
                  placeholder="3 Months Free Burgers On Us"
                  aria-describedby="name-optional"
                />
              </div>
              <p className="text-sm text-gray-300">30 characters max</p>
            </div>
            <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:gap-3 sm:justify-between sm:items-center">
              <div className="w-full">
                <div className="w-full flex justify-between">
                  <label htmlFor="expiration_date" className="block text-sm font-medium text-gray-700">
                    Expiration Date
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    onChange={event => setExpirationDate(event.target.value)}
                    type="date"
                    name="expiration_date"
                    id="expiration_date"
                    value={expirationDate}
                    className={generateClassName(!validExpirationDate && expirationDate)}
                    placeholder="04/23/2022"
                    aria-describedby="date-optional"
                  />
                </div>
                <p className="text-sm text-gray-300"></p>
              </div>
              <div className="w-full">
                <div className="w-full flex justify-between">
                  <label htmlFor="coupon_code" className="block text-sm font-medium text-gray-700">
                    Coupon Code
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    maxLength={15}
                    onChange={event => setCouponCode(event.target.value)}
                    type="text"
                    name="coupon_code"
                    id="coupon_code"
                    value={couponCode}
                    className={generateClassName(!validCouponCode && couponCode)}
                    placeholder="FREEBUG"
                    aria-describedby="code-optional"
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full flex justify-between">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Details on How to Use the Coupon
                </label>
              </div>
              <div className="mt-1">
                <textarea
                  maxLength={50}
                  rows="2"
                  onChange={event => setDescription(event.target.value)}
                  value={description}
                  name="description"
                  id="description"
                  className={generateClassName(!validDescription && description)}></textarea>
              </div>
              <p className="text-sm text-gray-300">50 characters max</p>

              <div className="mt-3 flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
                {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
                  <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                    {background?.small.url ? <img src={background.small.url} className="w-full h-full" alt="background upload" /> : null}
                    <FileUploader
                      fileType="image/*"
                      isMultiple={false}
                      validationCriteria={backgroundCriteria}
                      DisplayIcon={PhotoDisplay}
                      accessToken={accessToken}
                      folder="coupon_background_images"
                      description="250 X 250 5MB Background Image"
                      numberOfFiles={1}
                      callback={handleBackgroundUpload}
                    />
                  </div>
                {/* ) : null} */}
                <div className="w-full sm:max-w-full flex gap-3 overflow-x-auto ">
                  {COUPON_BG_TEMPLATE.length
                    ? COUPON_BG_TEMPLATE.map((template, index) => {
                        return (
                          <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                            <input
                              type="radio"
                              name="template"
                              value={index}
                              onChange={handleSelectTemplate}
                              className="sr-only"
                              aria-labelledby={`template-${index}`}
                              aria-describedby={`template-${index}`}
                            />
                            <div className="relative h-[200px] w-[200px]">
                              <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                            </div>
                            {checked !== null && index === checked ? (
                              <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ) : null}
                          </label>
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="mt-4 w-full flex justify-end">
                <div className="w-full sm:w-fit">
                  <ButtonPrimary text="Save your coupon" size="md" action={handleAddCoupons} />
                </div>
              </div>
              <div className="mt-5 w-full">
                <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                  {!coupons.length ? (
                    <DefaultState icon="discount" heading="No Coupons Added" description="Add coupons" />
                  ) : (
                    coupons.map((value, index) => <DisplayCouponCard key={index} coupon={{ value, index }} removeCoupon={handleRemoveCoupon} />)
                  )}
                </div>
              </div>
            </div>
          </div>
          <Link to="/dashboard/business_hub/my_listings" className="text-primary-400 underline text-lg block">
            To see and update your coupons follow this link
          </Link>
          <button
            type="button"
            onClick={updateCoupon}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update coupon
          </button>
          <hr />

          <div className="w-full px-4 py-5 sm:p-6 space-y-2">
            <h1 className="text-xl font-bold text-success-800">Photos</h1>
            <div className="w-full flex items-center justify-start gap-4 flex-wrap">
              <div className="flex flex-col justify-center space-y-4">
                <h1 className="text-primary-800 text-lg">Select a logo template</h1>
                <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
                  {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
                    <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                      {logo?.small.url ? <img src={logo.small.url} className="w-full h-full" alt="logo upload" /> : <p className="w-full pt-8 text-md text-center text-gray-400">400 X 400 5MB Logo</p>}
                      <FileUploader
                        fileType="image/*"
                        isMultiple={false}
                        validationCriteria={logoCriteria}
                        DisplayIcon={PhotoDisplay}
                        accessToken={accessToken}
                        folder="profile_photos"
                        description="Logo - Min: 400 X 400, Max: 5MB"
                        numberOfFiles={1}
                        callback={handleListingLogoUpload}
                      />
                    </div>
                  {/* ) : null} */}
                  <div className="w-full sm:max-w-[590px] flex gap-3 overflow-x-auto">
                    {LOGO_TEMPLATE.length
                      ? LOGO_TEMPLATE.map((template, index) => {
                          return (
                            <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                              <input
                                type="radio"
                                name="template"
                                value={index}
                                onChange={handleListingTemplateLogo}
                                className="sr-only"
                                aria-labelledby={`template-${index}`}
                                aria-describedby={`template-${index}`}
                              />
                              <div className="relative h-[200px] w-[200px]">
                                <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                              </div>
                              {logoTemplate !== null && index === logoTemplate ? (
                                <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : null}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <h1 className="text-primary-800 text-lg">Select a cover photo template</h1>
                <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
                  {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
                    <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                      {coverPhoto?.small.url ? (
                        <img src={coverPhoto.small.url} className="w-full h-full" alt="logo upload" />
                      ) : (
                        <p className="w-full text-md text-center text-gray-400">400 X 400 6MB Cover Photo</p>
                      )}
                      <FileUploader
                        fileType="image/*"
                        isMultiple={false}
                        validationCriteria={coverPhotoCriteria}
                        DisplayIcon={PhotoDisplay}
                        accessToken={accessToken}
                        folder="profile_photos"
                        description="Cover Photo - Min: 400 x 400, Max: 6MB"
                        numberOfFiles={1}
                        callback={handleListingCoverPhotoUpload}
                      />
                    </div>
                  {/* ) : null} */}
                  <div className="w-full sm:max-w-[590px] flex gap-3 overflow-x-auto">
                    {LISTING_COVER_TEMPLATE.length
                      ? LISTING_COVER_TEMPLATE.map((template, index) => {
                          return (
                            <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                              <input
                                type="radio"
                                name="template"
                                value={index}
                                onChange={handleListingTemplateCover}
                                className="sr-only"
                                aria-labelledby={`template-${index}`}
                                aria-describedby={`template-${index}`}
                              />
                              <div className="relative h-[200px] w-[200px]">
                                <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                              </div>
                              {coverTemplate !== null && index === coverTemplate ? (
                                <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : null}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <h1 className="text-primary-800 text-lg">Select gallery photo templates</h1>
                <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
                  {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
                    <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                      {gallery?.length ? (
                        <>
                          <img src={gallery[gallery.length - 1].small.url} className="w-full h-full" alt="logo upload" />
                          {gallery.length ? (
                            <div className="w-full flex justify-between">
                              <p className="text-md text-success-800 font-bold text-center">+ {gallery.length - 1}</p>
                              <p className="text-md text-success-800 font-bold cursor-pointer underline" onClick={clearGallery}>
                                Clear gallery
                              </p>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <p className="w-full text-md text-center text-gray-400">1000 X 800 6MB Gallery Photos</p>
                      )}
                      <FileUploader
                        fileType="image/*"
                        isMultiple={true}
                        validationCriteria={galleryPhotoCriteria}
                        DisplayIcon={PhotoDisplay}
                        accessToken={accessToken}
                        folder="profile_photos"
                        description="Gallery Photos - Min: 1000 x 800, Max: 6MB"
                        numberOfFiles={9 - gallery.length}
                        callback={handleListingGalleryUpload}
                      />
                    </div>
                  {/* ) : null} */}
                  <div className="w-full sm:max-w-[590px] flex gap-3 overflow-x-auto">
                    {LISTING_COVER_TEMPLATE.length
                      ? LISTING_COVER_TEMPLATE.map((template, index) => {
                          return (
                            <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                              <input
                                type="radio"
                                name={`template-${index}`}
                                value={index}
                                onChange={handleListingTemplateGallery}
                                className="sr-only"
                                aria-labelledby={`template-${index}`}
                                aria-describedby={`template-${index}`}
                              />
                              <div className="relative h-[200px] w-[200px]">
                                <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                              </div>
                              {galleryTemplate !== null && index === galleryTemplate ? (
                                <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : null}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={updatePhoto}
            className="mt-5 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update business photos
          </button>
          <hr />
          <div className="w-full">
            <div className="w-full flex items-center justify-center gap-4 flex-wrap">
              <div className="w-full">
                <div className="w-full flex justify-between items-center">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Business Address
                  </label>
                </div>
                <div className="mt-1">
                  {isLoaded ? (
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                      <input
                        onChange={() => null}
                        type="text"
                        name="name"
                        id="name"
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3"
                        aria-describedby="name-optional"
                      />
                    </Autocomplete>
                  ) : (
                    <Spinner displayText="Loading..." />
                  )}
                </div>
              </div>
              <div className="w-full h-96">
                {isLoaded ? (
                  <GoogleMap
                    onLoad={map => setTimeout(() => setGoogleMap(map))}
                    options={DEFAULT_MAP_OPTIONS}
                    zoom={DEFAULT_MAP_ZOOM}
                    center={DEFAULT_MAP_CENTER}
                    mapContainerClassName="h-full w-full">
                    {place && googleMap && <Marker position={place.coordinates} animation={["DROP"]} onPositionChanged={onPositionChanged} title={place.address} />}
                  </GoogleMap>
                ) : (
                  <Spinner displayText="Loading Map..." />
                )}
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={updateBusinessAddress}
            className="mt-5 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update business address
          </button>
          <hr />

          <div className="w-full py-5 space-y-2">
            <h1 className="text-xl font-bold text-success-800">Business Hours</h1>
            <div className="w-full flex items-center justify-center gap-4 flex-wrap">
              {daysOfWeek.map((dayOfWeek, index) => {
                return <TimeCard dayOfWeek={dayOfWeek} key={index} setListing={setBusinessHours} listing={listing} hour={listing?.businessHours?.[dayOfWeek] || null} />;
              })}
            </div>
          </div>
          <button
            type="button"
            onClick={updateBusinessHours}
            className="mt-5 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Update business hours
          </button>
          <hr />
          {/* <div className="w-full">
            <div className="w-full px-4 py-5 sm:p-6 space-y-5">
              <h1 className="text-xl font-bold text-success-800">Select A Badge</h1>
              <div className="w-full flex items-center justify-start gap-4 flex-wrap">
                {BADGES.map((badge, index) => {
                  return (
                    <div key={index} className="relative flex items-start text-center p-5">
                      <div className="flex items-center h-5">
                        <input
                          onChange={handleChange}
                          id={badge.badgeName}
                          value={badge.badgeName}
                          checked={listingBadges.includes(badge.badgeName) ? true : false}
                          type="checkbox"
                          className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor={badge.badgeName} className="font-medium text-gray-700">
                          <img className="h-40 w-40" src={badge.badge} alt={badge.badgeName} />
                        </label>
                        <p id={badge.badgeName} className="text-gray-500 font-extrabold capitalize text-lg">
                          {badge.badgeName}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              type="button"
              onClick={updateBadge}
              className="mt-5 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
              Update badge
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ListingUpdate;
