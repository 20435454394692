/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { formatDistanceToNow } from "date-fns";
import GroupReaction from "../group_reaction/GroupReaction";
import Reaction from "../reaction/Reaction";
import Option from "../option/Option";
import CommentSection from "../comment_section/CommentSection";
import notify from "../../services/toast";
import createReaction from "./creatReaction";
import useUserContext from "../../hooks/useUserContext";
import getReaction from "./getReaction";
import deletePost from "./deletePost";
import Media from "../media/Media";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { Anchorme } from "react-anchorme";
import ReactionModal from "../reaction_modal/ReactionModal";

const CustomLink = (props) => {
  const { href } = props;
  return (
    <i className="text-primary-400 text-md underline">
      <a href={href}>{href}</a>
    </i>
  );
};

function constructUser(users) {
  if (users?.length >= 3) {
    return `${capitalize(users[0]?.user?.firstName)}, ${capitalize(users[1]?.user?.firstName)} and ${users.length - 2} other(s)`;
  } else if (users?.length === 2) {
    return `${capitalize(users[0]?.user?.firstName)} and ${capitalize(users[1]?.user?.firstName)}`;
  } else if (users?.length === 1) {
    return `${capitalize(users[0]?.user?.firstName)}`;
  }
}

function constructTaggedMembers(taggedMembers) {
  if (taggedMembers?.length >= 4) {
    return `${capitalize(taggedMembers[0]?.firstName)}, ${capitalize(taggedMembers[1]?.firstName)}, ${capitalize(taggedMembers[2]?.firstName)} and ${taggedMembers.length - 3} other(s)`;
  } else if (taggedMembers?.length >= 3) {
    return `${capitalize(taggedMembers[0]?.firstName)}, ${capitalize(taggedMembers[1]?.firstName)} and ${taggedMembers.length - 2} other(s)`;
  } else if (taggedMembers?.length === 2) {
    return `${capitalize(taggedMembers[0]?.firstName)} and ${capitalize(taggedMembers[1]?.firstName)}`;
  } else if (taggedMembers?.length === 1) {
    return `${capitalize(taggedMembers[0]?.firstName)}`;
  }
}

function constructMedia(medias) {
  const totalMedia = medias.length;
  if (medias.length >= 4) {
    return (
      <div className="relative w-full grid grid-cols-2">
        {medias.slice(0, 4).map((media, index) => {
          return <Media key={index} media={media} index={index} multiple={true} total={totalMedia} allMedia={medias} />;
        })}
        {medias.length - 4 ? <p className="absolute cursor-pointer text-xl bottom-[48%] right-[45%] text-white font-bold">+{medias.length - 4} more</p> : null}
      </div>
    );
  } else if (medias.length === 3) {
    return (
      <div className="w-full">
        <div className="w-full grid grid-cols-2">
          <Media media={medias[0]} index={0} multiple={false} total={1} allMedia={medias} />
          <Media media={medias[1]} index={0} multiple={false} total={1} allMedia={medias} />
        </div>
        <Media media={medias[2]} index={0} multiple={false} total={1} allMedia={medias} />
      </div>
    );
  } else if (medias.length <= 2) {
    return (
      <div className="w-full grid grid-cols-1">
        {medias.map((media, index) => {
          return <Media key={index} media={media} index={index} multiple={false} total={1} allMedia={medias} />;
        })}
      </div>
    );
  }
}

function Post({ post, reload, socket, groupId, creatorId, groupName }) {
  const { userState } = useUserContext();
  const [reaction, setReaction] = React.useState(null);
  const [reactionOpen, setReactionOpen] = React.useState(false);
  const [commentOpen, setCommentOpen] = React.useState(false);
  const [totalComments, setTotalComments] = React.useState(0);
  const reactionRef = React.useRef(null);
  const toggleReaction = () => {
    setReactionOpen(!reactionOpen);
  };
  const toggleComment = () => {
    setCommentOpen(!commentOpen);
  };

  const userFirstName = post?.user?.firstName ? post.user.firstName : null;
  const userLastName = post?.user?.lastName ? post.user.lastName : null;
  const userJobTitle = post?.user?.jobTitle ? post.user.jobTitle : null;
  const photo = post?.user?.photo?.small ? post.user.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png";
  const postText = post?.postText ? post.postText : null;
  const postMedia = post?.postMedia.length ? post.postMedia : null;
  const taggedMembers = post?.taggedMembers ? post.taggedMembers : null;
  const formattedCauses = post?.formattedCauses ? post.formattedCauses : null;
  const location = post?.location ? post.location : null;

  const handleGetReaction = async () => {
    try {
      const response = await getReaction(userState.accessToken, post._id);
      if (response.status === "success") {
        setReaction(response.data);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleCreateReaction = async (payload) => {
    try {
      const response = await createReaction(userState.accessToken, payload);
      if (response.status === "success") {
        setReactionOpen(!reactionOpen);
        handleGetReaction();
        if (response.data === "success") {
          socket.current.emit("group_post_reaction", {
            groupId: groupId,
            postId: post._id,
            groupName: groupName,
            userFirstName: userState.firstName,
            userLastName: userState.lastName,
            userId: userState.id,
            creatorId: creatorId,
          });
        }
      } else {
        notify("An error occurred while reacting to post.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      const response = await deletePost(userState.accessToken, postId);
      if (response.status === "success") {
        reload();
        notify("Post successfully deleted.", "info");
      } else {
        notify("An error occurred while deleting post. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const returnActions = () => {
    return [{ text: "Delete", icon: "delete", key: "delete", action: () => handleDeletePost(post._id) }];
  };

  const action = React.useMemo(() => returnActions(), []);

  React.useEffect(() => {
    handleGetReaction();
  }, []);

  return (
    <div className="group w-full bg-white rounded-md shadow-md">
      <div className="w-full p-3 flex justify-between items-start border-b-2 border-gray-200">
        <div className="w-full flex gap-2">
          <img className="w-8 h-8 flex-shrink-0 mx-auto rounded-full" src={photo} alt={userLastName} />
          <div className="w-full flex flex-col">
            <Link to={`/profile/${post.userId}`} className="text-success-800 font-bold truncate underline capitalize">
              {userFirstName} {userLastName}
            </Link>

            <p className="text-xs text-gray-500">{formatDistanceToNow(new Date(post.createdAt), { addSuffix: false })} ago</p>
          </div>
        </div>
        <div className="invisible group-hover:visible">{post.userId === userState.id ? <Option actions={action} itemKey={post.userId} deleteAction={() => handleDeletePost(post._id)} /> : null}</div>
      </div>
      {postText ? (
        <div className="w-full p-4">
          <p className="text-md text-success-800 whitespace-pre-wrap">
            <Anchorme target="_blank" linkComponent={CustomLink}>
              {postText}
            </Anchorme>
          </p>
        </div>
      ) : null}
      {/**Media */}

      {postMedia ? constructMedia(postMedia) : null}
      {taggedMembers.length || formattedCauses || location ? (
        <div className="m-3 px-2 w-full flex-col space-y-2">
          {taggedMembers.length ? (
            <div className="w-full flex gap-1">
              <span className="text-sm material-icons-outlined text-primary-400">local_offer</span>
              <p className="text-sm text-primary-400 truncate">{constructTaggedMembers(taggedMembers)}</p>
            </div>
          ) : null}
          {formattedCauses.length ? (
            <div className="w-full flex gap-1">
              <span className="text-sm material-icons-outlined text-primary-400">local_offer</span>
              <p className="text-sm text-primary-400 truncate">{formattedCauses}</p>
            </div>
          ) : null}
          {location ? (
            <div className="w-full flex gap-1">
              <span className="text-sm material-icons-outlined text-yellow-400">location_on</span>
              <a href={`https://google.com/maps/dir/${location.address}`} target="_blank" rel="noreferrer" className="text-sm text-yellow-400 underline cursor-pointer lowercase truncate">
                {location.address}
              </a>
            </div>
          ) : null}
        </div>
      ) : null}
      {/**Reaction and comments stats */}
      <div className="w-full border-t-2 border-gray-200">
        <div className="w-full p-3 flex justify-between">
          <div className="flex gap-1 items-center">
            <GroupReaction reaction={reaction} />
            <div onClick={() => reactionRef.current.show()} className="text-xs text-success-800 cursor-pointer underline">
              {constructUser(reaction?.users || [])}
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <span className="material-icons-outlined text-sm text-success-800 cursor-pointer">comment</span>
            <span className="text-xs text-success-800 cursor-pointer" onClick={toggleComment}>
              {totalComments} {totalComments > 1 ? "comments" : "comment"}
            </span>
          </div>
        </div>
      </div>

      <div className="w-full border-t-2 border-gray-200">
        <div className="w-full p-3 flex justify-between">
          <div className="relative cursor-pointer">
            <span className={`${reactionOpen ? "block" : "hidden"} absolute bottom-10 transition-all duration-500`}>
              <Reaction createReaction={handleCreateReaction} postId={post._id} />
            </span>
            <span onClick={toggleReaction} className="material-icons-outlined text-success-800">
              add_reaction
            </span>
          </div>
          <div className="cursor-pointer">
            <span onClick={toggleComment} className="material-icons-outlined text-success-800">
              comment
            </span>
          </div>
        </div>

        <div className="w-full p-3">
          <CommentSection totalComments={totalComments} setTotalComments={setTotalComments} postId={post._id} socket={socket} groupId={groupId} groupName={groupName} creatorId={creatorId} />
        </div>
      </div>
      <ReactionModal postId={post._id} ref={reactionRef} />
    </div>
  );
}

export default Post;
