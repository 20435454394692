/* eslint-disable capitalized-comments */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { computeReviewSummary, determineListingHour, getValueFromArrayOfObject, inArrayObject } from "../../services/util";
import { BUSINESS_CATEGORY, PLANS } from "../../constant";
import getDistance from "../display_listing/getDistance";
import createFavorite from "../display_listing/createFavorite";
import Stars from "react-rating";
import { MdOutlineStar, MdOutlineStarOutline, MdLocationOn, MdDiscount } from "react-icons/md";
import useUserContext from "./../../hooks/useUserContext";
import notify from "../../services/toast";

function MarketplaceCard({ listing, userGeoLocation }) {
  const {
    userState,
    subscription: { product },
  } = useUserContext();
  const coverImage = listing?.businessPhotos?.coverPhoto?.small ? listing.businessPhotos.coverPhoto.small.url : null;
  const listingName = listing?.businessName ? listing.businessName : null;
  const listingCategory = listing?.businessCategory ? listing.businessCategory : null;
  const listingSubCategory = listing?.businessSubCategory ? getValueFromArrayOfObject(BUSINESS_CATEGORY[listingCategory]["subCategory"], { value: listing.businessSubCategory }) : null;
  const listingPrice = listing?.price ? listing.price : null;
  const listingAddress = listing?.businessAddress ? listing.businessAddress : null;
  const listingDescription = listing?.businessDescription ? listing.businessDescription : null;
  const reviewData = listing?.reviews ? computeReviewSummary(listing.reviews) : null;
  const listingBusinessHours = listing?.businessHours ? listing.businessHours : null;
  const averageRating = listing?.averageRating ? listing.averageRating : 0;
  const [favoriteList, setFavoriteList] = React.useState(listing?.favorites ? listing.favorites : []);
  const listingCoupons = listing?.coupons ? listing.coupons : null;
  

  const [distanceFromListing, setDistanceFromListing] = React.useState(null);
  const [favorite, setFavorite] = React.useState(inArrayObject(favoriteList, userState.id, "creator"));

  const fetchDistance = async () => {
    try {
      const payload = {
        origin: userGeoLocation,
        destination: listingAddress.coordinates,
      };
      const response = await getDistance(payload);
      if (response.status === "success") {
        const { status, rows } = response.data;
        if (status === "OK") {
          const distanceInMeters = rows[0]["elements"][0]["distance"]["value"];
          const distanceInMiles = (distanceInMeters / 1609).toFixed(2);
          setDistanceFromListing(distanceInMiles);
        }
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateFavorite = async () => {
    try {
      const payload = {
        kind: "Listing",
        target: listing._id,
      };
      const response = await createFavorite(payload, userState.accessToken);
      if (response.status === "success") {
        if (response.status === false) {
          setFavoriteList([]);
        }
        setFavorite(response.data);
      } else {
        notify("An error occurred while creating favorite. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  React.useEffect(() => {
    if (userGeoLocation) {
      fetchDistance();
    }
  }, [listing, userGeoLocation]);

  const renderBusinessHour = (listingHours) => {
    const hour = determineListingHour(listingHours);
    if (hour === "24" || hour === "open") {
      return <p className="w-full text-base text-primary-500 font-bold lowercase">Open Now</p>;
    } else {
      return <p className="w-full text-base text-red-500 font-bold lowercase">Closed</p>;
    }
  };
  console.log(listingName, listing.coupons, "coupon");
  return (
    <div className="relative flex w-full max-w-[26rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg">
      <div className="relative mx-4 mt-4 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
        <Link to={`/dashboard/marketplace/listing/${listing._id}`}>
          <img src={coverImage ? coverImage : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"} alt="" className="w-full h-full rounded-md object-cover" />
        </Link>

        {listingCoupons > 0 ? (
        <div className="absolute top-0 p-6">
          <h5 className="mb-2 text-2xl font-medium leading-tight text-primary-400">
            <MdDiscount />
          </h5>
        </div>
        ) : null}
        
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>
        <button
          className="!absolute top-4 right-4 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase text-red-500 transition-all hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-dark="true"
        >
          <div className="w-full flex justify-between items-center">
            {userState?.accessToken ? (
              <span
                onClick={handleCreateFavorite}
                className={`material-icons-outlined text-2xl cursor-pointer ${favorite || inArrayObject(favoriteList, userState.id, "creator") ? "text-primary-400" : "text-gray-400"
                  } hover:text-primary-400 transition-all duration-500`}
              >
                favorite
              </span>
            ) : null}
          </div>
        </button>
      </div>

      <div className="p-6">
        <div className="mb-3  items-center justify-between">
          <Link to={`/dashboard/marketplace/listing/${listing._id}`}>
            <h3 className="mt-3 text-lg font-semibold leading-6 text-success-800 group-hover:text-gray-600">
              {listingName}
            </h3>
            <div className="w-full flex gap-2 items-center text-base text-gray-500 truncate capitalize">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800 ">
                {listingSubCategory}
              </span>
              {listingBusinessHours ? (
                <>
                  <svg className="h-2 w-2 text-primary-400" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  {renderBusinessHour(listingBusinessHours)}
                </>
              ) : null}
            </div>

          </Link>
        </div>
        <div className="w-full flex gap-2 items-center text-sm text-gray-500 text-ellipsis overflow-hidden whitespace-nowrap">
          {distanceFromListing ? (
            <>
              {distanceFromListing} mi
              <svg className="h-2 w-2 text-primary-400" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
              </svg>
            </>
          ) : null}
          <MdLocationOn />
          <span className="truncate">
            {listingAddress.address}
          </span>
        </div>
        <p className="w-full text-sm text-primary-400 font-bold">
          {listingPrice}
        </p>
        {/* <p className="block font-sans text-base font-light leading-relaxed text-gray-700 antialiased truncate">
                    {listingDescription}
                </p> */}

      </div>
    </div>
  );
}

MarketplaceCard.propTypes = {
  listing: PropTypes.object,
  userGeoLocation: PropTypes.object,
};

export default React.memo(MarketplaceCard);
