/* eslint-disable  */
import React from "react";
import { BUSINESS_CATEGORY, DEFAULT_PAGINATION_LIMIT } from "../../../../constant";
import Map from "../../../../components/map/Map";
import DefaultState from "../../../../components/default_state/DefaultState";
import Pagination from "../../../../components/pagination/Pagination";
import { Link } from "react-router-dom";
import Spinner from "../../../../components/spinner/Spinner";
import useGeoLocation from "../../../../hooks/useGeoLocation";
import search from "../../../marketplace_home/listings/search";
import { debounce } from "../../../../services/util";
import { MAGIC_NUMBERS } from "../../../../constant";
import notify from "../../../../services/toast";
import useScrollPage from "../../../../hooks/useScrollPage";
import useTitle from "../../../../hooks/useTitle";
import getNonprofits from "./getNonprofits";
import useUserContext from "../../../../hooks/useUserContext";
import getListingsByCategory from "../../../marketplace_home/listings/getListings";
import MarketplaceCard from "../../../../components/marketplace_card/MarketplaceCard";


function AllNonprofits() {
	useScrollPage();
	useTitle("Nonprofits");

	const userGeoLocation = useGeoLocation();
	const { value, label } =
		BUSINESS_CATEGORY?.["non_profits"]?.["mainCategory"] || "";

	const [mode, setMode] = React.useState("search");
	const [listings, setListings] = React.useState(null);
	const suggestionContainerRef = React.useRef(null);

	const [suggestions, setSuggestions] = React.useState([]);
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: DEFAULT_PAGINATION_LIMIT,
	});
	const { userState } = useUserContext();

	const handleSearch = async (event) => {
		try {
			const query = event.target.value;
			setSearchTerm(query);
			const response = await search(value, query);
			if (response.status === "success") {
				setSuggestions(response.data);
			} else {
				console.log(response);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), [
		listings,
		value,
	]);

	const handleSubmit = React.useCallback(
		(event) => {
			event.preventDefault();
		},
		[listings, value]
	);

	// 	const fetchNonprofits = async (accessToken, offset, limit) => {
	//     console.log(accessToken);
	// 	try {
	// 		const response = await getNonprofits(
	// 			{
	// 				offset,
	// 				limit,
	// 			},
	// 			accessToken
	// 		);

	// 		if (response.status === "success") {
	// 			const { limit, page, remaining, total, results } = response.data;

	// 			setPagination({ limit, page: page, remaining, total });
	// 			setListings(results);
	// 		} else if (response.status === "fail") {
	// 			let displayMessage = "";
	// 			if (Array.isArray(response.message)) {
	// 				displayMessage = response.message[0].message;
	// 			} else {
	// 				displayMessage = response.message;
	// 				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
	// 					displayMessage =
	// 						"An error has occurred. Please try again later. If this error persists please contact support";
	// 				}
	// 			}
	// 			notify(displayMessage, "error");
	// 		}
	// 	} catch (error) {
	// 		notify("something went wrong");
	// 	} finally {
	// 		console.log("error");
	// 	}
	// };

	const fetchListingByCategory = async (value, offset, limit) => {
		try {
			const response = await getListingsByCategory(value, offset, limit);
			if (response.status === "success") {
				const { limit, page, remaining, total, results } = response.data;
				setPagination(prevState => {
					return { ...prevState, limit, page: page, remaining, total };
				});
				console.log(results);
				setListings(results);
			} else {
				console.log(response);
			}
		} catch (error) {
			console.error(error);
		}
	};


	const handlePrevFetch = React.useCallback(() => {
		if (mode === "search") {
		  fetchListingByCategory(value, pagination.page - 1, pagination.limit);
		} else if (mode === "filter") {
		  handleFilter(null, pagination.page - 1, pagination.limit);
		}
	  }, [pagination]);
	
	  const handleNextFetch = React.useCallback(() => {
		if (mode === "search") {
		  fetchListingByCategory(value, pagination.page + 1, pagination.limit);
		} else if (mode === "filter") {
		  handleFilter(null, pagination.page + 1, pagination.limit);
		}
	  }, [pagination]);
	
	  React.useEffect(() => {
		if (value) {
		  fetchListingByCategory(value, 0, DEFAULT_PAGINATION_LIMIT);
		}
	  }, [value]);
	
	  React.useEffect(() => {
		fetchListingByCategory(value, 0, 12);
	}, [value]);

	const getListingCoordinates = (listings) => {
		const coordinates = [];
		const info = {};
		for (const listing of listings) {
			coordinates.push(listing.businessAddress.coordinates);
			info[
				`${listing.businessAddress.coordinates.lat}${listing.businessAddress.coordinates.lng}`
			] = {
				image: listing.businessPhotos?.coverPhoto?.small
					? listing.businessPhotos.coverPhoto.small.url
					: null,
				listingName: listing.businessName,
				address: listing.businessAddress.address,
				listingId: listing._id,
			};
		}

		return [coordinates, info];
	};

	const listingCoordinates = React.useMemo(
		() => getListingCoordinates(listings || []),
		[listings]
	);
	const memoizedListings = React.useMemo(
		() => listings || [],
		[listings]
	);
	const memoizedPagination = React.useMemo(
		() => pagination,
		[pagination]
	);
	const memoizedGeoLocation = React.useMemo(
		() => userGeoLocation,
		[userGeoLocation, listings]
	);

	const closeSuggestionsOnClick = () => {
		setSuggestions([]);
	};

	const closeSuggestionsOnEnter = (event) => {
		if (event.code === "Enter") {
			setSuggestions([]);
		}
	};

	React.useEffect(() => {
		document.addEventListener("click", closeSuggestionsOnClick, false);
		document.addEventListener("keypress", closeSuggestionsOnEnter, false);

		return () => {
			document.removeEventListener("click", closeSuggestionsOnClick, false);
			document.removeEventListener("keypress", closeSuggestionsOnEnter, false);
		};
	}, []);

	return (
		<>
			<div className="w-full h-[450px] xl:h-[600px] mt-6">
				{<Map locations={listingCoordinates} />}
			</div>
			<div className="mt-10 w-full px-3 sm:px-3">
				
					<div className="w-full">
						<Pagination
							pagination={memoizedPagination}
							next={handleNextFetch}
							prev={handlePrevFetch}
						/>
					</div>
					<div className="space-y-5 sm:space-y-0 sm:w-full sm:flex sm:gap-2w-full flex justify-center md:justify-center gap-5 flex-wrap">
						<div className=" grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
							{memoizedListings && memoizedListings.length ? (
								memoizedListings.map((listing, index) => {
									return (
										<MarketplaceCard
											key={index}
											listing={listing}
											userGeoLocation={memoizedGeoLocation}
										/>
									);
								})
							) : (
								<DefaultState
									icon="store"
									heading="No Listings Found"
									description={`${label} Listings will appear here`}
								/>
							)}
						</div>
					</div>
					<Pagination
						pagination={memoizedPagination}
						next={handleNextFetch}
						prev={handlePrevFetch}
					/>
			</div>


		</>
	);
}

export default AllNonprofits;
