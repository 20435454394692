/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import useUserContext from "../../hooks/useUserContext";
import { MdClear, MdLocalSee, MdEmojiEmotions, MdSend } from "react-icons/md";
import ReactTextareaAutosize from "react-textarea-autosize";
import useConversationContext from "../../hooks/useConversationContext";
import { validateMessage } from "../../services/validation";
import notify from "../../services/toast";
import Picker from "emoji-picker-react";
import FileUploader from "../file_uploader/FileUploader";
import { encrypt } from "../../services/util";

const imageCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="text-md text-success-800 cursor-pointer" />;
}

function CreateMessage({ socket }) {
  const { currentConversation } = useConversationContext();
  const { userState } = useUserContext();
  const [attachedFiles, setAttachedFiles] = React.useState([]);
  const [textMessage, setTextMessage] = React.useState("");
  const [validTextMessage, setValidTextMessage] = React.useState(false);

  const [emojiPickerOpen, setEmojiPickerOpen] = React.useState(false);

  const onEmojiClick = (event, emojiObject) => {
    const { emoji } = emojiObject;
    const newTextMessage = textMessage.concat(emoji);
    setTextMessage(newTextMessage);
    setEmojiPickerOpen(false);
  };

  const recipient = currentConversation?.recipient?._id !== userState.id ? currentConversation?.recipient : currentConversation?.sender;
  const firstName = recipient?.firstName ? recipient.firstName : null;
  const lastName = recipient?.lastName ? recipient.lastName : null;

  const handleSendMessage = () => {
    try {
      if (!validTextMessage && !attachedFiles.length) {
        notify("Message length cannot exceed 1000 characters.", "error");
        return;
      }
      const payload = {
        to: recipient._id,
        from: userState.id,
        conversationId: currentConversation._id,
        textMessage: textMessage ? encrypt(textMessage) : null,
        mediaMessage: attachedFiles.length ? encrypt(JSON.stringify(attachedFiles)) : null,
      };
      socket.current.emit("private_message", payload, response => {
        if (response.ok) {
          setTextMessage("");
          setAttachedFiles([]);
        }
      });
    } catch (error) {
      console.log(error);
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleTypingNotification = (event, typing) => {
    try {
      const payload = {
        to: recipient._id,
        from: userState.id,
        conversationId: currentConversation._id,
        typing,
      };
      if (typing) {
        setTextMessage(event.target.value);
      }
      socket.current.emit("typing", payload);
    } catch (error) {
      console.log("an error occurred. Please try again later.");
    }
  };

  React.useEffect(() => {
    const result = validateMessage(textMessage);
    setValidTextMessage(result);
  }, [textMessage]);

  const handleImageUpload = result => {
    setAttachedFiles(prev => {
      return [...prev, { ...result, type: "image" }];
    });
  };

  return (
    <div className="relative w-full flex flex-col">
      {emojiPickerOpen ? (
        <div className="absolute -top-[330px]">
          <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} />
        </div>
      ) : null}
      <div className="w-full">
        <div className="">
          <ReactTextareaAutosize
            onBlur={event => handleTypingNotification(event, false)}
            onChange={event => handleTypingNotification(event, true)}
            minRows={5}
            maxRows={5}
            value={textMessage}
            className="appearance-none resize-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
            placeholder={`Message ${firstName} ${lastName}`}
          />
        </div>
      </div>
      {attachedFiles.length ? (
        <div className="absolute -top-10 w-full flex justify-between items-center p-3 bg-success-800">
          <span className="text-sm text-gray-300">{attachedFiles.length} media files attached</span>
          <MdClear className="text-md text-white cursor-pointer" onClick={() => setAttachedFiles([])} />
        </div>
      ) : null}
      <div className="w-full flex justify-between p-1">
        <div className="relative w-full flex gap-2 items-center">
          <FileUploader
            fileType="image/*"
            isMultiple={true}
            validationCriteria={imageCriteria}
            DisplayIcon={PhotoDisplay}
            className="text-2xl text-primary-400 cursor-pointer"
            accessToken={userState.accessToken}
            folder="post_photos"
            description="400 X 400 5MB Image Minium"
            numberOfFiles={4 - attachedFiles.length}
            callback={handleImageUpload}
          />

          <MdEmojiEmotions className="text-md text-success-800 cursor-pointer" onClick={() => setEmojiPickerOpen(!emojiPickerOpen)} />
        </div>
        <button
          onClick={handleSendMessage}
          type="button"
          disabled={!validTextMessage && !attachedFiles.length ? true : false}
          className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
          <MdSend className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Send
        </button>
      </div>
    </div>
  );
}

export default CreateMessage;
