/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import ListingCard from "./../../../components/favorite_listing_card/FavoriteListingCard";
import DefaultState from "../../../components/default_state/DefaultState";
import useUserContext from "./../../../hooks/useUserContext";
import notify from "../../../services/toast";
import getFavoriteListing from "./getFavoriteListing";
import Pagination from "../../../components/pagination/Pagination";
import Spinner from "../../../components/spinner/Spinner";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import getFavorites from "../../local_discount/getFavorites";
import { MAGIC_NUMBERS } from "../../../constant";
import CouponCard from "../../../components/coupon_card/CouponCard";
import { debounce } from "../../../services/util";

function FavoriteListing() {
  useScrollPage();
  useTitle("Favorite Listing(s)");
  
  const { userState } = useUserContext();
  const [favoriteListings, setFavoriteListings] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 6 });

  const getAllFavorites = async (accessToken, page, limit) => {
    try {
      setFetching(true);
      const response = await getFavoriteListing(accessToken, page, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setFavoriteListings(results);
      } else {
        notify(response.message, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const refetchFavorites = () => {
    getAllFavorites(userState.accessToken, 0, 6);
  };

  const handlePrevFetch = () => {
    getAllFavorites(userState.accessToken, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = () => {
    getAllFavorites(userState.accessToken, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    getAllFavorites(userState.accessToken, 0, 6);
  }, []);


  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [isDiscountLoading, setIsDiscountLoading] = useState(true);
  const categoryRef = useRef(category);
  categoryRef.current = category;

  const fetchDiscounts = async (accessToken, page, _keyword, _category) => {
    setIsDiscountLoading(true);
    const response = await getFavorites({
      page,
      keyword: _keyword,
      category: _category,
      limit: pagination.limit,
      kind: "Coupon"
    }, accessToken);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setDiscounts(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsDiscountLoading(false);
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchDiscounts(userState.accessToken, 0, query, categoryRef.current);
    setKeyword(query);
  };

  const handleSearchByCategory = value => {
    fetchDiscounts(userState.accessToken, 0, keyword, value);
    setCategory(value);
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);


  const handleReload = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page, keyword, category);
  };

  useEffect(() => {
    fetchDiscounts(userState.accessToken, 0, "", "");
  }, []);

  return (
    <>
      {fetching ? (
        <Spinner displayText="Fetching..." />
      ) : (
        <div className="h-full w-full">
          <h1 className="text-2xl font-bold leading-7 text-primary-400 font-bebas sm:text-4xl sm:truncate text-center">
						Favorite Listings/Discounts
					</h1>
          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="w-full mt-10 flex justify-center md:justify-start items-center gap-5 flex-wrap">
              {favoriteListings.length ? (
                favoriteListings.map((listing, index) => {
                  return <ListingCard key={index} listing={listing.target} favoriteId={listing._id} refetchFavorites={refetchFavorites} />;
                })
              ) : (
                <DefaultState icon="storefront" heading="No Favorite Listing" description="Your favorite listings will appear here" />
              )}
              {!isDiscountLoading && !discounts.length &&
          <DefaultState icon="corporate_fare" heading="No Discounts Found" description="Your favorited discounts will appear here." />
        }
        
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
          <>
            {isDiscountLoading ? (
              <Spinner displayText="Loading..." />
            ) : (
              discounts.map(discount =>
                <CouponCard
                  key={discount._id}
                  coupon={discount.target}
                  fid={discount._id}
                  onReload={handleReload}
                  isFavorite
                  isOwner={userState.id === discount.target.userId}
                />
              )
            )}
          </>
        </div>

              <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FavoriteListing;
