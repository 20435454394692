import React from "react";
import PropTypes from "prop-types";
import Map from "./../../../../components/map/Map";

function ListingMap({ listingAddress }) {
  const locations = listingAddress?.coordinates ? [[listingAddress.coordinates], {}] : null;
  return (
    <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
      <h1 className="text-xl text-success-800 font-bold m-2">Business Address</h1>
      <hr />
      <div className="w-full h-[400px] p-2">
        <Map locations={locations} marker={false}/>
      </div>
    </div>
  );
}

ListingMap.propTypes = {
  listingAddress: PropTypes.object,
};

export default ListingMap;
