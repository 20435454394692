import React from "react";
import { useNavigate } from "react-router-dom";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import Notification from "../../../components/notification/Notification";
import { generateClassName } from "../../../services/util";
import {
	validateName,
	validateEmail,
	validatePhone,
	validateMessage,
} from "../../../services/validation";
import notify from "../../../services/toast";
import { MAGIC_NUMBERS } from "../../../constant";
import SelectInput from "../../../components/select_input/SelectInput";
import submitContactForm from "../../contact_us/submitForm";
import { STATES_CITIES } from "../../../constant";
import { capitalize, sortBy } from "lodash";
import useUserContext from "../../../hooks/useUserContext";
import BreadCrumb from "../../../components/bread_crumb/BreadCrumb";

function AddEvent() {
	useScrollPage();
	useTitle("Give Schholarships");
	useNavigate();

	const directions = [
		{ pathName: "Member Events", url: "/dashboard/member_events" },
		{ pathName: "Add Event", url: "" },
	];
	const { userState } = useUserContext();

	const [firstName, setFirstName] = React.useState("");
	const [validFirstName, setValidFirstName] = React.useState(false);

	const [lastName, setLastName] = React.useState("");
	const [validLastName, setValidLastName] = React.useState(false);

	const [email, setEmail] = React.useState("");
	const [validEmail, setValidEmail] = React.useState(false);

	const [phone, setPhone] = React.useState("");
	const [validPhone, setValidPhone] = React.useState(false);

	const [message, setMessage] = React.useState("");
	const [validMessage, setValidMessage] = React.useState(false);

	const [submitting, setSubmitting] = React.useState(false);
	const notificationRef = React.useRef(null);
	const [displayMessage] = React.useState({
		heading: "",
		body: "",
		type: "",
	});

	const { state } = userState;
	const [userRegion, setUserRegion] = React.useState(state || null);

	React.useEffect(() => {
		const result = validateName(firstName);
		setValidFirstName(result);
	}, [firstName]);

	React.useEffect(() => {
		const result = validateName(lastName);
		setValidLastName(result);
	}, [lastName]);

	React.useEffect(() => {
		const result = validateEmail(email);
		setValidEmail(result);
	}, [email]);

	React.useEffect(() => {
		const result = validatePhone(phone);
		setValidPhone(result);
	}, [phone]);

	React.useEffect(() => {
		const result = validateMessage(message);
		setValidMessage(result);
	}, [message]);

	const handleSubmitContactForm = async (event) => {
		event.preventDefault();
		try {
			setSubmitting(true);
			if (!validFirstName || !validLastName || !validEmail || !validMessage) {
				notify(
					"One or more fields are invalid. Check your email address and try again.",
					"error"
				);
				return;
			}

			if (phone && !validatePhone(phone)) {
				notify(
					"Invalid phone number. It must be a 10 digit US phone number.",
					"error"
				);
				return;
			}

			const payload = {
				firstName,
				lastName,
				emailAddress: email,
				phoneNumber: phone,
				message,
			};
			const response = await submitContactForm(payload);
			if (response.status === "success") {
				setFirstName("");
				setLastName("");
				setEmail("");
				setPhone("");
				setMessage("");
				notify(
					"Your message was successfully submitted. Our team will reach out to you as soon as possible.",
					"info"
				);
			} else {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later.", "error");
		} finally {
			setSubmitting(false);
		}
	};

	const generateStates = (statesObject) => {
		const states = [];
		for (const state of Object.keys(statesObject)) {
			states.push({ value: state, label: state });
		}
		return sortBy(states, (item) => item.value);
	};
	return (
		<>
			<div className="mb-10 text-center mt-10">
				<h1 className="text-success-800 text-4xl sm:text-5xl lg:text-5xl font-bebas">
					Add Event
				</h1>
			</div>
			<BreadCrumb directions={directions} />
			<div className="w-full">
				<div className="relative  float-center lg:grid lg:grid-cols-4">
					<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
						<div className="max-w-lg mx-auto lg:max-w-none">
							<form
								onSubmit={handleSubmitContactForm}
								className="w-full grid grid-cols-1 gap-y-6"
							>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="first_name"
											className="block text-lg font-medium text-black-700"
										>
											<b>Name of event</b>
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											onChange={(event) => setFirstName(event.target.value)}
											type="text"
											name="first_name"
											id="first_name"
											autoComplete="off"
											value={firstName}
											className={generateClassName(
												!validFirstName && firstName
											)}
										/>
									</div>
								</div>
								<div>
									<label
										htmlFor="email"
										className="block text-lg font-medium text-black-700"
									>
										<b>Event contact Email </b>
									</label>
									<p className="text-sm text-red-400 text-right">*</p>
									<input
										onChange={(event) => setEmail(event.target.value)}
										id="email"
										name="email"
										type="email"
										autoComplete="off"
										value={email}
										className={generateClassName(!validEmail && email)}
										placeholder="john.smith@gmail.com"
									/>
								</div>
								<div>
									<label
										htmlFor="state"
										className="block text-lg font-medium text-black-700"
									>
										<b>Phone </b>
									</label>
									<p className="text-sm text-red-400 text-right">*</p>
									<input
										onChange={(event) => setPhone(event.target.value)}
										type="tel"
										name="phone"
										id="phone"
										autoComplete="off"
										value={phone}
										className={generateClassName(!validPhone && phone)}
										placeholder="Phone"
									/>
								</div>
								<div className="w-full">
									<label
										htmlFor="state"
										className="block text-lg font-medium text-black-700"
									>
										<b>Where is this event located?</b>
									</label>
									<p className="text-md text-red-400 text-right">*</p>
									<div className="mt-1">
										<SelectInput
											options={generateStates(STATES_CITIES)}
											noOptionsMessage="No states found"
											placeholder="Select a state"
											defaultValue={{
												value: userRegion,
												label: capitalize(userRegion),
											}}
											setSelectedValue={(event) => setUserRegion(event.value)}
										/>
									</div>
								</div>
								<div className="w-full">
									<label
										htmlFor="state"
										className="block text-lg font-medium text-black-700"
									>
										<b>Organization Name</b>
									</label>
									<p className="text-sm text-red-400 text-right">*</p>
									<input
										onChange={(event) => setLastName(event.target.value)}
										type="text"
										name="last_name"
										id="last_name"
										autoComplete="off"
										value={lastName}
										className={generateClassName(!validLastName && lastName)}
										placeholder="Give Org"
									/>
								</div>
								<div className="w-full">
									<label
										htmlFor="state"
										className="block text-lg font-medium text-black-700"
									>
										<b>Organization Website</b>
									</label>
									<input
										onChange={(event) => setLastName(event.target.value)}
										type="text"
										name="last_name"
										id="last_name"
										autoComplete="off"
										value={lastName}
										className={generateClassName(!validLastName && lastName)}
										placeholder="Give Org"
									/>
								</div>
								<div className="w-full">
									<label
										htmlFor="state"
										className="block text-lg font-medium text-black-700"
									>
										<b>Event Address</b>
									</label>
									<p className="text-sm text-red-400 text-right">*</p>
									<input
										onChange={(event) => setLastName(event.target.value)}
										type="text"
										name="last_name"
										id="last_name"
										autoComplete="off"
										value={lastName}
										className={generateClassName(!validLastName && lastName)}
									/>
									<p className="text-sm text-gray-400 text-left">
										Street Address
									</p>
									<input
										onChange={(event) => setLastName(event.target.value)}
										type="text"
										name="last_name"
										id="last_name"
										autoComplete="off"
										value={lastName}
										className={generateClassName(!validLastName && lastName)}
									/>
									<p className="text-sm text-gray-400 text-left">
										Address Line 2
									</p>
								</div>
								<div>
									<label
										htmlFor="state"
										className="block text-lg font-medium text-black-700"
									>
										<b>Description of Your Organization & Mission </b>
									</label>
									<p className="text-sm text-red-400 text-right">*</p>
									<textarea
										onChange={(event) => setMessage(event.target.value)}
										id="message"
										name="message"
										rows="4"
										value={message}
										className={generateClassName(!validMessage && message)}
										placeholder="Message"
									></textarea>
								</div>
								<div>
									<label
										htmlFor="state"
										className="block text-lg font-medium text-black-700"
									>
										<b>
											How do you think your organization impacts your community?
											(Ex: events, providing necessary services, etc.,)
										</b>
									</label>
									<p className="text-sm text-red-400 text-right">*</p>
									<textarea
										onChange={(event) => setMessage(event.target.value)}
										id="message"
										name="message"
										rows="4"
										value={message}
										className={generateClassName(!validMessage && message)}
										placeholder="Message"
									></textarea>
								</div>
								<div>
									<ButtonPrimary
										text={submitting ? "Please wait..." : "Submit"}
										size="md"
										loading={
											!validFirstName ||
											!validLastName ||
											!validEmail ||
											(!validMessage && submitting)
												? true
												: false
										}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>

				<Notification
					header={displayMessage.heading}
					body={displayMessage.body}
					type={displayMessage.type}
					ref={notificationRef}
				/>
			</div>
		</>
	);
}

export default AddEvent;
