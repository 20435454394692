/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { MdLocalSee, MdDeleteOutline } from "react-icons/md";
import FileUploader from "../../../../components/file_uploader/FileUploader";
import useUserContext from "./../../../../hooks/useUserContext";

const coverImageCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };
const imageCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

function PropertyPhotos({
  property,
  setProperty,
}) {
  const { userState } = useUserContext();

  const handleUpload = result => {
    setProperty({
      ...property,
      propertyCoverImage: result
    });
  };

  const handleImageUpload = (result) => {
    setProperty((prevState) => {
      return {
        ...prevState,
        propertyImages: [...(prevState?.propertyImages || []), { ...result, type: "image" }],
     };
   });
  };

  const handleDeleteImage = (idx) => {
    setProperty((prevState) => {
      return {
        ...prevState,
        propertyImages: (prevState?.propertyImages || []).filter((image, index) => index !== idx),
     };
   });
  };

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-success-800">Property Images</h3>
        </div>
        <div className="col-span-3">
          <div className="w-full flex justify-between items-center">
            <label className="block text-sm font-medium text-gray-700 mb-1">Cover Image</label>
            <span className="w-2 h-2 rounded-full bg-red-500"></span>
          </div>
          <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
            {property?.propertyCoverImage?.small.url && <img src={property?.propertyCoverImage.small.url} className="w-full h-full" alt="cover image upload" />}
            <FileUploader
              fileType="image/*"
              isMultiple={false}
              validationCriteria={coverImageCriteria}
              DisplayIcon={PhotoDisplay}
              accessToken={userState.accessToken}
              folder="property_photos"
              description="Image - Min: 400 X 400, Max: 5MB"
              numberOfFiles={1}
              callback={handleUpload}
            />
          </div>
          </div>
          <div className="col-span-3">


    <div className="col-span-3 mt-4">
      <div className="w-full flex justify-between items-center mb-1">
        <label className="block text-sm font-medium text-gray-700">Property Images</label>
        <span className="w-2 h-2 rounded-full bg-red-500"></span>
      </div>
      <p className="mt-1 text-sm text-gray-500">Exactly 10 images required</p>
      <div className="flex mb-8 mt-2">
        {property?.propertyImages.map((image, index) => <div key={image.small.url} className="mr-2 w-24 text-center">
          <img src={image.small.url} className="w-full h-full" alt="property image" />
          <div className="text-center">
            <MdDeleteOutline className="mt-1 text-red-400 cursor-pointer mx-auto" onClick={() => handleDeleteImage(index)} />
          </div>
        </div>)}
      </div>
          </div>
          <div className="col-span-3">
            
          <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
            <FileUploader
              fileType="image/*"
              isMultiple={true}
              validationCriteria={imageCriteria}
              DisplayIcon={PhotoDisplay}
              className="text-2xl text-primary-400 cursor-pointer"
              accessToken={userState.accessToken}
              folder="property_photos"
              description="400 X 400 8MB Image Minimum and you have to add exactly 5 photos"
              numberOfFiles={10}
              callback={handleImageUpload}
            />
          </div>
          </div>
      </div>
  </div>
    </div>
  );
}

export default PropertyPhotos;
