import React from "react";
import Footer from "../../../components/footer/Footer";
import MarketplaceNavigation from "../../../components/marketplace_navigation/MarketplaceNavigation";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function GeneralTerms() {
  useScrollPage();
  useTitle("COhatch+ General Terms");
    return (
<div className="w-full flex flex-col">
        <div className="sticky top-0 w-full z-[999999]">
          <MarketplaceNavigation />
        </div>
        <div className="w-full py-8 px-5 text-center space-y-3 bg-[url('/src/assets/grungeTextureLight.png')] bg-no-repeat bg-cover">
          <h1 className="w-full text-4xl md:text-5xl font-bebas text-success-800 font-bold">COhatch+ General Terms of Use</h1>
        </div>


<section>   
  <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-12">
        <div className="text-lg  mx-auto">
          <h1>
            <span className="block text-base text-2xl text-center text-gray-500 font-semibold tracking-wide">
            Welcome to COhatch+. While you are here, please follow these rules.
            </span>

            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            1. Accepting These Terms
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. These terms and conditions (“Terms”) contain important information regarding your rights and obligations, as well as conditions, limitations, and exclusions that might apply to you. Please read them carefully. The Terms are a legally binding contract between you and COhatch Marketplace, LLC (“COhatch”).
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. COhatch’s General Terms of Use require all account owners to be at least 18 years of age. Minors under 18 years of age are not permitted to use COhatch’s Services. You are responsible for any and all account activity conducted by a minor on your account. We do not knowingly “sell,” as that term is defined under applicable law, including the California Consumer Privacy Act, the personal information of minors.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. This contract sets out your rights and responsibilities when you use cohatchplus.com (the “Site”), our mobile apps, and any other services provided by COhatch (collectively, the “Services”). By using our Services, including posting on the Site or just browsing, you affirm that you are of legal age to enter into this agreement, have read and understand this policy and you accept and are bound by these Terms.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. These Terms are subject to change by COhatch without prior written notice at any time, in COhatch’s sole discretion. Any changes to the Terms will be in effect as of the “Last Updated Date” referenced on the Site. You should review these Terms prior to purchasing any product or services that are available through this Site. Your continued use of this Site after the “Last Updated Date” will constitute your acceptance of and agreement to such changes.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          E. In addition to these Terms, you should carefully review our Privacy Policy, and our Anti-Discrimination Policy.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          F. These Terms do not create any agency, partnership, joint venture, employment, or franchisee relationship between you and COhatch.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            2. Your Account with COhatch
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          You must create an account with COhatch to use some of our Services.
            </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. You must be 18 years or older to use our Services. Minors under 18 and at least 13 years of age are only permitted to use our Services through an account owned by a parent or legal guardian with their appropriate permission and under their direct supervision. Children under 13 years are not permitted to use COhatch or the Services. You are responsible for any and all account activity conducted by a minor on your account, and there may be commercial products or services available that you may want to consider to limit a minor&apos;s access to material online.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Be Truthful. You must provide accurate information about yourself. You are prohibited from using false information in association with your account or impersonating another person or company through your account.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. Choose an appropriate name. If you decide to not have your full name serve as the name associated with your account, you may not use language that is offensive, vulgar, infringes someone’s intellectual property rights, or otherwise violates the Terms.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. You are responsible for your account. You are solely responsible for any activity on your account.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          E. Location Information: We may collect information about your use of the Services for advertising, for analytics, to serve content, and to protect the Services. This can include your IP address, browser information (including referrers), device information (such as iOS IDFA, IDFV for limited non-advertising purposes, Android AAID, and, when enabled by you, location information provided by your device). When you use the Apps, you can choose to share your geolocation details with COhatch in order to use certain functions (although you don't need to share these details).
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            3. Your Content
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          Content that you post using our Services, including reviews, ratings, statements, photos, comments, usernames, etc. (collectively, “Your Content”) belongs to you.
            </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. Responsibility for Your Content. You understand that you are solely responsible for Your Content. You represent that you have all necessary rights to Your Content and that you are not infringing or violating any third party’s rights by posting it. You assume all risks associated with Your Content, including anyone’s reliance on its quality, accuracy, or reliability, and any risks associated with personal information you disclose. You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein. You may not imply that Your Content is in any way sponsored or endorsed by COhatch. You may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Permission to Use Your Content. By posting Your Content through our Services, you grant COhatch a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use, display, edit, modify, reproduce, distribute, store, and prepare derivative works of Your Content. This allows us to provide the Services and to promote COhatch, its sellers, or the Services in general, in any formats and through any channels, including across any COhatch affiliates, partners, or third-party websites or advertising mediums. You agree not to assert any moral rights or rights of publicity against us for using Your Content. You also recognize our legitimate interest in using it, in accordance with the scope of this license, to the extent Your Content contains any personal information.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. Reporting Unauthorized Content. COhatch has great respect for intellectual property rights, and is committed to following appropriate legal procedures to remove infringing content from the Services. If content that you own or have rights to has been posted to the Services without your permission and you want it removed, please contact COhatch at support@cohatchplus.com If Your Content is alleged to infringe another person’s intellectual property, we will take appropriate action, such as disabling it if we receive proper notice or terminating your account if you are found to be a repeat infringer. We’ll notify you if any of that happens.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. Inappropriate, False, or Misleading Content. You agree that you will not post any content that is fake, abusive, threatening, defamatory, obscene, vulgar, or otherwise offensive. You also agree not to post any content that is false and misleading or uses the Services in a manner that is fraudulent or deceptive.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          E. Advertising. COhatch and its licensees may publicly display advertisements, paid content and other information nearby or in association with Your Content. You are not entitled to any compensation for such ads. The manner, mode, and extent of such advertising are subject to change without specific notice to you.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          F. Other. COhatch reserves the right to remove, screen, edit, or reinstate Your Content at its sole discretion for any reason or no reason, and without notice to you.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            4. Your Use of Our Services
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          COhatch grants you a limited, non-exclusive, non-transferable, and revocable license to use our Services—subject to the Terms and the following restrictions in particular:
            </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. Do Not Use Our Services to Violate the Law. You agree that you will not violate any laws in connection with your use of the Services. This includes any local, state, federal, and international laws that may apply to you. This includes the sale and delivery of your items, such as age verification upon delivery, where required by law. You may not sell anything that violates any laws, and you may not engage in fraud (including false claims or infringement notices), theft, anti-competitive conduct, threatening conduct, or any other unlawful acts or crimes against COhatch, another COhatch user, or a third party.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Pay Your Bills. You are responsible for paying all fees that you owe to COhatch.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. Do Not Steal Our Code or Harm our System. You agree not to crawl, scrape, or spider any page of the Services or to reverse engineer or attempt to obtain the source code of the Services. You agree not to interfere with or try to disrupt our Services, for example by distributing a virus or other harmful computer code.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. Follow the Trademark Policy. The name “COhatch” and the other COhatch marks, phrases, logos, and designs that we use in connection with our Services (the “COhatch Trademarks”) are trademarks, service marks, or trade dress of COhatch in the US and other countries.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          E. Ideas You Share with Us. Any unsolicited ideas or other materials you submit to COhatch (not including Your Content) are considered non-confidential and non-proprietary to you. You grant COhatch a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          F. Electronic Communication. From time to time, COhatch may provide you with certain legal information in writing. By using our Services, you’re agreeing to our Electronic Communications Policy, which describes that we can send you information electronically (such as by email) instead of mailing you paper copies and that your electronic agreement is the same as your signature on paper.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            5. BOOKING AND TRANSACTING
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          You must create an account with COhatch to use some of our Services.
            </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. Generally. You can access features through the Services that allow you to book or transact online with local businesses, such as making restaurant or spa reservations, ordering food delivery, or scheduling appointments. When you access these third-party services, you do so at your own risk. The third parties may require you to accept their own terms of use. COhatch is not a party to those agreements; they are solely between you and the third party. Please note that such third-party partners and/or the transacting local businesses themselves are responsible for fulfilling such bookings and transactions.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Payments and Cancellations. You may be required to provide your credit card information to confirm a booking, and will be charged any applicable fees, including cancellation or no-show fees in accordance with the transacting local business’s cancellation policy provided at the time of booking. You agree that COhatch may facilitate any such payments and charges on behalf of the transacting local business.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. Coupons. Any coupons that COhatch might issue for use in connection with its Services are non-transferable (unless required by law), not redeemable for cash or any other consideration, and automatically expire thirty (30) days after the issue date unless otherwise specified. If your Account is terminated you will not be able to use any unexpired and unused coupons, and any such coupons will automatically terminate and cannot be redeemed unless required by law.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            6. Termination
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. Termination By You. You may terminate your account with COhatch at any time from your account settings. You can find more information in the Frequently Asked Questions. Terminating your account will not affect the availability of some of Your Content that you posted through the Services prior to termination. Terminating your account will not affect any amounts owed on any outstanding bills.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Termination By COhatch. We may terminate or suspend your account (and any accounts COhatch determines are related to your account) and your access to the Services should we have reason to believe you, your Content, or your use of the Services violate our Terms. If we suspend or terminate your account, you do not have a contractual or legal right to continue to use our Services.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. Lost Content. If you or COhatch terminate your account, you may lose any information associated with your account, including Your Content.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. We May Discontinue the Services. COhatch reserves the right to change, suspend, or discontinue any of the Services for you, any or all users, at any time, for any reason. COhatch will not be liable to you for the effect that any changes to the Services may have on you, including your income or your ability to generate revenue through the Services.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          E. Survival. The Terms will remain in effect even after your access to the Services is terminated, or your use of the Services ends.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            7. Warranties and Limitations of Liability
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. Third-Party Content, Products, and Services. You understand that COhatch does not endorse, manufacture, store, and/or inspect any of the products or services mentioned through our Services and that COhatch cannot and does not make any warranties about their quality, safety, or even their legality. Any legal claim related to an item you purchase or service you use must be brought directly against the seller of the product or service. You release COhatch from any claims related to products or services mentioned through our Services, including for defective items, misrepresentations by sellers, or items that caused physical injury.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Content You Access. You may come across materials that you find offensive or inappropriate while using our Services. We make no representations concerning any content posted by users through the Services. COhatch is not responsible for the accuracy, copyright compliance, legality, or decency of content posted by users that you accessed through the Services. You release us from all liability relating to that content.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. People You Interact With. You can use the Services to interact with other individuals, either online or in person. However, you understand that we do not screen users of our Services, and you release us from all liability relating to your interactions with other users. Please be careful and exercise caution and good judgment in all interactions with others, especially if you are meeting someone in person.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. Health & Wellness Services. Our Services are not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on the Site.
          <p className="text-base text-gray-500 leading-8">
          If you think you may have a medical emergency, call your doctor or 911 immediately. COhatch does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the Site. COhatch is not a health or wellness provider and cannot recommend or refer you to any health or wellness provider. Use of COhatch’s Services is solely at your own risk.
          </p>
          <p className="text-base text-gray-500 leading-8">
          The Site and Services may contain health-related materials that are sexually explicit. If you find these materials offensive, you should not use the Site and Services.
          </p>
          <p className="text-base text-gray-500 leading-8">
          You should be aware that if you post any health-related information about yourself or anyone else on the Site and Services, you do so at your own risk. If you post health information about services rendered to another individual, you represent that you have the legal authority to receive health information about that individual from that individual’s health care providers and that you have the legal authority to further disclose such health information. If you post health-related information, you will be placing it into the public domain which may violate federal or state laws that protect the privacy of health information. You also acknowledge that the health care or wellness provider about whom you submit Content may submit Content that contain your private or confidential health information in response to Content you submit. COhatch is not liable for any such Content. COhatch cannot be expected to keep your health information confidential if you post it to the Site or Services or otherwise make it available to others.
          </p>
          </p>

          <p className="mt-4 text-base text-gray-500 leading-8">
          E. Nonprofit and Startup Services. Nothing offered through COhatch’s Services should be considered personalized investment advice. Although COhatch may answer your general customer service questions, COhatch is not licensed under securities laws to address your particular investment situation. No communication by COhatch to you should be deemed as personalized investment advice. Any investments should be made only after consulting with your investment advisor and only after reviewing the prospectus or financial statements of the company. You represent and warrant that all information that you provide to COhatch or through the Site is accurate, complete and truthful. COhatch is entitled to and will rely upon your representations.
          
          <p className="text-base text-gray-500 leading-8">
          To the extent permissible under law, COhatch assumes no liability or responsibility for any errors or omissions in the content of the Site. COhatch does not endorse or represent the completeness, reliability or accuracy of any content or information distributed through or accessed from the Site, and has not performed any investigation into such information. COhatch shall not be liable for any investment decisions made based upon such information. You agree that any reliance upon any content or information distributed through or accessed from the Site is at your sole risk. You acknowledge and agree that COhatch does not provide any representation, warranty or assurance that offerings on the Site are made in accordance with state and/or federal securities law, including any exemption for the sale of unregistered securities and the prohibition against the general solicitation of unregistered securities. COhatch is not responsible for ensuring that any securities offering is made in accordance with state and federal law and regulations promulgated by the SEC, FINRA and the states. COhatch makes no representation or warranty regarding the legality or compliance of any offering.
          </p>
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8 underline">
          F. WARRANTIES. YOU EXPRESSLY AGREE THAT USE OF THE SITE AND SERVICES IS AT YOUR SOLE RISK. YOU UNDERSTAND THAT COHATCH’S SERVICES ARE PROVIDED “AS IS” AND WITHOUT ANY KIND OF WARRANTY (EXPRESS OR IMPLIED). COHATCH EXPRESSLY DISCLAIMS ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE.

          <p className="text-base text-gray-500 leading-8 underline">
          COHATCH DOES NOT GUARANTEE THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) THE SERVICES WILL BE FREE OF VIRUSES OR OTHER HARMFUL MATERIALS; OR (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR EXPECTATIONS. YOU USE THE SERVICES SOLELY AT YOUR OWN RISK. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
          </p>

          <p className="mt-4 text-base text-gray-500 leading-8 underline">
          G. LIABILITY LIMITS. TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER COHATCH, NOR OUR EMPLOYEES OR DIRECTORS SHALL BE LIABLE TO YOU FOR ANY LOST PROFITS OR REVENUES, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS. IN NO EVENT SHALL COHATCH’S AGGREGATE LIABILITY FOR ANY DAMAGES EXCEED THE GREATER OF ONE HUNDRED ($100) US DOLLARS (USD) OR THE AMOUNT YOU PAID COHATCH IN THE PAST TWELVE MONTHS. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            8. INDEMNIFICATION & RELEASE
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. Indemnification. You agree to defend, indemnify, and hold harmless COhatch, its affiliates, officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms or your use of the Services.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Release. You release COhatch from any claims, demands, and damages arising out of disputes with other users or parties. COhatch will attempt to help you resolve disputes in good faith and based solely on our interpretation of our policies, in our sole discretion; we will not make judgments regarding legal issues or claims. COhatch has no obligation to resolve any disputes.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            9. DISPUTES WITH COHATCH
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          These terms will govern any legal dispute involving our Services:
            </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. Governing Law. The Terms are governed by the laws of the State of Ohio without regard to its conflict of laws rules, and the laws of the United States of America. These laws will apply no matter where in the world you live, but if you live outside of the United States, you may be entitled to the protection of the mandatory consumer protection provisions of your local consumer protection law.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Arbitration. You and COhatch agree that any dispute or claim arising from or relating to the Terms shall be finally settled by final and binding arbitration, using the English language, administered by the American Arbitration Association (the “AAA”) under its Consumer Arbitration Rules (the “AAA Rules”) then in effect (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms you can find the AAA Rules here), unless otherwise required by law. **Arbitration, including threshold questions of arbitrability of the dispute, will be handled by a sole arbitrator in accordance with those rules. Judgment on the arbitration award may be entered in any court that has jurisdiction.

          <p className="text-base text-gray-500 leading-8">
          Any arbitration or mediation under the Terms will take place on an individual basis. You understand that by agreeing to the Terms, you and COhatch are each waiving the right to trial by jury or to participate in a class action lawsuit. Class arbitrations shall only be available if requested by either party under its Class Action Arbitration Rules and approved by the arbitration entity. Notwithstanding the foregoing, each party shall have the right to bring an action in a court of proper jurisdiction for injunctive or other equitable or conservatory relief, pending a final decision by the arbitrator or mediator. You may instead assert your claim in “small claims” court, but only if your claim qualifies, your claim remains in such court, and your claim remains on an individual, non-representative, and non-class basis.
          </p>
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. Costs of Arbitration. Payment for any and all reasonable AAA filing, administrative, and arbitrator fees will be in accordance with the Consumer Arbitration Rules. If the value of your claim does not exceed $6,000 USD, COhatch will pay for the reasonable filing, administrative, and arbitrator fees associated with the arbitration, unless the arbitrator finds that either the substance of your claim or the relief sought was frivolous or brought for an improper purpose.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. Forum. Any legal action against COhatch related to our Services must be filed and take place in Franklin County, Ohio. For all actions under the AAA Rules, the proceedings may be filed where your residence is, or in Franklin County, Ohio, and any in-person hearings will be conducted at a location which is reasonably convenient to both parties taking into account their ability to travel and other pertinent circumstances. For any actions not subject to arbitration or mediation, you and COhatch agree to submit to the personal jurisdiction of a state or federal court located in Franklin County, Ohio.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          E. Government Exception. If you are a government agent or entity in the United States using the Services in your official capacity, and you are legally unable to agree to the clauses in this section, then those clauses do not apply to you. In that case, the Terms and any action related to the Terms will be governed by the laws of the United States (without reference to conflict of laws) and, in the absence of federal law and to the extent permitted under federal law, the laws of the State of Ohio.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          F. Modifications. If we make any changes to this “Disputes with COhatch” section after the date you last accepted the Terms, those changes will not apply to any claims filed in a legal proceeding against COhatch prior to the date the changes became effective. COhatch will notify you of substantive changes to the “Disputes with COhatch” section at least 30 days prior to the date the change will become effective. If you do not agree to the modified terms, you may close your account within those 30 days. By closing your account, you agree to arbitrate any disputes between you and COhatch in accordance with the provisions of this “Disputes with COhatch” section as of the date you last accepted the Terms, including any changes made prior to your rejection. If you reopen your closed account or create a new account, you agree to be bound by the current version of the Terms.
          </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            10. Changes to the Terms
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          We may update these Terms from time to time. If we believe that the changes are material, we’ll definitely let you know by posting the changes through the Services and/or sending you an email or message about the changes. That way you can decide whether you want to continue using the Services. Changes will be effective upon the posting of the changes unless otherwise specified. You are responsible for reviewing and becoming familiar with any changes. Your use of the Services following the changes constitutes your acceptance of the updated Terms.
            </p>
        </div>

        <div className="text-lg  mx-auto">
          <h1>
            <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
            11. Additional Legal Points
            </span>
          </h1>
          <p className="mt-4 text-base text-gray-500 leading-8">
          A. The Terms, including all of the policies that make up the Terms, supersede any other agreement between you and COhatch regarding the Services. If any part of the Terms is found to be unenforceable, that part will be limited to the minimum extent necessary so that the Terms will otherwise remain in full force and effect. Our failure to enforce any part of the Terms is not a waiver of our right to later enforce that or any other part of the Terms. We may assign any of our rights and obligations under the Terms.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          B. Any failure on COhatch’s part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. The Terms may not be waived, except pursuant to a writing executed by COhatch.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          C. If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties’ intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          D. The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with COhatch’s prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.
          </p>
        </div>
        <div className="text-lg  mx-auto">
          <p className="mt-12 text-base text-gray-500 leading-8">
          Last updated: March 10, 2022
          </p>
        </div>
      </div>
  </div>
</section>
  <Footer />
</div> 
    );
}
    export default GeneralTerms;