/*eslint-disable*/
import { findInputError, isFormInvalid } from "../../services/util";
import { Controller, useFormContext, useController } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { MdError } from "react-icons/md";
import Select from "react-select";
import FileUploader from "../file_uploader/FileUploader";


export const Input = ({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  multiline,
  className,
  value,
  rows,
  onChange
}) => {
  
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name)
  const isInvalid = isFormInvalid(inputErrors)

  return (
    
    <div className="flex flex-col w-full gap-2">
      <div className="flex justify-between">
        <label htmlFor={id} className="font-semibold capitalize">
          {label}
        </label>
        <AnimatePresence 
        mode="wait" 
        initial={false}>
          {isInvalid && (
            <InputError
              message={inputErrors.error.message}
              key={inputErrors.error.message}
            />
          )}
        </AnimatePresence>
      </div>
        {multiline ? (
        <textarea
          id={id}
          type={type}
          rows={rows}
          className={className}
          placeholder={placeholder}
          {...register(`${name}`, validation)}
        ></textarea>
      ) : (
        <input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          {...register(name, validation)}
        />
      )}
   </div>
  )
}

export const SelectInputs = ({ options = [], noOptionsMessage = "No options found.", placeholder = "Select an option", defaultValue = null, placeMent = "auto", isDisabled = false,  label, id, name, validation,  }) => {
  
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();


  const inputErrors = findInputError(errors, name)
  const isInvalid = isFormInvalid(inputErrors)

  return (
    
    <div className="flex flex-col w-full gap-2">
      <div className="flex justify-between">
        <label htmlFor={id} className="font-semibold capitalize">
          {label}
        </label>
        <AnimatePresence 
        mode="wait" 
        initial={false}>
          {isInvalid && (
            <InputError
              message={inputErrors.error.message}
              key={inputErrors.error.message}
            />
          )}
        </AnimatePresence>
      </div>
         <Controller
          control={control}
            name={name}
            render={({ field: { onChange,   } }) => (
            <Select
              id={id}
              onChange={(val) => onChange(val.value)}
              placeholder={placeholder}
              options={options}
            />
          )}
          {...register(name, validation)}
          />
   </div>
  )
}

export const Upload = ({
  name,
  label,
  id,
  validation,
  type,
  folder
}) => {
  
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();


  const inputErrors = findInputError(errors, name)
  const isInvalid = isFormInvalid(inputErrors)

  return (
    
    <div className="flex flex-col w-full gap-2">
      <div className="flex justify-between">
        <label htmlFor={id} className="font-semibold capitalize">
          {label}
        </label>
       
      </div>
        <Controller
        control={control}
        name={name}
        id={id}
        //rules={{ required: "Recipe picture is required" }}
        render={({ field: { value, onChange, ...field } } ) => (
        <Input
        {...field}
        type={type}
        id={id}
        onChange={(event) => {
        onChange(event.target.files[0]);
      }}
        value={value?.name}
        folder={folder}
       
        />
        )}
                        
   
      />
                     
                    
                      
   </div>
  )
}



const InputError = ({ message }) => {
  return (
    <motion.p
      className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  )
}

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
}