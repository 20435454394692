/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { MdDeleteOutline, MdFavorite } from "react-icons/md";
import notify from "../../services/toast";
import removeFavoriteListing from "./removeFavoriteListing";
import Prompt from "../../components/prompt/Prompt";
import useUserContext from "../../hooks/useUserContext";
import { showPrompt } from "../../services/util";

function FavoriteListingCard({ listing, refetchFavorites, favoriteId }) {
  const coverPhoto = listing?.businessPhotos?.coverPhoto?.small ? listing.businessPhotos.coverPhoto.small.url : null;
  const { userState } = useUserContext();
  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });

  const handleRemoveFavorite = async () => {
    try {
      const response = await removeFavoriteListing(userState.accessToken, favoriteId);
      if (response.status === "success") {
        notify("Successfully removed from favorite list", "info");
        refetchFavorites();
      } else {
        notify("Error occurred while removing from favorite list. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleShowPrompt = () => {
    showPrompt(
      `Remove ${listing.businessName}`,
      `Are you sure you want to remove ${listing.businessName} from your favorites list? This action can't be undone.`,
      promptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  return (
    <div className="w-full max-w-xs shadow-xl rounded bg-white">
      <div className="w-full">
        <div className="w-full bg-white p-2 flex justify-between">
          <MdFavorite className="text-primary-500 text-xl" />
          <MdDeleteOutline onClick={handleShowPrompt} className="text-red-500 text-xl cursor-pointer" />
        </div>
        <Link to={`/marketplace/listing/${listing._id}`}>
          <div
            style={{ backgroundImage: `url(${coverPhoto ? coverPhoto : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_1.png"})` }}
            className="w-full h-[200px] bg-center bg-no-repeat bg-cover"></div>
          <div className="w-full bg-white p-5 space-y-3">
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">business</span>
              <h1 className="text-xl text-success-800 font-bold capitalize truncate">{listing?.businessName}</h1>
            </div>
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">location_on</span>
              <h1 className="text-md text-success-800 capitalize truncate">{listing?.businessAddress?.address}</h1>
            </div>
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">person</span>
              <h1 className="text-md text-success-800 capitalize trunc">
                {listing?.businessOwner?.firstName} {listing?.businessOwner?.lastName}
              </h1>
            </div>
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">schedule</span>
              <h1 className="text-md text-success-800 capitalize truncate">{format(new Date(listing.createdAt), "iii, MMMM do")}</h1>
            </div>
          </div>
        </Link>
      </div>
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={handleRemoveFavorite} />
    </div>
  );
}

export default FavoriteListingCard;
