/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { MdLocalSee } from "react-icons/md";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import DefaultState from "../../../../components/default_state/DefaultState";
import DisplayCouponCard from "./../../../../components/display_coupon_card/DisplayCouponCard";
import FileUploader from "../../../../components/file_uploader/FileUploader";
import { v4 as uuid } from "uuid";
import { validateCoupon, validateMessage } from "../../../../services/validation";
import { generateClassName, inArrayObject } from "../../../../services/util";
import notify from "../../../../services/toast";
import useUserContext from "./../../../../hooks/useUserContext";
import useScrollPage from "../../../../hooks/useScrollPage";
import { COUPON_BG_TEMPLATE, PLANS } from "../../../../constant";
import { Link } from "react-router-dom";

const backgroundCriteria = { requiredSize: 5e6, requiredDimensions: { width: 250, height: 250 } };

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

function Coupon({ steps, currentStep, setCurrentStep, listing, setListing }) {
  useScrollPage();

  const {
    userState,
    subscription: { product },
  } = useUserContext();
  const [coupons, setCoupons] = React.useState(listing?.coupon || []);
  const [couponName, setCouponName] = React.useState("");
  const [expirationDate, setExpirationDate] = React.useState("");
  const [couponCode, setCouponCode] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [background, setBackground] = React.useState(null);
  const [checked, setChecked] = React.useState(null);

  const [validCouponName, setValidCouponName] = React.useState(false);
  const [validExpirationDate, setValidExpirationDate] = React.useState(false);
  const [validCouponCode, setValidCouponCode] = React.useState(false);
  const [validDescription, setValidDescription] = React.useState(false);

  React.useEffect(() => {
    const result = validateCoupon(couponName);
    setValidCouponName(result);
  }, [couponName]);

  React.useEffect(() => {
    const result = expirationDate.toString().length > 0;
    setValidExpirationDate(result);
  }, [expirationDate]);

  React.useEffect(() => {
    const result = validateCoupon(couponCode);
    setValidCouponCode(result);
  }, [couponCode]);

  React.useEffect(() => {
    const result = validateMessage(description);
    setValidDescription(result);
  }, [description]);

  const validateCouponForm = () => {
    if (couponName || couponCode || expirationDate || description) {
      if (!couponName || !couponCode || !expirationDate || !description) {
        notify("You must provide all coupon details.", "error");
      } else {
        return true;
      }
    }
    return false;
  };

  const handleAddCoupons = () => {
    if (!validateCouponForm()) {
      return;
    }

    if (coupons.length >= 4) {
      notify("You can only add up to 4 coupons per listing", "error");
      return;
    }

    const payload = {
      couponName,
      expirationDate,
      couponCode,
      description,
      background,
    };

    if (inArrayObject(coupons, payload.couponCode, "couponCode")) {
      notify(`${payload.couponCode} already exists in your Coupon List.`, "error");
      return;
    }
    setCoupons((prevState) => {
      return [...prevState, payload];
    });
    setCouponName("");
    setExpirationDate("");
    setCouponCode("");
    setDescription("");
    setBackground(null);
    setChecked(null);
  };

  const handleRemoveCoupon = (event) => {
    const { coupon } = event.target.dataset;
    const newCouponList = coupons.filter((value, index) => parseInt(coupon, 10) !== index);
    setCoupons(newCouponList);
  };

  const handleSubmit = () => {
    if (couponName || couponCode || expirationDate || description) {
      if (!validCouponName || !validCouponCode || !validExpirationDate || !validDescription) {
        notify("One or more fields are invalid. Please check before submitting.", "error");
        return;
      }
    }

    if (coupons.length) {
      setListing((prevState) => {
        return { ...prevState, coupon: coupons };
      });
    }

    setCurrentStep(currentStep + 1);
  };

  const handleGoBack = () => {
    if (couponName || couponCode || expirationDate || description) {
      if (!validCouponName || !validCouponCode || !validExpirationDate || !validDescription) {
        return;
      }
    }
    if (coupons.length) {
      setListing((prevState) => {
        return { ...prevState, coupon: coupons };
      });
    }

    setCurrentStep(currentStep - 1);
  };

  const handleBackgroundUpload = (result) => {
    setBackground(result);
  };

  const handleSelectTemplate = (event) => {
    const templateIndex = parseInt(event.target.value, 10);
    setChecked(templateIndex);
    const template = COUPON_BG_TEMPLATE[templateIndex];
    setBackground(template);
  };

  return (
    <div className="w-full bg-white overflow-hidden shadow rounded-lg">
      <div className="w-full px-4 py-5 sm:p-6 space-y-2">
        <h1 className="text-xl font-bold text-success-800">
          Coupon Details: <span className="text-xl text-gray-400">Create up to 4 coupons per business listing.</span>
        </h1>
        <p className="text-sm text-gray-400">Drive more customers to your business with coupons!</p>
        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="coupon_name" className="block text-sm font-medium text-gray-700">
              Coupon Name
            </label>
          </div>
          <div className="mt-1">
            <input
              maxLength={30}
              onChange={(event) => setCouponName(event.target.value)}
              type="text"
              name="coupon_name"
              id="coupon_name"
              value={couponName}
              className={generateClassName(!validCouponName && couponName)}
              placeholder="3 Months Free Burgers On Us"
              aria-describedby="name-optional"
            />
          </div>
          <p className="text-sm text-gray-300">30 characters max</p>
        </div>
        <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:gap-3 sm:justify-between sm:items-center">
          <div className="w-full">
            <div className="w-full flex justify-between">
              <label htmlFor="expiration_date" className="block text-sm font-medium text-gray-700">
                Expiration Date
              </label>
            </div>
            <div className="mt-1">
              <input
                onChange={(event) => setExpirationDate(event.target.value)}
                type="date"
                name="expiration_date"
                id="expiration_date"
                value={expirationDate}
                className={generateClassName(!validExpirationDate && expirationDate)}
                placeholder="04/23/2022"
                aria-describedby="date-optional"
              />
            </div>
            <p className="text-sm text-gray-300"></p>
          </div>
          <div className="w-full">
            <div className="w-full flex justify-between">
              <label htmlFor="coupon_code" className="block text-sm font-medium text-gray-700">
                Coupon Code
              </label>
            </div>
            <div className="mt-1">
              <input
                maxLength={15}
                onChange={(event) => setCouponCode(event.target.value)}
                type="text"
                name="coupon_code"
                id="coupon_code"
                value={couponCode}
                className={generateClassName(!validCouponCode && couponCode)}
                placeholder="FREEBUG"
                aria-describedby="code-optional"
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="w-full flex justify-between">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Details on How to Use the Coupon
            </label>
          </div>
          <div className="mt-1">
            <textarea
              maxLength={50}
              rows="2"
              onChange={(event) => setDescription(event.target.value)}
              value={description}
              name="description"
              id="description"
              className={generateClassName(!validDescription && description)}
            ></textarea>
          </div>
          <p className="text-sm text-gray-300">50 characters max</p>

          <div className="w-full flex justify-between items-center">
            <label htmlFor="coupon_name" className="block text-sm font-medium text-gray-700">
              Add Coupon Background Image
            </label>
          </div>
          <div className="mt-3 flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
            {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
              <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                {background?.small.url ? <img src={background.small.url} className="w-full h-full" alt="background upload" /> : null}
                <FileUploader
                  fileType="image/*"
                  isMultiple={false}
                  validationCriteria={backgroundCriteria}
                  DisplayIcon={PhotoDisplay}
                  accessToken={userState.accessToken}
                  folder="coupon_background_images"
                  description="250 X 250 5MB Background Image"
                  numberOfFiles={1}
                  callback={handleBackgroundUpload}
                />
              </div>
            {/* ) : (
              <Link to="/dashboard/settings/subscription" className="text-primary-400 text-bold text-lg underline">
                Upgrade subscription to add custom coupon images
              </Link>
            )} */}
          </div>
          <div className="mt-3 w-full flex justify-between items-center">
            <label htmlFor="coupon_name" className="block text-sm font-medium text-gray-700">
              Select a template
            </label>
          </div>
          <div className="w-full sm:max-w-full flex gap-3 overflow-x-auto">
            {COUPON_BG_TEMPLATE.length
              ? COUPON_BG_TEMPLATE.map((template, index) => {
                  return (
                    <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                      <input
                        type="radio"
                        name="template"
                        value={index}
                        onChange={handleSelectTemplate}
                        className="sr-only"
                        aria-labelledby={`template-${index}`}
                        aria-describedby={`template-${index}`}
                      />
                      <div className="relative h-[200px] w-[200px]">
                        <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                      </div>
                      {checked !== null && index === checked ? (
                        <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : null}
                    </label>
                  );
                })
              : null}
          </div>
          <div className="mt-4 w-full flex justify-end">
            <div className="w-full sm:w-fit">
              <ButtonPrimary text="Save your coupon" size="md" action={handleAddCoupons} />
            </div>
          </div>
          <div className="mt-5 w-full">
            <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
              {!coupons.length ? (
                <DefaultState icon="discount" heading="No Coupons Added" description="Add coupons" />
              ) : (
                coupons.map((value, index) => <DisplayCouponCard key={uuid()} coupon={{ value, index }} removeCoupon={handleRemoveCoupon} />)
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
          <div>{currentStep <= 0 ? null : <ButtonPrimary icon="arrow_back" text={steps[currentStep - 1]} size="md" action={handleGoBack} />}</div>
          <div>{currentStep >= steps.length ? null : <ButtonPrimary icon="arrow_forward" text={steps[currentStep + 1]} size="md-1" action={handleSubmit} />}</div>
        </div>
      </div>
    </div>
  );
}

export default Coupon;
