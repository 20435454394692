/* eslint-disable callback-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import UploadButton from "./UploadButton";
import { MdClear, MdOutlineCloudUpload } from "react-icons/md";
import Preview from "./Preview";
import Modal from "../modal/Modal";
import notify from "../../services/toast";
import config from "../../config";

function FileUploader({ fileType, isMultiple, validationCriteria, DisplayIcon, accessToken, folder, description, numberOfFiles, callback, className }) {
  const [queue, setQueue] = React.useState([]);
  const [readingProgress, setReadingProgress] = React.useState("");
  const [remainingUploads, setRemainingUploads] = React.useState(numberOfFiles);
  const fileExplorerRef = React.useRef(null);
  const [uploading, setUploading] = React.useState(false);
  const success = React.useRef(0);
  const failedValidation = React.useRef(0);
  const [open, setOpen] = React.useState(false);

  const uploadMoreHandler = () => fileExplorerRef.current.openFileExplorer();

  const countInvalidFile = () => {
    const inValidFiles = queue.filter((file) => Object.keys(file.error).length > 0);
    failedValidation.current = inValidFiles.length;
  };

  const handleClearQueue = React.useCallback(() => {
    fileExplorerRef.current.resetFileInput();
    setQueue([]);
    setReadingProgress("");
    failedValidation.current = 0;
    setRemainingUploads(numberOfFiles);
    success.current = 0;
  }, []);

  React.useEffect(() => {
    countInvalidFile();
    setRemainingUploads(() => numberOfFiles - queue.length);
  }, [queue]);

  const handleRemoveFile = (derivedName) => {
    const newQueue = queue.filter((file) => file.derivedName !== derivedName);
    setQueue(newQueue);
    fileExplorerRef.current.updateUploads();
  };

  const handleUpload = React.useCallback(async () => {
    try {
      setUploading(true);
      if (fileType === "image/*") {
        for (const file of queue) {
          if (!Object.keys(file.error).length) {
            const form = new FormData();
            form.append("width", validationCriteria.requiredDimensions.width);
            form.append("height", validationCriteria.requiredDimensions.height);
            form.append("folder", folder);
            form.append("image", file.file);
            const BASE_API_URL = config["API_BASE_ENDPOINT"];
            const response = await fetch(`${BASE_API_URL}/uploads/images`, {
              method: "POST",
              body: form,
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            if (response.ok) {
              const jsonResponse = await response.json();
              callback(jsonResponse.data);
              success.current += 1;
            } else {
              setUploading(false);
              handleClearQueue();
              setOpen(false);
              notify("An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.", "error");
              return;
            }
          }
        }
      } else if (fileType === "video/*") {
        for (const file of queue) {
          if (!Object.keys(file.error).length) {
            const form = new FormData();
            form.append("folder", folder);
            form.append("video", file.file);
            const BASE_API_URL = config["API_BASE_ENDPOINT"];
            const response = await fetch(`${BASE_API_URL}/uploads/videos`, {
              method: "POST",
              body: form,
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            if (response.ok) {
              const jsonResponse = await response.json();
              callback(jsonResponse.data);
              success.current += 1;
            } else {
              setUploading(false);
              handleClearQueue();
              setOpen(false);
              notify("An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.", "error");
              return;
            }
          }
        }
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      if (success.current === queue.length - failedValidation.current) {
        setUploading(false);
        handleClearQueue();
        setOpen(false);
      }
    }
  }, [queue]);

  return (
    <>
      {open ? (
        <Modal>
          <div className="w-[800px] mx-auto bg-white shadow-md rounded-md divide-y-2">
            <div className="w-full h-full flex flex-col items-center sm:h-fit sm:flex sm:flex-row sm:justify-between sm:items-center p-3">
              <div className="text-sm text-gray-400 font-bold">{readingProgress}</div>
              {failedValidation.current ? <div className="text-sm text-red-400 font-bold">{failedValidation.current} file(s) failed validation</div> : null}
              <div className="text-sm text-gray-400 font-bold">{queue.length} File(s) queued</div>
            </div>
            <div className="w-full h-[500px] overflow-y-auto flex flex-wrap justify-center gap-3 p-3">
              {queue.length
                ? queue.map((file) => {
                    return <Preview key={file.derivedName} file={file} handleRemoveFile={handleRemoveFile} uploading={uploading} fileType={fileType} />;
                  })
                : null}
              <div className={`${queue.length ? "hidden" : "block"} w-full flex justify-center items-center`}>
                <UploadButton
                  ref={fileExplorerRef}
                  fileType={fileType}
                  isMultiple={isMultiple}
                  validationCriteria={validationCriteria}
                  setQueue={setQueue}
                  queue={queue}
                  description={description}
                  numberOfFiles={numberOfFiles}
                  setReadingProgress={setReadingProgress}
                />
              </div>
            </div>
            <div className="w-full flex-col space-y-2 flex sm:flex-row sm:space-y-0 justify-between items-center p-3">
              <div className="w-fit flex justify-between items-center gap-3">
                {queue.length && !uploading ? (
                  <button
                    onClick={handleClearQueue}
                    type="button"
                    disabled={uploading ? true : false}
                    className="inline-flex items-center gap-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 transition-all duration-500"
                  >
                    <MdClear className="text-lg" />
                    Cancel
                  </button>
                ) : null}

                {!queue.length && !uploading ? (
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    disabled={uploading ? true : false}
                    className="inline-flex items-center gap-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 transition-all duration-500"
                  >
                    <MdClear className="text-lg" />
                    Close
                  </button>
                ) : null}

                {queue.length && !uploading ? (
                  <button
                    onClick={handleUpload}
                    type="button"
                    disabled={uploading ? true : false}
                    className="inline-flex items-center gap-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition-all duration-500"
                  >
                    <MdOutlineCloudUpload className="text-lg" />
                    Upload
                  </button>
                ) : null}

                {isMultiple && remainingUploads > 0 && queue.length && !uploading ? (
                  <button
                    onClick={uploadMoreHandler}
                    type="button"
                    disabled={uploading ? true : false}
                    className="inline-flex items-center gap-1 px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition-all duration-500"
                  >
                    {remainingUploads} more upload(s)
                  </button>
                ) : null}
              </div>
              {uploading ? <div className="text-sm text-gray-400 font-bold">Uploading...</div> : null}
            </div>
          </div>
        </Modal>
      ) : (
        <DisplayIcon className={className ? className : "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"} callback={() => setOpen(true)} />
      )}
    </>
  );
}

export default FileUploader;
