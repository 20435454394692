/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import Spinner from "../../components/spinner/Spinner";
import Pagination from "../../components/pagination/Pagination";
import getMembers from "./getMembers";
import Prompt from "../../components/prompt/Prompt";
import { showPrompt } from "../../services/util";
import updateMember from "./updateMember";
import deleteMember from "./deleteMember";
import AddMemberModal from "../add_member_modal/AddMemberModal";
import createMember from "./createMember";

function MemberTable({ groupId, groupName, groupType, creator, socket }) {
  const { userState } = useUserContext();
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const [currentMember, setCurrentMember] = React.useState(null);
  const [members, setMembers] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const [pagination, setPagination] = React.useState({ limit: 10, total: 0, page: 0, remaining: 0 });

  const editPromptRef = React.useRef(null);
  const removePromptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });

  const handleGetMembers = async (accessToken, groupId, page, limit) => {
    try {
      setFetching(true);
      const response = await getMembers(accessToken, groupId, page, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setMembers(results);
      } else {
        notify("An error occurred while fetching members. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const handlePrevFetch = async () => {
    await handleGetMembers(userState.accessToken, groupId, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = async () => {
    await handleGetMembers(userState.accessToken, groupId, pagination.page + 1, pagination.limit);
  };

  const handleEditPrompt = (userName, memberId, role) => {
    setCurrentMember({ memberId, role });
    if (role === "admin") {
      showPrompt(
        `Make ${userName.toUpperCase()} a member`,
        `Are you sure you want to make ${userName.toUpperCase()} a member? Members have basic privileges and can't update group information, edit, or delete other members.`,
        editPromptRef,
        "open",
        setDisplayPromptMessage
      );
    } else {
      showPrompt(
        `Make ${userName.toUpperCase()} an administrator`,
        `Are you sure you want to make ${userName.toUpperCase()} an administrator? Administrators can update group information, edit, and delete other members.`,
        editPromptRef,
        "open",
        setDisplayPromptMessage
      );
    }
  };

  const handleRemovePrompt = (userName, memberId) => {
    setCurrentMember({ memberId });
    showPrompt(
      `Remove ${userName.toUpperCase()}`,
      `Are you sure you want to remove ${userName.toUpperCase()} from your group? This action can't be undone.`,
      removePromptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const handleEditMember = async () => {
    try {
      const payload = {
        update: {
          role: currentMember.role === "admin" ? "member" : "admin",
        },
        type: "role",
        groupName,
      };
      const response = await updateMember(userState.accessToken, groupId, currentMember.memberId, payload);
      if (response.status === "success") {
        setCurrentMember(null);
        handleGetMembers(userState.accessToken, groupId, 0, 10);
        editPromptRef.current.closePrompt();
        socket.current.emit("group_role", { recipientId: currentMember.memberId, ...payload });
        notify("Successfully updated member's role", "info");
      } else {
        notify("An error occurred while updating member. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleRemoveMember = async () => {
    try {
      const response = await deleteMember(userState.accessToken, groupId, currentMember.memberId, "admin", groupName);
      if (response.status === "success") {
        setCurrentMember(null);
        handleGetMembers(userState.accessToken, groupId, 0, 10);
        removePromptRef.current.closePrompt();
        socket.current.emit("group_member_remove", { recipientId: currentMember.memberId });
        notify("Successfully removed member from group.", "info");
      } else {
        notify("An error occurred while removing member. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  React.useEffect(() => {
    if (groupId && groupId) {
      handleGetMembers(userState.accessToken, groupId, 0, 10);
    }
  }, []);

  const handleDone = async () => {
    if (!selectedMembers.length) {
      setShowModal(false);
      return;
    }

    try {
      const payload = {
        groupId,
        groupName,
        members: selectedMembers,
        groupType,
        creator,
        type: "create",
      };
      const response = await createMember(userState.accessToken, payload);
      if (response.status === "success") {
        setShowModal(false);
        setSelectedMembers([]);
        notify("Invitations successfully sent.", "info");
      } else {
        notify("An error has occurred while sending the invitation. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  return (
    <div className="w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Members</h1>
          <p className="mt-2 text-sm text-gray-700">A list of all members in your group including their name, status, and role.</p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setShowModal(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto">
            Add Member
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>

                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {fetching ? (
                    <Spinner displayText="Fetching..." />
                  ) : (
                    members.map((member, index) => {
                      return (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={member.user.photo?.small ? member.user.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900 capitalize">
                                  {member.user.firstName} {member.user.lastName}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 capitalize">{member.user.status}</span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{member.role}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <span onClick={() => handleEditPrompt(member.user.lastName, member.user._id, member.role)} className="text-primary-600 hover:text-primary-500 cursor-pointer">
                              {member.role === "member" ? "Make Admin" : "Make Member"}
                              <span className="sr-only">
                                , {member.user.firstName} {member.user.lastName}
                              </span>
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <span onClick={() => handleRemovePrompt(member.user.lastName, member.user._id)} className="text-red-400 hover:text-red-600 cursor-pointer">
                              Remove
                              <span className="sr-only">
                                , {member.user.firstName} {member.user.lastName}
                              </span>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
          </div>
        </div>
      </div>
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={removePromptRef} action={handleRemoveMember} />
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={editPromptRef} action={handleEditMember} />
      <AddMemberModal open={showModal} selectedMembers={selectedMembers} onClose={() => setShowModal(false)} onDone={handleDone} onChange={setSelectedMembers} />
    </div>
  );
}

export default MemberTable;
