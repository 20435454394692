/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { MAGIC_NUMBERS } from "../constant";
import CryptoJS from "crypto-js";
import config from "../config";

export const showMessage = (header, body, type, notificationRef, mode, setErrorMessage) => {
  setErrorMessage({ heading: header, body: body, type: type });
  if (mode === "open") {
    notificationRef.current.showNotification();
  } else if (mode === "close") {
    notificationRef.current.hideNotification();
  }
};

export const showPrompt = (header, body, promptRef, mode, setErrorMessage) => {
  setErrorMessage({ heading: header, body: body });
  if (mode === "open") {
    promptRef.current.showPrompt();
  } else if (mode === "close") {
    promptRef.current.closePrompt();
  }
};

export const showUpgradeModal = (header, body, promptRef, mode, setErrorMessage) => {
  setErrorMessage({ heading: header, body: body });
  if (mode === "open") {
    promptRef.current.showUpgradeModal();
  } else if (mode === "close") {
    promptRef.current.closeUpgradeModal();
  }
};

export const showImageUploader = (action, imageUploadRef, mode, setErrorMessage) => {
  setErrorMessage({ action });
  if (mode === "open") {
    imageUploadRef.current.showImageUploader();
  } else if (mode === "close") {
    imageUploadRef.current.closeImageUploader();
  }
};

export const formatStripeMoney = amount => {
  const amountAsString = amount.toString();
  let amountValue = "0";
  if (amountAsString.length > MAGIC_NUMBERS.ONE) {
    amountValue = amountAsString.substring(MAGIC_NUMBERS.ZERO, amountAsString.length - MAGIC_NUMBERS.TWO);
  }

  return `${amountValue}.00`;
};

export const generateClassName = valid => {
  if (!valid) {
    return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md";
  } else {
    return "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-400 focus:border-red-400 sm:text-md";
  }
};

/**
 * Compares the values of two arrays.
 * The arrays are assumed to have unique values and can be of any length.
 * Return true if they all have same values, false otherwise
 * @param {Array} array1 First array
 * @param {Array} array2 Second array2
 * @return {Boolean} Return true if they have same values false otherwise
 */
export const compareArrays = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const array1Obj = {};
  const array2Obj = {};

  for (const value of array1) {
    array1Obj[value] = 0;
  }

  for (const value of array2) {
    array2Obj[value] = 0;
  }

  for (const key in array1Obj) {
    if (!(key in array2Obj)) {
      return false;
    }
  }
  return true;
};

/**
 *Checks if a value as changed in the original object and modifies the updateObject
 * @param {Object} original Object to compare valie against
 * @param {Any} updatedValue Updated value
 * @param {String} updatedValueKey Key to find in original Object
 * @param {Object} updateObj Containing the new updated value
 * @returns {Null} Returns null
 */
export const compareUpdates = (original, updatedValue, updatedValueKey, updateObj) => {
  if (updatedValueKey in original) {
    if ((typeof updatedValue === "string" || typeof updatedValue === "number") && updatedValue !== "") {
      if (original[updatedValueKey]?.toString().trim().toLowerCase() !== updatedValue?.toString().trim().toLowerCase()) {
        updateObj[updatedValueKey] = updatedValue;
      }
    } else if (Array.isArray(updatedValue)) {
      if (!compareArrays(original[updatedValueKey], updatedValue)) {
        updateObj[updatedValueKey] = updatedValue;
      }
    } else if (typeof updatedValue === "object") {
      if (Object.keys(original[updatedValueKey]).every(key => updatedValue[key] === original[updatedValueKey][key])) {
        updateObj[updatedValueKey] = updatedValue;
      }
    }
  }
  return null;
};

export const inArray = (array, item) => {
  for (const value of array) {
    if (value.toString().trim().toLowerCase() === item.toString().trim().toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const inArrayObject = (array, item, key) => {
  for (const value of array) {
    if (value[key].toString().trim().toLowerCase() === item.toString().trim().toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const greetings = () => {
  const myDate = new Date();
  const hours = myDate.getHours();

  let greetings = "Hi";

  if (hours < 12) {
    greetings = "Good Morning";
  } else if (hours >= 12 && hours <= 17) {
    greetings = "Good Afternoon";
  } else if (hours >= 17 && hours <= 24) {
    greetings = "Good Evening";
  }

  return greetings;
};

export const computeProfilePercentage = profile => {
  const metric = {
    jobTitle: 10,
    phoneNumber: 10,
    company: 10,
    website: 10,
    bio: 10,
    interest: 10,
    causes: 10,
    photo: 10,
  };

  let percentageComplete = 20;

  for (const key in profile) {
    if (key in metric && profile[key]) {
      if (typeof profile[key] === "string" && profile[key]) {
        percentageComplete += metric[key];
      } else if (Array.isArray(profile[key]) && profile[key].length > 0) {
        percentageComplete += metric[key];
      } else if (typeof profile[key] === "object" && profile[key]["url"] !== null && !Array.isArray(profile[key])) {
        percentageComplete += metric[key];
      }
    }
  }

  return percentageComplete;
};

export const getValueFromArrayOfObject = (arrayOfObject, value, valueField = "value", labelField = "label") => {
  for (const obj of arrayOfObject) {
    if (obj[valueField] === value[valueField]) {
      return obj[labelField];
    }
  }
  return value.label;
};

export const convert24hTo12h = timeAsString => {
  if (!timeAsString) {
    return "";
  }
  const [hour, seconds] = timeAsString.split(":");

  if (hour > 12) {
    const newHour = (hour - 12).toString().padStart(2, "0");
    return [newHour, seconds].join(":").concat(" PM");
  }
  return [hour, seconds].join(":").concat(" AM");
};
export const phoneNumberFormatter = phoneNumber => {
  const areaCode = phoneNumber.substring(0, 3);
  const nextThree = phoneNumber.substring(3, 6);
  const lastFour = phoneNumber.substring(6);
  return `(${areaCode}) ${nextThree} - ${lastFour}`;
};

export const formatUrl = url => {
  if (!url.startsWith("http")) {
    return `http://${url}`;
  }
  return url;
};

export function computeReviewSummary(reviews) {
  const ratings = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };

  let totalRatings = 0;
  for (const rating of reviews) {
    ratings[rating["_id"]] = rating["total"];
    totalRatings += rating["total"];
  }

  const averageRating = totalRatings ? Math.round((ratings["1"] * 1 + ratings["2"] * 2 + ratings["3"] * 3 + ratings["4"] * 4 + ratings["5"] * 5) / totalRatings) : 0;

  for (const rating in ratings) {
    ratings[rating] = Math.round((ratings[rating] * 100) / totalRatings);
  }
  return { resultSummary: ratings, avgRating: averageRating, totalReviews: totalRatings };
}

export const determineListingHour = listingBusinessHours => {
  const dayOfWeek = { 1: "Monday", 2: "Tuesday", 3: "Wednesday", 4: "Thursday", 5: "Friday", 6: "Saturday", 0: "Sunday" };
  const currentDayOfWeek = dayOfWeek[new Date().getDay()];
  const hour = listingBusinessHours?.[currentDayOfWeek] || null;

  if (hour && hour["round"]) {
    return hour["round"];
  } else if (hour) {
    const currentTimeInHour = Math.round(new Date().getTime() / 3.6e6);
    const currentYear = new Date().getFullYear();
    const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
    const currentDate = new Date().getDate().toString().padStart(2, "0");

    const openTimeHour = hour["open"];
    const closeTimeHour = hour["close"];

    const openTime = new Date(`${currentYear}-${currentMonth}-${currentDate}T${openTimeHour}:00`);
    const closeTime = new Date(`${currentYear}-${currentMonth}-${currentDate}T${closeTimeHour}:00`);

    const openTimeInHour = Math.round(new Date(openTime).getTime() / 3.6e6);
    const closeTimeInHour = Math.round(new Date(closeTime).getTime() / 3.6e6);
    if (currentTimeInHour >= openTimeInHour && currentTimeInHour <= closeTimeInHour) {
      return "open";
    }
  }
  return "close";
};

export const debounce = (callback, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const encrypt = plainText => {
  const cipherText = CryptoJS.AES.encrypt(plainText, config["ENCRYPTION_KEY"]).toString();
  return cipherText;
};

export const decrypt = cipherText => {
  const bytes = CryptoJS.AES.decrypt(cipherText, config["ENCRYPTION_KEY"]);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

//APPLICATION VALIDATORS
/*-------------------------------------------------------------------
|  🐼 Input Validators 
|
|  🐯 Purpose: THIS FILE CONTAINS ALL THE VALIDATORS OBJECTS
|
|  🐸 Returns:  -
*-------------------------------------------------------------------*/

export const nameValidation = {
  name: "name",
  label: "name",
  type: "text",
  id: "name",
  placeholder: "write your name ...",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    maxLength: {
      value: 30,
      message: "30 characters max",
    },
  },
};

export const descValidation = {
  name: "description",
  label: "description",
  multiline: true,
  id: "description",
  placeholder: "write description ...",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    maxLength: {
      value: 200,
      message: "200 characters max",
    },
  },
};



export const numValidation = {
  name: "num",
  label: "number",
  type: "number",
  id: "num",
  placeholder: "Write a number",
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const emailValidation = {
  name: "email",
  label: "email address",
  type: "email",
  id: "email",
  placeholder: "enter email address",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "not valid",
    },
  },
};

export function findInputError(errors, name) {
  const filtered = Object.keys(errors)
    .filter(key => key.includes(name))
    .reduce((cur, key) => {
      return Object.assign(cur, { error: errors[key] });
    }, {});
  return filtered;
}

export const isFormInvalid = err => {
  if (Object.keys(err).length > 0) {
    return true;
  } else{ return false; }
};
