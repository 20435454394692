import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/user/plan`;

async function getMembers(query, page, limit, accessToken) {
  let url = `${API_ENDPOINT}?page=${page}&limit=${limit}`;
  Object.keys(query).forEach(key => {
    url += `&${key}=${query[key]}`;
  });
  const response = await makeAPIRequest(url, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getMembers;
