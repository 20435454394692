/* eslint-disable react/prop-types */
import React from "react";
import VacationHomesFilter from "../vacation_home_filter/VacationHomesFilter";

 function PropertySearch({
  keyword,
  filters,
  setFilters,
  setKeyword,
  onSearch,
  onFilter,
  viewMode,
  setViewMode,
 }) {
  const anyFilter = filters.ratings.length > 0 ||
    filters.bedrooms.length > 0 ||
    filters.beds.length > 0 ||
    filters.bathrooms.length > 0 ||
    (filters.discounts || []).length > 0 ||
    filters.min > 0 ||
    filters.max > 0 ||
    Object.keys(filters.amenities.bathroom).filter(item => !!filters.amenities.bathroom[item]).length > 0 ||
    Object.keys(filters.amenities.bedroomLaundry).filter(item => !!filters.amenities.bedroomLaundry[item]).length > 0 ||
    Object.keys(filters.amenities.entertainmentTech).filter(item => !!filters.amenities.entertainmentTech[item]).length > 0 ||
    Object.keys(filters.amenities.kitchen).filter(item => !!filters.amenities.kitchen[item]).length > 0 ||
    Object.keys(filters.amenities.safety).filter(item => !!filters.amenities.safety[item]).length > 0 ||
    Object.keys(filters.amenities.heatingCooling).filter(item => !!filters.amenities.heatingCooling[item]).length > 0 ||
    Object.keys(filters.amenities.family).filter(item => !!filters.amenities.family[item]).length > 0 ||
    Object.keys(filters.amenities.parking).filter(item => !!filters.amenities.parking[item]).length > 0 ||
    Object.keys(filters.amenities.outdoor).filter(item => !!filters.amenities.outdoor[item]).length > 0 ||
    Object.keys(filters.amenities.accessibility).filter(item => !!filters.amenities.accessibility[item]).length > 0;

  const handleClear = () => {
    setFilters({
      ratings: [],
      discounts: [],
      bedrooms: [],
      beds: [],
      bathrooms: [],
      min: 0,
      max: 0,
      amenities: {
        bathroom: {},
        bedroomLaundry: {},
        entertainmentTech: {},
        kitchen: {},
        safety: {},
        heatingCooling: {},
        family: {},
        parking: {},
        outdoor: {},
        accessibility: {},
      },
    });
    onFilter({
      ratings: [],
      discounts: [],
      bedrooms: [],
      beds: [],
      bathrooms: [],
      min: 0,
      max: 0,
      amenities: {
        bathroom: {},
        bedroomLaundry: {},
        entertainmentTech: {},
        kitchen: {},
        safety: {},
        heatingCooling: {},
        family: {},
        parking: {},
        outdoor: {},
        accessibility: {},
      },
    });
  };

  return (

    <div className="bg-iwhite">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <div className="flex items-center">   
              <label htmlFor="simple-search" className="sr-only">Search</label>
              <div className="relative w-half">
                
        <input
          type="text"
          id="simple-search"
          className="shadow-sm focus:ring-primary-400 focus:border-primary-400 block w-full max-w-md p-3 sm:text-sm border-gray-300 rounded-full"
          placeholder="Search"
          value={keyword}
          onChange={evt => setKeyword(evt.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onSearch();
            }
          }}
          required
        ></input>
    </div>
    <button
      type="button"
      onClick={onSearch}
      className="inline-flex items-center py-2.5 px-3 ml-2 text-sm font-medium text-white bg-primary-400 rounded-full border border-primary-400 hover:bg-primary-800 focus:outline-none dark:bg-primary-600 dark:hover:bg-primary-700"
    >
        Search
    </button>

    <div className="text-center">
    <button
      type="button"
      onClick={() => setViewMode(viewMode === 0 ? 1 : 0)}
      className="inline-flex items-center py-2.5 px-3 ml-2 text-sm font-medium text-white bg-success-800 rounded-full border border-success-800 hover:bg-success-50 focus:outline-none dark:bg-success-800 dark:hover:bg-success-50"
    >
      Show {viewMode === 0 ? "Map" : "List"}
    </button>
    </div>
    {anyFilter && <button
      onClick={handleClear}
      type="button"
      className="inline-flex items-center ml-2  gap-2 px-3.5 py-2.5 border border-transparent shadow-sm text-md leading-4 font-medium rounded-full text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 transition-all duration-500"
    >
      Clear
    </button>}
    {!!setFilters && <VacationHomesFilter
      filters={filters}
      setFilters={setFilters}
      onFilter={onFilter}
      onClear={handleClear}
    />}
</div>

          </div>
          
        </div>
      </div>
    </div>
   




  );
}
export default PropertySearch;