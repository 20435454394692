/* eslint-disable react/prop-types */
/* eslint-disable id-blacklist */
/* eslint-disable no-unused-vars */
import React from "react";
import { useOutletContext } from "react-router-dom";
import MemberTable from "../../../components/group_member_table/MemberTable";
import ButtonPrimary from "./../../../components/button/ButtonPrimary";
import FileUploader from "../../../components/file_uploader/FileUploader";
import { validateGroupName, validateMessage } from "../../../services/validation";
import { generateClassName } from "../../../services/util";
import { MdLocalSee } from "react-icons/md";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import updateGroup from "./updateGroup";
import { MAGIC_NUMBERS } from "../../../constant";
import useScrollPage from "../../../hooks/useScrollPage";
import PickTemplate from "../../../components/pick_template/PickTemplate";

const logoCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };
const coverPhotoCriteria = { requiredSize: 6e6, requiredDimensions: { width: 1000, height: 800 } };

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

function Template({ callback }) {
  return (
    <div onClick={callback} className="absolute top-[80%] right-10 mt-5 sm:mt-0">
      <ButtonPrimary text="Select a template" size="md" />
    </div>
  );
}

function Settings() {
  useScrollPage();

  const { userState } = useUserContext();
  const { group, socket } = useOutletContext();
  const [groupName, setGroupName] = React.useState(group ? group.name : "");
  const [validName, setValidName] = React.useState(false);

  const [groupDescription, setGroupDescription] = React.useState(group ? group.description : "");
  const [validDescription, setValidDescription] = React.useState(false);

  const [groupRules, setGroupRules] = React.useState(group ? group.rules : "");
  const [validRules, setValidRules] = React.useState(false);

  const [logos, setLogos] = React.useState(group && group.logos ? group.logos : null);
  const [coverImages, setCoverImages] = React.useState(group && group.coverImages ? group.coverImages : null);

  const [updating, setUpdating] = React.useState(false);

  React.useEffect(() => {
    const result = validateGroupName(groupName);
    setValidName(result);
  }, [groupName]);

  React.useEffect(() => {
    const result = validateMessage(groupDescription);
    setValidDescription(result);
  }, [groupDescription]);

  React.useEffect(() => {
    const result = validateMessage(groupRules);
    setValidRules(result);
  }, [groupRules]);

  const handleLogoUpload = result => {
    setLogos(result);
  };

  const handleCoverImagesUpload = result => {
    setCoverImages(result);
  };

  const handleGroupUpdate = async () => {
    try {
      setUpdating(true);
      const payload = {
        update: {
          name: groupName.toLowerCase(),
          description: groupDescription,
          rules: groupRules,
          coverImages: coverImages,
          logos: logos,
        },
      };
      const response = await updateGroup(userState.accessToken, group._id, payload);
      if (response.status === "success") {
        notify("Successfully updated group", "info");
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setUpdating(false);
    }
  };
  return (
    <div className="relative w-full bg-white rounded-md shadow-md mt-4">
      <div className="relative max-w-6xl mx-auto bg-white rounded-t-md h-[400px]">
        <img src={coverImages?.large ? coverImages.large.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_2.png"} className="w-full h-full object-cover" />
        <FileUploader
          fileType="image/*"
          isMultiple={false}
          validationCriteria={coverPhotoCriteria}
          DisplayIcon={PhotoDisplay}
          accessToken={userState.accessToken}
          folder="group_photos"
          description="1000 X 800 6MB Cover Photo"
          numberOfFiles={1}
          callback={handleCoverImagesUpload}
        />
        <div className="absolute top-[280px] left-10 w-[150px] h-[150px] flex items-center justify-center rounded-sm shadow-sm border-solid">
          <img src={logos?.small ? logos.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"} className="w-full h-full object-cover" />
          <FileUploader
            fileType="image/*"
            isMultiple={false}
            validationCriteria={logoCriteria}
            DisplayIcon={PhotoDisplay}
            accessToken={userState.accessToken}
            folder="group_photos"
            description="400 X 400 5MB Logo"
            numberOfFiles={1}
            callback={handleLogoUpload}
          />
        </div>
        <PickTemplate DisplayIcon={Template} className="" setBackground={setCoverImages} setLogo={setLogos} />
      </div>
      <div className="w-full h-full bg-white p-10 space-y-8">
        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Group Name
            </label>
          </div>
          <div className="mt-1">
            <input
              onChange={event => setGroupName(event.target.value)}
              type="text"
              name="name"
              id="name"
              value={groupName}
              className={generateClassName(!validName && groupName)}
              placeholder="Technology Group"
              aria-describedby="name-optional"
            />
          </div>
        </div>

        <div className="w-full">
          <div className="w-full flex justify-between">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Group Description
            </label>
          </div>
          <div className="mt-1">
            <textarea
              rows="4"
              name="comment"
              onChange={event => setGroupDescription(event.target.value)}
              value={groupDescription}
              id="description"
              className={generateClassName(!validDescription && groupDescription)}></textarea>
          </div>
        </div>

        <div className="w-full">
          <div className="w-full flex justify-between">
            <label htmlFor="rules" className="block text-sm font-medium text-gray-700">
              Group Rules
            </label>
          </div>
          <div className="mt-1">
            <textarea
              rows="4"
              name="rules"
              id="rules"
              onChange={event => setGroupRules(event.target.value)}
              value={groupRules}
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
        </div>
        <div className="w-full flex justify-end">
          <div>
            <ButtonPrimary text="Save Changes" size="md" action={handleGroupUpdate} loading={!validName || !validDescription || updating ? true : false} />
          </div>
        </div>

        {group && group.status === "ACTIVE" ? (
          <>
            <hr />
            <MemberTable groupId={group._id} groupName={group.name} groupType={group.type} creator={group.creator} socket={socket} />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Settings;
