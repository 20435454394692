/* eslint-disable no-console */
import React from "react";
import { Outlet } from "react-router-dom";
import useRefreshToken from "../../hooks/useRefreshToken";
import useUserContext from "../../hooks/useUserContext";
import Spinner from "../spinner/Spinner";

function PersistAuth() {
  const [isLoading, setIsLoading] = React.useState(true);
  const refresh = useRefreshToken();
  const { userState } = useUserContext();

  React.useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!userState?.accessToken) {
      verifyRefreshToken();
    } else {
      setIsLoading(false);
    }
  }, []);

  return <>{isLoading ? <Spinner displayText="Please wait..." /> : <Outlet />}</>;
}

export default PersistAuth;
