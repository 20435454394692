/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import {FaWindowClose} from "react-icons/fa";
import Popup from "reactjs-popup";

function FeaturesVideo({ button, video, title, poster}) {
  

  return (
 
    <Popup trigger={button} modal nested position="right center">
    {close => (
      <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
            <div className="w-full flex justify-between">
              <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">{title}</h1>
              <button className="button" onClick={() => { close(); }} >
                <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close"/> </button> 
            </div>
            <video controls className="h-[550px]" poster={poster}>
              
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
    )}
  </Popup>
  );
}

export default React.forwardRef(FeaturesVideo);
