/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React, { useState, useEffect } from "react";
import Spinner from "../../../components/spinner/Spinner";
import useScrollPage from "../../../hooks/useScrollPage";
import CalendarMonthView from "../../../components/calendar_month_view/CalendarMonthView";
import CalendarWeekView from "../../../components/calendar_week_view/CalendarWeekView";
import CalendarDayView from "../../../components/calendar_day_view/CalendarDayView";
import CalendarListView from "../../../components/calendar_list_view/CalendarListView";
import CalendarNav from "../../../components/calendar_nav/CalendarNav";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import { DEFAULT_PAGINATION_LIMIT, MAGIC_NUMBERS } from "../../../constant";
import getEvents from "./getEvents";
import getPollIndividualVotes from "./getPollIndividualVotes";
import getPollTotalVotes from "./getPollTotalVotes";
import postVotePoll from "./postVotePoll";
import { Carousel } from "react-responsive-carousel";
import Poll from "../../../components/poll/Poll";
import json from "../../../options.json";
import getEventsList from "./getEventsList";
import Pagination from "../../../components/pagination/Pagination";


function EventsCalendar() {
  const {
    userState: { accessToken },
  } = useUserContext();
  const [events, setEvents] = useState([]);
  const [pollVotes, setPollVotes] = useState([]);
  const [totalPollVotes, setTotalPollVotes] = useState([]);
  const [pollOptions, setPollOptions] = useState([]);
  const [userVoted, setUserVoted] = useState(false);
  const [pollId, setPollId] = useState("645bd13e86b75395c4d4b024");
  const [filterListId, setFilterListId] = useState("");
  const [fetching, setFetching] = React.useState(false);
  const [viewMode, setViewMode] = useState(0);
  const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(1);
  const today = new Date();
  const startDate = new Date();
  const lastMondayDays = (today.getDay() === 0 ? 6 : today.getDay() - 1);
  startDate.setDate(today.getDate() - lastMondayDays);
  const [selectedWeek, setSelectedWeek] = useState(startDate);
  const [selectedDay, setSelectedDay] = useState(today);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: DEFAULT_PAGINATION_LIMIT });

  const getAllEvents = async (accessToken) => {
    try {
      setFetching(true);
      let year = 0;
      let month = 0;
      if (viewMode === 0) {
        year = selectedWeek.getFullYear();
      }
      // if (viewMode === 1) {
      //   year = selectedDay.getFullYear();
      //   month = selectedDay.getMonth() + 1;
      // }
      // if (viewMode === 2) {
      //   year = selectedWeek.getFullYear();
      //   month = selectedWeek.getMonth() + 1;
      // }
      if (viewMode === 1) {
        year = selectedYear;
        month = selectedMonth;
      }
      const response = await getEvents(accessToken, year, month, false);
      if (response.status === "success") {
        setEvents(response.data);
        console.log(response.data);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const getListEvents = async (accessToken, offset, limit, filterListId) => {
    try {
      setFetching(true);
      const response = await getEventsList(accessToken, offset, limit, filterListId);

      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setEvents(results);
        console.log(results);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };
  
  const fetchTotalPollVotes = async (accessToken) => {
    try {
      setFetching(true);
      const response = await getPollTotalVotes(accessToken, pollId);
      if (response.status === "success") {
        setPollOptions(response.data.options);
        setTotalPollVotes(response.data);
        // console.log(response.data.options);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };
  
  const fetchIndividualPollVotes = async (accessToken) => {
    try {
      setFetching(true);
      const response = await getPollIndividualVotes(accessToken, pollId);
      if (response.status === "success") {
        setPollVotes(response.data);
        // console.log(response.data);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const handleVote = async (voteOption) => {
    if (userVoted) {
      notify("Thank you, you already voted!");
    } else {
      try {
        const payload = {
          voteOption: voteOption,
        };
        const response = await postVotePoll(accessToken, payload, pollId);
        if (response.status === "success") {
          setPollVotes(response.data);
          notify("Thank you for voting!");
          fetchTotalPollVotes(accessToken);
        } else {
          let displayMessage = "";
          if (Array.isArray(response.message)) {
            displayMessage = response.message[0].message;
          } else {
            displayMessage = response.message;
            if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
              displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
            }
          }
          notify(displayMessage, "error");
        }
      } catch (error) {
        notify("Something went wrong. Please try again later", "error");
      } finally {
        setUserVoted(true);
      }
    }
  };


  useEffect(() => {
    fetchTotalPollVotes(accessToken);
    fetchIndividualPollVotes(accessToken);
  }, []);

  // useEffect(() => {
  //   getAllEvents(accessToken);
  // // }, [viewMode, selectedDay, selectedWeek, selectedMonth, selectedYear]);
  // useEffect(() => {
  //   getListEvents(accessToken);
  // }, []);

  const handlePrevFetch = async () => {
    await getListEvents(accessToken, pagination.page - 1, pagination.limit, filterListId);
  };

  const handleNextFetch = async () => {
    await getListEvents(accessToken, pagination.page + 1, pagination.limit, filterListId);
  };

  useEffect(() => {
    getListEvents(accessToken, 0, DEFAULT_PAGINATION_LIMIT, filterListId);
  }, [filterListId]);
  
  


  useScrollPage();
  return (
    <div className="p-5 space-y-2">
      <div className="py-24 sm:py-12 bg-white overflow-hidden rounded-lg shadow-lg"> 
        
        <div className="relative overflow-hidden pt-3 pb-2">
          {/* <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" /> */}
          <div className="relative">
            {/* <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"> */}
              <div className="mx-auto  text-center max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-0 lg:px-0">
                <div>
                  <h2 className="text-3xl font-bold font-bebas tracking-wide text-success-800">
                    Hosting a Workshop or Event? Be Our Guest!
                  </h2>
                  {/* <p className="mt-4 text-lg text-gray-500">
                    Have a workshop or event and want to share it with the COhatch community? You can do it all here inside of the Workshops tab. List your in-person or virtual workshop or event, request to book COhatch Workshop spaces, and extend your reach to COhatch members all over the country. Expand your reach, level up your skills, and meet new people here.
                  </p> */}
                </div>
              </div>
                
              {/* Image Carousel >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
              {/* <div className="mt-10 sm:mt-10 lg:mt-0">
                <Carousel
                  showThumbs={false}
                  centerSlidePercentage={50}
                  showStatus={false}
                  autoPlay={true}
                  interval={5000}
                  infiniteLoop={true}
                  renderArrowNext={(clickHandler, hasNext) =>
                    hasNext && (
                    <button
                      className="control-arrow control-next"
                      onClick={clickHandler}
                    >
                      <span className="material-icons-outlined text-4xl">
                        arrow_forward_ios
                      </span>
                    </button>
                  )}
                  renderArrowPrev={(clickHandler, hasPrev) =>
                    hasPrev && (
                      <button
                        className="control-arrow control-prev"
                        onClick={clickHandler}
                      >
                        <span className="material-icons-outlined text-4xl">
                          arrow_back_ios
                        </span>
                      </button>
                  )}
                >
                  <div className="w-full h-[300px]">
                    <img
                      src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops%2F07.06.22_CodeLaunch-26_web.jpg"
                      alt="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"
                      className="w-full h-full object-cover shadow-xl"
                    />
                  </div>
                  <div className="w-full h-[300px]">
                    <img
                      src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops%2F07.06.22_CodeLaunch-28_web.jpg"
                      alt="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"
                      className="w-full h-full object-cover shadow-xl"
                    />
                  </div>
                  <div className="w-full h-[300px]">
                    <img
                      src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops%2F09.16.22_Give%20Scholar%20Brunch-33_web.jpg"
                      alt="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"
                      className="w-full h-full object-cover shadow-xl"
                    />
                  </div>
                  <div className="w-full h-[300px]">
                    <img
                      src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops%2F12.09.21_Slalom%20Holiday%20Party-12.jpg"
                      alt="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"
                      className="w-full h-full object-cover shadow-xl"
                    />
                  </div>
                  <div className="w-full h-[300px]">
                    <img
                      src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops%2FKnitting%20Temptations-10_web.jpg"
                      alt="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/templates/listing_cover_photo/WorkshopCoverImage.png"
                      className="w-full h-full object-cover shadow-xl"
                    />
                  </div>
                </Carousel>
              </div> 
            </div>*/}
          </div>
        </div>

        {/* Poll Added Here */}
        {/* <div className="mx-auto mt-12 max-w-7xl ">
          <div className="mx-auto max-w-2xl">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold font-bebas tracking-wide text-success-800">
                What type of workshop would you like to see more of?
              </h2>
            </div>
            <Poll options={pollOptions} voteCallback={handleVote}/>
          </div>
        </div> */}

        

        {/* <div className="relative pb-12">
          <div className="text-center pt-4 pb-4 gap-x-6 overflow-hidden bg-primary-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
            <div className="text-center gap-x-4 gap-y-2">
              <p className="leading-6 text-white">
                <strong className=" text-2xl font-bold font-bebas tracking-wide "> Did you know you get 1 hour per month with your COhatch+ membership to host a Workshop at COhatch?</strong>
              </p> 
            </div>
            <div className="pt-4">
              <a
                href="/dashboard/meeting_rooms"
                className="flex-none rounded-full bg-success-800 px-3.5 py-1 text-md font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                Book a Room <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div> 
        </div> */}

      <div className="App">
        {fetching ? (
          <Spinner displayText="Fetching..." />
        ) : (<div className="lg:flex sticky lg:h-full lg:flex-col">
          <CalendarNav
            setViewMode={setViewMode}
            viewMode={viewMode}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            onReload={() => getAllEvents(accessToken, 0, 100)}
          />
          {viewMode === 0 && <CalendarListView  events={events} setEvents={setEvents} onReload={() => getAllEvents(accessToken)} pagination={pagination} handleNextFetch={handleNextFetch} handlePrevFetch={handlePrevFetch} setFilterListId={setFilterListId}/>}
          {/* {viewMode === 1 && <CalendarDayView selectedDay={selectedDay} events={events} setEvents={setEvents} setSelectedDay={setSelectedDay} onReload={() => getAllEvents(accessToken)} />}
          {viewMode === 2 && <CalendarWeekView selectedWeek={selectedWeek} events={events} setEvents={setEvents} onReload={() => getAllEvents(accessToken)} />} */}
          {viewMode === 1 && <CalendarMonthView selectedMonth={selectedMonth} selectedYear={selectedYear} events={events} setEvents={setEvents} onReload={() => getAllEvents(accessToken)} />}
          
        </div>)}
      </div>

      <div className="bg-white py-24 sm:py-12">
          <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
            <div className="mx-auto max-w-2xl">
              <h2 className="text-3xl font-bold font-bebas tracking-wide  text-success-800 sm:text-4xl">HOW TO ADD WORKSHOPS?</h2>
            </div>
            <ul role="list" className="mx-auto mt-12 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <li>
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops/Meetings%20Upper%20Arlington%201.jpg" alt=""></img>
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">1. Book a room at COhatch, create a virtual link, or find a location for your workshop.</h3>
              </li>
              <li>
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops/12.08.22_Worthington%20Holiday%20Marketplace-1_web%20(1).jpg" alt=""></img>
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">2. Add an event and hit submit. Our team will review and approve events within one business day.</h3>
              </li>
              <li>
                <img className="aspect-[3/2] w-full rounded-2xl object-cover" src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/workshops/Slalom%20Holiday%20Party%202022-8.jpg" alt=""></img>
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">3. Check out your RSVPs in the “My Events” tab.</h3>
              </li>
            </ul>
          </div>
        </div>

    </div>
    </div>
  );
}

export default EventsCalendar;
