/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import DefaultState from "./../../../components/default_state/DefaultState";
import useUserContext from "./../../../hooks/useUserContext";
import { MdLocalSee } from "react-icons/md";
import { v4 as uuid } from "uuid";
import InterestCard from "../../../components/interest_card/InterestCard";
import CauseCard from "../../../components/cause_card/CauseCard";
import {
  validateUserName,
  validateCompany,
  validateUrl,
  validateBio,
  validateJobTitle,
  validatePhone,
  validateCity,
} from "./../../../services/validation";
import {
  generateClassName,
  compareUpdates,
  inArray,
} from "./../../../services/util";
import submitProfile from "./submitProfile";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  STATES_CITIES,
  CAUSES,
  INTERESTS,
  MAGIC_NUMBERS,
  LOCATIONS,
  NP_OPTIONS,
} from "../../../constant";
import notify from "./../../../services/toast";
import FileUploader from "../../../components/file_uploader/FileUploader";
import { capitalize, lowerCase, sortBy } from "lodash";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "./../../../hooks/useTitle";
import SelectInput from "../../../components/select_input/SelectInput";
import { toLower } from "lodash";
import { isEqual } from "lodash";
import AvatarTemplate from "./../../../components/avatar_template/AvatarTemplate";

const profileImage = {
  requiredSize: 5e6,
  requiredDimensions: { width: 400, height: 400 },
};

function PhotoDisplay({ callback }) {
  return (
    <MdLocalSee
      onClick={callback}
      className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
    />
  );
}

function Template({ callback }) {
  return (
    <div
      onClick={callback}
      className="absolute top-[80%] right-10 mt-5 sm:mt-0"
    >
      <ButtonPrimary text="Select a template" size="md" />
    </div>
  );
}

function Profile() {
  useScrollPage();
  useTitle("COhatch+ Profile");

  const [updatingInfo, setUpdatingInfo] = React.useState(false);
  const { userState, setUserState } = useUserContext();
  const {
    firstName,
    lastName,
    jobTitle,
    phoneNumber,
    city,
    state,
    location,
    company,
    website,
    bio,
    interest,
    causes,
    facebook,
    twitter,
    instagram,
    linkedIn,
    photo,

  } = userState;

  const [profilePhoto, setProfilePhoto] = React.useState(photo || null);

  const [userFirstName, setUserFirstName] = React.useState(
    capitalize(firstName) || ""
  );
  const [validUserFirstName, setValidUserFirstName] = React.useState(false);

  const [userLastName, setUserLastName] = React.useState(
    capitalize(lastName) || ""
  );
  const [validUserLastName, setValidUserLastName] = React.useState(false);

  const [userJobTitle, setUserJobTitle] = React.useState(
    capitalize(jobTitle) || ""
  );
  const [validUserJobTitle, setValidUserJobTitle] = React.useState(false);

  const [userPhoneNumber, setUserPhoneNumber] = React.useState(
    phoneNumber || ""
  );
  const [validUserPhoneNumber, setValidUserPhoneNumber] = React.useState(false);

  const [userCity, setUserCity] = React.useState(capitalize(city) || "");
  const [validUserCity, setValidUserCity] = React.useState(false);

  const [userRegion, setUserRegion] = React.useState(state || null);
  const [userLocation, setUserLocation] = React.useState(location || null);

  const [userCompany, setUserCompany] = React.useState(company || "");
  const [validUserCompany, setValidUserCompany] = React.useState(false);

  const [userWebsite, setUserWebsite] = React.useState(toLower(website) || "");
  const [validUserWebsite, setValidUserWebsite] = React.useState(false);

  const [userFacebook, setUserFacebook] = React.useState(
    toLower(facebook) || ""
  );
  const [validUserFacebook, setValidUserFacebook] = React.useState(false);

  const [userTwitter, setUserTwitter] = React.useState(toLower(twitter) || "");
  const [validUserTwitter, setValidUserTwitter] = React.useState(false);

  const [userInstagram, setUserInstagram] = React.useState(
    toLower(instagram) || ""
  );
  const [validUserInstagram, setValidUserInstagram] = React.useState(false);

  const [userLinkedIn, setUserLinkedIn] = React.useState(
    toLower(linkedIn) || ""
  );
  const [validUserLinkedIn, setValidUserLinkedIn] = React.useState(false);

  const [userBio, setUserBio] = React.useState(bio || "");
  const [validUserBio, setValidUserBio] = React.useState(false);

  const [userInterest, setUserInterest] = React.useState(interest || []);
  const [userCause, setUserCause] = React.useState(causes || []);

  React.useEffect(() => {
    const result = validateUserName(userFirstName);
    setValidUserFirstName(result);
  }, [userFirstName]);

  React.useEffect(() => {
    const result = validateUserName(userLastName);
    setValidUserLastName(result);
  }, [userLastName]);

  React.useEffect(() => {
    const result = validateCompany(userCompany);
    setValidUserCompany(result);
  }, [userCompany]);

  React.useEffect(() => {
    const result = validateUrl(userWebsite);
    setValidUserWebsite(result);
  }, [userWebsite]);

  React.useEffect(() => {
    const result = validateUrl(userFacebook);
    setValidUserFacebook(result);
  }, [userFacebook]);

  React.useEffect(() => {
    const result = validateUrl(userTwitter);
    setValidUserTwitter(result);
  }, [userTwitter]);

  React.useEffect(() => {
    const result = validateUrl(userInstagram);
    setValidUserInstagram(result);
  }, [userInstagram]);

  React.useEffect(() => {
    const result = validateUrl(userLinkedIn);
    setValidUserLinkedIn(result);
  }, [userLinkedIn]);

  React.useEffect(() => {
    const result = validateBio(userBio);
    setValidUserBio(result);
  }, [userBio]);

  React.useEffect(() => {
    const result = validateJobTitle(userJobTitle);
    setValidUserJobTitle(result);
  }, [userJobTitle]);

  React.useEffect(() => {
    const result = validateCity(userCity);
    setValidUserCity(result);
  }, [userCity]);

  React.useEffect(() => {
    const result = validatePhone(userPhoneNumber);
    setValidUserPhoneNumber(result);
  }, [userPhoneNumber]);

  //Add interest
  const [newInterest, setNewInterest] = React.useState(null);
  const handleAddInterest = (event) => {
    event.preventDefault();
    if (!newInterest) {
      return;
    }

    if (inArray(userInterest, newInterest.value)) {
      notify(
        `${newInterest.value} already exists in your interest list`,
        "error"
      );
      return;
    }
    setUserInterest((prevState) => {
      return [...prevState, newInterest.value];
    });
    setNewInterest(null);
  };
  const handleRemoveInterest = (event) => {
    const { interest } = event.target.dataset;
    const newInterestList = userInterest.filter(
      (value, index) => parseInt(interest, 10) !== index
    );
    setUserInterest(newInterestList);
  };

  //Add Causes
  const [newCause, setNewCause] = React.useState(null);
  const handleAddCause = (event) => {
    event.preventDefault();
    if (!newCause) {
      return;
    }

    if (inArray(userCause, newCause.value)) {
      notify(`${newCause.value} already exists in your causes list.`, "error");
      return;
    }
    setUserCause((prevState) => {
      return [...prevState, newCause.value];
    });

    setNewCause(null);
  };

  const handleRemoveCause = (event) => {
    const { cause } = event.target.dataset;
    const newCauseList = userCause.filter(
      (value, index) => parseInt(cause, 10) !== index
    );
    setUserCause(newCauseList);
  };

  const handleUpdate = async () => {
    const newUpdateObject = {};
    const mustHaveFirstName = validateUserName(userFirstName);
    const mustHaveLastName = validateUserName(userLastName);
    const mustHaveCity = validateCity(userCity);

    if (!mustHaveFirstName || !mustHaveLastName || !mustHaveCity) {
      notify(
        "First name, Last name, City and State can't be empty or invalid.",
        "error"
      );
      return;
    }

    if (userJobTitle && !validateJobTitle(userJobTitle)) {
      notify(
        "Job title is invalid. Must be at least 2 characters and can't contain special characters.",
        "error"
      );
      return;
    }

    if (userPhoneNumber && !validatePhone(userPhoneNumber)) {
      notify(
        "Phone number is invalid. Must be exactly 9 digits and can't contain special characters.",
        "error"
      );
      return;
    }

    if (userCompany && !validateCompany(userCompany)) {
      notify(
        "Invalid company name. Must be at least 2 characters with no special characters.",
        "error"
      );
      return;
    }

    if (userWebsite && !validateUrl(userWebsite)) {
      notify(
        "The website address format is invalid. Check to make sure the format is correct.",
        "error"
      );
      return;
    }

    if (userFacebook && !validateUrl(userFacebook)) {
      notify(
        "The Facebook URL format is invalid. Check to make sure the format is correct.",
        "error"
      );
      return;
    }

    if (userInstagram && !validateUrl(userInstagram)) {
      notify(
        "The Instagram URL format is invalid. Check to make sure the format is correct.",
        "error"
      );
      return;
    }

    if (userTwitter && !validateUrl(userTwitter)) {
      notify(
        "The twitter URL format is invalid. Check to make sure the format is correct.",
        "error"
      );
      return;
    }

    if (userLinkedIn && !validateUrl(userLinkedIn)) {
      notify(
        "The LinkedIn URL format is invalid. Check to make sure the format is correct.",
        "error"
      );
      return;
    }

    if (userBio && !validateBio(userBio)) {
      notify("User bio can't exceed 4000 characters.", "error");
      return;
    }

    //Check which user information needs updating
    if (!isEqual(userFirstName, firstName)) {
      newUpdateObject["firstName"] = userFirstName;
    }

    if (!isEqual(userLastName, lastName)) {
      newUpdateObject["lastName"] = userLastName;
    }

    if (!isEqual(userJobTitle, jobTitle)) {
      newUpdateObject["jobTitle"] = userJobTitle;
    }

    if (!isEqual(userPhoneNumber, phoneNumber)) {
      newUpdateObject["phoneNumber"] = userPhoneNumber;
    }

    if (!isEqual(userRegion, state)) {
      newUpdateObject["state"] = userRegion;
    }

    if (!isEqual(userCity, city)) {
      newUpdateObject["city"] = userCity;
    }

    if (!isEqual(userCompany, company)) {
      newUpdateObject["company"] = userCompany;
    }

    if (!isEqual(userWebsite, website)) {
      newUpdateObject["website"] = userWebsite;
    }

    if (!isEqual(userFacebook, facebook)) {
      newUpdateObject["facebook"] = userFacebook;
    }

    if (!isEqual(userTwitter, twitter)) {
      newUpdateObject["twitter"] = userTwitter;
    }

    if (!isEqual(userInstagram, instagram)) {
      newUpdateObject["instagram"] = userInstagram;
    }

    if (!isEqual(userLinkedIn, linkedIn)) {
      newUpdateObject["linkedIn"] = userLinkedIn;
    }

    if (!isEqual(userBio, bio)) {
      newUpdateObject["bio"] = userBio;
    }

    if (!isEqual(userInterest, interest)) {
      newUpdateObject["interest"] = userInterest;
    }

    if (!isEqual(userCause, causes)) {
      newUpdateObject["causes"] = userCause;
    }

    if (!isEqual(profilePhoto, photo)) {
      newUpdateObject["photo"] = profilePhoto;
    }


    if (!Object.keys(newUpdateObject).length) {
      notify(
        "Your profile is already up-to-date. No changes detected.",
        "info"
      );
      return;
    }

    try {
      setUpdatingInfo(true);
      const response = await submitProfile(
        newUpdateObject,
        userState.accessToken
      );
      if (response.status === "success") {
        notify("Profile updated successfully.", "info");

        const { data } = response;
        setUserState((prevState) => {
          return { ...prevState, ...data };
        });
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage =
              "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setUpdatingInfo(false);
    }
  };

  const generateStates = (statesObject) => {
    const states = [];
    for (const state of Object.keys(statesObject)) {
      states.push({ value: state, label: state });
    }
    return sortBy(states, (item) => item.value);
  };

  const generateCities = (stateValue) => {
    if (stateValue) {
      const cities = [];
      for (const city of stateValue) {
        cities.push({ value: city, label: city });
      }
      return sortBy(cities, (item) => item.value);
    }
    return [];
  };

  const handleUpload = (result) => {
    setProfilePhoto(result);
  };

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
        <div className="relative w-full flex justify-center">
          <img
            src={
              profilePhoto?.small
                ? profilePhoto.small.url
                : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"
            }
            alt={
              profilePhoto?.small?.filename
                ? profilePhoto.small.filename
                : "Profile Photo"
            }
            className="inline-block rounded-full border border-3 object-cover h-[200px] w-[200px]"
          />
          <FileUploader
            fileType="image/*"
            isMultiple={false}
            validationCriteria={profileImage}
            DisplayIcon={PhotoDisplay}
            accessToken={userState.accessToken}
            folder="profile_photos"
            description="400 X 400 5MB Profile Photo"
            numberOfFiles={1}
            callback={handleUpload}
          />
          <AvatarTemplate
            DisplayIcon={Template}
            className=""
            setLogo={setProfilePhoto}
          />
        </div>
        <div className="mt-5 w-full sm:p-4 space-y-5">
          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserFirstName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  value={userFirstName}
                  className={generateClassName(
                    !validUserFirstName && userFirstName
                  )}
                  placeholder="Thomas"
                />
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserLastName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  value={userLastName}
                  className={generateClassName(
                    !validUserLastName && userLastName
                  )}
                  placeholder="Muller"
                />
              </div>
            </div>
          </div>
          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="jobTitle"
                className="block text-sm font-medium text-gray-700"
              >
                Job Title
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserJobTitle(event.target.value)}
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  value={userJobTitle}
                  className={generateClassName(
                    !validUserJobTitle && userJobTitle
                  )}
                  placeholder="Software Engineer"
                />
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserPhoneNumber(event.target.value)}
                  type="tel"
                  name="phone"
                  id="phone"
                  value={userPhoneNumber}
                  className={generateClassName(
                    !validUserPhoneNumber && userPhoneNumber
                  )}
                  placeholder="6146589856"
                />
              </div>
            </div>
          </div>

          <SelectInput
            options={generateStates(STATES_CITIES)}
            noOptionsMessage="No states found"
            placeholder="Select a state"
            defaultValue={{
              value: userRegion,
              label: capitalize(userRegion),
            }}
            setSelectedValue={(event) => {
              if (event) {
                setUserRegion(event.value);
              } else {
                setUserRegion(null); // Or any default value you prefer
              }
            }}
          />

          <div className="w-full">
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700"
            >
              City
            </label>
            <div className="mt-1">
              <SelectInput
                options={generateCities(STATES_CITIES[userRegion])}
                noOptionsMessage="Select a state"
                placeholder="Select a city"
                defaultValue={userCity ? { value: userCity, label: capitalize(userCity) } : null}
                setSelectedValue={(event) => {
                  if (event && event.value) {
                    setUserCity(event.value.trim()); // Trim the value if it's not null
                  } else {
                    setUserCity(null); // Or any default value you prefer
                  }
                }}
              />
            </div>
          </div>


          <div className="w-full">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Location
            </label>
            <div className="mt-1">
              <SelectInput
                options={sortBy(LOCATIONS, (item) => item.value)}
                noOptionsMessage="No locations found"
                placeholder="Select a location"
                defaultValue={{
                  value: userLocation,
                  label: capitalize(userLocation),
                }}
                isDisabled={true}
                setSelectedValue={(event) => {
                  if (event) {
                    setUserLocation(event.value);
                  } else {
                    setUserLocation(null); // Or any default value you prefer
                  }
                }}
              />
            </div>
          </div>

          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-700"
              >
                Company Name
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserCompany(event.target.value)}
                  type="text"
                  name="company"
                  id="company"
                  value={userCompany}
                  className={generateClassName(
                    !validUserCompany && userCompany
                  )}
                  placeholder="COhatch"
                />
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="website"
                className="block text-sm font-medium text-gray-700"
              >
                Website
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserWebsite(event.target.value)}
                  type="url"
                  name="website"
                  id="website"
                  value={userWebsite}
                  className={generateClassName(
                    !validUserWebsite && userWebsite
                  )}
                  placeholder="http://www.cohatchplus.com"
                />
              </div>
            </div>
          </div>
          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="facebook"
                className="block text-sm font-medium text-gray-700"
              >
                Facebook
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserFacebook(event.target.value)}
                  type="url"
                  name="website"
                  id="facebook"
                  value={userFacebook}
                  className={generateClassName(
                    !validUserFacebook && userFacebook
                  )}
                  placeholder="https://www.facebook.com"
                />
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="linkedin"
                className="block text-sm font-medium text-gray-700"
              >
                LinkedIn
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserLinkedIn(event.target.value)}
                  type="url"
                  name="website"
                  id="linkedin"
                  value={userLinkedIn}
                  className={generateClassName(
                    !validUserLinkedIn && userLinkedIn
                  )}
                  placeholder="https://www.linkedin.com"
                />
              </div>
            </div>
          </div>
          <div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="instagram"
                className="block text-sm font-medium text-gray-700"
              >
                Instagram
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserInstagram(event.target.value)}
                  type="url"
                  name="website"
                  id="instagram"
                  value={userInstagram}
                  className={generateClassName(
                    !validUserInstagram && userInstagram
                  )}
                  placeholder="https://www.instagram.com"
                />
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="twitter"
                className="block text-sm font-medium text-gray-700"
              >
                Twitter
              </label>
              <div className="mt-1">
                <input
                  onChange={(event) => setUserTwitter(event.target.value)}
                  type="url"
                  name="website"
                  id="twitter"
                  value={userTwitter}
                  className={generateClassName(
                    !validUserTwitter && userTwitter
                  )}
                  placeholder="https://www.twitter.com"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="bio"
              className="block text-sm font-medium text-gray-700"
            >
              About Me
            </label>
            <div className="mt-1">
              <textarea
                onChange={(event) => setUserBio(event.target.value)}
                rows="4"
                name="comment"
                id="bio"
                value={userBio}
                className={generateClassName(!validUserBio && userBio)}
              ></textarea>
            </div>
            <p className="text-sm text-gray-300">4000 characters max</p>
          </div>
          <div className="w-full">
            <h1 className="block text-sm font-medium text-gray-700">
              Add Your Interest
            </h1>
            <form onSubmit={handleAddInterest}>
              <div className="w-full flex items-center gap-3">
                <div className="w-full">
                  <div className="mt-1">
                    <CreatableSelect
                      placeholder="Select or create an interest:"
                      styles={{
                        input: (provided, state) => ({
                          ...provided,
                          padding: 5,
                        }),
                      }}
                      isDisabled={false}
                      isClearable={false}
                      isLoading={false}
                      isRtl={false}
                      isSearchable={true}
                      name="Interest"
                      value={newInterest}
                      menuPlacement="auto"
                      options={INTERESTS}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ecf8ef",
                          primary: "#56c271",
                        },
                      })}
                      onChange={(event) => setNewInterest(event)}
                    />
                  </div>
                </div>
                <div>
                  <ButtonPrimary icon="add" size="md" />
                </div>
              </div>
            </form>
            <div className="mt-5 w-full">
              <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                {!userInterest.length ? (
                  <DefaultState
                    icon="interests"
                    heading="No Interests Found"
                    description="Add hobbies or interests."
                  />
                ) : (
                  userInterest.map((value, index) => (
                    <InterestCard
                      key={uuid()}
                      interest={{ value, index }}
                      removeInterest={handleRemoveInterest}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="w-full">
            <h1 className="block text-sm font-medium text-gray-700">
              Causes You Care About
            </h1>
            <form onSubmit={handleAddCause}>
              <div className="w-full flex items-center gap-3">
                <div className="w-full">
                  <div className="mt-1">
                    <CreatableSelect
                      placeholder="Select or create an interest:"
                      styles={{
                        input: (provided, state) => ({
                          ...provided,
                          padding: 5,
                        }),
                      }}
                      isDisabled={false}
                      isClearable={false}
                      isLoading={false}
                      isRtl={false}
                      isSearchable={true}
                      name="Cause"
                      value={newCause}
                      menuPlacement="auto"
                      options={CAUSES}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ecf8ef",
                          primary: "#56c271",
                        },
                      })}
                      onChange={(event) => setNewCause(event)}
                    />
                  </div>
                </div>
                <div>
                  <ButtonPrimary icon="add" size="md" />
                </div>
              </div>
            </form>
            <div className="mt-5 w-full">
              <div className="w-full flex flex-wrap gap-2 items-start justify-start border-4 border-dashed rounded-md overflow-y-auto p-3">
                {!userCause.length ? (
                  <DefaultState
                    icon="diversity_1"
                    heading="No Causes You Care About Found"
                    description="Add the causes you care about."
                  />
                ) : (
                  userCause.map((value, index) => (
                    <CauseCard
                      key={uuid()}
                      cause={{ value, index }}
                      removeCause={handleRemoveCause}
                    />
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="w-full flex justify-end">
            <div>
              <ButtonPrimary
                text={updatingInfo ? "Please wait..." : "Save Changes"}
                size="md"
                icon="save"
                action={handleUpdate}
                loading={updatingInfo ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

