/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import useUserContext from "./../../hooks/useUserContext";
import Prompt from "../../components/prompt/Prompt";
import { showPrompt } from "../../services/util";

function Option({ actions = [], itemKey = null, deleteAction }) {
  const { userState } = useUserContext();
  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });

  const handleShowPrompt = () => {
    showPrompt("Delete", "Are you sure you want to delete this item? This operation cannot be undone.", promptRef, "open", setDisplayPromptMessage);
  };
  return (
    <div className="flex-shrink-0 self-center flex z-30">
      <div className="group relative inline-block text-left">
        <div>
          <button type="button" className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
            <span className="sr-only">Open options</span>
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>
        </div>
        <div
          className="invisible opacity-0 group-hover:visible group-hover:opacity-100 origin-top-right absolute right-7 top-0 w-56 rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition-all ease-linear duration-500"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu-0-button"
          tabIndex="-1">
          {actions.map((action, index) => {
            if (action.key === "delete" && userState.id === itemKey) {
              return (
                <div key={index} className="py-1 hover:bg-gray-200 cursor-pointer" role="none">
                  <span className="text-gray-700 flex px-4 py-2 text-sm items-center" role="menuitem" onClick={handleShowPrompt}>
                    <span className="material-icons-outlined text-gray-400 text-sm mr-3">{action.icon}</span>
                    <span>{action.text}</span>
                  </span>
                </div>
              );
            } else if (action.key !== "delete") {
              return (
                <div key={index} className="py-1 hover:bg-gray-200 cursor-pointer" role="none">
                  <span className="text-gray-700 flex px-4 py-2 text-sm items-center" role="menuitem" onClick={action.action}>
                    <span className="material-icons-outlined text-gray-400 text-sm mr-3">{action.icon}</span>
                    <span>{action.text}</span>
                  </span>
                </div>
              );
            }
          })}
        </div>
      </div>
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={deleteAction} />
    </div>
  );
}

export default Option;
