import React from "react";
import PropTypes from "prop-types";

function InterestCard({ interest, removeInterest }) {
  return (
    <div className="w-fit bg-white rounded-md shadow-md p-3">
      <div className="w-full flex gap-2">
        <span data-interest={interest.index} className="block text-success-800 cursor-pointer material-icons-outlined" onClick={removeInterest}>
          cancel
        </span>
        <p className="text-success-800 text-md capitalize">{interest.value}</p>
      </div>
    </div>
  );
}

InterestCard.propTypes = {
  interest: PropTypes.object.isRequired,
  removeInterest: PropTypes.func.isRequired,
};

export default InterestCard;
