import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function getReplies(accessToken, commentId, page, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/comment/reply/${commentId}?page=${page}&limit=${limit}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getReplies;
