/* eslint-disable no-console */
import React from "react";
import { UserContext } from "../context/UserContext";

function useUserContext() {
  const context = React.useContext(UserContext);
  return context;
}

export default useUserContext;
