/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getFeaturedVolunteerEvents(accessToken) {
  
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactevent/featured_volunteer`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getFeaturedVolunteerEvents;
