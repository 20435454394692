/* eslint-disable no-unused-vars */
import React from "react";
import { Outlet } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const ConversationContext = React.createContext({});

function ConversationProvider() {
  const [currentConversation, setCurrentConversation] = useLocalStorage("currentConversation", null);
  return (
    <ConversationContext.Provider value={{ currentConversation, setCurrentConversation }}>
      <Outlet />
    </ConversationContext.Provider>
  );
}

export { ConversationProvider, ConversationContext };
