/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { useParams } from "react-router-dom";
// import CreatePost from "../../../components/create_post/CreatePost";
import DefaultState from "../default_state/DefaultState";

// import Post from "../../../components/post/Post";
import Spinner from "../spinner/Spinner";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
// import getPost from "./getAllPost";
import getAllUserPosts from "./getAlluserPosts.js";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";
//This replaces CreatePost
import LifePerksPost from "../post/LifePerksPost";




// TODO
// change group ID and Group name to 
// DONE>> officeId and OfficeName
//Edit LifePerksPost to include socket


function LifePerksTimeline() {
  useScrollPage();

  // const { socket } = useOutletContext();
  const params = useParams();
  const { memberId } = params;
  const { member, userState } = useUserContext();
  const [posts, setPosts] = React.useState([]);
  const [fetchingPost, setFetchingPost] = React.useState(false);
  const rootRef = React.useRef(null);
  const page = React.useRef(0);
  const remaining = React.useRef(0);
  const [pagination, setPagination] = React.useState({ limit: 6, total: 0, page: 0, remaining: 0 });

  
  useTitle("Life Perks Live Wall");

  
  const fetchAllPost = async () => {
    try {
      setFetchingPost(true);
      const response = await getAllUserPosts(userState.accessToken, memberId, 0, 10);

      if (response.status === "success") {
        
        const { remaining: remainingResult, results } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setPosts(prevState => {
          return [...prevState, ...results];
        });
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };

  const reload = async () => {
    try {
      setFetchingPost(true);
      const response = await getAllUserPosts(userState.accessToken, memberId, 0, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        remaining.current = remainingResult;
        page.current = 0;
        setPosts([]);
        setPosts(results);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };

  const observer = React.useRef(
    new IntersectionObserver(
      entries => {
        const [first] = entries;
        if (first.isIntersecting) {
          fetchAllPost();
        }
      },
      { threshold: 1, root: rootRef.current }
    )
  );

  const [element, setElement] = React.useState(null);

  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  React.useEffect(() => {
    fetchAllPost(userState.accessToken, memberId, 0, 10);
  }, []);


  return (
    <div className="mt-5 w-full flex justify-center gap-4">
      <div className="w-full space-y-3">
        <div ref={rootRef} className="w-full space-y-3">
          {posts.map((post, index) => {
            // return <LifePerksPost key={index} post={post} reload={reload} socket={socket} groupId={group._id} groupName={group.name} creatorId={group.creator._id} />;
            return <LifePerksPost key={index} post={post} reload={reload} memberId={memberId} />;
          })}

          {posts.length <= 0 ? <DefaultState icon="post_add" heading="No Posts Found" description="All post will appear here" /> : null}

          {fetchingPost && <Spinner displayText="Fetching..." />}

          {remaining.current > 0 ? (
            <span ref={setElement} className="w-full flex justify-center text-gray-400">
              Loading...
            </span>
          ) : null}

          {posts.length && remaining.current <= 0 ? <span className="w-full flex justify-center text-gray-400">No more posts to load</span> : null}
        </div>
      </div>
    </div>

   
  );
}

export default LifePerksTimeline;