/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import { FaWindowClose } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { MdDashboardCustomize, MdAddBusiness, MdBusinessCenter, MdOutlineVolunteerActivism } from "react-icons/md";
import { TbHealthRecognition } from "react-icons/tb";
import { RiCalendarEventLine } from "react-icons/ri";

function HelpWidgets({ button, title, Explainer }) {
    return (

        <Popup trigger={button} modal nested position="right center">
            {close => (
                <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                &#8203;
                            </span>

                            <div className="relative max-h-md inline-block align-bottom bg-mild-50 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
                                <div className="w-full flex justify-between">
                                    <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">What is COhatch+?</h1>
                                    <button className="button" onClick={() => { close(); }} ><FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close" /> </button>
                                </div>

                                <Carousel
                                className="max-w-sm"
                                    showThumbs={true}
                                    centerSlidePercentage={100}
                                    showStatus={false}
                                    autoPlay={false}
                                    interval={4000}
                                    infiniteLoop={false}
                                    renderArrowNext={(clickHandler, hasNext) =>
                                        hasNext && (
                                            <button
                                                className="control-arrow control-next"
                                                onClick={clickHandler}
                                            >
                                                <span className="material-icons-outlined text-4xl">
                                                    arrow_forward_ios
                                                </span>
                                            </button>
                                        )
                                    }
                                    renderArrowPrev={(clickHandler, hasPrev) =>
                                        hasPrev && (
                                            <button
                                                className="control-arrow control-prev"
                                                onClick={clickHandler}
                                            >
                                                <span className="material-icons-outlined text-4xl">
                                                    arrow_back_ios
                                                </span>
                                            </button>
                                        )
                                    }
                                >
                                    <div className="max-w-[35rem] mx-auto">
                                        <div className="aspect-w-16 aspect-h-11">
                                            {/* <img className="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1562851529-c370841f6536?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" alt="Image Description" /> */}
                                        <MdDashboardCustomize className="w-full rounded-xl h-40 text-green-600" />
                                        </div>
                                        <div className="my-4">
                                            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-800 dark:group-hover:text-white">
                                                Dashboard
                                            </h3>
                                            <p className="mt-5 text-gray-600 dark:text-gray-600">
                                                Start here and see what’s the latest and greatest around the COhatch community. Post about events, ask questions, and find new ways to connect with other members.
                                            </p>
                                            <Link to="/dashboard"  onClick={() => { close(); }} className="text-md font-semibold leading-6 text-primary-400">Learn more <span aria-hidden="true">→</span></Link>
                                        </div>
                                    </div>
                                    <div className="max-w-[35rem] mx-auto">
                                        <div className="aspect-w-16 aspect-h-11">
                                            {/* <img className="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1562851529-c370841f6536?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" alt="Image Description" /> */}
                                        <MdAddBusiness className="w-full rounded-xl h-40 text-green-600" />
                                        </div>
                                        <div className="my-4">
                                            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-800 dark:group-hover:text-white">
                                                My Business Hub
                                            </h3>
                                            <p className="mt-5 text-gray-600 dark:text-gray-600">
                                                Add your business, apply for scholarships at COhatch, list discounts for your business for other members to use, and more.
                                            </p>
                                            <Link to="business_hub" className="text-md font-semibold leading-6 text-primary-400">Learn more <span aria-hidden="true">→</span></Link>
                                        </div>
                                    </div>
                                    <div className="max-w-[35rem] mx-auto">
                                        <div className="aspect-w-16 aspect-h-11">
                                            {/* <img className="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1562851529-c370841f6536?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" alt="Image Description" /> */}
                                        <MdBusinessCenter className="w-full rounded-xl h-40 text-green-600" />
                                        </div>
                                        <div className="my-4 mb-8">
                                            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-800 dark:group-hover:text-white">
                                                Businesses & Members
                                            </h3>
                                            <p className="mt-5 text-gray-600 dark:text-gray-600">
                                                Take a look inside what makes COhatch great: our members & their businesses! Find local businesses, connect with other members across all locations, and use local discounts for shopping, dining, and more.
                                            </p>
                                            <Link to="marketplace" className="text-md font-semibold leading-6 text-primary-400">Learn more <span aria-hidden="true">→</span></Link>
                                        </div>
                                    </div>
                                    <div className="max-w-[35rem] mx-auto">
                                        <div className="aspect-w-16 aspect-h-11">
                                            {/* <img className="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1562851529-c370841f6536?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" alt="Image Description" /> */}
                                        <TbHealthRecognition className="w-full rounded-xl h-40 text-green-600" />
                                        </div>
                                        <div className="my-6">
                                            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-800 dark:group-hover:text-white">
                                                Health Benefits
                                            </h3>
                                            <p className="mt-5 text-gray-600 dark:text-gray-600">
                                                Looking for health benefits for you, your family, or your team? We now offer group benefits at COhatch through CoreLife Benefits.
                                            </p>
                                            <Link to="health_and_wellness" className="text-md font-semibold leading-6 text-primary-400">Learn more <span aria-hidden="true">→</span></Link>
                                        </div>
                                    </div>
                                    <div className="max-w-[35rem] mx-auto">
                                        <div className="aspect-w-16 aspect-h-11">
                                            {/* <img className="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1562851529-c370841f6536?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" alt="Image Description" /> */}
                                        <RiCalendarEventLine className="w-full rounded-xl h-40 text-green-600" />
                                        </div>
                                        <div className="my-6">
                                            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-800 dark:group-hover:text-white">
                                                Events
                                            </h3>
                                            <p className="mt-5 text-gray-600 dark:text-gray-600">
                                                Create and find workshops and events near you. Become a part of the community by RSVPing and attending COhatch events.
                                            </p>
                                            <Link to="workshop" className="text-md font-semibold leading-6 text-primary-400">Learn more <span aria-hidden="true">→</span></Link>
                                        </div>
                                    </div>
                                    <div className="max-w-[35rem] mx-auto">
                                        <div className="aspect-w-16 aspect-h-11">
                                            {/* <img className="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1562851529-c370841f6536?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80" alt="Image Description" /> */}
                                        <MdOutlineVolunteerActivism className="w-full rounded-xl h-40 text-green-600" />
                                        </div>
                                        <div className="my-6">
                                            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-800 dark:group-hover:text-white">
                                                Volunteer
                                            </h3>
                                            <p className="mt-5 text-gray-600 dark:text-gray-600">
                                                Do more good with just a few clicks. Find local nonprofits, volunteering opportunities, and drives here. Track your impact throughout the year, too.
                                            </p>
                                            <Link to="volunteer" className="text-md font-semibold leading-6 text-primary-400">Learn more <span aria-hidden="true">→</span></Link>
                                        </div>
                                    </div>

                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Popup>



    );
}
export default React.forwardRef(HelpWidgets);