import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function deleteMember(accessToken, groupId, memberId, type, groupName) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/group_member/${groupId}/${memberId}/${type}/${groupName}`;

  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteMember;
