/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { MdLocalSee } from "react-icons/md";
import ButtonPrimary from "../button/ButtonPrimary";
import { generateClassName } from "../../services/util";
import { validateCouponName, validateCouponCode, validateCouponUse } from "../../services/validation";
import FileUploader from "../file_uploader/FileUploader";
import notify from "../../services/toast";
import useUserContext from "../../hooks/useUserContext";
import { COUPON_BG_TEMPLATE, PLANS } from "../../constant";
import { Link } from "react-router-dom";

const backgroundCriteria = { requiredSize: 5e6, requiredDimensions: { width: 250, height: 250 } };
function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

const   EditCouponModal = ({
  open,
  coupon,
  onClose,
  onSave,
}) => {
  const { userState,
    subscription: { product }, } = useUserContext();
  const [couponName, setCouponName] = useState(coupon.couponName || "");
  const [expirationDate, setExpirationDate] = useState(coupon.expiration ? coupon.expiration.substring(0, 10) : "");
  const [couponCode, setCouponCode] = useState(coupon.couponCode || "");
  const [description, setDescription] = useState(coupon.useAge || "");
  const [background, setBackground] = useState(coupon.background || null);

  const [validCouponName, setValidCouponName] = useState(false);
  const [validExpirationDate, setValidExpirationDate] = useState(false);
  const [validCouponCode, setValidCouponCode] = useState(false);
  const [validDescription, setValidDescription] = useState(false);
  const [checked, setChecked] = React.useState(null);

  useEffect(() => {
    const result = validateCouponName(couponName);
    setValidCouponName(result);
  }, [couponName]);

  useEffect(() => {
    const result = expirationDate.toString().length > 0;
    setValidExpirationDate(result);
  }, [expirationDate]);

  useEffect(() => {
    const result = validateCouponCode(couponCode);
    setValidCouponCode(result);
  }, [couponCode]);

  useEffect(() => {
    const result = validateCouponUse(description);
    setValidDescription(result);
  }, [description]);

  const handleSave = () => {
    if (couponName || couponCode || expirationDate || description) {
      if (!validCouponName || !validCouponCode || !validExpirationDate || !validDescription) {
        notify("One or more fields are invalid. Please review before submitting.", "error");
        return;
      }
    }

    onSave({
      couponName,
      couponCode,
      expiration: expirationDate,
      useAge: description,
      background
    });
  };

  const handleSelectTemplate = (event) => {
    const templateIndex = parseInt(event.target.value, 10);
    setChecked(templateIndex);
    const template = COUPON_BG_TEMPLATE[templateIndex];
    setBackground(template);
  };

  return (
    <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <h2 className="text-lg font-bold ">Edit Coupon</h2>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="coupon_name" className="block text-sm font-medium text-gray-700">
                  Coupon Name - <p className="text-sm text-black">4000 characters max</p>
                </label>
              </div>
              <div className="mt-1">
                <input
                  maxLength={30}
                  onChange={(event) => setCouponName(event.target.value)}
                  type="text"
                  name="coupon_name"
                  id="coupon_name"
                  value={couponName}
                  className={generateClassName(!validCouponName && couponName)}
                  placeholder="3 Months Free Burgers On Us"
                  aria-describedby="name-optional"
                />
              </div>
            </div>
            <div className="w-full mt-3">
              <div className="w-full flex justify-between">
                <label htmlFor="expiration_date" className="block text-sm font-medium text-gray-700">
                  Expiration Date
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={(event) => setExpirationDate(event.target.value)}
                  type="date"
                  name="expiration_date"
                  id="expiration_date"
                  value={expirationDate}
                  className={generateClassName(!validExpirationDate && expirationDate)}
                  placeholder="04/23/2022"
                  aria-describedby="date-optional"
                />
              </div>
            </div>
            <div className="w-full mt-3">
              <div className="w-full flex justify-between">
                <label htmlFor="coupon_code" className="block text-sm font-medium text-gray-700">
                  Coupon Code
                </label>
              </div>
              <div className="mt-1">
                <input
                  maxLength={15}
                  onChange={(event) => setCouponCode(event.target.value)}
                  type="text"
                  name="coupon_code"
                  id="coupone_code"
                  value={couponCode}
                  className={generateClassName(!validCouponCode && couponCode)}
                  placeholder="FREEBUG"
                  aria-describedby="code-optional"
                />
              </div>
            </div>
            <div className="w-full mt-3">
              <div className="w-full flex justify-between">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  How To Use This Coupon
                </label>
              </div>
              <div className="mt-1">
                <textarea
                  maxLength={50}
                  rows="2"
                  onChange={(event) => setDescription(event.target.value)}
                  value={description}
                  name="description"
                  id="description"
                  className={generateClassName(!validDescription && description)}
                ></textarea>
              </div>
            </div>
            <div className="w-full mt-3">
            <div className="w-full flex justify-between items-center">
            <label htmlFor="coupon_name" className="block text-sm font-medium text-gray-700">
              Add Coupon Background Image
            </label>
          </div>
            <div className="mt-3 flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
            {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
              <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                {background?.small.url ? <img src={background.small.url} className="w-full h-full" alt="background upload" /> : null}
                <FileUploader
                  fileType="image/*"
                  isMultiple={false}
                  validationCriteria={backgroundCriteria}
                  DisplayIcon={PhotoDisplay}
                  accessToken={userState.accessToken}
                  folder="coupon_background_images"
                  description="250 X 250 5MB Background Image"
                  numberOfFiles={1}
                  callback={result => setBackground(result)}
                />
              </div>
               {/* ) : (
                <Link to="/dashboard/settings/subscription" className="text-primary-400 text-bold text-lg underline">
                  Upgrade subscription to add custom coupon images
                </Link>
              )} */}
            </div>
            <div className="mt-3 w-full flex justify-between items-center">
              <label htmlFor="coupon_name" className="block text-sm font-medium text-gray-700">
                Select a template
              </label>
            </div>
            <div className="w-full sm:max-w-full flex gap-3 overflow-x-auto">
              {COUPON_BG_TEMPLATE.length
                ? COUPON_BG_TEMPLATE.map((template, index) => {
                    return (
                      <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                        <input
                          type="radio"
                          name="template"
                          value={index}
                          onChange={handleSelectTemplate}
                          className="sr-only"
                          aria-labelledby={`template-${index}`}
                          aria-describedby={`template-${index}`}
                        />
                        <div className="relative h-[200px] w-[200px]">
                          <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                        </div>
                        {checked !== null && index === checked ? (
                          <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : null}
                      </label>
                    );
                  })
                : null}
            </div>
            </div>


            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <div>
                <button
                  onClick={onClose}
                  type="button"
                  className="ml-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
              <div>
                <ButtonPrimary action={handleSave} loading={false} size="md" text="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCouponModal;
