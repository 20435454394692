/* eslint-disable no-console */
import React from "react";
import { STATUS } from "../../constant";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";

function RequiresSubscription() {
  const { userState, subscription } = useUserContext();
  const location = useLocation();

  //Must be logged in
  if (!userState?.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  //ACTIVE, REQUIRES_EMAIL_VERIFICATION send them to dashboard;
  else if ((userState?.status === STATUS.ACTIVE || userState?.status === STATUS.REQUIRES_EMAIL_VERIFICATION) && subscription.subscriptionStatus === "active") {
    return <Navigate to="/dashboard" replace />;
  }
  //SUBSCRIPTION_INACTIVE send to subscription info page
  else if (subscription?.subscriptionStatus && subscription?.subscriptionStatus !== "active") {
    return <Navigate to="/subscription_info" replace />;
  } else {
    return <Outlet />;
  }
}

export default RequiresSubscription;
