/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React, { useEffect, useState } from "react";
import useScrollPage from "../../../hooks/useScrollPage";
import MyImpactRsvpEvents from "./MyImpactRsvpEvents";
import notify from "../../../services/toast";
import Spinner from "../../../components/spinner/Spinner";
import { MAGIC_NUMBERS } from "../../../constant";
import useUserContext from "./../../../hooks/useUserContext";
import getMyRSVPs from "../../workshops/workshop_rsvp/getMyRSVPs";
import DefaultState from "../../../components/default_state/DefaultState";
import getImpactRSVPS from "./getImpactRSVPS";

function Attending() {
	const [fetching, setFetching] = useState(false);
	const [rsvps, setRsvps] = useState([]);
  
	useScrollPage();
	const {
		userState: { accessToken },
	} = useUserContext();

  const getRSVPs = async () => {
    try {
      setFetching(true);
      const response = await getImpactRSVPS(accessToken);
      if (response.status === "success") {
        setRsvps(response.data);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const reload = () => {
    getRSVPs();
  };

  useEffect(() => {
  	getRSVPs();
  }, []);

    return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8">
          {fetching ? (
            <div className="text-center w-full relative">
              <Spinner displayText="Fetching..." />
            </div>
          ) : rsvps.length ? (
            rsvps.map(item => (<MyImpactRsvpEvents data={item} key={item._id} reload={reload} />))
          ) : (
            <DefaultState icon="block" heading="No Events" description="You have not RSVP'd to any events." />
          )}
        </div>
      </div>
    </div>





);
}

export default Attending;


