/* eslint-disable no-console */ 
import makeAPIRequest from "../../../../services/makeRequest";
import config from "../../../../config";

async function getCommunityResourcesListings(authToken, page, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/listing/community_listings?page=${page}&limit=${limit}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getCommunityResourcesListings;