/* eslint-disable no-console */
import React from "react";
import { NotificationContext } from "../context/NotificationContext";

function useNotificationContext() {
  const context = React.useContext(NotificationContext);
  return context;
}

export default useNotificationContext;
