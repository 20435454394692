/* eslint-disable capitalized-comments */
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";


//payload has the following fields
// officeId: officeId,
// postText: postText,
// postMedia: postMedia,
// location: location,
// taggedMembers: taggedMembers

async function lifePerksHandleCreatePost(accessToken, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/lifeperks_post`;

  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default lifePerksHandleCreatePost;
