import React from "react";
import ButtonPrimary from "../button/ButtonPrimary";
import createSubscription from "./manage";
import useUserContext from "../../hooks/useUserContext";

function UpgradeSubscription() {
  const [subscribing, setSubscribing] = React.useState(false);
  const { userState } = useUserContext();

  const handleLocalSubscription = async () => {
    const payload = {
      firstName: userState.firstName,
      lastName: userState.lastName,
      emailAddress: userState.emailAddress,
      memberShip: "BASIC",
    };

    await createSubscription(payload, setSubscribing, userState.accessToken);
  };

  const handlePremiumSubscription = async () => {
    const payload = {
      firstName: userState.firstName,
      lastName: userState.lastName,
      emailAddress: userState.emailAddress,
      memberShip: "PREMIUM",
    };

    await createSubscription(payload, setSubscribing, userState.accessToken);
  };
  return (
    <div className="max-w-7xl mt-10">
      <div className="w-full flex flex-col">
        <div className="w-full">
          <h1 className="text-lg text-success-800 font-bold">Upgrade Your Subscription.</h1>
          <div className="w-full flex gap-3 bg-white rounded-md shadow-md p-3 mt-2">
            <div className="w-full text-left p-3">
              <h1 className="text-md text-success-800 font-bold">Local Discount Club</h1>
              <div className="mt-5 w-fit">
                <ButtonPrimary text={subscribing ? "Please wait..." : "Upgrade to Local Discount Club"} size="md" action={handleLocalSubscription} loading={subscribing ? true : false} />
                <p className="mt-2 text-sm text-gray-400">You will be temporarily redirected to stripe to finalize your subscription.</p>
              </div>
            </div>
            <div className="w-full text-left p-3">
              <h1 className="text-md text-success-800 font-bold">Premium</h1>
              <div className="mt-5 w-fit">
                <ButtonPrimary text={subscribing ? "Please wait..." : "Upgrade to Premium"} size="md" action={handlePremiumSubscription} loading={subscribing ? true : false} />
                <p className="mt-2 text-sm text-gray-400">You will be temporarily redirected to stripe to finalize your subscription.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeSubscription;
