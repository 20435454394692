/* eslint-disable no-console */
import makeAPIRequest from "../services/makeRequest";
import useUserContext from "./useUserContext";
import config from "../config";

function useRefreshToken() {
  const { setUserState, setSubscription, setPrivacy, setEmailPreference } = useUserContext();

  const refresh = async () => {
    const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/refresh_token`;
    const response = await makeAPIRequest(API_ENDPOINT, "GET");
    if (response.ok) {
      const jsonResponse = await response.json();
      const { data, subscription, privacy, emailPreference } = jsonResponse;
      setUserState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
      setSubscription((prevState) => {
        return {
          ...prevState,
          ...subscription,
        };
      });

      setPrivacy((prevState) => {
        return {
          ...prevState,
          ...privacy,
        };
      });

      setEmailPreference((prevState) => {
        return {
          ...prevState,
          ...emailPreference,
        };
      });
    }
  };
  return refresh;
}

export default useRefreshToken;
