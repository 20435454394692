import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function handleDeleteAccount(authToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/user`;
  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default handleDeleteAccount;
