/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React from "react";
import { Fragment, useState } from "react";
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { FaEllipsisH } from "react-icons/fa";
import { Menu, Transition } from "@headlessui/react";
import WorkshopForm from "../workshop_form/WorkshopForm";
import createEvent from "./createEvent";
import { MAGIC_NUMBERS, PLANS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import { useNavigate } from "react-router-dom";
import { showPrompt } from "../../services/util";
import Prompt from "../../components/prompt/Prompt";

const months = [{
  name: "January",
  value: 1,
}, {
  name: "February",
  value: 2,
}, {
  name: "March",
  value: 3,
}, {
  name: "April",
  value: 4,
}, {
  name: "May",
  value: 5,
}, {
  name: "June",
  value: 6,
}, {
  name: "July",
  value: 7,
}, {
  name: "August",
  value: 8,
}, {
  name: "September",
  value: 9,
}, {
  name: "October",
  value: 10,
}, {
  name: "November",
  value: 11,
}, {
  name: "December",
  value: 12,
}];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CalendarNav({
  setViewMode,
  viewMode,
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear,
  selectedWeek,
  setSelectedWeek,
  selectedDay,
  setSelectedDay,
  onReload,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [event, setEvent] = useState({
    eventName: "",
    eventDescription: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    eventCategory: "",
    eventLocation: {
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
    },
    eventCOhatchMarket: "",
    eventCOhatchLocation: "",
    eventCOhatchMeetingRoom: "",
    eventImage: null,
    eventOwnerImage: null,
    eventOwnerFirstName: "",
    eventOwnerLastName: "",
    aboutOrganization: "",
  });
  const {
    userState: { accessToken },
    subscription: { product },
  } = useUserContext();
  const navigate = useNavigate();
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });
  const promptRef = React.useRef(null);

  const handleShowPrompt = () => {
    showPrompt(
      "Upgrade Your Subscription",
      "This feature is only accessible to Premium or COhatch Member level subscriptions. To upgrade or manage your subscription, click the button below to access this feature.",
      promptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const handlePrev = () => {
    if (viewMode === 1) {
      setSelectedMonth((selectedMonth + 10) % 12 + 1);
    }
    // if (viewMode === 2) {
    //   const dt = new Date(selectedWeek);
    //   dt.setDate(dt.getDate() - 7);
    //   setSelectedWeek(dt);
    // }
    // if (viewMode === 1) {
    //   const dt = new Date(selectedDay);
    //   dt.setDate(dt.getDate() - 1);
    //   setSelectedDay(dt);
    // }
  };

  const handleNext = () => {
    if (viewMode === 1) {
      setSelectedMonth((selectedMonth) % 12 + 1);
    }
    // if (viewMode === 2) {
    //   const dt = new Date(selectedWeek);
    //   dt.setDate(dt.getDate() + 7);
    //   setSelectedWeek(dt);
    // }
    // if (viewMode === 1) {
    //   const dt = new Date(selectedDay);
    //   dt.setDate(dt.getDate() + 1);
    //   setSelectedDay(dt);
    // }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const dt = new Date(parseInt(event.eventDate.substring(0, 4), 10), parseInt(event.eventDate.substring(5, 7), 10) - 1, parseInt(event.eventDate.substring(8, 10), 10), parseInt(event.startTime.substring(0, 2), 10), parseInt(event.endTime.substring(3, 5), 10), 0);
      const response = await createEvent(accessToken, {
        ...event,
        eventDate: dt,
      });
      if (response.status === "success") {
        notify("Your event was successfully submitted.", "info");
        onReload();
      } else {
        setSubmitting(false);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  return (
    <>
    <header className="flex items-center justify-between py-4 px-6 lg:flex-none">
      <h1 className="text-lg font-semibold text-gray-900">
        
        {viewMode === 1 && <time dateTime="2022-01">{months[selectedMonth - 1].name} {selectedYear}</time>}
        {/* {viewMode === 2 && <time dateTime="2022-01">{months[selectedWeek.getMonth()].name} {selectedWeek.getFullYear()}</time>}
        {viewMode === 1 && <time dateTime="2022-01">{months[selectedDay.getMonth()].name} {selectedDay.getDate()}, {selectedDay.getFullYear()}</time>} */}
        {viewMode === 0 }

      </h1>
      <div className="flex items-center">
        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
          {viewMode === 2 && <Menu as="div" className="relative mr-4">
            <Menu.Button
              type="button"
              className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              {selectedYear}
              <FiChevronDown className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {[2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025].map(year => <Menu.Item key={year}>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() => setSelectedYear(year)}
                      >
                        {year}
                      </a>
                    )}
                  </Menu.Item>)}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>}
          
          <button
            type="button"
            onClick={handlePrev}
            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">Previous month</span>
            <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
          >
            {viewMode === 0}
            {/* {viewMode === 1 && "Today"}
            {viewMode === 2 && "Current week"} */}
            {viewMode === 1 && months[selectedMonth - 1].name}
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            type="button"
            onClick={handleNext}
            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">Next month</span>
            <FiChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <Menu as="div" className="relative">
            <Menu.Button
              type="button"
              className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              {["List", "Month"][viewMode]} View
              <FiChevronDown className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() => setViewMode(0)}
                      >
                        List view
                      </a>
                    )}
                  </Menu.Item>
                  {/* <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() => setViewMode(1)}
                      >
                        Day view
                      </a>
                    )}
                  </Menu.Item> */}
                  {/* <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() => setViewMode(2)}
                      >
                        Week view
                      </a>
                    )}
                  </Menu.Item> */}
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() => setViewMode(1)}
                      >
                        Month view
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <div className="ml-6 h-6 w-px bg-gray-300" />
            {/* {["PREMIUM", "COHATCH", "BASIC", "LOCAL"].includes(PLANS[product]) ? ( */}
              <WorkshopForm 
                button={<button
                type="button"
                className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                >
                  Add Event
                </button>}
                title="Workshops Form"
                event={event}
                setEvent={setEvent}
                onSubmit={handleSubmit}
                /> 
            {/* ) : 
              <button
                type="button"
                className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                onClick={handleShowPrompt}
              >
                Add Event
              </button>
            <Modal>
              <div className="w-full flex-col">
                <Link to="/dashboard/settings/subscription" className="text-primary-400 font-bold text-lg underline">
                  Upgrade your subscription to add a badge
                </Link>
              </div>
            </Modal>
            }  */}
        </div>
        <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={() => navigate("/dashboard/settings/subscription")} />

        <Menu as="div" className="relative ml-6 md:hidden">
          <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
            <FaEllipsisH className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Create event
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Go to today
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                {/* <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                        onClick={() => setViewMode(1)}
                    >
                      Day view
                    </a>
                  )}
                </Menu.Item> */}
                {/* <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                        onClick={() => setViewMode(2)}
                    >
                      Week view
                    </a>
                  )}
                </Menu.Item> */}
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                        onClick={() => setViewMode(1)}
                    >
                      Month view
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
    <div className="relative pt-2">
    <div className="absolute pt-2 inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-start">
      {/* <span className="ml-3 text-4xl font-bold font-bebas text-primary-400 sm:leading-9 sm:truncate">Book Free Life Events</span> */}
      <span className="bg-white pl-6 pr-3 text-4xl text-primary-400 font-bebas font-bold">
        UPCOMING EVENTS
      </span>
    </div>
  </div>
  </>
  );
}
