import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../components/logo/Logo";
import config from "../../../config";
import {MdStar} from "react-icons/md";
import ReviewList from "../../../components/review/ReviewList";
import RequestBooking from "../../../components/request_booking/RequestBooking";
import { Carousel } from "react-responsive-carousel";

const sightseeings = [
    { name: "Disney Parks (Animal Kingdom, Epcot, Disney Waterparks, etc.)", },
    { name: "Universal Studios", },
    { name: "SeaWorld", },
    { name: "Golf courses", },
  ];
  const restaurants = [
    { name: "Ohana", },
    { name: "Topolino’s", },
    { name: "Epcot World Showcase", },
    { name: "Chef Mickey’s", },
    { name: "A million other choices, google it! :)", },
  ];
  const shopping = [
    { name: "Disney Springs", },
  ];

  const info = [
    { week: "TBD", title: "Animal Kingdom", room: "TBD", price: "TBD" },
    { week: "TBD", title: "Grand Floridian", room: "TBD", price: "TBD" },
    { week: "TBD", title: "Polynesian Villas", room: "TBD", price: "TBD" },
    { week: "TBD", title: "Boardwalk Resort", room: "TBD", price: "TBD" },
    { week: "TBD", title: "Saratoga Springs", room: "TBD", price: "TBD" },
    { week: "TBD", title: "Bay Lake", room: "TBD", price: "TBD" },
    { week: "TBD", title: "Fort Wilderness", room: "TBD", price: "TBD" },
  ];


const product = {
    name: "Disney Vacation Club",
    href: "#",
    minPrice: "1000+",
    propertyDiscount: "5",
    _id: config["DISNEY_PROPERTY_ID"],
    userId: config["DISNEY_USER_ID"],
    //C price: "$400 - $1000/night",
    propertyImages: [
      {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2FIMG_5914.jpg",
          alt: "Family in Disney World.",
        },
      }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Fboulder-ridge-landscape-16x9.png",
          alt: "Disney Boulder Ridge.",
        },
      }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Fbay-lake-tower-at-contemporary-gallery05.png",
          alt: "Disney Bay Lake.",
        },
      }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2FIMG_1128.jpg",
          alt: "Family in Disney Star Wars attraction.",
        },
      }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Fpolynesian-villa-resort-exterior-16x9.png",
          alt: "Disney Polynesian Villa.",
        }, 
    },  {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Faulani.webp",
          alt: "Aulani",
        }, 
    }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Fdisney%20castle%202.png",
          alt: "Disney Castle",
        }, 
    }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Fanimal%20kingdom.jpeg",
          alt: "Animal Kingdom.",
        }, 
    }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Froom-zb-g10.png",
          alt: "Room 2",
        }, 
    }, {
        small: {
          url: "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2Froom-4a-g01.png",
          alt: "Room 1",
        }, 
    },
    ],
  };
  const reviews = { average: 0, totalCount: 0 };
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


export default function VavationGallery() {
    return (
<>
    {/* Top Nav Bar with Logo and Arrow */}
    <div className="sticky top-0 z-10 w-full bg-white shadow-md flex justify-start p-5">
        <div className="w-full flex justify-between items-center gap-3 text-primary-400 hover:text-primary-600 transition-all duration-500">
            <Logo color="green" />
            <div className="flex justify-center gap-3">
                <Link to="/dashboard/vacation_homes">All Vacation Homes</Link>
                <span className="material-icons-outlined">arrow_forward</span>
            </div>
        </div>
    </div>

    {/* Gallery Space     */}
    <section className="overflow-hidden text-gray-700">
    <Carousel
      showThumbs={false}
      centerSlidePercentage={25}
      centerMode={true}
      showStatus={false}
      renderArrowNext={(clickHandler, hasNext) =>
        hasNext && (
          <button className="control-arrow control-next" onClick={clickHandler}>
            <span className="material-icons-outlined text-4xl">arrow_forward_ios</span>
          </button>
        )
      }
      renderArrowPrev={(clickHandler, hasPrev) =>
        hasPrev && (
          <button className="control-arrow control-prev" onClick={clickHandler}>
            <span className="material-icons-outlined text-4xl">arrow_back_ios</span>
          </button>
        )
      }>
      {product.propertyImages.map((image, index) => {
        return (
          <div key={index}>
            <img src={image.small.url} alt="default cover image" className="w-full h-96 object-cover" />
          </div>
        );
      })}
    </Carousel>
  </section>

    {/* Pricing, Title, and Details */}
    <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
        <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            {/* //House Header */}
            <div className="bg-white mb-4">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 text-primary-400">
                    <div className="flex w-full flex-wrap">
                        <h1 className="text-3xl font-bold tracking-normal text-primary-400 sm:text-4xl">{product.name}</h1>
                    </div>
                {/*<div className="mt-4 space-y-6">
                    <p className="text-base text-gray-500">{property.propertyLocation?.streetAddress} {property.propertyLocation?.city}, {property.propertyLocation?.state} {property.propertyLocation?.zip}</p>
                </div>*/}
            <div className="flex items-center mt-2">
              <p className="text-lg text-gray-900 sm:text-xl">${product.minPrice}</p>
              <div className="ml-4 border-l border-gray-300 pl-4">
                <h2 className="sr-only">Reviews</h2>
                <div className="flex items-center">
                  <div>
                    
                    <div className="flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <MdStar
                            key={rating}
                            className={classNames(
                            reviews.average > rating ? "text-yellow-400" : "text-gray-300",
                            "h-5 w-5 flex-shrink-0"
                            )}
                            aria-hidden="true"
                        />
                     ))}
                    </div>
                    <p className="sr-only">{reviews.average} out of 5 stars</p>
                  </div>
                  <p className="ml-2 text-sm text-gray-500">{reviews.totalCount} reviews</p>
                </div>
              </div>
            </div>

            {/*<div className="flex items-center mt-2">
              <p className="mt-1 text-sm text-gray-500">99 Guests</p>
              <div className="ml-4 border-l border-gray-300 pl-4">
                <div className="flex items-center">
                  <p className="mt-1 text-sm text-gray-500">99 Bedrooms</p>
                </div>
              </div>
              <div className="ml-4 border-l border-gray-300 pl-4">
                <div className="flex items-center">
                  <p className="mt-1 text-sm text-gray-500">99 Beds</p>
                </div>
              </div>
              <div className="ml-4 border-l border-gray-300 pl-4">
                <div className="flex items-center">
                  <p className="mt-1 text-sm text-gray-500">99 Bathrooms</p>
                </div>
              </div>
                            </div>*/}
        </div>
    </div>
    
            {/* <div className="text-2xlfont-extrabold	font-bold tracking-tight text-gray-900 sm:text-3xl">
                <div className="lg:max-w-lg lg:self-end">
                    <div className="mt-4">
                        <h1 className="text-3xlfont-extrabold	font-bold tracking-tight text-gray-900 sm:text-4xl">{product.name}</h1>
                    </div>
                    <section aria-labelledby="information-heading" className="mt-4">
                        <div className="flex items-center">
                            <p className="text-lg text-gray-900 sm:text-xl">{product.price}</p>
                            <div className="ml-4 border-l border-gray-300 pl-4">
                                <h2 className="sr-only">Reviews</h2>
                                <div className="flex items-center">
                                    <div>
                                        <div className="flex items-center">
                                            {[0, 1, 2, 3, 4].map((rating) => (
                                                <MdStar
                                                    key={rating}
                                                    className={classNames(
                                                    reviews.average > rating ? "text-yellow-400" : "text-gray-300",
                                                    "h-5 w-5 flex-shrink-0"
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            ))}
                                        </div>
                                        <p className="sr-only">{reviews.average} out of 5 stars</p>
                                    </div>
                                    <p className="ml-2 text-sm text-gray-500">{reviews.totalCount} reviews</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> */}

            {/* About and Description Section >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
            <div className="mt-6">
                <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
                    <h1 className="text-xl text-success-800 font-extrabold font-bold m-2">About Disney Vacation Club</h1>
                    <hr />
                    <p className="text-md text-gray-600 font-medium p-2">We admit, we became Disney-holics after our first family trip in 2012. I still look at those pictures of my little girls dressed up as princesses and sitting on my shoulders watching the fireworks. Man how times flies! We joined the Disney Vacation Club right after!</p>

                    <p className="text-md text-gray-600 font-medium p-2">DVC a uniquely designed Timeshare in which you buy-in and get a certain amount of points every year. These points can be used on deluxe room accommodations at their best, on park Deluxe Villa resorts.</p>

                    <p className="text-md text-gray-600 font-medium p-2">The points needed for your stay vary by room type, view, time of year, etc. You can pick from studios that sleep 4-5, single villas with full kitchens and a separate master (sleeps 4-5), 2 bedroom villas (sleeps 8-9), and even larger 3 bedroom villas.</p>

                    <p className="text-md text-gray-600 font-medium p-2">We love it so much that we want to share our experience with other COhatch families. Hopefully we can make it easier for you to go (and learn how to navigate the complex world of Disney). I really enjoy seeing the enjoyment on kids (and parents) faces when they get to meet their favorite character or see the fireworks over the castle for the first time.</p>

                    <p className="text-md text-gray-600 font-medium p-2">We have a certain (very limited) amount of points we can rent out to our friends. This saves you 20-50% off Disney rooms. YOU really need to try to book 6-8 months out if you want a chance! </p>

                    <p className="text-md text-gray-600 font-medium p-2">You can see all the options here and check the LIST price.</p>

                    <a href="https://disneyworld.disney.go.com/resorts/#/deluxe-villa" 
                    target="_blank" rel="noreferrer" 
                    className="text-md text-primary-400 font-medium p-2"> https://disneyworld.disney.go.com/resorts/#/deluxe-villa</a>

                    <p className="text-md text-gray-600 font-medium p-2">If for some reason we can’t help, or run out of points to rent, you can always rent more from larger brokers like this one (we rent for cheaper because it is COhatch families).</p>

                    <a href="https://dvcrequest.com/dvc-guests/cost-calculator" 
                    target="_blank" rel="noreferrer"
                    className="text-md text-primary-400 font-medium p-2">https://dvcrequest.com/dvc-guests/cost-calculator</a>
                    
                    <p className="text-md text-gray-600 font-medium p-2">Have a great vacation! Just send us a picture!</p>
                </div>
            </div>

                {/*<div className="mt-6">
                <HouseRules property={property} />
            </div> */}

            <div className="mt-6">
                <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
                    <h1 className="text-xl text-success-800 font-extrabold font-bold m-2">Available Disney Vacation Weeks</h1>
                    <hr />
                    <div className="mt-8 flex flex-col">
        
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      Week Available
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Name of the Resort
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Room Details
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Price
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {info.map((person) => (
                    <tr key={person.week}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {person.week}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.title}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.room}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.price}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <div className="text-primary-500">
                        COMING SOON<span className="sr-only">, {person.name}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
                </div>
            </div>
        </div>  
                
        <div className="sm:w-[110%] h-full sticky top-[90px] space-y-2 p-3">
            <RequestBooking property={product} isDisney={true} />
        </div>
          
    </div>

    <div className="container px-8 py-0 mx-auto flex flex-wrap">
        <div className="bg-white mt-0 w-full">
            <section className="overflow-hidden bg-white">
                <div className="relative mx-auto max-w-7xl px-6 pt-20 pb-12 lg:px-8 lg:py-20">
                    <div className="relative lg:flex lg:items-center">
                        <div className="hidden lg:block lg:flex-shrink-0">
                            <img
                                className="h-64 w-64 rounded-full xl:h-80 xl:w-80 object-cover"
                                src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/disney_temp%2FIMG_6116.jpg"
                                alt=""
                            />
                        </div>

                        <div className="relative lg:ml-10">
                            <blockquote className="relative">
                                <div className="ml-4 lg:ml-0">
                                    <div className="tspace-y-1 text-2xl font-medium leading-6 text-primary-400">Matt Davis</div>
                                    <div className="text-base font-medium text-success-800">CEO, COhatch</div>
                                </div>
                                <div className="text-xl font-medium leading-9 text-gray-900">
                                    <p>
                                    The Davis Family: Matt, Jodie, Claire, Izzy and Luke are Disney-holics and have been to the parks over 20 times. We have also been to Disney’s resorts in Vero Beach, Hawaii, and a Disney Cruise. We love sharing Disney with others and watching the joy on kids and parents faces. It is fun to be a kid... with your kids
                                    </p>
                                </div>
              
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
                
    <div className="mt-4 container px-8 py-2 mx-auto flex flex-wrap">
        <div className="bg-white mt-4 w-full">
            <div className="mx-auto max-w-full py-6 px-4 sm:px-6 text-primary-400">
                <div className="space-y-5 sm:space-y-4">
                    <h2 className="text-3xlfont-extrabold	font-bold font-bebas tracking-wide sm:text-4xl">Best Disney Rides + Recommendations</h2>
                </div>
                <section className="text-gray-600 body-font">
                    <div className="container px-5 py-6 mx-auto">
                        <div className="flex flex-wrap -m-4">
                            <div className="p-4 lg:w-1/3">
                                <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-4 rounded-lg overflow-hidden text-center relative">
                                    <h1 className="title-font sm:text-2xl text-xl font-medium text-success-800 mb-3">Sightseeing</h1>
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {sightseeings.map((sightseeing) => (
                                            <li key={sightseeing.namel}>
                                                <div className="flex items-center px-4 py-4 sm:px-6">
                                                    <div className="flex min-w-auto flex-1 items-center">
                                                        <div>
                                                            <p className=" text-sm text-left font-medium text-black">{sightseeing.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
    
                            <div className="p-4 lg:w-1/3">
                                <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-4 rounded-lg overflow-hidden text-center relative">
                                    <h1 className="title-font sm:text-2xl text-xl font-medium text-success-800 mb-3">Restaurants</h1>
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {restaurants.map((restaurant) => (
                                            <li key={restaurant.namel}>
                                                <div className="flex items-center px-4 py-4 sm:px-6">
                                                    <div className="flex min-w-auto flex-1 items-center">
                                                        <div>
                                                            <p className="text-sm font-medium text-black">{restaurant.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
      
                            <div className="p-4 lg:w-1/3">
                                <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-4 rounded-lg overflow-hidden text-center relative">
                                    <h1 className="title-font sm:text-2xl text-xl font-medium text-success-800 mb-3">Shopping</h1>
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {shopping.map((shopping) => (
                                            <li key={shopping.name}>
                                                <div className="flex items-center px-4 py-4 sm:px-6">
                                                    <div className="flex min-w-auto flex-1 items-center">
                                                        <div>
                                                            <p className="text-sm font-medium text-black">{shopping.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <div className="mt-4 container px-8 py-2 mx-auto flex flex-wrap">
        <div className="bg-white mt-4 w-full">
          <div className="mx-auto max-w-full py-6 px-4 sm:px-6 text-primary-400">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-bold font-bebas tracking-wide sm:text-4xl">Reviews</h2>
            </div>
            <hr />
            <div className="w-full p-2">
              <ReviewList
                userId={product.userId}
                target="property"
                targetId={product._id}
                targetObject={product}
                targetObjectName={product.name}
                imageAllowed={true}
              />
            </div>
          </div>
        </div>
    </div>
</>
);
}



