/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import Option from "../option/Option";
import Rating from "../rating/Rating";
import { computeReviewSummary } from "../../services/util";
import useUserContext from "../../hooks/useUserContext";
import { PLANS } from "../../constant";

function ListingCard({ listing, deleteListing, editListing }) {
  const {
    subscription: { product },
  } = useUserContext();
  const { avgRating, totalReviews } = computeReviewSummary(listing?.reviews || []);
  const coverPhoto = listing?.businessPhotos?.coverPhoto?.small ? listing.businessPhotos.coverPhoto.small.url : null;

  const returnActions = () => {
    return [
      { text: "Delete", icon: "delete", key: "delete", action: () => deleteListing(listing._id) },
      { text: "Edit", icon: "edit", key: "edit", action: () => editListing(listing) },
    ];
  };

  const action = React.useMemo(() => returnActions(), []);
  return (
    <div className="w-full max-w-xs shadow-xl rounded bg-white">
      <div className="w-full">
        <div className="w-full bg-white p-2 flex justify-end">
          {deleteListing && editListing ? <Option actions={action} itemKey={listing.userId} deleteAction={() => deleteListing(listing._id)} /> : null}
        </div>
        <Link to={`/dashboard/marketplace/listing/${listing._id}`}>
          <div
            style={{
              backgroundImage: `url(${coverPhoto ? coverPhoto : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_1.png"})`,
            }}
            className="w-full h-[200px] bg-center bg-no-repeat bg-cover"></div>
          <div className="w-full bg-white p-5 space-y-3">
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">business</span>
              <h1 className="text-xl text-success-800 font-bold capitalize truncate">{listing?.businessName}</h1>
            </div>
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">location_on</span>
              <h1 className="text-md text-success-800 capitalize truncate">{listing?.businessAddress?.address}</h1>
            </div>
            <div className="w-full flex items-center gap-2">
              <span className="material-icons-outlined text-success-800">person</span>
              <h1 className="text-md text-success-800 capitalize trunc">
                {listing?.businessOwner?.firstName} {listing?.businessOwner?.lastName}
              </h1>
            </div>
          </div>
          {listing?.reviews ? (
            <div className="w-full flex items-center justify-between p-3">
              <Rating rating={avgRating} reviews={totalReviews} />
            </div>
          ) : null}
        </Link>
      </div>
    </div>
  );
}

export default ListingCard;
