import React from "react";
import PropTypes from "prop-types";
import { getValueFromArrayOfObject } from "../../../../services/util";
import { BADGES } from "../../../../constant";

function ListingBadges({ listingBadges }) {
  return (
    <>
      {listingBadges?.length ? (
        <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
          <h1 className="text-xl text-success-800 font-bold m-2">Badges</h1>
          <hr />
          <div className="w-full flex gap-3 overflow-x-auto p-2">
            {listingBadges.map((badge, index) => {
              return (
                <img
                  key={index}
                  src={getValueFromArrayOfObject(BADGES, { badgeName: badge }, "badgeName", "badge")}
                  alt={getValueFromArrayOfObject(BADGES, { badgeName: badge }, "badgeName", "badgeName")}
                  className="w-[100] h-[100px] rounded-full"
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

ListingBadges.propTypes = {
  listingBadges: PropTypes.array,
};

export default ListingBadges;
