/* eslint-disable no-magic-numbers */
import React from "react";
import Alert from "../../../components/alert/Alert";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import useTogglePassword from "./../../../hooks/useTogglePassword";
import PasswordRequirement from "../../../components/password_requirement/PasswordRequirement";
import { generateClassName, showPrompt } from "./../../../services/util";
import { validatePassword, validateEmail } from "../../../services/validation";
import Prompt from "../../../components/prompt/Prompt";
import { useNavigate } from "react-router-dom";
import handleChangePassword from "./handleChangePassword";
import handleChangeEmail from "./handleChangeEmail";
import handleDeleteAccount from "./handleDeleteAccount";
import useUserContext from "./../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function Account() {
  useScrollPage();
  useTitle("COhatch+ Account");
  
  const { userState, setUserState, setSubscription } = useUserContext();
  const navigate = useNavigate();
  const passwordRef = React.useRef(null);
  const passwordVisibilityRef = React.useRef(null);

  //Password Change;
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [validNewPassword, setValidNewPassword] = React.useState(false);
  const [changingPassword, setChangingPassword] = React.useState(false);

  React.useEffect(() => {
    const result = validatePassword(newPassword);
    setValidNewPassword(result);
  }, [newPassword]);

  //Email Address
  const [emailAddress, setEmailAddress] = React.useState("");
  const [validEmailAddress, setValidEmailAddress] = React.useState(false);
  const [changingEmailAddress, setChangingEmailAddress] = React.useState(false);

  const [togglePasswordVisibility] = useTogglePassword(passwordRef, passwordVisibilityRef);
  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });

  const [deletingAccount, setDeletingAccount] = React.useState(false);

  React.useEffect(() => {
    const result = validateEmail(emailAddress);
    setValidEmailAddress(result);
  }, [emailAddress]);

  const changePassword = async (event) => {
    event.preventDefault();
    if (!validNewPassword || !oldPassword) {
      notify("One or more fields are invalid. Check and try again.", "error");
      return;
    }

    const payload = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    try {
      setChangingPassword(true);
      const response = await handleChangePassword(payload, userState.accessToken);
      if (response.status === "success") {
        setOldPassword("");
        setNewPassword("");
        notify("Your password update was successful. You will logged out in 5 seconds to re-authenticate.", "info");
        setTimeout(() => {
          setUserState({});
          setSubscription({});
          navigate("/login");
        }, 5000);
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setChangingPassword(false);
    }
  };

  const changeEmail = async (event) => {
    event.preventDefault();
    if (!validEmailAddress) {
      notify("One or more fields are invalid. Check and try again.", "error");
      return;
    }

    const payload = {
      emailAddress: emailAddress,
    };

    try {
      setChangingEmailAddress(true);
      const response = await handleChangeEmail(payload, userState.accessToken);
      if (response.status === "success") {
        setEmailAddress("");
        notify("Your email address update was successful. Please check your inbox for a verification email. You will be logged out in 5 seconds.", "info");
        setTimeout(() => {
          setUserState({});
          setSubscription({});
          navigate("/login");
        }, 5000);
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setChangingEmailAddress(false);
    }
  };

  const handleShowPrompt = () => {
    showPrompt(
      "Delete Account",
      "Are you sure you want to delete your account? Deleting your account is an irreversible action. You will not be able to recover any data after deletion. Your account will be deleted completely. You won't be able to recover any data.",
      promptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const deleteAccount = async () => {
    try {
      setDeletingAccount(true);
      const response = await handleDeleteAccount(userState.accessToken);
      if (response.status === "success") {
        notify("Your delete account request was successful. You should get an email confirming this request. You should be automatically logged out after 5 seconds.", "info");
        setTimeout(() => {
          setUserState({});
          setSubscription({});
          navigate("/");
        }, 5000);
      } else {
        let displayMessage = "";
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setDeletingAccount(false);
    }
  };
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
        <form onSubmit={changePassword} className="w-full space-y-3 p-4">
          <h1 className="text-lg text-success-800 font-semibold">Change Your Password</h1>
          <Alert
            color="yellow"
            heading="Changing your password"
            body={[
              "Changing your password will require you to log out and log back in.",
              "The new password will be active immediately after resetting your password.",
              "Please ensure you don't have any unsaved changes before changing your password.",
            ]}
          />

          <div>
            <label htmlFor="old_password" className="block text-sm font-medium text-gray-700">
              Old Password
            </label>
            <div className="mt-1">
              <input
                onChange={(event) => setOldPassword(event.target.value)}
                type="password"
                name="old_password"
                id="old_password"
                value={oldPassword}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                placeholder="Old password"
                autoComplete="off"
              />
            </div>
          </div>
          <div>
            <label htmlFor="new_password" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <div className="relative mt-1">
              <input
                onChange={(event) => setNewPassword(event.target.value)}
                ref={passwordRef}
                type="password"
                name="new_password"
                id="new_password"
                value={newPassword}
                autoComplete="off"
                className={generateClassName(!validNewPassword && newPassword)}
                placeholder="New password"
              />
              <span ref={passwordVisibilityRef} onClick={togglePasswordVisibility} className="absolute top-2 right-[2%] material-icons-outlined text-gray-400 cursor-pointer">
                visibility
              </span>
              <PasswordRequirement />
            </div>
          </div>
          <div className="w-full flex justify-end">
            <div>
              <ButtonPrimary text={changingPassword ? "Please wait..." : "Change Password"} size="md" loading={!validNewPassword || changingPassword ? true : false} />
            </div>
          </div>
        </form>
        <hr />
        <form onSubmit={changeEmail} className="w-full space-y-3 p-4">
          <h1 className="text-lg text-success-800 font-semibold">Change Your Email Address</h1>
          <Alert
            color="yellow"
            heading="Changing your email address"
            body={[
              "You will be required to verify this email address after it has been changed.",
              "You won't be allowed to carry out any major actions inside COhatch+ without verifying your new email address first.",
              "Please ensure your new email works and that you have access to the inbox.",
            ]}
          />
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              New Email Address
            </label>
            <div className="mt-1">
              <input
                onChange={(event) => setEmailAddress(event.target.value)}
                type="email"
                name="email"
                id="email"
                className={generateClassName(!validEmailAddress && emailAddress)}
                placeholder="New email address"
                autoComplete="off"
              />
            </div>
          </div>
          <p className="text-sm text-gray-400 ">Logged in as: {userState.emailAddress}</p>
          <div className="w-full flex justify-end">
            <div>
              <ButtonPrimary text={changingEmailAddress ? "Please wait..." : "Change Email"} size="md" loading={!validEmailAddress || changingEmailAddress ? true : false} />
            </div>
          </div>
        </form>
        <hr />
        <div className="w-full space-y-3 p-4">
          <h1 className="text-xl text-red-500 font-semibold">Delete Your Account</h1>
          <Alert
            color="yellow"
            heading="Deleting your account"
            body={[
              "After submitting this request, your account will be queued for deletion.",
              "All your listings, groups, and data will be deleted completely from our servers.",
              "You won't be able to access this account or your data after you submit this request.",
            ]}
          />
          <div className="w-full flex justify-start">
            <div>
              <button
                onClick={handleShowPrompt}
                type="button"
                className="flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all ease-linear duration-500"
              >
                <span className="material-icons-outlined text-white text-md">delete</span>
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={deleteAccount} isExecuting={deletingAccount} />
    </div>
  );
}

export default Account;
