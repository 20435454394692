import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function updateProperty(authToken, propertyId, update) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/property/${propertyId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "PUT", { update }, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateProperty;
