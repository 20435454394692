/* eslint-disable no-unused-expressions */
import React from "react";
import NavigationAuth from "../navigation_auth/NavigationAuth";
import Logo from "./../logo/Logo";
import { NavLink } from "react-router-dom";

function HomeNavigation() {
  const mobileFeatureContainerRef = React.useRef(null);

  const toggleMobileFeatures = () => {
    mobileFeatureContainerRef.current.classList.toggle("hidden");
  };

  

  return (
    <div className="md:sticky md:top-0 md:z-30 bg-primary-400 font-roboto">
      <div className="relative z-30">
        <div className="w-full mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
          <div>
            <Logo color="white" />
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button onClick={toggleMobileFeatures} type="button" className="bg-primary-400 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-200" aria-expanded="false">
              <span className="sr-only">Open menu</span>
              <span className="material-icons-outlined text-3xl">menu</span>
            </button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <nav className="flex space-x-10">
              {/* <div className="group">
                <a
                  href="/#home_features"
                  className="text-white group bg-primary-400 rounded-md inline-flex items-center text-lg font-bold hover:text-gray-200 focus:outline-none ease-linear duration-500"
                  aria-expanded="false">
                  <span>Features</span>
                 
                </a>
              </div> */}
              <NavLink
                to="/pricing"
                className={({ isActive }) => {
                  return isActive ? "text-lg font-bold text-success-800 hover:text-success-900 ease-linear duration-500" : "text-lg font-bold text-white hover:text-gray-200 ease-linear duration-500";
                }}>
                Pricing
              </NavLink>

              <NavLink
                to="/health_benefits"
                className={({ isActive }) => {
                  return isActive ? "text-lg font-bold text-success-800 hover:text-success-900 ease-linear duration-500" : "text-lg font-bold text-white hover:text-gray-200 ease-linear duration-500";
                }}>
                Health Benefits
              </NavLink>
            
            </nav>
            <div className="flex items-center md:ml-12">
              <NavigationAuth />
            </div>
          </div>
        </div>
      </div>

      <div ref={mobileFeatureContainerRef} className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right hidden md:hidden">
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5 sm:pb-8">
            <div className="flex items-center justify-between">
              <div>
                <Logo color="green" />
              </div>
              <div className="-mr-2">
                <button
                  onClick={toggleMobileFeatures}
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-400">
                  <span className="sr-only">Close menu</span>
                  <span className="material-icons-outlined">close</span>
                </button>
              </div>
            </div>
            <div className="mt-6 sm:mt-8">
              <nav>
                <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                <a
                  href="/#home_features"
                  className="text-black group  rounded-md inline-flex items-center text-lg font-bold hover:text-gray-600 focus:outline-none ease-linear duration-500"
                  aria-expanded="false">
                  <span>Features</span>
                 
                </a>
                </div>
                <hr className="mt-5" />
                <div className="flex gap-10 justify-start mt-4">
                  <NavLink to="/pricing" className="text-lg font-bold text-black hover:text-gray-600">
                    Pricing
                  </NavLink>
                 
                </div>
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 bg-primary-400">
            <div className="flex justify-between items-center">
              <NavigationAuth />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeNavigation;
