/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import DefaultState from "../../../components/default_state/DefaultState";
import ListingCard from "../../../components/listing_card/ListingCard";
import { useNavigate } from "react-router-dom";
import getListings from "./getListing";
import useUserContext from "./../../../hooks/useUserContext";
import notify from "../../../services/toast";
import Pagination from "../../../components/pagination/Pagination";
import { MAGIC_NUMBERS } from "../../../constant";
import Spinner from "../../../components/spinner/Spinner";
import handleDeleteListing from "./handleDeleteListing";
import ListingUpdate from "../../../components/listing_update/ListingUpdate";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import getDiscounts from "../../local_discount/getDiscounts";
import { debounce } from "../../../services/util";
import CouponCard from "../../../components/coupon_card/CouponCard";

function MyListing() {
  useScrollPage();
  useTitle("My Listing(s)");

  const navigate = useNavigate();
  const {
    userState: { accessToken },
  } = useUserContext();

  const [fetching, setFetching] = React.useState(false);
  const [listings, setListings] = React.useState([]);
  const [editingMode, setEditingMode] = React.useState(false);
  const [listing, setListing] = React.useState(null);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 6 });

  const editListing = React.useCallback(listing => {
    setEditingMode(true);
    setListing(listing);
  }, []);

  const getAllListing = async (accessToken, page, limit) => {
    try {
      setFetching(true);
      const response = await getListings(accessToken, page, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setListings(results);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const deleteListing = React.useCallback(async listingId => {
    try {
      const response = await handleDeleteListing(accessToken, listingId);
      if (response.status === "success") {
        getAllListing(accessToken, pagination.page, pagination.limit);
        notify("Listing successfully deleted.", "info");
      } else {
        notify("An error occurred while trying to delete listing. Contact support if this error persist", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  }, []);

  const handlePrevFetch = () => {
    getAllListing(accessToken, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = () => {
    getAllListing(accessToken, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    getAllListing(accessToken, 0, 6);
  }, []);

  const { userState } = useUserContext();
  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [isDiscountLoading, setIsDiscountLoading] = useState(true);
  const categoryRef = useRef(category);
  categoryRef.current = category;

  const fetchDiscounts = async (accessToken, page, _keyword, _category) => {
    setIsDiscountLoading(true);
    const response = await getDiscounts({
      creator: true,
      page,
      keyword: _keyword,
      category: _category,
      limit: pagination.limit,
    }, accessToken);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setDiscounts(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsDiscountLoading(false);
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchDiscounts(userState.accessToken, 0, query, categoryRef.current);
    setKeyword(query);
  };

  const handleSearchByCategory = value => {
    fetchDiscounts(userState.accessToken, 0, keyword, value);
    setCategory(value);
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handleReload = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page, keyword, category);
  };

  useEffect(() => {
    fetchDiscounts(userState.accessToken, 0, "", "");
  }, []);

  return (
    <>
      {editingMode ? (
        <ListingUpdate listing={listing} setEditingMode={setEditingMode} reload={getAllListing} />
      ) : (
        <>
          {fetching ? (
            <Spinner displayText="Fetching..." />
          ) : (
            <div className="h-full w-full">
              <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="w-full mt-10 flex items-stretch justify-center md:justify-start gap-5 flex-wrap">
                  {listings.length ? (
                    listings.map((listing, index) => {
                      return <ListingCard key={index} listing={listing} deleteListing={deleteListing} editListing={editListing} />;
                    })
                  ) : (
                    <DefaultState
                      icon="storefront"
                      heading="No Listings Found"
                      description="Your business listings will appear here"
                      action={() => navigate("/dashboard/business_hub")}
                      actionText="Add a business Listing"
                    />
                  )}
                  {!isDiscountLoading && !discounts.length &&
                    <DefaultState icon="corporate_fare" heading="No Discounts Found" description="Discounts added by your businesses will appear here." />
                  }
                  <div className="w-full flex items-center justify-center md:justify-start gap-5 flex-wrap">
                    <>
                      {isDiscountLoading ? (
                        <Spinner displayText="Loading..." />
                      ) : (
                        discounts.map(discount =>
                          <CouponCard
                            key={discount._id}
                            coupon={discount}
                            isFavorite={!!discount.favorites.length}
                            isOwner
                            onReload={handleReload}
                            fid={discount.favorites[0] ? discount.favorites[0]._id : ""}
                          />
                        )
                      )}
                    </>
                  </div>
                  <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
                </div>
              </div>
              {/* <section className="pt-12">
              <Footer />
              </section> */}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MyListing;
