/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import getUserInfo from "./getUserInfo";
import { Link } from "react-router-dom";

function ListingOwner({ listingOwner, userId }) {
  const { firstName, lastName, emailAddress, jobTitle } = listingOwner;
  const [userInfo, setUserInfo] = React.useState(null);

  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo(userId);
      if (response.status === "success") {
        setUserInfo(response.data);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchUserInfo();
  }, []);
  return (
    <div className="w-full flex flex-col bg-white rounded-md shadow-md p-3">
      <h1 className="text-xl text-success-800 font-bold m-2">Contact Person</h1>
      <div className="w-full relative px-6 py-5 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={userInfo?.photo?.small ? userInfo.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
            alt=""
          />
        </div>
        <div className="flex-1 min-w-0">
          <Link to={`/profile/${userId}`} className="text-sm font-medium text-gray-900 underline capitalize">
            {firstName} {lastName}
          </Link>
          <p className="text-sm text-gray-500 truncate capitalize">{jobTitle}</p>
          {userInfo?.privacy?.privacy?.businessEmailAddress ? null : <p className="text-sm font-medium text-gray-900 lowercase">{emailAddress}</p>}
        </div>
      </div>
    </div>
  );
}

ListingOwner.propTypes = {
  listingOwner: PropTypes.object,
  userId: PropTypes.string,
};
export default ListingOwner;
