/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import { Outlet} from "react-router-dom";

import Tab from "./../../components/tab/Tab";


const navigations = [
	{ pathName: "Add A Listing", url: "", end: true },
  {pathName: "My Listings", url: "my_listings"},
 
	{ pathName: "Scholarship Applications", url: "my_applications" },

];

function BusinessHub() {
	return (
		<>
			<div className="p-5 space-y-2">
				<div className="flex-1 min-w-0 flex justify-center items-center">
					<h1 className="text-2xl font-bold leading-7 text-primary-400 font-bebas sm:text-4xl sm:truncate text-center">
						My Business Hub
					</h1>
				</div>
			
				{/* <Tab navigations={navigations} section="marketplace" /> */}
				<Outlet />
			</div>
		</>
	);
}

export default BusinessHub;
