/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../../hooks/useUserContext";
import getGroups from "./getGroups";
import DefaultState from "../../../components/default_state/DefaultState";
import GroupCard from "../../../components/group_card/GroupCard";
import Spinner from "../../../components/spinner/Spinner";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import { debounce } from "../../../services/util";
import deleteGroup from "./deleteGroup";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

const groupFilter = [
  { value: "ACTIVE", label: "Approved" },
  { value: "PENDING", label: "Pending" },
  { value: "REJECTED", label: "Rejected" },
];
const allStatuses = groupFilter.map((item) => item.value);

function MyGroup() {
  useScrollPage();
  useTitle("My Group(s)");
  
  const navigate = useNavigate();
  const { userState } = useUserContext();
  const [groups, setGroups] = useState([]);
  const [sort, setSort] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isGroupLoading, setIsGroupLoading] = useState(true);

  const fetchGroups = async (query) => {
    setIsGroupLoading(true);
    const response = await getGroups(
      {
        creator: true,
        ...query,
      },
      userState.accessToken
    );
    if (response.status === "success") {
      const newGroups = [...response.data.results];
      setGroups(newGroups);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
    setIsGroupLoading(false);
  };

  useEffect(() => {
    fetchGroups({
      status: allStatuses,
    });
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setKeyword(query);
    fetchGroups({
      status: sort || allStatuses,
      keyword: query,
    });
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handleDeleteGroup = async (groupId) => {
    try {
      const response = await deleteGroup(userState.accessToken, groupId);
      if (response.status === "success") {
        notify("Group was successfully deleted", "success");
        fetchGroups({ status: allStatuses });
      } else {
        notify("An error occurred while deleting the group. If this error persists please contact support", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  return (
    <div className="h-full w-full">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full mt-10 flex items-center justify-center md:justify-start gap-5 flex-wrap">
          <div className="w-full flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:justify-between sm:items-center">
            <div className="w-full sm:w-2/4 sm:mt-1">
              <input
                type="text"
                name="search"
                id="search"
                onInput={debouncedHandleSearch}
                placeholder="Search groups"
                className="shadow-sm focus:ring-primary-400 focus:border-primary-400 block w-full max-w-md p-3 sm:text-sm border-gray-300 rounded-full"
              />
            </div>
            <div className="w-full sm:w-1/5">
              <Select
                placeholder="Sort groups"
                isDisabled={false}
                isClearable={true}
                isLoading={false}
                isRtl={false}
                isSearchable={false}
                name="months of the year"
                options={groupFilter}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#ecf8ef",
                    primary: "#56c271",
                  },
                })}
                onChange={(value) => {
                  fetchGroups({
                    status: value.value,
                    keyword: keyword,
                  });
                  setSort(value.value);
                }}
              />
            </div>
          </div>
          {!isGroupLoading && !groups.length && (
            <DefaultState
              icon="corporate_fare"
              heading="No Groups Found"
              description="My groups created will appear here"
              action={() => navigate("/dashboard/neighborhood/create_group")}
              actionText="Create your own group"
            />
          )}
          <>{isGroupLoading ? <Spinner displayText="Loading..." /> : groups.map((group) => <GroupCard key={group._id} group={group} deleteGroup={handleDeleteGroup} />)}</>
        </div>
      </div>
    </div>
  );
}

export default MyGroup;
