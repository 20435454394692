/* eslint-disable no-console */
import React from "react";
import { Outlet } from "react-router-dom";
import getUnreadNotifications from "../pages/dashboard/getUnreadNotifications";
import useUserContext from "../hooks/useUserContext";

const NotificationContext = React.createContext({});

function NotificationProvider() {
  const { userState } = useUserContext();
  const [unreadNotifications, setUnreadNotifications] = React.useState(0);

  const handleGetUnreadNotifications = async () => {
    try {
      const response = await getUnreadNotifications(userState.accessToken);
      if (response.status === "success") {
        setUnreadNotifications(response.data);
      } else {
        console.error("An error occurred while updating notification");
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    handleGetUnreadNotifications();
  }, []);
  return (
    <NotificationContext.Provider value={{ unreadNotifications, setUnreadNotifications }}>
      <Outlet />
    </NotificationContext.Provider>
  );
}

export { NotificationProvider, NotificationContext };
