/*eslint-disable*/
import React, {useState, useEffect} from "react";

import useTitle from "../../../hooks/useTitle";
import Tab from "../../../components/tab/Tab";
import { FaHome } from "react-icons/fa";
import { MdCabin } from "react-icons/md";
import { MAGIC_NUMBERS } from "../../../constant";
import { NavLink } from "react-router-dom";
//import { Link } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import { GiOpenedFoodCan, GiNotebook } from "react-icons/gi";
import { IoShirtSharp } from "react-icons/io5";
import { Carousel } from "react-responsive-carousel";

import DriveEventForm from "../../../components/philanthropy/DriveEventForm";
import notify from "../../../services/toast";
import createImpactEvent from "../../../components/philanthropy/createImpactEvent"
import useUserContext from "../../../hooks/useUserContext";
import DriveCard from "../../../components/philanthropy/DriveCard";
import getDrives from "./getDrives"
import DefaultState from "../../../components/default_state/DefaultState";


//import ButtonPrimary from "../../../components/button/ButtonPrimary";

function Drives() {
 
  const [events, setEvents] = useState([]);
	 const [submitting, setSubmitting] = useState(false);
  const { userState } = useUserContext();
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 11,
	});




  	function renderEvents(evts, index) {
    return <DriveCard
          
          key={index}
          event={evts}/>;							
  }

  const [event, setEvent] = useState({
     eventName: "",
    eventDescription: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    eventCategory: "",
    eventLocation: {
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
    },
    eventCOhatchMarket: "",
    eventCOhatchLocation: "",
    eventCOhatchMeetingRoom: "",
    eventImage: null,
    eventOwnerImage: null,
    eventOwnerFirstName: "",
    eventOwnerLastName: "",
    aboutOrganization: "",
    donation: {
      goal: null,
      amountRaised: null
    }
  });

  const fetchEvents = async (accessToken) => {
    
		try {
      setFetching(true);
			const response = await getDrives(accessToken);

			if (response.status === "success") {
      
				setEvents(response.data);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occured. Please try again later. If this error persists please contact support.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later");
		} finally {
			setFetching(false);
		}
	};


	const handlePrevFetch = async () => {
		await fetchEvents(userState.accessToken, pagination.page - 1, keyword);
	};

	const handleNextFetch = async () => {
		await fetchEvents(userState.accessToken, pagination.page + 1, keyword);
	};

    const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const dt = new Date(parseInt(event.eventDate.substring(0, 4), 10), parseInt(event.eventDate.substring(5, 7), 10) - 1, parseInt(event.eventDate.substring(8, 10), 10), parseInt(event.startTime.substring(0, 2), 10), parseInt(event.endTime.substring(3, 5), 10), 0);
      const response = await createImpactEvent(userState.accessToken, {
        ...event,
        eventDate: dt,
      });
      if (response.status === "success") {
        notify("Your event was successfully submitted.", "info");
        
      } else {
        setSubmitting(false);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      notify("Something went wrong. Please try again later.", "error");
    }
  };

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}
	// const queryParams = new URLSearchParams(window.location.search);
	// const propertyType = queryParams.get("type") || "";
  
useEffect(() => {
		fetchEvents(userState.accessToken);
	}, []);
	return (
		<div className="w-full py-8 px-5 text-center ">
	
				
			<div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
				<div className="relative mt-10 max-w-full h-full mx-auto bg-white shadow rounded-lg">
					<div className="w-full h-full bg-white p-10 space-y-8">
						{/* Header Language */}
						<div className="relative overflow-hidden pt-3 pb-2">
							{/* <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" /> */}
							<div
								aria-hidden="true"
								className="absolute inset-x-0 top-0 h-48 bg-white"
							/>
							<div className="relative">
								<div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
									<div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-0 lg:px-0">
										<div>
											<div>
												<h2 className="text-3xl font-bold font-bebas tracking-wide text-success-800">
													See All Ongoing Drives
												</h2>
												<p className="mt-4 text-lg text-gray-500">
													COhatch is all about community and helping eachother.
													Have an ongoing cause that needs support? Add the
													event!
												</p>
												{/* <p className="mt-4 text-lg text-gray-500">
                        You can now find cost effective coverage for you or your employees using A.I. Underwriting and top carriers without lengthy health questionnaires or clunky processes.
                      </p> */}
												{/* <div className="mt-6">
                    <a
                        href="https://calendly.com/jackreinoehl/benefits-overview"
                        target="_blank" rel="noreferrer"
                        className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-primary-400 to-primary-400 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-primary-100 hover:to-primary-100"
                      >
                        Learn More
                      </a>
                    </div> */}
											</div>
										</div>
									</div>
									{/* Image Carousel >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
									<div className="mt-10 sm:mt-10 lg:mt-0">
										<Carousel
											showThumbs={false}
											centerSlidePercentage={50}
											showStatus={false}
											autoPlay={true}
											interval={5000}
											infiniteLoop={true}
											renderArrowNext={(clickHandler, hasNext) =>
												hasNext && (
													<button
														className="control-arrow control-next"
														onClick={clickHandler}
													>
														<span className="material-icons-outlined text-4xl">
															arrow_forward_ios
														</span>
													</button>
												)
											}
											renderArrowPrev={(clickHandler, hasPrev) =>
												hasPrev && (
													<button
														className="control-arrow control-prev"
														onClick={clickHandler}
													>
														<span className="material-icons-outlined text-4xl">
															arrow_back_ios
														</span>
													</button>
												)
											}
										>
											<div className="w-full h-[300px]">
												<img
													src="https://www.feedingamerica.org/sites/default/files/styles/max_650x650/public/2022-11/Volunteer-Photo%20Sep%2002%2C%2011%2047%2012%20AM%20%281%29%20%281%29.jpg"
													alt="default cover image"
													className="w-full h-full object-cover shadow-xl"
												/>
											</div>
											<div className="w-full h-[300px]">
												<img
													src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD_vTI2hVp0ZvjyH2-WqX3HPafupJzLwUvGQ&usqp=CAU"
													alt="default cover image"
													className="w-full h-full object-cover shadow-xl"
												/>
											</div>
											<div className="w-full h-[300px]">
												<img
													src="https://cdn.aarp.net/content/dam/aarp/volunteer/2022/1140x655-create-the-good-volunteer.jpg"
													alt="default cover image"
													className="w-full h-full object-cover shadow-xl"
												/>
											</div>
										</Carousel>
									</div>

							
								</div>
							</div>
						</div>
                <div className="bg-white py-24 sm:py-12">
       
        </div>

				<div className="flex justify-end ">
					 <DriveEventForm
					  button={<button
                type="button"
                className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                >
                  Add Event
                </button>}
                title="Drives"
                link={"Virtual Link to Donate"}
                event={event}
                setEvent={setEvent}
                onSubmit={handleSubmit}
		
					/> 
					</div>
					<div className="relative">
            
						<div
							className="absolute inset-0 flex items-center"
							aria-hidden="true"
						>
							<div className="w-full border-t border-gray-300" />
						</div>
           
						<div className="relative flex justify-start">
							{/* <span className="ml-3 text-4xl font-bold font-bebas text-primary-400 sm:leading-9 sm:truncate">Book Free Life Events</span> */}
							<span className="bg-white pr-3 text-4xl text-primary-400 font-bebas font-bold">
								Ongoing Drives
							</span>
            
						</div>
            
					</div>
       
       <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {events.map((evts, index) => {
								return renderEvents(evts, index);
							})}
          </div>
         

					</div>
         
				</div>
			</div>
		</div>
	);
}

export default Drives;
