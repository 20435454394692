/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function getPropertyByType(accessToken, page, limit, type, keyword, filter) {
  let API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/property?page=${page}&limit=${limit}${type ? `&type=${type}` : ""}${keyword ? `&keyword=${keyword}` : ""}`;
  if (filter.bedrooms && filter.bedrooms.length > 0) {
    API_ENDPOINT += `&bedrooms=${filter.bedrooms.join(",")}`;
  }
  if (filter.beds && filter.beds.length > 0) {
    API_ENDPOINT += `&beds=${filter.beds.join(",")}`;
  }
  if (filter.bathrooms && filter.bathrooms.length > 0) {
    API_ENDPOINT += `&bathrooms=${filter.bathrooms.join(",")}`;
  }
  if (filter.ratings && filter.ratings.length > 0) {
    API_ENDPOINT += `&ratings=${filter.ratings.join(",")}`;
  }
  if (filter.discounts && filter.discounts.length > 0) {
    API_ENDPOINT += `&discounts=${filter.discounts.join(",")}`;
  }
  if (filter.creator) {
    API_ENDPOINT += "&creator=true";
  }
  if (filter.status) {
    API_ENDPOINT += `&status=${filter.status}`;
  }
  if (filter.amenities && filter.amenities.length > 0) {
    API_ENDPOINT += `&amenities=${filter.amenities.join(",")}`;
  }
  if (filter.max && filter.max > 1) {
    API_ENDPOINT += `&min=${filter.min}&max=${filter.max}`;
  }

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getPropertyByType;
