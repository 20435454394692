import React from "react";

function PasswordRequirement() {
  return (
    <div className="rounded-md bg-gray-100 p-2 mt-4 font-roboto transition ease-linear duration-500">
      <div className="flex">
        <div className="ml-3">
          <div className="mt-2 text-sm">
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li className="text-success-800">Must have 1 lowercase letter.</li>
              <li className="text-success-800">Must have 1 uppercase letter.</li>
              <li className="text-success-800">Must have 1 numeric character.</li>
              <li className="text-success-800">Must contain at least 1 special character.</li>
              <li className="text-success-800">Must be at least 8 characters in length.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordRequirement;
