import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getAllLifePerksPost(accessToken, officeId, page, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/lifeperks_post/${officeId}?page=${page}&limit=${limit}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getAllLifePerksPost;
