/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function getRSVPs(authToken, eventId, page, limit, status) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/event/${eventId}/attendee?page=${page}&limit=${limit}${status ? `&status=${status}` : ""}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getRSVPs;
