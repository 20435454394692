/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";

function EventCard({
  event,
}) {
  return (
    <div className="w-full max-w-xs shadow-xl rounded bg-white">
      <div className="w-full">
        <div
          className="w-full h-[200px] bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${event.logo?.url})` }}
        ></div>
        <div className="w-full bg-white p-5 space-y-3">
          <div className="w-full flex items-center gap-2">
            <span className="material-icons-outlined text-success-800">corporate_fare</span>
            <h1 className="text-xl text-success-800 font-bold capitalize text-ellipsis overflow-hidden whitespace-nowrap">{event.name.text}</h1>
          </div>
          <div className="w-full flex items-center gap-2">
            <span className="material-icons-outlined text-success-800">calendar_month</span>
            <h1 className="text-md text-success-800 capitalize">{format(new Date(event.start.utc), "iii, MMMM do, hh:mm a")}</h1>
          </div>
          <div className="w-full flex items-center gap-2">
            <span className="material-icons-outlined text-success-800">calendar_month</span>
            <h1 className="text-md text-success-800 capitalize">{format(new Date(event.end.utc), "iii, MMMM do, hh:mm a")}</h1>
          </div>
          <div className="w-full flex items-center gap-2">
            <a href={event.url} className="text-primary-400 underline" target="_blank" rel="noreferrer">
              <i className="fa fa-sign-in"></i> Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
