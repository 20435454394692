import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function getReaction(accessToken, type, postId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/reaction/stat?type=${type}&postId=${postId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getReaction;
