import React from "react";
import Footer from "../../../components/footer/Footer";
import MarketplaceNavigation from "../../../components/marketplace_navigation/MarketplaceNavigation";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function AntiDiscrimination() {
  useScrollPage();
  useTitle("COhatch+ Anti Discrimination Policy");
    return (
<div className="w-full flex flex-col">
        <div className="sticky top-0 w-full z-[999999]">
          <MarketplaceNavigation />
        </div>
        <div className="w-full py-8 px-5 text-center space-y-3 bg-[url('/src/assets/grungeTextureLight.png')] bg-no-repeat bg-cover">
          <h1 className="w-full text-4xl md:text-5xl font-bebas text-success-800 font-bold">Anti-Discrimination and Hate Speech Policy</h1>
        </div>


<section>   
  <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-12">
        <div className="text-lg  mx-auto">
        <p className="mt-4 text-base text-gray-500 leading-8">
          We want everyone on COhatch to feel safe, and fostering an inclusive environment is a priority. This Anti-Discrimination and Hate Speech Policy (“Policy”) explains the kind of behavior we prohibit on COhatch to make sure we all have a positive experience.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          This Policy is a part of our General Terms of Use. By using COhatch, you are agreeing to this Policy and our General Terms of Use.
          </p>
          
          <p className="mt-4 text-base text-gray-500 leading-8">
          COhatch prohibits the use of our Services to discriminate against people based on the following personal attributes (collectively, the “Protected Groups”):
          <ul className="list-disc pl-10">
              <li>Race</li>
              <li>Color</li>
              <li>Ethnicity</li>
              <li>National Origin</li>
              <li>Religion</li>
              <li>Gender</li>
              <li>Gender Identity</li>
              <li>Sexual Orientation</li>
              <li>Disability</li>
              <li>Any other characteristic protected under applicable law</li>
          </ul>
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          It is your responsibility to know your local laws and any other legal regulations on discrimination that might apply to you. Additionally, COhatch does not allow hate speech. Hate speech occurs when violent, offensive, derogatory or demeaning language is directed at a person or group based on their one or more protected group attributes.
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          Whether you are engaging with public features on COhatch, such as posting reviews, using community spaces, or having direct communication with other members of the COhatch community, such as via Messages, discrimination and hate speech are not allowed. As a seller on COhatch, your shop content, including shop announcements and shop policies, cannot display discriminatory behavior toward protected groups. Examples of prohibited behavior include, but are not limited to:
          <ul className="list-disc pl-10">
              <li>Refusing to provide a product or service based on membership in one or more protected group;</li>
              <li>Expressing intolerance or a lack of respect for another member on the basis of protected group attributes;</li>
              <li>Having a shop policy that excludes sales to members of one or more protected groups listed above;</li>
              <li>Directly or indirectly making derogatory or demeaning remarks against protected groups listed above;</li>
              <li>Racial slurs; and/or</li>
              <li>Posts that support or glorify hate groups and their members.</li>
          </ul>
          </p>
          <p className="mt-4 text-base text-gray-500 leading-8">
          If you think discrimination or hate speech has occurred on COhatch, please report it by emailing support@cohatchplus.com.
          </p>
        </div>

        
        <div className="text-lg  mx-auto">
          <p className="mt-12 text-base text-gray-500 leading-8">
          Last updated: March 10, 2022
          </p>
        </div>
      </div>
  </div>
</section>
  <Footer />
</div> 
    );
}
    export default AntiDiscrimination;