/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable capitalized-comments */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import { GoogleMap, Autocomplete, useLoadScript, Marker } from "@react-google-maps/api";
import Spinner from "../../../../components/spinner/Spinner";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_OPTIONS, DEFAULT_MAP_ZOOM } from "../../../../constant";
import useScrollPage from "../../../../hooks/useScrollPage";

const libraries = ["places"];
function BusinessAddress({ steps, currentStep, setCurrentStep, listing, setListing }) {
  useScrollPage();

  const [autoComplete, setAutoComplete] = React.useState(null);
  const [place, setPlace] = React.useState(listing?.address || null);
  const [googleMap, setGoogleMap] = React.useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
    region: "US",
  });

  const onLoad = autoComplete => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const {
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
      } = autoComplete.getPlace();
      const latitude = lat();
      const longitude = lng();
      setPlace({ address: formatted_address, coordinates: { lat: latitude, lng: longitude }, location: { type: "Point", coordinates: [longitude, latitude] } });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onPositionChanged = () => {
    if (googleMap) {
      googleMap.panTo(place.coordinates);
    }
  };

  const handleSubmit = () => {
    if (Object.keys(place).length > 0) {
      setListing(prevState => {
        return { ...prevState, address: place };
      });
    }

    setCurrentStep(currentStep + 1);
  };

  const handleGoBack = () => {
    if (Object.keys(place).length > 0) {
      setListing(prevState => {
        return { ...prevState, address: place };
      });
    }
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="w-full bg-white overflow-hidden shadow rounded-lg">
      <div className="w-full px-4 py-5 sm:p-6 space-y-2">
        <h1 className="text-xl font-bold text-success-800">Business Address</h1>
        <p className="text-sm text-gray-400">Where are you located?</p>
        <div className="w-full flex items-center justify-center gap-4 flex-wrap">
          <div className="w-full">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Business Address
              </label>
              <span className="w-2 h-2 rounded-full bg-red-500"></span>
            </div>
            <div className="mt-1">
              {isLoaded ? (
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3"
                    aria-describedby="name-optional"
                  />
                </Autocomplete>
              ) : (
                <Spinner displayText="Loading..." />
              )}
            </div>
          </div>
          <div className="w-full h-96">
            {isLoaded ? (
              <GoogleMap onLoad={map => setTimeout(() => setGoogleMap(map))} options={DEFAULT_MAP_OPTIONS} zoom={DEFAULT_MAP_ZOOM} center={DEFAULT_MAP_CENTER} mapContainerClassName="h-full w-full">
                {place && googleMap && <Marker position={place.coordinates} animation={["DROP"]} onPositionChanged={onPositionChanged} title={place.address} />}
              </GoogleMap>
            ) : (
              <Spinner displayText="Loading Map..." />
            )}
          </div>
        </div>
        <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
          <div>{currentStep <= 0 ? null : <ButtonPrimary icon="arrow_back" text={steps[currentStep - 1]} size="md" action={handleGoBack} />}</div>
          <div>{currentStep >= steps.length ? null : <ButtonPrimary icon="arrow_forward" text={steps[currentStep + 1]} size="md-1" loading={!place ? true : false} action={handleSubmit} />}</div>
        </div>
      </div>
    </div>
  );
}

export default BusinessAddress;
