import React from "react";
import Footer from "../../components/footer/Footer";
import MarketplaceNavigation from "../../components/marketplace_navigation/MarketplaceNavigation";
import {Link} from "react-router-dom";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";

function Policy() {
  useScrollPage();
  useTitle("COhatch+ Policies");
    return (
        <>
      <div className="w-full flex flex-col">
        <div className="sticky top-0 w-full z-[999999]">
          <MarketplaceNavigation />
        </div>
        
        
       
<section className="text-gray-600 body-font bg-white">
   
  <div className="container px-5 py-12 mx-auto">
  <div className="flex flex-wrap w-full mb-10 flex-col items-center text-center">
      <h1 className="sm:text-5xl text-4xl font-medium font-bebas title-font mb-2 text-primary-400">See Our Policies</h1>
    </div>
    <div className="flex flex-wrap -m-4">
    <div className="p-4 lg:w-1/3 sm:w-full">
        <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
        <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
        </div>
          <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 mx-12">General Terms of Use</h1>
          <Link to="/general_terms" className="text-indigo-500 inline-flex items-center" >Learn More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
        <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
        </div>
          <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 sm:mx-24">Terms of Sale</h1>
          <Link to="/terms_of_sale" className="text-indigo-500 inline-flex items-center">Learn More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
        <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
        </div>
        <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 md:mx-12">COhatch Privacy Policy</h1>
          <Link to="/cohatch_policy" className="text-indigo-500 inline-flex items-center">Learn More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="p-4 lg:w-1/2">
        <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
        <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
        </div>
          <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Anti-Discrimination and Hate Speech Policy</h1>
          <Link to="/anti_discrimination" className="text-indigo-500 inline-flex items-center">Learn More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="p-4 lg:w-1/2">
        <div className="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
        <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
        </div>
          <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Electronic Communications Policy</h1>
          <Link to="/electronic_communication" className="text-indigo-500 inline-flex items-center">Learn More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  </div>
</section>

      </div>
      <Footer />
    </>
    );

}
export default Policy;
