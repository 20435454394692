/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { Link, useOutletContext } from "react-router-dom";
import CreatePost from "../../../components/create_post/CreatePost";
import DefaultState from "../../../components/default_state/DefaultState";
import GroupAvatar from "../../../components/group_avatar/GroupAvatar";
import Post from "../../../components/post/Post";
import Spinner from "../../../components/spinner/Spinner";
import UserProfile from "../../../components/user_profile/UserProfile";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import getPost from "./getAllPost";
import useScrollPage from "../../../hooks/useScrollPage";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import useTitle from "../../../hooks/useTitle";

function admin({ callback }) {
  return (
    <div onClick={callback} className="w-full bg-gray-200 p-3 cursor-pointer text-center hover:bg-gray-300 transition ease-linear duration-500">
      See more
    </div>
  );
}
function getActiveMember(members) {
  return members.filter(member => member.status === "ACTIVE");
}

function TimeLine() {
  useScrollPage();

  const { group, socket } = useOutletContext();
  const { userState } = useUserContext();
  const [posts, setPosts] = React.useState([]);
  const [fetchingPost, setFetchingPost] = React.useState(false);
  const rootRef = React.useRef(null);
  const page = React.useRef(0);
  const remaining = React.useRef(0);

  useTitle(group?.name?.toUpperCase() || "Timeline");

  const fetchAllPost = async () => {
    try {
      setFetchingPost(true);
      const response = await getPost(userState.accessToken, group._id, page.current, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setPosts(prevState => {
          return [...prevState, ...results];
        });
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };

  const reload = async () => {
    try {
      setFetchingPost(true);
      const response = await getPost(userState.accessToken, group._id, 0, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        remaining.current = remainingResult;
        page.current = 0;
        setPosts([]);
        setPosts(results);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };
  const observer = React.useRef(
    new IntersectionObserver(
      entries => {
        const [first] = entries;
        if (first.isIntersecting) {
          fetchAllPost();
        }
      },
      { threshold: 1, root: rootRef.current }
    )
  );

  const [element, setElement] = React.useState(null);

  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  React.useEffect(() => {
    fetchAllPost();
  }, []);

  return (
    <div className="mt-5 w-full flex justify-center gap-4">
      <div className="w-full space-y-3">
        <CreatePost groupId={group._id} groupName={group.name} reload={reload} setPosts={setPosts} creatorId={group.creator._id} socket={socket} />
        <div ref={rootRef} className="w-full space-y-3">
          {posts.map((post, index) => {
            return <Post key={index} post={post} reload={reload} socket={socket} groupId={group._id} groupName={group.name} creatorId={group.creator._id} />;
          })}

          {posts.length <= 0 ? <DefaultState icon="post_add" heading="No Posts Found" description="All post will appear here" /> : null}

          {fetchingPost && <Spinner displayText="Fetching..." />}

          {remaining.current > 0 ? (
            <span ref={setElement} className="w-full flex justify-center text-gray-400">
              Loading...
            </span>
          ) : null}

          {posts.length && remaining.current <= 0 ? <span className="w-full flex justify-center text-gray-400">No more posts to load</span> : null}
        </div>
      </div>
      <div className="hidden md:block md:sticky md:top-24 w-[600px] h-full space-y-5">
        <div className="w-full bg-white rounded-md shadow-md h-full">
          <div className="w-full border-b-2 border-solid border-gray-200 p-2">
            <h1 className="text-md text-success-800 font-bold">Administrator</h1>
          </div>
          <div className="w-full flex flex-col p-3">
            <img
              className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
              src={group && group?.creator?.photo?.small ? group.creator.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
              alt={group && group.creator.lastName}
            />
            <div className="mt-2 w-full text-center">
              <h1 className="text-md text-success-800 font-bold">
                {group && group.creator.firstName} {group && group.creator.lastName}
              </h1>
              {group && group.creator.jobTitle ? <p className="text-sm text-gray-400">{group.creator.jobTitle}</p> : null}
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary-100 text-primary-800">
                <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-primary-400" fill="currentColor" viewBox="0 0 8 8">
                  <circle cx="4" cy="4" r="3" />
                </svg>
                Creator
              </span>
            </div>
          </div>
          <UserProfile userId={group && group.creator._id} Display={admin} />
        </div>

        {/* <div className="w-full bg-white rounded-md shadow-md h-full">
          <div className="w-full border-b-2 border-solid border-gray-200 p-2">
            <h1 className="text-md text-success-800 font-bold">Book a Meeting Room</h1>
          </div>

          <div className="w-full flex flex-col p-3">
            <a href="https://www.cohatch.com/cohatch-plus-book-a-room/" target="_blank" rel="noreferrer">
              <ButtonPrimary className="bg-success-400" size="md" text="Meet at COhatch"></ButtonPrimary>
            </a>
          </div>
        </div> */}

        <div className="w-full bg-white rounded-md shadow-md h-full">
          <div className="w-full border-b-2 border-solid border-gray-200 p-2">
            <h1 className="text-md text-success-800 font-bold">About</h1>
          </div>
          <div className="w-full flex flex-col items-center p-3">
            <div className="w-full flex gap-2 items-center">
              <span className="text-md text-success-800">{group && getActiveMember(group.members).length}</span>
              <p className="text-md text-success-800">Member (s)</p>
            </div>
          </div>
          <div className="w-full flex flex-col p-3">
            <div className="w-full flex flex-col space-y-2">
              <h1 className="w-full text-md text-success-800">Description</h1>
              <p className="text-sm text-gray-400">
                {group && group.description.length ? group.description.slice(0, 150) : null} {group && Math.abs(group.description.length - 150 > 0) ? "..." : null}
              </p>
            </div>
          </div>

          <div className="w-full flex flex-col p-3">
            <div className="w-full flex flex-col space-y-2">
              <h1 className="w-full text-md text-success-800">Rules</h1>
              <p className="text-sm text-gray-400">
                {group && group.rules.length ? group.rules.slice(0, 150) : null} {group && Math.abs(group.rules.length - 150 > 0) ? "..." : null}
              </p>
            </div>
          </div>
          <div className="w-full bg-gray-200 p-3 cursor-pointer text-center hover:bg-gray-300 transition ease-linear duration-500">
            <Link to="about">See more</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeLine;
