import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function getPremiumMembers(accessToken, page, limit, endpoint) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/user${endpoint}&page=${page}&limit=${limit}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getPremiumMembers;
