/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import TimeCard from "../../../../components/time_card/TimeCard";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import useScrollPage from "../../../../hooks/useScrollPage";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function BusinessHours({ steps, currentStep, setCurrentStep, listing, setListing }) {
  useScrollPage();
  return (
    <div className="w-full bg-white overflow-hidden shadow rounded-lg">
      <div className="w-full px-4 py-5 sm:p-6 space-y-2">
        <h1 className="text-xl font-bold text-success-800">Business Hours</h1>
        <p className="text-sm text-gray-400">Let members know when they can stop by!</p>
        <div className="w-full flex items-center justify-center gap-4 flex-wrap">
          {daysOfWeek.map((dayOfWeek, index) => {
            return <TimeCard dayOfWeek={dayOfWeek} key={index} setListing={setListing} listing={listing} hour={listing?.businessHours?.[dayOfWeek] || null} />;
          })}
        </div>
        <div className="w-full flex justify-between items-center">
          <div>{currentStep <= 0 ? null : <ButtonPrimary icon="arrow_back" text={steps[currentStep - 1]} size="md" action={() => setCurrentStep(currentStep - 1)} />}</div>
          <div>{currentStep >= steps.length ? null : <ButtonPrimary icon="arrow_forward" text={steps[currentStep + 1]} size="md-1" action={() => setCurrentStep(currentStep + 1)} />}</div>
        </div>
      </div>
    </div>
  );
}

export default BusinessHours;
