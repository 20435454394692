/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function getNotification(authToken, page, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/notification?page=${page}&limit=${limit}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getNotification;
