/* eslint-disable capitalized-comments */
import React, { useState, useEffect } from "react";
import getEvents from "./getEvents";
import EventCard from "../../../../components/event_card/EventCard";
import Spinner from "../../../../components/spinner/Spinner";
import Pagination from "../../../../components/pagination/Pagination";
// import Footer from "../../../../components/footer/Footer";
import notify from "../../../../services/toast";
import useScrollPage from "../../../../hooks/useScrollPage";

function Events() {
  useScrollPage();
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ limit: 20, total: 0, page: 0, remaining: 0, continuation: null });

  const fetchEvents = async page => {
    setIsEventsLoading(true);
    try {
      const response = await getEvents({
        continuation: pagination.continuation,
        // eslint-disable-next-line camelcase
        page_size: pagination.limit,
      });
      // eslint-disable-next-line camelcase
      const { continuation, object_count } = response.pagination;
      const newEvents = [...events];
      newEvents.push(response.events);
      // eslint-disable-next-line camelcase
      setPagination({ limit: pagination.limit, page, remaining: object_count - (page + 1) * pagination.limit, total: object_count, continuation });
      setEvents(newEvents);
    } catch (error) {
      const displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
      notify(displayMessage, "error");
    } finally {
      setIsEventsLoading(false);
    }
  };

  const handlePrevFetch = () => {
    setPagination({
      limit: pagination.limit,
      page: pagination.page - 1,
      remaining: pagination.remaining + pagination.limit,
      continuation: pagination.continuation,
      total: pagination.total,
    });
  };

  const handleNextFetch = async () => {
    if (events[pagination.page + 1]) {
      setPagination({
        limit: pagination.limit,
        page: pagination.page + 1,
        remaining: pagination.remaining - pagination.limit,
        continuation: pagination.continuation,
        total: pagination.total,
      });
    } else {
      await fetchEvents(pagination.page + 1);
    }
  };

  useEffect(() => {
    fetchEvents(0);
  }, []);

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="w-full py-8 px-5 text-center space-y-3 bg-[url('/src/assets/grungeTextureLight.png')] bg-no-repeat bg-cover">
          <h1 className="w-full text-4xl md:text-5xl text-success-800 font-bold">Events</h1>
          <span className="w-full text-md text-gray-500"> Stop by your local COhatch location for fun community events and workshops.</span>
        </div>
        <div className="h-full w-full">
          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="w-full mt-10 flex justify-center md:justify-start items-center gap-5 flex-wrap">
              <div className="w-full flex items-center justify-center md:justify-start gap-5 flex-wrap">
                <>{isEventsLoading ? <Spinner displayText="Loading..." /> : (events[pagination.page] || []).map(event => <EventCard key={event.id} event={event} />)}</>
              </div>
              <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Events;
