/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { FaWindowClose } from "react-icons/fa";
import Popup from "reactjs-popup";
import { MdLocalSee } from "react-icons/md";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import FileUploader from "../file_uploader/FileUploader";
import useUserContext from "../../hooks/useUserContext";
import SelectInput from "../select_input/SelectInput";
import { WORKSHOP_CATEGORY, COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant";
import { getValueFromArrayOfObject } from "../../services/util";

const libraries = ["places"];

const imageCriteriaEventOwner = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };
const eventImageCriteria = { requiredSize: 6e6, requiredDimensions: { width: 1000, height: 800 } };

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

function WorkshopForm({
  button,
  video,
  title,
  poster,
  event,
  setEvent,
  onSubmit,
}) {

  const { userState } = useUserContext();
  const [autoComplete, setAutoComplete] = useState(null);
  const [fullAddress, setFullAddress] = useState(event?.eventLocation?.fullAddress || "");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
    region: "US",
  });

  const [loadLocation, setloadLocation] = React.useState([]);
  const [selectedMarket, setSelectedMarket] = React.useState(event?.cohatchMarket || null);
  const [selectedLocation, setSelectedLocation] = React.useState(event?.cohatchLocation || null);
  const [selectedMeetingRooms, setSelectedMeetingRooms] = React.useState(event?.cohatchMeetingRooms || null);


  const [market, setMarket] = React.useState(event?.cohatchMarket || null);
  const [location, setLocation] = React.useState(event?.cohatchLocation || null);
  const [meetingRooms, setMeetingRoom] = React.useState(event?.cohatchMeetingRooms || null);

  const handleMarketChange = (event) => {
    const { value } = event || {};
    setMarket(value);
    setSelectedMarket(value);
    setLocation(null);
    setMeetingRoom(null);
  };

  const handleLocationsChange = (event) => {
    const { value } = event || {};
    setLocation(value);
    setSelectedLocation(value);
    setMeetingRoom([null]);
  };

  const handleMeetingRoomsChange = (event) => {
    const { value } = event || {};
    setMeetingRoom(value);
    setSelectedMeetingRooms(value);
  };


  const generateCohatchMarket = () => {
    const cohatchMarket = [];

    for (const market in COHATCH_MARKETS) {
      cohatchMarket.push(COHATCH_MARKETS[market]);
    }
    return cohatchMarket.flat();
  };

  const generateCohatchMeetingRooms = () => {
    if (location) {
      // console.log(`inside the generateCohatchMeetingRooms and the location: ${location}`);
      const cohatchMeetingRooms = [];
      cohatchMeetingRooms.push(COHATCH_MEETING_ROOMS[location]["meeting_rooms"]);
      return cohatchMeetingRooms.flat();
    } else {
      return [];
    }
  };

  const generateCohatchLocation = () => {
    if (market) {
      const cohatchLocation = [];
      cohatchLocation.push(COHATCH_LOCATIONS[market]["locations"]);
      generateCohatchMeetingRooms();
      return cohatchLocation.flat();
    } else {
      return [];
    }
  };


  const onLoad = autoComplete => {
    setAutoComplete(autoComplete);
  };

  const handleUpload = result => {
    setEvent({
      ...event,
      eventImage: result,
    });
  };

  const handleOwnerImageUpload = result => {
    setEvent({
      ...event,
      eventOwnerImage: result,
    });
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete.getPlace();
      const {
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
      } = autoComplete.getPlace();
      if (formatted_address) {
        const addresses = formatted_address.split(",");
        setEvent({
          ...event,
          eventLocation: {
            ...event.eventLocation,
            fullAddress: formatted_address,
            streetAddress: addresses[0].trim(),
            city: addresses[1].trim(),
            state: addresses[2].trim().split(" ")[0].trim(),
            zip: addresses[2].trim().split(" ")[1].trim(),
            lat: lat(),
            lng: lng(),
          },
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleChange = (key, value) => {
    setEvent({
      ...event,
      [key]: value,
    });
  };

  const handleChangeAddress = (key, value) => {
    setEvent({
      ...event,
      eventLocation: {
        ...event.eventLocation,
        [key]: value,
      },
    });
  };

  const submitData = (key, value) => {
    setEvent((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    generateCohatchLocation();
  }, [market]);


  const onSubmitForm = () => {
    const eventData = {
      // ... other event data
      eventCOhatchMarket: selectedMarket,
      eventCOhatchLocation: selectedLocation,
      eventCOhatchMeetingRoom: selectedMeetingRooms,
    };
    console.log("Submitting data:", eventData);
    onSubmit();
  };

  return (
    <Popup trigger={button} modal nested position="right center">
      {close => (
        <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="relative max-h-md inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
                <div className="w-full flex justify-between">
                  <h1 className="mb-5 text-3xl text-success-800 font-bold capitalize">{title}</h1>
                  <button className="button" onClick={() => { close(); }} >
                    <FaWindowClose className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" alt="Close" />
                  </button>
                </div>

                <div className="w-full bg-white overflow-hidden shadow rounded-lg">
                  <div className="w-full px-4 py-5 sm:p-6 space-y-2">

                    <div className="col-span-6 sm:col-span-3">
                      <div className="w-full flex justify-between items-center">
                        <label htmlFor="workshop_title" className="block text-sm font-medium text-gray-700">
                          Workshop Title
                        </label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      </div>
                      <div className="mt-1">
                        <input
                          id="workshop-title"
                          name="workshop-title"
                          type="text"
                          value={event.eventName}
                          onChange={(evt) => {
                            handleChange("eventName", evt.target.value);
                          }}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          validation={{
                            required: {
                              value: true,
                              message: "required",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <p className="text-sm text-gray-300">30 characters max</p>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="w-full flex justify-between items-center">
                        <label htmlFor="workshop_category" className="block text-sm font-medium text-gray-700">
                          Workshop Category
                        </label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      </div>
                      <SelectInput
                        options={WORKSHOP_CATEGORY}
                        noOptionsMessage="No categories found"
                        placeholder="Select event type"
                        defaultValue={() => {
                          return event?.eventCategory ? WORKSHOP_CATEGORY.find(item => item.value === event?.eventCategory) : null;
                        }}
                        setSelectedValue={(event) => event ? submitData("eventCategory", event.value) : submitData("eventCategory", null)}
                      // onChange={console.log(event)}
                      />
                    </div>

                    <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:gap-3 sm:justify-between sm:items-center">
                      <div className="w-full col-span-6 sm:col-span-3">
                        <div className="w-full flex justify-between">
                          <label htmlFor="event_date" className="block text-sm font-medium text-gray-700">
                            Event Date
                          </label>
                          <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        </div>
                        <div className="mt-1">
                          <input
                            type="date"
                            name="event_date"
                            id="event_date"
                            aria-describedby="date-optional"
                            value={event.eventDate}
                            onChange={evt => {
                              setEvent({
                                ...event,
                                eventDate: evt.target.value,
                              });
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          />
                        </div>
                        <p className="text-sm text-gray-300"></p>
                      </div>
                      <div className="w-full col-span-6 sm:col-span-3">
                        <div className="w-full flex justify-between">
                          <label
                            htmlFor="startTime"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Start Time
                          </label>
                          <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        </div>
                        <input
                          type="time"
                          name="startTime"
                          id="startTime"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          onChange={(evt) => handleChange("startTime", evt.target.value)}
                          required
                        />
                      </div>

                      <div className="w-full col-span-6 sm:col-span-3">
                        <div className="w-full flex justify-between">
                          <label
                            htmlFor="endTime"
                            className="block text-sm font-medium text-gray-700"
                          >
                            End Time
                          </label>
                          <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        </div>
                        <input
                          type="time"
                          name="endTime"
                          id="endTime"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          onChange={(evt) => handleChange("endTime", evt.target.value)}
                          required
                        />
                      </div>

                    </div>
                    <div className="w-full">
                      <div className="w-full flex justify-between items-center">
                        <label htmlFor="about-event" className="block text-sm font-medium text-gray-700">
                          Details of Event
                        </label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      </div>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          value={event.eventDescription}
                          onChange={(evt) => handleChange("eventDescription", evt.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          defaultValue={" "}
                        />
                      </div>

                      <div className="sm:col-span-6 mt-2">
                        <label htmlFor="event-link" className="block text-sm font-medium text-gray-700">
                          Link for the Virtual Event
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                            http://
                          </span>
                          <input
                            type="text"
                            name="event-link"
                            id="event-link"
                            className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                            placeholder="www.example.com"
                            value={event?.eventLink || ""}
                            onChange={(evt) => setEvent({
                              ...event,
                              eventLink: evt.target.value
                            })}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                          Street address
                        </label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        <div className="mt-1">
                          {isLoaded &&
                            <Autocomplete
                              onLoad={onLoad}
                              onPlaceChanged={onPlaceChanged}
                            >
                              <input
                                type="text"
                                name="street-address"
                                id="name"
                                className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3"
                                aria-describedby="name-optional"
                                defaultValue={event.eventLocation.streetAddress}
                                onChange={event => setFullAddress(event.target.value)}
                              />
                            </Autocomplete>
                          }
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          City
                        </label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            value={event.eventLocation.city}
                            onChange={(evt) => handleChangeAddress("city", evt.target.value)}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                          State / Province
                        </label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="region"
                            id="region"
                            autoComplete="address-level1"
                            value={event.eventLocation.state}
                            onChange={(evt) => handleChangeAddress("state", evt.target.value)}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                          ZIP / Postal code
                        </label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            value={event.eventLocation.zip}
                            onChange={(evt) => handleChangeAddress("zip", evt.target.value)}
                            autoComplete="postal-code"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          />
                        </div>
                      </div>


                      <div className=" mt-6 text-center overflow-visible gap-x-6 bg-success-800 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                        <div className="text-center gap-x-4 gap-y-2">
                          <h2 className="mx-auto max-w-2xl font-bebas text-3xl font-bold tracking-wide text-white sm:text-4xl">
                            Need a place to meet?
                          </h2>
                          <p className="leading-6 text-white">
                            <strong className=" text-md font-bold font-roboto tracking-wide "> Please only select a COhatch meeting room if you have already booked through COhatch+ or the COhatch Member App.</strong>
                          </p>
                        </div>
                        
                        <p className="leading-6 mt-4 text-white text-center">
                          <strong className=" text-md font-bold font-roboto tracking-wide "> After booking your COhatch meeting room, please select from the following to have it added to your event.</strong>
                        </p>

                        <div className="w-full flex pt-4 flex-col sm:flex-row space-y-2 sm:space-y-0 sm:items-center sm:gap-4">


                          <div className="w-full">
                            <div className="w-full overflow-visible flex justify-between items-center">
                              <label htmlFor="category" className="block text-sm font-medium text-white">
                                COhatch City
                              </label>
                            </div>
                            <SelectInput
                              options={generateCohatchMarket()}
                              noOptionsMessage="No cities found"
                              placeholder="Select a COhatch City"
                              // defaultValue={() => {
                              // return market ? { value: market, label: COHATCH_LOCATIONS[market]["market"]["label"] } : null;
                              // }}
                              setSelectedValue={handleMarketChange}
                            />
                          </div>

                          <div className="w-full">
                            <div className="w-full flex justify-between items-center">
                              <label htmlFor="locations" className="block text-sm font-medium text-white">
                                COhatch Location
                              </label>
                            </div>
                            <SelectInput
                              options={generateCohatchLocation()}
                              noOptionsMessage="Select a COhatch Location"
                              placeholder="Select a COhatch Location"
                              // defaultValue={() => {
                              // return location ? { value: location, label: getValueFromArrayOfObject(COHATCH_LOCATIONS[market]["location"], { value: location }) } : null;
                              // }}
                              setSelectedValue={handleLocationsChange}
                            />
                          </div>

                          <div className="w-full">
                            <div className="w-full flex justify-between items-center">
                              <label htmlFor="meetingRoom" className="block text-sm font-medium text-white">
                                COhatch Meeting Room
                              </label>
                            </div>
                            <SelectInput
                              options={generateCohatchMeetingRooms()}
                              noOptionsMessage="Select a COhatch Meeting Room"
                              placeholder="Select a COhatch Meeting Room"
                              // defaultValue={() => {
                              // return meetingRooms ? { value: meetingRooms, label: getValueFromArrayOfObject(COHATCH_LOCATIONS[market][location]["meetingRooms"], { value: meetingRooms }) } : null;
                              // }}
                              setSelectedValue={handleMeetingRoomsChange}
                            />
                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="mt-3 w-3/4">
                      <p className="font-bold text-white">
                        <span className="text-4xl mt-3 text-white material-icons-outlined">campaign</span>
                        Community Managers and Market Leaders. If this a COhatch event, please select City and Location
                        <span className="text-4xl mt-3 text-white material-icons-outlined">campaign</span>
                      </p>
                    </div>
                  </div>



                  <div className="w-full pt-4 flex justify-between items-center">
                    <label htmlFor="event_image" className="block text-sm font-medium text-gray-700">
                      Add Event Image
                    </label>
                    <span className="w-2 h-2 rounded-full bg-red-500"></span>
                  </div>
                  <div className="relative border-4 border-dashed rounded-md h-[200px] w-full">
                    {event.eventImage?.small.url && <img src={event.eventImage.small.url} className="w-full h-full" alt="cover image upload" />}
                    <FileUploader
                      fileType="image/*"
                      isMultiple={false}
                      validationCriteria={eventImageCriteria}
                      DisplayIcon={PhotoDisplay}
                      accessToken={userState.accessToken}
                      folder="event_photos"
                      description="Image - Min: 1000 X 800, Max: 6MB"
                      numberOfFiles={1}
                      callback={handleUpload}
                    />
                  </div>

                  <div className="space-y-6 bg-white py-6 px-4 sm:p-1">
                    <div className="text-center">
                      <h3 className="text-lg font-medium leading-6 pt-8 text-success-800">Host and Organization Information</h3>
                    </div>
                    <div className="col-span-3">
                      <div className="w-full flex justify-between items-center mb-1">
                        <label className="block text-sm font-medium text-gray-700">Profile Image</label>
                        <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      </div>
                      <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                        {event?.eventOwnerImage?.small.url ? <img src={event?.eventOwnerImage.small.url} className="w-full h-full" alt="event owner image upload" /> : <p className="w-full pt-8 text-md text-center text-gray-400">Profile Image</p>}
                        <FileUploader
                          fileType="image/*"
                          isMultiple={false}
                          validationCriteria={imageCriteriaEventOwner}
                          DisplayIcon={PhotoDisplay}
                          accessToken={userState.accessToken}
                          folder="event_photos"
                          description="Image - Min: 400 X 400, Max: 5MB"
                          numberOfFiles={1}
                          callback={handleOwnerImageUpload}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <div className="w-full flex justify-between items-center">
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Host First Name
                          </label>
                          <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        </div>
                        <input
                          onChange={(event) => submitData("eventOwnerFirstName", event.target.value)}
                          type="text"
                          name="first_name"
                          id="first_name"
                          autoComplete="off"
                          value={event?.eventOwnerFirstName}
                          placeholder=""
                          aria-describedby="name-optional"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <div className="w-full flex justify-between items-center">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                            Host Last Name
                          </label>
                          <span className="w-2 h-2 rounded-full bg-red-500"></span>
                        </div>
                        <input
                          onChange={(event) => submitData("eventOwnerLastName", event.target.value)}
                          type="text"
                          name="last_name"
                          id="last_name"
                          autoComplete="off"
                          value={event?.eventOwnerLastName}
                          placeholder=""
                          aria-describedby="name-optional"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6">
                        <label htmlFor="about-organization" className="block text-sm font-medium text-gray-700">
                          About Host or Organization
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="about-organization"
                            name="about-organization"
                            rows={3}
                            placeholder="Please share a brief description of the host."
                            onChange={(event) => submitData("aboutOrganization", event.target.value)}
                            value={event?.aboutOrganization}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => {
                    onSubmitForm(); // Call the new onSubmitForm function
                    close();
                  }}
                  type="button"
                  className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                >
                  Submit
                </button>

              </div>
            </div>

          </div>
        </div>
      )
}
    </Popup >

  );
}

export default React.forwardRef(WorkshopForm);
