/*eslint-disable */
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../../hooks/useUserContext";
import getStoryById from "./getStoryById";
import Spinner from "../../../components/spinner/Spinner";
import { Link } from "react-router-dom";

function StoryPage() {
  const params = useParams();
  //const storyId = {params.id};
 const navigate = useNavigate();
 const [fetching, setFetching] = React.useState(false);
 const [story, setStory] = React.useState("");
 const {
    userState: { accessToken },
  } = useUserContext();

  
  const fetchStory = async () => {
 
    try {
      setFetching(true);
      const response = await getStoryById(accessToken, params.storyid);
   
      if (response.status === "success") {
        setFetching(false);
        const { data } = response;
        
        setStory(data);
    
      } else {
        setFetching(false);
        navigate("/error");
      }
    } catch (error) {
      setFetching(false);
      navigate("/error");
    }
  };

  
   React.useEffect(() => {
    fetchStory();
  }, []);


	return (
    <>
		{story === null || fetching ? (
      <Spinner displayText="Fetching..."/>
    ) : (
      <>
			<div className="bg-gradient-to-b from-white to-emerald-300 rounded-xl from-5%">
				<main className=" pb-20">
					{/* Hero section */}
					<div className="pt-10 mt-10 mx-auto max-w-2xl text-center">
						<h2 className="text-5xl text-blue-950 font-bold tracking-tight ">
							{story.blogTitle}
						</h2>
            <p className="text-xl leading-8 text-gray-600 pt-3">by {story.firstName} {story.lastName}</p>
					</div>
					<div className="pt-20 px-6 mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
						<h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
							{/* {location.state.orgName}  */}
						</h1>
						<div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 text-blue-950 sm:text-4xl">How did you use COhatch this year to support your
										organization?</h1>
							<p className="mt-6 text-2xl leading-8 text-gray-600">
								{story.prompts?.howWeHelped}
							</p>
						</div>
						<img
							src={story.coverImage?.coverImage?.small?.url ? story.coverImage.coverImage.small.url : "https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"}
							alt=""
							className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
						/>
					</div>

					{/* Prompt  */}
					<div className="mt-32 overflow-hidden sm:mt-40">
						<div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
							<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
								<div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
									<h2 className="text-3xl font-bold tracking-tight text-blue-950 sm:text-4xl">
										How did COhatch help you create connections, recieve funding, or any other related metrics this year?
									</h2>
									<p className="mt-6 text-2xl leading-8 text-gray-600">
								{story.prompts?.metrics}
									</p>
								</div>
								<div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
									<div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
										<img
											src={story.storyImages?.[0]?.storyImages?.[0]?.small?.url ? story.storyImages?.[0]?.storyImages?.[0]?.small?.url : "https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"}
											alt=""
											className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
										/>
									</div>
									<div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
										<div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
											<img
												src={story.storyImages?.[0]?.storyImages?.[1]?.small?.url ? story.storyImages?.[0]?.storyImages?.[1]?.small?.url : "https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&h=842&q=80"}
												alt=""
												className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
											/>
										</div>

										<div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
											<img
												src={story.storyImages?.[0]?.storyImages?.[2]?.small?.url ? story.storyImages?.[0]?.storyImages?.[2]?.small?.url : "https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"}
												alt=""
												className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
											/>
										</div>
										<div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
											<img
												src={story.storyImages?.[0]?.storyImages?.[3]?.small?.url ? story.storyImages?.[0]?.storyImages?.[3]?.small?.url : "https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"}
												alt=""
												className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Prompt 2 */}
					<div className="mt-32 overflow-hidden sm:mt-40">
						<div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
							<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
								<div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
									<h2 className="text-3xl font-bold tracking-tight text-blue-950 sm:text-4xl">
										How many times did you use the COhatch location?
									</h2>
									<p className="mt-6 text-2xl leading-8 text-gray-600">
										{story.prompts?.amountUsed}
									</p>
								</div>
								<div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
									<div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
										<img
											src={story.storyImages?.[0]?.storyImages?.[4]?.small?.url ? story.storyImages?.[0]?.storyImages?.[4]?.small?.url : "https://images.unsplash.com/photo-1517048676732-d65bc937f952?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
											alt=""
											className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Stats */}
					<div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
						<div className="mx-auto max-w-2xl lg:mx-0">
							<h2 className="text-3xl font-bold tracking-tight text-blue-950 sm:text-4xl">
								What would you like to add about COhatch
							</h2>
							<p className="mt-6 text-xl text-base leading-7 text-gray-600">
								{story.prompts?.feedback}
							</p>
						</div>
						
					</div>

					{/* Content section */}
					{/* <div className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
            
            </div>
            
            <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="bg-emerald-200/75 rounded-xl">
                  <div className="mx-auto max-w-7xl px-6 py-12 sm:py-32 lg:px-8 ">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      Be apart of the community.
                      <br/>
                      Share Your Impact Story.
                    </h2>
                    <div className="mt-10 flex items-center gap-x-6">
                      <Link
                        to="/philanthropy/impact_stories/add"
                        className="bg-orange-700 text-white rounded-md px-3.5 py-2.5 text-sm font-semibold"
                      >
                        Share
                      </Link>
                      <Link
                        to="/philanthropy/impact_stories"
                        className="text-sm font-semibold leading-6 text-gray-900"
                      >
                        Read More Stories<span aria-hidden="true">→</span>
                      </Link>
                    </div>
                  </div>
                </div>
              {posts.map((post) => (
                <article
                  key={post.id}
                  className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                >
                  <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                  <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                  <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                    <time dateTime={post.datetime} className="mr-8">
                      {post.date}
                    </time>
                    <div className="-ml-4 flex items-center gap-x-4">
                      <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <div className="flex gap-x-2.5">
                        <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                        {post.author.name}
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                </article>
              ))}
            </div>
          </div>
        </div> */}

				</main>
			</div>
		</>
    )}
    </>
	);
}

export default StoryPage;
