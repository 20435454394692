import React from "react";
import { format } from "date-fns";
import { useOutletContext } from "react-router-dom";
import useScrollPage from "../../../hooks/useScrollPage";

function About() {
  useScrollPage();
  const {group} = useOutletContext();
  return (
    <div className="w-full bg-white rounded-md shadow-md mt-5">
      <div className="w-full p-5 flex flex-col space-y-3">
        <div className="w-full flex-col space-y-1">
          <h1 className="text-lg font-bold text-success-800 capitalize">Description</h1>
          <p className="text-md text-gray-400">{group && group.description}</p>
        </div>
        <div className="w-full flex-col space-y-1">
          <h1 className="text-lg font-bold text-success-800 capitalize">Rules</h1>
          <p className="text-md text-gray-400">{group && group.rules ? group.rules : "No rules"}</p>
        </div>

        <div className="w-full flex-col space-y-1">
          <h1 className="text-lg font-bold text-success-800 capitalize">Created</h1>
          <p className="text-md text-gray-400">
            {format(new Date(group && group.createdAt), "MMMM do")} by {group && group.creator.firstName} {group && group.creator.lastName}
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
