import React from "react";
import { Outlet } from "react-router-dom";
import BreadCrumb from "./../../components/bread_crumb/BreadCrumb";
import useScrollPage from "../../hooks/useScrollPage";
import Tab from "./../../components/tab/Tab";


const directions = [
  { pathName: "Dashboard", url: "/dashboard" },
  { pathName: "Vacation Homes", url: "" },
];
const navigations = [
  { pathName: "All Homes", url: "", end: true },
  { pathName: "My Property", url: "my_property" },
  { pathName: "My Reviews", url: "my_reviews"},
  { pathName: "Visitor Reviews", url: "visitors_reviews" },
  { pathName: "Favorite Properties", url: "favorite_property" },
  { pathName: "Add a Property", url: "add_vacation_home",},
];

function VacationHomes() {
  useScrollPage();
 
  return (
    <div className="p-5 space-y-2">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">Vacation + Rental</h1>
        <p className="mt-1 text-md text-bold text-center text-success-800">
        Book your next vacation or rental from someone you know!
          </p>
      </div>
      <BreadCrumb directions={directions} />
      <Tab navigations={navigations} section="vacation_homes" />
      <Outlet />
    </div>
  );
}
export default VacationHomes;
