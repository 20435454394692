/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function createConversation(payload, accessToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/messaging/conversation`;

  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createConversation;
