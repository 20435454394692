/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function updateEvent(accessToken, payload, eventId) {
  console.log("entered update function", eventId);
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactevent/${eventId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "PATCH", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateEvent;