/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function searchAll(query, offset, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/search/marketplace?query=${query}&page=${offset}&limit=${limit}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default searchAll;
