function useTogglePassword(passwordRef, passwordVisibilityRef) {
  const togglePasswordVisibility = () => {
    if (passwordRef.current.type === "password") {
      passwordRef.current.type = "text";
      passwordVisibilityRef.current.textContent = "visibility_off";
    } else {
      passwordRef.current.type = "password";
      passwordVisibilityRef.current.textContent = "visibility";
    }
  };

  return [togglePasswordVisibility];
}

export default useTogglePassword;
