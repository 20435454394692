import React from "react";
import { Link } from "react-router-dom";

function LocalShopingFeature() {
  return (
    <div className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
      <div className="rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          <Link to="/marketplace/listings/makers_and_artisans" className="-m-3 p-3 block rounded-md hover:bg-gray-200 transition ease-in-out duration-150">
            <p className="text-base font-medium text-gray-900">Makers + Artisans</p>
          </Link>

          <Link to="/marketplace/listings/shops" className="-m-3 p-3 block rounded-md hover:bg-gray-200 transition ease-in-out duration-150">
            <p className="text-base font-medium text-gray-900">Shops</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LocalShopingFeature;
