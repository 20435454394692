
/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getImpactRSVPs(authToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactevent/impactattendee/rsvp`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, authToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getImpactRSVPs;