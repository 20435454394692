/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import LightBox from "../lightbox/LightBox";

function Media({ media, multiple, index, totalMedia, allMedia }) {
  return (
    <>
      {media.type === "image" && multiple ? (
        <LightBox
          medias={allMedia}
          initial={<img key={index} src={media.large.url} alt={media.large.filename} className={`w-full h-full object-center object-cover ${index === 3 && totalMedia - 4 ? "blur-sm" : ""}`} />}
        />
      ) : media.type === "video" && multiple ? (
        <LightBox
          medias={allMedia}
          initial={
            <video className={`w-full h-full object-center object-cover ${index === 3 && totalMedia - 4 ? "blur-sm" : ""}`} muted preload="auto" controls playsInline>
              <source src={media.url} type="video/mp4"></source>
              Your browser does not support this video format.
            </video>
          }
        />
      ) : media.type === "image" && !multiple ? (
        <LightBox medias={allMedia} initial={<img src={media.large.url} alt={media.large.filename} className="w-full h-full object-center object-cover" />} />
      ) : media.type === "video" && !multiple ? (
        <LightBox
          medias={allMedia}
          initial={
            <video className="w-full h-full object-center object-cover" muted controls preload="auto" playsInline>
              <source src={media.url} type="video/mp4"></source>
              Your browser does not support this video format.
            </video>
          }
        />
      ) : null}
    </>
  );
}

export default Media;
