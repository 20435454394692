/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */

const API_KEY = "K2I3AFCTPBJLWBR7KIJZ";
const API_ENDPOINT = "https://www.eventbriteapi.com/v3/organizations/208000749055/events/?order_by=start_asc&status=live";

const makeAPIRequest = apiUrl => {
  const url = apiUrl;
  const init = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${API_KEY}`,
    },
    mode: "cors",
    method: "GET",
  };

  return window.fetch(url, init);
};

async function getEvents(query) {
  let url = API_ENDPOINT;
  Object.keys(query).forEach(key => {
    if (query[key]) {
      url += `&${key}=${query[key]}`;
    }
  });
  const response = await makeAPIRequest(url);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getEvents;
