/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Conversations from "../../components/conversations/Conversations";
import ConversationHeader from "../../components/conversation_header/ConversationHeader";
import CreateMessage from "../../components/create_message/CreateMessage";
import { useParams, useNavigate } from "react-router-dom";
import DefaultState from "../../components/default_state/DefaultState";
import useWindowSize from "./../../hooks/useWindowSize";
import Message from "../../components/message/Message";
import useUserContext from "../../hooks/useUserContext";
import useWebsocket from "../../hooks/useWebsocket";
import notify from "../../services/toast";
import getMessages from "./getMessages";
import useScrollPage from "../../hooks/useScrollPage";
import useConversationContext from "../../hooks/useConversationContext";
import getConversation from "./getCurrentConversation";

function Messaging() {
  useScrollPage();
  const { userState } = useUserContext();
  const { conversationId } = useParams();
  const [showMessages, setShowMessages] = React.useState(true);
  const [messages, setMessages] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [newConversation, setNewConversation] = React.useState(false);
  const { setCurrentConversation } = useConversationContext();
  const bottomDiv = React.useRef(null);

  const { width } = useWindowSize();
  const socket = React.useRef(useWebsocket(userState.accessToken));

  const handleGetMessages = async () => {
    try {
      setFetching(true);
      const response = await getMessages(userState.accessToken, conversationId, 0, 10);
      if (response.status === "success") {
        setMessages(response.data.results);
      } else {
        notify("An error occurred while getting messages. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const handleGetConversation = async () => {
    try {
      setFetching(true);
      const response = await getConversation(userState.accessToken, conversationId);
      if (response.status === "success") {
        setCurrentConversation(response.data[0]);
      } else {
        notify("An error occurred while getting conversation. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const handleIncomingMessages = messages => {
    const messagesNotRelated = messages.filter(message => message.conversationId !== conversationId);
    if (messagesNotRelated.length) {
      setNewConversation(!newConversation);
    }
  };

  React.useEffect(() => {
    socket.current.on("message", data => {
      setMessages(prev => {
        return [...prev, data];
      });
    });
    socket.current.on("message_error", data => {
      const { message } = data;
      notify(message, "error");
    });
  }, []);

  React.useEffect(() => {
    if (messages.length && conversationId) {
      bottomDiv.current.scrollIntoView({ behavior: "smooth" });
    }
    handleIncomingMessages(messages);
  }, [messages]);

  React.useEffect(() => {
    if (conversationId) {
      handleGetMessages();
      handleGetConversation();
    }
  }, [conversationId]);

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      socket.current.emit("status", { senderId: userState.id, status: "active" });
    }, 5000);

    socket.current.on("message_notification", data => {
      console.log(data);
    });

    return () => {
      clearInterval(timer);
    };
  }, [socket]);
  return (
    <div className="flex h-full fixed pt-16">
      {width <= 912 && conversationId && showMessages ? (
        <div className="w-screen h-full">
          {conversationId ? (
            <>
              <div className="h-auto">
                <ConversationHeader setShowMessages={setShowMessages} socket={socket} />
              </div>
              <div className="h-[calc(100vh-315px)] overflow-y-auto scrollbar-hide">
                <p className="text-sm text-gray-500 w-full flex gap-2 p-3">
                  <span className="material-icons-outlined text-sm">enhanced_encryption</span>
                  Messages are encrypted before being sent to our servers
                </p>
                {messages.length
                  ? messages
                      .filter(message => message.conversationId === conversationId)
                      .map((message, index) => {
                        return <Message key={index} sender={true} message={message} getMessages={handleGetMessages} />;
                      })
                  : null}
                <div ref={bottomDiv}></div>
              </div>
              <div className="h-auto">
                <CreateMessage socket={socket} />
              </div>
            </>
          ) : (
            <DefaultState icon="chat" heading="No messages found." description="Select a conversation to see messages." />
          )}
        </div>
      ) : width >= 640 ? (
        <>
          <div className="w-screen sm:w-96 h-full">
            <Conversations setShowMessages={setShowMessages} newConversation={newConversation} />
          </div>
          <div className="w-full sm:w-[calc(100vw-644px)] h-full">
            {conversationId ? (
              <>
                <div className="h-auto">
                  <ConversationHeader setShowMessages={setShowMessages} socket={socket} />
                </div>
                <div className="h-[calc(100vh-315px)] w-full overflow-y-auto scrollbar-hide">
                  <p className="text-sm text-gray-500 w-full flex gap-2 justify-center">
                    <span className="material-icons-outlined text-sm">enhanced_encryption</span>
                    Messages are encrypted before being sent to our servers
                  </p>
                  {messages.length
                    ? messages
                        .filter(message => message.conversationId === conversationId)
                        .map((message, index) => {
                          return <Message key={index} sender={true} message={message} getMessages={handleGetMessages} />;
                        })
                    : null}
                  <div ref={bottomDiv}></div>
                </div>
                <div className="h-auto">
                  <CreateMessage socket={socket} />
                </div>
              </>
            ) : (
              <DefaultState icon="chat" heading="No messages found" description="Select a conversation to see messages." />
            )}
          </div>
        </>
      ) : (
        <div className="w-screen sm:w-96 h-full">
          <Conversations setShowMessages={setShowMessages} newConversation={newConversation} />
        </div>
      )}
    </div>
  );
}

export default Messaging;
