 import React from "react";
 import ButtonPrimary from "../../components/button/ButtonPrimary";
 import { useNavigate } from "react-router-dom";



function ApplicationSuccess() {

  const navigate = useNavigate();
  const goHome = () => {
    navigate("/dashboard/business_hub/my_applications");
  };


  return(

     
               <div className="h-screen flex items-center justify-center bg-white ">
                <div className="max-w-xl  ">
                  <div className="flex items-center justify-center">
                  <div className="material-icons-outlined text-primary-400 text-9xl">check_circle</div>
                  </div>
                  <div className="mt-5 mb-10">
              <h1 className="text-lg font-bold">
                Scholarship application successfully submitted. Our team will reach out to you in 3-4 business days.
              </h1>
              </div>
             <div className="m-20">
                <ButtonPrimary size="md" text="Return Home" action={goHome}
                />
                </div>
              </div>
               
              </div>
            
  
  );
}

export default ApplicationSuccess;
