/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "./../../components/logo/Logo";
import Alert from "../../components/alert/Alert";
import useScrollPage from "../../hooks/useScrollPage";

function SubscriptionInfo() {
  useScrollPage();
  return (
    <div className="h-screen w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-[url('https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/background-image.png')] bg-no-repeat bg-cover">
      <div className="flex justify-center">
        <Logo color="green" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md space-y-5">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-400">Subscription Inactive</h2>
        <Alert
          color="green"
          heading="There is an issue with your subscription."
          body={[
            "Your subscription may be  pending. Please try using the link below to access the dashboard.",
            "If you still can't access the dashboard after trying using the link below, contact support@cohatchplus.com.",
          ]}
          direction="/dashboard"
          directionName="Go to Dashboard"
        />
      </div>
      <div className="p-5 text-xs text-gray-500 text-center">&copy; COhatch/COhatch+ All rights reserved</div>
    </div>
  );
}

export default SubscriptionInfo;
