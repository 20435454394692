/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { MdOutlineCancel } from "react-icons/md";

function Preview({ file, handleRemoveFile, uploading, fileType }) {
  const isValid = Object.keys(file.error).length ? false : true;
  const onRemove = () => {
    handleRemoveFile(file.derivedName);
  };

  return (
    <>
      {fileType === "image/*" ? (
        <div className={`h-fit w-fit bg-white shadow rounded-md border-2 ${isValid ? "border-primary-400" : "border-red-400"}`}>
          <div className="w-full flex justify-end">{uploading ? null : <MdOutlineCancel className="text-lg cursor-pointer" onClick={onRemove} />}</div>
          <div className="relative w-[200px] h-[200px]">
            <img src={file.image.src} alt={file.derivedName} className="object-contain h-full w-full peer hover:brightness-50 hover:blur-sm" />
            <p className={`invisible absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md font-extrabold peer-hover:visible ${isValid ? "text-primary-500" : "text-red-500"}`}>
              ~ {(file.size / 1e6).toFixed(2)}MB
            </p>
          </div>
        </div>
      ) : (
        <div className={`h-fit w-fit bg-white shadow rounded-md border-2 ${isValid ? "border-primary-400" : "border-red-400"}`}>
          <div className="w-full flex justify-end">{uploading ? null : <MdOutlineCancel className="text-lg cursor-pointer" onClick={onRemove} />}</div>
          <div className="relative w-[200px] h-[200px]">
            <video className="h-full w-full peer hover:brightness-50 hover:blur-sm" autoPlay loop muted preload="auto" playsInline>
              <source src={file.source} type="video/mp4"></source>
              Your browser does not support this video format
            </video>
            <p className={`invisible absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md font-extrabold peer-hover:visible ${isValid ? "text-primary-500" : "text-red-500"}`}>
              ~ {(file.size / 1e6).toFixed(2)}MB
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(Preview);
