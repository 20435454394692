/* eslint-disable no-unused-vars */
import React from "react";
import { Outlet, Link } from "react-router-dom";
import BreadCrumb from "./../../components/bread_crumb/BreadCrumb";
import Tab from "./../../components/tab/Tab";

const directions = [
	{ pathName: "Dashboard", url: "/dashboard" },
	{ pathName: "Marketplace", url: "" },
];

const navigations = [
	{ pathName: "Marketplace Feed", url: "", end: true },
	
	//{ pathName: "My Reviews", url: "my_review" },
	//{ pathName: "Visitor Reviews", url: "visitor_review" },
	

	{ pathName: "Local Discount", url: "local_discount" },
	{ pathName: "My Listings", url: "my_listing" },
	// { pathName: "My Reviews", url: "my_review" },
	// { pathName: "Visitor Reviews", url: "visitor_review" },
	{ pathName: "Listing Analytics", url: "listing_analytics" },

	//{ pathName: "Add Listing", url: "add_listing" },
];

function MarketPlace() {
	return (
		<>
			<div className="p-5 space-y-2">
				{/* <div className="flex-1 min-w-0 flex justify-center items-center">
					<h1 className="text-2xl font-bold leading-7 text-primary-400 font-bebas sm:text-4xl sm:truncate text-center">
						Member Directory
					</h1>
				</div> */}
				{/* <div className="flex-1 min-w-0 flex justify-center items-center">
					<Link
						to="/marketplace"
						className="flex gap-2 items-center text-primary-400 font-bold"
					>
						<span className="material-icons-outlined">storefront</span>
						<span className="underline"> Go to Marketplace</span>
					</Link>
				</div> */}
				{/* <BreadCrumb directions={directions} /> */}
				{/* <Tab navigations={navigations} section="marketplace" /> */}
				<Outlet />
			</div>
		</>
	);
}

export default MarketPlace;
