/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import Onboarding from "../../../components/onboarding/Onboarding";
import { Link } from "react-router-dom";
import useUserContext from "./../../../hooks/useUserContext";
import { greetings, computeProfilePercentage } from "../../../services/util";
import { MAGIC_NUMBERS, STATUS } from "../../../constant";
import CircularProgressBar from "../../../components/circular_progress_bar/CircularProgressBar";
// import MarketplaceDiscountsAd from "../../../assets/MarketplaceDiscountsAd.png";
import useTitle from "../../../hooks/useTitle";
import useScrollPage from "../../../hooks/useScrollPage";
import Footer from "../../../components/footer/Footer";
import featured from "../../local_discount/featured";
import notify from "../../../services/toast";
import DefaultState from "../../../components/default_state/DefaultState";
import Spinner from "../../../components/spinner/Spinner";
import CouponCard from "../../../components/coupon_card/CouponCard";
import FeaturedEventsDashboard from "../../../components/featured_events_dashboard/FeaturedEvents";
import getFeaturedEvents from "../../../components/lifeperks/getFeaturedEvents";
import LifePerksFeed from "../../life_perks/life_perks_feed/LifePerksFeed";
import { MdHelp, MdEditDocument, MdCheckCircle } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import HelpWidgets from "../../../components/help_widgets/HelpWidgets";
import Modal from "../../../components/modal/Modal";
import { MdClear } from "react-icons/md";



function DashboardHome() {
  useScrollPage();
  useTitle("COhatch+ Dashboard");

  const { userState } = useUserContext();
  const profilePercentage = computeProfilePercentage(userState);
  const { firstName, lastName, photo, status, onboarding } = userState;

  const [discounts, setDiscounts] = React.useState([]);
  const [isDiscountLoading, setIsDiscountLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({ limit: 6, total: 0, page: 0, remaining: 0 });
  const [onboardingModal, setOnboardingModal] = React.useState(false);

  const [isFeaturedEventsLoading, setIsFeaturedEventsLoading] = React.useState(true);
  const [featuredEvents, setFeaturedEvents] = React.useState([]);

  const fetchDiscounts = async (accessToken, page, _keyword, _category) => {
    setIsDiscountLoading(true);
    const response = await featured(
      {
        page,
        keyword: _keyword,
        category: _category,
        limit: pagination.limit,
      },
      accessToken
    );
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setDiscounts(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsDiscountLoading(false);
  };

  const fetchFeaturedEvents = async () => {
    try {
      setIsFeaturedEventsLoading(true);
      const response = await getFeaturedEvents(userState.accessToken);
      if (response.status === "success") {
        setFeaturedEvents(response.data);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setIsFeaturedEventsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDiscounts(userState.accessToken, 0, "", "");
    fetchFeaturedEvents();
  }, []);

  return (
    <main>
      {onboardingModal && (
        <Modal>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 space-y-4">
                <div className="w-full flex justify-between items-center">
                  <MdClear className="text-2xl cursor-pointer text-success-800" onClick={() => setOnboardingModal(false)} />
                </div>
                <Onboarding setOnboardingModal={setOnboardingModal} />
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="py-6">
        <div className="flex items-center px-8">
          <div className="w-full flex justify-between items-start">
            <div>
              <div className="flex items-center">
                <img
                  src={photo?.small?.url ? photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                  alt={photo?.small?.filename ? photo.small.filename : "Profile Photo"}
                  className="inline-block rounded-full border border-3 object-cover h-[64px] w-[64px]"
                />
                <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate capitalize">
                  {greetings()}, {firstName} {lastName}
                </h1>
              </div>
              
            </div>
            {/* <div className="hidden sm:block flex-col justify-center items-center">
              <Link to="settings" className="block text-sm text-success-800 underline">
                <CircularProgressBar percentage={profilePercentage} width={80} fill="white" primaryColor={profilePercentage > 80 ? ["#56c271", "#56c271"] : ["#fa5f1f", "#fa5f1f"]} />
              </Link>
            </div> */}
          </div>
        </div>
        <div>
          <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4 pt-4 px-4">
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md bg-success-800 text-sm font-medium text-white">
                <svg className="flex-shrink-0 mr-1.5 h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-4 text-sm">
                  <Link to="business_hub" className="font-medium text-gray-900 hover:text-gray-600">Add a Business Listing</Link>
                </div>
              </div>
            </li>
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md bg-success-50 text-sm font-medium text-white">
                <MdEditDocument className="flex-shrink-0 mr-1.5 h-8 w-8 text-white" />
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-4 text-sm">
                  <a href="dashboard/business_hub/my_applications" className="font-medium text-gray-900 hover:text-gray-600">Apply for Scholarship</a>
                </div>
              </div>
            </li>
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div
                className={`flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white ${status === STATUS.ACTIVE ? "bg-primary-400" : "bg-red-400"}`}
              >
                {status === STATUS.ACTIVE ? (
                  <MdCheckCircle className="flex-shrink-0 mr-1.5 h-8 w-8 text-white" />
                ) : (
                  <IoMdCloseCircle className="flex-shrink-0 mr-1.5 h-8 w-8 text-white" />
                )}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-4 text-sm">
                  <dd onClick={() => setOnboardingModal(true)}
                    className="mt-3 flex gap-1 items-center text-sm text-gray-600 font-medium sm:mr-6 sm:mt-0 capitalize cursor-pointer ">
                    {status === STATUS.ACTIVE ? (
                      <>Verified account</>
                    ) : (
                      <>Verify Your Account</>
                    )}
                  </dd>
                </div>
              </div>
            </li>
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md bg-yellow-400 text-sm font-medium text-white">
                <MdHelp className="flex-shrink-0 mr-1.5 h-8 w-8 text-white" />
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-4 text-sm">
                  <HelpWidgets
                    button={<button type="button">Help</button>}
                    title="HELP"
                  />
                </div>
              </div>
            </li>
          </ul>


        </div>

        {/* <Onboarding onboarding={onboarding} status={status} /> */}


        <LifePerksFeed />

        {/* <div className="max-w-full mx-auto p-1 text-center space-y-4">
            <h1 className="text-4xl text-success-800 font-bold font-bebas">FEATURED DISCOUNTS</h1>
            <div className="w-full flex gap-6">
              {!discounts.length ? (
                <div className="w-full h-full flex justify-center">
                  <DefaultState icon="discount" heading="No featured discounts" description="No featured discounts available" />
                </div>
              ) : (
                <div className="w-full">
                  {isDiscountLoading ? (
                    <Spinner displayText="Loading..." />
                  ) : (
                    <div className="mt-5 w-full flex gap-4 flex-wrap px-5">
                      {discounts.map((discount, index) => (
                        <CouponCard
                          key={discount._id}
                          coupon={discount}
                          fid={discount.favorites[0] ? discount.favorites[0]._id : ""}
                          isFavorite={!!discount.favorites.length}
                          isOwner={userState.id === discount.creator._id}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div> */}


        {/* <div className="max-w-full mx-auto p-1 text-center space-y-4">
            <h1 className="text-4xl text-success-800 font-bold font-bebas">FEATURED EVENTS</h1>
            <div className="w-full flex gap-6">
              {!featuredEvents.length ? (
                <div className="w-full h-full flex justify-center">
                  <DefaultState icon="discount" heading="No featured events" description="No featured events available" />
                </div>
              ) : (
                <div className="w-full">
                  {isFeaturedEventsLoading ? (
                    <Spinner displayText="Loading..." />
                  ) : (
                    <div className="mt-5 w-full flex gap-4 flex-wrap px-5">

                      <FeaturedEventsDashboard featuredEvents={featuredEvents} />

                      {featuredEvents.map((event) => (
                        <FeaturedEventsDashboard key={event._id} posts={featuredEvents}/>
                      ))} 
                    </div>
                  )}
                </div>
              )}
            </div>
          </div> */}

        {/* <div className="max-w-full mx-auto p-1 text-center space-y-4">
            <h1 className="text-4xl text-success-800 font-bold font-bebas">FEATURED EVENTS</h1>
            <FeaturedEventsDashboard />
          </div> */}


      </div>

      {/* <Footer /> */}
    </main>
  );
}

export default DashboardHome;
