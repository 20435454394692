/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
import React from "react";
import useUserContext from "../../hooks/useUserContext";
import { Check, ThumbUp, User } from "heroicons-react";
import FeedList from "../feed_list/FeedList";
import MarketplaceCard from "../marketplace_card/MarketplaceCard";
import { Link } from "react-router-dom";
import MemberCard from "../member_card/MemberCard";
import DefaultState from "../default_state/DefaultState";
import getMostRecentVendor from "./getMostRecentVendor";
import notify from "../../services/toast";
import getListingNearMe from "./getListingNearMe";
import getSuggestedListing from "./getSuggestedListing";
// import getMostRecentReview from "./getMostRecentReview";
// import getMostRecentMessage from "./getMostRecentMessage";
import getRecentMembers from "./getMostRecentMember";
// import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";

function buildTimeLine(recentListing, recentReview, member, recentMessage) {
  const timeLine = [];
  if (recentListing) {
    timeLine.push({
      id: recentListing._id,
      content: "New listing ",
      target: recentListing.businessName.toUpperCase(),
      href: `/marketplace/listing/${recentListing._id}`,
      date: recentListing.createdAt,
      icon: User,
      iconBackground: "bg-gray-400",
    });
  }

  if (recentReview) {
    timeLine.push({
      id: recentReview._id,
      content: "Review on the listing ",
      target: recentReview.listing.businessName.toUpperCase(),
      href: `/marketplace/listing/${recentReview.listing._id}`,
      date: recentReview.createdAt,
      icon: ThumbUp,
      iconBackground: "bg-blue-500",
    });
  }

  if (member) {
    timeLine.push({
      id: member.user._id,
      content: "New Member ",
      target: `${member.user.firstName} ${member.user.lastName}`,
      href: "/dashboard/neighborhood/members",
      date: member.user.createdAt,
      icon: Check,
      iconBackground: "bg-green-500",
    });
  }

  if (recentListing) {
    timeLine.push({
      id: recentListing.user[0]._id,
      content: "New Vendor ",
      target: `${recentListing.user[0].firstName} ${recentListing.user[0].lastName}`,
      href: `/marketplace/listing/${recentListing._id}`,
      date: recentListing.user[0].createdAt,
      icon: Check,
      iconBackground: "bg-green-500",
    });
  }

  if (recentMessage) {
    timeLine.push({
      id: 4,
      content: "New Message from the listing ",
      target: recentMessage.listing.businessName.toUpperCase(),
      href: "/dashboard/messaging",
      date: recentMessage.createdAt,
      icon: ThumbUp,
      iconBackground: "bg-blue-500",
    });
  }

  return timeLine;
}

function MarketPlaceFeed() {
  useScrollPage();
  useTitle("Marketplace Feed");

  const { userState } = useUserContext();
  const { firstName, photo } = userState;
  const [listings, setListings] = React.useState([]);
  const [listingNear, setListingNear] = React.useState([]);
  const [suggestedListing, setSuggestedListing] = React.useState([]);
  const [review, setReview] = React.useState([]);
  const [message, setMessage] = React.useState([]);
  const [member, setMember] = React.useState([]);

  const getMostRecentVendors = async () => {
    try {
      const response = await getMostRecentVendor(userState.accessToken);
      if (response.status === "success") {
        setListings(response.data);
      }
    } catch (error) {
      // notify("Something went wrong. Please try again later", "error");
    }
  };

  const getListingNear = async () => {
    try {
      const response = await getListingNearMe(userState.accessToken);
      if (response.status === "success") {
        setListingNear(response.data);
      }
    } catch (error) {
      // notify("Something went wrong. Please try again later", "error");
    }
  };

  const getSuggestedListings = async () => {
    try {
      const response = await getSuggestedListing(userState.accessToken);
      if (response.status === "success") {
        setSuggestedListing(response.data);
      }
    } catch (error) {
      // notify("Something went wrong. Please try again later", "error");
    }
  };

  // const getMostRecentReviews = async () => {
  //   try {
  //     const response = await getMostRecentReview(userState.accessToken);
  //     if (response.status === "success") {
  //       setReview(response.data);
  //     }
  //   } catch (error) {
  //     // notify("Something went wrong. Please try again later", "error");
  //   }
  // };

  // const getMostRecentMessages = async () => {
  //   try {
  //     const response = await getMostRecentMessage(userState.accessToken);
  //     if (response.status === "success") {
  //       setMessage(response.data);
  //     }
  //   } catch (error) {
  //     // notify("Something went wrong. Please try again later", "error");
  //   }
  // };

  const getMostRecentMember = async () => {
    try {
      const response = await getRecentMembers(userState.accessToken);
      if (response.status === "success") {
        setMember(response.data);
      }
    } catch (error) {
      // notify("Something went wrong. Please try again later", "error");
    }
  };

  React.useEffect(() => {
    getMostRecentVendors();
    getListingNear();
    getSuggestedListings();
    // getMostRecentReviews();
    // getMostRecentMessages();
    getMostRecentMember();
    const timer = window.setInterval(() => {
      getMostRecentVendors();
      getListingNear();
      getSuggestedListings();
      // getMostRecentReviews();
      // getMostRecentMessages();
      getMostRecentMember();
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    
      <div className="relative max-w-full mx-auto bg-white shadow rounded-lg p-4 space-y-8">
       
          {/* <div className="flex items-center">
        <img
          src={photo?.small?.url || "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
          alt={photo?.small?.filename || "Profile Photo"}
          className="inline-block rounded-full border border-3 object-cover h-[64px] w-[64px]"
        />
        <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-success-800 sm:leading-9 sm:truncate">
          {firstName}, you missed:
        </h1>
      </div> */}

          <FeedList timelines={buildTimeLine(listings[0], review[0], member[0], message[0])} />

          {/* <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">LISTINGS NEAR ME</h1>
          <div className="h-full w-full">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative justify-center w-full grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                {listingNear.map((listing, index) => (
                  <MarketplaceCard key={index} listing={listing} deleteListing={null} editListing={null} />
                ))}
              </div>
            </div>
          </div>
          <div className="z-20 flex flex-col">
            <div className="mt-2 flex flex-col px-3 py-3 space-y-4 sm:space-y-0 sm:mx-auto sm:gap-5">
              <Link
                to="/marketplace"
                className="flex items-center justify-center px-3 py-2 border border-transparent text-xl font-bold rounded-md shadow-sm text-white bg-primary-500 bg-opacity-100 hover:bg-opacity-70 sm:px-8 transition ease-linear duration-500"
              >
                See More
              </Link>
            </div>
          </div> */}

          <h1 className=" text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9  l:truncate">SUGGESTED LISTINGS</h1>
          <div className="h-full w-full">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-0">
              <div className="relative justify-center w-full grid grid-cols-1 gap-12 ">
                {suggestedListing.map((listing, index) => (
                  <MarketplaceCard key={index} listing={listing} deleteListing={null} editListing={null} />
                ))}
              </div>
            </div>
          </div>

          {/* <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">FEATURED VENDORS</h1>
          <div>
            <ul role="list" className="relative justify-center w-full grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
              {listings.length ? (
                listings.map((people, index) => <MemberCard key={index} member={people.user[0]} />)
              ) : (
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <DefaultState icon="person" heading="No Members Found" description="Premium and COhatch members will appear here" />
                </div>
              )}
            </ul>
          </div> */}
      
      </div>
    
  );
}

export default MarketPlaceFeed;
