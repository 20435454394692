/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React from "react";
import { Outlet } from "react-router-dom";
import BreadCrumb from "./../../components/bread_crumb/BreadCrumb";
import useScrollPage from "../../hooks/useScrollPage";
import Tab from "./../../components/tab/Tab";

// const directions = [
//   { pathName: "Dashboard", url: "/dashboard" },
//   { pathName: "Workshops", url: "" },
// ];

// const navigations = [
//   { pathName: "Events Calendar", url: "", end: true },
//   { pathName: "My Events", url: "my_workshops" },
//   { pathName: "Events Attending", url: "workshop_rsvp" },
// ];

function Workshops() {

  useScrollPage();
  return (
    <div className="p-5 space-y-2">
      <div className="flex-1 min-w-0">
        <h1 className="text-2xl font-bold leading-7 text-primary-500 font-bebas sm:text-4xl sm:truncate text-center">Workshops</h1>
      </div>
      {/* <BreadCrumb directions={directions} />
      <Tab navigations={navigations} /> */}
      <Outlet />
    </div>
  );
}

export default Workshops;