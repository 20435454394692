/* eslint-disable capitalized-comments */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import ButtonPrimary from "./../../components/button/ButtonPrimary";

function TimeCard({ dayOfWeek, setListing, listing, hour }) {
  const [selected, setSelected] = React.useState(() => {
    return hour ? true : false;
  });

  const [openTime, setOpenTime] = React.useState(() => {
    return hour ? hour.open : "";
  });
  const [closeTime, setCloseTime] = React.useState(() => {
    return hour ? hour.close : "";
  });
  const [roundClock, setRoundClock] = React.useState(() => {
    return hour ? hour.round : "";
  });

  const roundClockRef = React.useRef(null);
  const closedRef = React.useRef(null);

  const handleConfirmation = () => {
    if ((openTime && closeTime) || roundClock) {
      setListing(prevState => {
        const newObj = Object.assign(prevState?.businessHours || {}, { [dayOfWeek]: { close: closeTime, open: openTime, round: roundClock } });
        return { ...prevState, businessHours: newObj };
      });
      setSelected(true);
    }
  };

  const handleReset = () => {
    setOpenTime("");
    setCloseTime("");
    setRoundClock("");
    roundClockRef.current.checked = false;
    closedRef.current.checked = false;
    delete listing.businessHours[dayOfWeek];
    setListing(prevState => {
      return { ...prevState, listing: Object.keys(listing).length ? listing : null };
    });
    setSelected(false);
  };

  React.useEffect(() => {
    setSelected(false);
  }, [openTime, closeTime, roundClock]);
  return (
    <div className="w-full flex flex-col space-y-5 border border-solid p-5 rounded-md">
      <div className="w-full flex justify-between items-center">
        <h1 className="w-full text-2xl font-bold text-success-800">{dayOfWeek}</h1>
      </div>
      <div className="w-full flex flex-wrap gap-5 items-end justify-start md:flex-nowrap md:justify-center">
        <div className="w-full">
          <div className="w-full flex justify-between">
            <label htmlFor="openTime" className="block text-sm font-medium text-gray-700">
              Open Time
            </label>
          </div>
          <div className="mt-1">
            <input
              onChange={event => setOpenTime(event.target.value)}
              type="time"
              name="openTime"
              id="openTime"
              value={openTime}
              disabled={roundClock ? true : false}
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3"
              placeholder="04/23/2022"
              aria-describedby="date-optional"
            />
          </div>
        </div>
        <div className="w-full">
          <div className="w-full flex justify-between">
            <label htmlFor="closeTime" className="block text-sm font-medium text-gray-700">
              Close Time
            </label>
          </div>
          <div className="mt-1">
            <input
              onChange={event => setCloseTime(event.target.value)}
              type="time"
              name="closeTime"
              id="closeTime"
              value={closeTime}
              disabled={roundClock ? true : false}
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md p-3"
              placeholder="04/23/2022"
              aria-describedby="date-optional"
            />
          </div>
        </div>
        <div className="w-full flex justify-between items-center gap-3">
          <label className="border p-3 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none rounded-md">
            <div className="flex items-center text-sm">
              <input
                disabled={openTime || closeTime ? true : false}
                ref={roundClockRef}
                onChange={event => setRoundClock(event.target.value)}
                type="radio"
                name={`${dayOfWeek}-roundClock`}
                value="24"
                checked={roundClock !== "" && roundClock === "24" ? true : false}
                className="h-4 w-4 text-primary-400 border-gray-300 focus:ring-primary-500"
                aria-labelledby="pricing-plans-0-label"
                aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1"
              />
              <span className="ml-3 font-medium">24hrs</span>
            </div>
          </label>

          <label className="border p-3 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none rounded-md">
            <div className="flex items-center text-sm">
              <input
                disabled={openTime || closeTime ? true : false}
                ref={closedRef}
                onChange={event => setRoundClock(event.target.value)}
                type="radio"
                name={`${dayOfWeek}-roundClock`}
                value="Closed"
                checked={roundClock !== "" && roundClock === "Closed" ? true : false}
                className="h-4 w-4 text-primary-400 border-gray-300 focus:ring-primary-500"
                aria-labelledby="pricing-plans-0-label"
                aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1"
              />
              <span className="ml-3 font-medium">Closed</span>
            </div>
          </label>
          {((openTime && closeTime) || roundClock) && !selected ? (
            <div className="flex">
              <ButtonPrimary text="save" size="md" action={handleConfirmation} />
            </div>
          ) : selected ? (
            <div className="flex">
              <ButtonPrimary icon="restart_alt" size="md" action={handleReset} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

TimeCard.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  setListing: PropTypes.func.isRequired,
  hour: PropTypes.object,
  listing: PropTypes.object.isRequired,
};

export default React.memo(TimeCard);
