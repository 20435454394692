/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";

function GroupAvatar({ images = [], dimensions = "h6 w-6" }) {
  return (
    <div className="flex -space-x-1 relative z-0 overflow-hidden">
      {images.map((image, index) => (
        <img
          key={`group-avatar-${index}`}
          className={`relative z-${(3 - index) * 10} ${dimensions} inline-block rounded-full ring-2 ring-white`}
          src={image ? image?.small?.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
          alt=""
        />
      ))}
    </div>
  );
}

export default GroupAvatar;
