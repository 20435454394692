/* eslint-disable capitalized-comments */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "../../components/logo/Logo";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ButtonTertiary from "./../../components/button/ButtonTertiary";
import GroupAvatar from "../../components/group_avatar/GroupAvatar";
import GroupNav from "../../components/group_nav/GroupNavigation";
import { useParams, useNavigate, Link, Outlet } from "react-router-dom";
import notify from "../../services/toast";
import useUserContext from "./../../hooks/useUserContext";
import getGroup from "./getGroup";
import Spinner from "../../components/spinner/Spinner";
import joinGroup from "./joinGroup";
import Prompt from "../../components/prompt/Prompt";
import { showPrompt } from "../../services/util";
import leaveGroup from "./leaveGroup";
import useWebsocket from "../../hooks/useWebsocket";

function getActiveMember(members) {
  return members.filter((member) => member.status === "ACTIVE");
}

function renderButton(group, userId, handleJoinGroup, joining, leaving, handleRemovePrompt) {
  if (group.status !== "ACTIVE" || group.creator._id === userId) {
    return null;
  }

  if (!group.members.length) {
    return <ButtonPrimary text="Join Group" size="md" icon="add" action={handleJoinGroup} loading={joining ? true : false} />;
  }
  const member = group.members.find((member) => member?.user?._id === userId);

  if (!member) {
    return <ButtonPrimary text="Join Group" size="md" icon="add" action={handleJoinGroup} loading={joining ? true : false} />;
  }

  if (member.status === "ACTIVE" && (group.type === "Public" || group.type === "Private")) {
    return <ButtonTertiary text="Leave Group" size="md" icon="logout" action={handleRemovePrompt} loading={leaving ? true : false} />;
  }

  if (member.status !== "ACTIVE" && group.type === "Private") {
    return <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 capitalize">Pending Approval</span>;
  }
}

function Group() {
  const { userState } = useUserContext();
  const socket = React.useRef(useWebsocket(userState.accessToken));
  const params = useParams();
  const navigate = useNavigate();
  const { groupId } = params;
  const [group, setGroup] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const [joining, setJoining] = React.useState(false);
  const [leaving, setLeaving] = React.useState(false);

  const leavePromptRef = React.useRef(null);

  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });

  const handleGetGroup = async (accessToken, groupId) => {
    try {
      setFetching(true);
      const response = await getGroup(accessToken, groupId);
      if (response.status === "success") {
        setGroup(response.data);
      } else {
        notify("An error occurred while loading group. If this error persists please contact support", "error");
        navigate("/invalid");
        return;
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const handleJoinGroup = async () => {
    try {
      setJoining(true);
      const payload = {
        groupId: group._id,
        members: [{ _id: userState.id }],
        groupName: group.name,
        groupType: group.type,
        creator: group.creator,
        type: "join",
      };
      const response = await joinGroup(userState.accessToken, payload);
      if (response.status === "success") {
        if (group.type === "Private") {
          socket.current.emit("private_group_join_request", { recipientId: group.creator._id }, (response) => {
            if (response.ok) {
              window.location.href = `/feed/${group._id}`;
            }
          });
        }
        window.location.href = `/feed/${group._id}`;
      } else {
        notify("An error occurred while joining group. If this error persists please contact support", "error");
        return;
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setJoining(false);
    }
  };

  const handleRemovePrompt = () => {
    showPrompt(
      `Leave ${group.name.toUpperCase()} Group`,
      `Are you sure you want to leave ${group.name.toUpperCase()} group? This action can't be undone. If this group is private, joining again will require administrator's approval`,
      leavePromptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const handleLeaveGroup = async () => {
    try {
      setLeaving(true);
      const response = await leaveGroup(userState.accessToken, group._id, userState.id, "user", group.name);
      if (response.status === "success") {
        leavePromptRef.current.closePrompt();
        window.location.href = `/feed/${group._id}`;
      } else {
        notify("An error occurred while leaving group. If this error persists please contact support", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setLeaving(false);
    }
  };

  React.useEffect(() => {
    if (groupId) {
      handleGetGroup(userState.accessToken, groupId);
    }
  }, [groupId]);
  return (
    <>
      <div className="sticky top-0 z-10 w-full bg-white shadow-md flex justify-start p-5">
        <div className="w-full flex justify-between items-center gap-3 text-primary-400 hover:text-primary-600 transition-all duration-500">
          <Logo color="green" />
          <div className="flex justify-center gap-3">
            <Link to="/dashboard/neighborhood">Dashboard</Link>
            <span className="material-icons-outlined">arrow_forward</span>
          </div>
        </div>
      </div>
      {fetching ? (
        <Spinner displayText="Loading..." />
      ) : (
        <div className="mt-5 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative max-w-6xl mx-auto bg-white rounded-t-md h-[400px]">
            <img
              src={group && group.coverImages?.large ? group.coverImages.large.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_2.png"}
              className="w-full h-full object-cover"
            />
            <div className="absolute top-[280px] left-10 w-[150px] h-[150px] flex items-center justify-center rounded-sm shadow-sm border-solid">
              <img
                src={group && group.logos?.small ? group.logos.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="w-full bg-white">
            <div className="w-full flex flex-col p-10">
              <div className="w-full space-y-3 md:space-y-0 md:flex md:justify-between md:items-center">
                <div className="flex flex-col">
                  <h1 className="text-3xl font-bold text-success-800 uppercase">{group && group.name}</h1>
                  <div className="w-full space-y-2 md:space-y-0 md:flex md:justify-start md:gap-5 text-success-800">
                    <div className="flex gap-2">
                      <span className="material-icons-outlined">{group && group.type === "Public" ? "public" : "vpn_lock"}</span>
                      <p className="text-md">{group && group.type}</p>
                    </div>
                    <div className="flex gap-2">
                      <span className="material-icons-outlined">groups</span>
                      <p className="text-md">{group && getActiveMember(group.members).length} Member(s)</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center space-y-3">
                  <GroupAvatar
                    images={
                      group && getActiveMember(group.members).length
                        ? getActiveMember(group.members)
                            .map((member) => member?.user?.photo)
                            .slice(0, 6)
                        : []
                    }
                    dimensions="h-12 w-12"
                  />
                  {group && renderButton(group, userState.id, handleJoinGroup, joining, leaving, handleRemovePrompt)}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white border-t-2 border-gray-300">{group ? <GroupNav group={group} /> : null}</div>
          <div className="w-full pb-10">{group ? <Outlet context={{ group, socket }} /> : null}</div>
        </div>
      )}
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={leavePromptRef} action={handleLeaveGroup} />
    </>
  );
}

export default Group;
