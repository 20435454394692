/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Stars from "react-rating";
import { MdOutlineStar, MdOutlineStarOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { BUSINESS_CATEGORY } from "../../constant";
import { computeReviewSummary, convert24hTo12h, determineListingHour, getValueFromArrayOfObject } from "../../services/util";

function SearchResult({ listing }) {
  const coverPhoto = listing?.businessPhotos?.coverPhoto?.small ? listing.businessPhotos.coverPhoto.small.url : null;
  const listingName = listing?.businessName ? listing.businessName : null;
  const listingCategory = listing?.businessCategory ? listing.businessCategory : null;
  const listingSubCategory = listing?.businessSubCategory ? getValueFromArrayOfObject(BUSINESS_CATEGORY[listingCategory]["subCategory"], { value: listing.businessSubCategory }) : null;
  const price = listing?.price ? listing.price : null;
  const reviewData = listing?.reviews ? computeReviewSummary(listing.reviews) : null;
  const listingBusinessHours = listing?.businessHours ? listing.businessHours : null;
  const listingAddress = listing?.businessAddress ? listing.businessAddress : null;
  const listingDescription = listing?.businessDescription ? listing.businessDescription : null;

  const renderBusinessHour = listingHours => {
    const hour = determineListingHour(listingHours);
    if (hour === "24" || hour === "open") {
      return <span className="w-full text-base text-primary-500 font-bold">Open Now</span>;
    } else {
      return <span className="w-full text-base text-red-500 font-bold">Closed</span>;
    }
  };

  return (
    <div className="w-full flex flex-row-reverse items-center ">
      <div className="hidden sm:block w-full">
        <img
          src={coverPhoto ? coverPhoto : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"}
          className="w-40 h-40 border-2 border-solid border-white rounded-md"
          alt=""
        />
      </div>
      <div className="w-full flex flex-col">
        <Link to={`/dashboard/marketplace/listing/${listing._id}`} className="text-sm text-primary-400 underline lowercase">
          {`https://cohatchplus.com/${listingName}`}
        </Link>
        <h1 className="text-md text-success-800 font-bold capitalize">{listingName}</h1>
        <div className="w-full flex items-center gap-2">
          <span className="text-sm text-gray-400 capitalize">{listingSubCategory}</span>
          <svg className="h-2 w-2 text-primary-400" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
          <span className="text-sm text-gray-400">{price}</span>
        </div>
        <div className="w-full flex items-start gap-2">
          {reviewData.avgRating.toString().padEnd(3, ".0")}{" "}
          <Stars initialRating={reviewData.avgRating} fullSymbol={<MdOutlineStar className="text-yellow-500 text-xl" />} readonly emptySymbol={<MdOutlineStarOutline className="text-xl" />} /> (
          {reviewData.totalReviews} reviews)
        </div>
        <p className="text-md text-primary-400 lowercase">{listingBusinessHours ? renderBusinessHour(listingBusinessHours) : null}</p>
        <div className="w-full flex items-center gap-2">
          <span className="text-sm text-gray-400">{listingAddress.address}</span>
        </div>
        <p className="text-md text-gray-400">{listingDescription?.substring(0, 100)}...</p>
      </div>
    </div>
  );
}

export default SearchResult;
