/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
import React from "react";
import useUserContext from "./../../../hooks/useUserContext";
import { Check, ThumbUp, User } from "heroicons-react";
import FeedList from "../../../components/feed_list/FeedList";
import ListingCard from "../../../components/listing_card/ListingCard";
import { Link } from "react-router-dom";
import MemberCard from "./../../../components/member_card/MemberCard";
import DefaultState from "../../../components/default_state/DefaultState";
import getAllVendors from "./getAllVendors";
import notify from "../../../services/toast";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import { BsChatLeftTextFill, BsInfoCircleFill } from "react-icons/bs";


function BusinessDirectory() {
  useScrollPage();
  useTitle("Business Directory");

  const { userState } = useUserContext();
  const [listings, setListings] = React.useState([]);


  const getAllVendorss = async () => {
    try {
      const response = await getAllVendors(userState.accessToken);
      if (response.status === "success") {
        setListings(response.data);
      }
    } catch (error) {
      // notify("Something went wrong. Please try again later", "error");
    }
  };

  React.useEffect(() => {
    getAllVendorss();

    const timer = window.setInterval(() => {
      getAllVendorss();

    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="relative mt-10 max-w-full h-full mx-auto bg-white shadow rounded-lg">
        <div className="w-full h-full bg-white p-10 space-y-8">

          <h1 className="ml-3 text-4xl font-bold leading-7 font-bebas text-primary-400 sm:leading-9 sm:truncate">All VENDORS</h1>
          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <ul role="list" className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {listings.length ? (
                listings.map((people, index) => <MemberCard key={index} member={people.user[0]} />)
              ) : (
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <DefaultState icon="person" heading="No Members Found" description="Premium and COhatch members will appear here" />
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default BusinessDirectory;
