/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import VacationCard from "../../../components/vacation_card/VacationCard";
import DefaultState from "../../../components/default_state/DefaultState";
import useUserContext from "./../../../hooks/useUserContext";
import notify from "../../../services/toast";
import getFavoriteProperty from "./getFavoriteProperty";
import Pagination from "../../../components/pagination/Pagination";
import Spinner from "../../../components/spinner/Spinner";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function FavoriteProperty() {
  useScrollPage();
  useTitle("Favorite Property(s)");
  
  const { userState } = useUserContext();
  const [favoriteProperties, setFavoriteProperties] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 6 });

  const getAllFavorites = async (accessToken, page, limit) => {
    try {
      setFetching(true);
      const response = await getFavoriteProperty(accessToken, page, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setFavoriteProperties(results);
      } else {
        notify(response.message, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetching(false);
    }
  };

  const refetchFavorites = () => {
    getAllFavorites(userState.accessToken, 0, 6);
  };

  const handlePrevFetch = () => {
    getAllFavorites(userState.accessToken, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = () => {
    getAllFavorites(userState.accessToken, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    getAllFavorites(userState.accessToken, 0, 6);
  }, []);
  return (
    <div className="hidden sm:block bg-white">
      <div className="bg-white">
        <div className="mx-auto max-w-2xl py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
          {fetching ? (
            <div className="text-center w-full relative">
              <Spinner displayText="Fetching..." />
            </div>
          ) : (<>
            {favoriteProperties.length ? (<>
              <div className="mb-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                {favoriteProperties.map((property, index) => {
                  return <VacationCard key={property._id} forceFavorite property={property.target} refetchFavorites={refetchFavorites} />;
                })}
              </div>
              <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
            </>) : (
              <DefaultState icon="storefront" heading="No favorites yet" description="Your favorite properties will appear here" />
            )}
          </>)}
        </div>
      </div>
    </div>
  );
}

export default FavoriteProperty;
