/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable id-length */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useParams, Navigate, useLocation } from "react-router-dom";
import { BUSINESS_CATEGORY, DEFAULT_PAGINATION_LIMIT } from "./../../../constant";
import Map from "./../../../components/map/Map";
import DefaultState from "./../../../components/default_state/DefaultState";
import DisplayListingCard from "../../../components/display_listing/DisplayListingCard";
import Pagination from "../../../components/pagination/Pagination";
import Footer from "../../../components/footer/Footer";
import { Link } from "react-router-dom";
import getListingsByCategory from "./getListings";
import Spinner from "../../../components/spinner/Spinner";
import useGeoLocation from "./../../../hooks/useGeoLocation";
import search from "./search";
import { debounce } from "../../../services/util";
import filter from "./filter";
import FooterSignUp from "../../../components/footer_signup/FooterSignUp";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";
import SelectInput from "../../../components/select_input/SelectInput";
import ListingCard from "../../../components/listing_card/ListingCard";
import MarketplaceCard from "../../../components/marketplace_card/MarketplaceCard";

const filters = {
  ratings: [
    { value: "5", label: "5 stars", checked: false },
    { value: "4", label: "4 stars", checked: false },
    { value: "3", label: "3 stars", checked: false },
  ],
  price: [
    { value: "$", label: "$", checked: false },
    { value: "$$", label: "$$", checked: false },
    { value: "$$$", label: "$$$", checked: false },
  ],
};
const sortOptions = [
  { name: "Highest rating", href: "#", current: true },
  { name: "Highest review", href: "#", current: true },
  { name: "Highest views", href: "#", current: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Listings() {
  useScrollPage();
  useTitle("Listings");

  const params = useParams();
  const location = useLocation();
  const userGeoLocation = useGeoLocation();

  const { value, label } = BUSINESS_CATEGORY?.[params.category]?.["mainCategory"] || "";

  const [mode, setMode] = React.useState("search");

  const [listings, setListings] = React.useState(null);

  const suggestionContainerRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: DEFAULT_PAGINATION_LIMIT });

  const [rating, setRating] = React.useState([]);
  const [price, setPrice] = React.useState([]);
  const [amenities, setAmenities] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");

  const handleSetRating = event => {
    const { value } = event.target;
    if (rating.includes(value)) {
      const newRating = rating.filter(ratingValue => ratingValue !== value);
      setRating(newRating);
    } else {
      setRating(prevState => [...prevState, value]);
    }
  };

  const handleSetPrice = event => {
    const { value } = event.target;
    if (price.includes(value)) {
      const newPrice = price.filter(priceValue => priceValue !== value);
      setPrice(newPrice);
    } else {
      setPrice(prevState => [...prevState, value]);
    }
  };

  const handleSearch = async event => {
    try {
      const query = event.target.value;
      setSearchTerm(query);
      const response = await search(value, query);
      if (response.status === "success") {
        setSuggestions(response.data);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), [listings, value]);

  const handleSubmit = React.useCallback(
    event => {
      event.preventDefault();
    },
    [listings, value]
  );

  const handleFilter = async (event, offset = 0, limit = DEFAULT_PAGINATION_LIMIT) => {
    try {
      setMode("filter");
      const payload = {
        ratings: rating.length ? rating : [],
        prices: price.length ? price : [],
        subCategory: subCategory ? subCategory : null,
        amenities: amenities ? amenities : null,
      };
      const response = await filter(value, offset, limit, payload);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination(prevState => {
          return { ...prevState, limit, page: page, remaining, total };
        });
        setListings(results);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListingByCategory = async (value, offset, limit) => {
    try {
      const response = await getListingsByCategory(value, offset, limit);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination(prevState => {
          return { ...prevState, limit, page: page, remaining, total };
        });
        setListings(results);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrevFetch = React.useCallback(() => {
    if (mode === "search") {
      fetchListingByCategory(value, pagination.page - 1, pagination.limit);
    } else if (mode === "filter") {
      handleFilter(null, pagination.page - 1, pagination.limit);
    }
  }, [pagination]);

  const handleNextFetch = React.useCallback(() => {
    if (mode === "search") {
      fetchListingByCategory(value, pagination.page + 1, pagination.limit);
    } else if (mode === "filter") {
      handleFilter(null, pagination.page + 1, pagination.limit);
    }
  }, [pagination]);

  React.useEffect(() => {
    if (value) {
      fetchListingByCategory(value, 0, DEFAULT_PAGINATION_LIMIT);
    }
  }, [value]);

  React.useEffect(() => {
    if (!rating.length && !price.length && !subCategory && !amenities) {
      fetchListingByCategory(value, 0, 12);
    }
  }, [rating, price, subCategory, amenities]);

  const getListingCoordinates = listings => {
    const coordinates = [];
    const info = {};
    for (const listing of listings) {
      coordinates.push(listing.businessAddress.coordinates);
      info[`${listing.businessAddress.coordinates.lat}${listing.businessAddress.coordinates.lng}`] = {
        image: listing.businessPhotos?.coverPhoto?.small ? listing.businessPhotos.coverPhoto.small.url : null,
        listingName: listing.businessName,
        address: listing.businessAddress.address,
        listingId: listing._id,
      };
    }

    return [coordinates, info];
  };

  const listingCoordinates = React.useMemo(() => getListingCoordinates(listings || []), [listings, value]);
  const memoizedListings = React.useMemo(() => listings || [], [listings, value]);
  const memoizedPagination = React.useMemo(() => pagination, [pagination, value]);
  const memoizedGeoLocation = React.useMemo(() => userGeoLocation, [userGeoLocation, listings, value]);

  const closeSuggestionsOnClick = () => {
    setSuggestions([]);
  };

  const closeSuggestionsOnEnter = event => {
    if (event.code === "Enter") {
      setSuggestions([]);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", closeSuggestionsOnClick, false);
    document.addEventListener("keypress", closeSuggestionsOnEnter, false);

    return () => {
      document.removeEventListener("click", closeSuggestionsOnClick, false);
      document.removeEventListener("keypress", closeSuggestionsOnEnter, false);
    };
  }, []);


  const [show, setShow] = React.useState(false);

  return (
    <>
      {value ? (
        <div className=" flex flex-col">
          <div className="w-full py-8 px-5 text-center space-y-3 bg-no-repeat bg-cover bg-[url('/src/assets/grungeTextureLight.png')]">
            <h1 className="w-full text-4xl md:text-5xl text-success-800 font-bebas font-bold">{label}</h1>
            <p className="w-full text-md text-gray-500">{BUSINESS_CATEGORY[value]["summary"]}</p>
          </div>
          {/* <div className="w-full h-[450px] xl:h-[600px]">{<Map locations={listingCoordinates} />}</div> */}
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <button
              onClick={() => setShow(!show)}
              className=" items-center gap-x-2 rounded-md bg-primary-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {show ? "Hide Map" : "Show Map"}
            </button>
          </div>
          {show && <div>
            <div className="w-full h-[450px] xl:h-[600px]">
              {<Map locations={listingCoordinates} />}
            </div>
          </div>
          }

          <div className="mt-10 w-full px-3 sm:px-3">
            <div className="mb-10 max-w-5xl mx-auto space-y-4">
              <div className="w-full relative mt-1">
                <form onSubmit={handleSubmit}>
                  <input
                    onInput={debouncedHandleSearch}
                    type="search"
                    name="search"
                    className={`shadow-sm focus:ring-primary-500 bg-primary-100 focus:border-primary-100 block w-full text-lg text-success-400 border-gray-300 px-10 py-4 ${suggestions.length ? "rounded-t-md" : "rounded-full"
                      }`}
                    placeholder={`Search ${label}`}
                  />
                  <div
                    ref={suggestionContainerRef}
                    className={`${suggestions.length ? "block" : "hidden"} z-30 absolute w-full bg-white border border-primary-400 max-h-80 overflow-y-auto p-4 divide-y`}>
                    {suggestions?.length ? (
                      suggestions.map(suggestion => {
                        return (
                          <Link to={`/marketplace/listing/${suggestion._id}`} key={suggestion._id} className="w-full flex gap-2 items-start p-2 hover:bg-gray-200">
                            <img
                              src={suggestion?.businessPhotos?.coverPhoto?.small?.url || "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"}
                              className="w-16 h-16 border-2 border-solid border-white rounded-md"
                              alt={suggestion?.businessName}
                            />
                            <div className="w-full flex flex-col">
                              <h1 className="text-md text-success-800 font-bold capitalize">{suggestion?.businessName}</h1>
                              <p className="w-full text-sm text-gray-400 ">{suggestion?.businessDescription?.substring(0, 500)}</p>
                              <p className="text-sm text-primary-400 truncate">
                                {suggestion?.businessOwner?.firstName} {suggestion?.businessOwner?.lastName}
                              </p>
                            </div>
                          </Link>
                        );
                      })
                    ) : (
                      <Spinner displayText="Loading..." />
                    )}
                  </div>
                  <span className="absolute top-5 left-3 material-icons-outlined text-gray-300">search</span>
                </form>
              </div>

              <div className="mx-auto max-w-7xl px-6 lg:px-8 bg-white">
                {/* Filters */}
                <Disclosure as="section" aria-labelledby="filter-heading" className="grid items-center border-t border-b border-gray-200">
                  <h2 id="filter-heading" className="sr-only">
                    Filters
                  </h2>
                  <div className="relative col-start-1 row-start-1 py-4">
                    <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
                      <div>
                        <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                          <FunnelIcon className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          Filters
                        </Disclosure.Button>
                      </div>
                      <div className="pl-6 flex gap-4">
                        {rating.length || price.length || subCategory || amenities ? (
                          <button type="button" className="text-gray-500" onClick={handleFilter}>
                            Apply all
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <Disclosure.Panel className="border-t border-gray-200 py-10">
                    <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
                      <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                        <fieldset>
                          <legend className="block font-medium">Rating</legend>
                          <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                            {filters.ratings.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center text-base sm:text-sm">
                                <input
                                  onChange={event => handleSetRating(event)}
                                  id={`price-${optionIdx}`}
                                  name="price[]"
                                  defaultValue={option.value}
                                  type="checkbox"
                                  className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                                  defaultChecked={option.checked}
                                />
                                <label htmlFor={`price-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend className="block font-medium">Price</legend>
                          <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                            {filters.price.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center text-base sm:text-sm">
                                <input
                                  onChange={event => handleSetPrice(event)}
                                  id={`color-${optionIdx}`}
                                  name="color[]"
                                  defaultValue={option.value}
                                  type="checkbox"
                                  className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                                  defaultChecked={option.checked}
                                />
                                <label htmlFor={`color-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                      <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                        <fieldset>
                          <legend className="block font-medium">Amenities</legend>
                          <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                            <SelectInput
                              options={BUSINESS_CATEGORY[value]["amenities"]}
                              noOptionsMessage="No amenities found"
                              placeholder="Select an amenity"
                              setSelectedValue={event =>
                                setAmenities(() => {
                                  return event?.value ? event.value : null;
                                })
                              }
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend className="block font-medium">SubCategory</legend>
                          <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                            <SelectInput
                              options={BUSINESS_CATEGORY[value]["subCategory"]}
                              noOptionsMessage="No subcategory found"
                              placeholder="Select a subcategory"
                              setSelectedValue={event =>
                                setSubCategory(() => {
                                  return event?.value ? event.value : null;
                                })
                              }
                            />
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </Disclosure.Panel>
                  {/* <div className="col-start-1 row-start-1 py-4">
                    <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
                      <Menu as="div" className="relative inline-block">
                        <div className="flex">
                          <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                            Sort
                            <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {sortOptions.map((option) => (
                                <Menu.Item key={option.name}>
                                  {({ active }) => (
                                    <a
                                      href={option.href}
                                      className={classNames(option.current ? "font-medium text-gray-900" : "text-gray-500", active ? "bg-gray-100" : "", "block px-4 py-2 text-sm")}
                                    >
                                      {option.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div> */}
                </Disclosure>
              </div>
            </div>
            <div className="w-full">
              <Pagination pagination={memoizedPagination} next={handleNextFetch} prev={handlePrevFetch} />
            </div>
            <div className="space-y-5 sm:space-y-0 sm:w-full sm:flex sm:gap-2w-full flex justify-center md:justify-center gap-5 flex-wrap">
              <div className=" grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {memoizedListings && memoizedListings.length ? (
                  memoizedListings.map((listing, index) => {
                    return <MarketplaceCard key={index} listing={listing} userGeoLocation={memoizedGeoLocation} />;
                  })
                ) : (
                  <DefaultState icon="store" heading="No Listings Found" description={`${label} Listings will appear here`} />
                )}
              </div>
            </div>
            <Pagination pagination={memoizedPagination} next={handleNextFetch} prev={handlePrevFetch} />
          </div>
        </div>

      ) : (
        <Navigate to="/listing/invalid_category" state={{ from: location }} replace />
      )}
      <section className="w-full font-roboto pr-20 pl-20">
        <FooterSignUp />
      </section>
      <Footer />
    </>
  );
}

export default Listings;
