/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

/*eslint-disable capitalized-comments */

import React from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import useUserContext from "../../hooks/useUserContext";
import { generateClassName } from "../../services/util";
import { validateMessage } from "../../services/validation";
import { MdPostAdd, MdClear } from "react-icons/md";
import notify from "../../services/toast";
import handleCreatePost from "./handleCreatePost";
import FileUploader from "../../components/file_uploader/FileUploader";
import AddMember from "../add_member/AddMember";
import Location from "../location/Location";
import capitalize from "lodash/capitalize";
import AddCauses from "../add_causes/AddCauses";

const imageCriteria = { requiredSize: 10e6, requiredDimensions: { width: 400, height: 400 } };


function PhotoDisplay({ callback }) {
  return (
    <div onClick={callback} className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-primary-100 transition-all duration-500">
      <span className="material-icons-outlined text-primary-400">camera_alt</span>
      <span className="hidden lg:block text-sm text-success-800 font-bold">Photo</span>
    </div>
  );
}

function VideoDisplay({ callback }) {
  return (
    <div onClick={callback} className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-red-100 transition-all duration-500">
      <span className="material-icons-outlined text-red-400">videocam</span>
      <span className="hidden lg:block text-sm text-success-800 font-bold">Video</span>
    </div>
  );
}

function TagDisplay({ callback }) {
  return (
    <div onClick={callback} className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-green-100 transition-all duration-500">
      <span className="material-icons-outlined text-green-400">person_add</span>
      <span className="hidden lg:block text-sm text-success-800 font-bold">Tag Member</span>
    </div>
  );
}

function TagCausesDisplay({ callback }) {
  return (
    <div onClick={callback} className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-green-100 transition-all duration-500">
      <span className="material-icons-outlined text-green-400">person_add</span>
      <span className="hidden lg:block text-sm text-success-800 font-bold">Tag Causes</span>
    </div>
  );
}

function CreatePost({ groupId, groupName, reload, creatorId, socket }) {
  const { userState } = useUserContext();
  const [postText, setPostText] = React.useState("");
  const [validPostText, setValidPostText] = React.useState(false);
  const [taggedMembers, setTaggedMembers] = React.useState([]);
  const [postMedia, setPostMedia] = React.useState([]);
  const [location, setLocation] = React.useState(null);
  const [creatingPost, setCreatingPost] = React.useState(false);
  const [selectedCauses, setSelectedCauses] = React.useState([]);
  const [formattedCauses, setFormattedCauses] = React.useState("");


  const [showMap, setShowMap] = React.useState(false);

  React.useEffect(() => {
    const result = validateMessage(postText);
    setValidPostText(result);
  }, [postText]);

  const handlePost = async () => {
    try {
      const payload = {
        groupId: groupId,
        postText: postText,
        postMedia: postMedia,
        location: location,
        taggedMembers: taggedMembers,
      };
      setCreatingPost(true);
      const response = await handleCreatePost(userState.accessToken, payload);
      if (response.status === "success") {
        const { id: postId } = response.data;
        notify("Post created successfully", "info");
        setPostText("");
        setPostMedia([]);
        setTaggedMembers([]);
        setLocation(null);
        socket.current.emit("new_group_post", {
          groupId: groupId,
          postId: postId,
          groupName: groupName,
          userFirstName: userState.firstName,
          userLastName: userState.lastName,
          userId: userState.id,
          creatorId: creatorId,
        });
        if (taggedMembers.length) {
          socket.current.emit("tagged", {
            groupId: groupId,
            postId: postId,
            groupName: groupName,
            recipientId: taggedMembers,
            userFirstName: userState.firstName,
            userLastName: userState.lastName,
            userId: userState.id,
          });
        }
        reload();
      } else {
        notify("An error occurred while creating the post. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setCreatingPost(false);
    }
  };

  const handleTagMembers = () => {
    return null;
  };

  const handleTagCauses = () => null;

  const displaySelectedCauses = (selectedCauses) => {
    const formattedCauses = selectedCauses.map((cause) => `#${cause}`).join(" ");
    setFormattedCauses(formattedCauses);
  };

  const handleAddLocation = () => {
    setShowMap(false);
  };

  const cancelPost = () => {
    setPostText("");
    setPostMedia([]);
    setTaggedMembers([]);
    setLocation(null);
    setSelectedCauses([]);
  };

  const handleImageUpload = (result) => {
    setPostMedia((prev) => {
      return [...prev, { ...result, type: "image" }];
    });
  };

  const handleVideoUpload = (result) => {
    setPostMedia((prev) => {
      return [...prev, { ...result, type: "video" }];
    });
  };
  return (
    <div className="w-full bg-white rounded-md shadow-md p-5">
      <div className="w-full flex justify-start items-center gap-3">
        <img
          className="w-10 h-10 flex-shrink-0 mx-auto rounded-full"
          src={userState?.photo?.small ? userState.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
          alt={userState.lastName}
        />
        <div className="w-full">
          <div className="mt-1">
            <ReactTextareaAutosize
              autoFocus
              onChange={(event) => setPostText(event.target.value)}
              value={postText}
              className={generateClassName(!validPostText && postText)}
              placeholder={`${capitalize(userState.firstName)} ${capitalize(userState.lastName)} What's on your mind`}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 w-full flex justify-between items-center">
        <div className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-primary-100 transition-all duration-500">
          <FileUploader
            fileType="image/*"
            isMultiple={true}
            validationCriteria={imageCriteria}
            DisplayIcon={PhotoDisplay}
            className="text-2xl text-primary-400 cursor-pointer"
            accessToken={userState.accessToken}
            folder="post_photos"
            description="400 X 400 8MB Image Minimum"
            numberOfFiles={10 - postMedia.length}
            callback={handleImageUpload}
          />
        </div>
        <div className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-red-100 transition-all duration-500">
          <FileUploader
            fileType="video/*"
            validationCriteria={imageCriteria}
            DisplayIcon={VideoDisplay}
            className="text-2xl text-primary-400 cursor-pointer"
            accessToken={userState.accessToken}
            folder="post_videos"
            description="8MB Video Maximum"
            numberOfFiles={10 - postMedia.length}
            callback={handleVideoUpload}
          />
        </div>
        <div className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-green-100 transition-all duration-500">
          <AddMember
            invite={false}
            selectedMembers={taggedMembers}
            setSelectedMembers={setTaggedMembers}
            endpoint={"/plan?plan=COHATCH,PREMIUM"}
            searchMembers={"/plan/search?plan=COHATCH,PREMIUM"}
            complete={handleTagMembers}
            DisplayIcon={TagDisplay}
            className="text-2xl text-red-400 cursor-pointer"
            heading="Tag a member"
          />
        </div>
        <div className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-green-100 transition-all duration-500">
        <AddCauses
            selectedCauses={selectedCauses}
            setSelectedMembers={setSelectedCauses}
            complete={handleTagCauses}
            DisplayIcon={TagCausesDisplay}
            className="text-2xl text-red-400 cursor-pointer"
            heading="Tag Causes"
            showSelectedCauses={displaySelectedCauses}
          />
        </div>
        <div onClick={() => setShowMap(!showMap)} className="flex gap-2 items-center justify-center p-1 cursor-pointer hover:rounded-md hover:bg-yellow-100 transition-all duration-500">
          <span className="material-icons-outlined text-yellow-400">add_location</span>
          <span className="hidden lg:block text-sm text-success-800 font-bold">Add Location</span>
        </div>
      </div>
      <div className="w-full flex-col space-y-2">
        {postMedia.length ? <p className="text-md text-primary-400">{postMedia.length} Images/Videos</p> : null}
        {taggedMembers.length ? <p className="text-md text-green-400">{taggedMembers.length} member(s) Tagged</p> : null}
        {formattedCauses && (
          <div className="flex gap-2 text-md text-green-400">
            Selected Causes: {formattedCauses}
          </div>
        )}
        {location ? <p className="text-md text-yellow-400">{location.address}</p> : null}
      </div>
      {showMap ? <Location location={location} setLocation={setLocation} callback={handleAddLocation} /> : null}
      {(postText && validPostText) || postMedia.length > 0 ? (
        <div className="mt-5 w-full flex justify-end gap-4">
          <button
            onClick={cancelPost}
            type="button"
            disabled={creatingPost ? true : false}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <MdClear className="-ml-0.5 mr-2 h-6 w-6" />
            {creatingPost ? "Please wait..." : "Cancel post"}
          </button>
          <button
            onClick={handlePost}
            type="button"
            disabled={creatingPost ? true : false}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <MdPostAdd className="-ml-0.5 mr-2 h-6 w-6" />
            {creatingPost ? "Please wait..." : "Create post"}
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default CreatePost;
