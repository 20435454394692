/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { MdStar } from "react-icons/md";
import { Link } from "react-router-dom";
import { inArrayObject } from "../../services/util";
import notify from "../../services/toast";
import createFavorite from "../display_listing/createFavorite";
import useUserContext from "./../../hooks/useUserContext";
import DisneyCard from "../../assets/DisneyCard.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const products = [
    {
      id: 1,
      name: "Disney Vacation Club",
      href: "disney_details",
      price: "$1000+",
      imageSrc: DisneyCard,
      imageAlt:
        "Disney",
    },
    // More products...
  ];
  
 
export default function VacationCard({
  property,
  refetchFavorites,
  forceFavorite,
}) {
  const {
    userState,
  } = useUserContext();
  const [isFavorite, setIsFavorite] = useState(inArrayObject(property?.favorites || [], userState.id, "creator"));

  const handleCreateFavorite = async () => {
    try {
      const payload = {
        kind: "Property",
        target: property._id,
      };
      const response = await createFavorite(payload, userState.accessToken);
      if (response.status === "success") {
        setIsFavorite(response.data);
        if (refetchFavorites) {
          refetchFavorites();
        }
      } else {
        notify("An error occurred while creating favorite. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  return (
<div>
    <div className="w-full bg-gray-100 p-2 flex justify-end">
        <span
         className="material-icons-outlined text-2xl cursor-pointer hover:text-primary-400 transition-all duration-500 text-primary-400 text-gray-400"
        >
        favorite
        </span>
    </div>
    {products.map((product) => (
            <div key={product.id} className="group relative">
              <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-lg bg-gray-100">
              <div className="px-5">
                <img src={product.imageSrc} alt={product.imageAlt} className="object-cover object-center" />
              </div>
                <div className="flex items-end p-6 opacity-0 group-hover:opacity-100" aria-hidden="true" style={{ marginTop: "-70px", marginBottom: "48px" }}>
                  <div className="w-full rounded-md bg-white bg-opacity-75 py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter">
                    View Disney
                  </div>
                </div>
              </div>
              <div className="pt-10 pb-4 text-center">
                    <h3 className="text-sm font-medium text-gray-900">
                        <Link to={`/disney_details/${property._id}`}>
                            <span aria-hidden="true" className="absolute inset-0" />
                            {product.name}
                        </Link>
                    </h3>
                    <div className="mt-3 flex flex-col items-center">
                        <p className="sr-only">{product.rating} out of 5 stars</p>
                        <div className="flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                            <MdStar
                            key={rating}
                            className={classNames(
                            product.rating > rating ? "text-yellow-400" : "text-gray-200",
                            "flex-shrink-0 h-5 w-5"
                            )}
                            aria-hidden="true"
                        />
                        ))}
                    </div>
                  <p className="mt-1 text-sm text-gray-500">{product.reviewCount} reviews</p>
                </div>
                <p className="mt-4 text-base font-medium text-gray-900">{product.price}</p>
              </div>
              </div>
            ))}
        </div>
      
  );
}
