/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getEvents(accessToken, year, month, creator) {
  console.log("trying", accessToken, year, month, creator);
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactevent?year=${year}&month=${month}${creator ? "&creator=true" : ""}`;

  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getEvents;
