/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Modal from "../modal/Modal";
import useUserContext from "../../hooks/useUserContext";
import { MdClose } from "react-icons/md";
import notify from "../../services/toast";
import getUserData from "./getUserData";
import Spinner from "../spinner/Spinner";
import { phoneNumberFormatter } from "../../services/util";

function UserProfile({ userId, Display }) {
  const { userState } = useUserContext();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  const handleGetUser = async () => {
    try {
      setFetching(true);
      const response = await getUserData(userState.accessToken, userId);
      if (response.status === "success") {
        setUser(response.data);
      } else {
        notify("An error occurred while getting user information. If this error persists please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    if (open === true) {
      handleGetUser();
    }
  }, [userId, open]);

  return (
    <>
      {open ? (
        <Modal>
          <div className="w-full sm:w-[600px] mx-auto bg-white shadow-md rounded-md divide-y-2">
            <div className="w-full h-full flex flex-col p-3">
              <div className="w-full p-3 flex justify-end">
                <MdClose className="cursor-pointer text-2xl" onClick={() => setOpen(false)} />
              </div>
              <>
                {fetching ? (
                  <Spinner displayText="Fetching..." />
                ) : (
                  <div className="w-full flex flex-col space-y-5">
                    {/**User Image, First and Last Name, Job Title, State and City*/}
                    <div className="sm:flex sm:items-center">
                      <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                        <img
                          className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
                          src={user?.photo?.small ? user.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                          alt={`${user?.lastName ? user.lastName : ""} Profile Photo`}
                        />
                      </div>
                      <div>
                        {user?.firstName && user?.lastName ? (
                          <h4 className="text-lg text-left font-bold">
                            {user.firstName} {user.lastName}
                          </h4>
                        ) : null}

                        {user?.jobTitle ? <h3 className="text-md font-semibold text-left text-gray-400">{user.jobTitle}</h3> : null}
                        {user?.state && user?.city ? (
                          <h3 className="text-sm text-primary-400 text-left font-extrabold">
                            {user.city}, {user.state}
                          </h3>
                        ) : null}
                      </div>
                    </div>

                    {/* User Email */}
                    {user?.emailAddress ? (
                      <div className="flex items-center gap-4">
                        <dt className="text-md font-bold text-gray-500">Email address</dt>
                        <dd className="text-sm text-gray-900 lowercase">{user.emailAddress}</dd>
                      </div>
                    ) : null}

                    {/* User Phone */}
                    {user?.phoneNumber ? (
                      <div className="flex items-center gap-4">
                        <dt className="text-md font-bold text-gray-500">Phone number</dt>
                        <dd className="text-sm text-gray-900 lowercase">{phoneNumberFormatter(user.phoneNumber)}</dd>
                      </div>
                    ) : null}

                    {/* User Company */}
                    {user?.company ? (
                      <div className="flex items-center gap-4">
                        <dt className="text-md font-bold text-gray-500">Company</dt>
                        <dd className="text-sm text-gray-900 capitalize">{user.company}</dd>
                      </div>
                    ) : null}

                    {/* User Website*/}
                    {user?.website ? (
                      <div className="flex items-center gap-4">
                        <dt className="text-md font-bold text-gray-500">Website</dt>
                        <a href={user.website}>
                          <dd className="text-sm text-primary-400 underline lowercase">{user.website}</dd>
                        </a>
                      </div>
                    ) : null}

                    {/* User Bio*/}
                    {user?.bio ? (
                      <div className="flex flex-col items-start gap-4">
                        <dt className="text-md font-bold text-gray-500">About Me</dt>
                        <div className="w-full max-h-24 overflow-y-auto text-left px-4">{user.bio}</div>
                      </div>
                    ) : null}

                    {/* User Causes*/}
                    {user?.causes && user.causes.length ? (
                      <div className="flex flex-col items-start gap-4">
                        <div className="text-md font-bold text-gray-500">Causes I Care About</div>
                        <div className="w-full flex gap-3 items-center overflow-x-auto p-4">
                          {user.causes.map((cause, index) => {
                            return (
                              <div key={index} className="w-fit h-fit bg-white rounded-md shadow-md p-3">
                                <div className="w-full flex gap-2">
                                  <p className="text-success-800 text-md capitalize">{cause}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}

                    {/* User Interest*/}
                    {user?.interest && user.interest.length ? (
                      <div className="flex flex-col items-start gap-4">
                        <dt className="text-md font-bold text-gray-500">My Interests</dt>
                        <div className="w-full flex gap-3 items-center overflow-x-auto p-4">
                          {user.interest.map((interest, index) => {
                            return (
                              <div key={index} className="w-fit h-fit bg-white rounded-md shadow-md p-3">
                                <div className="w-full flex gap-2">
                                  <p className="text-success-800 text-md capitalize">{interest.label}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            </div>
          </div>
        </Modal>
      ) : (
        <Display callback={() => setOpen(true)} />
      )}
    </>
  );
}

export default UserProfile;
