/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";

export default function CheckBox({ amenities, checked, onChange }) {
  return (
    <fieldset className="space-y-5">
      <ul className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-4 sm:gap-px sm:divide-y-0">
        {amenities.map(({ label, value }, index) => {
          return (
            <li className="w-full " key={index}>
              <div className="flex items-center pl-3">
                <input
                  id={`custom-checkbox-${value}`}
                  type="checkbox"
                  name={label}
                  value={value}
                  checked={!!(checked && checked[value])}
                  onChange={() => onChange(value)}
                  className="w-4 h-4 text-primary-400 bg-white rounded border-gray-300 focus:ring-primary-400 dark:focus:ring-primary-400 dark:ring-offset-primary-400 focus:ring-2 dark:bg-white dark:border-gray-500"
                />
                <label className="py-3 pl-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={`custom-checkbox-${value}`}>
                  {label}
                </label>
              </div>
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
}
