/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import createLike from "./createLike";
import getLikes from "./getLikes";

import { Anchorme } from "react-anchorme";

const CustomLink = (props) => {
  const { href } = props;
  return (
    <i className="text-primary-400 text-md underline">
      <a href={href}>{href}</a>
    </i>
  );
};

function CommentReply({ index, total, reply, deleteReply }) {
  const { userState } = useUserContext();
  const userFirstName = reply?.user?.firstName ? reply.user.firstName : null;
  const userLastName = reply?.user?.lastName ? reply.user.lastName : null;
  const photo = reply?.user?.photo?.small ? reply.user.photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png";
  const userReply = reply?.comment ? reply.comment : null;
  const [likes, setLikes] = React.useState(0);

  const handleGetLikes = async () => {
    try {
      const response = await getLikes(userState.accessToken, reply._id);
      if (response.status === "success") {
        setLikes(response?.data?.like ? response.data.like : 0);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleCreateLike = async () => {
    try {
      const payload = {
        commentId: reply._id,
        postId: reply.postId,
      };
      const response = await createLike(userState.accessToken, payload);
      if (response.status === "success") {
        notify("Successfully liked this comment", "info");
        const newLike = parseInt(likes, 10) + parseInt(response.data, 10);
        setLikes(newLike);
      } else {
        notify("Error creating a like for this comment. If error persists, please contact support@cohatchplus.com.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  React.useEffect(() => {
    if (reply._id) {
      handleGetLikes();
    }
  }, []);

  return (
    <li>
      <div className="relative pb-8">
        {index === total - 1 ? null : <span className="absolute top-5 left-5 -ml-px h-full w-1 bg-gray-200" aria-hidden="true"></span>}
        <div className="relative flex items-start space-x-3">
          <div className="relative">
            <img className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white" src={photo} alt={`${userLastName} Photo`} />

            <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm">
                <Link to={`/profile/${reply.userId}`} className="font-medium text-success-800 underline capitalize">
                  {userFirstName} {userLastName}
                </Link>
              </div>
              <p className="mt-0.5 text-sm text-gray-500">Replied {formatDistanceToNow(new Date(reply.createdAt), { addSuffix: false })} ago</p>
            </div>
            <div className="mt-2 text-sm text-gray-700">
              <p>
                <Anchorme target="_blank" linkComponent={CustomLink}>
                  {userReply}
                </Anchorme>
              </p>
            </div>
            <div className="w-fit gap-5 flex justify-center items-center">
              <span onClick={handleCreateLike} className="material-icons-outlined text-sm cursor-pointer">
                thumb_up
              </span>
              {likes > 0 ? <p className="text-sm">{likes}</p> : null}
              {reply.userId === userState.id ? (
                <div className="w-full flex justify-end">
                  <MdDeleteOutline className="text-red-400 cursor-pointer" onClick={() => deleteReply(reply._id)} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default CommentReply;
