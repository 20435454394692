/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import DefaultState from "../../../components/default_state/DefaultState";
import Spinner from "../../../components/spinner/Spinner";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import getAllLifePerksPost from "../../../pages/life_perks/life_perks_feed/getAllLifePerksPost";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import LifePerksCreatePost from "../../create_post/LifePerksCreatePost";
import LifePerksPost from "../../post/LifePerksPost";
import FeaturedEvents from "../../featured_events_life_perks/FeaturedEvents";
import getFeaturedEvents from "../getFeaturedEvents";
import FeaturedCoupons from "../../featured_coupons/FeaturedCoupons";
import featured from "../../../pages/local_discount/featured";
import { MAGIC_NUMBERS } from "../../../constant";
import MarketPlaceFeed from "../MarketplaceFeed.jsx";

function LifePerksTimeline() {
  useScrollPage();
  
  const officeId = "606b10f2f7ff2fc987137673";
  const { userState } = useUserContext();
  const [posts, setPosts] = React.useState([]);
  const [featuredEvents, setFeaturedEvents] = React.useState([]);
  const [fetchingPost, setFetchingPost] = React.useState(false);
  const rootRef = React.useRef(null);
  const page = React.useRef(0);
  const remaining = React.useRef(0);
  const [discounts, setDiscounts] = React.useState([]);
  const [isDiscountLoading, setIsDiscountLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({ limit: 6, total: 0, page: 0, remaining: 0 });
  const [element, setElement] = React.useState(null);

  const fetchDiscounts = async (accessToken, page, _keyword, _category) => {
    setIsDiscountLoading(true);
    const response = await featured({ page, keyword: _keyword, category: _category, limit: pagination.limit }, accessToken);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page, remaining, total });
      setDiscounts(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsDiscountLoading(false);
  };

  useTitle("Life Perks Live Wall");

  const fetchFeaturedEvents = async () => {
    try {
      setFetchingPost(true);
      const response = await getFeaturedEvents(userState.accessToken);
      if (response.status === "success") {
        setFeaturedEvents(response.data);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };

  const fetchAllPost = async () => {
    try {
      setFetchingPost(true);
      const response = await getAllLifePerksPost(userState.accessToken, officeId, page.current, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setPosts(prevState => [...prevState, ...results]);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };

  const reload = async () => {
    try {
      setFetchingPost(true);
      const response = await getAllLifePerksPost(userState.accessToken, officeId, 0, 10);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        remaining.current = remainingResult;
        page.current = 0;
        setPosts(results);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFetchingPost(false);
    }
  };

  const observer = React.useRef(new IntersectionObserver(
    entries => {
      const [first] = entries;
      if (first.isIntersecting) {
        fetchAllPost();
      }
    }, 
    { threshold: 1, root: rootRef.current }
  ));

  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  React.useEffect(() => {
    fetchAllPost();
    fetchDiscounts(userState.accessToken, 0, "", "");
    fetchFeaturedEvents();
  }, []);

  return (
    <div className="flex min-h-full flex-col">
      <div className="mx-auto w-full max-w-9xl flex flex-row gap-2 p-2">
        <aside className="sticky top-0 h-screen overflow-y-auto hidden lg:block lg:w-64 xl:w-72 shrink-0">
          <MarketPlaceFeed />
        </aside>

        <main className="flex-1 overflow-y-auto">
          <div className="space-y-3">
            <LifePerksCreatePost officeId={officeId} reload={reload} setPosts={setPosts} />
            <div ref={rootRef} className="space-y-3">
              {posts.map((post, index) => (
                <LifePerksPost key={index} post={post} reload={reload} officeId={officeId} />
              ))}
              {!posts.length && <DefaultState icon="post_add" heading="No Posts Found" description="All posts will appear here" />}
              {fetchingPost && <Spinner displayText="Fetching..." />}
              {remaining.current > 0 && <span ref={setElement} className="w-full flex justify-center text-gray-400">Loading...</span>}
              {posts.length > 0 && remaining.current <= 0 && <span className="w-full flex justify-center text-gray-400">No more posts to load</span>}
            </div>
          </div>
        </main>

        <aside className="sticky top-0 h-screen overflow-y-auto hidden xl:block xl:w-80 shrink-0">
          <div className="bg-white rounded-md shadow-md mb-5">
            <div className="border-b-2 border-solid border-gray-200 p-2">
              <h1 className="text-md text-success-800 font-bold text-center">Featured Discounts</h1>
            </div>
            {!discounts.length ? (
              <div className="flex justify-center">
                <DefaultState icon="discount" heading="No featured discounts" description="No featured discounts available" />
              </div>
            ) : (
              <div>
                {isDiscountLoading ? (
                  <Spinner displayText="Loading..." />
                ) : (
                  <div className="mt-5 flex flex-wrap gap-4 p-2">
                    {discounts.map(discount => (
                      <FeaturedCoupons
                        key={discount._id}
                        coupon={discount}
                        fid={discount.favorites[0] ? discount.favorites[0]._id : ""}
                        isFavorite={!!discount.favorites.length}
                        isOwner={userState.id === discount.creator._id}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            <Link to="/dashboard/local_discount">
              <div className="w-full bg-gray-200 p-3 text-center hover:bg-gray-300 transition ease-linear duration-500">See more</div>
            </Link>
          </div>

          <div className="bg-white rounded-md shadow-md">
            <div className="border-b-2 border-solid border-gray-200 p-2">
              <h1 className="text-md text-success-800 font-bold text-center">Featured Events</h1>
            </div>
            {featuredEvents.map(event => (
              <FeaturedEvents key={event._id} event={event} />
            ))}
            <Link to="/dashboard/workshop">
              <div className="w-full bg-gray-200 p-3 text-center hover:bg-gray-300 transition ease-linear duration-500">See more</div>
            </Link>
          </div>
        </aside>
      </div>
    </div>
  );
}

export default LifePerksTimeline;
