/* eslint-disable no-console */
import React from "react";

function useGeoLocation() {
  const [coordinates, setCoordinates] = React.useState(null);
  const onSuccess = position => {
    setCoordinates(() => {
      return { lat: position.coords.latitude, lng: position.coords.longitude };
    });
  };

  const onFailure = error => {
    console.info(`Unable to get user location: ${error.message}`);
  };

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onFailure);
    }
  }, []);

  return coordinates;
}

export default useGeoLocation;
