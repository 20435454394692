/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function createFavorite(payload, accessToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/favorite`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createFavorite;
