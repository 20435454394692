import React from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";

function Alert({ color, heading, body, direction, directionName = "Dashboard" }) {
  return (
    <div className={`rounded-md bg-${color}-100 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <span className={`material-icons-outlined text-${color}-400`}>report_problem</span>
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color}-500`}>{heading}</h3>
          <div className={`mt-2 text-sm text-${color}-500`}>
            <ul role="list" className="list-disc pl-5 space-y-1">
              {body.map(text => (
                <li key={uuid()}>{text}</li>
              ))}
            </ul>
          </div>
          {direction ? (
            <div className="mt-4">
              <a href={direction} className="text-md text-primary-400 underline capitalize">
                {directionName}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  color: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
  direction: PropTypes.string,
  directionName: PropTypes.string,
};

export default Alert;
