/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import { MdLocalSee } from "react-icons/md";
import FileUploader from "../../../../components/file_uploader/FileUploader";
import useUserContext from "./../../../../hooks/useUserContext";
import useScrollPage from "../../../../hooks/useScrollPage";
import { LISTING_COVER_TEMPLATE, LOGO_TEMPLATE, PLANS } from "../../../../constant";
import notify from "../../../../services/toast";
import { Link } from "react-router-dom";

const logoCriteria = { requiredSize: 5e6, requiredDimensions: { width: 400, height: 400 } };
const coverPhotoCriteria = { requiredSize: 6e6, requiredDimensions: { width: 400, height: 400 } };
const galleryPhotoCriteria = { requiredSize: 5e6, requiredDimensions: { width: 1000, height: 800 } };

function PhotoDisplay({ callback }) {
  return <MdLocalSee onClick={callback} className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer" />;
}

function Photos({ steps, currentStep, setCurrentStep, listing, setListing }) {
  useScrollPage();

  const {
    userState,
    subscription: { product },
  } = useUserContext();
  const [logo, setLogo] = React.useState(listing?.photos?.logo || null);
  const [coverPhoto, setCoverPhoto] = React.useState(listing?.photos?.coverPhoto || null);
  const [gallery, setGallery] = React.useState(listing?.photos?.gallery || []);

  const [logoTemplate, setLogoTemplate] = React.useState(null);
  const [coverTemplate, setCoverTemplate] = React.useState(null);
  const [galleryTemplate, setGalleryTemplate] = React.useState([]);

  const handleListingLogoUpload = result => {
    setLogo(result);
  };

  const handleListingTemplateLogo = event => {
    const templateIndex = parseInt(event.target.value, 10);
    setLogoTemplate(templateIndex);
    const template = LOGO_TEMPLATE[templateIndex];
    setLogo(template);
  };

  const handleListingCoverPhotoUpload = result => {
    setCoverPhoto(result);
  };

  const handleListingTemplateCover = event => {
    const templateIndex = parseInt(event.target.value, 10);
    setCoverTemplate(templateIndex);
    const template = LISTING_COVER_TEMPLATE[templateIndex];
    setCoverPhoto(template);
  };

  const handleListingGalleryUpload = result => {
    setGallery(prevState => {
      return [...prevState, result];
    });
  };

  const handleListingTemplateGallery = event => {
    if (gallery?.length >= 9) {
      notify("You can upload a maximum of 9 images", "error");
      return;
    }
    const templateIndex = parseInt(event.target.value, 10);
    if (galleryTemplate.includes(templateIndex)) {
      const newTemplates = galleryTemplate.filter(template => template !== templateIndex);
      setGalleryTemplate(newTemplates);
      setGallery([]);
      for (const template of newTemplates) {
        const gallery = LISTING_COVER_TEMPLATE[template];
        setGallery(prevState => {
          return [...prevState, gallery];
        });
      }
      return;
    }
    setGalleryTemplate(prev => {
      return [...prev, templateIndex];
    });
    const template = LISTING_COVER_TEMPLATE[templateIndex];
    setGallery(prevState => {
      return [...prevState, template];
    });
  };

  const handleSubmit = () => {
    const payload = {
      logo: logo || null,
      coverPhoto: coverPhoto || null,
      gallery: gallery.length ? gallery : null,
    };

    if (logo || coverPhoto || gallery) {
      setListing(prevState => {
        return { ...prevState, photos: payload };
      });
    }

    setCurrentStep(currentStep + 1);
  };

  const handleGoBack = () => {
    const payload = {
      logo: logo || null,
      coverPhoto: coverPhoto || null,
      gallery: gallery.length ? gallery : null,
    };

    if (logo || coverPhoto || gallery) {
      setListing(prevState => {
        return { ...prevState, photos: payload };
      });
    }

    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="w-full overflow-hidden bg-white shadow rounded-lg">
      <div className="w-full px-4 py-5 sm:p-6 space-y-2">
        <h1 className="text-xl font-bold text-success-800">Upload Photos</h1>
        <p className="text-sm text-gray-400">Spruce up your profile with photos that showcase all you do!</p>
        <div className="w-full flex items-center justify-start gap-4 flex-wrap">
          <div className="w-full flex flex-col justify-center space-y-4">
            <h1 className="text-primary-800 text-lg">Select a logo template</h1>
            <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
              {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
                <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                  {logo?.small.url ? <img src={logo.small.url} className="w-full h-full" alt="logo upload" /> : <p className="w-full pt-8 text-md text-center text-gray-400">Logo</p>}
                  <FileUploader
                    fileType="image/*"
                    isMultiple={false}
                    validationCriteria={logoCriteria}
                    DisplayIcon={PhotoDisplay}
                    accessToken={userState.accessToken}
                    folder="profile_photos"
                    description="Logo - Min: 400 X 400, Max: 5MB"
                    numberOfFiles={1}
                    callback={handleListingLogoUpload}
                  />
                </div>
              {/* ) : (
                <Link to="/dashboard/settings/subscription" className="text-primary-400 text-bold text-lg underline">
                  Upgrade subscription to add custom logo image
                </Link>
              )} */}

              <div className="w-full sm:max-w-[100%] flex gap-3 overflow-x-auto">
                {LOGO_TEMPLATE.length
                  ? LOGO_TEMPLATE.map((template, index) => {
                      return (
                        <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                          <input
                            type="radio"
                            name="template"
                            value={index}
                            onChange={handleListingTemplateLogo}
                            className="sr-only"
                            aria-labelledby={`template-${index}`}
                            aria-describedby={`template-${index}`}
                          />
                          <div className="relative h-[200px] w-[200px]">
                            <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                          </div>
                          {logoTemplate !== null && index === logoTemplate ? (
                            <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : null}
                        </label>
                      );
                    })
                  : null}
              </div>
            </div>

            <h1 className="text-primary-800 text-lg">Select a cover photo template</h1>
            <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
              {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
                <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                  {coverPhoto?.small.url ? <img src={coverPhoto.small.url} className="w-full h-full" alt="logo upload" /> : <p className="w-full text-md text-center text-gray-400">Cover Photo</p>}
                  <FileUploader
                    fileType="image/*"
                    isMultiple={false}
                    validationCriteria={coverPhotoCriteria}
                    DisplayIcon={PhotoDisplay}
                    accessToken={userState.accessToken}
                    folder="profile_photos"
                    description="Cover Photo - Min: 400 x 400, Max: 6MB"
                    numberOfFiles={1}
                    callback={handleListingCoverPhotoUpload}
                  />
                </div>
              {/* ) : (
                <Link to="/dashboard/settings/subscription" className="text-primary-400 text-bold text-lg underline">
                  Upgrade subscription to add custom cover image
                </Link>
              )} */}
              <div className="w-full sm:max-w-[100%] flex gap-3 overflow-x-auto">
                {LISTING_COVER_TEMPLATE.length
                  ? LISTING_COVER_TEMPLATE.map((template, index) => {
                      return (
                        <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                          <input
                            type="radio"
                            name="template"
                            value={index}
                            onChange={handleListingTemplateCover}
                            className="sr-only"
                            aria-labelledby={`template-${index}`}
                            aria-describedby={`template-${index}`}
                          />
                          <div className="relative h-[200px] w-[200px]">
                            <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                          </div>
                          {coverTemplate !== null && index === coverTemplate ? (
                            <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : null}
                        </label>
                      );
                    })
                  : null}
              </div>
            </div>

            <h1 className="text-primary-800 text-lg">Select gallery photo templates</h1>
            <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:gap-4 sm:space-y-0">
              {/* {["PREMIUM", "COHATCH", "LOCAL DISCOUNT"].includes(PLANS[product]) ? ( */}
                <div className="relative border-4 border-dashed rounded-md h-[200px] w-[200px]">
                  {gallery?.length ? (
                    <>
                      <img src={gallery[gallery.length - 1].small.url} className="w-full h-full" alt="logo upload" />
                      {gallery.length ? <p className="text-md text-success-800 font-bold text-center">+ {gallery.length - 1}</p> : null}
                    </>
                  ) : (
                    <p className="w-full text-md text-center text-gray-400">Gallery Photos</p>
                  )}
                  <FileUploader
                    fileType="image/*"
                    isMultiple={true}
                    validationCriteria={galleryPhotoCriteria}
                    DisplayIcon={PhotoDisplay}
                    accessToken={userState.accessToken}
                    folder="profile_photos"
                    description="Gallery Photos - Min: 1000 x 800, Max: 6MB"
                    numberOfFiles={9 - gallery.length}
                    callback={handleListingGalleryUpload}
                  />
                </div>
              {/* ) : (
                <Link to="/dashboard/settings/subscription" className="text-primary-400 text-bold text-lg underline">
                  Upgrade subscription to add custom gallery images
                </Link>
              )} */}
              <div className="w-full sm:max-w-[100%] flex gap-3 overflow-x-auto">
                {LISTING_COVER_TEMPLATE.length
                  ? LISTING_COVER_TEMPLATE.map((template, index) => {
                      return (
                        <label key={index} className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer">
                          <input
                            type="radio"
                            name={`template-${index}`}
                            value={index}
                            onChange={handleListingTemplateGallery}
                            className="sr-only"
                            aria-labelledby={`template-${index}`}
                            aria-describedby={`template-${index}`}
                          />
                          <div className="relative h-[200px] w-[200px]">
                            <img src={template.small.url} className="w-full h-full" alt={template.small.filename} />
                          </div>
                          {galleryTemplate.length && galleryTemplate.includes(index) ? (
                            <svg className="h-5 w-5 text-primary-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : null}
                        </label>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
            <div>{currentStep <= 0 ? null : <ButtonPrimary icon="arrow_back" text={steps[currentStep - 1]} size="md" action={handleGoBack} />}</div>
            <div>{currentStep >= steps.length ? null : <ButtonPrimary icon="arrow_forward" text={steps[currentStep + 1]} size="md-1" action={handleSubmit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Photos;
