/* eslint-disable id-length */
/* eslint-disable new-cap */
/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Preview, print } from "react-html2pdf";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { BUSINESS_CATEGORY, MAGIC_NUMBERS } from "../../constant";
import ButtonTertiary from "../../components/button/ButtonTertiary";
import Prompt from "../../components/prompt/Prompt";
import useUserContext from "../../hooks/useUserContext";
import addFavorite from "../../pages/local_discount/addFavorite";
import deleteFavorite from "../../pages/local_discount/deleteFavorite";
import deleteCoupon from "../../pages/local_discount/deleteCoupon";
import updateCoupon from "../../pages/local_discount/updateCoupon";
import notify from "../../services/toast";
import { showPrompt } from "../../services/util";
import EditCouponModal from "./EditCouponModal";
import CouponLogo from "../../assets/CouponLogo.png";
import { Link } from "react-router-dom";
import { MdTimer } from "react-icons/md";
import Option from "../option/Option";

function CouponCard({ coupon, isFavorite, isOwner, fid, onReload, deleteListing, editListing }) {
  const { listing, couponName, useAge, couponCode, background, expiration, _id } = coupon;
  const { userState } = useUserContext();
  const promptRef = React.useRef(null);
  const [displayPromptMessage, setDisplayPromptMessage] = React.useState({ heading: "", body: "" });
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showBackSide, setShowBackSide] = React.useState(false);

  const handleShowPrompt = () => {
    showPrompt("Delete", "Are your sure you want to delete this coupon? This action cannot be undone.", promptRef, "open", setDisplayPromptMessage);
  };

  const handleFavorite = async () => {
    if (!isFavorite) {
      const response = await addFavorite(
        {
          target: _id,
          kind: "Coupon",
        },
        userState.accessToken
      );
      if (response.status === "success") {
        notify("This coupon was successfully added to your favorite list.", "info");
        if (onReload) {
          onReload();
        }
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } else {
      const response = await deleteFavorite(fid, userState.accessToken);
      if (response.status === "success") {
        notify("This coupon was successfully removed from your favorite list.", "info");
        if (onReload) {
          onReload();
        }
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    }
  };

  const handleDelete = async () => {
    const response = await deleteCoupon(_id, userState.accessToken);
    if (response.status === "success") {
      notify("This coupon was successfully deleted.", "info");
      if (onReload) {
        onReload();
      }
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
  };

  const handleSave = async body => {
    const response = await updateCoupon(_id, body, userState.accessToken);
    if (response.status === "success") {
      notify("This coupon was successfully updated.", "info");
      if (onReload) {
        onReload();
      }
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
  };

  const handlePrint = id => {
    const isSmall = screen.width < 1600;
    if (isSmall) {
      const input = document.getElementById(`coupon-print-laptop-${id}`);
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0);
        pdf.save("coupon.pdf");
      });
    } else {
      print("coupon", `coupon-print-${id}`);
    }
  };

  const expirationDate = new Date(parseInt(expiration.substring(0, 4), 10), parseInt(expiration.substring(5, 7), 10) - 1, parseInt(expiration.substring(8, 10), 10));

  const returnActions = () => {
    return [
      { text: "Delete", icon: "delete", key: "delete", action: () => deleteListing(listing._id) },
      { text: "Edit", icon: "edit", key: "edit", action: () => editListing(listing) },
    ];
  };

  const action = React.useMemo(() => returnActions(), []);

  return (
    <>


      <div className="rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-success-800 focus-within:ring-offset-2 hover:border-gray-400">
        <div className="bg-white p-2 flex justify-center">
          {!!isOwner && (
            <span className="material-icons cursor-pointer text-lg mr-1" onClick={handleShowPrompt}>
              delete
            </span>
          )}
          {!!isOwner && (
            <span className="material-icons cursor-pointer text-lg" onClick={() => setShowEditModal(true)}>
              edit
            </span>
          )}
        </div>
        <div className="relative overflow-auto flex items-center space-x-3 rounded-lg  bg-white px-4 py-3 ">
          <span className="material-icons cursor-pointer text-lg mr-1" onClick={handleFavorite}>
            {isFavorite ? "favorite" : "favorite_border"}
          </span>
          <div className="flex-shrink-0">

            <img className="mx-auto h-24 w-24 flex-shrink-0 rounded-full"
              src={
                listing && listing.businessPhotos && listing.businessPhotos.logo
                  ? listing.businessPhotos.logo.small.url
                  : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"
              }
              alt="Listing Logo" />

          </div>
          <Link to={`/dashboard/marketplace/listing/${listing._id}`} className="focus:outline-none">
            <div className="min-w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{listing ? listing.businessName : "-"}</p>
              <p className=" text-sm text-gray-500">How To Use: {useAge}</p>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <svg className="h-5 w-5 text-gray-400 " fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                  </svg>
                </dt>
                <dd className="text-sm text-gray-500">{listing ? listing.businessAddress.address : "-"}</dd>
              </div>

              <p className="truncate text-sm text-gray-500">Ends: {format(expirationDate, "MMMM do YYY")}</p>

            </div>
          </Link>

          <div className="flex w-52 h-32 items-center justify-center bg-[url('/src/assets/grungTextureGreen.jpg')] border-2 border-dashed rounded-l-lg font-medium text-white">
            <p className="text-sm text-center leading-6 text-white">{couponName}
              <p className="rounded-full bg-green-50 px-2 py-1 text-md font-bold text-green-700 ring-1 ring-inset ring-green-600/20">
                {couponCode}
              </p>
            </p>
          </div>

        </div>
      </div>

      {/* <li >
            <span className="justify-end text-right material-icons cursor-pointer text-xlg mr-1" onClick={handleFavorite}>
              {isFavorite ? "favorite" : "favorite_border"}
            </span>
            <Link to={`/marketplace/listing/${listing._id}`} >
                <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                  src={
                    listing && listing.businessPhotos && listing.businessPhotos.logo
                      ? listing.businessPhotos.logo.small.url
                      : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"
                  }
                  alt="Listing Logo" />
              </Link>
              <h3 className="mt-2 text-base font-semibold leading-7 tracking-tight text-black">{listing ? listing.businessName : "-"}</h3>
              <p className="text-sm leading-6 text-black">{couponName}</p>
              <p className="text-sm leading-6 text-black"> How To Use: {useAge}</p>
              <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{couponCode}</span>
              <ul role="list" className="mt-6 flex justify-center gap-x-6">
                <li>
                  <a href="#" className="text-black hover:text-gray-300">
                    <span className="sr-only">Twitter</span>
                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    {listing ? listing.businessAddress.address : "-"}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-black hover:text-gray-300">
                    <span className="sr-only">LinkedIn</span>
                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                    </svg>
                    {format(expirationDate, "MMMM do YYY")}
                  </a>
                </li>
              </ul>
            </li> */}






      {/* <div className="w-[300px] sm:w-[500px] h-[320px] sm:h-[320px] mt-[30px] sm:mt-[50px] max-w-lg bg-transparent cursor-pointer group perspective">
        <div className={`relative h-full shadow-xl rounded preserve-3d duration-1000 ${showBackSide ? "custom-rotate-y-180" : ""}`}>
          <div className="absolute flex flex-col justify-between w-full h-full backface-hidden bg-white">
            <div className="font-extrabold text-success-800 text-xs sm:text-base flex justify-between items-center p-3 border-b-2 border-success-800">
              <div className="w-full flex gap-3 items-center">
              <Link to={`/marketplace/listing/${listing._id}`}>
                <h1 className="w-[150px] capitalize truncate">{listing ? BUSINESS_CATEGORY[listing.businessCategory]["mainCategory"]["label"] : "-"}</h1>
                </Link>
              </div>
              <div className="w-full flex justify-end items-center">
                <span className="material-icons cursor-pointer text-lg mr-1" onClick={handleFavorite}>
                  {isFavorite ? "favorite" : "favorite_border"}
                </span>
                <span
                  className="material-icons cursor-pointer text-lg mr-1"
                  onClick={() => {
                    handlePrint(_id);
                  }}>
                  print
                </span>
                {!!isOwner && (
                  <span className="material-icons cursor-pointer text-lg mr-1" onClick={handleShowPrompt}>
                    delete
                  </span>
                )}
                {!!isOwner && (
                  <span className="material-icons cursor-pointer text-lg" onClick={() => setShowEditModal(true)}>
                    edit
                  </span>
                )}
              </div>
            </div>

            <div className="bg-center bg-no-repeat bg-cover grow opacity-100" style={{ backgroundImage: `url(${background && background.large ? background.large.url : ""})` }}>
              <div style={{ backgroundColor: "rgba(0, 0, 0, 0.55)" }}>
                <div className="text-center text-white space-y-12">
                <Link to={`/marketplace/listing/${listing._id}`}>
                  <h2 className="text-base font-bebas pt-12 sm:text-3xl">{listing ? listing.businessName : "-"}</h2>
                  </Link>
                  <h2 className="text-base sm:text-xl">{couponName}</h2>
                  <span className="text-md sm:text-xl font-extrabold ">Coupon Code: </span>
                  <span className="text-white p-0 bg-primary-400 text-lg sm:text-xl font-extrabold uppercase border border-dotted border-success-800 cursor-copy pr-1"> {couponCode}</span>
                </div>

                <div className="text-center text-white min-h-[30%] p-5">
                  <div className="w-[100px] mt-2 mx-auto">
                    <ButtonTertiary text="Details" size="md" action={() => setShowBackSide(true)} />
                  </div>
                </div>
              </div>
            </div>

            <Link to={`/marketplace/listing/${listing._id}`} className="absolute left-[120px] -top-[20px] h-[60px] w-[60px] sm:left-[190px] sm:-top-[40px] sm:h-[120px] sm:w-[120px]">
              <img
                src={
                  listing && listing.businessPhotos && listing.businessPhotos.logo
                    ? listing.businessPhotos.logo.small.url
                    : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"
                }
                alt="Listing Logo"
                className="inline-block rounded-full object-cover h-[60px] w-620px] sm:h-[120px] sm:w-[120px]"
              />
            </Link>
          </div>
          <div className="absolute flex flex-col justify-between w-full h-full custom-rotate-y-180 backface-hidden bg-gray-200 text-success-800">
            <div className="font-extrabold flex justify-between items-center p-3 border-b-2 border-gray-200">
              <div className="w-full flex gap-3 items-center">
                <span className="material-icons-outlined">business</span>
                <Link to={`/marketplace/listing/${listing._id}`}>
                <h2 className="text-base sm:text-lg font-bold capitalize">{listing ? listing.businessName : "-"}</h2>
                </Link>
              </div>
              <div className="w-28 justify-end text-right items-center">
                <span className="material-icons cursor-pointer text-lg mr-1" onClick={handleFavorite}>
                  {isFavorite ? "favorite" : "favorite_border"}
                </span>
                <span
                  className="material-icons cursor-pointer text-lg mr-1"
                  onClick={() => {
                    handlePrint(_id);
                  }}>
                  print
                </span>
                {!!isOwner && (
                  <span className="material-icons cursor-pointer text-lg mr-1" onClick={handleShowPrompt}>
                    delete
                  </span>
                )}
                {!!isOwner && (
                  <span className="material-icons cursor-pointer text-lg" onClick={() => setShowEditModal(true)}>
                    edit
                  </span>
                )}
              </div>
            </div>
            <p className="text-md min-h-[24%] p-3 text-center">
              <p className="font-bold">How To Use:</p>
              <p>{useAge}</p>
            </p>
            <p className="text-md min-h-[15%] p-1 text-center">
              <p className="font-bold">Location:</p>
              <p>{listing ? listing.businessAddress.address : "-"}</p>
            </p>
            <div className="w-[100px] mt-2 mx-auto">
              <ButtonTertiary icon="arrow_back" size="md" action={() => setShowBackSide(false)} />
            </div>
            <div className="flex justify-between items-center p-3">
              <p className="flex flex-col items-center gap-2">
                <p className="font-extrabold">Coupon Code:</p>
                <p className="flex items-center gap-2">
                  <span className="material-icons">discount</span>
                  <span className="text-sm sm:text-xl font-extrabold uppercase border border-dotted border-primary-500 p-2 cursor-copy">{couponCode}</span>
                </p>
              </p>
              <p className="flex flex-col items-center gap-2">
                <p className="font-extrabold">Expiration Date:</p>
                <p className="flex items-center gap-2">
                  <span className="material-icons">timer</span>
                  <span className="text-sm sm:text-xl font-extrabold  uppercase">{format(expirationDate, "MMMM do YYY")}</span>
                </p>
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="absolute left-[-2000px]">
        <div id={`coupon-print-laptop-${_id}`} className="w-[350px] pl-[50px] pt-[60px] bg-transparent">
          <div className="relative h-full shadow-xl rounded">
            <div className="flex flex-col justify-between w-full h-full bg-gray-200">
              <div className="font-extrabold text-success-800 text-base flex justify-between items-center p-3 pb-2 border-b-2 border-success-800">
                <div className="w-full flex gap-3 items-center">
                  <h1 className="text-sm capitalize truncate mt-0">&nbsp;</h1>
                </div>
              </div>
              <div className="bg-center bg-no-repeat bg-cover grow bg-[url('/src/assets/Footer.jpg')]">
                <div className="text-center text-white space-y-5 ">
                  <h2 className="text-md pt-8 mb-1">{couponName}</h2>
                  <span className="text-sm font-extrabold cursor-copy">Coupon Code:</span>
                  <span className="text-white bg-primary-400 ml-2 pr-1 pl-1 text-sm font-extrabold uppercase border border-dotted border-success-800" style={{ paddingBottom: "14px" }}>
                    {couponCode}
                  </span>
                </div>
                <div className="text-center text-white min-h-[30%] p-3">
                <Link to={`/marketplace/listing/${listing._id}`}>
                  <h2 className="text-md">{listing ? listing.businessName : "-"}</h2>
                  </Link>
                  <div className="font-bold">{listing ? listing.businessAddress.address : "-"}</div>
                </div>
              </div>
              <div className="absolute left-[114px] -top-[40px] h-[72px] w-[72px]">
                <img src={CouponLogo} alt="Listing Logo" className="inline-block rounded-full object-cover h-[72px] w-[72px]" />
              </div>
            </div>
            <div className="h-[20px] bg-white"></div>
            <div className="flex flex-col justify-between w-full h-full bg-gray-200 text-success-800">
              <div className="font-extrabold flex justify-between items-center p-3 border-b-2 border-gray-200">
                <div className="w-full flex gap-3 items-center">
                  <span className="material-icons-outlined">business</span>
                  <Link to={`/marketplace/listing/${listing._id}`}>
                  <h2 className="text-sm font-bold capitalize">{listing ? listing.businessName : "-"}</h2>
                  </Link>
                </div>
              </div>
              <p className="text-sm min-h-[30%] p-3 text-center">
                <p className="font-extrabold">How To Use:</p>
                <p>{useAge}</p>
              </p>
              <div className="flex justify-between items-center mt-3 p-3">
                <p className="flex flex-col items-center gap-2">
                  <p className="font-bold text-md">Coupon Code:</p>
                  <p className="flex items-center gap-2">
                    <span className="material-icons">discount</span>
                    <span className="text-sm uppercase">{couponCode}</span>
                  </p>
                </p>
                <p className="flex flex-col items-center gap-2">
                  <p className="font-bold text-md">Expiration Date:</p>
                  <p className="flex items-center gap-2">
                    <span className="material-icons">timer</span>
                    <span className="text-sm uppercase">{format(expirationDate, "MMMM do YYY")}</span>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="hidden absolute left-[-1000px]">
        <Preview id={`coupon-print-${_id}`}>
          <div className="w-[500px] pt-[50px] mx-auto bg-transparent">
            <div className="relative h-full shadow-xl rounded">
              <div className="flex flex-col justify-between w-full h-full bg-gray-200">
                <div className="font-extrabold text-success-800 text-xs sm:text-base flex justify-between items-center p-3 pb-2 border-b-2 border-success-800">
                  <div className="w-full flex gap-3 items-center">
                  <Link to={`/marketplace/listing/${listing._id}`}>
                    <h1 className="w-[150px] capitalize truncate mt-0">{listing ? BUSINESS_CATEGORY[listing.businessCategory]["mainCategory"]["label"] : "-"}</h1>
                    </Link>
                  </div>
                </div>
                <div className="bg-center bg-no-repeat bg-cover grow bg-[url('/src/assets/Footer.jpg')]">
                  <div className="text-center text-white space-y-5 ">
                    <h2 className="text-base sm:text-2xl pt-10">{couponName}</h2>
                    <span className="text-md sm:text-xl font-extrabold cursor-copy">Coupon Code:</span>
                    <span className="text-white bg-primary-400 pt-1 pb-1 pr-1 pl-1 text-lg sm:text-xl font-extrabold uppercase border border-dotted border-success-800 cursor-copy">{couponCode}</span>
                  </div>
                  <div className="text-center text-white min-h-[30%] p-3">
                  <Link to={`/marketplace/listing/${listing._id}`}>
                    <h2 className="text-base sm:text-2xl">{listing ? listing.businessName : "-"}</h2>
                    </Link>
                    <div className="font-bold">{listing ? listing.businessAddress.address : "-"}</div>
                  </div>
                </div>
                <div className="absolute left-[120px] -top-[20px] h-[60px] w-[60px] sm:left-[190px] sm:-top-[40px] sm:h-[120px] sm:w-[120px]">
                  <img src={CouponLogo} alt="Listing Logo" className="inline-block rounded-full object-cover h-[60px] w-620px] sm:h-[120px] sm:w-[120px]" />
                </div>
              </div>
              <div className="h-[30px] bg-white"></div>
              <div className="flex flex-col justify-between w-full h-full bg-gray-200 text-success-800">
                <div className="font-extrabold flex justify-between items-center p-3 border-b-2 border-gray-200">
                  <div className="w-full flex gap-3 items-center">
                    <span className="material-icons-outlined">business</span>
                    <Link to={`/marketplace/listing/${listing._id}`}>
                    <h2 className="text-base sm:text-lg font-bold capitalize">{listing ? listing.businessName : "-"}</h2>
                    </Link>
                  </div>
                </div>
                <p className="text-md min-h-[30%] p-3 text-center">
                  <p className="font-extrabold">How To Use:</p>
                  <p>{useAge}</p>
                </p>
                <div className="flex justify-between items-center mt-3 p-3">
                  <p className="flex flex-col items-center gap-2">
                    <p className="font-extrabold">Coupon Code:</p>
                    <p className="flex items-center gap-2">
                      <span className="material-icons">discount</span>
                      <span className="text-sm sm:text-xl font-extrabold uppercase border border-dotted border-primary-500 pt-2 pb-2 px-2 cursor-copy">{couponCode}</span>
                    </p>
                  </p>
                  <p className="flex flex-col items-center gap-2">
                    <p className="font-extrabold">Expiration Date:</p>
                    <p className="flex items-center gap-2">
                      <span className="material-icons">timer</span>
                      <span className="text-sm sm:text-xl font-extrabold uppercase">{format(expirationDate, "MMMM do YYY")}</span>
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Preview>
      </div> */}
      {!!isOwner && showEditModal && <EditCouponModal open={showEditModal} coupon={coupon} onClose={() => setShowEditModal(false)} onSave={handleSave} />}
      {!!isOwner && <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={promptRef} action={handleDelete} />}
    </>
  );
}

CouponCard.propTypes = {
  coupon: PropTypes.object.isRequired,
  isFavorite: PropTypes.bool,
  isOwner: PropTypes.bool,
  fid: PropTypes.string,
  onReload: PropTypes.func,
};

export default CouponCard;
