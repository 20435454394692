import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function updateGroup(accessToken, groupId, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/group/${groupId}`;

  const response = await makeAPIRequest(API_ENDPOINT, "PUT", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateGroup;
