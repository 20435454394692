/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import NavigationAuth from "../navigation_auth/NavigationAuth";
import Logo from "../marketplace_logo/Marketplace_logo";
import ServicesFeature from "../marketplace_features/services_feature/ServicesFeature";
import LocalShopingFeature from "../marketplace_features/local_shoping/LocalShoppingFeature";
import LocalFoodFeature from "../marketplace_features/local_food/LocalFoodFeature";
import ComResources from "../marketplace_features/community_resources/ComResources";
import MarketplaceNavFeatures from "../navigation_features/MarketPlaceNavFeatures";

function MarketplaceNavigation() {
  const [mobile, setMobile] = React.useState(false);

  return (
    <div className="md:sticky md:top-0 md:z-30 bg-primary-400 font-roboto">
      <div className="relative z-30">
        <div className="w-full mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 xl:justify-start md:space-x-10">
          <div>
            <Logo color="white" />
          </div>
          <div className="-mr-2 -my-2 xl:hidden">
            <button
              onClick={() => setMobile(!mobile)}
              type="button"
              className="bg-primary-400 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-200"
              aria-expanded="false">
              <span className="sr-only">Open menu</span>
              <span className="material-icons-outlined text-3xl">menu</span>
            </button>
          </div>
          <div className="hidden xl:flex-1 xl:flex xl:items-center xl:justify-between">
            <nav className="flex space-x-10">
              
              <div className="group">
                <button
                  type="button"
                  className="text-white group bg-primary-400 rounded-md inline-flex items-center text-lg font-bold hover:text-gray-200 focus:outline-none ease-linear duration-500"
                  aria-expanded="false">
                  <span>Services</span>
                  <span className="material-icons-outlined">expand_more</span>
                </button>
                <div className="group-hover:visible group-hover:opacity-100 group-hover:-translate-y-3 relative z-10 invisible opacity-0 top-full inset-x-0 -translate-y-4 transition-all duration-500">
                  <ServicesFeature />
                </div>
              </div>
              
              <div className="group">
                <button
                  type="button"
                  className="text-white group bg-primary-400 rounded-md inline-flex items-center text-lg font-bold hover:text-gray-200 focus:outline-none ease-linear duration-500"
                  aria-expanded="false">
                  <span>Local Shopping</span>
                  <span className="material-icons-outlined">expand_more</span>
                </button>
                <div className="group-hover:visible group-hover:opacity-100 group-hover:-translate-y-3 relative z-10 invisible opacity-0 top-full inset-x-0 -translate-y-4 transition-all duration-500">
                  <LocalShopingFeature />
                </div>
              </div>
              
              <div className="group">
                <button
                  type="button"
                  className="text-white group bg-primary-400 rounded-md inline-flex items-center text-lg font-bold hover:text-gray-200 focus:outline-none ease-linear duration-500"
                  aria-expanded="false">
                  <span>Local Food</span>
                  <span className="material-icons-outlined">expand_more</span>
                </button>
                <div className="group-hover:visible group-hover:opacity-100 group-hover:-translate-y-3 relative z-10 invisible opacity-0 top-full inset-x-0 -translate-y-4 transition-all duration-500">
                  <LocalFoodFeature />
                </div>
              </div>
             
              <div className="group">
                <button
                  type="button"
                  className="text-white group bg-primary-400 rounded-md inline-flex items-center text-lg font-bold hover:text-gray-200 focus:outline-none ease-linear duration-500"
                  aria-expanded="false">
                  <span>Community Resources</span>
                  <span className="material-icons-outlined">expand_more</span>
                </button>
                <div className="group-hover:visible group-hover:opacity-100 group-hover:-translate-y-3 relative z-10 invisible opacity-0 top-full inset-x-0 -translate-y-4 transition-all duration-500">
                  <ComResources />
                </div>
              </div>
            </nav>
            <div className="flex items-center md:ml-12">
              <NavigationAuth />
            </div>
          </div>
        </div>
      </div>

      <div className={`absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right ${mobile ? "block" : "hidden md:hidden"}`}>
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5 sm:pb-8">
            <div className="flex items-center justify-between">
              <div>
                <Logo color="green" />
              </div>
              <div className="-mr-2">
                <button
                  onClick={() => setMobile(!mobile)}
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                  <span className="sr-only">Close menu</span>
                  <span className="material-icons-outlined">close</span>
                </button>
              </div>
            </div>
            <div className="mt-6 sm:mt-8">
              <nav>
                <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                  <MarketplaceNavFeatures />
                </div>
                <hr className="mt-5" />
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 bg-primary-400">
            <div className="flex justify-between items-center">
              <NavigationAuth />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MarketplaceNavigation);
