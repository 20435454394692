/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
import React from "react";
import ButtonTeritary from "../button/ButtonTertiary";
import { Link } from "react-router-dom";

function FooterSignUp() {
  return (
<div className="relative top-20">
    <div className="bg-no-repeat bg-cover bg-[url('/src/assets/grungTextureGreen.jpg')]">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center ">
        <div className="lg:w-0 lg:flex-1">
          <h2 className="text-xl font-bold text-center tracking-tight text-success-800 sm:text-4xl" id="newsletter-headline">
          Want to list your business for thousands of COhatch members to see? Sign up here.
          </h2>
        </div>
        <div className="mt-8 lg:mt-0 lg:ml-8">
          <form className="sm:flex items-center">
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0 ">
          <Link to="/pricing">
              <ButtonTeritary className="bg-success-400" size="md" text="Sign Up"></ButtonTeritary></Link>
            </div>
          </form>
          <p className="mt-3 text-sm text-white">
            We care about the protection of your data. Read our 
            <Link to="/Policy" className="text-success-800 font-medium underline px-1">
              Privacy Policy
            </Link>
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterSignUp;
