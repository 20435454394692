/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Spinner from "../../../components/spinner/Spinner";
import DefaultState from "../../../components/default_state/DefaultState";
import CouponCard from "../../../components/coupon_card/CouponCard";
import Map from "../../../components/map/Map";
import Pagination from "../../../components/pagination/Pagination";
import getDiscounts from "../getDiscounts";
import generateCategory from "../generateCategory";
import useUserContext from "../../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import { debounce } from "../../../services/util";
import Footer from "../../../components/footer/Footer";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";
import featured from "../featured";

function AllDiscounts() {
  useScrollPage();
  useTitle("Featured Discounts");

  const { userState } = useUserContext();
  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [isDiscountLoading, setIsDiscountLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ limit: 30, total: 0, page: 0, remaining: 0 });
  const categoryRef = useRef(category);
  categoryRef.current = category;

  const fetchDiscounts = async (accessToken, page, _keyword, _category) => {
    setIsDiscountLoading(true);
    const response = await featured(
      {
        page,
        keyword: _keyword,
        category: _category,
        limit: pagination.limit,
      },
      accessToken
    );
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setDiscounts(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsDiscountLoading(false);
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchDiscounts(userState.accessToken, 0, query, categoryRef.current);
    setKeyword(query);
  };

  const handleSearchByCategory = value => {
    fetchDiscounts(userState.accessToken, 0, keyword, value);
    setCategory(value);
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page - 1, keyword, category);
  };

  const handleNextFetch = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page + 1, keyword, category);
  };

  const handleReload = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page, keyword, category);
  };

  const getListingCoordinates = discounts => {
    const coordinates = [];
    const info = {};
    const listings = discounts.map(discount => discount.listing);
    for (const listing of listings) {
      coordinates.push(listing.businessAddress.coordinates);
      info[`${listing.businessAddress.coordinates.lat}${listing.businessAddress.coordinates.lng}`] = {
        image: listing.businessPhotos?.coverPhoto?.small ? listing.businessPhotos.coverPhoto.small.url : null,
        listingName: listing.businessName,
        address: listing.businessAddress.address,
        listingId: listing._id,
      };
    }

    return [coordinates, info];
  };

  const listingCoordinates = React.useMemo(() => getListingCoordinates(discounts || []), [discounts]);

  useEffect(() => {
    fetchDiscounts(userState.accessToken, 0, "", "");
  }, []);

  return (
    <div className="h-full w-full">
      <div className="w-full h-[450px] xl:h-[600px] mt-5 relative">
        {!isDiscountLoading && <Map locations={listingCoordinates} />}
        <div className="flex absolute shadow-sm lg:inset-x-12 sm:inset-x-0 bottom-4 m-5 border-2 border-gray-300 hover:ring-primary-400 hover:border-primary-400 rounded-full bg-white p-2 h-[60px] items-center justify-between">
          <div className="w-full relative  mt-1 flex items-center border-r-2 pr-3">
            <input type="text" name="search" onInput={debouncedHandleSearch} placeholder="Search discount" className="block w-full border-0 focus:border-0 focus:ring-0 max-w-md sm:text-base" />
          </div>
          <div className="w-full mx-3" id="discountCategorySelect">
            <Select
              className="border-0"
              placeholder="Select a category..."
              isDisabled={false}
              isClearable={true}
              isLoading={false}
              isRtl={false}
              isSearchable={true}
              name="category"
              options={generateCategory()}
              onChange={event => handleSearchByCategory(event ? event.value : "")}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ecf8ef",
                  primary: "#56c271",
                },
              })}
            />
          </div>
          <div className="w-[120px]">
            <button
              disabled={isDiscountLoading}
              className={`w-full ${
                isDiscountLoading ? "cursor-not-allowed" : "cursor-pointer"
              } inline-flex items-center justify-center gap-2 px-4 py-1.5 border border-transparent text-md font-medium rounded-full shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}>
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="w-full mt-10 flex flex-col items-center justify-center gap-5 sm:flex-wrap sm:justify-start sm:flex-row">
        {!isDiscountLoading && !discounts.length && <DefaultState icon="discount" heading="No featured discounts" description="No featured discounts available" />}
        <div className="w-full flex items-center justify-center md:justify-start gap-5 flex-wrap">
          <>
            {isDiscountLoading ? (
              <Spinner displayText="Loading..." />
            ) : (
              discounts.map(discount => (
                <CouponCard
                  key={discount._id}
                  coupon={discount}
                  fid={discount.favorites[0] ? discount.favorites[0]._id : ""}
                  isFavorite={!!discount.favorites.length}
                  isOwner={userState.id === discount.creator._id}
                  onReload={handleReload}
                />
              ))
            )}
          </>
        </div>
        <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      </div>
      {/* <section className="pt-12">
        <Footer />
      </section> */}
    </div>
  );
}

export default AllDiscounts;
