import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function deleteEvent(accessToken, id, body) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/impactevent/${id}`;

  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteEvent;
