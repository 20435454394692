/* eslint-disable no-console */
import React from "react";
import { STATUS } from "../../constant";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";

function AuthUser() {
  const { userState, subscription } = useUserContext();
  const location = useLocation();

  if (!userState?.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (userState.status === STATUS.REQUIRES_SUBSCRIPTION) {
    return <Navigate to="/plan" replace />;
  } else if (subscription.subscriptionStatus !== "active") {
    return <Navigate to="/subscription_info" replace />;
  } else {
    return <Outlet />;
  }
}

export default AuthUser;
