/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
import React, { useState, useRef, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ButtonPrimary from "../button/ButtonPrimary";
import Member from "../member/Member";
import Spinner from "../spinner/Spinner";
import { validateEmail } from "../../services/validation";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../constant";
import notify from "../../services/toast";
import { debounce, generateClassName } from "../../services/util";
import searchPremiumMembers from "../add_member/searchPremiumMembers";
import getPremiumMembers from "../add_member/getPremiumMembers";

const endpoint = "/plan?plan=COHATCH,PREMIUM";
const searchMembers = "/plan/search?plan=COHATCH,PREMIUM";

const AddMemberModal = ({ open, selectedMembers, onClose, onDone, onChange }) => {
  const { userState } = useUserContext();
  const [members, setMembers] = useState([]);
  const [query, setQuery] = React.useState("");
  const [email, setEmail] = useState("");
  const [invited, setInvited] = useState(0);
  const [validEmail, setValidEmail] = React.useState(false);

  const rootRef = useRef(null);
  const page = useRef(0);
  const remaining = useRef(0);

  const resetResult = () => {
    page.current = 0;
    remaining.current = 0;
  };

  useEffect(() => {
    const result = validateEmail(email);
    setValidEmail(result);
  }, [email]);

  const handleAddInvite = event => {
    event.preventDefault();
    const indx = selectedMembers.findIndex(member => member.emailAddress.toLowerCase() === email.toLowerCase());
    if (indx === -1 && email.toLowerCase() !== userState.emailAddress.toLowerCase()) {
      onChange([...selectedMembers, { emailAddress: email, _id: null }]);
      setInvited(prev => prev + 1);
      setEmail("");
    } else {
      notify(`${email} already exists`, "error");
      return;
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await getPremiumMembers(userState.accessToken, page.current, 5, endpoint);
      if (response.status === "success") {
        const { remaining: remainingResult, results } = response.data;
        page.current += 1;
        remaining.current = remainingResult;
        setMembers(prevState => {
          return [...prevState, ...results];
        });
      } else {
        notify("An error occurred while fetching members. Please try again later", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  const handleSearchMembers = async event => {
    try {
      if (!event.target.value) {
        resetResult();
        setMembers([]);
        fetchMembers();
        return;
      }
      resetResult();
      setQuery(event.target.value);
      const response = await searchPremiumMembers(userState.accessToken, page.current, 5, searchMembers, event.target.value);
      if (response.status === "success") {
        const { results } = response.data;
        setMembers(results);
      } else {
        notify("An error occurred while searching members. Please try again later", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  const debouncedHandleSearch = debounce(handleSearchMembers, 500);

  const observer = useRef(
    new IntersectionObserver(
      entries => {
        const [first] = entries;
        if (first.isIntersecting) {
          fetchMembers();
        }
      },
      { threshold: 1, root: rootRef.current }
    )
  );

  const [element, setElement] = useState(null);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <h2 className="text-lg font-bold ">Add or Invite Members</h2>

            <div className="mt-1 flex justify-between items-center gap-3">
              <input type="text" name="text" id="text" onChange={debouncedHandleSearch} className={generateClassName(false)} placeholder="Search members by first or last name" />
            </div>
            <ul className="p-2 divide-y divide-slate-200 max-h-60 overflow-y-auto">
              {members.length ? members.map((member, index) => <Member key={index} member={member} selectedMembers={selectedMembers} setSelectedMembers={onChange} />) : null}
              {remaining.current > 0 ? (
                <span ref={setElement} className="w-full flex justify-center text-gray-400">
                  Loading...
                </span>
              ) : null}

              {members.length && remaining.current <= 0 ? <span className="w-full flex justify-center text-gray-400">No more members to load</span> : null}
            </ul>
            <h2 className="text-lg font-bold">{invited} Member(s) Invited</h2>
            <div className="mt-1 flex justify-between items-center gap-3">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                className={generateClassName(!validEmail && email)}
                placeholder="Enter an email address to send an invite to friends outside of COhatch+!"
                aria-describedby="invite-optional"
              />
              <div>
                <ButtonPrimary action={handleAddInvite} size="md" text="Add" loading={!validEmail} />
              </div>
            </div>
            {selectedMembers.length > 0 ? (
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <div>
                  <ButtonPrimary action={onDone} size="md" text="Done" />
                </div>
              </div>
            ) : (
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <div>
                  <ButtonPrimary action={onClose} size="md" text="Cancel" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
