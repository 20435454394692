/* eslint-disable no-unused-vars */
import React from "react";
import MarketplaceCover from "../../assets/MarketplaceCover.jpg";
import Neighbor from "../../assets/NeighborhoodCover.jpg";
import Workshop from "../../assets/WorkshopCover.jpg";
import Life from "../../assets/LifePerksCover.jpg";
import Philanthropy from "../../assets/PhilanthropyCover.jpg";
import Startup from "../../assets/StartupCover.jpg";
import Invest from "../../assets/InvestCover.jpg";
import { MdLock } from "react-icons/md";
import FeaturesVideo from "../features_videos/FeaturesVideo";
import PhilanthropyVideo from "../../assets/PhilanthropyVideo.mp4";
import StartupVideo from "../../assets/StartupVideo.mp4";
import InvestVideo from "../../assets/InvestorVideo.mp4";
import PhilanthropyCover from "../../assets/PhilanthropyCoverImage.png";
import StartupCover from "../../assets/StartupCoverImage.png";
import InvestCover from "../../assets/InvestorCoverImage.png";


function HomeFeatures() {
    return (
      <>
    <section id="home_features" className="font-roboto max-w-full mx-auto p-5 md:p-20 bg-gray-200">
        <div className="max-w-7xl pb-12 mx-auto">
          <div className="lg:text-center">
            <h1 className="text-2xl md:text-5xl font-extrabold text-success-800 sm:text-center font-bebas">Equipping You To Be Greater</h1>
            <p className="mt-4 max-w-2xl  text-base md:text-xl text-gray-500 lg:mx-auto">
              Do more good, get involved with your community, get access to discounted vacation homes, services, shops, and more. All in one place.
            </p>
          </div>
        </div>
        <div className="relative bg-white pt-12 pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-2 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6 text-center">
                                <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Marketplace</h2>
                                <p className="mt-4 text-lg text-gray-500">Your one stop shop to find and support local businesses, food producers, restaurants, makers, and more.</p>
                                <div className="mt-6">
                                    <a href="/marketplace" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Go to Marketplace </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-12 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={MarketplaceCover} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
         {/* <div className="relative bg-white pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6 text-center">
                                <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Neighborhood</h2>
                                <p className="mt-4 text-lg text-gray-500">Connect with people in your neighborhood through groups and messages</p>
                                <div className="mt-6">
                                    <a href="/dashboard/neighborhood" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Go to Neighborhood </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={Neighbor} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
  
        <div className="relative bg-white pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6 text-center">
                                <div className="-m-3 p-3 flex items-end justify-center rounded-lg ">
                                    <div className="ml-4">
                                        <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Workshops </h2>
                                    </div>
                                </div>
                                <p className="mt-4 text-lg text-gray-500">  Host an in-person or virtual workshop through COhatch+ to drive more awareness to your business or idea.</p>
                                <div className="mt-6 text-center">
                            
                                    <a href="/dashboard/workshop" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Go to Workshops </a>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={Workshop} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
        {/* <div className="relative bg-white pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6 text-center">
                                <div className="-m-3 p-3 flex items-end justify-center rounded-lg">
                                    <div className="ml-4">
                                        <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Life Perks</h2>
                                    </div>
                                </div>
                                <p className="mt-4 text-lg text-gray-500">Have the whole family join in on the fun by reserving our Bounce House, Sports Simulators, Movie Theaters, and Game Rooms for your next event or party</p>
                                <div className="mt-6">
                                <div className="mt-6">
                                    <a href="/dashboard/life_perks" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Go to Life Perks </a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={Life} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}

        <div className="relative bg-white pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6 text-center">
                                <div className="-m-3 p-3 flex items-end justify-center rounded-lg">
                                    <div className="ml-4">
                                        <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Volunteer</h2>
                                    </div>
                                </div>
                                <p className="mt-4 text-lg text-gray-500">Get matched up with volunteer opportunities you are passionate about in your community and get to work.</p>
                                <div className="mt-6">
                                   <FeaturesVideo 
                                   button={<button className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Learn More </button>}
                                   title="Philanthropy - COMING SOON"
                                   video={PhilanthropyVideo}
                                   poster={PhilanthropyCover}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={Philanthropy} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
        {/* <div className="relative bg-white pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6 text-center">
                                <div className="-m-3 p-3 flex items-end justify-center rounded-lg">
                                    <MdLock className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" />
                                    <div className="ml-4">
                                        <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Startups - COMING SOON</h2>
                                    </div>
                                </div>
                                <p className="mt-4 text-lg text-gray-500">List your startup, pitch deck, and fundraising rounds to be found by local impact investors inside the COhatch+ ecosystem.</p>
                                <div className="mt-6">
                                  <FeaturesVideo button={<button className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Learn More </button>}
                                  title="Startups - COMING SOON"
                                  video={StartupVideo}
                                  poster={StartupCover}
                                  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={Startup} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
  
        {/* <div className="relative bg-white pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6 text-center">
                                <div className="-m-3 p-3 flex items-end justify-center rounded-lg">
                                    <MdLock className="flex-shrink-0 h-8 w-8 pb-2 text-success-800" aria-hidden="true" />
                                    <div className="ml-4">
                                        <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Investors - COMING SOON</h2>
                                    </div>
                                </div>
                                <p className="mt-4 text-lg text-gray-500">Invest in awesome, local startups with our all-local startup guide and make an impact inside your community.</p>
                                <div className="mt-6">
                                    <FeaturesVideo 
                                    button={<button className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Learn More </button>}
                                    title="Investors - COMING SOON"
                                    video={InvestVideo}
                                    poster={InvestCover}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={Invest} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        {/* <div className="relative bg-white pb-12 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6 text-center">
                                <div className="-m-3 p-3 flex items-end justify-center rounded-lg">
                                    <div className="ml-4">
                                        <h2 className="text-3xl font-extrabold block font-bebas text-success-800">Vacation + Rentals</h2>
                                    </div>
                                </div>
                                <p className="mt-4 text-lg text-gray-500">Get discounts on your next vacation stay or list your vacation home for booking!</p>
                                <div className="mt-6">
                                    <a href="/dashboard/vacation_homes" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-200 hover:text-success-800 hover:text-bold"> Go to Vacation + Rentals</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-24" src={Startup} alt="Inbox user interface"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </section>
</>
    );
  }
  
  export default HomeFeatures;
  