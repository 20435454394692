/* eslint-disable  */

import React from "react";
import VideoOne from "../onboard_video/VideoOne";
import VideoTwo from "../onboard_video/VideoTwo";
import VideoThree from "../onboard_video/VideoThree";
import { MAGIC_NUMBERS, STATUS } from "./../../constant";
import handleOnboarding from "./handleOnboarding";
import handleResendVerification from "./handleResendVerification";
import useUserContext from "./../../hooks/useUserContext";
import { computeProfilePercentage, showMessage } from "./../../services/util";
import notify from "./../../services/toast";
import { Link } from "react-router-dom";

function Onboarding() {
  const video1Ref = React.useRef(null);
  const video2Ref = React.useRef(null);
  const video3Ref = React.useRef(null);

  const notificationRef = React.useRef(null);

  const { userState, setUserState } = useUserContext();
  const { onboarding, status } = userState;

  const [tutorialDetails, setTutorialDetails] = React.useState({ source: "", title: "" });
  const playVideo = tutorial => {
    if (tutorial === MAGIC_NUMBERS.ONE) {
      setTutorialDetails({ source: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/videos/COhatchplus_Profile_Profile.mp4", title: "Setup your COhatch+ Profile" });
      video1Ref.current.showVideo();
    } else if (tutorial === MAGIC_NUMBERS.TWO) {
      setTutorialDetails({ source: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/videos/COhatchplus_%20Adding_Listing.mp4", title: "Add a Business Listing" });
      video2Ref.current.showVideo();
    } else if (tutorial === MAGIC_NUMBERS.THREE) {
      setTutorialDetails({ source: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/videos/COhatchplus_Creating_Group.mp4", title: "Create a Neighborhood Group" });
      video3Ref.current.showVideo();
    }
  };

  const updateOnboardingStatus = async data => {
    try {
      const payload = {
        ONE: { profileSetup: true },
        TWO: { addListing: true },
        THREE: { createGroup: true },
      };

      if (data === "ONE" && onboarding.profileSetup) {
        return;
      }

      if (data === "TWO" && onboarding.addListing) {
        return;
      }

      if (data === "THREE" && onboarding.createGroup) {
        return;
      }
      const newPayload = { ...onboarding, ...payload[data] };
      const response = await handleOnboarding(newPayload, userState.accessToken);
      if (response.status === "success") {
        setUserState(prevState => {
          return { ...prevState, onboarding: newPayload };
        });
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const resendEmailVerification = async event => {
    event.preventDefault();
    try {
      const payload = { emailAddress: userState.emailAddress };
      const response = await handleResendVerification(payload, userState.accessToken);
      if (response.status === "success") {
        notify("Your new email verification should be on it's way to your inbox.", "info");
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };
  return (
    <>
     <div className="flex flex-col justify-start">
        <h3 className="text-xl font-bold text-success-800">Email verification</h3>
        <p className="text-base text-gray-400">
          Check your email inbox for a verification email.{" "}
          {userState.status === STATUS.REQUIRES_EMAIL_VERIFICATION ? (
            <div onClick={resendEmailVerification} className="cursor-pointer text-sm text-primary-400 underline">
              Resend verification
         </div>
          ) : null}
        </p>
      </div>
      <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${status === STATUS.ACTIVE ? "bg-primary-500" : "bg-red-500"}  text-white`}>
        {status === STATUS.ACTIVE ? "Complete" : "Incomplete"}
      </p>
    
    {userState.officerndId ? (
      <li className="w-full px-4 py-4 sm:px-0 sm:flex sm:items-center sm:justify-between">
        <div className="flex flex-col justify-start">
          <h3 className="text-xl font-bold text-success-800">Update your password</h3>
          <Link to="settings/account" className="text-base text-gray-400 underline">
            Follow this link to update your default password.
          </Link>
        </div>
        <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${onboarding.updatePassword ? "bg-primary-500" : "bg-red-500"}  text-white`}>
          {onboarding.updatePassword ? "Complete" : "Incomplete"}
        </p>
      </li>
    ) : null}
</>
    // <div className="py-4">
    //   <div className=" rounded-lg bg-white shadow-md p-5">
    //     <h1 className="text-2xl text-success mb-2">Get Started</h1>
    //     <div className="bg-white shadow overflow-hidden sm:rounded-md p-5">
    //       <ul role="list" className="divide-y divide-gray-200 w-full">
    //         <li className="w-full px-4 py-4 sm:px-0 sm:flex sm:items-center sm:justify-between">
    //           <div className="flex flex-col justify-start">
    //             <h3 className="text-xl font-bold text-success-800">Email verification</h3>
    //             <p className="text-base text-gray-400">
    //               Check your email inbox for a verification email.{" "}
    //               {userState.status === STATUS.REQUIRES_EMAIL_VERIFICATION ? (
    //                 <Link to="" onClick={resendEmailVerification} className="text-sm text-primary-400 underline">
    //                   Resend verification
    //                 </Link>
    //               ) : null}
    //             </p>
    //           </div>
    //           <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${status === STATUS.ACTIVE ? "bg-primary-500" : "bg-red-500"}  text-white`}>
    //             {status === STATUS.ACTIVE ? "Complete" : "Incomplete"}
    //           </p>
    //         </li>
    //         {userState.officerndId ? (
    //           <li className="w-full px-4 py-4 sm:px-0 sm:flex sm:items-center sm:justify-between">
    //             <div className="flex flex-col justify-start">
    //               <h3 className="text-xl font-bold text-success-800">Update your password</h3>
    //               <Link to="settings/account" className="text-base text-gray-400 underline">
    //                 Follow this link to update your default password.
    //               </Link>
    //             </div>
    //             <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${onboarding.updatePassword ? "bg-primary-500" : "bg-red-500"}  text-white`}>
    //               {onboarding.updatePassword ? "Complete" : "Incomplete"}
    //             </p>
    //           </li>
    //         ) : null}
    //         <li className="w-full px-4 py-4 sm:px-0 sm:flex sm:items-center sm:justify-between">
    //           <div className="flex flex-col justify-start">
    //             <h3 className="text-xl font-bold text-success-800">Your profile</h3>
    //             <Link to="settings" className="text-base text-gray-400">
    //               You have completed: {computeProfilePercentage(userState)}% of your profile.
    //             </Link>
    //           </div>
    //           <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${computeProfilePercentage(userState) === 100 ? "bg-primary-500" : "bg-red-500"}  text-white`}>
    //             {computeProfilePercentage(userState) === 100 ? "Complete" : "Incomplete"}
    //           </p>
    //         </li>
    //         <li className="w-full px-4 py-4 sm:px-0 sm:flex sm:items-center sm:justify-between">
    //           <div className="flex flex-col justify-start">
    //             <h3 className="text-xl font-bold text-success-800">Setup your COhatch+ Member Profile</h3>
    //             <Link to="settings" className="text-base text-gray-400">
    //               You have completed: {computeProfilePercentage(userState)}% of your profile.
    //             </Link>
    //             <p
    //               onClick={() => {
    //                 playVideo(MAGIC_NUMBERS.ONE);
    //                 updateOnboardingStatus("ONE");
    //               }}
    //               className="text-base text-gray-400 flex gap-2 items-center cursor-pointer">
    //               <span className="material-icons-outlined">play_circle</span>
    //               Watch This Video
    //             </p>
    //           </div>
    //           <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${onboarding.profileSetup ? "bg-primary-500" : "bg-red-500"}  text-white`}>
    //             {onboarding.profileSetup ? "Complete" : "Incomplete"}
    //           </p>
    //         </li>

    //         <li className="w-full px-4 py-4 sm:px-0 sm:flex sm:items-center sm:justify-between">
    //           <div className="flex flex-col justify-start">
    //             <h3 className="text-xl font-bold text-success-800">Add a Business Listing</h3>
    //             <p
    //               onClick={() => {
    //                 playVideo(MAGIC_NUMBERS.TWO);
    //                 updateOnboardingStatus("TWO");
    //               }}
    //               className="text-base text-gray-400 flex gap-2 items-center cursor-pointer">
    //               <span className="material-icons-outlined">play_circle</span>
    //               Watch This Video
    //             </p>
    //           </div>
    //           <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${onboarding.addListing ? "bg-primary-500" : "bg-red-500"}  text-white`}>
    //             {onboarding.addListing ? "Complete" : "Incomplete"}
    //           </p>
    //         </li>

    //         <li className="w-full px-4 py-4 sm:px-0 sm:flex sm:items-center sm:justify-between">
    //           <div className="flex flex-col justify-start">
    //             <h3 className="text-xl font-bold text-success-800">Create a Neighborhood Group</h3>
    //             <p
    //               onClick={() => {
    //                 playVideo(MAGIC_NUMBERS.THREE);
    //                 updateOnboardingStatus("THREE");
    //               }}
    //               className="text-base text-gray-400 flex gap-2 items-center cursor-pointer">
    //               <span className="material-icons-outlined">play_circle</span>
    //               Watch This Video
    //             </p>
    //           </div>
    //           <p className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${onboarding.createGroup ? "bg-primary-500" : "bg-red-500"}  text-white`}>
    //             {onboarding.createGroup ? "Complete" : "Incomplete"}
    //           </p>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    //   <VideoOne videoSource={tutorialDetails.source} title={tutorialDetails.title} ref={video1Ref} />
    //   <VideoTwo videoSource={tutorialDetails.source} title={tutorialDetails.title} ref={video2Ref} />
    //   <VideoThree videoSource={tutorialDetails.source} title={tutorialDetails.title} ref={video3Ref} />
    // </div>
  );
}

export default Onboarding;
