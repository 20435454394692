/* eslint-disable id-length */
/* eslint-disable new-cap */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable capitalized-comments */

import React from "react";
import { Link } from "react-router-dom";
import { WORKSHOP_CATEGORY } from "../../constant";
import format from "date-fns/format";

// const post = 
//     {
//       id: 1,
//       title: "Boost your conversion rate",
//       href: "#",
//       description:
//         "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.",
//       imageUrl:
//         "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80",
//       date: "Mar 16, 2020",
//       datetime: "2020-03-16",
//       category: { title: "Marketing", href: "#" },
//       author: {
//         name: "Michael Foster",
//         role: "Co-Founder / CTO",
//         href: "#",
//         imageUrl:
//           "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//       },
//     };
  
  
  export default function FeaturedEvents({ event }) {

    const background = event?.eventImage?.large ? event.eventImage?.large.url : null;
    const logo = event?.eventOwnerImage?.small ? event.eventOwnerImage.small.url : null;
    const businessName = event?.listing?.businessName;
    const category = WORKSHOP_CATEGORY.find((item) => item.value === event.eventCategory)?.label;
    const address = event?.listing?.businessAddress ? event.listing?.businessAddress.address : null;
    const firstName = event?.eventOwnerFirstName ? event.eventOwnerFirstName : null;
    const lastName = event?.eventOwnerLastName ? event?.eventOwnerLastName : null;
    const email = event?.creator?.emailAddress ? event.creator.emailAddress : null;
    const eventName = event?.eventName;
    const approvalStatus = event?.approvalStatus;
    const eventDescription = event?.eventDescription.slice(0, 200);
    const eventDate = event?.eventDate;
    const startTime = event?.startTime;
    const endTime = event?.endTime;

    return (
      <div className="bg-white sm:py-5">
        <div className="mx-auto max-w-7xl px-6">
          <div className="mx-auto max-w-2xl">
                <article key={event._id} className="relative isolate flex flex-col gap-8 ">
                  <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-[2/1]">
                    <img
                      src={background}
                      alt=""
                      className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                    />
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div>
                    <div className="flex items-center gap-x-4 text-xs">
                      
                      <div
                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                      >
                        {category}
                      </div>
                    </div>
                    
                    <div className="group relative max-w-xl">
                      <h3 className="mt-1 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        <div>
                          <span className="absolute inset-0" />
                          {eventName}
                        </div>
                      </h3>
                      <div  className="text-gray-500">
                        {format(new Date(eventDate), "PP")}
                      </div>
                      <p className="mt-5 text-sm leading-6 text-gray-600">{`${eventDescription}...`}</p>
                    </div>

                    <div className="mt-3 flex border-b border-gray-900/5 pt-1 pb-3">
                      <div className="relative flex items-center gap-x-4">
                        <Link to={"/dashboard/workshop"}>
                          <button className="group flex">
                            <div className="text-sm font-semibold leading-6 text-primary-400 hover:text-primary-100">
                              View Event <span aria-hidden="true">&rarr;</span>
                            </div>
                          </button>
                        </Link>
                      </div>                              
                    </div>

                    
                  </div>
                </article>
          </div>
        </div>
      </div>
    );
  }
  