import React from "react";
import PropTypes from "prop-types";

function Video({ source, type, poster }) {
  const START_PROGRESS = 0;
  const PERCENTAGE = 100;
  const VIDEO_DEFAULT_DURATION = 0;
  const SECONDS_IN_AN_HOUR = 3600;
  const SECONDS_IN_A_MINUTE = 60;
  const TIME_LENGTH = 2;
  const DEFAULT_INCREMENT = 1;
  const playPauseRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const videoProgressRef = React.useRef(null);
  const [videoProgress, setVideoProgress] = React.useState(START_PROGRESS);
  const [videoDuration, setVideoDuration] = React.useState(VIDEO_DEFAULT_DURATION);
  const [hour, setHour] = React.useState(VIDEO_DEFAULT_DURATION);
  const [minute, setMinute] = React.useState(VIDEO_DEFAULT_DURATION);
  const [seconds, setSeconds] = React.useState(VIDEO_DEFAULT_DURATION);
  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const [controls, setControls] = React.useState("");

  const playPauseVideo = () => {
    if (videoRef.current.paused) {
      playPauseRef.current.textContent = "pause_circle_outline";
      videoRef.current.play();
    } else {
      playPauseRef.current.textContent = "play_circle_outline";
      videoRef.current.pause();
    }
  };

  const updateVideoDuration = durationInSeconds => {
    if (durationInSeconds > SECONDS_IN_A_MINUTE) {
      setMinute(prevState => prevState + DEFAULT_INCREMENT);
      setSeconds(VIDEO_DEFAULT_DURATION);
    } else if (durationInSeconds > SECONDS_IN_AN_HOUR) {
      setHour(prevState => prevState + DEFAULT_INCREMENT);
      setMinute(VIDEO_DEFAULT_DURATION);
    }
    setSeconds(durationInSeconds);
  };

  const handleTimeUpdate = () => {
    const progress = (videoRef.current.currentTime / videoRef.current.duration) * PERCENTAGE;
    setVideoProgress(progress);
    updateVideoDuration(Math.ceil(videoRef.current.currentTime));
  };

  const handleVideoEnded = () => {
    playPauseRef.current.textContent = "play_circle_outline";
    setVideoProgress(START_PROGRESS);
    setHour(VIDEO_DEFAULT_DURATION);
    setMinute(VIDEO_DEFAULT_DURATION);
    setSeconds(VIDEO_DEFAULT_DURATION);
    setVideoPlaying(false);
    videoRef.current.load();
  };

  const handleLoadedMetaData = () => {
    const videoDuration = videoRef.current.duration || VIDEO_DEFAULT_DURATION;
    const durationInSeconds = Math.ceil(videoDuration);
    const durationInHours = Math.floor(durationInSeconds / SECONDS_IN_AN_HOUR);
    const durationInMinutes = Math.floor(durationInSeconds / SECONDS_IN_A_MINUTE);
    const durationAsString = `${durationInHours.toString().padStart(TIME_LENGTH, "0")}:${durationInMinutes.toString().padStart(TIME_LENGTH, "0")}:${durationInSeconds
      .toString()
      .padStart(TIME_LENGTH, "0")}`;
    setVideoDuration(durationAsString);
  };

  const handleVideoPlaying = () => {
    setVideoPlaying(!videoPlaying);
    setControls("translate-y-[85px]");
  };
  return (
    <div className="group relative w-full overflow-hidden">
      <video poster={poster} onTimeUpdate={handleTimeUpdate} onEnded={handleVideoEnded} onLoadedMetadata={handleLoadedMetaData} onPlaying={handleVideoPlaying} className="w-full " ref={videoRef}>
        <source src={source} type={type} />
        Video not supported by this browser
      </video>
      <div className={`absolute flex bottom-0 w-full flex-wrap bg-success-800 ${controls} group-hover:translate-y-[0px] transition ease-linear duration-500`}>
        <div className="h-2 top-0 left-0 w-full">
          <div ref={videoProgressRef} className="h-2 bg-primary-400" style={{ width: `${videoProgress}%` }}></div>
        </div>
        <div className="flex items-center gap-5 p-3">
          <span onClick={playPauseVideo} ref={playPauseRef} className="material-icons text-3xl sm:text-6xl text-primary-400 cursor-pointer">
            play_circle_outline
          </span>
          <div className="text-xl font-bold text-white">
            <span className="mr-2">
              <span>{hour.toString().padStart(TIME_LENGTH, "0")}</span>:<span>{minute.toString().padStart(TIME_LENGTH, "0")}</span>:<span>{seconds.toString().padStart(TIME_LENGTH, "0")}</span>
            </span>
            /<span className="ml-2">{videoDuration}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

Video.propTypes = {
  source: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  poster: PropTypes.string,
};

export default Video;
