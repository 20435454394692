/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "./../../components/logo/Logo";
import ButtonPrimary from "./../../components/button/ButtonPrimary";
import { Link } from "react-router-dom";
import Notification from "./../../components/notification/Notification";
import { showMessage } from "./../../services/util";
import { validateEmail } from "./../../services/validation";
import submitForm from "./submitForm";
import notify from "../../services/toast";
import useScrollPage from "../../hooks/useScrollPage";

const SERVER_ERROR_CODES = [404, 500];
function RequestRequest() {
  useScrollPage();
  const emailRef = React.useRef(null);
  const notificationRef = React.useRef(null);

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState({ heading: "", body: "", type: "" });
  const [submitting, setSubmitting] = React.useState(false);

  const generateClassName = valid => {
    if (!valid) {
      return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md";
    } else {
      return "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-400 focus:border-red-400 sm:text-md";
    }
  };

  React.useEffect(() => {
    const result = validateEmail(email);
    setError(result);
  }, [email]);

  React.useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleFormSubmit = async event => {
    event.preventDefault();
    setSubmitting(true);
    try {
      if (!error) {
        showMessage("Ooh no", "One or more fields are invalid. Check and try again.", "error", notificationRef, "open", setDisplayMessage);
        return;
      }

      const payload = {
        emailAddress: email,
      };
      const response = await submitForm(payload);
      if (response.status === "success") {
        notify("Reset request granted. If the email address exists, you will receive an email with instructions to reset your password.", "info");
        setEmail("");
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="h-screen w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-[url('https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/background-image.png')] bg-no-repeat bg-cover">
      <div className="flex justify-center">
        <Logo color="green" />
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-400">Reset your password</h2>
        <p className="mt-4 text-gray-500 text-base text-center px-2">Enter the email address associated with your account and we&apos;ll send you a link to reset your password.</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 md:shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleFormSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  ref={emailRef}
                  onChange={event => setEmail(event.target.value)}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="example@email.com"
                  value={email}
                  className={generateClassName(!error && email)}
                />
              </div>
            </div>
            <div>
              <ButtonPrimary text={submitting ? "Please wait..." : "Continue"} size="md" loading={!error || submitting ? true : false} />
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500"> Changed your mind? </span>
              </div>
            </div>

            <div className="mt-6 flex justify-between">
              <span className="text-sm text-gray-500">
                <Link className="text-primary-400 ml-1" to="/login">
                  Login
                </Link>
              </span>
              <span className="text-sm text-gray-500">
                <Link className="text-primary-400 ml-1" to="/signup">
                  Signup
                </Link>
              </span>
            </div>
            <div className="p-5 text-xs text-gray-500 text-center">&copy; COhatch/COhatch+ All rights reserved</div>
          </div>
        </div>
      </div>
      <Notification header={displayMessage.heading} body={displayMessage.body} type={displayMessage.type} ref={notificationRef} />
    </div>
  );
}

export default RequestRequest;
