import React from "react";
import Boost from "../../assets/JoinPeerGroups.jpg";


function PricingCta() {
    return (
<>
<div className="relative py-6 bg-white">
  <div className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true"></div>
  <div className="max-w-9xl mx-auto bg-success-800 lg:bg-transparent lg:px-8">
    <div className="lg:grid lg:grid-cols-12">
      <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
       
        <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
          <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
            <img className="object-cover object-center rounded-3xl shadow-2xl" src={Boost} alt=""></img>
          </div>
        </div>
      </div>

      <div className="relative bg-success-800 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
        <div className="relative text-center max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
          <h2 className="text-3xl font-extrabold text-white" id="join-heading">Start using COhatch+ today.</h2>
          <p className="text-lg text-white">We know you’ll love it.</p>
          <a className="block w-full  py-3 px-5 text-center bg-primary-400 border border-transparent rounded-md shadow-md text-base font-medium text-white hover:bg-primary-100 sm:inline-block sm:w-auto" href="/pricing">See All Plans</a>
        </div>
      </div>
    </div>
  </div>
</div>    
</>
    );
  }
  
  export default PricingCta;
  