import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function deleteReply(accessToken, commentId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/comment/reply/${commentId}`;

  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteReply;
