/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import useUserContext from "../../hooks/useUserContext";
import { format } from "date-fns";
import updateMessage from "./updateMessage";
import notify from "../../services/toast";
import deleteMessage from "./deleteMessage";
import { decrypt } from "../../services/util";

function Message({ message, getMessages }) {
  const { userState } = useUserContext();
  const sender = message?.senderId === userState.id ? false : true;
  const textMessage = message?.textMessage ? decrypt(message.textMessage) : null;
  const mediaMessage = message?.mediaMessage ? JSON.parse(decrypt(message.mediaMessage)) : null;
  const createdAt = message?.createdAt ? message.createdAt : null;
  const deletedBy = message?.deletedBy ? message.deletedBy : [];
  const { firstName, lastName, photo } = message.sender;

  const handleUpdateMessages = async () => {
    try {
      await updateMessage(userState.accessToken, message._id);
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  const handleDeleteMessage = async () => {
    try {
      const response = await deleteMessage(userState.accessToken, message._id);
      if (response.status === "success") {
        notify("Successfully deleted message.", "success");
        getMessages();
      } else {
        notify("An error occurred while deleting message.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  React.useEffect(() => {
    if (!message.read.includes(userState.id)) {
      handleUpdateMessages();
    }
  }, []);
  return (
    <>
      {deletedBy.includes(userState.id) ? null : (
        <div className={`group w-full flex ${sender ? "justify-start" : "justify-end"} px-4 py-2 `}>
          <div className="w-fit">
            <div className={`max-w-xl flex flex-col flex-1 justify-items-stretch ${sender ? "bg-success-800 text-white" : "bg-gray-400 text-white"} pt-4 px-4 rounded-md`}>
              {textMessage ? <p className="w-full text-md">{textMessage.split("\n").map((item, idx) => (<span key={`msg-${idx}`}>{item}<br /></span>))}</p> : null}
              {mediaMessage ? (
                <div className="relative w-[200px] h-[200px]">
                  <img className={`block h-full w-full ${mediaMessage.length > 1 ? "blur-sm" : ""}`} src={mediaMessage[mediaMessage.length - 1].small.url} alt="" />
                  {mediaMessage.length > 1 ? (
                    <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-xl font-bold">+{mediaMessage.length - 1}</span>
                  ) : null}
                </div>
              ) : null}
              <div className="mt-4 w-full flex justify-between items-center">
                <div className="invisible group-hover:visible">
                  <span className=" material-icons-outlined text-[20px] text-red-400 cursor-pointer" onClick={handleDeleteMessage}>
                    delete
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="material-icons-outlined text-sm">schedule</span>
                  <span className="text-xs">{format(new Date(createdAt), "hh:mm a")}</span>
                </div>
              </div>
            </div>
            <div className="mt-2 w-full flex flex-1 justify-between items-center gap-2">
              <div className="w-full flex gap-2 items-center">
                <img
                  className="block h-8 w-8 rounded-full"
                  src={photo?.small ? photo.small.url : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"}
                  alt={lastName}
                />
                <h1 className="w-full text-left text-gray-400 text-sm capitalize">
                  {firstName} {lastName}
                </h1>
              </div>
              {!sender ? <span className="text-sm text-primary-400">sent</span> : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Message;
