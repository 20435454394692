/* eslint-disable capitalized-comments */
import React from "react";

const marketplaceCategories = [
    {
      name: "Services",
      imageUrl:
        "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/Marketplace-Categories/services.png",
      href: "marketplace/services",  
    },
    {
        name: "Local Shopping",
        imageUrl:
          "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/Marketplace-Categories/local%20shopping%20+%20artisans%20shopping.png",
          href: "marketplace/local_shopping"
      },
      {
        name: "Local Food",
        imageUrl:
          "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/Marketplace-Categories/local%20foods%20+%20food.png",
          href: "marketplace/local_food"
      },
      {
        name: "Community Resources",
        imageUrl:
          "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/Marketplace-Categories/community%20resources%20resources%20services.png",
          href: "marketplace/community_resources"
      },
      // {
      //   name: "Events",
      //   imageUrl:
      //     "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
      //   href: "marketplace/events"
      // },
    // More marketplaceCategories...
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  
  export default function MarketplaceCategories() {
    return (
      <div className="py-2">
        <div className="mx-auto max-w-7xl px-6 lg:px-2 text-center">
            <h2 className="text-3xl font-bold tracking-wide font-bebas text-primary-400 sm:text-4xl">Marketplace Categories</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
            Your one stop shop to find and support local businesses, food producers, restaurants, makers, and more.
            </p>
            </div>
        <div className="hidden sm:block">
          <ul
            role="list"
            className="text-center mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4"
          >
            {marketplaceCategories.map((marketplaceCategory) => (
              <li key={marketplaceCategory.name}>
                <a href={marketplaceCategory.href} className="font-medium text-gray-900 hover:text-gray-600">
                <img className="mx-auto h-40 w-40 rounded-full" src={marketplaceCategory.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-black">{marketplaceCategory.name}</h3>
                </a>
              </li>
            ))}
          </ul>
        </div>
        
        <div className="sm:hidden">
      
      <ul role="list" className="mt-3">
        {marketplaceCategories.map((marketplaceCategory) => (
          <li key={marketplaceCategory.name} className="col-span-1 mt-4 flex rounded-md shadow-sm">
            <div
              className={classNames(
                marketplaceCategory.bgColor,
                "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
              )}
            >
              <img  src={marketplaceCategory.imageUrl} alt="" />
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <a href={marketplaceCategory.href} className="font-medium text-gray-900 hover:text-gray-600">
                  {marketplaceCategory.name}
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
      </div>
    );
  }
  